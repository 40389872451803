import React, { memo, ReactNode } from "react";
import "./styles.scss";

interface Props {
  data?: (string | ReactNode)[];
}

function List(props: Props) {
  const { data = [] } = props;

  return (
    <div className="b-list-container">
      <ul className="b-list">
        {data.map((item, index) => (
          <li key={index.toString()} className="b-list__item">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default memo(List);
