import styled, { css } from "styled-components";
import { switchProp } from "styled-tools";
import { CSSProperties, memo, PropsWithChildren } from "react";

export type PaperSizes = "xsmall" | "small" | "medium" | "large" | "xlarge";

interface Props {
  fullHeight: boolean;
  size?: PaperSizes;
  customSize?: string;
}

const StyledModalPaper = styled.div<Partial<Props>>`
  background-color: ${(props) => props.theme.color.white};
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;

  @media only screen and (min-width: 576px) {
    width: ${switchProp("size", {
      xsmall: "480px",
      small: "640px",
      medium: "960px",
      large: "1120px",
      xlarge: "calc(100% - 40px)",
    })};
  }

  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}
`;

function ModalPaper(props: Props & PropsWithChildren) {
  const { customSize, size = "small", fullHeight = false, children } = props;

  const paperProps: Partial<Props> & { style?: CSSProperties } = {
    fullHeight,
  };

  if (!!customSize) {
    paperProps.style = {
      width: customSize,
    };
  } else {
    paperProps.size = size;
  }

  return <StyledModalPaper {...paperProps}>{children}</StyledModalPaper>;
}

export default memo(ModalPaper);
