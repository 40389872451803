import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Container } from "@app/components";
import {
  PanelContract,
  PanelControl,
  PanelEconomy,
  PanelMonitoring,
  PanelSmartContract,
  PanelVehicleAccounting,
  PanelVehiclePrice,
  PanelVehicleRequest,
  PanelWorkSearch,
} from "./components";
import { ifProp, theme } from "styled-tools";
import SectionTitle from "../SectionTitle";

const StyledSection = styled.section`
  padding: 40px 0;
  box-sizing: border-box;
`;

const StyledTitle = styled(SectionTitle)`
  text-align: center;
  margin: 0 0 32px 0;
`;

const StyledControls = styled.div`
  margin: 32px auto 60px;
  border-radius: 16px;
  border: 1px solid ${theme("color.grayLight")};
  padding: 4px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    margin: 0 auto 32px;
  }
`;

const StyledControl = styled.button<{
  active: boolean;
}>`
  padding: 4px 8px;
  box-sizing: border-box;
  margin: 0;
  border: none;
  cursor: pointer;
  background: ${ifProp(
    "active",
    `linear-gradient(99.46deg, #ed4a3f 43.11%, #f99335 99.74%)`,
    theme("color.white")
  )};
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  color: ${ifProp("active", theme("color.white"), "#69748C")};
`;

const StyledCustomerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: max-content;
  grid-gap: 16px;

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

function SectionPossibilities() {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const onClickButton = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setCurrentIndex(index);
    },
    []
  );

  return (
    <StyledSection id="section-possibilities">
      <Container>
        <StyledTitle>Возможности сервиса</StyledTitle>
        <div style={{ textAlign: "center" }}>
          <StyledControls>
            <StyledControl
              active={currentIndex === 0}
              onClick={onClickButton(0)}
            >
              Застройщик
            </StyledControl>
            <StyledControl
              active={currentIndex === 1}
              onClick={onClickButton(1)}
            >
              Поставщик техники
            </StyledControl>
          </StyledControls>
        </div>
        <StyledCustomerGrid>
          {currentIndex === 0 && (
            <>
              <PanelEconomy />
              <PanelVehicleRequest />
              <PanelSmartContract />
              <PanelControl />
              <PanelVehicleAccounting />
              <PanelContract />
              <PanelVehiclePrice />
            </>
          )}
          {currentIndex === 1 && (
            <>
              <PanelVehicleRequest variant="secondary" />
              <PanelSmartContract variant="secondary" />
              <PanelWorkSearch />
              <PanelMonitoring />
              <PanelContract variant="secondary" />
              <PanelControl variant="secondary" />
              <PanelEconomy variant="secondary" />
            </>
          )}
        </StyledCustomerGrid>
      </Container>
    </StyledSection>
  );
}

export default memo(SectionPossibilities);
