import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { IconInfo16 } from "@app/icons";

type Props = {
  onClick: () => void;
};

const StyledAlert = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${theme("color.grayLight")};
  background: ${theme("color.white")};

  p {
    font-size: 12px;
    line-height: 16px;
  }
`;

const StyledAlertText = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoIcon = styled.div`
  display: flex;
  height: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${theme("color.dark")};
  cursor: pointer;

  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    50% {
      transform: scale(1.05);
      box-shadow: 0 0 0 14px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const VehicleAlert = (props: Props) => {
  const { onClick } = props;

  const onAlertClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <>
      <StyledAlert>
        <InfoIcon onClick={onAlertClick}>
          <IconInfo16 />
        </InfoIcon>
        <StyledAlertText>
          <p>Добавление Техники занимает до одного рабочего дня.</p>
          <p>
            В случае отсутствия обратной связи в течение одного рабочего дня
            просим обращаться по номеру телефона: +7 771 046 0533
          </p>
        </StyledAlertText>
      </StyledAlert>
    </>
  );
};

export default memo(VehicleAlert);
