const units = ["байт", "кб", "мб"];

export function formatFileSize(size: number = 0): string {
  let i = 0;

  while (size >= 1024 && ++i) {
    size = size / 1024;
  }

  return size.toFixed(size < 10 && i > 0 ? 1 : 0) + " " + units[i];
}
