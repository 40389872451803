import { Confirmer } from "./User";
import { CompanyType } from "./Company";
import { Project } from "./Project";
import { NdsShort } from "./NDS";
import { SmartContractStatus } from "./SmartContract";

export interface Task {
  id: string;
  name: string;
  initiator: Confirmer;
  executor: CompanyType;
  executorUser: Confirmer;
  customer: CompanyType | null;
  project: Project;
  dateStart: string;
  dateFinish: string;
  sourceId: string;
  taskType: UserTaskType;
  status: UserTaskStatuses;
  statusMain?: SmartContractStatus;
  nds: NdsShort | null;
}

export interface TaskRedirectRequest {
  taskId: string;
  userId: string;
  oldUserId: string;
  comment?: string;
}

export enum UserTaskType {
  AgreementAct = 1,
  AcquaintanceAct = 2,
  SigningContract = 3,
  SigningAvr = 4,
  AgreementMemo = 5,
  AcquaintanceMemo = 6,
  ApprovalContract = 7,

  ApprovedContract = 8,
  RejectedOnApprovalContract = 9,
}
export const UserTaskTypeName: Record<UserTaskType, string> = {
  [UserTaskType.AgreementAct]: "Согласование табеля оказанных услуг",
  [UserTaskType.AcquaintanceAct]: "Ознакомление с табелем оказанных услуг",
  [UserTaskType.SigningContract]: "Подписание договора",
  [UserTaskType.SigningAvr]: "Подписание акта выполненных работ",
  [UserTaskType.AgreementMemo]: "Согласование служебной записки",
  [UserTaskType.AcquaintanceMemo]: "Ознакомление служебной записки",
  [UserTaskType.ApprovalContract]: "Согласование договора",
  [UserTaskType.ApprovedContract]: "Согласован договор",
  [UserTaskType.RejectedOnApprovalContract]: "Отклонен при согласовании",
};

export enum UserTaskStatuses {
  ToBeAgreed = 1,
  OnApprove = 2,
  SigningContract = 3,
  SigningAvr = 4,
  Acquainted = 5,
  Agreed = 6,
  SignedContract = 7,
  SignedAvr = 8,
  Cancelled = 9,
  Recalled = 10,
  Rejected = 20,
  Redirected = 21,
  Adhesion = 22,
  AdhesionSigned = 23,
}

export const UserTaskStatusesName: Record<UserTaskStatuses, string> = {
  [UserTaskStatuses.ToBeAgreed]: "На согласовании",
  [UserTaskStatuses.OnApprove]: "На ознакомлении",
  [UserTaskStatuses.SigningContract]: "Подписание договора",
  [UserTaskStatuses.SigningAvr]: "Подписание акта выполненных работ",
  [UserTaskStatuses.Acquainted]: "Ознакомлен",
  [UserTaskStatuses.Agreed]: "Согласован",
  [UserTaskStatuses.SignedContract]: "Подписан договор",
  [UserTaskStatuses.SignedAvr]: "Подписан акт выполненных работ",
  [UserTaskStatuses.Cancelled]: "Отменен",
  [UserTaskStatuses.Recalled]: "Отозван",
  [UserTaskStatuses.Rejected]: "Отклонен",
  [UserTaskStatuses.Redirected]: "Перенаправлен",
  [UserTaskStatuses.Adhesion]: "Подписание договора",
  [UserTaskStatuses.AdhesionSigned]: "Подписан договор",
};
