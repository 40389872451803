import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, ListItem, Modal, ModalProps, ModalTitle } from "@app/components";
import { useNotification } from "@app/providers";
import { ActOfWork } from "@app/models";
import { SignerListView } from "@app/common";
import { APIResponse, getActOfWorksById } from "@app/api";
import { dateFormat, getAxiosErrorMessage, priceFormat } from "@app/helpers";
import { AxiosError } from "axios";
import styled from "styled-components";

interface Props extends Omit<ModalProps, "title"> {
  data: ActOfWork | null;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  background: #f5f6f8;
  border-bottom: 1px solid #d8d8d8;
`;

const ModalActDocDetail = (props: Props) => {
  const { onClose, data, ...restProps } = props;
  const { showNotification } = useNotification();
  const [actDetail, setActDetail] = useState<ActOfWork | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!actDetail) {
      return "Загружается...";
    }

    return (
      <ModalTitle text={`Акт выполненных работ №${data?.number}` || ""}>
        {/*<ActStatusChip status={actDetail.status} />*/}
      </ModalTitle>
    );
  }, [actDetail, data]);

  const getActDetail = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response = await getActOfWorksById(id);
        setActDetail(response.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  useEffect(() => {
    if (data && data.id) {
      getActDetail(data.id).then(() => {});
    }
  }, [data, getActDetail]);

  const onModalDetailClose = useCallback(() => {
    setActDetail(null);
    onClose();
  }, [onClose]);

  return (
    <Modal
      onClose={onModalDetailClose}
      title={modalTitle}
      actions={[]}
      customSize={"720px"}
      dense
      {...restProps}
    >
      <Header>
        <Grid columns={3}>
          <ListItem title="Заказчик" subtitle={actDetail?.senderCompany.name} />
          <ListItem
            title="Исполнитель"
            subtitle={actDetail?.recipientCompany.name}
          />
          <ListItem title="Проект" subtitle={actDetail?.project?.name} />
          <ListItem
            title="Дата создания"
            subtitle={
              actDetail?.createdDate
                ? dateFormat(actDetail?.createdDate, "dd.MM.yyyy")
                : "—"
            }
          />
          <ListItem
            title="Дата выставления АВР"
            subtitle={
              actDetail?.date ? dateFormat(actDetail?.date, "dd.MM.yyyy") : "—"
            }
          />
          <ListItem
            title="Сумма"
            subtitle={priceFormat(actDetail?.totalSum.toString() || "")}
          />
        </Grid>
      </Header>
      <SignerListView act={actDetail} signedPdf={actDetail?.signedFile} />
    </Modal>
  );
};

export default memo(ModalActDocDetail);
