import { APIResponse, PageableParams, PageableResponse } from "./interfaces";
import { GPS, GPSGeofenceList } from "@app/models";
import instance from "./instance";

export function getGps(
  params: PageableParams<GPS>
): Promise<PageableResponse<GPS>> {
  return instance()
    .get("/gps", {
      params,
    })
    .then((response) => response.data);
}

export function getGpsGeoFences(
  params: PageableParams<GPS> & {
    gpsId: string;
  }
): Promise<APIResponse<GPSGeofenceList[] | null>> {
  return instance()
    .get("/gps/geofences", {
      params,
    })
    .then((response) => response.data);
}
