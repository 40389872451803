import { PageableParams, PageableResponse } from "./interfaces";
import { WorkShift } from "@app/models";
import instance from "./instance";

export function getWorkShifts(
  params: PageableParams<WorkShift>
): Promise<PageableResponse<WorkShift>> {
  return instance()
    .get("/workShift/short", {
      params,
    })
    .then((response) => response.data);
}
