import React, { useCallback, useMemo, useState } from "react";
import {
  Chip,
  Grid,
  Modal,
  ModalProps,
  Select,
  SelectOption,
} from "@app/components";
import styled, { css } from "styled-components";
import {
  KIPProject,
  KIPServiceType,
  KIPUnits,
  KIPVehicle,
  KIPVehicleDetails,
} from "@app/models";
import { capitalize, priceFormat } from "@app/helpers";
import { switchProp, theme } from "styled-tools";
import { VehicleTimelineStatistics } from "@app/common";
import { ModalKIPVehicleTrack } from "../index";

interface Props extends Pick<ModalProps, "onClose" | "open"> {
  vehicle: KIPVehicle | null;
  projectId?: KIPProject["id"];
}

const StyledContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 16px;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
`;

const StyledFilterRow = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 176px;
  align-items: center;
`;

const StyledChipWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px;
`;

const StyledChip = styled.div<{ active: boolean }>`
  border-radius: 56px;
  background-color: ${(props) =>
    props.active ? theme("color.yellow") : theme("color.white")};
  border-color: ${(props) =>
    props.active ? theme("color.orange") : theme("color.gray")};
  border-width: 1px;
  border-style: solid;
  padding: 4px 8px;
  cursor: pointer;

  span {
    color: ${(props) =>
      props.active ? theme("color.dark") : theme("color.grayDark")};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
`;

const StyledInfo = styled.div`
  max-width: 185px;
  .secondary {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: ${theme("color.grayDark")};
  }

  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const FooterInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
`;

const FooterInfoText = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${theme("color.dark")};
`;

const FooterInfoBox = styled.div<{
  variant: "dark" | "light" | "yellow";
}>`
  width: 24px;
  height: 24px;
  border-radius: 4px;

  ${switchProp("variant", {
    dark: css`
      background-color: #066478;
    `,
    light: css`
      background-color: #dedb80;
    `,
    yellow: css`
      background-color: ${theme("color.greenDark")};
    `,
  })}
`;

// Элемент по умолчанию для списка ед.изм.
const defaultUnitItem = {
  label: "Все",
  value: null,
  item: null,
};

export default function (props: Props) {
  const { onClose, open, vehicle, projectId } = props;
  const [vehicleDetails, setVehicleDetails] =
    useState<KIPVehicleDetails | null>(null);
  const [serviceTypeId, setServiceTypeId] = useState<
    KIPServiceType["id"] | null
  >(null);
  const [unit, setUnit] = useState<SelectOption<null> | null>(defaultUnitItem);

  const showTrack = useCallback((data: KIPVehicleDetails) => {
    setVehicleDetails(data);
  }, []);

  const onCloseTrackModal = useCallback(() => {
    setVehicleDetails(null);
  }, []);

  const getHoursGps = useCallback((hoursGps: string) => {
    if (!hoursGps) {
      return "-";
    }

    const hoursGpsSplited = hoursGps.split(".");

    if (hoursGpsSplited.length === 1) {
      return hoursGps;
    }

    return `${hoursGpsSplited[0]}д. ${hoursGpsSplited[1]}`;
  }, []);

  const modalTitle = useMemo(
    () => (!!vehicle ? vehicle.vehicleGovNumber : ""),
    [vehicle]
  );

  const onChangeUnit = useCallback(
    (value: SelectOption<null> | null, _: any) => {
      setUnit(value);
    },
    []
  );

  const onChangeServiceType = useCallback(
    (id?: string) => () => {
      // Сброс ед.изм, т.к. оно релевантно к Виду услуги
      setUnit(null);
      setServiceTypeId(id || null);
    },
    []
  );

  const compareServiceTypes = useCallback(
    (st: KIPServiceType) => st.id === serviceTypeId,
    [serviceTypeId]
  );

  const compareUnits = useCallback(
    (_unit: KIPUnits) => _unit.id === unit?.value,
    [unit]
  );

  const serviceTypes = useMemo(() => {
    return vehicle?.serviceTypes.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  }, [vehicle?.serviceTypes]);

  const unitList: SelectOption<null>[] = useMemo(() => {
    let units = vehicle?.units;

    if (serviceTypeId) {
      units = vehicle?.serviceTypes.find(compareServiceTypes)?.units;
    }

    if (!units) {
      return [defaultUnitItem];
    }

    const options = units.map((_unit) => ({
      label: capitalize(_unit.name),
      value: _unit.id,
      item: null,
    }));

    return [defaultUnitItem, ...options];
  }, [
    compareServiceTypes,
    serviceTypeId,
    vehicle?.serviceTypes,
    vehicle?.units,
  ]);

  // Динамический список техник зависящий от единиц измерений и вида услуг
  const vehicleDates = useMemo(() => {
    if (serviceTypeId && unit?.value) {
      const findServiceType = vehicle?.serviceTypes.find(compareServiceTypes);

      const findUnit = findServiceType?.units.find(compareUnits);

      return findUnit?.dates || [];
    }

    if (unit?.value) {
      const findUnit = vehicle?.units.find(compareUnits);
      return findUnit?.dates || [];
    }

    if (serviceTypeId) {
      return vehicle?.serviceTypes.find(compareServiceTypes)?.dates || [];
    }

    return vehicle?.dates || [];
  }, [
    compareServiceTypes,
    compareUnits,
    serviceTypeId,
    unit,
    vehicle?.dates,
    vehicle?.serviceTypes,
    vehicle?.units,
  ]);

  const headerContent = useMemo(() => {
    if (!vehicle) {
      return null;
    }

    return (
      <StyledHeader>
        <StyledInfo>
          <p title={vehicle.vehicleTypeName}>{vehicle.vehicleTypeName}</p>
          <p className="secondary">Вид техники</p>
        </StyledInfo>
        <StyledInfo>
          <p title={vehicle.characteristicsName}>
            {vehicle.characteristicsName}
          </p>
          <p className="secondary">Характеристика</p>
        </StyledInfo>
        <StyledInfo>
          <p title={vehicle.partnerName}>{vehicle.partnerName}</p>
          <p className="secondary">Исполнитель</p>
        </StyledInfo>
        <StyledInfo>
          <p title={vehicle.mileageGps.toString()}>
            {vehicle.mileageGps.toString()}
          </p>
          <p className="secondary">Пробег</p>
        </StyledInfo>
        <StyledInfo>
          <Chip
            bold={true}
            color="info"
            text={priceFormat(vehicle.costAmount.toFixed(0))}
          />
          <p className="secondary">Сумма к оплате</p>
        </StyledInfo>
        <StyledInfo>
          <Chip
            bold={true}
            color="warning"
            text={vehicle.workLoad ? `${vehicle.workLoad.toFixed(0)} %` : "-"}
          />
          <p className="secondary">Загруженность</p>
        </StyledInfo>
        <StyledInfo>
          <Chip
            bold={true}
            color="danger"
            text={priceFormat(vehicle.unusedAmount.toFixed(0))}
          />
          <p className="secondary">Неосвоенный бюджет</p>
        </StyledInfo>
        <StyledInfo>
          <Chip
            bold={true}
            color="primaryDark"
            text={getHoursGps(vehicle.hoursGps)}
          />
          <p className="secondary">Моточасы</p>
        </StyledInfo>
      </StyledHeader>
    );
  }, [getHoursGps, vehicle]);

  const onModalClose = useCallback(() => {
    setUnit(defaultUnitItem);
    setServiceTypeId(null);
    onClose();
  }, [onClose]);

  if (!open || !vehicle) {
    return null;
  }

  return (
    <>
      <Modal
        title={modalTitle}
        onClose={onModalClose}
        open={open}
        headerContent={headerContent}
        customSize="776px"
      >
        <StyledContent>
          <StyledFilterRow>
            <StyledChipWrapper>
              <StyledChip
                active={!serviceTypeId}
                onClick={onChangeServiceType()}
              >
                <span>Все</span>
              </StyledChip>
              {serviceTypes?.map((serviceType) => (
                <StyledChip
                  key={serviceType.id}
                  active={serviceType.id === serviceTypeId}
                  onClick={onChangeServiceType(serviceType.id)}
                >
                  <span>{capitalize(serviceType.name)}</span>
                </StyledChip>
              ))}
            </StyledChipWrapper>
            <Select<any>
              label="Показать"
              name="unit"
              onChange={onChangeUnit}
              value={unit}
              valueKey="id"
              labelKey="name"
              options={unitList}
            />
          </StyledFilterRow>
          <VehicleTimelineStatistics
            data={vehicleDates}
            showTrack={showTrack}
          />
          <Grid gap={24}>
            <Grid columns={3} gap={16}>
              <FooterInfo>
                <FooterInfoBox variant="yellow" />
                <FooterInfoText>Двигатель включен днем</FooterInfoText>
              </FooterInfo>
              <FooterInfo>
                <FooterInfoBox variant="dark" />
                <FooterInfoText>Двигатель включен ночью</FooterInfoText>
              </FooterInfo>
              <FooterInfo>
                <FooterInfoBox variant="light" />
                <FooterInfoText>Выключенный двигатель</FooterInfoText>
              </FooterInfo>
            </Grid>
          </Grid>
        </StyledContent>
      </Modal>
      <ModalKIPVehicleTrack
        open={!!vehicleDetails}
        projectId={projectId}
        vehicleDetails={vehicleDetails}
        vehicleId={vehicle.id}
        vehicleGovNumber={vehicle.vehicleGovNumber}
        onClose={onCloseTrackModal}
      />
    </>
  );
}
