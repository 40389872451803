import { SmartContractStatus, SmartContractStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: SmartContractStatus;
}

const getStatusVariant = (status: SmartContractStatus): ChipColor => {
  switch (status) {
    case SmartContractStatus.Draft:
      return "default";
    case SmartContractStatus.Singing:
      return "warning";
    case SmartContractStatus.PartnerSigned:
      return "warning";
    case SmartContractStatus.CustomerSigned:
      return "warning";
    case SmartContractStatus.Signed:
      return "success";
    case SmartContractStatus.Rejected:
      return "danger";
    case SmartContractStatus.Revoked:
      return "danger";
    default:
      return "default";
  }
};

function SmartContractStatusChip(props: Props) {
  const { status } = props;

  return (
    <Chip
      text={SmartContractStatusName[status]}
      color={getStatusVariant(status)}
    />
  );
}

export default memo(SmartContractStatusChip);
