import React, {
  ComponentType,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Grid,
  Loader,
  ModalProps,
  Pagination,
  Table,
  Toolbar,
  ToolbarProps,
} from "@app/components";
import { APIResponse, PageableParams, PageableResponse } from "@app/api";
import {
  addUrlParam,
  genericMemo,
  getUrlParams,
  updateUrlParams,
} from "@app/helpers";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";
import { useEventEmitter } from "@app/providers";

export type SearchTableData = (string | ReactNode)[];

export interface TemplateSearchModalDetail<T>
  extends Pick<ModalProps, "open" | "onClose"> {
  item: T | null;
  updateItem: (item: T | null) => void;
}

export interface FilterProps {
  filterParams?: Record<string, any>;
  filters?: ReactNode[];
  onClear?: () => void;
}

interface Props<T> {
  toolbarProps?: Exclude<
    ToolbarProps,
    "onSearch" | "onFilter" | "initialSearchText"
  >;
  filterProps?: FilterProps;
  getData: (params: PageableParams) => Promise<PageableResponse<T>>;
  mapTableData: (item: T) => SearchTableData;
  tableLabels: string[];
  onClick?: (data: T) => void;
  emptyText?: string;
  toolbarVisible?: boolean;
  searchBar?: boolean;
  shadow?: boolean;
  modalDetail?: ComponentType<TemplateSearchModalDetail<T>>;
  getItemDetailData?: (id: string) => Promise<APIResponse<T>>;
  refreshEventName?: string;
}

const StyledSearchTemplate = styled.div`
  height: 100%;
`;

const StyledContent = styled.div<
  Pick<Props<unknown>, "shadow" | "toolbarVisible">
>`
  height: ${ifProp(
    "toolbarVisible",
    "calc(100% - 108px)",
    "calc(100% - 48px)"
  )};
  margin: 16px;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.shadow ? "0 2px 4px rgba(0, 0, 0, 0.08)" : "none"};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${theme("color.white")};
`;

const StyledPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${theme("color.white")};
  box-sizing: border-box;
  padding: 12px;
  margin-top: auto;
  margin-left: auto;
  width: 300px;
`;

const StyledBlock = styled.div`
  height: calc(100% - 72px);
  display: flex;
`;

const StyledEmptyText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.gray")};
  margin: auto;
  text-align: center;
`;

const StyledFiltersContainer = styled.div<{ show: boolean }>`
  box-sizing: border-box;
  background-color: ${theme("color.white")};
  border-top: 1px solid ${theme("color.grayLight")};
  padding: ${(props) => `${props.show ? "16px 16px 24px" : "0 16px"}`};
  visibility: ${(props) => `${props.show ? "visible" : "hidden"}`};
  height: ${(props) => `${props.show ? "auto" : 0}`};
  opacity: ${(props) => `${props.show ? 1 : 0}`};
  overflow: ${(props) => `${props.show ? "inherit" : "hidden"}`};
  transition: height 0.3s ease-in-out, padding 0.7s, opacity 0.4s;
`;

function TemplateSearch<T extends { id: string }>(props: Props<T>) {
  const initialSearchParams = getUrlParams<{
    page: number;
    perPage: number;
    searchText: string;
    id: string;
  }>(["page", "perPage", "searchText", "id"]);

  const {
    toolbarVisible = true,
    toolbarProps = {
      searchPlaceholder: "Поиск",
    },
    filterProps,
    tableLabels,
    getData: getDataProps,
    getItemDetailData,
    mapTableData,
    onClick,
    emptyText = "Нет данных",
    searchBar = true,
    shadow = true,
    modalDetail: ModalDetail,
    refreshEventName,
  } = props;
  const { addEventListener, removeEventListener } = useEventEmitter();
  const [pending, setPending] = useState<boolean>(true);
  const [item, setItem] = useState<T | null>(null);
  const [items, setItems] = useState<T[]>([]);
  const [searchText, setSearchText] = useState<string>(
    initialSearchParams.searchText || ""
  );
  const [selectedId] = useState<string>(initialSearchParams.id || "");
  const [page, setPage] = useState<number>(
    Number(initialSearchParams.page) || 1
  );
  const [totalCount, setTotalCount] = useState(0);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const perPage = useMemo<number>(
    () => initialSearchParams.perPage || 20,
    [initialSearchParams.perPage]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => items.map(mapTableData),
    [items, mapTableData]
  );

  const hasData = useMemo(() => tableData.length > 0, [tableData]);

  const hasFilterData = useMemo(
    () => Object.values(filterProps?.filterParams || {}).some((v) => v?.length),
    [filterProps?.filterParams]
  );

  useEffect(() => {
    setPage(1);
  }, [filterProps?.filterParams]);

  const getItemData = useCallback(async () => {
    if (getItemDetailData && selectedId) {
      const response = await getItemDetailData(selectedId);
      setItem(response.data);
    }
  }, [getItemDetailData, selectedId]);

  useEffect(() => {
    getItemData();
  }, [getItemData]);

  const getData = useCallback(async () => {
    try {
      let params: PageableParams = {
        pageNumber: page,
        pageSize: perPage,
      };

      if (!!searchText) {
        params.searchText = searchText;
      }

      if (filterProps?.filterParams) {
        params = { ...params, ...filterProps.filterParams };
      }

      updateUrlParams({
        page,
        perPage,
        searchText,
        id: selectedId,
        ...filterProps?.filterParams,
      });

      setItems([]);
      setPending(true);

      const response = await getDataProps!(params);

      setItems(response.data);
      setTotalCount(response.recordsFiltered);
      setPending(false);
    } catch (e) {
      console.log(e);
      setPending(false);
    }
  }, [
    filterProps?.filterParams,
    getDataProps,
    page,
    perPage,
    searchText,
    selectedId,
  ]);

  const onCloseDetailModal = useCallback(() => {
    setItem(null);
    addUrlParam(null);
  }, []);

  const updateItem = useCallback(
    async (updatedItem: T | null) => {
      if (updatedItem === null) {
        await getData();

        return;
      }

      setItems((prevItems) =>
        prevItems.map((item) => ({
          ...item,
          ...(item.id === updatedItem.id ? updatedItem : {}),
        }))
      );
    },
    [getData, setItems]
  );

  const onClickFilter = useCallback(() => {
    setShowFilters((prevState) => !prevState);
  }, []);

  const onFilterClear = useCallback(() => {
    if (filterProps?.onClear) {
      filterProps.onClear();
    }
  }, [filterProps]);

  const onClickItem = useCallback(
    (index: number) => {
      const selectedItem = items[index];

      if (!!ModalDetail) {
        setItem(selectedItem);
        if (!!getItemDetailData) {
          addUrlParam(selectedItem.id);
        }
        return;
      }

      if (!onClick) {
        return;
      }

      onClick(selectedItem);
    },
    [ModalDetail, getItemDetailData, items, onClick]
  );

  const onChangePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onSearch = useCallback((value: string) => {
    setSearchText(value);
    setPage(1);
  }, []);

  const refreshPage = useCallback(() => {
    if (page === 1) {
      getData();
    }
  }, [page, getData]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!refreshEventName) {
      return;
    }

    addEventListener(refreshEventName, refreshPage);

    return () => {
      removeEventListener(refreshEventName, refreshPage);
    };
  }, [refreshEventName, addEventListener, removeEventListener, refreshPage]);

  return (
    <StyledSearchTemplate>
      {toolbarVisible && (
        <Toolbar
          {...toolbarProps}
          onSearch={searchBar ? onSearch : undefined}
          hasFilters={hasFilterData}
          onFilter={onClickFilter}
          onFilterClear={onFilterClear}
          initialSearchText={searchText}
        />
      )}
      {filterProps?.filters && (
        <StyledFiltersContainer show={showFilters}>
          <Grid columns={5}>
            {filterProps.filters.map((filter, fIndex) => (
              <div key={fIndex.toString()}>{filter}</div>
            ))}
          </Grid>
        </StyledFiltersContainer>
      )}
      {pending ? (
        <StyledBlock>
          <Loader />
        </StyledBlock>
      ) : hasData ? (
        <StyledContent shadow={shadow} toolbarVisible={toolbarVisible}>
          <Table labels={tableLabels} onClick={onClickItem} data={tableData} />
          <StyledPagination>
            <Pagination
              onChange={onChangePage}
              pageSize={perPage}
              initialPage={page}
              totalCount={totalCount}
              siblingCount={2}
            />
          </StyledPagination>
          {!!ModalDetail && (
            <ModalDetail
              open={!!item}
              onClose={onCloseDetailModal}
              updateItem={updateItem}
              item={item}
            />
          )}
        </StyledContent>
      ) : (
        <StyledBlock>
          <StyledEmptyText>{emptyText}</StyledEmptyText>
        </StyledBlock>
      )}
    </StyledSearchTemplate>
  );
}

export default genericMemo(TemplateSearch);
