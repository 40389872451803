import React, { memo, useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNotification, useUser } from "@app/providers";
import { APIResponse, getApprovalHistory, getSmartContract } from "@app/api";
import {
  Approver,
  SmartContract,
  UserTaskStatuses,
  UserTaskStatusesName,
  UserTaskTypeName,
} from "@app/models";
import { Grid, ListItem } from "@app/components";
import { dateFormat, getAxiosErrorMessage, getFullName } from "@app/helpers";
import styled from "styled-components";
import { LayoutPrinting } from "@app/layouts";

const StyledTableHead = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 14px 12px;
  text-align: left;
`;

const PrintButton = styled.button`
  border: none;
  background: lightgray;
  outline: none;
  cursor: pointer;
`;

function PageApprovalHistoryPrinting() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useUser();

  const [pending, setPending] = useState<boolean>(true);
  const [list, setList] = useState<Approver[] | null>(null);
  const [contractDetail, setContractDetail] = useState<SmartContract | null>(
    null
  );

  const { showNotification } = useNotification();

  const tableLabels = ["Дата", "Задача", "Статус", "Комментарий", "ФИО"];

  const getHistory = useCallback(
    (id: string) => {
      getApprovalHistory(id)
        .then(({ succeeded, data }) => {
          setPending(false);

          if (!succeeded) {
            showNotification({
              message: "Возникла ошибка, попробуйте позднее",
              variant: "error",
            });
            return;
          }

          setList(data);
          setTimeout(() => {
            // window.print();
          }, 700);
        })
        .catch(() => {
          showNotification({
            message: "Error",
            variant: "error",
          });
          setPending(false);
        });
    },
    [showNotification]
  );

  const getContractDetail = useCallback(
    async (sourceId: string) => {
      setPending(true);
      try {
        const response = await getSmartContract(sourceId);
        setContractDetail(response.data);
        setPending(false);
      } catch (e) {
        setPending(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  useEffect(() => {
    if (params.contractId) {
      getContractDetail(params.contractId).then(() => {
        getHistory(params.contractId!);
      });
    } else {
      navigate("/");
    }
  }, [
    authenticated,
    getContractDetail,
    getHistory,
    location,
    navigate,
    params,
  ]);

  return (
    <LayoutPrinting title={"Маршрут документа"}>
      <Grid columns={4}>
        <ListItem
          title={contractDetail?.customer.name}
          subtitle={"Организация"}
        />
        <ListItem title={contractDetail?.project?.name} subtitle={"Проект"} />
        <ListItem
          title={contractDetail?.partner?.name}
          subtitle={"Исполнитель"}
        />
        <ListItem title={contractDetail?.name} subtitle={"Договор"} />
      </Grid>

      {/* SIGNERS */}
      <h3>Лица согласования</h3>
      <table>
        <thead>
          <tr>
            {tableLabels.map((label, labelIndex) => (
              <StyledTableHead key={labelIndex.toString()}>
                {label}
              </StyledTableHead>
            ))}
          </tr>
        </thead>
        <tbody>
          {(list || []).map((item, index) => (
            <tr key={index.toString()}>
              <td>
                {item.status !== UserTaskStatuses.ToBeAgreed
                  ? `${dateFormat(item.date, "dd.MM.yyyy HH:mm")}`
                  : "–"}
              </td>
              <td>{UserTaskTypeName[item.taskTypeId]}</td>
              <td>{UserTaskStatusesName[item.status]}</td>
              <td>{item.comment}</td>
              <td>{getFullName(item.user)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <PrintButton onClick={() => window.print()}>Печать</PrintButton>
    </LayoutPrinting>
  );
}

export default memo(PageApprovalHistoryPrinting);
