import { SmartContractStatus, SmartContractStatusName } from "@app/models";
import { ChipColor } from "@app/components";
import React, { memo, PropsWithChildren } from "react";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

interface Props extends PropsWithChildren {
  status: SmartContractStatus;
  title?: string | null;
  subtitle?: string | null;
}

interface BadgeProps {
  color: string;
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledBadge = styled.div<BadgeProps>`
  width: 12px;
  height: 12px;
  min-width: 12px;
  border-radius: 6px;

  cursor: pointer;

  background-color: ${switchProp("color", {
    default: theme("color.grayLight"),
    primary: theme("color.blue"),
    primaryDark: theme("color.dark"),
    info: theme("color.blueGreen"),
    success: theme("color.green"),
    danger: theme("color.red"),
    warning: theme("color.yellow"),
  })};
`;

const StyledInfo = styled.div`
  max-width: 250px;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${theme("color.grayDark")};
  }
`;

const getStatusVariant = (status: SmartContractStatus): ChipColor => {
  switch (status) {
    case SmartContractStatus.Draft:
      return "default";
    case SmartContractStatus.Singing:
    case SmartContractStatus.CustomerSigned:
    case SmartContractStatus.PartnerSigned:
      return "warning";
    case SmartContractStatus.Signed:
      return "success";
    case SmartContractStatus.Rejected:
    case SmartContractStatus.Revoked:
    case SmartContractStatus.RejectedApproval:
      return "danger";
    default:
      return "default";
  }
};

function SmartContractStatusBadge(props: Props) {
  const { status, title = "—", subtitle, children } = props;

  return (
    <StyledWrapper>
      <StyledBadge
        title={SmartContractStatusName[status] || "Статус отсутствует"}
        color={getStatusVariant(status)}
      />
      {children || (
        <StyledInfo>
          <p title={title || ""}>{title}</p>
          {subtitle && <p className={"subtitle"}>{subtitle || "—"}</p>}
        </StyledInfo>
      )}
    </StyledWrapper>
  );
}

export default memo(SmartContractStatusBadge);
