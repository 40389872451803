let webSocket = null;
let callback = null;
export function initializeLayer() {
  webSocket = new WebSocket("wss://127.0.0.1:13579/");
  callback = null;
  webSocket.onopen = function (event) {
    console.log("Connection opened");
  };

  webSocket.onclose = function (event) {
    if (event.wasClean) {
      console.log("connection has been closed");
    } else {
      console.log("Connection error");
      openDialog();
    }
    // console.log('Code: ' + event.code + ' Reason: ' + event.reason);
  };

  webSocket.onmessage = function (event) {
    console.log({ event });
    const result = JSON.parse(event.data);

    if (result != null) {
      const rw = {
        code: result["code"],
        message: result["message"],
        responseObject: result["responseObject"],
        getResult: function () {
          return this.result;
        },
        getMessage: function () {
          return this.message;
        },
        getResponseObject: function () {
          return this.responseObject;
        },
        getCode: function () {
          return this.code;
        },
      };
      if (callback != null) {
        callback(rw);
      }
    }
    // console.log(event);
  };
}
export function destroyLayer() {
  webSocket.close();
  webSocket = null;
  callback = null;
}
export function checkWebsocket() {
  return !!webSocket;
}

export function openDialog(reload = true) {
  if (
    window.confirm(
      "Ошибка при подключении к NCALayer. Запустите NCALayer и нажмите ОК"
    ) === true
  ) {
    if (reload) window.location.reload();
  }
}

function unblockScreen() {
  // $.unblockUI();
}

function getActiveTokens(callBack) {
  const getActiveTokens = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "getActiveTokens",
  };
  callback = callBack;
  webSocket.send(JSON.stringify(getActiveTokens));
}

export function getKeyInfo(storageName, callBack) {
  const getKeyInfo = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "getKeyInfo",
    args: [storageName],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(getKeyInfo));
}

export function getKeyInfoBack(result) {
  unblockScreen();
  if (result["code"] === "500") {
    alert(result["message"]);
  } else if (result["code"] === "200") {
    const res = result["responseObject"];
    console.log({ res });
    /*const alias = res["alias"];
    $("#alias").val(alias);

    const keyId = res["keyId"];
    $("#keyId").val(keyId);

    const algorithm = res["algorithm"];
    $("#algorithm").val(algorithm);

    const subjectCn = res["subjectCn"];
    $("#subjectCn").val(subjectCn);

    const subjectDn = res["subjectDn"];
    $("#subjectDn").val(subjectDn);

    const issuerCn = res["issuerCn"];
    $("#issuerCn").val(issuerCn);

    const issuerDn = res["issuerDn"];
    $("#issuerDn").val(issuerDn);

    const serialNumber = res["serialNumber"];
    $("#serialNumber").val(serialNumber);

    const dateString = res["certNotAfter"];
    const date = new Date(Number(dateString));
    $("#notafter").val(date.toLocaleString());

    dateString = res["certNotBefore"];
    date = new Date(Number(dateString));
    $("#notbefore").val(date.toLocaleString());

    const authorityKeyIdentifier = res["authorityKeyIdentifier"];
    $("#authorityKeyIdentifier").val(authorityKeyIdentifier);

    const pem = res["pem"];
    $("#pem").val(pem);*/
  }
}

export function signXml(storageName, keyType, xmlToSign, callBack) {
  const signXml = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "signXml",
    args: [storageName, keyType, xmlToSign, "", ""],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(signXml));
}

function signXmls(storageName, keyType, xmlsToSign, callBack) {
  const signXmls = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "signXmls",
    args: [storageName, keyType, xmlsToSign, "", ""],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(signXmls));
}

function createCAdESFromFile(storageName, keyType, filePath, flag, callBack) {
  const createCAdESFromFile = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "createCAdESFromFile",
    args: [storageName, keyType, filePath, flag],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(createCAdESFromFile));
}

export function createCAdESFromBase64(
  storageName,
  keyType,
  base64ToSign,
  flag,
  callBack
) {
  const createCAdESFromBase64 = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "createCAdESFromBase64",
    args: [storageName, keyType, base64ToSign, flag],
  };
  console.log(createCAdESFromBase64);
  callback = callBack;
  webSocket.send(JSON.stringify(createCAdESFromBase64));
}

export function createCAdESFromBase64Hash(
  storageName,
  keyType,
  base64ToSign,
  callBack
) {
  const createCAdESFromBase64Hash = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "createCAdESFromBase64Hash",
    args: [storageName, keyType, base64ToSign],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(createCAdESFromBase64Hash));
}

export function applyCAdEST(storageName, keyType, cmsForTS, callBack) {
  const applyCAdEST = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "applyCAdEST",
    args: [storageName, keyType, cmsForTS],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(applyCAdEST));
}

function showFileChooser(fileExtension, currentDirectory, callBack) {
  const showFileChooser = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "showFileChooser",
    args: [fileExtension, currentDirectory],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(showFileChooser));
}

function changeLocale(language) {
  const changeLocale = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "changeLocale",
    args: [language],
  };
  callback = null;
  webSocket.send(JSON.stringify(changeLocale));
}

export function createCMSSignatureFromFile(
  storageName,
  keyType,
  filePath,
  flag,
  callBack
) {
  const createCMSSignatureFromFile = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "createCMSSignatureFromFile",
    args: [storageName, keyType, filePath, flag],
  };
  callback = callBack;
  webSocket.send(JSON.stringify(createCMSSignatureFromFile));
}

export function createCMSSignatureFromBase64(
  storageName,
  keyType,
  base64ToSign,
  flag,
  callBack
) {
  const createCMSSignatureFromBase64 = {
    module: "kz.gov.pki.knca.commonUtils",
    method: "createCMSSignatureFromBase64",
    args: [storageName, keyType, base64ToSign, flag],
  };
  console.log(JSON.stringify(createCMSSignatureFromBase64));
  callback = callBack;
  webSocket.send(JSON.stringify(createCMSSignatureFromBase64));
}
