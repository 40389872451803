import React from "react";
import { useParams } from "react-router-dom";

const PageVideoPlayer: React.FC = () => {
  const { videoSrc } = useParams<"videoSrc">();

  if (!videoSrc) {
    return <div>Видео не найдено.</div>;
  }

  const videoUrl = decodeURIComponent(videoSrc);

  return (
    <video
      src={videoUrl}
      controls
      controlsList="nodownload"
      style={{ width: "100%" }}
    />
  );
};

export default PageVideoPlayer;
