import { Info } from "@app/components";
import React, { FC } from "react";
import { VehicleInfo } from "@app/models";
import { getDictionaryName } from "@app/helpers";
import styled from "styled-components";
import { theme } from "styled-tools";
import { CompanyStatusChip } from "@app/common";

type Props = {
  vehicle: VehicleInfo | null;
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding: 16px;
`;

const StyledCompanies = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  color: ${theme("color.gray")};
`;

const StyledCompanyName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;
  background: ${theme("color.blueLight")};
  color: ${theme("color.dark")};
  border-radius: 8px;
`;

export const VehicleDetailView: FC<Props> = (props) => {
  const { vehicle } = props;

  if (!vehicle) {
    return null;
  }

  return (
    <StyledGrid>
      <StyledCompanies>
        <p>Компании</p>
        {vehicle.companies.map(({ company, comment }) => (
          <StyledCompanyName>
            <span>{company.name}</span>
            <CompanyStatusChip
              status={company.status}
              comment={comment ?? "—"}
            />
          </StyledCompanyName>
        ))}
      </StyledCompanies>
      <Info label="Вид техники" value={getDictionaryName(vehicle.type)} />
      <Info label="Модель" value={getDictionaryName(vehicle.model, "—")} />
      <Info label="Гос номер" value={vehicle.govNumber ?? "—"} />
      <Info label="Провайдер GPS" value={getDictionaryName(vehicle.gps, "—")} />
      <Info
        label="Характеристика"
        value={getDictionaryName(vehicle.characteristic)}
      />
    </StyledGrid>
  );
};
