import React, { useCallback, useMemo } from "react";
import { KIPVehicleDetailRPMS, KIPVehicleDetails } from "@app/models";
import styled, { css } from "styled-components";
import { ifProp, switchProp, theme } from "styled-tools";
import { dateFormat } from "@app/helpers";

interface Props
  extends Pick<KIPVehicleDetails, "workStartDateTime" | "workEndDateTime"> {
  data: KIPVehicleDetailRPMS[];
  type: string;
  variant: "full" | "info";
}

const StyledShiftTime = styled.div<Pick<Props, "variant">>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${switchProp("variant", {
    full: css`
      gap: 8px;
    `,
    info: css`
      gap: 2px;
    `,
  })}
`;

const StyledHour = styled.div<
  Pick<Props, "variant"> & {
    minutes: number;
    type: "day" | "night";
  }
>`
  width: 32px;
  height: 24px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(224, 222, 153, 0.7);
  overflow: hidden;
  display: inline-flex;

  ${switchProp("variant", {
    full: css`
      width: 32px;
      height: 24px;
    `,
    info: css`
      width: 30px;
      height: 16px;
    `,
  })}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => (props.minutes / 60) * 100}%;
    background-color: ${ifProp(
      {
        type: "day",
      },
      theme("color.greenDark"),
      theme("color.dark")
    )};
  }

  span {
    position: relative;

    color: ${theme("color.dark")};
    margin: auto;

    ${switchProp("variant", {
      full: css`
        font-size: 10px;
      `,
      info: css`
        font-size: 8px;
      `,
    })}
  }
`;

export default function (props: Props) {
  const { data, type, variant, workStartDateTime, workEndDateTime } = props;

  const dataToRender = useMemo<
    (KIPVehicleDetailRPMS & {
      time: string;
    })[]
  >(() => {
    if (data.length > 0) {
      return data.map((item, itemIndex) => ({
        ...item,
        time:
          itemIndex === 0
            ? dateFormat(workStartDateTime, "HH:mm")
            : itemIndex === data.length - 1
            ? dateFormat(workEndDateTime, "HH:mm")
            : "",
      }));
    }

    return [...Array(12).keys()].map((_item, itemIndex) => ({
      time:
        itemIndex === 0
          ? dateFormat(workStartDateTime, "HH:mm")
          : itemIndex === 11
          ? dateFormat(workEndDateTime, "HH:mm")
          : "",
      totalMinutes: 0,
      index: itemIndex,
    }));
  }, [data, workEndDateTime, workStartDateTime]);

  const getType = useCallback(
    (index: number) => {
      if (type === "1") {
        return "day";
      }

      if (type === "2") {
        return "night";
      }

      return index < 12 ? "day" : "night";
    },
    [type]
  );

  return (
    <StyledShiftTime variant={variant}>
      {dataToRender.map((rpms, rpmsIndex) => (
        <StyledHour
          variant={variant}
          key={rpmsIndex.toString()}
          minutes={rpms.totalMinutes}
          type={getType(rpmsIndex)}
        >
          <span>{rpms.time}</span>
        </StyledHour>
      ))}
    </StyledShiftTime>
  );
}
