import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/index.scss";
import reportWebVitals from "./reportWebVitals";
import Router from "./Router";
import NotificationProvider from "./providers/Notification";
import {
  CookiePolicyProvider,
  EventEmitter,
  UserProvider,
} from "@app/providers";
import ThemeProvider from "./ThemeProvider";
import { ClearCacheProvider } from "react-clear-cache";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ClearCacheProvider>
    <ThemeProvider>
      <NotificationProvider>
        <UserProvider>
          <EventEmitter>
            <CookiePolicyProvider>
              <Router />
            </CookiePolicyProvider>
          </EventEmitter>
        </UserProvider>
      </NotificationProvider>
    </ThemeProvider>
  </ClearCacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
