import React, { memo, useCallback } from "react";
import { Modal, ModalProps } from "@app/components";
import { content } from "./content";
import styled from "styled-components";

interface Props extends Omit<ModalProps, "title"> {}

const StyledContent = styled.div`
  height: 400px;
`;

const ModalVehicleInformation = (props: Props) => {
  const { open, onClose } = props;

  const onModalClose = useCallback(() => {
    if (!!onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Modal
      open={open}
      title={""}
      position="center"
      size="medium"
      hideHeader
      contentStyle={{ padding: "32px" }}
      onClose={onModalClose}
    >
      <StyledContent>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </StyledContent>
    </Modal>
  );
};

export default memo(ModalVehicleInformation);
