import React, { memo, useCallback } from "react";
import {
  FilesPicker,
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import { Company, CompanyType, NDS, NdsShort } from "@app/models";
import {
  APIResponse,
  CompanyCreateUpdateDTO,
  createCompany,
  getCompanyTypes,
  getNds,
  updateCompany,
} from "@app/api";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";

interface Props {
  onSuccess: () => void;
  company: Company | null;
}

interface Data {
  type: SelectOption<CompanyType> | null;
  name: string;
  bin: string;
  nds: SelectOption<NdsShort> | null;
  files: File[];
}

const schema = yup.object().shape({
  type: yup.object().nullable().required(),
  bin: yup.string().required(),
  name: yup.string().required(),
  nds: yup.object().nullable().required(),
});

function FormCompany(props: Props) {
  const { onSuccess, company } = props;
  const { showNotification } = useNotification();
  const { pending, onChange, values, errors, validate, setPending } =
    useForm<Data>({
      values: {
        type: company
          ? {
              label: company.companyType.name,
              value: company.companyType.id,
              item: company.companyType,
            }
          : null,
        name: company ? company.name : "",
        bin: company ? company.bin : "",
        nds: company
          ? {
              label: company.nds.name,
              value: company.nds.id,
              item: company.nds,
            }
          : null,
        files: [],
      },
      schema,
    });

  const onChangeFiles = useCallback(
    (files: File[]) => {
      onChange(files, "files");
    },
    [onChange]
  );

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const companyData: CompanyCreateUpdateDTO = {
        typeId: values.type!.value,
        name: values.name,
        bin: values.bin,
        ndsId: values.nds!.value,
      };

      const response = !!company
        ? await updateCompany({
            ...companyData,
            id: company.id,
          })
        : await createCompany(companyData);

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: !!company
          ? "Заявка на редактирование организации отправлена на модерацию"
          : "Заявка на добавление отправлена на модерацию",
        variant: "success",
      });

      onSuccess();
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [validate, setPending, values, company, showNotification, onSuccess]);

  return (
    <Form
      submitLabel="Отправить на модерацию"
      onSubmit={onSubmitForm}
      pending={pending}
    >
      <Grid>
        <Select<CompanyType>
          label="Форма организации"
          name="type"
          onChange={onChange}
          value={values.type}
          error={!!errors.type}
          helperText={errors.type}
          valueKey="id"
          labelKey="name"
          loadData={getCompanyTypes}
        />
        <TextField
          label="Наименование организации"
          placeholder="Введите наименование"
          name="name"
          onChange={onChange}
          value={values.name}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="ИИН/БИН"
          placeholder="Введите ИИН/БИН"
          name="bin"
          onChange={onChange}
          value={values.bin}
          error={!!errors.bin}
          helperText={errors.bin}
        />
        <Select<NdsShort | NDS>
          label="Ставка НДС"
          name="nds"
          onChange={onChange}
          value={values.nds}
          error={!!errors.nds}
          helperText={errors.nds}
          valueKey="id"
          labelKey="name"
          loadData={getNds}
        />
        <FilesPicker files={values.files} onChange={onChangeFiles} />
      </Grid>
    </Form>
  );
}

export default memo(FormCompany);
