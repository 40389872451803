import React, { memo } from "react";
import { LayoutLanding } from "@app/layouts";
import {
  SectionGPS,
  SectionInfo,
  SectionIntegration,
  SectionPartners,
  SectionPossibilities,
  SectionUsage,
  SectionWelcome,
} from "./components";

interface Props {}

function PageLading(props: Props) {
  return (
    <LayoutLanding>
      <SectionWelcome />
      <SectionInfo />
      <SectionPossibilities />
      <SectionGPS />
      <SectionIntegration />
      <SectionUsage />
      <SectionPartners />
    </LayoutLanding>
  );
}

export default memo(PageLading);
