import React, { memo } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import styled from "styled-components";
import BGSrc from "./bg.png";
import ImageVehAccounting from "./veh-accounting.png";
import content from "./content.json";
import { Grid } from "@app/components";

const StyledPanelVehicleAccounting = styled(StyledPanel)`
  @media only screen and (max-width: 576px) {
    background: none;
  }
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;
  height: 480px;
  background-image: url(${BGSrc});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;

  @media only screen and (max-width: 576px) {
    height: auto;
  }
`;

function PanelVehicleAccounting() {
  return (
    <StyledPanelVehicleAccounting>
      <Grid gap={12}>
        <StyledPanelTitle>{content.title}</StyledPanelTitle>
        <StyledPanelText>{content.text}</StyledPanelText>
        <StyledPanelImageMobile src={ImageVehAccounting} width={343} alt="" />
      </Grid>
    </StyledPanelVehicleAccounting>
  );
}

export default memo(PanelVehicleAccounting);
