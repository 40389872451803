import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUser } from "@app/providers";

const PageVideoPlayer: React.FC = () => {
  const { videoSrc } = useParams<"videoSrc">();
  const location = useLocation();
  const navigate = useNavigate();
  const { authenticated } = useUser();

  const isSignUpPage = useMemo<boolean>(
    () => location.pathname.startsWith("/sign-up"),
    [location.pathname]
  );

  useEffect(() => {
    if (!authenticated && !isSignUpPage) {
      navigate("/sign-in");
    }
  }, [authenticated, navigate, isSignUpPage]);

  if (!authenticated && !isSignUpPage) {
    return null;
  }

  if (!videoSrc) {
    return <div>Видео не найдено.</div>;
  }

  const videoUrl = decodeURIComponent(videoSrc);

  return (
    <video
      src={videoUrl}
      controls
      controlsList="nodownload"
      style={{ width: "100%" }}
    />
  );
};

export default PageVideoPlayer;
