import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Company,
  Confirmer,
  DocumentType,
  Project,
  SmartContractStatus,
  SmartContractStatusName,
  Task,
  UserTaskStatuses,
  UserTaskStatusesName,
  UserTaskType,
} from "@app/models";
import {
  companyLabelKeys,
  dateFormat,
  enumToArray,
  getDateFilterValue,
  getFilterValues,
  getFullName,
  isDispatcher,
} from "@app/helpers";
import { TemplateSearch } from "@app/templates";
import {
  getCompanies,
  getCompanyEmployeesShort,
  getDispatchers,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getTasks,
  PageableParams,
} from "@app/api";
import {
  ModalActTaskRedirect,
  ModalContractTaskApprove,
  ModalContractTaskRedirect,
  ModalContractTaskReject,
  ModalDocumentDetail,
} from "@app/modals";
import {
  Checkbox,
  Combobox,
  DateTimePicker,
  DefaultObject,
} from "@app/components";
import { useUser } from "@app/providers";
import { useDebounce } from "@app/hooks";

const FILTER_STORAGE_KEY = "contractsFilters";

interface Props {
  documentType: DocumentType;
}

interface CustomUser extends Confirmer {
  name: string;
}

interface FilterParamsProps {
  customer: Array<Company> | null;
  initiator: Array<CustomUser> | null;
  executor: Array<Company> | null;
  executorUser: Array<CustomUser> | null;
  project: Array<Project> | null;
  status: DefaultObject<UserTaskStatuses>[] | null;
  contractStatus: DefaultObject<SmartContractStatus>[] | null;
  startDate: Date | null;
  endDate: Date | null;
}

interface FilterValuesProps {
  CustomerIds: string[];
  InitiatorIds: string[];
  ExecutorIds: string[];
  ExecutorUserIds: string[];
  ProjectIds: string[];
  TaskStatusIds: (string | number)[];
  ContractStatusIds: (string | number)[];
  FromDate?: string;
  ToDate?: string;
}

const initialFiltersParams = JSON.stringify({
  initiator: null,
  customer: null,
  executor: null,
  executorUser: null,
  project: null,
  contractStatus: null,
  endDate: null,
  startDate: null,
  status: null,
});

function Contracts(props: Props) {
  const { documentType } = props;
  const { user } = useUser();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [redirectModalVisible, setRedirectModalVisible] =
    useState<boolean>(false);
  const [redirectContractModalVisible, setRedirectContractModalVisible] =
    useState<boolean>(false);
  const [showRejecting, setShowRejecting] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [taskSelected, setTaskSelected] = useState<Task | null>(null);
  const [loading, setLoading] = useState(false);
  const [onlyUnfinished, setOnlyUnfinished] = useState(true);
  const [initiators, setInitiators] = useState<CustomUser[]>([]);
  const [executors, setExecutors] = useState<CustomUser[]>([]);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const tableLabels = useMemo(
    () => [
      "Наименование задачи",
      "Статус договора",
      "Заказчик",
      "Проект",
      "Исполнитель/Контрагент",
      "Дата назначения",
      "Дата завершения",
      "Статус задачи",
      "Исполнитель задачи",
      "Инициатор",
    ],
    []
  );

  const mapTableData = useCallback((item: Task) => {
    return [
      item.name,
      item.statusMain && SmartContractStatusName[item.statusMain],
      item.customer?.name || "-",
      item.project.name,
      item.executor.name,
      dateFormat(item.dateStart, "dd.MM.yyyy"),
      item.dateFinish ? dateFormat(item.dateFinish, "dd.MM.yyyy") : "-",
      UserTaskStatusesName[item.status],
      getFullName(item.executorUser),
      getFullName(item.initiator),
    ];
  }, []);

  const onClickItem = useCallback((data: any) => {
    setTaskSelected(data);
    setModalVisible(true);
  }, []);

  const leftControls = useMemo(
    () => [
      <Checkbox
        label={"Только незакрытые задачи"}
        checked={onlyUnfinished}
        onChange={() => {
          setOnlyUnfinished((v) => !v);
        }}
      />,
    ],
    [onlyUnfinished]
  );

  const getData = useCallback(
    (params: PageableParams) => {
      return getTasks({
        ...params,
        forModuleId: documentType,
        isUnfinished: onlyUnfinished,
      });
    },
    [documentType, onlyUnfinished]
  );

  const closeAllModals = useCallback((closeDetail?: boolean) => {
    setRedirectContractModalVisible(false);
    setRedirectModalVisible(false);
    setShowApproveModal(false);
    setShowRejecting(false);

    if (closeDetail) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
    setTaskSelected(null);
    setModalVisible(false);
  }, []);

  useEffect(() => {
    getDispatchers().then(({ data }) => {
      const arr = data.map((user) => ({
        name: getFullName(user),
        ...user,
      }));
      setInitiators(arr);
    });
  }, []);

  useEffect(() => {
    const execIds = getFilterValues(filterParams.executor);
    const custIds = getFilterValues(filterParams.customer);

    if (execIds.length || custIds.length) {
      getCompanyEmployeesShort([...execIds, ...custIds]).then(({ data }) => {
        const arr = data.map(({ user }) => ({
          name: getFullName(user),
          ...user,
        }));

        setExecutors(arr);
      });
    } else {
      setExecutors([]);
      return;
    }
  }, [filterParams.customer, filterParams.executor]);

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        InitiatorIds: getFilterValues(filterParams.initiator),
        ExecutorIds: getFilterValues(filterParams.executor),
        ExecutorUserIds: getFilterValues(filterParams.executorUser),
        CustomerIds: getFilterValues(filterParams.customer),
        ProjectIds: getFilterValues(filterParams.project),
        TaskStatusIds: getFilterValues(filterParams.status),
        ContractStatusIds: getFilterValues(filterParams.contractStatus),
        FromDate: getDateFilterValue(filterParams.startDate),
        ToDate: getDateFilterValue(filterParams.endDate),
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox<Company>
        label="Заказчик"
        name="customer"
        onChange={onFilterChange}
        values={filterParams.customer}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={getCompanies}
      />,
      <Combobox<CustomUser>
        label="Инициатор"
        name="initiator"
        values={filterParams.initiator}
        options={initiators}
        onChange={onFilterChange}
      />,
      <Combobox<Company>
        label="Контрагент"
        name="executor"
        onChange={onFilterChange}
        values={filterParams.executor}
        valueKey="id"
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<CustomUser>
        label="Исполнитель"
        name="executorUser"
        onChange={onFilterChange}
        values={filterParams.executorUser}
        valueKey="id"
        labelKey="name"
        options={executors}
      />,
      <Combobox<Project>
        label="Проект"
        name="project"
        onChange={onFilterChange}
        values={filterParams.project}
        valueKey="id"
        labelKey="name"
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
      />,
      <Combobox<DefaultObject<UserTaskStatuses>>
        label="Статус задачи"
        name="status"
        values={filterParams.status}
        options={enumToArray(UserTaskStatusesName)}
        onChange={onFilterChange}
      />,
      <Combobox<DefaultObject<SmartContractStatus>>
        label="Статус договора"
        name="contractStatus"
        values={filterParams.contractStatus}
        options={enumToArray(SmartContractStatusName)}
        onChange={onFilterChange}
      />,
      <DateTimePicker
        label="Дата создания"
        name="startDate"
        onChange={onFilterChange}
        value={filterParams.startDate}
        hideTime
      />,
      <DateTimePicker
        label="Дата завершения"
        name="endDate"
        onChange={onFilterChange}
        value={filterParams.endDate}
        hideTime
      />,
    ],
    [executors, filterParams, initiators, onFilterChange, user]
  );

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <TemplateSearch
        toolbarProps={{
          onFilter: () => {},
          searchPlaceholder: "Наименование документа, организация, проект",
          leftControls,
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        getData={getData}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onClickItem}
      />
      <ModalDocumentDetail
        open={modalVisible}
        size="large"
        data={taskSelected}
        onUserRedirect={() => {
          if (taskSelected?.taskType === UserTaskType.ApprovalContract) {
            setRedirectContractModalVisible(true);
          } else {
            setRedirectModalVisible(true);
          }
        }}
        onApprove={() => setShowApproveModal(true)}
        onReject={() => setShowRejecting(true)}
        onClose={(val) => closeAllModals(val)}
      />
      <ModalActTaskRedirect
        open={redirectModalVisible}
        taskId={taskSelected?.id}
        companyId={taskSelected?.customer?.id}
        title={"Перенаправление задачи"}
        onClose={(val) => {
          if (val) {
            closeAllModals(true);
          } else {
            setRedirectModalVisible(false);
          }
        }}
      />
      <ModalContractTaskRedirect
        open={redirectContractModalVisible}
        taskId={taskSelected?.id}
        companyId={taskSelected?.customer?.id}
        title={"Перенаправление задачи (Договор)"}
        onClose={(val) => {
          if (val) {
            closeAllModals(true);
          } else {
            setRedirectContractModalVisible(false);
          }
        }}
      />
      <ModalContractTaskReject
        open={showRejecting}
        taskId={taskSelected?.id}
        title={"Отклонение договора"}
        onClose={(val) => closeAllModals(val)}
      />
      <ModalContractTaskApprove
        open={showApproveModal}
        taskId={taskSelected?.id}
        title={"Согласование договора"}
        onClose={(val) => closeAllModals(val)}
      />
    </>
  );
}

export default Contracts;
