import instance from "./instance";
import { APIResponse } from "./interfaces";
import { KIPCustomer, KIPDivision, KIPProject, KIPRegion } from "@app/models";

export interface GetKIPDTO {
  startDate: string;
  endDate: string;
  holdingId?: string;
  companyId?: string;
  regionId?: string;
  vehicleTypeId?: string;
  characteristicsId?: string;
  serviceTypeId?: string;
  projectId?: string;
  fromActs?: boolean;
}

export function getKip(params: GetKIPDTO): Promise<
  APIResponse<{
    divisions: KIPDivision[];
    projects: KIPProject[];
    regions: KIPRegion[];
    customers: KIPCustomer[];
  }>
> {
  return instance()
    .get("/vehicleUseCoefficient", {
      params,
    })
    .then((response) => response.data);
}
