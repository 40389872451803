import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader, Table } from "@app/components";
import {
  Approver,
  SmartContractHistory,
  UserTaskStatusesName,
  UserTaskTypeName,
} from "@app/models";
import styled from "styled-components";
import { dateFormat, getAxiosErrorMessage, getFullName } from "@app/helpers";
import { APIResponse, getApprovalHistory } from "@app/api";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";

type Props = {
  contractId?: string;
  histories?: any[];
  dense?: boolean;
};

const StyledContainer = styled.div<{ dense?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${(props) => (props.dense ? "16px 0" : "16px 24px")};
`;

const ApproveListView = (props: Props) => {
  const { contractId, histories, dense = true } = props;
  const { showNotification } = useNotification();
  const [signers, setSigners] = useState<Approver[]>([]);
  const [loading, setLoading] = useState(false);

  const getTableData = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        const response = await getApprovalHistory(id);
        setSigners(response.data);

        setLoading(false);
      } catch (e) {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  useEffect(() => {
    if (contractId) {
      getTableData(contractId).then(() => {});
    }
  }, [contractId, getTableData]);

  const sortedHistory = useMemo(() => {
    if (!histories) {
      return [];
    }
    return histories.sort(
      (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
    );
  }, [histories]);

  const tableLabels = useMemo(
    () => [
      "Вид задачи",
      "ФИО ответственного",
      "Результат",
      "Дата выполнения",
      "Комментарий",
    ],
    []
  );

  const tableLabelsHistory = useMemo(
    () => ["№", "Дата", "ФИО", "Действие", "Комментарий"],
    []
  );

  const mapTableData = useCallback((item: Approver) => {
    return [
      UserTaskTypeName[item.taskTypeId],
      getFullName(item.user),
      UserTaskStatusesName[item.status],
      item.date ? dateFormat(item.date, "dd.MM.yyyy") : "-",
      item.comment,
    ];
  }, []);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => signers.map(mapTableData),
    [signers, mapTableData]
  );

  const mapTableDataHistory = useCallback(
    (item: SmartContractHistory, index: number) => {
      const createdDate = item.created ? new Date(item.created) : null;
      if (createdDate) {
        createdDate.setHours(createdDate.getHours() + 5);
      }
      const formattedDate = createdDate
        ? dateFormat(createdDate, "dd.MM.yyyy HH:mm")
        : "-";
      return [
        index + 1,
        formattedDate,
        getFullName(item.user),
        item.text,
        item.comment,
      ];
    },
    []
  );

  const tableDataHistory = useMemo<(string | ReactNode)[][]>(
    () => sortedHistory.map(mapTableDataHistory),
    [sortedHistory, mapTableDataHistory]
  );

  if (loading) {
    return <Loader size={"small"} />;
  }

  if (histories) {
    return (
      <StyledContainer dense={dense}>
        <h6>История согласования</h6>
        {histories.length ? (
          <Table labels={tableLabelsHistory} data={tableDataHistory} />
        ) : (
          <p>Список пуст</p>
        )}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      {signers.length ? (
        <Table labels={tableLabels} data={tableData} />
      ) : (
        <p>Список пуст</p>
      )}
    </StyledContainer>
  );
};

export default memo(ApproveListView);
