import { ActStatus, ActStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: ActStatus;
}

const getStatusVariant = (status: ActStatus): ChipColor => {
  switch (status) {
    case ActStatus.Created:
      return "default";
    case ActStatus.ToBeAgreed:
      return "warning";
    case ActStatus.SentToAccounting:
      return "success";
    case ActStatus.Agreed:
      return "success";
    case ActStatus.ActSigningRejected:
    case ActStatus.ActSigningOverdue:
    case ActStatus.ActRevoked:
    case ActStatus.Rejected:
      return "danger";
    case ActStatus.SigningCounterparty:
      return "warning";
    case ActStatus.SigningProject:
      return "warning";
    case ActStatus.AwaitingUploadToBC:
      return "warning";
    case ActStatus.Deleted:
      return "danger";
    case ActStatus.AvrCreated:
      return "default";
    case ActStatus.AvrSigned:
      return "success";
    case ActStatus.AvrRevoked:
      return "danger";
    case ActStatus.AvrDeleted:
      return "danger";
    default:
      return "default";
  }
};

function ActStatusChip(props: Props) {
  const { status } = props;

  return <Chip text={ActStatusName[status]} color={getStatusVariant(status)} />;
}

export default memo(ActStatusChip);
