import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Checkbox, Grid, Paper, Table, TextField } from "@app/components";
import { BankAccount, Company, SmartContractSigner } from "@app/models";
import { getCompanyBankAccounts, getCompanySigners } from "@app/api";
import { getFullName } from "@app/helpers";

interface Props {
  signer: SmartContractSigner | null;
  bankAccount: BankAccount | null;
  company: Company;
  companyLabel: string;
  bankAccountLabel: string;
  requisitesLabel: string;
  onChangeBankAccount: (bankAccount: BankAccount) => void;
  onChangeSigner: (signer: SmartContractSigner) => void;
}

const StyledCompanyRequisites = styled.div`
  background-color: ${theme("color.white")};
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 480px;
  padding: 16px 24px;
  box-sizing: border-box;
  grid-auto-rows: max-content;
`;

const StyledHeading = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${theme("color.dark")};
`;

const StyledEmptyText = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${theme("color.gray")};
`;

interface DetailProps {
  company: Company;
  signer: SmartContractSigner | null;
  bankAccount: BankAccount | null;
}

function Detail(props: DetailProps) {
  const { company, signer, bankAccount } = props;

  return (
    <Paper bordered={true}>
      <Grid>
        <Grid columns={2}>
          <TextField label="Регион" disabled={true} />
          <TextField
            label="Организация"
            placeholder={company ? company.name : ""}
            disabled={true}
          />
        </Grid>
        <TextField
          label="ФИО подписанта"
          placeholder={!!signer ? getFullName(signer) : ""}
          disabled={true}
        />
        <TextField
          label="Основание"
          placeholder={!!signer ? signer.workBasis : ""}
          disabled={true}
        />
        <TextField
          label="Банковские реквизиты"
          placeholder={
            !!bankAccount
              ? `${bankAccount.accountNumber} - ${bankAccount.bank?.name}`
              : ""
          }
          multiline={true}
          disabled={true}
        />
        <TextField label="Адрес" multiline={true} disabled={true} />
        <TextField
          label="Контакты"
          placeholder={!!signer ? signer.phone || "" : ""}
          disabled={true}
        />
      </Grid>
    </Paper>
  );
}

function CompanyRequisites(props: Props) {
  const {
    company,
    signer,
    bankAccount,
    companyLabel,
    bankAccountLabel,
    requisitesLabel,
    onChangeBankAccount,
    onChangeSigner,
  } = props;
  const [pending, setPending] = useState<boolean>(true);
  const [signers, setSigners] = useState<SmartContractSigner[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

  const getData = useCallback(async () => {
    try {
      const [signersResponse, bankAccountsResponse] = await Promise.all([
        getCompanySigners(company.id),
        getCompanyBankAccounts(company.id),
      ]);

      if (signersResponse.succeeded) {
        setSigners(signersResponse.data);
      }

      if (bankAccountsResponse.succeeded) {
        setBankAccounts(bankAccountsResponse.data);
      }

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [company]);

  const companySignersTableData = useMemo(
    () =>
      signers.map((item) => [
        <Checkbox
          checked={!!signer && item.id === signer.id}
          onChange={() => onChangeSigner(item)}
        />,
        getFullName(item),
        item.workBasis || "-",
        "-",
      ]),
    [onChangeSigner, signer, signers]
  );

  const bankAccountsTableData = useMemo(
    () =>
      bankAccounts.map((item) => [
        <Checkbox
          checked={!!bankAccount && item.id === bankAccount.id}
          onChange={() => onChangeBankAccount(item)}
        />,
        item.accountNumber,
        item.bank?.name || "—",
      ]),
    [bankAccounts, bankAccount, onChangeBankAccount]
  );
  const companySignersTableLabels = useMemo<string[]>(
    () => ["", "Подписант", "Основание", "ФИО в родительном падеже"],
    []
  );

  const bankAccountTableLabels = useMemo<string[]>(
    () => ["", "Счет", "Банк"],
    []
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <StyledCompanyRequisites>
      <Grid gap={16}>
        {!pending && (
          <>
            <Grid gap={8}>
              <StyledHeading>{companyLabel}</StyledHeading>
              {signers.length > 0 ? (
                <Paper bordered={true} dense={true}>
                  <Table
                    labels={companySignersTableLabels}
                    data={companySignersTableData}
                  />
                </Paper>
              ) : (
                <StyledEmptyText>Нет данных</StyledEmptyText>
              )}
            </Grid>
            <Grid gap={8}>
              <StyledHeading>{bankAccountLabel}</StyledHeading>
              {bankAccounts.length > 0 ? (
                <Paper bordered={true} dense={true}>
                  <Table
                    labels={bankAccountTableLabels}
                    data={bankAccountsTableData}
                  />
                </Paper>
              ) : (
                <StyledEmptyText>Нет данных</StyledEmptyText>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid gap={8}>
        <StyledHeading>{requisitesLabel}</StyledHeading>
        <Detail company={company} bankAccount={bankAccount} signer={signer} />
      </Grid>
    </StyledCompanyRequisites>
  );
}

export default memo(CompanyRequisites);
