import React, { memo, useMemo } from "react";
import { Info } from "@app/components";
import { dateFormat, getDictionaryName, getFullName } from "@app/helpers";
import { VAERequest } from "@app/models";
import styled from "styled-components";
import { prop, switchProp, theme } from "styled-tools";

interface Props {
  request: VAERequest;
  variant?: "default" | "secondary";
  columns?: number;
}

const StyledVehicleRequestDetail = styled.div<
  Pick<Props, "variant" | "columns">
>`
  padding: 16px 24px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(${prop("columns")}, 1fr);
  background-color: ${switchProp("variant", {
    default: theme("color.white"),
    secondary: "#F5F6F8",
  })};
`;

function VAERequestDetail(props: Props) {
  const { request, variant = "default", columns = 1 } = props;

  const vehicleTitle = useMemo(() => {
    return [
      getDictionaryName(request.vehicle.vehicleModel, "-"),
      getDictionaryName(request.vehicle.vehicleType, "-"),
    ].join(", ");
  }, [request.vehicle]);

  return (
    <StyledVehicleRequestDetail variant={variant} columns={columns}>
      <Info
        variant={variant}
        label="Организация"
        value={getDictionaryName(request.customer, "-").replace(
          "Товарищество с ограниченной ответственностью",
          ""
        )}
      />
      <Info
        variant={variant}
        label="Проект"
        value={getDictionaryName(request.project, "-")}
      />
      <Info
        variant={variant}
        label="Контрагент"
        value={getDictionaryName(request.partner, "-")}
      />
      <Info variant={variant} label="Техника" value={vehicleTitle} />
      <Info
        variant={variant}
        label="Номенклатура"
        value={getDictionaryName(request.nomenclature, "-")}
      />
      <Info
        variant={variant}
        label="Единица измерения работ"
        value={getDictionaryName(request.unit, "-")}
      />
      <Info
        variant={variant}
        label="Количество"
        value={!!request.volume ? request.volume.toString() : "-"}
      />
      <Info
        variant={variant}
        label="Дата начала"
        value={dateFormat(request.startDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={variant}
        label="Дата завершения"
        value={dateFormat(request.endDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={variant}
        label="Комментарий"
        value={!!request.comments ? request.comments : "-"}
      />
      {!!request.confirmer && (
        <>
          <Info
            variant={variant}
            label="ФИО диспетчера"
            value={getFullName(request.confirmer)}
          />
          <Info
            variant={variant}
            label="Контакты диспетчера"
            value={request.confirmer.phone || "-"}
          />
        </>
      )}
    </StyledVehicleRequestDetail>
  );
}

export default memo(VAERequestDetail);
