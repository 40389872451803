import { excludeVAT, MONTH_UNIT_ID } from "./index";
import { getDaysInMonth } from "date-fns";
import { ActVehicleItemV2, NdsShort } from "@app/models";

export const getVehicleItemsTotal = (
  items: ActVehicleItemV2[] | null,
  ndsRate: NdsShort["rate"]
) => {
  return (items || []).map((item) => {
    let sum = item.toPay * (item.price || 0);

    if (item.unitDto?.id === MONTH_UNIT_ID) {
      const days = getDaysInMonth(new Date(item.workDate));
      const toPayPerDay = days ? (item.price || 0) / days : 0;
      sum = item.toPay * toPayPerDay;
    }

    item.summa = sum;
    item.summaNds = excludeVAT(sum, ndsRate);
    item.total = sum;

    return {
      ...item,
    };
  });
};
