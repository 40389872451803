import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Modal, ModalProps, TextField } from "@app/components";
import { useNotification } from "@app/providers";
import { rejectTask } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";

interface Props extends ModalProps {
  taskId?: string;
  onClose: (val?: boolean) => void;
}

const ModalActTaskReject = (props: Props) => {
  const { onClose, taskId, ...restProps } = props;
  const { showNotification } = useNotification();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const onRejectConfirm = useCallback(async () => {
    if (!taskId) {
      return;
    }

    setLoading(true);
    rejectTask(taskId, comment)
      .then(() => {
        showNotification({
          variant: "success",
          message: "Отклонен",
        });
        setLoading(false);
        onClose(true);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e),
          variant: "error",
        });
      });
  }, [comment, onClose, showNotification, taskId]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text="Подтвердить отклонение"
        onClick={onRejectConfirm}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onRejectConfirm]);

  return (
    <Modal actions={actions} onClose={onClose} {...restProps}>
      <div className="">
        <TextField
          label="Комментарий"
          placeholder="Укажите причину отклонения"
          value={comment}
          name="comment"
          onChange={(value, name) => {
            setComment(value);
          }}
        />
      </div>
    </Modal>
  );
};

export default memo(ModalActTaskReject);
