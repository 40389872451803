import React, { memo } from "react";
import { IconChevronLeft24, IconChevronRight24 } from "@app/icons";
import classNames from "classnames";
import "./styles.scss";

export const DOTS = "...";

const range = (start: number, end: number) => {
  const length = end - start + 1;

  return Array.from({ length }, (_, index) => index + start);
};

export interface PaginationProps {
  onChange: (page: number) => void;
  initialPage: number;
  totalCount: number;
  siblingCount?: number;
  pageSize?: number;
}

interface PaginationState {
  currentPage: number;
}

class Pagination extends React.Component<PaginationProps, PaginationState> {
  state: PaginationState = {
    currentPage: this.props.initialPage || 1,
  };

  goToNextPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { totalCount, pageSize } = this.props;
    const { currentPage } = this.state;
    const totalPageCount = Math.ceil(totalCount / (pageSize || 20));

    if (currentPage === totalPageCount) {
      return;
    }

    this.setState(
      {
        currentPage: currentPage + 1,
      },
      () => {
        this.props.onChange(this.state.currentPage);
      }
    );
  };

  gotToPreviousPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { currentPage } = this.state;

    if (currentPage === 1) {
      return;
    }

    this.setState(
      {
        currentPage: currentPage - 1,
      },
      () => {
        this.props.onChange(this.state.currentPage);
      }
    );
  };

  changePage = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLButtonElement;
    const pageNumber = Number(target.textContent);

    this.setState(
      {
        currentPage: pageNumber,
      },
      () => {
        this.props.onChange(this.state.currentPage);
      }
    );
  };

  paginationRange = (
    totalCount: number,
    pageSize: number,
    currentPage: number,
    siblingCount: number
  ) => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount - 2) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 3;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  };

  render() {
    const { siblingCount = 1, totalCount, pageSize = 20 } = this.props;
    const { currentPage } = this.state;

    const paginationRange = this.paginationRange(
      totalCount,
      pageSize,
      currentPage,
      siblingCount
    );

    if (currentPage === 0 || paginationRange!.length < 2) {
      return null;
    }

    let lastPage = paginationRange![paginationRange!.length - 1];

    return (
      <div>
        <div className="b-pagination">
          <button
            onClick={this.gotToPreviousPage}
            className={classNames(
              "b-pagination__button b-pagination__button--prev",
              {
                "b-pagination__button--disabled": currentPage === 1,
              }
            )}
          >
            <IconChevronLeft24 />
          </button>
          {paginationRange?.map((item, index) => {
            if (item === DOTS) {
              return (
                <button key={index} className="b-pagination__button">
                  &#8230;
                </button>
              );
            }
            return (
              <button
                key={index}
                onClick={this.changePage}
                className={classNames("b-pagination__button", {
                  "b-pagination__button--active": currentPage === item,
                })}
              >
                <span>{item}</span>
              </button>
            );
          })}
          <button
            onClick={this.goToNextPage}
            className={classNames(
              "b-pagination__button b-pagination__button--next",
              {
                "b-pagination__button--disabled": currentPage === lastPage,
              }
            )}
          >
            <IconChevronRight24 />
          </button>
        </div>
      </div>
    );
  }
}

export default memo(Pagination);
