import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "@app/providers";
import FirebaseProvider from "../providers/Firebase";

export function AuthorizedOutlet() {
  const { authenticated } = useUser();

  if (!authenticated) {
    return <Navigate to="/sign-in" replace={true} />;
  }

  return (
    <FirebaseProvider>
      <Outlet />
    </FirebaseProvider>
  );
}
