import React, { memo, PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {}

const StyleModalFooter = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
  border-top: 1px solid #d8d8d8;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  > * {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

function ModalFooter(props: Props & PropsWithChildren) {
  const { children } = props;

  return <StyleModalFooter>{children}</StyleModalFooter>;
}

export default memo(ModalFooter);
