import React, { memo } from "react";
import styled from "styled-components";
import { Container } from "@app/components";
import MapSrc from "./map.png";
import ImageMapNew from "./map-new.png";
import ImageFlags from "./flags.png";
import content from "./content.json";
import { theme } from "styled-tools";
import SectionTitle from "../SectionTitle";

const StyledSection = styled.section`
  padding: 40px 0;
  box-sizing: border-box;
`;

const StyledTitle = styled(SectionTitle)`
  text-align: center;
  margin: 0 0 60px 0;

  @media only screen and (max-width: 576px) {
    margin: 0 0 32px 0;
  }
`;

const StyledMap = styled.img`
  width: 80%;
  margin: 0 auto 80px;
  display: block;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const StyledInfo = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;

const StyledInfoTitle = styled.p`
  font-size: 40px;
  line-height: 48px;
  color: ${theme("color.dark")};
  margin: 0 0 12px 0;
  border-bottom: 1px solid ${theme("color.dark")};
  padding-bottom: 12px;
  box-sizing: border-box;

  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

const StyledInfoText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #69748c;
`;

const StyledRow = styled.div`
  text-align: center;
  margin: 16px 0;
`;

const StyledRowMobile = styled.div`
  text-align: center;
  margin: 16px 0;

  @media only screen and (min-width: 576px) {
    display: none;
  }
`;

function SectionUsage() {
  return (
    <StyledSection>
      <Container>
        <StyledTitle>{content.title}</StyledTitle>
        <StyledMap src={MapSrc} alt="" />
        <StyledRow>
          <img src={ImageFlags} width={74} alt="" />
        </StyledRow>
        <StyledRowMobile>
          <img src={ImageMapNew} width={"100%"} alt="" />
        </StyledRowMobile>
        <StyledInfo>
          {content.infos.map((info, infoIndex) => (
            <div key={infoIndex.toString()}>
              <StyledInfoTitle>{info.title}</StyledInfoTitle>
              <StyledInfoText>{info.text}</StyledInfoText>
            </div>
          ))}
        </StyledInfo>
      </Container>
    </StyledSection>
  );
}

export default memo(SectionUsage);
