import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AxiosError } from "axios";
import { Grid, Modal, ModalProps, ModalTitle, Tabs } from "@app/components";
import { SmartContract } from "@app/models";
import {
  ContractorDocuments,
  SignerListView,
  SmartContractStatusChip,
} from "@app/common";
import { APIResponse, getSmartContract } from "@app/api";
import {
  currencyFormat,
  dateFormat,
  getAxiosErrorMessage,
  isPartner,
} from "@app/helpers";
import { useNotification, useUser } from "@app/providers";
import ListItem from "../../components/List/ListItem";
import styled from "styled-components";
import { ApproveListView } from "./components";

interface Props extends Omit<ModalProps, "title"> {
  contractId: string | null;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  background: #f5f6f8;
  border-bottom: 1px solid #d8d8d8;
`;

const ModalContractDocDetail = (props: Props) => {
  const { onClose, contractId, ...restProps } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [contractDetail, setContractDetail] = useState<SmartContract | null>(
    null
  );
  const [, setLoading] = useState<boolean>(false);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!contractDetail) {
      return "Загружается...";
    }

    return (
      <ModalTitle text={contractDetail.name || ""}>
        <SmartContractStatusChip status={contractDetail.status} />
      </ModalTitle>
    );
  }, [contractDetail]);

  const tabLabels = useMemo<string[]>(() => {
    const tabs = ["Подписанты", "Лист согласования", "Документы исполнителя"];
    // Скрыть для партнера 'Лист согласования'
    if (isPartner(user!.role)) {
      tabs.splice(1, 1);
      return tabs;
    }
    return tabs;
  }, [user]);

  const getContractDetail = useCallback(
    async (sourceId: string) => {
      setLoading(true);
      try {
        const response = await getSmartContract(sourceId);
        setContractDetail(response.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  useEffect(() => {
    if (!!contractId) {
      getContractDetail(contractId).then(() => {});
    }
  }, [contractId, getContractDetail]);

  const onModalDetailClose = useCallback(() => {
    setContractDetail(null);
    onClose();
  }, [onClose]);

  return (
    <Modal
      onClose={onModalDetailClose}
      title={modalTitle}
      actions={[]}
      dense
      {...restProps}
    >
      <Header>
        <Grid columns={4}>
          <ListItem
            title="Организация"
            subtitle={contractDetail?.customer.name}
          />
          <ListItem
            title="Срок"
            subtitle={
              contractDetail?.contractDate
                ? dateFormat(contractDetail?.contractDate, "dd.MM.yyyy")
                : "-"
            }
          />
          <ListItem
            title="Дата начала"
            subtitle={
              contractDetail?.startDate
                ? dateFormat(contractDetail?.startDate, "dd.MM.yyyy")
                : "-"
            }
          />
          <ListItem
            title="Испонитель"
            subtitle={contractDetail?.partner?.name}
          />
          <ListItem title="Проект" subtitle={contractDetail?.project?.name} />
          <ListItem
            title="Сумма договора"
            subtitle={currencyFormat(contractDetail?.amount || "")}
          />
          <ListItem
            title="Дата завершения"
            subtitle={
              contractDetail?.endDate
                ? dateFormat(contractDetail?.endDate, "dd.MM.yyyy")
                : "-"
            }
          />
        </Grid>
      </Header>
      <Tabs labels={tabLabels}>
        <SignerListView
          contract={contractDetail}
          signedPdf={contractDetail?.signedPdf}
          signedPdfFiles={contractDetail?.signedPdfFiles}
        />
        <>
          <ApproveListView contractId={contractDetail?.id} />
          <ApproveListView
            histories={contractDetail?.histories}
            dense={false}
          />
        </>
        <ContractorDocuments contractData={contractDetail} />
      </Tabs>
    </Modal>
  );
};

export default memo(ModalContractDocDetail);
