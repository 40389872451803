import { SelectOption } from "@app/components";

export function setSelectOption<
  T extends {
    name: string;
    id: string;
  }
>(
  item: T | null,
  labelKeys: (keyof T)[] = ["name"],
  valueKey = "id"
): SelectOption<T> | null {
  if (!item) {
    return null;
  }

  return {
    label: labelKeys.reduce(
      (acc, currentValue) => `${acc} ${item[currentValue]}`.trim(),
      ""
    ),
    value: item[valueKey as keyof T],
    item,
  };
}
