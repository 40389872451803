import instance from "./instance";
import { PageableParams, PageableResponse } from "./interfaces";
import { Country } from "@app/models";

export function getCountries(
  params: PageableParams<Country>
): Promise<PageableResponse<Country>> {
  return instance()
    .get("/country", {
      params,
    })
    .then((response) => response.data);
}
