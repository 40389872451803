import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { AxiosError } from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNotification, useUser } from "@app/providers";
import {
  APIResponse,
  getVehicleRequest,
  getVehicleRequestOffers,
} from "@app/api";
import {
  VehicleRequest,
  VehicleRequestOffer,
  VehicleRequestOfferStatus,
} from "@app/models";
import {
  getAxiosErrorMessage,
  getDictionaryName,
  priceFormat,
} from "@app/helpers";
import styled from "styled-components";
import { LayoutPrinting } from "@app/layouts";
import { VehicleRequestDetail } from "@app/common";
import { IconCheckCircle32 } from "@app/icons";

const StyledTableHead = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 14px 12px;
  text-align: left;
`;

const PrintButton = styled.button`
  border: none;
  background: lightgray;
  outline: none;
  cursor: pointer;
`;

function PageVehicleRequestPrinting() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useUser();

  const [pending, setPending] = useState<boolean>(true);
  const [request, setRequest] = useState<VehicleRequest | null>(null);
  const [offers, setOffers] = useState<VehicleRequestOffer[] | null>(null);

  const { showNotification } = useNotification();

  const getRequestOffers = useCallback(
    async (requestId: string) => {
      const params = {
        pageNumber: 1,
        pageSize: 100,
      };

      setPending(true);
      try {
        const response = await getVehicleRequestOffers({
          ...params,
          requestId,
        });
        setOffers(response.data);

        setTimeout(() => {
          // window.print();
        }, 700);

        setPending(false);
      } catch (e) {
        setPending(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  const tableLabels = useMemo<string[]>(
    () => [
      "",
      "Абонент",
      "Рейтинг",
      "Гос номер",
      "Цена без НДС",
      "Провайдер GPS",
      "Цена с НДС",
      "Ставка НДС",
      "Примечание",
    ],
    []
  );

  const getRequestDetail = useCallback(
    async (sourceId: string) => {
      setPending(true);
      try {
        const response = await getVehicleRequest(sourceId);
        setRequest(response.data);
        setPending(false);
      } catch (e) {
        setPending(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  useEffect(() => {
    if (params.requestId) {
      getRequestDetail(params.requestId).then(async () => {
        await getRequestOffers(params.requestId!);
      });
    } else {
      navigate("/");
    }
  }, [
    authenticated,
    getRequestDetail,
    getRequestOffers,
    location,
    navigate,
    params,
  ]);

  return (
    <LayoutPrinting
      title={
        request?.number
          ? `Заявка на технику № ${request?.number}`
          : "Загружается..."
      }
    >
      {request && (
        <VehicleRequestDetail
          request={request}
          columns={4}
          variant={"secondary"}
        />
      )}
      {offers && offers.length ? (
        <>
          <h3>Ценовые предложения</h3>
          <table>
            <thead>
              <tr>
                {tableLabels.map((label, labelIndex) => (
                  <StyledTableHead key={labelIndex.toString()}>
                    {label}
                  </StyledTableHead>
                ))}
              </tr>
            </thead>
            <tbody>
              {(offers || []).map((item, index) => (
                <tr key={index.toString()}>
                  <td>
                    {item.status === VehicleRequestOfferStatus.Selected ? (
                      <IconCheckCircle32 />
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{getDictionaryName(item.company, "-")}</td>
                  <td>{"-"}</td>
                  <td>{item.vehicle.govNumber || "-"}</td>
                  <td>
                    {!!item.priceWithoutNds
                      ? priceFormat(item.priceWithoutNds.toString())
                      : "-"}
                  </td>
                  <td>{getDictionaryName(item.vehicle.gps, "-")}</td>
                  <td>{priceFormat(item.price.toString())}</td>
                  <td>{getDictionaryName(item.nds, "-")}</td>
                  <td>{item.comments || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <h4>Ценовые предложения отсутствуют</h4>
      )}
      <PrintButton onClick={() => window.print()}>Печать</PrintButton>
    </LayoutPrinting>
  );
}

export default memo(PageVehicleRequestPrinting);
