import React, { useMemo } from "react";
import { priceFormat } from "@app/helpers";
import { useKIP } from "@app/providers";
import styled, { css } from "styled-components";
import { switchProp, theme } from "styled-tools";

const StyledInfoRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledIconContainer = styled.div<{
  variant: 1 | 2 | 3 | 4;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  gap: 10px;
  border-radius: 4px;

  ${switchProp("variant", {
    1: css`
      background-color: ${theme("color.dark")};
      color: ${theme("color.white")};
    `,
    2: css`
      background-color: ${theme("color.yellow")};
      color: ${theme("color.dark")};
    `,
    3: css`
      background-color: ${theme("color.blueGreen")};
      color: ${theme("color.white")};
    `,
    4: css`
      background-color: ${theme("color.red")};
      color: ${theme("color.white")};
    `,
  })}
`;

const StyledValue = styled.p`
  color: ${theme("color.darkGray")};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const StyledInfo = styled.div`
  //min-width: 144px;
  display: flex;
  padding: 4px 0;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  p {
    margin: 0;
    text-align: center;
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
`;

export default function () {
  const {
    pending,
    loaded,
    getVehiclesQuantity,
    getWorkLoad,
    getCostAmount,
    getUnusedAmount,
  } = useKIP();

  const vehiclesQuantity = useMemo<number>(() => {
    if (!loaded || pending) {
      return 0;
    }

    return getVehiclesQuantity();
  }, [getVehiclesQuantity, loaded, pending]);

  const workLoad = useMemo<number>(() => {
    if (!loaded || pending) {
      return 0;
    }

    return getWorkLoad();
  }, [getWorkLoad, loaded, pending]);

  const costAmount = useMemo<number>(() => {
    if (!loaded || pending) {
      return 0;
    }

    return getCostAmount();
  }, [getCostAmount, loaded, pending]);

  const unusedAmount = useMemo<number>(() => {
    if (!loaded || pending) {
      return 0;
    }

    return getUnusedAmount();
  }, [getUnusedAmount, loaded, pending]);

  return (
    <>
      <StyledInfoRow>
        <StyledInfo>
          <StyledIconContainer variant={1}>
            <StyledValue>{vehiclesQuantity} ТС</StyledValue>
          </StyledIconContainer>
          <p>Количество техники</p>
        </StyledInfo>
        <StyledInfo>
          <StyledIconContainer variant={2}>
            <StyledValue>{`${
              !!workLoad ? Math.round(workLoad) : 0
            }%`}</StyledValue>
          </StyledIconContainer>
          <p>Загруженность</p>
        </StyledInfo>
        <StyledInfo>
          <StyledIconContainer variant={3}>
            <StyledValue>{priceFormat(costAmount.toFixed())}</StyledValue>
          </StyledIconContainer>
          <p>Сумма затрат</p>
        </StyledInfo>
        <StyledInfo>
          <StyledIconContainer variant={4}>
            <StyledValue>{priceFormat(unusedAmount.toFixed())}</StyledValue>
          </StyledIconContainer>
          <p>Неосвоенный бюджет</p>
        </StyledInfo>
      </StyledInfoRow>
    </>
  );
}
