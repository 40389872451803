import React, { memo } from "react";
import "./styles.scss";
import { IconLogo } from "@app/icons";
import { FormSignIn } from "@app/forms";

interface Props {}

function PageSignIn(props: Props) {
  return (
    <div className="p-sign-in">
      <div className="b-container">
        <div className="p-sign-in__content">
          <a href="/" className="p-sign-in__link">
            <IconLogo />
          </a>
          <h2>Вход</h2>
          <FormSignIn />
          <a href="/sign-up" className="p-sign-in__link">
            Зарегистрироваться
          </a>
        </div>
      </div>
    </div>
  );
}

export default memo(PageSignIn);
