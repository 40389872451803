import React, { memo, useCallback } from "react";
import styled from "styled-components";
import FileComponent from "../../../components/File/File";
import { DateTimePicker } from "@app/components";
import { parseISO } from "date-fns";
import DataProgressBar from "../../../components/DateProgressBar";

export interface FileType {
  name: string;
  isExpirationDateRequired: boolean;
  id: string;
  isDeleted: boolean;
}

interface FilterParams {
  [key: string]: string;
}

interface Props {
  filterParams: FilterParams;
  files: { [key: string]: any[] };
  setFiles: React.Dispatch<React.SetStateAction<{ [key: string]: any[] }>>;
  fileTypes: FileType[];
  updateData?: any | null;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
  onFileDelete: (companyFileTypeId: string, fileIndex: number) => void;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const StyledDatePicker = styled.div`
  display: flex;
  width: 256px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 16px;
`;

const StyledDropZone = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 12px;
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;
  margin-bottom: 16px;

  input {
    display: none;
  }
`;

const StyledDropZoneTitle = styled.div`
  color: #636463;

  .link {
    color: #4f8bff;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #1c2246;
  margin-bottom: 12px;
`;

const StyledDropZoneCaption = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
`;

function Step3(props: Props) {
  const {
    onFileDelete,
    files,
    setFiles,
    fileTypes,
    onDataChange,
    filterParams,
    updateData,
  } = props;

  const onChangeInput = useCallback(
    (companyFileTypeId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => ({
          ...prevFiles,
          [companyFileTypeId]: [
            ...(prevFiles[companyFileTypeId] || []),
            ...newFiles,
          ],
        }));
      }
    },
    [setFiles]
  );

  const handleChange = (fileTypeId: string, value: Date | null) => {
    const formattedValue = value instanceof Date ? value.toISOString() : null;
    onDataChange(formattedValue, fileTypeId);
  };

  return (
    <StyledContent>
      <StyledHeader>Документы</StyledHeader>
      {fileTypes.map((fileType) => (
        <StyledDropZone key={fileType.id}>
          <StyledTitle>{fileType.name}</StyledTitle>
          {!files[fileType.id] || files[fileType.id].length === 0 ? (
            <>
              <StyledDropZoneTitle>
                <span
                  className="link"
                  onClick={() => {
                    const fileInput = document.getElementById(
                      `file-input-${fileType && fileType.id}`
                    );
                    if (fileInput) {
                      fileInput.click();
                    }
                  }}
                >
                  Загрузите
                </span>{" "}
                документ или перетащите его сюда
              </StyledDropZoneTitle>
              <StyledDropZoneCaption>
                Форматы: doc, docx, pdf, png, jpeg; Максимальный размер: 3 мб
              </StyledDropZoneCaption>
            </>
          ) : null}
          <input
            type="file"
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
            id={`file-input-${fileType.id}`}
            onChange={onChangeInput(fileType.id)}
          />
          {files[fileType.id]?.map((file, fileIndex) => (
            <FileComponent
              key={fileIndex}
              file={file}
              onDelete={() => onFileDelete(fileType.id, fileIndex)}
            />
          ))}
          {fileType.isExpirationDateRequired && (
            <StyledDatePicker>
              <DateTimePicker
                label="Документ годен до"
                name={fileType.id}
                onChange={(dateValue) => handleChange(fileType.id, dateValue)}
                value={
                  filterParams[fileType.id]
                    ? parseISO(filterParams[fileType.id])
                    : null
                }
                hideTime={true}
              />
            </StyledDatePicker>
          )}
          {filterParams[fileType.id] && updateData !== null && (
            <DataProgressBar
              today={new Date()}
              expirationDate={new Date(filterParams[fileType.id])}
            />
          )}
        </StyledDropZone>
      ))}
    </StyledContent>
  );
}

export default memo(Step3);
