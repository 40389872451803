import React, { memo, useMemo } from "react";
import { SVGIconType } from "@app/icons";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

export interface Props {
  title: string;
  path: string;
  icon?: SVGIconType;
  activeIcon?: SVGIconType;
  badge?: number;
}

function MenuItem(props: Props) {
  const { title, path, icon: Icon, activeIcon: ActiveIcon, badge } = props;
  const location = useLocation();

  const isActive = useMemo<boolean>(() => {
    return (
      location.pathname.substring(1).split("/")[0] ===
      path.substring(1).split("/")[0].toLocaleLowerCase()
    );
  }, [location, path]);

  return (
    <li
      className={classNames("b-menu__item", {
        "b-menu__item--active": isActive,
      })}
    >
      <Link to={path} className="b-menu__link">
        {!!Icon && (
          <span className="b-menu__icon">
            {isActive && !!ActiveIcon ? <ActiveIcon /> : <Icon />}
          </span>
        )}
        <span className="b-menu__text">{title}</span>
        {!!badge && <span className="b-menu__badge">{badge.toString()}</span>}
      </Link>
    </li>
  );
}

export default memo(MenuItem);
