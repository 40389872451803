import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Chip, ChipColor } from "@app/components";
import ModalOrganizationBankAccount from "../../OrganizationBankAccount";
import { theme } from "styled-tools";
import { IconTrash24 } from "@app/icons";
import { MoreMenu } from "@app/common";
import { APIResponse } from "@app/api";
import { useNotification } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

type Props = {
  dataParams?: any;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
  readonly: boolean;
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 16px;
`;

const ButtonStyled = styled.div`
  display: flex;
  width: 188px;
  gap: 8px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 24px;
`;

const StyledItemStatus = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
`;

const StyledContainerTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #636463;
  margin-bottom: 8px;
`;

const StyledContainerText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #1c2246;
`;

const StyledButton = styled.button`
  margin-left: 14px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${theme("color.gray")};
  opacity: 0.8;
  transition: 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const Step5 = (props: Props) => {
  const { dataParams, readonly, onDataChange } = props;
  const { showNotification } = useNotification();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onClickShowModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const getStatusVariant = (status: any): ChipColor => {
    switch (status) {
      case true:
        return "success";
      case false:
        return "default";
      default:
        return "default";
    }
  };

  const handleDeleteBank = useCallback(
    async (bankIndex: any) => {
      try {
        const bankAccount = dataParams.bank[bankIndex];

        if (bankAccount.bankAccountId) {
          // Если есть id, отправляем запрос на удаление
          //
          // закоментирован т.к. коммит не залит на бой (12.09.2024)
          //
          // const response = await deleteBankAccount({
          //   companyId: dataParams.id,
          //   bankAccountId: bankAccount.bankAccountId,
          // });
          //
          // if (response?.succeeded) {
          //   const updatedBankData = dataParams.bank.filter(
          //     (_: any, index: any) => index !== bankIndex
          //   );
          //   onDataChange(updatedBankData, "bank");
          //   showNotification({
          //     message: "Банковский реквизит успешно удален",
          //     variant: "success",
          //   });
          // } else {
          //   showNotification({
          //     message: "Ошибка при удалении банковского реквизита",
          //     variant: "error",
          //   });
          // }
        } else {
          // Если id нет, просто удаляем локально
          const updatedBankData = dataParams.bank.filter(
            (_: any, index: any) => index !== bankIndex
          );
          onDataChange(updatedBankData, "bank");
        }
      } catch (error) {
        console.error("Ошибка при удалении банковского реквизита: ", error);
        showNotification({
          message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [dataParams.bank, dataParams.id, onDataChange]
  );

  const menuItems = useCallback(
    (bankIndex: any) => [
      {
        icon: IconTrash24,
        label: "Удалить",
        onClick: () => handleDeleteBank(bankIndex),
      },
    ],
    [handleDeleteBank]
  );

  const handleBankSubmit = useCallback(
    (newBankData: any) => {
      const updatedBankData = Array.isArray(dataParams.bank)
        ? [...dataParams.bank, newBankData]
        : [newBankData];
      onDataChange(updatedBankData, "bank");
    },
    [dataParams.bank, onDataChange]
  );

  return (
    <StyledContent>
      <StyledHeader>Банковски реквизиты</StyledHeader>
      {Array.isArray(dataParams.bank) &&
        dataParams.bank.map((bank: any, bankIndex: any) => (
          <StyledContainer key={bankIndex}>
            <StyledItemContainer>
              <StyledItem>
                <StyledContainerTitle>Банк</StyledContainerTitle>
                <StyledContainerText>{bank.bank.label}</StyledContainerText>
              </StyledItem>
              <StyledItem>
                <StyledContainerTitle>ИИК</StyledContainerTitle>
                <StyledContainerText>{bank.accountNumber}</StyledContainerText>
              </StyledItem>
            </StyledItemContainer>
            <StyledItemStatus>
              <StyledChipContainer>
                <Chip
                  text={bank.isMain === true ? "Основной" : "Не основной"}
                  color={getStatusVariant(bank.isMain)}
                />
              </StyledChipContainer>

              <StyledButton onClick={() => {}}>
                <MoreMenu items={menuItems(bankIndex)} />
              </StyledButton>
            </StyledItemStatus>
          </StyledContainer>
        ))}

      {!readonly && (
        <ButtonStyled>
          <Button
            text={"Добавить реквизиты"}
            onClick={onClickShowModal}
            size={"small"}
          />
        </ButtonStyled>
      )}
      <ModalOrganizationBankAccount
        open={modalVisible}
        title={"Добавление реквизита"}
        onClose={hideModal}
        onSubmit={handleBankSubmit}
      />
    </StyledContent>
  );
};

export default memo(Step5);
