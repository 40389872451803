import { LayoutDefault } from "@app/layouts";
import React, { memo, useMemo } from "react";
import { MyVehicleRequests, VehicleRequests } from "./components";
import { TabRoute, TabRouter } from "@app/common";
import { Route, Routes } from "react-router-dom";

function PageVehicleRequests() {
  const routes: TabRoute[] = useMemo(
    () => [
      {
        label: "Заказ на технику",
        path: "/vehicle-requests/list",
        element: <VehicleRequests />,
      },
      {
        label: "Мои предложения",
        path: "/vehicle-requests/my",
        element: <MyVehicleRequests />,
      },
    ],
    []
  );

  return (
    <LayoutDefault title="Заказ на технику">
      <TabRouter routes={routes}>
        <Routes>
          <Route path="/list" element={<VehicleRequests />} />
          <Route path="/my" element={<MyVehicleRequests />} />
        </Routes>
      </TabRouter>
    </LayoutDefault>
  );
}

export default memo(PageVehicleRequests);
