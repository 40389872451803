import { Company } from "./Company";
import { Project } from "./Project";
import { VehicleItem, VehicleJournalForActV2, VehicleType } from "./Vehicle";
import { WorkShift } from "./WorkShift";
import { UserTaskStatuses, UserTaskType } from "./Task";
import { NdsShort } from "./NDS";
import { Confirmer } from "./User";
import { Unit } from "./Default";
import { Contract } from "./Contract";
import { ConstructiveRequest, ConstructiveResponse } from "./Constructive";

export interface Act {
  id: string;
  actNumber: string;
  actConstructiveDto: ConstructiveResponse[];
  createdDate: string;
  startWorkDate: string;
  endWorkDate: string;
  sum: number;
  company: Pick<Company, "id" | "name" | "nds">;
  partner: Pick<Company, "id" | "name" | "nds">;
  project: Pick<Project, "id" | "name">;
  contract: Pick<Contract, "id" | "name" | "contractDraftId" | "status">;
  nds: NdsShort;
  status: ActStatus;
  isLifting?: boolean;
  actVehiclesDto?: ActVehicles[];
  actVehicleGroupDto: VehicleJournalForActV2[];
  actSignersDto?: ActSignerDTO[];
  actNotificationRecipientsDto?: Confirmer[] | null;
  currency?: unknown;
  isOrderedApproval?: unknown;
}

export interface ActSigner {
  taskType: UserTaskType;
  userId: string;
}

export interface ActSignerDTO {
  taskTypeId: UserTaskType;
  userShort: Confirmer | null;
  fullName: string;
  status: UserTaskStatuses;
  created: string;
  termDate: string;
  lastModified: string | null;
  comment: string | null;
  queue: number;
}

export interface ActCreateDTO {
  id?: string;
  companyId: string;
  projectId: string;
  partnerId: string;
  contractId: string;
  ndsId: string;
  isLifting: boolean;
  startWorkDate: string;
  endWorkDate: string;
  vehicles: VehicleItem[];
  actSigners: ActSigner[];
}

export interface GetPayDataDTO {
  actId: string;
  vehicleJurnalIds: string[];
}

export interface AddConstructiveDTO {
  actId: string;
  constructives: ConstructiveRequest[];
}

export interface ActHistoryItemDTO {
  actId: string;
  status: ActStatus;
  user: Confirmer;
  created: string;
  comment: string;
  text: string;
}

export interface ActVehicles {
  actId?: string;
  amountToPay: number;
  downtimeAmount: number;
  hoursGps: number;
  priceWithNds: number;
  priceWithOutNds: number;
  shiftTypeDto: WorkShift;
  toPay: number;
  unit: Unit;
  vehicleId: string;
  id?: string;
  total?: number;
  govNumber?: string;
  lineData?: number;
  vehicleJurnalId: string;
  vehicleTypeDto: VehicleType;
  workDate: string;
  workEndDateTime: string;
  workPerformed: string;
  workStartDateTime: string;
}

export interface ActNotificationRecipientsDto {
  id: Act["id"];
  userIds: string[];
}

export enum ActStatus {
  Created = 1,
  ToBeAgreed = 2,
  SentToAccounting = 3,
  Agreed = 4,
  Rejected = 5,
  SigningCounterparty = 6,
  SigningProject = 7,
  AwaitingUploadToBC = 8,
  ActSigningRejected = 9,
  ActSigningOverdue = 10,
  Deleted = 11,
  AvrCreated = 20,
  AvrSigned = 30,
  AvrRevoked = 40,
  AvrDeleted = 50,
  ActRevoked = 60,
  OnProjectApproval = 70,
}

export const ActStatusName: Record<ActStatus, string> = {
  [ActStatus.Created]: "Создан табель",
  [ActStatus.ToBeAgreed]: "Табель на согласовании",
  [ActStatus.SentToAccounting]: "Отправлен в бухгалтерию",
  [ActStatus.Agreed]: "Табель согласован",
  [ActStatus.Rejected]: "Табель отклонен",
  [ActStatus.SigningCounterparty]: "АВР на подписании у контрагента",
  [ActStatus.SigningProject]: "АВР на подписании у проекта",
  [ActStatus.AwaitingUploadToBC]: "Ожидает загрузки в БК",
  [ActStatus.ActSigningRejected]: "Подписание АВР отклонено",
  [ActStatus.ActSigningOverdue]: "Просрочено подписание АВР",
  [ActStatus.Deleted]: "Табель удален",
  [ActStatus.AvrCreated]: "Создан АВР",
  [ActStatus.AvrSigned]: "АВР подписан обоюдно",
  [ActStatus.AvrRevoked]: "Отозван АВР",
  [ActStatus.AvrDeleted]: "Удален АВР",
  [ActStatus.ActRevoked]: "Табель отозван",
  [ActStatus.OnProjectApproval]: "АВР на согласовании у заказчика",
};

// Используется в Отчетах
export enum ActStatusForFilters {
  Created = 1,
  Agreed = 2,
}

export const ActStatusForFiltersName: Record<ActStatusForFilters, string> = {
  [ActStatusForFilters.Created]: "Создан табель",
  [ActStatusForFilters.Agreed]: "Согласованные табели",
};
