import { TemplateSearch } from "@app/templates";
import { Adhesion } from "@app/models";
import React, { useCallback, useMemo, useState } from "react";
import { getAdhesionContracts, PageableParams } from "@app/api";
import { dateFormat, getFullName } from "@app/helpers";
import { AdhesionStatusChip } from "@app/common";
import { ModalAdhesionDetail } from "@app/modals";

const AdhesionDocuments = () => {
  const [adhesionContract, setAdhesionContract] = useState<Adhesion>();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const tableLabels = useMemo(
    () => [
      // "Наименование задачи",
      // "Статус договора",
      "Дата назначения",
      "Дата завершения",
      "Статус задачи",
      "Контрагент",
      "БИН",
      // "Исполнитель задачи",
      "Инициатор",
    ],
    []
  );

  const mapTableData = useCallback((item: Adhesion) => {
    return [
      dateFormat(item.created, "dd.MM.yyyy "),
      dateFormat(item.date, "dd.MM.yyyy "),
      <AdhesionStatusChip status={item.statusId} />,
      item.company.name,
      item.company.bin,
      getFullName(item.initiatorUser),
    ];
  }, []);

  const getData = useCallback((params: PageableParams) => {
    return getAdhesionContracts({ ...params });
  }, []);

  const onTableRowClick = useCallback((value: Adhesion) => {
    setAdhesionContract(value);
    setOpenModal(true);
  }, []);

  const onModalClose = useCallback(() => {
    setOpenModal(false);
    setAdhesionContract(undefined);
  }, []);

  return (
    <>
      <TemplateSearch<Adhesion>
        toolbarProps={{
          onFilter: () => {},
          searchPlaceholder: "Наименование документа, организация, проект",
        }}
        getData={getData}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onTableRowClick}
      />
      {adhesionContract && (
        <ModalAdhesionDetail
          open={openModal}
          adhesionContract={adhesionContract}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

export default AdhesionDocuments;
