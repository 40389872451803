import { APIResponse, PageableParams, PageableResponse } from "./interfaces";
import instance from "./instance";
import { GEOFence, KIPProject } from "@app/models";

export type GPSGeofencesListRequest = {
  gpsId: string;
  gpsGeofenceId: string;
  gpsGeofenceName: string;
};

type AddGPSGeofencesRequest = {
  geofenceId: string;
  gpsGeofences: GPSGeofencesListRequest[];
};

export function getGEOFences(
  params: PageableParams & { projectId?: KIPProject["id"] }
): Promise<PageableResponse<GEOFence> | null> {
  return instance()
    .get(`/geofence/list`, {
      params,
    })
    .then((response) => response.data);
}

export function getGEOFence(id: string): Promise<APIResponse<GEOFence>> {
  return instance()
    .get(`/geofence/${id}`)
    .then((response) => response.data);
}

export function createGEOFence(
  data: Partial<GEOFence>
): Promise<APIResponse<GEOFence>> {
  return instance()
    .post("/geofence", data)
    .then((response) => response.data);
}

export function updateGEOFence(
  data: Partial<GEOFence>
): Promise<APIResponse<GEOFence>> {
  return instance()
    .put("/geofence", data)
    .then((response) => response.data);
}

export function deleteGEOFence(
  id: GEOFence["id"]
): Promise<APIResponse<GEOFence>> {
  return instance()
    .delete(`/geofence/${id}`)
    .then((response) => response.data);
}

export function addGPSGeofences(
  data: AddGPSGeofencesRequest
): Promise<APIResponse<boolean>> {
  return instance()
    .post("/geofence/gpsGeofences", data)
    .then((response) => response.data);
}
