import { VehicleRequestStatus, VehicleRequestStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: VehicleRequestStatus;
}

const getStatusVariant = (status: VehicleRequestStatus): ChipColor => {
  switch (status) {
    case VehicleRequestStatus.AwaitingConfirmation:
      return "primary";
    case VehicleRequestStatus.AwaitingRequest:
      return "warning";
    case VehicleRequestStatus.TermAgreement:
      return "default";
    case VehicleRequestStatus.ContractSign:
      return "default";
    case VehicleRequestStatus.OrderCompleted:
      return "success";
    case VehicleRequestStatus.Rejected:
      return "danger";
    default:
      return "default";
  }
};

function VehicleRequestStatusChip(props: Props) {
  const { status } = props;

  return (
    <Chip
      text={VehicleRequestStatusName[status]}
      color={getStatusVariant(status)}
    />
  );
}

export default memo(VehicleRequestStatusChip);
