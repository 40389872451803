export const content = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
    {font-family:"Cambria Math";
    panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
    {font-family:Calibri;
    panose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
    {margin-top:0in;
    margin-right:0in;
    margin-bottom:8.0pt;
    margin-left:0in;
    line-height:107%;
    font-size:11.0pt;
    font-family:"Calibri",sans-serif;}
.MsoChpDefault
    {font-family:"Calibri",sans-serif;}
.MsoPapDefault
    {margin-bottom:8.0pt;
    line-height:50%;}
@page WordSection1
    {size:595.3pt 841.9pt;
    margin:56.7pt 42.5pt 56.7pt 85.05pt;}
div.WordSection1
    {page:WordSection1;}
.vehicle-status-info ul {
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 0;
    }
    .vehicle-status-info ul li {
        /*display: inline-block;*/
        align-content: center;
        margin: 0 10px;
    }
    .vehicle-status-info-text {
    margin-left: 12px;
    }
    .vehicle-status-info-list {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    }
    .vehicle-other-details{
    display: flex;
    flex-direction: row;
    margin-top: 24px;
      margin-bottom: 24px;
    }
    .vehicle-status{ 
     display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    }
    .text{ 
    margin-left: 10px;
    }
-->
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class="vehicle-status-info">
    <p>Для вашего удобства мы внесли цветовую индикацию статусов транспортных средств:</p>
    <ul>
        <li class="vehicle-status-info-list">
        <div class="vehicle-status">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#ACACAC"/>
</svg><span class="vehicle-status-info-text">Серый цвет:</span>
<span class="text">Создан</span>
</div>

         </li>
        <li class="vehicle-status-info-list">
        <div class="vehicle-status">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#FFB84E"/>
</svg><span class="vehicle-status-info-text">Желтый цвет:</span>
<span class="text">На модерации</span>
</div>

         </li>
        <li class="vehicle-status-info-list">
        <div class="vehicle-status">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#5BCD71"/>
</svg><span class="vehicle-status-info-text">Зеленый цвет:</span>
<span class="text">Подтверждено</span>
</div>

         </li>
       <li class="vehicle-status-info-list">
        <div class="vehicle-status">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="24" width="24" height="24" rx="12" transform="rotate(90 24 0)" fill="#DA616C"/>
</svg><span class="vehicle-status-info-text">Красный цвет:</span>
<span class="text">Отклонено</span>
</div>
         </li>
     

    </ul>
    <p>Пожалуйста, имейте в виду, что транспортные средства, находящиеся на модерации, будут проверены в течение одного рабочего дня. В случае отсутствия обратной связи в течение одного рабочего дня просим обращаться по номеру телефона: +7 771 046 0533.</p>
<li class="vehicle-other-details">
    <p>
        Ссылка на ватсап: <a href="http://wa.me/77710460533" target="_blank">http://wa.me/77710460533</a>
    </p>
    </li>
         <li class="vehicle-other-details">
    <p>
        Электронная почта: <a href="mailto:support@dispatchcds.com">support@dispatchcds.com</a>
    </p>
    </li>
</div>

</body>

</html>
`;
