import React, { memo, useCallback } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import styled, { css } from "styled-components";
import BGSrc from "./bg.png";
import BG2Src from "./bg2.png";
import content from "./content.json";
import { Button, Grid } from "@app/components";
import { switchProp } from "styled-tools";
import { useUser } from "@app/providers";
import { useNavigate } from "react-router-dom";

interface Props {
  variant?: "default" | "secondary";
}

const StyledPanelVehicleRequest = styled(StyledPanel)<Pick<Props, "variant">>`
  @media only screen and (max-width: 576px) {
    background: none;
  }

  background-repeat: no-repeat;

  ${switchProp("variant", {
    default: css`
      grid-column-start: 5;
      grid-column-end: 7;
      background-image: url(${BGSrc});
      background-size: 50%;
      background-position: bottom right;
    `,
    secondary: css`
      grid-column-start: 1;
      grid-column-end: 5;
      background-image: url(${BG2Src});
      background-size: 184px;
      background-position: 550px bottom;
    `,
  })}
`;

const StyledAction = styled.div`
  @media only screen and (max-width: 576px) {
    margin: 0 auto;
  }
`;

function PanelVehicleRequest(props: Props) {
  const { variant = "default" } = props;
  const { authenticated, asPartner } = useUser();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (!authenticated) {
      navigate("/sign-in");

      return;
    }

    navigate(asPartner ? "/vehicles" : "/vehicles-in-project/requests");
  }, [asPartner, authenticated, navigate]);

  return (
    <StyledPanelVehicleRequest variant={variant}>
      <Grid gap={24}>
        <StyledPanelTitle>
          {variant === "default" ? content.title : content.title2}
        </StyledPanelTitle>
        <StyledPanelText>
          {variant === "default" ? content.text : content.text2}
        </StyledPanelText>
        <StyledAction>
          <Button
            text={variant === "default" ? "Заказать" : "Заказы"}
            onClick={onClick}
          />
        </StyledAction>
        <StyledPanelImageMobile src={BGSrc} width={236} alt="" />
      </Grid>
    </StyledPanelVehicleRequest>
  );
}

export default memo(PanelVehicleRequest);
