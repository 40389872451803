import React, { CSSProperties, memo, PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  dense?: boolean;
  style?: CSSProperties;
}

const StyledModalContent = styled.div<Props>`
  overflow-y: auto;
  padding: ${(props) => (!!props.dense ? 0 : "16px 24px")};
  box-sizing: border-box;
  background-color: ${(props) => props.theme.color.white};
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

function ModalContent(props: Props & PropsWithChildren) {
  const { children, dense, style = {} } = props;

  return (
    <StyledModalContent style={style} dense={dense}>
      {children}
    </StyledModalContent>
  );
}

export default memo(ModalContent);
