import { ComponentProps, ComponentType, memo } from "react";

type PropsComparator<T extends ComponentType> = (
  prevProps: Readonly<ComponentProps<T>>,
  nextProps: Readonly<ComponentProps<T>>
) => boolean;

export function genericMemo<C extends ComponentType<any>>(
  Component: C,
  propsComparator?: PropsComparator<C>
) {
  return memo(Component, propsComparator) as any as C;
}
