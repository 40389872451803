import styled from "styled-components";
import { theme } from "styled-tools";

export const StyledPanel = styled.div`
  background-color: ${theme("color.light")};
  border: 1px solid ${theme("color.grayLight")};
  padding: 24px;
  box-sizing: border-box;
  border-radius: 12px;

  overflow: hidden;

  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
`;

export const StyledPanelTitle = styled.p<{
  offset?: number;
}>`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${theme("color.dark")};
  white-space: pre-wrap;
  margin: 0;
`;

export const StyledPanelText = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #69748c;
  white-space: pre-wrap;
  margin: 0;
`;

export const StyledPanelImageMobile = styled.img`
  height: auto;
  margin: 0 auto;
  @media only screen and (min-width: 576px) {
    display: none;
  }
`;
