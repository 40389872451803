import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { Button, Select, SelectOption, Table, Toggle } from "@app/components";
import { useNotification } from "@app/providers";
import { dateFormat, getAxiosErrorMessage, getFullName } from "@app/helpers";
import { AxiosError } from "axios";
import {
  APIResponse,
  getCompanyEmployeesShort,
  recallApprovalSmartContract,
  SendToApprovalDTO,
  sendToApprovalSmartContract,
  sendToOrderedApprovalContract,
  SendToOrderedApprovalDTO,
} from "@app/api";
import {
  CompanyProjectRecipientsType,
  Confirmer,
  ShortEmployee,
  SmartContract,
  SmartContractStatus,
  UserTaskStatuses,
  UserTaskStatusesName,
  UserTaskType,
  UserTaskTypeName,
} from "@app/models";
import { IconPlus24, IconTrash24 } from "@app/icons";
import { theme } from "styled-tools";
import { ApproveListView } from "../../../ContractDocDetail/components";
import { CompanyNotificationRecipients } from "@app/common";

interface Props {
  contract: SmartContract;
  onChange: (step: number) => void;
  setModalActions: Dispatch<SetStateAction<ReactNode[]>>;
  updateContract: (
    partialContract: Partial<SmartContract>,
    refresh?: boolean
  ) => void;
}

interface Signers {
  user: SelectOption<Confirmer> | null;
  taskTypeId: UserTaskType;
  status?: UserTaskStatuses;
  date?: string;
  comment?: string;
  ord: number;
}

const StyledStep5 = styled.div`
  display: grid;
  grid-gap: 16px;
  height: 100%;
  grid-template-rows: auto auto 1fr;
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const UserNameTableCell = styled.div`
  display: flex;
  width: 300px;
`;

const CustomCell = styled.div`
  display: flex;
  width: 150px;
  p {
    font-size: 14px;
    font-style: italic;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:empty {
      &:after {
        content: "—";
      }
    }
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  width: 500px;
  padding-bottom: 16px;
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${theme("color.orange")};
  opacity: 0.8;
  transition: 0.1s ease-in-out;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
  &:disabled {
    opacity: 1 !important;
    transform: scale(1);
    cursor: default;
    color: ${theme("color.gray")};
    box-shadow: none;
  }
`;

function Step5(props: Props) {
  const {
    onChange: onChangeStep,
    contract,
    updateContract,
    setModalActions,
  } = props;

  const { showNotification } = useNotification();

  const [draftSigners, setDraftSigners] = useState<Signers[]>([]);
  const [employees, setEmployees] = useState<ShortEmployee[]>([]);
  const [pending, setPending] = useState<boolean>(false);
  const [isParallel, setParallel] = useState<boolean>(
    !contract.isOrderedApproval
  );
  const [selectedRecipientIds, setSelectedRecipientIds] = useState<string[]>(
    contract?.contractDraftNotifications?.map((user) => user.userShort.id) || []
  );

  const getEmployees = (companyId: string) => {
    getCompanyEmployeesShort([companyId]).then((res) => {
      if (res && res.data) {
        setEmployees(res.data);
      }
    });
  };

  useEffect(() => {
    if (contract.customer) {
      getEmployees(contract.customer.id);
    }
    const signerArr: Signers[] = contract.approvers.map(({ user, ...etc }) => ({
      user: {
        label: getFullName(user),
        value: user.id,
        item: user,
      },
      ...etc,
    }));
    setDraftSigners(signerArr);
    // const response = getApprovalHistory(contract.id);
    // console.log(response);
  }, [contract]);

  const onClickPrev = useCallback(() => {
    onChangeStep(4);
  }, [onChangeStep]);

  const onClickSendToApproval = useCallback(async () => {
    try {
      if (draftSigners.some((item) => !item.user)) {
        showNotification({
          message: "Укажите подписанта",
          variant: "error",
        });

        return;
      }
      setPending(true);

      let response;

      if (!isParallel) {
        const body: SendToOrderedApprovalDTO = {
          id: contract.id,
          isOrderedApproval: true,
          approvalUsers: draftSigners.map(({ user }, index) => ({
            userId: user!.value,
            ord: index + 1,
          })),
          notificationRecipients: selectedRecipientIds,
        };
        response = await sendToOrderedApprovalContract(body);
      } else {
        const body: SendToApprovalDTO = {
          id: contract.id,
          customerUserIds: draftSigners.map(({ user }) => user?.value),
          notificationRecipients: selectedRecipientIds,
        };
        response = await sendToApprovalSmartContract(body);
      }

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Smart-договор отправлен на согласование",
        variant: "success",
      });

      updateContract(
        {
          status: SmartContractStatus.Singing,
        },
        false
      );
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [
    draftSigners,
    isParallel,
    showNotification,
    updateContract,
    contract.id,
    selectedRecipientIds,
  ]);

  const onClickRecallApproval = useCallback(async () => {
    try {
      setPending(true);

      const response = await recallApprovalSmartContract(contract.id);

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Smart-договор отозван",
        variant: "success",
      });

      updateContract(
        {
          status: SmartContractStatus.Revoked,
        },
        false
      );
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [contract.id, showNotification, updateContract]);

  const rejectedStatuses = useMemo(
    () =>
      [
        SmartContractStatus.Draft,
        SmartContractStatus.Revoked,
        SmartContractStatus.RejectedApproval,
        SmartContractStatus.Rejected,
      ].indexOf(contract.status) > -1,
    [contract.status]
  );

  useEffect(() => {
    const modalActions: ReactNode[] = [];

    if (rejectedStatuses) {
      modalActions.push(
        <Button
          text="Отправить на согласование"
          variant="contained"
          onClick={onClickSendToApproval}
          disabled={pending || draftSigners.length === 0}
        />
      );
    }

    if (
      [SmartContractStatus.OnApproval, SmartContractStatus.Singing].indexOf(
        contract.status
      ) > -1
    ) {
      modalActions.push(
        <Button
          text="Отозвать"
          variant="outlined"
          onClick={onClickRecallApproval}
          disabled={pending}
        />
      );
    }

    setModalActions(modalActions);
  }, [
    draftSigners,
    contract.status,
    onClickSendToApproval,
    pending,
    setModalActions,
    onClickRecallApproval,
    rejectedStatuses,
  ]);

  const tableLabels = useMemo(
    () => [
      "Вид задачи",
      "ФИО ответственного",
      "Результат",
      "Срок",
      "Комментарий",
      "",
    ],
    []
  );

  const getUserList = useCallback(() => {
    return employees
      .filter(
        (employee) =>
          !draftSigners.find(
            (signer) => signer.user && signer.user.value === employee.user.id
          )
      )
      .map(({ user }) => ({
        label: getFullName(user),
        value: user.id,
        item: user,
      }));
  }, [draftSigners, employees]);

  const mapTableData = useCallback(
    (item: Signers, index: number) => {
      return [
        item.taskTypeId ? UserTaskTypeName[item.taskTypeId] : "-",
        <UserNameTableCell>
          <Select<any>
            name="user"
            onChange={(value, name) => {
              draftSigners[index] = {
                ...draftSigners[index],
                [name]: value,
              };
              setDraftSigners([...draftSigners]);
            }}
            value={item.user}
            options={getUserList()}
          />
        </UserNameTableCell>,
        item.status ? UserTaskStatusesName[item.status] : "-",
        item.date ? dateFormat(item.date, "dd.MM.yyyy") : "-",
        <CustomCell title={item.comment}>
          <p>{item.comment}</p>
        </CustomCell>,
        <StyledButton
          onClick={() => {
            draftSigners.splice(index, 1);
            setDraftSigners([...draftSigners]);
          }}
        >
          <IconTrash24 />
        </StyledButton>,
      ];
    },
    [draftSigners, getUserList]
  );

  const sortedDraftSigners = useMemo(() => {
    return draftSigners.sort((a, b) => {
      if (!isParallel) {
        return a.ord - b.ord;
      }

      if (a.date && b.date) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      }
      return 0;
    });
  }, [draftSigners, isParallel]);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => sortedDraftSigners.map(mapTableData),
    [sortedDraftSigners, mapTableData]
  );

  const onSignerAdd = () => {
    if (draftSigners.length >= employees.length) {
      return;
    }
    setDraftSigners([
      ...draftSigners,
      {
        taskTypeId: 7,
        user: null,
        ord: 0,
      },
    ]);
  };

  const onSigningTypeChange = useCallback(
    (val: boolean) => {
      setParallel(val);
      setDraftSigners([...draftSigners]);
    },
    [draftSigners]
  );

  const onRecipientChange = useCallback(
    (userIds: string[]) => {
      if (rejectedStatuses) {
        setSelectedRecipientIds(userIds);
      }
    },
    [rejectedStatuses]
  );

  return (
    <StyledStep5>
      <ToggleContainer>
        <Toggle
          negativeLabel="Последовательное"
          positiveLabel="Параллельное согласование"
          value={isParallel}
          onClick={onSigningTypeChange}
        />
      </ToggleContainer>
      {contract && (
        <CompanyNotificationRecipients
          companyId={contract.customer.id}
          projectId={contract.project.id}
          typeId={CompanyProjectRecipientsType.Contracts}
          userIds={selectedRecipientIds}
          onChange={onRecipientChange}
        />
      )}
      <StyledContent>
        <h6>Лица согласования</h6>
        <div>
          <Table
            labels={tableLabels}
            data={tableData}
            disableOverflow={true}
            emptyTitle={"Добавьте подписантов"}
          />
        </div>
        <ApproveListView histories={contract.histories} />
      </StyledContent>
      <StyledFooter>
        <Button onClick={onClickPrev} text="Назад" variant="outlined" />
        <Button
          onClick={onSignerAdd}
          text="Добавить подписантов"
          disabled={draftSigners.length >= employees.length}
          startIcon={IconPlus24}
        />
      </StyledFooter>
    </StyledStep5>
  );
}

export default memo(Step5);
