import { UserTaskStatuses, UserTaskStatusesName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: UserTaskStatuses;
}

const getStatusVariant = (status: UserTaskStatuses): ChipColor => {
  switch (status) {
    case UserTaskStatuses.SigningContract:
    case UserTaskStatuses.SigningAvr:
    case UserTaskStatuses.SignedContract:
    case UserTaskStatuses.SignedAvr:
      return "warning";
    case UserTaskStatuses.Acquainted:
    case UserTaskStatuses.Redirected:
      return "primary";
    case UserTaskStatuses.Agreed:
      return "success";
    case UserTaskStatuses.Rejected:
    case UserTaskStatuses.Cancelled:
      return "danger";
    default:
      return "default";
  }
};

function TaskStatusChip(props: Props) {
  const { status } = props;

  return (
    <Chip
      text={UserTaskStatusesName[status]}
      color={getStatusVariant(status)}
    />
  );
}

export default memo(TaskStatusChip);
