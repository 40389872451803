import React, { memo, useMemo } from "react";
import { Modal, ModalProps } from "@app/components";
import { VAERequest } from "@app/models";
import { VAERequestDetail } from "@app/common";
import { FormVAERequestOffer } from "@app/forms";

interface Props extends Omit<ModalProps, "title"> {
  request: VAERequest;
  hideInfo?: boolean;
  onSuccess: () => void;
}

function ModalVehicleRequestOffer(props: Props) {
  const { request, hideInfo = false, onSuccess, ...modalProps } = props;

  const modalTitle = useMemo(() => "Подача предложения", []);

  const actions = useMemo(() => [], []);

  if (!modalProps.open) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      title={modalTitle}
      actions={actions}
      dense={true}
      size="medium"
    >
      {!hideInfo && (
        <VAERequestDetail request={request} variant="secondary" columns={3} />
      )}
      <FormVAERequestOffer request={request} onSuccess={onSuccess} />
    </Modal>
  );
}

export default memo(ModalVehicleRequestOffer);
