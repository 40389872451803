import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  APIResponse,
  confirmCompanyJoinRequest,
  getCompanyJoinRequests,
} from "@app/api";
import { useNotification } from "@app/providers";
import { Button, Checkbox, Grid, Loader } from "@app/components";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { Employee } from "@app/models";
import ModalAccessLevel from "../ModalAccessLevel";

interface Props {
  companyId: string | null;
  onConfirm: () => void;
  onJoinRequestsCount: (count: number) => void;
}

const StyledCompanyJoinRequests = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;

const JoinRequestWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const JoinRequestName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px 0 0;
  color: ${(props) => props.theme.color.dark};
`;

function JoinRequestComponent(
  props: Employee & {
    onConfirm: (employee: Employee) => void;
    companyId: string;
  }
) {
  const { isOwner, user, companyId, onConfirm } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [accessLevel, setAccessLevel] = useState<number>(2);

  const fullName = useMemo(
    () =>
      `${user.lastname || ""} ${user.firstname || ""} ${
        user.middlename || ""
      }`.trim(),
    [user]
  );

  const onClickConfirm = useCallback(() => {
    setAccessLevel(2);
    setShowDialog(true);
  }, []);

  const onConfirmAccessLevelModal = useCallback(async () => {
    if (accessLevel === null) {
      showNotification({
        variant: "error",
        message: "Выберите уровень доступа",
      });
      return;
    }

    try {
      setPending(true);

      const response = await confirmCompanyJoinRequest({
        userId: user.id,
        companyId,
        accessLevel,
      });

      if (!response.succeeded) {
        showNotification({
          variant: "error",
          message: response.message,
        });
      } else {
        showNotification({
          message: `${fullName} добавлен`,
          variant: "success",
        });
      }

      setPending(false);
      setShowDialog(false);

      onConfirm({
        user,
        isOwner,
      });
    } catch (e) {
      setPending(false);
      setShowDialog(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    accessLevel,
    companyId,
    fullName,
    isOwner,
    onConfirm,
    showNotification,
    user,
  ]);

  return (
    <JoinRequestWrapper>
      <JoinRequestName>{fullName}</JoinRequestName>
      <Button
        onClick={onClickConfirm}
        text="Добавить"
        size="small"
        disabled={pending}
        showLoader={pending}
      />
      <ModalAccessLevel
        open={showDialog}
        title={`Уровень доступа`}
        subtitle={`Выберите уровень доступа`}
        children={
          <>
            <div style={{ marginTop: 24 }}>
              <Checkbox
                label="Админ"
                checked={accessLevel === 1}
                onChange={() => setAccessLevel(1)}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              <Checkbox
                label="Сотрудник"
                checked={accessLevel === 2}
                onChange={() => setAccessLevel(2)}
              />
            </div>
          </>
        }
        onConfirm={onConfirmAccessLevelModal}
        confirmLabel={"Подтвердить"}
        onClose={() => setShowDialog(false)}
      />
    </JoinRequestWrapper>
  );
}

function CompanyJoinRequests(props: Props) {
  const { companyId, onJoinRequestsCount } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);
  const [joinRequests, setJoinRequests] = useState<Employee[]>([]);

  const getData = useCallback(async () => {
    try {
      setPending(true);

      const response = await getCompanyJoinRequests(companyId!);

      if (response.succeeded) {
        setJoinRequests(response.data);
        onJoinRequestsCount(response.data.length);
      } else {
        showNotification({
          variant: "error",
          message: "Ошибка! Попробуйте позднее или обратитесь к администратору",
        });
      }

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [showNotification, companyId, onJoinRequestsCount]);

  const onConfirm = useCallback((employee: Employee) => {
    setJoinRequests((prevJoinRequests) =>
      prevJoinRequests.filter((item) => item.user.id !== employee.user.id)
    );
  }, []);

  useEffect(() => {
    if (!!companyId) {
      getData();
    }
  }, [getData, companyId]);

  if (pending) {
    return <Loader />;
  }

  return (
    <StyledCompanyJoinRequests>
      <Grid>
        {joinRequests.map((employee) => (
          <JoinRequestComponent
            key={employee.user.id}
            {...employee}
            onConfirm={onConfirm}
            companyId={companyId as string}
          />
        ))}
      </Grid>
    </StyledCompanyJoinRequests>
  );
}

export default memo(CompanyJoinRequests);
