import { Info } from "@app/components";
import React, { FC } from "react";
import { CompanyInfo } from "@app/models";
import { StyledGrid } from "./styled";

type Props = {
  company: CompanyInfo | null;
};

const AddressListView: FC<Props> = ({ company }) => {
  if (!company) {
    return null;
  }

  return (
    <StyledGrid>
      {company.addresses.map((item) => (
        <React.Fragment key={item.id}>
          <Info label="Регион" value={item.region.name ?? "—"} />
          <Info label="Улица" value={item.street ?? "—"} />
          <Info label="№ дома" value={item.block ?? "—"} />
          <Info label="№ офиса/квартиры" value={item.apartment ?? "—"} />
        </React.Fragment>
      ))}
    </StyledGrid>
  );
};

export default AddressListView;
