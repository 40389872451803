import React, { memo, useCallback, useMemo } from "react";
import { Button, Modal, ModalProps } from "@app/components";
import { CompanyRequisites } from "@app/common";
import styled from "styled-components";
import {
  BankAccount,
  CompanyAddress,
  SmartContract,
  SmartContractSigner,
} from "@app/models";
import { useNotification } from "@app/providers";

export interface Requisites {
  customerSigner: SmartContractSigner | null;
  partnerSigner: SmartContractSigner | null;
  customerBankAccount: BankAccount | null;
  partnerBankAccount: BankAccount | null;
  customerAddress: CompanyAddress | null;
  partnerAddress: CompanyAddress | null;
}

interface Props extends Omit<ModalProps, "title"> {
  contract: SmartContract;
  requisites: Requisites;
  onChangeRequisites: (requisites: Partial<Requisites>) => void;
}

const StyledContent = styled.div`
  height: 100%;
  background-color: #f5f6f8;
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: max-content;
`;

function ModalRequisites(props: Props) {
  const { contract, onClose, requisites, onChangeRequisites, ...restProps } =
    props;
  const { showNotification } = useNotification();
  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const onChangeCustomerBankAccount = useCallback(
    (customerBankAccount: BankAccount) => {
      onChangeRequisites({
        customerBankAccount,
      });
    },
    [onChangeRequisites]
  );

  const onChangeCustomerSigner = useCallback(
    (customerSigner: SmartContractSigner) => {
      onChangeRequisites({
        customerSigner,
      });
    },
    [onChangeRequisites]
  );

  const onChangePartnerBankAccount = useCallback(
    (partnerBankAccount: BankAccount) => {
      onChangeRequisites({
        partnerBankAccount,
      });
    },
    [onChangeRequisites]
  );

  const onChangePartnerSigner = useCallback(
    (partnerSigner: SmartContractSigner) => {
      onChangeRequisites({
        partnerSigner,
      });
    },
    [onChangeRequisites]
  );

  const onClickSave = useCallback(() => {
    if (!requisites.customerSigner || !requisites.customerBankAccount) {
      return showNotification({
        variant: "error",
        message: "Укажите реквизиты заказчика",
      });
    }

    if (!requisites.partnerSigner || !requisites.partnerBankAccount) {
      return showNotification({
        variant: "error",
        message: "Укажите реквизиты исполнителя",
      });
    }

    onClose();
  }, [
    onClose,
    requisites.customerBankAccount,
    requisites.customerSigner,
    requisites.partnerBankAccount,
    requisites.partnerSigner,
    showNotification,
  ]);

  const modalTitle = useMemo(() => "Реквизиты", []);

  const modalActions = useMemo(
    () => [<Button text="Сохранить" onClick={onClickSave} />],
    [onClickSave]
  );

  if (!restProps.open) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      onClose={onCloseModal}
      {...restProps}
      size="large"
      dense={true}
      actions={modalActions}
    >
      <StyledContent>
        <CompanyRequisites
          signer={requisites.customerSigner}
          bankAccount={requisites.customerBankAccount}
          company={contract.customer}
          companyLabel="Заказчик"
          bankAccountLabel="Счет заказчика"
          requisitesLabel="Реквизиты заказчика"
          onChangeBankAccount={onChangeCustomerBankAccount}
          onChangeSigner={onChangeCustomerSigner}
        />
        <CompanyRequisites
          signer={requisites.partnerSigner}
          bankAccount={requisites.partnerBankAccount}
          company={contract.partner}
          companyLabel="Исполнитель"
          bankAccountLabel="Реквизиты исполнителя"
          requisitesLabel="Счет исполнителя"
          onChangeBankAccount={onChangePartnerBankAccount}
          onChangeSigner={onChangePartnerSigner}
        />
      </StyledContent>
    </Modal>
  );
}

export default memo(ModalRequisites);
