import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageKIP } from "../index";
import { KIPProvider } from "@app/providers";

interface Props {}

export default function () {
  return (
    <Routes>
      <Route
        path="kip"
        element={
          <KIPProvider>
            <PageKIP />
          </KIPProvider>
        }
      />
    </Routes>
  );
}
