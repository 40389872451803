import React, { FC, useMemo } from "react";
import { CompanyFormType, CompanyInfo } from "@app/models";
import { getDictionaryName } from "@app/helpers";
import { Info } from "@app/components";
import { StyledGrid } from "./styled";

type Props = {
  company: CompanyInfo | null;
  companyTypes: CompanyFormType[];
};

const GeneralInformationView: FC<Props> = ({ company, companyTypes }) => {
  const companyTypeName = useMemo(() => {
    const findType = companyTypes.find((item) => item.id === company?.typeId);
    return findType?.name ?? "—";
  }, [company?.typeId, companyTypes]);

  if (!company) {
    return null;
  }

  return (
    <StyledGrid>
      <Info label="Наименование организации" value={company.name} />
      <Info label="Страна" value={getDictionaryName(company.country)} />
      <Info
        label="Регистрационный номер"
        value={company.registrationNumber ?? ""}
      />
      <Info label="Регион" value={"???"} />
      <Info label="Форма организации" value={companyTypeName} />
      <Info label="ИИН/БИН" value={company.bin} />
      <Info label="Лицо" value={"???"} />
      <Info label="ФИО Директора" value={company.headName ?? "—"} />
      <Info label="ИИН/БИН директора" value={company.headIdn ?? "—"} />
      <Info label="КБЕ" value={company.kbe} />
      <Info label="Ставка НДС" value={company.nds?.name ?? "—"} />
      {/* Контакты организации */}
      <h6>Контакты организации</h6>
      <Info label="Номер телефона" value={company.phone ?? "—"} />
      <Info label="E-mail" value={company.email ?? "—"} />
    </StyledGrid>
  );
};

export default GeneralInformationView;
