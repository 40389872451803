import React, { useCallback, useEffect, useRef, useState } from "react";
import Field, { FieldProps } from "../Field";
import { genericMemo } from "@app/helpers";
import "./styles.scss";
import { IconChevronDown24, IconClose24, IconInfo16 } from "@app/icons";
import { Checkbox, Paper } from "../index";
import styled from "styled-components";
import { theme } from "styled-tools";

interface Props<T extends { name: string; id: string }> extends FieldProps {
  options?: T[];
  valueKey?: keyof T;
  labelKey?: keyof T;
  values?: T[];
  placeholder?: string;
  onChange: (value: T[]) => void;
  companyStatuses?: { [companyId: string]: number };
  companies?: any;
  onClear?: () => void;
  showChips?: boolean;
}

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.div<{ disabled?: boolean }>`
  height: 40px;
  display: flex;
  padding: 0 36px 0 12px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  background: ${(props) =>
    theme(props.disabled ? "color.disabled" : "color.white")};
  cursor: pointer;

  :hover {
    button {
      visibility: visible;
    }
  }

  .placeholder {
    color: ${theme("color.gray")};
  }

  button {
    visibility: hidden;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 16px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    opacity: 0.3;

    &:hover {
      opacity: 1;
      background-color: #eee;
    }

    &:active {
      transform: scale(0.8);
    }
  }
`;

const StyledDropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 4px;
  z-index: 1;

  > div {
    padding: 0;
  }
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 12px 16px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;

    &:hover {
      background: ${theme("color.blueLight")};
    }
  }
`;

const getStatusColor = (statusId: number): string => {
  switch (statusId) {
    case 10:
      return "#D8D8D8";
    case 20:
      return "#FFB84E";
    case 30:
      return "#5BCD71";
    case 40:
      return "#DA616C";
    case 50:
      return "#DA616C";
    default:
      return "#FFF";
  }
};

const Chip = styled.div<{ statusId: any }>`
  padding: 8px;
  margin: 4px;
  display: inline-flex;
  align-items: center;
  background-color: ${({ statusId }) =>
    statusId ? getStatusColor(statusId) : "#ffffff"};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ statusId }) =>
    statusId ? getStatusColor(statusId) : "#ACACAC"};
  border-radius: 56px;

  .chip-label {
    margin-right: 8px;
    color: ${({ statusId }) => (statusId === 40 ? "#FFF" : "#000")};
  }

  .chip-close {
    cursor: pointer;
    border: none;
    background: transparent;
    border-radius: 50%;
    margin-left: 4px;

    &:hover {
      //background-color: none;
    }
  }
`;

const TooltipContainer = styled.div`
  display: none;
  position: absolute;
  min-width: 350px;
  min-height: 40px;
  background-color: ${theme("color.dark")};
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 100;
  box-sizing: border-box;
  margin-top: 2px;
  word-break: break-word;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  word-wrap: break-word;

  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

const InfoIcon = styled.div`
  display: flex;
  height: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${theme("color.dark")};
  cursor: pointer;
  position: relative;

  &:hover ${TooltipContainer} {
    display: flex;
  }
`;

const MultiSelect = <T extends { name: string; id: string }>({
  options = [],
  values = [],
  label,
  valueKey = "id",
  labelKey = "name",
  placeholder = "Выберите из списка",
  onChange,
  onClear,
  companyStatuses,
  companies,
  showChips = false,
  ...fieldProps
}: Props<T>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [paperVisible, setPaperVisible] = useState<boolean>(false);
  // const [selected, setSelected] = useState(values);

  const onChangeHandler = (item: T, index: number) => {
    if (index >= 0) {
      values.splice(index, 1);
    } else {
      values?.push(item);
    }
    // setSelected([...selected]);
    onChange([...values]);
  };

  const onClickRemoveChip = useCallback(
    (item: T) => {
      const newValues = values.filter((v) => v[valueKey] !== item[valueKey]);
      onChange(newValues);
    },
    [onChange, valueKey]
  );

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target as HTMLElement)
    ) {
      setPaperVisible(false);
    }
  }, []);

  const onInputClick = useCallback(() => {
    setPaperVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  const renderOptions = () => {
    return options.map((item, index) => {
      const idx = values?.findIndex((v) => v[valueKey] === item[valueKey]);
      return (
        <li key={index.toString()} onClick={() => onChangeHandler(item, idx)}>
          <Checkbox onChange={() => {}} checked={idx >= 0} />
          {item[labelKey] as string}
        </li>
      );
    });
  };

  const renderChips = () => {
    return values
      .filter((item) => companyStatuses?.[item.id] !== 50)
      .map((item: any) => {
        const companyData = companies.find(
          (company: any) => company.companyId === item.id
        );
        const statusId = companyStatuses?.[item.id];
        const comment = statusId ? companyData?.comment : "";
        return (
          <Chip key={item[valueKey]} statusId={statusId}>
            <span className="chip-label">{item[labelKey]}</span>
            {statusId === 40 && (
              <InfoIcon>
                <IconInfo16 />
                <TooltipContainer>{`Причина отклонения техники: ${comment}`}</TooltipContainer>
              </InfoIcon>
            )}
            <button
              className="chip-close"
              onClick={() => onClickRemoveChip(item)}
            >
              ×
            </button>
          </Chip>
        );
      });
  };

  return (
    <StyledWrapper ref={wrapperRef}>
      <Field
        label={label}
        appendIcon={<IconChevronDown24 />}
        onAppendClick={onInputClick}
        {...fieldProps}
      >
        <StyledInput disabled={fieldProps.disabled} onClick={onInputClick}>
          {values?.length ? (
            <>
              <p>Выбрано ({values.length})</p>
              {!!onClear && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                >
                  <IconClose24 />
                </button>
              )}
            </>
          ) : (
            <p className="placeholder">{placeholder}</p>
          )}
        </StyledInput>
      </Field>
      {paperVisible && (
        <StyledDropdown>
          <Paper shadow={true}>
            <StyledList>{renderOptions()}</StyledList>
          </Paper>
        </StyledDropdown>
      )}
      {showChips && <div>{renderChips()}</div>}
    </StyledWrapper>
  );
};

export default genericMemo(MultiSelect);
