import React, { ComponentProps, createRef, LegacyRef } from "react";
import Field, { FieldProps } from "../Field";
import classNames from "classnames";
import { genericMemo } from "@app/helpers";
import { PageableParams, PageableResponse } from "@app/api";
import "./styles.scss";
import { IconChevronDown24, IconClose24 } from "@app/icons";

export interface SelectOption<T> {
  label: string;
  value: any;
  item?: T;
}

interface Props<T> extends FieldProps {
  options?: SelectOption<T>[];
  valueKey?: keyof T;
  labelKey?: keyof T;
  labelKeys?: (keyof T)[];
  labelKeysSeparator?: string;
  value?: SelectOption<T> | null;
  onChange?: (value: SelectOption<T> | null, name: any) => void;
  onInputChange?: (value: string, name: any) => void;
  onClear?: () => void;
  loadData?: (params: PageableParams<T>) => Promise<PageableResponse<T>>;
  orderBy?: keyof T;
  readonly?: boolean;
}

interface State<T> {
  pending: boolean;
  pendingMore: boolean;
  hasMore: boolean;
  paperVisible: boolean;
  page: number;
  searchText: string;
  options: SelectOption<T>[];
}

class Select<T> extends React.Component<Props<T>, State<T>> {
  fieldRef = createRef<HTMLDivElement>();
  inputRef = createRef<HTMLInputElement>();
  observer: IntersectionObserver | null = null;
  timeout: NodeJS.Timeout | null = null;

  state: State<T> = {
    pending: !!this.props.loadData && !this.props.disabled,
    pendingMore: false,
    hasMore: !!this.props.loadData,
    paperVisible: false,
    page: 1,
    searchText: !!this.props.value ? this.props.value.label : "",
    options: this.props.options || [],
  };

  async componentDidMount() {
    document.addEventListener("click", this.onClickOutside, true);

    if (!!this.props.loadData && !this.props.disabled) {
      await this.getData();
    }
  }

  componentWillReceiveProps(nextProps: Readonly<Props<T>>) {
    if (JSON.stringify(this.props) === JSON.stringify(nextProps)) {
      return;
    }

    if (
      !this.state.pending &&
      !nextProps.disabled &&
      !!nextProps.loadData &&
      (!!nextProps.labelKey || !!nextProps.labelKeys)
    ) {
      this.setState(
        {
          pending: true,
          options: [],
          searchText: !!nextProps.value ? nextProps.value.label : "",
          page: 1,
        },
        async () => {
          await this.getData();
        }
      );

      return;
    }

    this.setState({
      options:
        !nextProps.loadData && !!nextProps.options
          ? nextProps.options
          : this.state.options,
      searchText: !!nextProps.value ? nextProps.value.label : "",
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClickOutside, true);
  }

  getData = (): Promise<void> => {
    return new Promise(async (resolve, _reject) => {
      try {
        const {
          orderBy,
          loadData,
          labelKeys,
          labelKey,
          valueKey,
          labelKeysSeparator,
        } = this.props;
        const { page, searchText } = this.state;

        const params: PageableParams<T> = {
          pageNumber: page,
          pageSize: 10,
          searchText,
        };

        if (!!orderBy) {
          params.orderBy = orderBy;
        }

        const response = await loadData!(params);

        this.setState(
          (prevState) => ({
            options: [
              ...prevState.options,
              // фильтруем списки, иногда попадается null
              ...response.data.filter(Boolean).map((item) => ({
                label: !!labelKeys
                  ? labelKeys
                      .map((key) => item[key] as string)
                      .join(labelKeysSeparator || " ")
                  : (item[labelKey!] as string),
                value: item[valueKey!] as string,
                item,
              })),
            ],
            hasMore:
              Math.ceil(response.recordsFiltered / response.pageSize) > page,
            pending: false,
            pendingMore: false,
          }),
          resolve
        );
      } catch (e) {
        this.setState(
          {
            pending: false,
            pendingMore: false,
          },
          resolve
        );
      }
    });
  };

  lastElementRef = (node: Element) => {
    const { pendingMore, hasMore } = this.state;

    if (pendingMore) return;

    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        this.setState(
          (prevPage) => ({
            page: prevPage.page + 1,
          }),
          async () => {
            await this.getData();
          }
        );
      }
    });

    if (node) this.observer.observe(node);
  };

  onClickOutside = (e: MouseEvent) => {
    if (
      this.fieldRef.current &&
      !this.fieldRef.current.contains(e.target as HTMLElement)
    ) {
      this.setState({
        paperVisible: false,
      });
    }
  };

  onClearClick = () => {
    const { onClear, onChange, name, value } = this.props;

    if (!!onClear) {
      this.onChangeSearchText(null, false);

      return onClear();
    }

    if (!value) {
      return;
    }

    this.onChangeSearchText(null, false);

    if (!!onChange) {
      onChange(null, name);
    }
  };

  onChangeSearchText = (
    e: React.ChangeEvent<HTMLInputElement> | null,
    paperVisible = true
  ) => {
    const searchText = e ? e.target.value : "";

    this.setState(
      {
        searchText,
        paperVisible: false,
      },
      () => {
        if (!this.props.loadData && !!this.props.options) {
          this.setState({
            options: this.props.options.filter(
              (option) =>
                option.label
                  .toLocaleLowerCase()
                  .indexOf(searchText.toLocaleLowerCase()) > -1
            ),
            paperVisible: true,
          });

          return;
        }

        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.setState(
            {
              options: [],
              page: 1,
              pending: true,
            },
            async () => {
              await this.getData();

              if (!!this.props.onInputChange) {
                this.props.onInputChange(searchText, this.props.name);
              }

              this.setState(
                {
                  paperVisible,
                },
                () => {
                  if (paperVisible) {
                    this.inputRef.current?.focus();
                    this.onFocus();
                  }
                }
              );
            }
          );
        }, 800);
      }
    );
  };

  onFocus = () => {
    if (this.props.readonly) {
      return;
    }

    this.setState((prevState) => ({
      paperVisible:
        prevState.options.length > 0
          ? !prevState.paperVisible
          : prevState.paperVisible,
    }));
  };

  onClickOption =
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const { onChange, name } = this.props;
      const { options } = this.state;
      const option = options.find((_, optionIndex) => optionIndex === index)!;

      if (!!onChange) {
        onChange(option, name);
      }

      this.setState((prevState) => ({
        paperVisible: false,
        searchText: option.label,
        options: !!this.props.loadData ? [] : prevState.options,
      }));
    };

  renderItem = (option: SelectOption<T>, optionIndex: number) => {
    const { value } = this.props;
    const { options } = this.state;

    const isLastElement = options.length === optionIndex + 1;
    const buttonProps: ComponentProps<"button"> = {};

    if (isLastElement) {
      buttonProps.ref = this.lastElementRef as LegacyRef<HTMLButtonElement>;
    }

    return (
      <button
        {...buttonProps}
        onClick={this.onClickOption(optionIndex)}
        className={classNames("b-select__option", {
          "b-select__option--selected": !!value && value.value === option.value,
        })}
        key={optionIndex.toString()}
      >
        {option.label}
      </button>
    );
  };

  render() {
    const {
      className = "",
      placeholder = "Выберите из списка",
      labelKey,
      labelKeys,
      valueKey,
      onChange,
      name,
      orderBy,
      loadData,
      disabled = false,
      readonly = false,
      ...fieldProps
    } = this.props;

    const { options, paperVisible, searchText, pending } = this.state;

    return (
      <Field
        {...fieldProps}
        disabled={disabled || pending}
        ref={this.fieldRef}
        className={`b-select ${className}`.trim()}
        appendIcon={<IconChevronDown24 />}
        onClick={this.onFocus}
      >
        <div
          className={classNames("b-select__trigger", {
            "b-select__trigger--open": paperVisible,
          })}
        >
          <input
            ref={this.inputRef}
            type="text"
            value={searchText}
            onChange={this.onChangeSearchText}
            placeholder={placeholder}
            disabled={disabled || pending}
            readOnly={readonly}
          />
          {!readonly && (
            <button className="b-select__clear-btn" onClick={this.onClearClick}>
              <IconClose24 />
            </button>
          )}
        </div>
        <div
          className={classNames("b-select__paper", {
            "b-select__paper--visible": paperVisible,
          })}
        >
          {options.map(this.renderItem)}
        </div>
      </Field>
    );
  }
}

export default genericMemo(Select);
