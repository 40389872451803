import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ifProp, prop, theme } from "styled-tools";
import {
  Button,
  Checkbox,
  DatePeriodPicker,
  Grid,
  TextField,
} from "@app/components";
import { IconCheck20, IconFilter24 } from "@app/icons";
import { VehiclesFilter, VehiclesFilterParams } from "../index";
import { useMonitoring } from "@app/providers";

export const MonitoringStatusColors: Record<number, string> = {
  0: "#1C2246",
  10: "#5BCD71",
  20: "#FFB84E",
  30: "#DA616C",
  40: "#ACACAC",
};

const StyledHeader = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
  padding: 12px 16px;
`;

const StyledLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 16px 0 12px;
  color: ${theme("color.dark")};
`;

const StyledResetFilter = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme("color.dark")};
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSearchBar = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
`;

const StyledHeaderFilter = styled.div<{
  visible: boolean;
}>`
  position: relative;
  overflow: ${ifProp("visible", "visible", "hidden")};
`;

const StyledVehiclesFilter = styled.div<{
  visible: boolean;
}>`
  position: absolute;
  top: 0;
  left: 100%;
  overflow: ${ifProp("visible", "visible", "hidden")};
`;

const StyledCheckboxes = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 6px;
  margin-bottom: 20px;
`;

const StyledCheckboxContainer = styled.div`
  width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledCheckbox = styled.button<{
  color: string;
}>`
  width: 24px;
  height: 24px;
  padding: 2px;
  position: relative;
  background-color: ${prop("color")};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  outline: none;
  border: none;
  color: ${theme("color.white")};
  display: block;
  box-sizing: border-box;

  &:hover {
    > p {
      display: block;
    }
  }
`;

const StyledCheckboxLabel = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${theme("color.dark")};
`;

const StyledCheckboxHint = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${theme("color.white")};
  background-color: ${theme("color.dark")};
  padding: 12px 16px;
  box-sizing: border-box;
  position: absolute;
  bottom: calc(100% + 8px);
  display: none;
  width: max-content;
  left: -6px;
  border-radius: 4px;
`;

const StyledCheckAllContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export interface MonitoringVehiclesFilter {
  dateMax: Date;
  dateMin: Date;
  govNumber: string;
}

interface Props {
  gpsStatus: number | null;
  dateMin: Date | null;
  dateMax: Date | null;
  govNumber: string;
  onChangeGpsStatus: (value: number | null) => void;
  onChangeDateMin: (value: Date | null) => void;
  onChangeDateMax: (value: Date | null) => void;
  onChangeGovNumber: (value: string) => void;
  onCheckAll: () => void;
  onSubmitFilter: (values: VehiclesFilterParams) => void;
  defaultFilter: VehiclesFilterParams;
  disabled: boolean;
  statusData: any[];
}

export default function (props: Props) {
  const {
    gpsStatus,
    dateMin,
    dateMax,
    govNumber,
    onChangeGpsStatus,
    onChangeDateMin,
    onChangeDateMax,
    onChangeGovNumber,
    onCheckAll,
    onSubmitFilter: onSubmitFilterProps,
    defaultFilter,
    statusData = [],
    disabled,
  } = props;

  const { vehicles, setVehicles } = useMonitoring();
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const onClickFilter = useCallback(() => {
    setFilterVisible((prevFilterVisible) => !prevFilterVisible);
  }, []);

  const hideFilter = useCallback(() => {
    if (filterVisible) {
      setFilterVisible(false);
    }
  }, [filterVisible]);

  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        hideFilter();
      }
    },
    [hideFilter]
  );

  const onSubmitFilter = useCallback(
    (values: VehiclesFilterParams) => {
      hideFilter();

      onSubmitFilterProps(values);
    },
    [hideFilter, onSubmitFilterProps]
  );

  const onClickGpsStatus = useCallback(
    (value: number | null) => (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      onChangeGpsStatus(value);
    },
    [onChangeGpsStatus]
  );

  const onChangeCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      setCheckAll(checked);

      if (checked) {
        onCheckAll();
      } else {
        setVehicles([]);
      }
    },
    [setVehicles, onCheckAll]
  );

  const onClickClear = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setCheckAll(false);
      setVehicles([]);
      onChangeGpsStatus(null);
    },
    [setVehicles, onChangeGpsStatus]
  );

  const onDateRangeChange = useCallback(
    (dates: [Date, Date] | null) => {
      onChangeDateMin(dates ? dates[0] : null);
      onChangeDateMax(dates ? dates[1] : null);
    },
    [onChangeDateMax, onChangeDateMin]
  );

  const checkboxes = useMemo<
    {
      status: number | null;
      color: string;
      text: string;
      label: string;
    }[]
  >(
    () => [
      {
        status: 10,
        color: MonitoringStatusColors[10],
        text: "Данные поступили за последние 30 мин",
        label: statusData.find((item) => item.statusId === 10)?.quantity || 0,
      },
      {
        status: 20,
        color: MonitoringStatusColors[20],
        text: "Данные поступили от 30 мин до 24 ч назад",
        label: statusData.find((item) => item.statusId === 20)?.quantity || 0,
      },
      {
        status: 30,
        color: MonitoringStatusColors[30],
        text: "Данные не поступали более 24 ч 00 мин",
        label: statusData.find((item) => item.statusId === 30)?.quantity || 0,
      },
      {
        status: 40,
        color: MonitoringStatusColors[40],
        text: "Данные отсутствует в системе",
        label: statusData.find((item) => item.statusId === 40)?.quantity || 0,
      },
      {
        status: null,
        color: MonitoringStatusColors[0],
        text: "Отразить все ТС",
        label: statusData.reduce((acc, item) => acc + item.quantity, 0),
      },
    ],
    [statusData]
  );

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  useEffect(() => {
    if (vehicles.length === 0) {
      setCheckAll(false);
    }
  }, [vehicles]);

  return (
    <StyledHeader>
      <Grid gap={8}>
        <DatePeriodPicker
          label={"Период"}
          startDate={dateMin}
          endDate={dateMax}
          maxDate={new Date()}
          onChange={onDateRangeChange}
        />
        {/*<DateTimePicker
          onChange={onChangeDateMin}
          value={dateMin}
          maxDate={dateMax}
          label="Период от"
          placeholder="Период от"
          disabled={disabled}
        />
        <DateTimePicker
          onChange={onChangeDateMax}
          minDate={dateMin}
          value={dateMax}
          label="Период до"
          placeholder="Период до"
          maxDate={new Date()}
          disabled={disabled}
        />*/}
        <StyledSearchBar>
          <TextField
            value={govNumber}
            onChange={onChangeGovNumber}
            placeholder="Гос номер"
            disabled={disabled}
          />
          <StyledHeaderFilter visible={filterVisible} ref={ref}>
            <Button
              disabled={disabled}
              startIcon={IconFilter24}
              variant="outlined"
              onClick={onClickFilter}
            />
            <StyledVehiclesFilter visible={filterVisible}>
              <VehiclesFilter
                onSubmit={onSubmitFilter}
                defaultFilter={defaultFilter}
              />
            </StyledVehiclesFilter>
          </StyledHeaderFilter>
        </StyledSearchBar>
      </Grid>
      <StyledLabel>Поступление данных</StyledLabel>
      <StyledCheckboxes>
        {checkboxes.map((checkbox, checkboxIndex) => (
          <StyledCheckboxContainer
            key={checkboxIndex.toString()}
            onClick={onClickGpsStatus(checkbox.status)}
          >
            <StyledCheckbox color={checkbox.color}>
              <StyledCheckboxHint>{checkbox.text}</StyledCheckboxHint>
              {checkbox.status === gpsStatus && <IconCheck20 />}
            </StyledCheckbox>
            <StyledCheckboxLabel>{checkbox.label}</StyledCheckboxLabel>
          </StyledCheckboxContainer>
        ))}
      </StyledCheckboxes>
      <StyledCheckAllContainer>
        <Checkbox checked={checkAll} onChange={onChangeCheckAll} />
        <StyledResetFilter onClick={onClickClear}>
          Сбросить фильтр (
          {statusData.reduce((acc, item) => acc + item.quantity, 0)})
        </StyledResetFilter>
      </StyledCheckAllContainer>
    </StyledHeader>
  );
}
