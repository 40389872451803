import React, { useMemo } from "react";
import { KIPVehicleDetails } from "@app/models";
import styled from "styled-components";
import { Day } from "./components";

interface Props {
  data: KIPVehicleDetails[];
  variant?: "full" | "info";
  showTrack?: (data: KIPVehicleDetails) => void;
}

const StyledWrapper = styled.div`
  padding: 16px 0;
  overflow-y: auto;
`;

export default function (props: Props) {
  const { data, variant = "full", showTrack } = props;

  const sortedDates = useMemo(
    () =>
      data
        .sort((a, b) => {
          // Заменено на workStartDateTime, т.к. поле date имеет разный формат
          // (dd/mm или dd.mm) из-за чего некорректная сортировка
          const dateA = new Date(a.workStartDateTime).getTime();
          const dateB = new Date(b.workStartDateTime).getTime();

          return dateB - dateA;
        })
        .filter((item, itemIndex) =>
          variant === "full" ? true : itemIndex < 3
        ),
    [data, variant]
  );

  return (
    <StyledWrapper>
      {sortedDates.map((date, dateIndex) => (
        <Day
          key={dateIndex.toString()}
          data={date}
          variant={variant}
          showTrack={showTrack}
        />
      ))}
    </StyledWrapper>
  );
}
