import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalProps,
  PhoneField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import "./styles.scss";
import { useNotification, useUser } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { APIResponse, checkPhone } from "@app/api";
import { isValidPhoneNumber } from "react-phone-number-input";

interface Props extends Omit<ModalProps, "title"> {
  onClose: (val?: boolean) => void;
}

const ModalEnterPhone = (props: Props) => {
  const { onClose, open, ...restProps } = props;
  const { sendCode } = useUser();
  const { showNotification } = useNotification();
  const [isUserExist, setUserExist] = useState(true);

  const { values, errors, onChange, validate, setPending, resetForm, pending } =
    useForm<{
      phone: string;
    }>({
      values: {
        phone: "",
      },
      schema: yup.object().shape({
        phone: yup
          .string()
          .required()
          .test(
            "phone",
            "Укажите корректный номер телефона",
            (val) => !!val && isValidPhoneNumber(val)
          ),
      }),
      onSubmit: () => onClickSubmit(),
    });

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const res = await sendCode(values.phone);

      if (res) {
        onClose(true);
      }

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [validate, setPending, sendCode, values.phone, onClose, showNotification]);

  const checkPhoneNumber = useCallback(() => {
    if (isValidPhoneNumber(values.phone)) {
      setPending(true);
      checkPhone(values.phone.substring(1)).then((res) => {
        setPending(false);
        setUserExist(res);
        if (!res) {
          showNotification({
            message: "Пользователь не найден",
            variant: "error",
          });
        }
      });
    }
  }, [setPending, showNotification, values.phone]);

  useEffect(() => {
    checkPhoneNumber();
  }, [checkPhoneNumber]);

  const onModalClose = useCallback(() => {
    setUserExist(true);
    resetForm();
    onClose();
    // do not add 'resetForm' dependency
  }, [onClose]);

  return (
    <Modal
      open={open}
      title={"title"}
      position={"center"}
      size={"xsmall"}
      hideHeader
      onClose={onModalClose}
      {...restProps}
    >
      <div className="recovery-pwd-modal">
        <p className="recovery-pwd-modal__title">Введите номер телефона</p>
        <div className="recovery-pwd-modal__form">
          <PhoneField
            label="Номер телефона"
            placeholder="Введите номер телефона"
            value={values.phone}
            name="phone"
            error={!!errors.phone || !isUserExist}
            helperText={
              errors.phone ||
              (!isUserExist ? "Пользователь не найден" : undefined)
            }
            onChange={onChange}
          />
          <Button
            text="Продолжить"
            disabled={pending || !isUserExist}
            showLoader={pending}
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalEnterPhone);
