import React, { memo } from "react";
import { Info } from "@app/components";
import styled from "styled-components";
import { theme } from "styled-tools";
import { BankAccount, CompanyAddress, SmartContractSigner } from "@app/models";
import { getFullAddress, getFullName } from "@app/helpers";

interface Props {
  title: string;
  companyAddress: CompanyAddress;
  contractor: SmartContractSigner;
  bankAccount: BankAccount;
}

const StyledContractor = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const StyledContractorTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${theme("color.dark")};
`;

const StyledContractorInfo = styled.div`
  display: grid;
  grid-gap: 12px;
  border: 1px solid ${theme("color.grayLight")};
  padding: 16px;
  box-sizing: border-box;
`;

function Contractor(props: Props) {
  const { title, contractor, bankAccount, companyAddress } = props;

  return (
    <StyledContractor>
      <StyledContractorTitle>{title}</StyledContractorTitle>
      <StyledContractorInfo>
        <Info label="Подписант" value={getFullName(contractor)} size="small" />
        <Info label="Основание" value="-" />
        <Info label="Счет" value={bankAccount.accountNumber} size="small" />
        <Info
          label="Банковские реквизиты"
          value={`${bankAccount.bank.name}, ${bankAccount.bank.bik}`}
        />
        <Info
          label="Юр адрес"
          value={getFullAddress(companyAddress?.address)}
        />
        <Info label="Контакты" value={contractor.phone || "-"} size="small" />
      </StyledContractorInfo>
    </StyledContractor>
  );
}

export default memo(Contractor);
