import React, { memo } from "react";
import "./styles.scss";
import { FormSignUp } from "@app/forms";

interface Props {}

function PageSignUp() {
  return (
    <div className="p-sign-up">
      <div className="b-container">
        <div className="p-sign-up__content">
          {/*<IconLogo />*/}
          <h2>Регистрация</h2>
          <FormSignUp />
          <a href="/sign-in" className="p-sign-up__link">
            Уже зарегистрированы?
          </a>
        </div>
      </div>
    </div>
  );
}

export default memo(PageSignUp);
