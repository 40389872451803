import { DefaultObject, SelectOption } from "@app/components";

export const enumToOptions = <
  T extends number,
  TEnumValue extends string
>(data: { [key in T]: TEnumValue }): SelectOption<T>[] => {
  return Object.entries(data).map(([key, val]) => ({
    label: val as TEnumValue,
    value: key,
    item: key as unknown as T,
  }));
};

export const enumToArray = <T extends string | number | symbol>(
  data: Record<T, string>
): DefaultObject<T>[] => {
  return Object.entries(data).map(([key, val]) => ({
    id: key as keyof T,
    name: val as string,
  }));
};

export const objectToOptions = (obj: any) => {
  if (!obj) return null;
  return {
    label: obj.name,
    value: obj.id,
    item: obj,
  };
};
