import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Container } from "@app/components";
import { ModalContacts, Panel } from "./components";
import GPSTrackerImageSrc from "./gps-tracker.png";
import FuelLevelSensorImageSrc from "./fuel-level-sensor.png";
import MonitoringSystemImageSrc from "./monitoring-system.png";
import CheckmarkImageSrc from "./checkmark.png";
import content from "./content.json";
import {
  IconLocation28,
  IconRSS28,
  IconStatistic28,
  IconTracking28,
} from "@app/icons";
import { ifProp, theme } from "styled-tools";
import SectionTitle from "../SectionTitle";
import LogosSrc from "./logos.png";
import { useWindowWide } from "@app/hooks";
import Slider from "react-slick";
import "./styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderSettings = {
  dots: true,
  infinite: true,
  arrows: false,
  adaptiveHeight: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: "dot-custom",
};

const StyledSection = styled.section`
  padding: 40px 0;
  box-sizing: border-box;
  @media only screen and (max-width: 576px) {
    padding: 0;
  }
`;

const StyledTitle = styled(SectionTitle)`
  text-align: center;
  margin: 0 0 32px 0;

  @media only screen and (max-width: 576px) {
    margin: 0 0 16px 0;
  }
`;

const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  margin-bottom: 32px;
`;

const StyledButton = styled.button<{
  isActive: boolean;
}>`
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  border: 1px solid ${theme("color.grayLight")};
  cursor: pointer;
  background-color: ${ifProp(
    "isActive",
    theme("color.light"),
    theme("color.white")
  )};

  svg {
    color: ${theme("color.orange")};
  }

  span {
    font-size: 14px;
    line-height: 28px;
    color: ${theme("color.dark")};
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

const StyledText = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
  margin: 16px 0;
`;

const StyledImg = styled.img`
  width: 460px;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

function SectionGPS() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const isMobile = useWindowWide(576);

  const onClickShowModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onClickButton = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setActiveIndex(index);
    },
    []
  );

  const buttons = useMemo(
    () => [
      { label: content.gpsTracker.label, icon: IconTracking28 },
      { label: content.fuelLevelSensor.label, icon: IconRSS28 },
      { label: content.monitoringSystem.label, icon: IconLocation28 },
      { label: content.checkMark.label, icon: IconStatistic28 },
    ],
    []
  );

  if (isMobile) {
    return (
      <StyledSection id="section-gps">
        <Container>
          <StyledTitle>{content.title}</StyledTitle>
          <Slider {...sliderSettings}>
            <StyledSlide>
              <StyledButton isActive>
                <IconTracking28 />
                <span>{content.gpsTracker.label}</span>
              </StyledButton>
              <Panel
                title={content.gpsTracker.title}
                text={content.gpsTracker.text}
                imageSrc={GPSTrackerImageSrc}
                onClick={onClickShowModal}
              >
                <StyledText>Мониторинговые системы GPS:</StyledText>
                <StyledImg src={LogosSrc} alt="" />
              </Panel>
            </StyledSlide>
            <StyledSlide>
              <StyledButton isActive>
                <IconRSS28 />
                <span>{content.fuelLevelSensor.label}</span>
              </StyledButton>
              <Panel
                title={content.fuelLevelSensor.title}
                text={content.fuelLevelSensor.text}
                imageSrc={FuelLevelSensorImageSrc}
              />
            </StyledSlide>
            <StyledSlide>
              <StyledButton isActive>
                <IconLocation28 />
                <span>{content.monitoringSystem.label}</span>
              </StyledButton>
              <Panel
                title={content.monitoringSystem.title}
                text={content.monitoringSystem.text}
                imageSrc={MonitoringSystemImageSrc}
              />
            </StyledSlide>
            <StyledSlide>
              <StyledButton isActive>
                <IconStatistic28 />
                <span>{content.checkMark.label}</span>
              </StyledButton>
              <Panel
                title={content.checkMark.title}
                text={content.checkMark.text}
                imageSrc={CheckmarkImageSrc}
              />
            </StyledSlide>
          </Slider>
        </Container>
      </StyledSection>
    );
  }

  return (
    <StyledSection id="section-gps">
      <Container>
        <StyledTitle>{content.title}</StyledTitle>
        <StyledButtons>
          {buttons.map(({ label, icon: Icon }, buttonIndex) => (
            <StyledButton
              isActive={buttonIndex === activeIndex}
              onClick={onClickButton(buttonIndex)}
              key={buttonIndex.toString()}
            >
              <Icon />
              <span>{label}</span>
            </StyledButton>
          ))}
        </StyledButtons>
        {activeIndex === 0 && (
          <Panel
            title={content.gpsTracker.title}
            text={content.gpsTracker.text}
            imageSrc={GPSTrackerImageSrc}
            onClick={onClickShowModal}
          >
            <StyledText>Мониторинговые системы GPS:</StyledText>
            <StyledImg src={LogosSrc} alt="" />
          </Panel>
        )}
        {activeIndex === 1 && (
          <Panel
            title={content.fuelLevelSensor.title}
            text={content.fuelLevelSensor.text}
            imageSrc={FuelLevelSensorImageSrc}
          />
        )}
        {activeIndex === 2 && (
          <Panel
            title={content.monitoringSystem.title}
            text={content.monitoringSystem.text}
            imageSrc={MonitoringSystemImageSrc}
          />
        )}
        {activeIndex === 3 && (
          <Panel
            title={content.checkMark.title}
            text={content.checkMark.text}
            imageSrc={CheckmarkImageSrc}
          />
        )}
        <ModalContacts open={modalVisible} onClose={onCloseModal} />
      </Container>
    </StyledSection>
  );
}

export default memo(SectionGPS);
