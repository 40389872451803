import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import CalendarCustom, { ICalendarDay } from "./components/CalendarCustom";
import TextField from "../TextField";
import Button from "../Button";
import Grid from "../Grid/Grid";
import Select, { SelectOption } from "../Select";
import { setMask } from "@app/helpers";
import { useCalendarCustom } from "./components/useCalendarCustom";

interface DateTimeCalendarProps {
  value?: Date | null;
  onChange?: (value: Date | null, name: string) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  name: string;
  onClose?: () => void;
}

const DateTimeCalendar: React.FC<DateTimeCalendarProps> = ({
  value = null,
  onChange,
  minDate,
  maxDate,
  name,
  onClose,
}) => {
  const [date, setDate] = useState<Date>(value || new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [time, setTime] = useState<string>(format(new Date(), "HH:mm"));
  const [timeError, setTimeError] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<number>(
    selectedDate.getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    selectedDate.getMonth()
  );

  const { currentMonth, setCurrentMonth } = useCalendarCustom({
    date: value,
    currentMonth: selectedDate,
    setCurrentMonth: setSelectedDate,
  });

  const years = Array.from({ length: 50 }, (_, i) => {
    const year = new Date().getFullYear() - 25 + i;
    return { label: year.toString(), value: year };
  });

  const months = [
    { label: "Январь", value: 0 },
    { label: "Февраль", value: 1 },
    { label: "Март", value: 2 },
    { label: "Апрель", value: 3 },
    { label: "Май", value: 4 },
    { label: "Июнь", value: 5 },
    { label: "Июль", value: 6 },
    { label: "Август", value: 7 },
    { label: "Сентябрь", value: 8 },
    { label: "Октябрь", value: 9 },
    { label: "Ноябрь", value: 10 },
    { label: "Декабрь", value: 11 },
  ];

  const onYearChange = (
    selectedOption: SelectOption<number> | null,
    name: string
  ) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
      setCurrentMonth(new Date(selectedOption.value, selectedMonth, 1));
    }
  };

  const onMonthChange = (
    selectedOption: SelectOption<number> | null,
    name: string
  ) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
      setCurrentMonth(new Date(selectedYear, selectedOption.value, 1));
    }
  };

  useEffect(() => {
    const newDate = new Date(selectedYear, selectedMonth, 1);
    setSelectedDate(newDate);
    setCurrentMonth(newDate);
  }, [selectedYear, selectedMonth]);

  const handleDateClick = useCallback((day: ICalendarDay) => {
    if (!day.disabled) {
      setSelectedDate(day.day);
    }
  }, []);

  const onChangeTime = useCallback((value: string) => {
    const maskedValue = setMask(value.replace(/\D/g, ""), "##:##");
    const maskedValueParts = maskedValue.split(":");
    const hourParts = maskedValueParts[0].split("");
    const part2 = maskedValueParts[1];

    if (
      Number(hourParts[0]) > 2 ||
      (Number(hourParts[0]) === 2 && Number(hourParts[1]) > 3)
    ) {
      return;
    }

    if (part2) {
      const minParts = part2.split("");

      if (Number(minParts[0]) > 5) {
        return;
      }

      if (!!minParts[1] && Number(maskedValueParts[1]) > 59) {
        return;
      }
    }

    setTime(maskedValue);
    setTimeError(false);
  }, []);

  const onApply = useCallback(() => {
    if (!onChange) {
      return;
    }

    if (time.length < 5 || !selectedDate) {
      setTimeError(true);
      return;
    }
    const datetime = new Date(selectedDate);
    const [hours, minutes] = time.split(":");
    datetime.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    onChange(datetime, name);
    setTimeError(false);
    if (onClose) {
      onClose();
    }
  }, [selectedDate, time, onChange, name]);

  return (
    <div style={{ background: "white", padding: 16 }}>
      <CalendarCustom
        value={selectedDate}
        onClick={handleDateClick}
        minDate={minDate}
        maxDate={maxDate}
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
      />
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Grid>
          <Grid columns={2}>
            <Select
              options={years}
              value={years.find((y) => y.value === selectedYear) || null}
              onChange={onYearChange}
              placeholder="Year"
              name="year"
            />
            <Select
              options={months}
              value={months.find((m) => m.value === selectedMonth) || null}
              onChange={onMonthChange}
              placeholder="Month"
              name="month"
            />
          </Grid>
          <TextField
            label="Время начала"
            placeholder="00:00"
            value={time}
            error={timeError}
            helperText={timeError ? "Неверный формат времени" : ""}
            timeFormat={true}
            onChange={onChangeTime}
          />
          <Grid columns={2}>
            <Button text="Отмена" variant="outlined" onClick={onClose} />
            <Button text="Применить" onClick={onApply} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DateTimeCalendar;
