import React, { createContext, FC, ReactNode, useContext } from "react";

type EventListener = (...args: any[]) => void;

interface EventEmitter {
  emitEvent: (eventName: string, ...args: any[]) => void;
  addEventListener: (eventName: string, callback: EventListener) => void;
  removeEventListener: (eventName: string, callback: EventListener) => void;
  getEventEmitters: () => { [eventName: string]: EventListener[] };
}

const EventEmitterContext = createContext<EventEmitter | null>(null);

interface EventEmitterProviderProps {
  children: ReactNode;
}

const EventEmitterProvider: FC<EventEmitterProviderProps> = ({ children }) => {
  const listeners: { [eventName: string]: EventListener[] } = {};

  const emitEvent = (eventName: string, ...args: any[]): void => {
    const eventListeners = listeners[eventName];
    if (eventListeners) {
      eventListeners.forEach((listener) => listener(...args));
    }
  };

  const addEventListener = (
    eventName: string,
    callback: EventListener
  ): void => {
    if (!listeners[eventName]) {
      listeners[eventName] = [];
    }
    listeners[eventName].push(callback);
  };

  const removeEventListener = (
    eventName: string,
    callback: EventListener
  ): void => {
    const eventListeners = listeners[eventName];
    if (eventListeners) {
      listeners[eventName] = eventListeners.filter(
        (listener) => listener !== callback
      );
    }
  };

  const getEventEmitters = () => {
    return listeners;
  };

  const eventEmitter: EventEmitter = {
    emitEvent,
    addEventListener,
    removeEventListener,
    getEventEmitters,
  };

  return (
    <EventEmitterContext.Provider value={eventEmitter}>
      {children}
    </EventEmitterContext.Provider>
  );
};

export const useEventEmitter = (): EventEmitter => {
  const eventEmitter = useContext(EventEmitterContext);

  if (!eventEmitter) {
    throw new Error("EventEmitterProvider not found");
  }
  return eventEmitter;
};

export default EventEmitterProvider;
