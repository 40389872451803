import styled from "styled-components";
import { theme } from "styled-tools";

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${theme("color.white")};
  padding: 24px;
  box-sizing: border-box;
`;

export const Grid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);
`;

export const FilterPanel = styled.div<{ open: boolean }>`
  height: ${(props) => (props.open ? "190px" : 0)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  padding: 0 24px;
  background-color: ${theme("color.white")};
  transition: opacity 0.2s linear, height 0.2s ease-in-out 0.15s;
`;
