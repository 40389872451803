import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader, Modal, ModalProps, ModalTitle, Tabs } from "@app/components";
import { VehicleInfo, VehicleListItem } from "@app/models";
import { APIResponse, getVehicleDetail } from "@app/api";
import { VehicleStatusChip } from "@app/common";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";
import { VehicleDetailView, VehicleFilesView } from "./views";

interface Props extends Omit<ModalProps, "title"> {
  item: VehicleListItem | null;
}

function ModalDictionaryVehicleDetail(props: Props) {
  const { item: vehicleProps, ...modalProps } = props;
  const { showNotification } = useNotification();
  const [vehicle, setVehicle] = useState<VehicleInfo | null>(null);
  const [pending, setPending] = useState<boolean>(true);

  const tabLabels = useMemo(
    () => ["Детали техники", "Документы техники", "Документы БиОТ"],
    []
  );

  const modalTitle = useMemo<ReactNode>(() => {
    if (!vehicleProps) {
      return "";
    }

    return (
      <ModalTitle text={vehicleProps.vehicleType}>
        {!!vehicleProps.status && (
          <VehicleStatusChip status={vehicleProps.status} />
        )}
      </ModalTitle>
    );
  }, [vehicleProps]);

  const getVehicleInfo = useCallback(async () => {
    if (vehicleProps) {
      try {
        setVehicle(null);
        setPending(true);
        const res = await getVehicleDetail(vehicleProps.id);
        setVehicle(res.data);
        setPending(false);
      } catch (e) {
        setPending(false);

        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    }
  }, [vehicleProps, showNotification]);

  useEffect(() => {
    getVehicleInfo();
  }, [vehicleProps, getVehicleInfo]);

  return (
    <Modal title={modalTitle} dense={true} {...modalProps}>
      {pending && <Loader />}
      <Tabs labels={tabLabels}>
        <VehicleDetailView vehicle={vehicle} />
        <VehicleFilesView vehicle={vehicle} />
        <VehicleFilesView vehicle={null} />
      </Tabs>
    </Modal>
  );
}

export default memo(ModalDictionaryVehicleDetail);
