export const actTableLabels = [
  "",
  "№",
  "Дата",
  "Смена",
  "Время работы",
  "Вид техники",
  "Характеристика",
  "Вид услуги",
  "Гос номер",
  "Данные линии",
  "Моточасы GPS",
  "Длительность смены по GPS",
  "Ед. изм.",
  "Цена аренды",
  "Данные к оплате",
  "Сумма",
  "Сумма НДС",
  "Итого к оплате",
  "Комментарий",
  "",
];
