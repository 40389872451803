import React, {
  memo,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Field, { FieldProps } from "../Field";
import "./styles.scss";
import { numberWithSpaces, setMask } from "@app/helpers";
import { IconClose24 } from "@app/icons";
import classNames from "classnames";

export interface Props extends FieldProps {
  startIcon?: ReactNode;
  value?: string;
  onChange?: (value: string, name: any) => void;
  onBlur?: () => void;
  secureTextEntry?: boolean;
  multiline?: boolean;
  onKeyPress?: (e: KeyboardEvent) => void;
  cols?: number;
  type?: string;
  disabled?: boolean;
  width?: string;
  priceFormat?: boolean;
  timeFormat?: boolean;
  maxLength?: number;
  inputProps?: any;
  readOnly?: boolean;
}

function TextField(props: Props) {
  const {
    value = "",
    onChange,
    onBlur,
    onKeyPress: onKeyPressProps,
    placeholder,
    secureTextEntry = false,
    className = "",
    type = "text",
    name = "",
    multiline = false,
    disabled = false,
    priceFormat = false,
    timeFormat = false,
    readOnly = false,
    maxLength,
    inputProps = {},
    width,
    ...fieldProps
  } = props;
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    // используется для переноса каретки в указанное ранее место
    // и предотвращения перехода в конец строки
    // Пример: компонент <DatePeriodPicker /> поле Время
    if (type !== "number") {
      ref.current?.setSelectionRange(cursor, cursor);
    }
  }, [cursor, type]);

  const onTimeChange = useCallback(
    (value: string, selectionStart: number | null) => {
      const maskedValue = setMask(value.replace(/\D/g, ""), "##:##");
      const maskedValueParts = maskedValue.split(":");
      const hourParts = maskedValueParts[0].split("");
      const part2 = maskedValueParts[1];

      if (
        Number(hourParts[0]) > 2 ||
        (Number(hourParts[0]) === 2 && Number(hourParts[1]) > 3)
      ) {
        return;
      }

      if (part2) {
        const minParts = part2.split("");

        if (Number(minParts[0]) > 5) {
          return;
        }

        if (!!minParts[1] && Number(maskedValueParts[1]) > 59) {
          return;
        }
      }

      // При форматировании (replace) времени каретка сдвигается в конец ввода
      // при этом добавляется двоеточие и позиция курсора оказывается неверной
      // добавлено условие сдвига, когда курсор находится на третьем символе (т.е. двоеточие)
      if (selectionStart === 3) {
        setCursor(selectionStart + 1);
      } else {
        setCursor(selectionStart);
      }
      if (onChange) {
        onChange(maskedValue, name);
      }
    },
    [name, onChange]
  );

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value: eventValue, selectionStart } = e.target;

      if (readOnly) {
        return;
      }

      if (!!onChange) {
        if (timeFormat) {
          onTimeChange(eventValue, selectionStart);
          return;
        }
        onChange(
          priceFormat ? eventValue.replace(/[^0-9,0-9]/g, "") : eventValue,
          name
        );
      }
    },
    [readOnly, onChange, timeFormat, priceFormat, name, onTimeChange]
  );

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!!onKeyPressProps) {
        onKeyPressProps(e as unknown as KeyboardEvent);
      }
    },
    [onKeyPressProps]
  );

  const onClearClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!onChange) {
        return;
      }

      onChange("", name);
    },
    [name, onChange]
  );

  return (
    <Field
      {...fieldProps}
      disabled={disabled}
      className={`b-text-field ${className}`.trim()}
    >
      {multiline ? (
        <textarea
          className="b-text-field__textarea"
          onChange={onChangeText}
          placeholder={placeholder}
          onKeyDown={onKeyPress}
          value={value}
          maxLength={maxLength}
          disabled={disabled}
        />
      ) : (
        <input
          ref={ref}
          className={classNames("b-text-field__input", {
            "b-text-field__input--disabled": disabled,
          })}
          type={secureTextEntry ? "password" : type}
          value={priceFormat ? numberWithSpaces(value) : value}
          onChange={onChangeText}
          onBlur={onBlur}
          placeholder={placeholder}
          onKeyDown={onKeyPress}
          disabled={disabled}
          style={{ width }}
          {...inputProps}
        />
      )}
      {!multiline && !!value && !disabled && !readOnly && (
        <button
          className={classNames("b-text-field__clear", {
            "b-text-field__clear--icon-offset": !!fieldProps.appendIcon,
          })}
          onClick={onClearClick}
        >
          <IconClose24 />
        </button>
      )}
    </Field>
  );
}

export default memo(TextField);
