import { StyledGrid } from "./styled";
import { Info } from "@app/components";
import { CompanyInfo } from "@app/models";
import { FC } from "react";

type Props = {
  company: CompanyInfo | null;
};

const CompanyBasisView: FC<Props> = ({ company }) => {
  if (!company) {
    return null;
  }

  return (
    <StyledGrid>
      <Info label="Вид основания" value={""} />
    </StyledGrid>
  );
};

export default CompanyBasisView;
