import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Button, Info, Select, SelectOption, useForm } from "@app/components";
import { getActOfProvidedWorkBasisList, PageableResponse } from "@app/api";
import { Act, ActOfWorkStatus, Dictionary } from "@app/models";
import * as yup from "yup";
import { ModalActChoose } from "@app/modals";
import { useNotification, useUser } from "@app/providers";
import { dateFormat, isPartner, priceFormat } from "@app/helpers";
import { IconTrash24 } from "@app/icons";

interface Props {
  basis: Dictionary | null;
  onSubmit: (basis: BasisDictionary | null) => void;
  disabled: boolean;
  act: Act | null;
  setAct: (act: Act | null) => void;
  actOfWorkStatus: ActOfWorkStatus | null;
}

export interface BasisDictionary extends Dictionary {
  isActBasis: boolean;
}

interface Data {
  basis: SelectOption<BasisDictionary> | null;
}

const StyledStep = styled.div`
  display: grid;
  grid-gap: 16px;
  height: 100%;
  grid-template-rows: 1fr auto;
  position: relative;
  padding: 16px 24px;
  box-sizing: border-box;
`;

const StyledContent = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: max-content;
`;

const StyledFooter = styled.div`
  padding-top: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${theme("color.grayLight")};
  justify-content: flex-end;
`;

const StyledActPanel = styled.div`
  border: 1px solid ${theme("color.grayLight")};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
`;

const StyledActPanelHeader = styled.div`
  padding-bottom: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${theme("color.grayLight")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const StyledActPanelTitle = styled.p`
  color: ${theme("color.dark")};
  font-size: 16px;
  line-height: 24px;
`;

const StyledActPanelContent = styled.div`
  display: grid;
  padding-top: 16px;
  box-sizing: border-box;
  gap: 16px;
`;

const schema = yup.object().shape({
  basis: yup.object().nullable().required("Выберите основание"),
});

export default function (props: Props) {
  const { onSubmit, basis, disabled, act, setAct, actOfWorkStatus } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const { onChange, errors, values, validate, setValues } = useForm<Data>({
    values: {
      basis: null,
    },
    schema,
  });

  const onChangeBasis = useCallback(
    (item: SelectOption<BasisDictionary> | null) => {
      if (!item?.value.isActBasis) {
        setAct(null);
      }

      onChange(item, "basis");
    },
    [onChange, setAct]
  );

  const [visibleActChooseModal, setVisibleActChooseModal] =
    useState<boolean>(false);

  const onClickChooseAct = useCallback(() => {
    setVisibleActChooseModal(true);
  }, []);

  const onCloseActChooseModal = useCallback(() => {
    setVisibleActChooseModal(false);
  }, []);

  const onClickClearAct = useCallback(() => {
    setAct(null);
  }, [setAct]);

  const onSubmitForm = useCallback(async () => {
    try {
      if (!!basis) {
        onSubmit(null);

        return;
      }

      const isValid = await validate();

      if (!isValid) {
        return;
      }

      if (values.basis?.item?.isActBasis && !act) {
        showNotification({
          variant: "error",
          message: "Выберите акт",
        });

        return;
      }

      onSubmit(values.basis?.item ?? null);
    } catch (e) {}
  }, [basis, validate, values.basis?.item, act, onSubmit, showNotification]);

  const loadData = useCallback(() => {
    return getActOfProvidedWorkBasisList()
      .then((res) => {
        // убрать Ручное добавление АВР у Партнера
        const filterData = res.data.filter(
          (item) => !isPartner(user!.role) || item.isActBasis
        );
        return {
          ...res,
          data: filterData,
        };
      })
      .then((response) => ({
        ...(response as PageableResponse<BasisDictionary>),
        pageNumber: 1,
        pageSize: 10,
        recordsFiltered: response.data.length,
        recordsTotal: response.data.length,
      }));
  }, [user]);

  useEffect(() => {
    if (!!basis) {
      setValues({
        basis: {
          label: basis.name,
          value: basis.id,
        },
      });
    }
  }, [setValues, basis]);

  return (
    <>
      <StyledStep>
        <StyledContent>
          <Select<BasisDictionary>
            label="Основание"
            loadData={loadData}
            value={values.basis}
            onChange={onChangeBasis}
            name="basis"
            error={!!errors.basis}
            helperText={errors.basis}
            disabled={disabled}
            labelKey="name"
            valueKey="id"
          />
          {values.basis?.item?.isActBasis && (
            <div>
              <Button
                text="Выбрать Табель"
                onClick={onClickChooseAct}
                disabled={!!act}
              />
            </div>
          )}
          {!!act && (
            <StyledActPanel>
              <StyledActPanelHeader>
                <StyledActPanelTitle>
                  Табель №{act.actNumber}
                </StyledActPanelTitle>
                {(!actOfWorkStatus ||
                  actOfWorkStatus <= ActOfWorkStatus.Updated) && (
                  <Button
                    startIcon={IconTrash24}
                    onClick={onClickClearAct}
                    variant="outlined"
                  />
                )}
              </StyledActPanelHeader>
              <StyledActPanelContent>
                <Info
                  label="Дата создания"
                  value={dateFormat(act.createdDate, "dd.MM.yyyy")}
                />
                <Info label="Организация" value={act.company.name} />
                <Info label="Проект" value={act.project.name} />
                <Info label="Контрагент" value={act.partner.name} />
                <Info
                  label="Период"
                  value={`${dateFormat(
                    act.startWorkDate,
                    "dd.MM.yyyy"
                  )}-${dateFormat(act.endWorkDate, "dd.MM.yyyy")}`}
                />
                <Info label="Сумма" value={priceFormat(act.sum.toString())} />
              </StyledActPanelContent>
            </StyledActPanel>
          )}
        </StyledContent>
        <StyledFooter>
          <Button text="Далее" onClick={onSubmitForm} />
        </StyledFooter>
      </StyledStep>
      <ModalActChoose
        setAct={setAct}
        open={visibleActChooseModal}
        onClose={onCloseActChooseModal}
      />
    </>
  );
}
