import React, { memo, useCallback, useMemo, useState } from "react";
import {
  Company,
  Nomenclature,
  Project,
  VAERequest,
  VAERequestStatus,
  VAERequestStatusName,
  VehicleListItem,
} from "@app/models";
import {
  companyLabelKeys,
  dateFormat,
  enumToArray,
  getDateFilterValue,
  getDictionaryName,
  getFilterValues,
  isDispatcher,
} from "@app/helpers";
import {
  Button,
  Combobox,
  DateTimePicker,
  DefaultObject,
} from "@app/components";
import { IconPlus20 } from "@app/icons";
import { TemplateSearch } from "@app/templates";
import { ModalVAERequestCreate } from "@app/modals";
import {
  getCompanies,
  getMyCompanies,
  getMyProjects,
  getNomenclatures,
  getProjects,
  getVAERequests,
  getVehicles,
} from "@app/api";
import ModalVAERequestDetail from "../../../modals/VAERequestDetail";
import { VAERequestStatusChip } from "@app/common";
import { useDebounce } from "@app/hooks";
import { useUser } from "@app/providers";

const FILTER_STORAGE_KEY = "vaeRequestFilters";

interface FilterParamsProps {
  numbers: string[];
  organization: Array<Company> | null;
  project: Array<Project> | null;
  nomenclature: Array<Nomenclature> | null;
  partner: Array<Company> | null;
  vehicle: Array<VehicleListItem> | null;
  status: DefaultObject<VAERequestStatus>[] | null;
  fromDate: Date | null;
  toDate: Date | null;
}

const initialFiltersParams = JSON.stringify({
  numbers: [],
  partner: null,
  organization: null,
  project: null,
  nomenclature: null,
  vehicle: null,
  status: null,
  fromDate: null,
  toDate: null,
});

function VAERequests() {
  const { user } = useUser();
  const [modalCreateVisible, setModalCreateVisible] = useState<boolean>(false);
  const [request, setRequest] = useState<VAERequest | null>(null);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const mapTableData = useCallback((item: VAERequest) => {
    return [
      item.number,
      item.customer.name,
      dateFormat(item.startDate, "dd.MM.yyyy "),
      item.project.name,
      `${getDictionaryName(item.vehicle.vehicleModel)} ${
        item.vehicle.govNumber
      }`.trim(),
      item.nomenclature.name,
      <VAERequestStatusChip status={item.status} />,
      dateFormat(item.endDate, "dd.MM.yyyy"),
    ];
  }, []);

  const onClickAdd = useCallback(() => {
    setModalCreateVisible(true);
  }, []);

  const onClickItem = useCallback((item: VAERequest) => {
    setRequest(item);
  }, []);

  const closeModal = useCallback(() => {
    setModalCreateVisible(false);
  }, []);

  const closeDetailModal = useCallback(() => {
    setRequest(null);
  }, []);

  const tableLabels = useMemo(
    () => [
      "№",
      "Организация",
      "Дата начала",
      "Проект",
      "Техника",
      "Номенклатура",
      "Статус",
      "Дата завершения",
    ],
    []
  );

  const leftControls = useMemo(
    () => [
      <Button
        text="Создать заказ"
        startIcon={IconPlus20}
        onClick={onClickAdd}
      />,
    ],
    [onClickAdd]
  );

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      () => ({
        Numbers: filterParams.numbers,
        CustomerIds: getFilterValues(filterParams.organization),
        PartnerIds: getFilterValues(filterParams.partner),
        ProjectIds: getFilterValues(filterParams.project),
        VehicleIds: getFilterValues(filterParams.vehicle),
        NomenclatureIds: getFilterValues(filterParams.nomenclature),
        StatusIds: getFilterValues(filterParams.status),
        FromDate: getDateFilterValue(filterParams.fromDate),
        ToDate: getDateFilterValue(filterParams.toDate),
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox<Company>
        label="Организация"
        name="organization"
        onChange={onFilterChange}
        values={filterParams.organization}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Project>
        label="Проект"
        name="project"
        onChange={onFilterChange}
        values={filterParams.project}
        valueKey="id"
        labelKey="name"
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
      />,
      <Combobox<Company>
        label="Контрагент"
        name="partner"
        onChange={onFilterChange}
        values={filterParams.partner}
        valueKey="id"
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={getCompanies}
      />,
      <Combobox<Nomenclature>
        label="Номенклатура"
        name="nomenclature"
        onChange={onFilterChange}
        values={filterParams.nomenclature}
        valueKey="id"
        labelKey="name"
        loadData={getNomenclatures}
      />,
      <Combobox<VehicleListItem>
        label="Техника"
        name="vehicle"
        onChange={onFilterChange}
        values={filterParams.vehicle}
        loadData={(params) => getVehicles({ ...params, companyId: "" })}
      />,
      <Combobox<DefaultObject<VAERequestStatus>>
        label="Статус"
        name="status"
        onChange={onFilterChange}
        values={filterParams.status}
        options={enumToArray(VAERequestStatusName)}
      />,
      <DateTimePicker
        label="Дата начала"
        name="fromDate"
        onChange={onFilterChange}
        value={filterParams.fromDate}
        hideTime
      />,
      <DateTimePicker
        label="Дата завершения"
        name="toDate"
        onChange={onFilterChange}
        value={filterParams.toDate}
        hideTime
      />,
    ],
    [filterParams, onFilterChange, user]
  );

  return (
    <>
      <TemplateSearch<VAERequest>
        toolbarProps={{
          leftControls: leftControls,
          searchPlaceholder: "Задача, инициатор проект, контрагент",
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        getData={getVAERequests}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onClickItem}
      />
      <ModalVAERequestCreate
        title="Создание заказа на доп затраты"
        open={modalCreateVisible}
        onClose={closeModal}
      />
      <ModalVAERequestDetail
        data={request}
        open={!!request}
        onClose={closeDetailModal}
        showOffers={
          !!request &&
          [
            VAERequestStatus.AwaitingConfirmation,
            VAERequestStatus.Rejected,
          ].indexOf(request.status) === -1
        }
      />
    </>
  );
}

export default memo(VAERequests);
