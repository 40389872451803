import React, {
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BaseButton, Menu } from "@app/components";
import { useUser } from "@app/providers";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styled-tools";
import { isPartner, isUser } from "@app/helpers";
import { DefaultHeader, DefaultSidebar } from "./components";
import { useMenuList } from "@app/hooks";
import { IconChevronLeft16 } from "@app/icons";

interface Props extends PropsWithChildren {
  title?: string;
  rightControls?: ReactNode;
  showNavigation?: boolean;
}

const StyledLayout = styled.div`
  height: 100%;
  background: #f5f6f8;
`;

const StyledSidebar = styled.div<{
  variant: "light" | "dark";
}>`
  position: fixed;
  left: 0;
  top: 0;
  width: 240px;
  height: 100%;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.08);
  z-index: 100;
  background-color: ${(props) =>
    props.variant === "light" ? theme("color.white") : theme("color.dark")};
`;

const StyledNavigationBar = styled.div`
  padding: 16px;
`;

const StyledMain = styled.div`
  margin-left: 240px;
  height: 100%;
`;

const StyledContent = styled.div<{ offset: number }>`
  height: calc(100% - ${(props) => props.offset}px);
  position: relative;
`;

function LayoutDefault(props: Props) {
  const { title, children, rightControls, showNavigation = false } = props;
  const { authenticated, user } = useUser();
  const { routes } = useMenuList();
  const navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(true);

  const variant = useMemo(() => {
    if (!!user && (isPartner(user!.role) || isUser(user!.role))) {
      return "light";
    }

    return "dark";
  }, [user]);

  useEffect(() => {
    if (!authenticated) {
      navigate("/sign-in");

      setPending(false);

      return;
    }

    setPending(false);
  }, [authenticated, navigate]);

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = "80%";
    document.body.style.backgroundColor = "#E5E5E5";

    return () => {
      // @ts-ignore
      document.body.style.zoom = "100%";
      document.body.style.backgroundColor = "#FFF";
    };
  }, []);

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (pending) {
    return null;
  }

  return (
    <StyledLayout>
      <StyledSidebar variant={variant}>
        <DefaultSidebar variant={variant}>
          <Menu items={routes} variant={variant} />
        </DefaultSidebar>
      </StyledSidebar>
      <StyledMain>
        <DefaultHeader title={title} rightControls={rightControls} />
        {showNavigation && (
          <StyledNavigationBar>
            <BaseButton onClick={onBackClick}>
              <IconChevronLeft16 />
              Назад
            </BaseButton>
          </StyledNavigationBar>
        )}
        <StyledContent offset={showNavigation ? 112 : 64}>
          {children}
        </StyledContent>
      </StyledMain>
    </StyledLayout>
  );
}

export default memo(LayoutDefault);
