import React, { memo, useCallback, useMemo, useState } from "react";
import { ActOfWork, Company, Project } from "@app/models";
import {
  companyLabelKeys,
  dateFormat,
  getDateFilterValue,
  getFilterValues,
  isDispatcher,
} from "@app/helpers";
import { TemplateSearch } from "@app/templates";
import {
  getCompanies,
  getMyCompanies,
  getMyProjects,
  getOnlySignedActOfWorks,
  getProjects,
} from "@app/api";
import { ModalActDocDetail } from "@app/modals";
import { Combobox, DateTimePicker } from "@app/components";
import { useUser } from "@app/providers";
import { useDebounce } from "@app/hooks";

const FILTER_STORAGE_KEY = "actDocumentsFilters";

interface FilterParamsProps {
  companies: Company[] | null;
  partners: Company[] | null;
  projects: Project[] | null;
  startDate: Date | null;
  endDate: Date | null;
}

interface FilterValuesProps {
  CustomerIds: string[];
  PartnerIds: string[];
  ProjectIds: string[];
  StartDate?: string;
  EndDate?: string;
}

const initialFiltersParams = JSON.stringify({
  companies: null,
  partners: null,
  projects: null,
  startDate: null,
  endDate: null,
});

function ActDocuments() {
  const { user } = useUser();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [actOfWork, setActOfWork] = useState<ActOfWork | null>(null);
  // const [loading, setLoading] = useState(false);
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const tableLabels = useMemo(
    () => [
      "Наименование документа",
      "Номер АВР",
      "Заказчик",
      "Исполнитель",
      "Проект",
      "Дата начала",
      "Дата завершения",
    ],
    []
  );

  const mapTableData = useCallback((item: ActOfWork) => {
    return [
      `Акт выполненных работ №${item.number}`,
      item.registrationNumber || "—",
      item.senderCompany.name || "—",
      item.recipientCompany.name || "—",
      item.project.name,
      item.startDate ? dateFormat(item.startDate, "dd.MM.yyyy") : "-",
      item.endDate ? dateFormat(item.endDate, "dd.MM.yyyy") : "-",
    ];
  }, []);

  const onClickItem = useCallback((data: ActOfWork) => {
    setActOfWork(data);
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setActOfWork(null);
    setModalVisible(false);
  }, []);

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        CustomerIds: getFilterValues(filterParams.companies),
        PartnerIds: getFilterValues(filterParams.partners),
        ProjectIds: getFilterValues(filterParams.projects),
        StartDate: getDateFilterValue(filterParams.startDate),
        EndDate: getDateFilterValue(filterParams.endDate),
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox<Company>
        label="Организация"
        name="companies"
        onChange={onFilterChange}
        values={filterParams.companies}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Company>
        label="Контрагент"
        name="partners"
        onChange={onFilterChange}
        values={filterParams.partners}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Project>
        label="Проект"
        name="projects"
        onChange={onFilterChange}
        values={filterParams.projects}
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
      />,
      <DateTimePicker
        label="Дата начала"
        name="startDate"
        onChange={onFilterChange}
        value={filterParams.startDate}
        hideTime
      />,
      <DateTimePicker
        label="Дата завершения"
        name="endDate"
        onChange={onFilterChange}
        value={filterParams.endDate}
        hideTime
      />,
    ],
    [filterParams, onFilterChange, user]
  );

  return (
    <>
      <TemplateSearch<ActOfWork>
        toolbarProps={{
          onFilter: () => {},
          searchPlaceholder: "Наименование документа, организация, проект",
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        getData={getOnlySignedActOfWorks}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onClickItem}
      />
      <ModalActDocDetail
        open={modalVisible}
        data={actOfWork}
        onClose={closeModal}
      />
    </>
  );
}

export default memo(ActDocuments);
