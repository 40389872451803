import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Chip, Info } from "@app/components";
import { theme } from "styled-tools";
import { KIPProject, KIPVehicle } from "@app/models";
import { priceFormat } from "@app/helpers";
import { ModalKIPVehicle } from "@app/modals";
import { VehicleTimelineStatistics } from "../index";

interface Props {
  vehicle: KIPVehicle;
  projectId?: KIPProject["id"];
}

const StyledContainer = styled.div`
  display: grid;
  padding: 12px 8px;
  box-sizing: border-box;
  gap: 16px;
  border: 1px solid ${theme("color.grayLight")};
  transition: all 0.2s ease-in;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 -2px 8px 0 rgba(0, 0, 0, 0.08);
  //max-width: 443px;

  &:hover {
    box-shadow: 0 0 5px ${theme("color.grayLight")};
    transform: scale(1.02);
  }
`;

const StyledHeader = styled.div`
  display: grid;
  gap: 8px;
`;

const StyledHeaderTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

const StyledDescription = styled.div`
  display: flex;
  box-sizing: border-box;
  //width: 424px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #f4f4f4;
  padding: 4px 0;
`;

const StyledInfo = styled.div`
  min-width: 112px;
  overflow: hidden;
`;

const StyledContent = styled.div`
  box-sizing: border-box;
  //padding-top: 8px;
  //padding-bottom: 8px;
`;

const StyledStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLabel = styled.p`
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: ${theme("color.grayDark")};
`;

const StyledDivider = styled.div`
  height: 20px;
  width: 1px;
  background-color: ${theme("color.grayLight")};
`;

const StyledHr = styled.div`
  width: 100%;
  border-top: 1px solid ${theme("color.grayLight")};
`;

const StyledFooter = styled.div``;

export default function (props: Props) {
  const { vehicle, projectId } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onClickDetails = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setModalVisible(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <>
      <StyledContainer onClick={onClickDetails}>
        <StyledHeader>
          <StyledHeaderTitle>{vehicle.vehicleGovNumber}</StyledHeaderTitle>
          <StyledDescription>
            <StyledInfo>
              <Info
                label="Исполнитель"
                value={vehicle.partnerName}
                variant="secondary"
                size="small"
                ellipsis={true}
                reversed
                centered
              />
            </StyledInfo>
            <StyledDivider />
            <StyledInfo>
              <Info
                label="Вид техники"
                value={vehicle.vehicleTypeName}
                variant="secondary"
                size="small"
                ellipsis={true}
                reversed
                centered
              />
            </StyledInfo>
            <StyledDivider />
            <StyledInfo>
              <Info
                label="Характеристика"
                value={vehicle.characteristicsName}
                variant="secondary"
                size="small"
                ellipsis={true}
                reversed
                centered
              />
            </StyledInfo>
          </StyledDescription>
        </StyledHeader>
        <StyledHr />
        <StyledContent>
          <VehicleTimelineStatistics data={vehicle.dates} variant="info" />
        </StyledContent>
        <StyledHr />
        <StyledFooter>
          <StyledDescription>
            <StyledStats>
              <Chip
                bold={true}
                text={priceFormat(vehicle.costAmount.toFixed(0))}
                color="info"
                textSize="sm"
              />
              <StyledLabel>К оплате</StyledLabel>
            </StyledStats>
            <StyledDivider />
            <StyledStats>
              <Chip
                bold={true}
                text={
                  vehicle.workLoad ? `${vehicle.workLoad.toFixed(0)}%` : "-"
                }
                color="warning"
                textSize="sm"
              />
              <StyledLabel>Загруженность</StyledLabel>
            </StyledStats>
            <StyledDivider />
            <StyledStats>
              <Chip
                bold={true}
                text={priceFormat(vehicle.unusedAmount.toFixed(0))}
                color="danger"
                textSize="sm"
              />
              <StyledLabel>Неосвоенный бюджет</StyledLabel>
            </StyledStats>
          </StyledDescription>
        </StyledFooter>
      </StyledContainer>
      <ModalKIPVehicle
        projectId={projectId}
        vehicle={vehicle}
        onClose={onCloseModal}
        open={modalVisible}
      />
    </>
  );
}
