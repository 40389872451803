import React, { ReactNode, useCallback, useMemo } from "react";
import { Chip, Paper, Table } from "@app/components";
import { priceFormat } from "@app/helpers";
import { KIPProject } from "@app/models";
import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
  title?: string;
  projects: Partial<KIPProject>[];
  onClick: (item: KIPProject) => void;
}

const StyledTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 0 16px 0;
  color: ${theme("color.dark")};
  font-weight: 600;
`;

const StyledTableLabel = styled.div`
  > div {
    margin-bottom: 6px;
  }
`;

export default function (props: Props) {
  const { title, projects, onClick } = props;

  const tableData = useMemo(() => {
    return projects.map((item, itemIndex) => [
      (itemIndex + 1).toString(),
      item.name,
      item.vehiclesQty ? `${item.vehiclesQty} ТС` : "-",
      typeof item.workLoad === "number" ? `${item.workLoad.toFixed()}%` : "-",
      item.costAmount ? priceFormat(item.costAmount.toFixed()) : "-",
      item.unusedAmount ? priceFormat(item.unusedAmount.toFixed()) : "-",
    ]);
  }, [projects]);

  const tableLabels = useMemo<(string | ReactNode)[]>(() => {
    const vehicleCount = projects.reduce(
      (acc, item) => acc + item.vehiclesQty!,
      0
    );

    // const workLoad =
    //   projects.reduce((acc, item) => acc + (item.workLoad ?? 0), 0) /
    //   projects.length;

    const workLoad =
      projects.reduce(
        (acc, item) =>
          acc + (typeof item.workLoad === "number" ? item.workLoad : 0),
        0
      ) / projects.length;

    const costAmount = projects.reduce(
      (acc, item) => acc + item.costAmount!,
      0
    );

    const unusedAmount = projects.reduce(
      (acc, item) => acc + item.unusedAmount!,
      0
    );

    return [
      "№",
      "Наименование",
      <StyledTableLabel>
        <div>Количество техники</div>
        <Chip bold={true} text={`${vehicleCount} TC`} />
      </StyledTableLabel>,
      <StyledTableLabel>
        <div>Загруженность</div>
        <Chip
          bold={true}
          color="warning"
          text={`${!!workLoad ? workLoad.toFixed() : 0}%`}
        />
      </StyledTableLabel>,
      <StyledTableLabel>
        <div>Сумма затрат</div>
        <Chip
          bold={true}
          color="primary"
          text={priceFormat(costAmount.toFixed())}
        />
      </StyledTableLabel>,
      <StyledTableLabel>
        <div>Потери</div>
        <Chip
          bold={true}
          color="danger"
          text={priceFormat(unusedAmount.toFixed())}
        />
      </StyledTableLabel>,
    ];
  }, [projects]);

  const onClickTableRow = useCallback(
    (index: number) => {
      onClick(projects[index] as KIPProject);
    },
    [onClick, projects]
  );

  return (
    <Paper>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      <Table
        align="top"
        labels={tableLabels}
        data={tableData}
        onClick={onClickTableRow}
      />
    </Paper>
  );
}
