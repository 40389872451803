import React, { Fragment, memo, ReactNode, useCallback, useState } from "react";
import classNames from "classnames";
import styled from "styled-components";
import "./styles.scss";

interface Props {
  labels: string[];
  badges?: (string | number | undefined)[];
  onChange?: (index: number) => void;
  className?: string;
  children?: ReactNode[];
  dense?: boolean;
  initialTabIndex?: number;
  transparent?: boolean;
  bordered?: boolean;
  controls?: ReactNode[];
}

const StyledTabs = styled.div``;

function Tabs(props: Props) {
  const {
    labels,
    badges = [],
    className = "",
    onChange,
    dense = true,
    initialTabIndex = 0,
    transparent = false,
    bordered = false,
    children,
    controls,
  } = props;
  const [tabIndex, setTabIndex] = useState<number>(initialTabIndex);

  const onClickTab = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setTabIndex(index);

      if (!!onChange) {
        onChange(index);
      }
    },
    [onChange]
  );

  if (!labels) {
    return null;
  }

  return (
    <StyledTabs
      className={classNames(`b-tabs ${className}`.trim(), {
        "b-tabs--transparent": transparent,
        "b-tabs--bordered": bordered,
      })}
    >
      <div className="b-tabs__labels">
        {labels.map((label, labelIndex) => (
          <button
            key={labelIndex.toString()}
            onClick={onClickTab(labelIndex)}
            className={classNames("b-tabs__label", {
              "b-tabs__label--active": tabIndex === labelIndex,
            })}
          >
            <span className="b-tabs__text">{label}</span>
            {!!badges[labelIndex] && (
              <span className="b-tabs__badge">
                {badges[labelIndex]?.toString()}
              </span>
            )}
          </button>
        ))}
        {!!controls &&
          controls.map((control, controlIndex) => (
            <Fragment key={controlIndex.toString()}>{control}</Fragment>
          ))}
      </div>
      {!!children && (
        <div
          style={{
            padding: dense ? "0" : "0 16px",
          }}
        >
          {children.map((tabContent, tabContentIndex) => {
            if (tabContentIndex !== tabIndex) {
              return null;
            }

            return (
              <div key={tabContentIndex.toString()} className="b-tabs__content">
                {tabContent}
              </div>
            );
          })}
        </div>
      )}
    </StyledTabs>
  );
}

export default memo(Tabs);
