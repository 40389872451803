import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, DateTimePicker, Loader, useForm } from "@app/components";
import styled from "styled-components";
import { SmartContract } from "@app/models";
import Contractor from "../Contractor";
import * as yup from "yup";
import { theme } from "styled-tools";
import { ModalRequisites, Requisites } from "@app/modals";
import { useNotification } from "@app/providers";
import { addSmartContractRequisites, APIResponse } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

interface Props {
  contract: SmartContract | null;
  onChange: (step: number) => void;
  setModalActions: Dispatch<SetStateAction<ReactNode[]>>;
  updateContract: (partialContract: Partial<SmartContract>) => void;
}

const StyledStep1 = styled.div`
  display: grid;
  grid-gap: 16px;
  height: 100%;
  grid-template-rows: auto auto 1fr auto;
  position: relative;
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 256px);
  grid-gap: 16px;
`;

const StyledContent = styled.div`
  grid-auto-rows: max-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

const StyledEmpty = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 10px;
  color: ${theme("color.grayDark")};
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Step1FormData extends Requisites {
  contractDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
}

const schema = yup.object().shape({
  contractDate: yup.date().nullable().required(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
});

function Step1(props: Props) {
  const {
    contract,
    onChange: onChangeStep,
    setModalActions,
    updateContract,
  } = props;
  const { showNotification } = useNotification();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);

  const { values, onChange, errors, setValues, validate } =
    useForm<Step1FormData>({
      values: {
        contractDate: null,
        startDate: null,
        endDate: null,
        customerSigner: null,
        partnerSigner: null,
        customerAddress: null,
        partnerAddress: null,
        customerBankAccount: null,
        partnerBankAccount: null,
      },
      schema,
    });

  const hasRequisites = useMemo<boolean>(
    () =>
      !!values.partnerBankAccount &&
      !!values.customerBankAccount &&
      !!values.partnerSigner &&
      !!values.customerSigner,
    [values]
  );

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onChangeRequisites = useCallback(
    (requisites: Partial<Requisites>) => {
      setValues({
        ...requisites,
      });
    },
    [setValues]
  );

  const correctTimezone = (dateVal: Date) => {
    const date = new Date(
      dateVal.getTime() - dateVal.getTimezoneOffset() * 60000
    );

    return date.toJSON();
  };

  const onClickSave = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      if (!hasRequisites) {
        return showNotification({
          variant: "error",
          message: "Заполните реквизиты",
        });
      }

      setPending(true);

      const { succeeded, message } = await addSmartContractRequisites({
        ...contract,
        draftId: contract!.id,
        endDate: correctTimezone(values.endDate!),
        contractDate: correctTimezone(values.contractDate!),
        startDate: correctTimezone(values.startDate!),
        partnerSignerId: values.partnerSigner!.id,
        customerSignerId: values.customerSigner!.id,
        partnerBankAccountId: values.partnerBankAccount!.id,
        customerBankAccountId: values.customerBankAccount!.id,
      });

      if (!succeeded) {
        return showNotification({
          message,
          variant: "error",
        });
      }

      setPending(false);

      updateContract({
        endDate: values.endDate!.toJSON(),
        contractDate: values.contractDate!.toJSON(),
        startDate: values.startDate!.toJSON(),
        partnerSigner: values.partnerSigner!,
        customerSigner: values.customerSigner!,
        partnerAddress: values.partnerAddress!,
        customerAddress: values.customerAddress!,
        partnerBankAccount: values.partnerBankAccount!,
        customerBankAccount: values.customerBankAccount!,
      });

      showNotification({
        message: "Реквизиты были успешно сохранены",
        variant: "success",
      });
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    contract,
    hasRequisites,
    showNotification,
    updateContract,
    validate,
    values,
  ]);

  const onClickShowModal = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onClickNext = useCallback(() => {
    onChangeStep(2);
  }, [onChangeStep]);

  useEffect(() => {
    if (!!contract) {
      setValues({
        contractDate: !!contract.contractDate
          ? new Date(contract.contractDate)
          : null,
        startDate: !!contract.startDate ? new Date(contract.startDate) : null,
        endDate: !!contract.endDate ? new Date(contract.endDate) : null,
        customerSigner: contract.customerSigner,
        partnerSigner: contract.partnerSigner,
        customerAddress: contract.customerAddress,
        partnerAddress: contract.partnerAddress,
        customerBankAccount: contract.customerBankAccount,
        partnerBankAccount: contract.partnerBankAccount,
      });
    }
  }, [setModalActions, contract, setValues]);

  useEffect(() => {
    setModalActions([
      <Button
        text="Сохранить"
        variant="text"
        onClick={onClickSave}
        disabled={
          !values.contractDate ||
          !values.startDate ||
          !values.endDate ||
          !hasRequisites
        }
      />,
    ]);
  }, [setModalActions, values, hasRequisites, onClickSave]);

  return (
    <StyledStep1>
      {pending && (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      )}
      <StyledHeader>
        <DateTimePicker
          label="Дата договора"
          disabled={!contract}
          value={values.contractDate}
          onChange={onChange}
          name="contractDate"
          helperText={errors.contractDate}
          hideTime={true}
          error={!!errors.contractDate}
        />
        <DateTimePicker
          label="Дата начала"
          disabled={!contract}
          value={values.startDate}
          onChange={onChange}
          hideTime={true}
          name="startDate"
          helperText={errors.startDate}
          error={!!errors.startDate}
        />
        <DateTimePicker
          label="Дата окончания"
          disabled={!contract}
          value={values.endDate}
          hideTime={true}
          onChange={onChange}
          name="endDate"
          helperText={errors.endDate}
          error={!!errors.endDate}
          minDate={values.startDate}
        />
      </StyledHeader>
      {!!contract && hasRequisites ? (
        <>
          <div>
            <Button
              size="small"
              text="Редактировать"
              onClick={onClickShowModal}
            />
          </div>
          <StyledContent>
            <Contractor
              title="Реквизиты заказчика"
              companyAddress={values.customerAddress!}
              contractor={values.customerSigner!}
              bankAccount={values.customerBankAccount!}
            />
            <Contractor
              title="Реквизиты исполнителя"
              companyAddress={values.partnerAddress!}
              contractor={values.partnerSigner!}
              bankAccount={values.partnerBankAccount!}
            />
          </StyledContent>
          <StyledFooter>
            <Button
              text="Далее"
              onClick={onClickNext}
              disabled={
                !contract.endDate ||
                !contract.contractDate ||
                !contract.startDate ||
                !hasRequisites
              }
            />
          </StyledFooter>
        </>
      ) : (
        <>
          <div />
          <StyledEmpty>
            <StyledText>Заполните реквизиты</StyledText>
            <Button
              text="Заполнить"
              disabled={!contract}
              onClick={onClickShowModal}
            />
          </StyledEmpty>
        </>
      )}
      {!!contract && (
        <ModalRequisites
          open={modalVisible}
          onClose={onCloseModal}
          contract={contract}
          onChangeRequisites={onChangeRequisites}
          requisites={{
            customerSigner: values.customerSigner,
            partnerSigner: values.partnerSigner,
            customerAddress: contract.customerAddress,
            partnerAddress: contract.partnerAddress,
            customerBankAccount: values.customerBankAccount,
            partnerBankAccount: values.partnerBankAccount,
          }}
        />
      )}
    </StyledStep1>
  );
}

export default memo(Step1);
