import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Grid,
  ListItem,
  Modal,
  ModalProps,
  ModalTitle,
  Table,
  Tabs,
} from "@app/components";
import styled, { css } from "styled-components";
import {
  Act,
  ActVehicles,
  UserTaskStatusesName,
  UserTaskTypeName,
} from "@app/models";
import { dateFormat, getFullName } from "@app/helpers";

interface Props extends Omit<ModalProps, "title"> {
  labels: string[];
  tableData: (string | ReactNode)[][];
  actData: Act;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTableHead = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 14px 12px;
  text-align: left;
`;

function ModalActOfServicesPrint(props: Props) {
  const { onClose, actData, tableData, labels, ...restProps } = props;
  // const [items, setItems] = useState<ActVehicles[]>([]);

  useEffect(() => {
    if (restProps.open) {
      // window.print();
    }

    window.addEventListener("afterprint", onClose);
    return () => {
      window.removeEventListener("afterprint", () => {});
    };
  }, [onClose, restProps.open]);

  const signersTableLabels = [
    "№",
    "Вид задачи",
    "ФИО",
    "Результат",
    "Дата выполнения",
  ];

  return (
    <Modal title={""} hideHeader onClose={onClose} dense {...restProps}>
      <Container>
        <Grid columns={4}>
          <ListItem title={actData?.company.name} subtitle={"Организация"} />
          <ListItem title={actData?.project?.name} subtitle={"Проект"} />
          <ListItem title={actData?.partner?.name} subtitle={"Исполнитель"} />
          <ListItem title={actData?.contract?.name} subtitle={"Договор"} />
        </Grid>
        <table>
          <thead>
            <tr>
              {labels.map((label, labelIndex) => (
                <StyledTableHead key={labelIndex.toString()}>
                  {label}
                </StyledTableHead>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((values, valuesIndex) => (
              <tr key={valuesIndex.toString()}>
                {values.map((value, valueIndex) => (
                  <td key={valueIndex.toString()}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* SIGNERS */}
        <table>
          <thead>
            <tr>
              {signersTableLabels.map((label, labelIndex) => (
                <StyledTableHead key={labelIndex.toString()}>
                  {label}
                </StyledTableHead>
              ))}
            </tr>
          </thead>
          <tbody>
            {(actData.actSignersDto || []).map((signer, sIndex) => (
              <tr key={sIndex.toString()}>
                <td>{sIndex + 1}</td>
                <td>{UserTaskTypeName[signer.taskTypeId]}</td>
                <td>{getFullName(signer.userShort)}</td>
                <td>{UserTaskStatusesName[signer.status]}</td>
                <td>{`${dateFormat(signer.termDate, "dd.MM.yyyy")}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
    </Modal>
  );
}

export default memo(ModalActOfServicesPrint);
