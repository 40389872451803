import { memo, useState } from "react";
import "./styles.scss";
import classNames from "classnames";

interface Props {
  negativeLabel?: string;
  positiveLabel?: string;
  value: boolean;
  dark?: boolean;
  onClick: (val: boolean) => void;
}

const Toggle = ({
  negativeLabel,
  positiveLabel,
  value,
  dark,
  onClick,
}: Props) => {
  const [isToggled, toggle] = useState(value);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <div className="container">
      <div
        className={classNames("toggle-btn", {
          "toggle-btn--active": !isToggled,
          "toggle-btn--dark": !isToggled && dark,
        })}
        onClick={callback}
      >
        {negativeLabel}
      </div>
      <div
        className={classNames("toggle-btn", {
          "toggle-btn--active": isToggled,
          "toggle-btn--dark": isToggled && dark,
        })}
        onClick={callback}
      >
        {positiveLabel}
      </div>
    </div>
  );
};

export default memo(Toggle);
