import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Grid,
  ListItem,
  Loader,
  Modal,
  ModalProps,
  Select,
  SelectOption,
} from "@app/components";
import VehicleWorkingCard from "./components";
import { dateFormat, shiftTypes, vehicleStatuses } from "@app/helpers";
import {
  VehicleJournalDetail,
  VehicleJournalList,
  VehicleZhurnalState,
} from "@app/models";
import "./styles.scss";
import styled from "styled-components";
import { IconPlay24 } from "@app/icons";
import { theme } from "styled-tools";
import { FILTER_TYPES } from "../../pages/VehicleAccounting/components/ListView";

const StyledFooter = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  gap: 16px;
`;

const StyledBlock = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledIcon = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) => theme(`color.${props.color}`)};
`;

const LinkButton = styled.button`
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

interface Props extends Omit<ModalProps, "title" | "onClose"> {
  data: VehicleJournalDetail[] | null;
  headerInfo: VehicleJournalList | null;
  filterTypeIndex: number;
  onClose: (needUpdate?: boolean) => void;
}

const FilterRow = styled.div`
  position: sticky;
  top: 178px;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;

  background-color: ${theme("color.white")};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

  p {
    padding: 8px 0;
    white-space: nowrap;
  }
`;

function ModalJournalItem(props: Props) {
  const { onClose, data, headerInfo, filterTypeIndex, ...restProps } = props;

  const [collapsedCards, setCollapsedCards] = useState<number[]>([]);
  const [currentShiftType, setCurrentShiftType] = useState(shiftTypes[0]);
  const [currentGovNumber, setCurrentGovNumber] =
    useState<SelectOption<string>>();

  const filteredList = useMemo(
    () =>
      data?.filter((item) => {
        return (
          ((currentShiftType.value === 0 ||
            currentShiftType.value === +item.shiftType.code) &&
            !currentGovNumber?.value) ||
          item.vehicle.govNumber === currentGovNumber?.value
        );
      }) || [],
    [currentGovNumber, currentShiftType.value, data]
  );

  const onShiftTypeChange = useCallback(
    (value: SelectOption<number> | null) => {
      setCurrentShiftType(value || shiftTypes[0]);
    },
    []
  );

  const govNumbersOptions = useMemo(() => {
    const nums = data?.map((item) => item.vehicle.govNumber);
    const uniq = [...new Set(nums)];
    return uniq.map((num) => ({
      label: num,
      value: num,
    }));
  }, [data]);

  const onGovNumberChange = useCallback(
    (value: SelectOption<string> | null) => {
      setCurrentGovNumber(value || undefined);
    },
    []
  );

  const getStartDate = useCallback(() => {
    if (!data) {
      return "-";
    }
    const minDate = Math.min(...data.map((item) => Date.parse(item.workDate)));
    return dateFormat(new Date(minDate), "dd.MM.yyyy");
  }, [data]);

  const getEndDate = useCallback(() => {
    if (!data) {
      return "-";
    }
    const maxDate = Math.max(...data.map((item) => Date.parse(item.workDate)));
    return dateFormat(new Date(maxDate), "dd.MM.yyyy");
  }, [data]);

  const collapseAllCards = useCallback(
    (onlyConfirmed = false) => {
      if (data) {
        const indexes = data
          .map((item, index) => {
            if (onlyConfirmed) {
              return item.stateId !== VehicleZhurnalState.SAVE ? index : -1;
            }
            return index;
          })
          .filter((x) => x >= 0);
        setCollapsedCards([...indexes]);
      }
    },
    [data]
  );

  const expandAllCards = useCallback(() => {
    setCollapsedCards([]);
  }, []);

  useEffect(() => {
    if (data) {
      collapseAllCards(true);
    }
  }, [collapseAllCards, data]);

  const onModalClose = useCallback(() => {
    setCollapsedCards([]);
    onShiftTypeChange(null);
    setCurrentGovNumber(undefined);
    onClose();
  }, [onClose, onShiftTypeChange]);

  const onGovNumberClick = useCallback(
    (value?: string) => {
      const findIndex = govNumbersOptions.findIndex(
        (item) => item.value === value
      );
      if (findIndex >= 0) {
        setCurrentGovNumber(govNumbersOptions[findIndex]);
      }
    },
    [govNumbersOptions]
  );

  const onShiftTypeClick = useCallback((code?: string) => {
    const findIndex = shiftTypes.findIndex(
      (item) => code && item.value === +code
    );
    if (findIndex >= 0) {
      setCurrentShiftType(shiftTypes[findIndex]);
    }
  }, []);

  const caption = useMemo(() => {
    const returnActions: ReactNode[] = [];

    returnActions.push(
      <StyledFooter>
        {vehicleStatuses.map((item, index) => (
          <StyledBlock key={index.toString()}>
            <StyledIcon color={item.color}>
              <IconPlay24 />
            </StyledIcon>
            <p>{item.title}</p>
          </StyledBlock>
        ))}
      </StyledFooter>
    );
    return returnActions;
  }, []);

  return (
    <Modal
      title={""}
      onClose={onModalClose}
      size="medium"
      hideHeader
      dense
      actions={caption}
      {...restProps}
    >
      <div className="journal-detail">
        <div className="journal-detail__header">
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h2>{headerInfo?.companyName || ""}</h2>
            </div>
            <h3 title={headerInfo?.projectName || ""}>
              {headerInfo?.projectName || ""}
            </h3>
          </div>
          <div className="journal-detail__divider" />
          <div className="journal-detail__card-row"></div>
          <Grid columns={4}>
            <ListItem
              title={`${data?.length || "-"}`}
              subtitle="Количество техники"
              reverse
            />
            <ListItem
              title={getStartDate()}
              subtitle={
                filterTypeIndex === FILTER_TYPES.BY_DATES
                  ? "Дата выполнения"
                  : "Дата начала"
              }
              reverse
            />
            {filterTypeIndex !== FILTER_TYPES.BY_DATES ? (
              <ListItem
                title={getEndDate()}
                subtitle="Дата завершения"
                reverse
              />
            ) : (
              <div></div>
            )}

            <LinkButton
              onClick={() => {
                collapsedCards.length ? expandAllCards() : collapseAllCards();
              }}
            >
              {collapsedCards.length ? "Развернуть все" : "Свернуть все"}
            </LinkButton>
          </Grid>
        </div>
        <FilterRow>
          <Select
            label={"Фильтровать по смене"}
            options={shiftTypes}
            value={currentShiftType}
            onChange={onShiftTypeChange}
          />
          <Select
            label={"Фильтровать по госномеру"}
            options={govNumbersOptions}
            value={currentGovNumber}
            onChange={onGovNumberChange}
          />
          <p>Показано всего: {filteredList.length}</p>
        </FilterRow>
        <div className="journal-detail__content">
          {!data && <Loader />}
          {filteredList.map((item: VehicleJournalDetail, index) => (
            <VehicleWorkingCard
              key={index.toString() + item.id}
              data={item}
              headerInfo={headerInfo}
              filterTypeIndex={filterTypeIndex}
              expanded={!collapsedCards.includes(index)}
              onConfirm={onClose}
              onGovNumberClick={onGovNumberClick}
              onShiftClick={onShiftTypeClick}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalJournalItem);
