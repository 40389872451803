import React, { memo } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import styled, { css } from "styled-components";
import BGSrc from "./bg.png";
import ImageDocuments from "./documents.png";
import content from "./content.json";
import { Grid } from "@app/components";
import { switchProp } from "styled-tools";

interface Props {
  variant?: "default" | "secondary";
}

const StyledPanelContract = styled(StyledPanel)<Pick<Props, "variant">>`
  @media only screen and (max-width: 576px) {
    background: none;
  }
  background-image: url(${BGSrc});
  background-size: 435px;
  background-repeat: no-repeat;
  background-position: bottom right;
  grid-column-start: 3;
  grid-column-end: 7;

  ${switchProp("variant", {
    default: css``,
    secondary: css``,
  })}
`;

function PanelContract(props: Props) {
  const { variant = "default" } = props;

  return (
    <StyledPanelContract variant={variant}>
      <Grid gap={12}>
        <StyledPanelTitle>{content.title}</StyledPanelTitle>
        <StyledPanelText>
          {variant === "default" ? content.text : content.text2}
        </StyledPanelText>
        <StyledPanelImageMobile src={ImageDocuments} width={"100%"} alt="" />
      </Grid>
    </StyledPanelContract>
  );
}

export default memo(PanelContract);
