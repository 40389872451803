import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Grid,
  Modal,
  ModalProps,
  Select,
  SelectOption,
  TextField,
} from "@app/components";
import { ShortEmployee } from "@app/models";
import { useNotification, useUser } from "@app/providers";
import { APIResponse, getCompanyEmployeesShort, redirectTask } from "@app/api";
import { getAxiosErrorMessage, getFullName } from "@app/helpers";
import { AxiosError } from "axios";

interface Props extends ModalProps {
  taskId?: string;
  companyId?: string;
  onClose: (val?: boolean) => void;
}

const ModalActTaskRedirect = (props: Props) => {
  const { onClose, taskId, companyId, ...restProps } = props;
  const { showNotification } = useNotification();
  const { user } = useUser();
  const [employees, setEmployees] = useState<SelectOption<ShortEmployee>[]>([]);
  const [selectedUser, selectUser] =
    useState<SelectOption<ShortEmployee> | null>(null);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const getCompanyEmployees = useCallback(async (id: string) => {
    const response = await getCompanyEmployeesShort([id]);
    if (response && response.data) {
      setEmployees(
        response.data.map((employee) => ({
          label: getFullName(employee.user),
          value: employee.user.id,
          item: employee,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      getCompanyEmployees(companyId).then(() => {});
    }
  }, [companyId, getCompanyEmployees]);

  const onRedirectConfirm = useCallback(async () => {
    if (!taskId || !selectedUser || !user) {
      return;
    }
    const body = {
      taskId,
      userId: selectedUser.value,
      oldUserId: user.id,
      comment,
    };
    setLoading(true);
    redirectTask(body)
      .then((res) => {
        showNotification({
          variant: "success",
          message: "Перенаправлен",
        });
        onClose(true);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      });
  }, [comment, onClose, selectedUser, showNotification, taskId, user]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text="Перенаправить"
        disabled={!selectedUser}
        onClick={onRedirectConfirm}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onRedirectConfirm, selectedUser]);

  const onUserSelect = (value: SelectOption<ShortEmployee> | null) => {
    selectUser(value);
  };

  const onCommentChange = useCallback((value: string) => {
    setComment(value);
  }, []);

  return (
    <Modal actions={actions} onClose={onClose} {...restProps}>
      <Grid>
        <Select<ShortEmployee>
          label="ФИО ответственнога лица"
          placeholder="Выберите из списка"
          name="user"
          onChange={onUserSelect}
          value={selectedUser}
          options={employees}
        />
        <TextField
          label="Комментарий"
          placeholder="Добавьте комментарий (необязательно)"
          value={comment}
          name="comment"
          onChange={onCommentChange}
        />
      </Grid>
    </Modal>
  );
};

export default memo(ModalActTaskRedirect);
