import { VehicleRequestOfferStatus, VReqOfferStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: VehicleRequestOfferStatus;
}

const getStatusVariant = (status: VehicleRequestOfferStatus): ChipColor => {
  switch (status) {
    case VehicleRequestOfferStatus.UnderConsideration:
      return "default";
    case VehicleRequestOfferStatus.Selected:
      return "success";
    case VehicleRequestOfferStatus.RequestCancelled:
    case VehicleRequestOfferStatus.Deleted:
    case VehicleRequestOfferStatus.Rejected:
      return "danger";
    default:
      return "default";
  }
};

function VRequestOfferStatusChip(props: Props) {
  const { status } = props;

  return (
    <Chip text={VReqOfferStatusName[status]} color={getStatusVariant(status)} />
  );
}

export default memo(VRequestOfferStatusChip);
