import { memo, PropsWithChildren, useEffect } from "react";
import styled from "styled-components";
import { LandingFooter, LandingHeader } from "./components";
import { theme } from "styled-tools";

interface Props {}

const StyledLayout = styled.div`
  background-color: ${theme("color.white")};
  min-height: 100%;
  padding-top: 70px;
  box-sizing: border-box;
`;

function LayoutLanding(props: Props & PropsWithChildren) {
  const { children } = props;

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = "100%";
    document.body.style.backgroundColor = "#FFF";
  }, []);

  return (
    <StyledLayout>
      <LandingHeader />
      {children}
      <LandingFooter />
    </StyledLayout>
  );
}

export default memo(LayoutLanding);
