import { LayoutDefault } from "@app/layouts";
import React, { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styled-tools";
import { IconBag36, IconTyre36 } from "@app/icons";
import { ImgDictCompanies, ImgDictVehicles } from "assets/images/main";
import { PageCard } from "@app/common";

const StyledContainer = styled.div`
  display: flex;
  height: calc(100% - 32px);
`;

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 16px;
  background: ${theme("color.white")};
  flex: 1;
`;

function PageDictionaries() {
  const navigate = useNavigate();
  const data = useMemo(
    () => [
      {
        path: "companies",
        bg: ImgDictCompanies,
        icon: () => <IconBag36 />,
        title: "Компании",
      },
      {
        path: "vehicles",
        bg: ImgDictVehicles,
        icon: () => <IconTyre36 />,
        title: "Транспортные средства",
      },
    ],
    []
  );

  const onClick = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <LayoutDefault title="Справочная информация">
      <StyledContainer>
        <StyledGrid>
          {data.map((item, index) => (
            <PageCard
              key={index.toString()}
              bg={item.bg}
              onClick={onClick(item.path)}
            >
              {item.icon()}
              <p>{item.title}</p>
            </PageCard>
          ))}
        </StyledGrid>
      </StyledContainer>
    </LayoutDefault>
  );
}

export default memo(PageDictionaries);
