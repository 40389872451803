import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export const useElementOnScreen = <T extends Element>(
  options?: IntersectionObserverInit
): [RefObject<T>, boolean, boolean] => {
  const containerRef = useRef<T>(null);
  const [appeared, setAppeared] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const observeCallback: IntersectionObserverCallback = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
    if (entry.intersectionRatio > (options?.threshold || 0)) {
      setAppeared(true);
    }
  };

  useEffect(() => {
    const { current: container } = containerRef;
    if (!container) return undefined;

    if (!("IntersectionObserver" in window)) return undefined;

    const observer = new IntersectionObserver(observeCallback, options);
    observer.observe(container);

    return () => observer.unobserve(container);
  }, [containerRef, observeCallback, options]);

  return [containerRef, appeared, isVisible];
};
