import React, { memo, PropsWithChildren, useEffect, useState } from "react";
import { useUser } from "@app/providers";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface Props extends PropsWithChildren {
  title?: string;
}

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  @page {
    size: A4 landscape;
  }

  @media print {
    padding: 0;
    button {
      display: none;
    }
  }
`;

function LayoutPrinting(props: Props) {
  const { title, children } = props;
  const { authenticated } = useUser();
  const navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    if (!authenticated) {
      navigate("/sign-in");

      setPending(false);

      return;
    }

    setPending(false);
  }, [authenticated, navigate]);

  if (pending) {
    return null;
  }

  return (
    <StyledLayout>
      <h2>{title}</h2>
      {children}
    </StyledLayout>
  );
}

export default memo(LayoutPrinting);
