import { memo } from "react";
import styled from "styled-components";

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (!!props.onClick ? "pointer" : "initial")};
`;

export default memo(FileWrapper);
