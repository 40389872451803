import React, { memo, PropsWithChildren, ReactNode, useCallback } from "react";
import styled from "styled-components";
import { IconClose24 } from "@app/icons";
import ModalTitle from "../ModalTitle";

interface Props extends PropsWithChildren {
  title: string | ReactNode;
  closeModal: () => void;
}

const StyledModalHeader = styled.div`
  box-sizing: border-box;
  padding: 0 24px;
  justify-content: space-between;
  background: #f5f6f8;
  border-bottom: 1px solid #d8d8d8;
  z-index: 1;
`;

const StyledTop = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 16px 0;
  box-sizing: border-box;
`;

const StyledContent = styled.div`
  padding-bottom: 16px;
  box-sizing: border-box;
`;

const StyledModalClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: #eee;
  }
`;

function ModalHeader(props: Props) {
  const { title, closeModal, children } = props;

  const onClickClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      closeModal();
    },
    [closeModal]
  );

  return (
    <StyledModalHeader>
      <StyledTop>
        {typeof title === "string" ? <ModalTitle text={title} /> : title}
        <StyledModalClose onClick={onClickClose}>
          <IconClose24 />
        </StyledModalClose>
      </StyledTop>
      {!!children && <StyledContent>{children}</StyledContent>}
    </StyledModalHeader>
  );
}

export default memo(ModalHeader);
