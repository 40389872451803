import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Chip, Modal, ModalProps, Table } from "@app/components";
import { priceFormat } from "@app/helpers";
import styled from "styled-components";
import { theme } from "styled-tools";
import { KIPProject } from "@app/models";
import { TooltipCell } from "@app/common";
import { ModalKIPProject } from "../index";

interface Props extends Pick<ModalProps, "onClose" | "open" | "title"> {
  projects: Partial<KIPProject>[];
  date: string;
}

const CellNumeration = styled.div`
  padding-left: 8px;
`;

const CellTitle = styled.div`
  width: 260px;
`;

const CellRedText = styled.div`
  color: ${theme("color.red")};
`;

const StyledTableLabel = styled.div`
  p {
    color: ${theme("color.grayDark")};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
  }

  > div {
    margin-bottom: 6px;
  }
`;

export default function (props: Props) {
  const { onClose, open, projects, title, date } = props;
  const [project, setProject] = useState<KIPProject | null>(null);

  const tableData = useMemo(() => {
    return projects.map((item, itemIndex) => [
      <CellNumeration>{(itemIndex + 1).toString()}</CellNumeration>,
      <CellTitle>
        <TooltipCell text={item.name || ""} maxWidth={260} />
      </CellTitle>,
      item.vehiclesQty ? `${item.vehiclesQty} ТС` : "-",
      typeof item.workLoad === "number" ? `${item.workLoad.toFixed()}%` : "-",
      item.costAmount ? priceFormat(item.costAmount.toFixed()) : "-",
      <CellRedText>
        {item.unusedAmount ? priceFormat(item.unusedAmount.toFixed()) : "-"}
      </CellRedText>,
    ]);
  }, [projects]);

  const tableLabels = useMemo<(string | ReactNode)[]>(() => {
    const vehicleCount = projects.reduce(
      (acc, item) => acc + item.vehiclesQty!,
      0
    );

    const workLoad =
      projects.reduce(
        (acc, item) =>
          acc + (typeof item.workLoad === "number" ? item.workLoad : 0),
        0
      ) / projects.length;

    const costAmount = projects.reduce(
      (acc, item) => acc + item.costAmount!,
      0
    );

    const unusedAmount = projects.reduce(
      (acc, item) => acc + item.unusedAmount!,
      0
    );

    return [
      "№",
      "Наименование",
      <StyledTableLabel>
        <p>Количество техники</p>
        <Chip
          bold={true}
          color="primaryDark"
          text={`${vehicleCount} TC`}
          textSize="sm"
        />
      </StyledTableLabel>,
      <StyledTableLabel>
        <p>Загруженность</p>
        <Chip
          bold={true}
          color="warning"
          text={`${!!workLoad ? workLoad.toFixed() : 0}%`}
          textSize="sm"
        />
      </StyledTableLabel>,
      <StyledTableLabel>
        <p>Сумма затрат</p>
        <Chip
          color="info"
          text={priceFormat(costAmount.toFixed())}
          textSize="sm"
        />
      </StyledTableLabel>,
      <StyledTableLabel>
        <p>Неосвоенный бюджет</p>
        <Chip
          bold={true}
          color="danger"
          text={priceFormat(unusedAmount.toFixed())}
          textSize="sm"
        />
      </StyledTableLabel>,
    ];
  }, [projects]);

  const onCloseModal = useCallback(() => {
    setProject(null);
  }, []);

  const onClickTableRow = useCallback(
    (index: number) => {
      setProject(projects[index] as KIPProject);
    },
    [projects]
  );

  if (!open) {
    return null;
  }

  return (
    <>
      <Modal title={title} onClose={onClose} open={open} size={"medium"}>
        <Table
          align="top"
          labels={tableLabels}
          data={tableData}
          dense
          onClick={onClickTableRow}
        />
      </Modal>
      <ModalKIPProject
        project={project}
        date={date}
        onClose={onCloseModal}
        open={!!project}
      />
    </>
  );
}
