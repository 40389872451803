import {
  Button,
  Checkbox,
  Grid,
  Loader,
  Modal,
  ModalProps,
  Select,
  SelectOption,
  TextField,
} from "@app/components";
import { useCallback, useEffect, useState } from "react";
import { getCompanyAddresses } from "@app/api";
import { Dictionary, LegalAddress } from "@app/models";
import { useNotification } from "@app/providers";

interface Props extends Pick<ModalProps, "open" | "onClose" | "title"> {
  onSubmit: (address: string) => void;
  companyId: string;
  address: string;
}

function ModalActCompanyAddress(props: Props) {
  const {
    open,
    onClose,
    onSubmit,
    address: propsAddress,
    companyId,
    ...modalProps
  } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(true);
  const [isSelect, setIsSelect] = useState<boolean>(true);
  const [addressText, setAddressText] = useState<string>("");
  const [address, setAddress] = useState<SelectOption<Dictionary> | null>(null);
  const [addresses, setAddresses] = useState<SelectOption<Dictionary>[]>([]);

  const onChange = useCallback(
    (value: boolean) => () => {
      setAddressText("");
      setAddress(null);
      setIsSelect(value);
    },
    []
  );

  const onChangeAddress = useCallback(
    (value: SelectOption<Dictionary> | null) => {
      setAddress(value);
    },
    []
  );

  const onChangeAddressText = useCallback((value: string) => {
    setAddressText(value);
  }, []);

  const onClickSubmit = useCallback(() => {
    if (isSelect) {
      if (!address) {
        showNotification({
          variant: "error",
          message: "Выберите адрес",
        });

        return;
      }

      onSubmit(address.label);
      return;
    }

    if (!addressText) {
      showNotification({
        variant: "error",
        message: "Укажите адрес",
      });

      return;
    }

    onSubmit(addressText);
  }, [address, addressText, isSelect, onSubmit, showNotification]);

  const getActions = useCallback(
    () => [<Button text="Далее" onClick={onClickSubmit} />],
    [onClickSubmit]
  );

  const formatLegalAddress = useCallback((item: LegalAddress) => {
    if (!item.region) {
      return item.street;
    }

    return (
      `${item.region.name || ""}, ул.${item.street || ""}` +
      (item.block ? ` ${item.block}` : "") +
      (item.apartment ? `, офис ${item.apartment}` : "")
    );
  }, []);

  const getData = useCallback(async () => {
    try {
      const response = await getCompanyAddresses(companyId);
      const tempAddresses = response.data.map((item: any) =>
        formatLegalAddress(item)
      );
      const tempAddressIndex = tempAddresses.indexOf(propsAddress);

      if (!!propsAddress && tempAddressIndex > -1) {
        setAddress({
          label: tempAddresses[tempAddressIndex],
          value: tempAddresses[tempAddressIndex],
        });
      } else {
        setAddressText(propsAddress);
      }

      setAddresses(
        tempAddresses.map((tempAddress) => ({
          label: tempAddress,
          value: tempAddress,
        }))
      );

      setPending(false);
    } catch (e) {
      setPending(false);
      setIsSelect(false);
    }
  }, [companyId, propsAddress, formatLegalAddress]);

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [getData, open]);

  if (!open) {
    return null;
  }

  return (
    <Modal {...modalProps} open={open} onClose={onClose} actions={getActions()}>
      {pending && <Loader />}
      {!pending && (
        <Grid gap={16}>
          <Checkbox
            type="radio"
            label="Выбрать из списка"
            checked={isSelect}
            onChange={onChange(true)}
          />
          <Checkbox
            type="radio"
            label="Заполнить адрес вручную"
            checked={!isSelect}
            onChange={onChange(false)}
          />
          {isSelect ? (
            <Select<Dictionary>
              label={modalProps.title as string}
              onChange={onChangeAddress}
              value={address}
              options={addresses}
            />
          ) : (
            <TextField
              label={modalProps.title as string}
              onChange={onChangeAddressText}
              value={addressText}
            />
          )}
        </Grid>
      )}
    </Modal>
  );
}

export default ModalActCompanyAddress;
