import { DocumentFile } from "@app/components";
import React, { FC } from "react";
import { VehicleInfo } from "@app/models";
import styled from "styled-components";

type Props = {
  vehicle: VehicleInfo | null;
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding: 16px;
`;

export const VehicleFilesView: FC<Props> = (props) => {
  const { vehicle } = props;

  if (!vehicle) {
    return null;
  }

  return (
    <StyledGrid>
      {vehicle.files.map((item, index) => {
        const { file } = item;
        return (
          <DocumentFile
            key={index.toString()}
            id={file.id}
            name={file.name || `${item?.id || index.toString()}`}
            previewEnabled={true}
          />
        );
      })}
    </StyledGrid>
  );
};
