import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "@app/providers";
import {
  Company,
  Project,
  VehicleListItem,
  VehicleZhurnalState,
  VehicleZhurnalStateName,
  VehicleZhurnalStateNaming,
} from "@app/models";
import styled from "styled-components";
import { theme } from "styled-tools";
import {
  Button,
  DateTimePicker,
  Grid,
  Loader,
  Select,
  SelectOption,
  useForm,
} from "@app/components";
import {
  companyLabelKeys,
  currencyFormat,
  dateFormat,
  enumToArray,
  isDispatcher,
  isPartner,
  isUser,
  shiftTypes,
} from "@app/helpers";
import {
  getCompanies,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getVehicleJournalDetailByPartner,
  getVehicles,
  PageableParams,
  PageableResponse,
} from "@app/api";
import * as yup from "yup";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  table {
    width: 100%;
    border-collapse: collapse;
    page-break-inside: auto;
  }

  th,
  td {
    border: 1px solid ${theme("color.grayLight")};
    padding: 8px;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  hr {
    border-top: 1px solid ${theme("color.grayLight")};
    width: 100%;
    float: left;
    height: 0;
  }

  @page {
    size: A4 landscape;
  }

  @media print {
    padding: 0;
    button {
      display: none;
    }
  }
`;

const StyledTableHead = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  background-color: ${theme("color.yellowLight")};
  width: 320px;
`;

const ChildCell = styled.td`
  width: 320px;
  font-size: 14px;
  color: ${theme("color.dark")};
  font-family: ${theme("fontFamily")};
`;

const PrintButton = styled.button`
  border: none;
  background: lightgray;
  outline: none;
  cursor: pointer;
`;

const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledButton = styled.div`
  margin-right: 16px;
`;

const WrappedCell = styled.td<{ width: number }>`
  max-width: 340px;
  font-size: 14px;
  line-height: 16px;
  color: ${theme("color.dark")};
  white-space: normal;
`;

const StyledEmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 200px;
`;

const StyledEmptyText = styled.p`
  font-size: 12px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Data {
  customer: SelectOption<any> | null;
  project: SelectOption<Project> | null;
  partner: SelectOption<Company> | null;
  startDate: Date | null;
  endDate: Date | null;
  statuses: SelectOption<any> | null;
  vehicle: SelectOption<VehicleListItem> | null;
}

const schema = yup.object().shape({
  customer: yup.object().nullable().required("required"),
  project: yup.object().nullable().required(),
  partner: yup.object().nullable().required(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
});

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: " ",
};

function PageVehicleAccountingPrinting() {
  const { user } = useUser();
  const [vehicleData, setVehicleData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [currentShiftType, setCurrentShiftType] = useState(shiftTypes[0]);
  // const [myCompanies, setMyCompanies] = useState<any[]>([]);
  // const [companies, setCompanies] = useState<any[]>([]);
  const { onChange, values, errors, setPending, setValues } = useForm<Data>({
    values: {
      customer: null,
      project: null,
      partner: null,
      startDate: null,
      endDate: null,
      statuses: null,
      vehicle: null,
    },
    schema,
  });

  const onOrganizationSelect = (value: SelectOption<any> | null, name: any) => {
    onChange(value, name);
  };

  const onClickSubmit = useCallback(async () => {
    setLoading(true);

    const params: any = {
      companyId: values.customer?.value,
      projectId: values.project?.value,
      partnerId: values.partner?.value,
      startDate: values.startDate
        ? dateFormat(values.startDate, "yyyy-MM-dd")
        : null,
      endDate: values.endDate ? dateFormat(values.endDate, "yyyy-MM-dd") : null,
      stateIds: values.statuses?.value,
      vehicleId: values.vehicle?.value,
    };

    if (currentShiftType.value !== 0 && currentShiftType.value !== null) {
      params.shiftTypeCode = currentShiftType.value;
    }
    try {
      setPending(true);
      const response = await getVehicleJournalDetailByPartner(params);
      setVehicleData(response.data);
      setLoading(false);
    } catch (error) {
      setPending(false);
      setLoading(false);
    } finally {
      setPending(false);
      setLoading(false);
    }
  }, [setPending, values, currentShiftType]);

  const loadVehicles = useCallback(
    (params: PageableParams<VehicleListItem>) => {
      return getVehicles({
        ...params,
        companyId: values.partner!.value,
      });
    },
    [values.partner]
  );

  const getPriceWithNDS = useCallback((price: number | string | null) => {
    if (price === null || price === undefined) {
      return "-";
    }
    return currencyFormat(price);
  }, []);

  const vehicleLabelKeys = useMemo<(keyof VehicleListItem)[]>(
    () => ["vehicleType", "govNumber"],
    []
  );

  const onClickReset = useCallback(() => {
    setValues({});
  }, [setValues]);

  const onShiftTypeChange = useCallback(
    (value: SelectOption<number> | null) => {
      setCurrentShiftType(value || shiftTypes[0]);
    },
    []
  );

  const getStateName = (stateId: VehicleZhurnalState) => {
    return VehicleZhurnalStateNaming[stateId] || "-";
  };

  function formatSumma(sum: number | null | undefined): string {
    if (sum === null || sum === undefined) {
      return "-";
    }
    if (Math.floor(sum) === sum) {
      return sum.toString();
    }
    return sum.toFixed(2);
  }

  const loadMyCompanies = useCallback(
    async (params: PageableParams<any>): Promise<PageableResponse<any>> => {
      return await getMyCompanies(params);
    },
    []
  );

  const loadCompanies = useCallback(
    async (params: PageableParams<any>): Promise<PageableResponse<any>> => {
      return await getCompanies(params);
    },
    []
  );

  useEffect(() => {
    (async () => {
      await loadMyCompanies(initialParams);
      await loadCompanies(initialParams);
    })();
  }, [loadMyCompanies, loadCompanies]);

  const onFilterChange = useCallback(
    (value: any, key: any) => {
      setValues({ ...values, [key]: value });
    },
    [setValues, values]
  );

  return (
    <Container>
      <h2>{`Журнал учета техники`}</h2>
      <Grid columns={5}>
        <Select<any>
          label="Заказчик"
          name="customer"
          onChange={onOrganizationSelect}
          value={values.customer}
          error={!!errors.customer}
          helperText={errors.customer}
          valueKey="id"
          labelKeys={companyLabelKeys}
          labelKeysSeparator={" / "}
          loadData={isUser(user!.role) ? loadMyCompanies : loadCompanies}
        />
        <Select<Project>
          label="Проект"
          name="project"
          onChange={onChange}
          value={values.project}
          error={!!errors.project}
          helperText={errors.project}
          valueKey="id"
          labelKey="name"
          loadData={
            isDispatcher(user!.role) || isPartner(user!.role)
              ? getProjects
              : getMyProjects
          }
        />
        <Select<any>
          label="Партнер"
          name="partner"
          onChange={onChange}
          value={values.partner}
          valueKey="id"
          labelKey="name"
          labelKeys={companyLabelKeys}
          labelKeysSeparator={" / "}
          loadData={
            isUser(user!.role) || isDispatcher(user!.role)
              ? loadCompanies
              : loadMyCompanies
          }
        />
        <DateTimePicker
          label="Период От"
          name="startDate"
          onChange={onChange}
          value={values.startDate}
          error={!!errors.startDate}
          helperText={errors.startDate}
          hideTime={true}
        />
        <DateTimePicker
          label="Период До"
          name="endDate"
          onChange={onChange}
          value={values.endDate}
          error={!!errors.endDate}
          helperText={errors.endDate}
          hideTime={true}
        />
        <Select<VehicleListItem>
          label="Техника"
          name="vehicle"
          onChange={onChange}
          value={values.vehicle}
          error={!!errors.vehicle}
          helperText={errors.vehicle}
          valueKey="id"
          labelKeys={vehicleLabelKeys}
          loadData={loadVehicles}
          disabled={!values.partner}
        />
        <Select
          label={"Смена"}
          options={shiftTypes}
          value={currentShiftType}
          onChange={onShiftTypeChange}
        />
        <Select
          label="Статус"
          name="statuses"
          value={values.statuses}
          onChange={(value) => onFilterChange(value, "statuses")}
          options={enumToArray(VehicleZhurnalStateName).map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
        />
      </Grid>
      <StyledButtonRow>
        <StyledButton>
          <Button text="Сбросить" variant="outlined" onClick={onClickReset} />
        </StyledButton>
        <Button text="Применить" onClick={onClickSubmit} showLoader={loading} />
      </StyledButtonRow>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {vehicleData && vehicleData.length > 0 ? (
            <>
              <Grid columns={4}>
                <Container>
                  <Grid columns={1}>
                    <table>
                      <thead>
                        <tr>
                          <StyledTableHead>Контрагент</StyledTableHead>
                          <StyledTableHead>Вид техники</StyledTableHead>
                          <StyledTableHead>Характеристика</StyledTableHead>
                          <StyledTableHead>Вид услуги</StyledTableHead>
                          <StyledTableHead>Гос. номер</StyledTableHead>
                          <StyledTableHead>Цена</StyledTableHead>
                          <StyledTableHead>Ставка НДС</StyledTableHead>
                          <StyledTableHead>Дата работы</StyledTableHead>
                          <StyledTableHead>Время начала работ</StyledTableHead>
                          <StyledTableHead>
                            Время завершения работ
                          </StyledTableHead>
                          <StyledTableHead>
                            Моточасы работы двигателя GPS
                          </StyledTableHead>
                          <StyledTableHead>
                            Длительность смены по GPS
                          </StyledTableHead>
                          <StyledTableHead>
                            Фактическое время работы
                          </StyledTableHead>
                          <StyledTableHead>Ед. изм</StyledTableHead>
                          <StyledTableHead>Сумма</StyledTableHead>
                          <StyledTableHead>Статус</StyledTableHead>
                        </tr>
                      </thead>

                      <tbody>
                        {vehicleData?.map((item: any, index: any) => (
                          <tr key={index}>
                            <WrappedCell width={300}>
                              {item.company.name}
                            </WrappedCell>
                            <ChildCell>{item.vehicleType.name}</ChildCell>
                            <ChildCell>
                              {item.vehicleCharacteristic
                                ? item.vehicleCharacteristic.name
                                : ""}
                            </ChildCell>
                            <ChildCell>{item.serviceType.name}</ChildCell>
                            <ChildCell>{item.vehicle.govNumber}</ChildCell>
                            <WrappedCell width={300}>
                              {getPriceWithNDS(
                                item.ndsShortDto?.isNds
                                  ? item.priceWithNds
                                  : item.priceWithOutNds
                              )}
                            </WrappedCell>
                            <WrappedCell width={300}>
                              {item.ndsShortDto?.isNds ? "с НДС" : "без НДС"}
                            </WrappedCell>
                            <ChildCell>
                              {dateFormat(item.workDate, "dd.MM.yyyy")}
                            </ChildCell>
                            <ChildCell>
                              {dateFormat(item.workStartDateTime, "HH:mm")}
                            </ChildCell>
                            <ChildCell>
                              {dateFormat(item.workEndDateTime, "HH:mm")}
                            </ChildCell>
                            <ChildCell>{item.hoursGps}</ChildCell>
                            <ChildCell>{item.shiftDuration}</ChildCell>
                            <ChildCell>{item.workPerformed}</ChildCell>
                            <ChildCell>{item.unitShort.name}</ChildCell>
                            <ChildCell>{formatSumma(item.summa)}</ChildCell>
                            <ChildCell>{getStateName(item.stateId)}</ChildCell>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Grid>
                </Container>
              </Grid>
              <PrintButton onClick={() => window.print()}>Печать</PrintButton>
            </>
          ) : (
            <StyledEmptyContainer>
              <StyledEmptyText>Нет данных</StyledEmptyText>
            </StyledEmptyContainer>
          )}
        </>
      )}
    </Container>
  );
}

export default memo(PageVehicleAccountingPrinting);
