import styled from "styled-components";
import { theme } from "styled-tools";

const SectionTitle = styled.h4`
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;
  text-transform: uppercase;
  white-space: pre-wrap;
  color: ${theme("color.dark")};

  @media only screen and (max-width: 576px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }
`;

export default SectionTitle;
