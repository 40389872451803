import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getCompanyEmployeesShort } from "@app/api";
import {
  Act,
  ActStatus,
  CompanyProjectRecipientsType,
  ShortEmployee,
  UserTaskStatusesName,
  UserTaskType,
  UserTaskTypeName,
} from "@app/models";
import { Button, Select, SelectOption, Table, Toggle } from "@app/components";
import { dateFormat, getFullName } from "@app/helpers";
import { IconPlus24, IconTrash24 } from "@app/icons";
import styled from "styled-components";
import ActApprovalListView from "../../ActTaskDetail/components/ApprovalList";
import { theme } from "styled-tools";
import { CompanyNotificationRecipients } from "@app/common";
import { Signer } from "../types";

type Props = {
  actData: Act | null;
  actSigners: Signer[];
  selectedRecipientIds: string[];
  isParallel: boolean;
  onSignersChange: (signers: Signer[]) => void;
  onRecipientsChange: (ids: string[]) => void;
  onApprovalTypeChange: (isParallel: boolean) => void;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 16px;
`;

const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 8px;

  h6 {
    margin-top: 24px;
  }
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${theme("color.orange")};
  opacity: 0.8;
  transition: 0.1s ease-in-out;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
  &:disabled {
    opacity: 1 !important;
    transform: scale(1);
    cursor: default;
    color: ${theme("color.gray")};
    box-shadow: none;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  width: 500px;
  padding-bottom: 16px;
`;

const Step3 = (props: Props) => {
  const {
    actData,
    actSigners,
    selectedRecipientIds,
    isParallel,
    onSignersChange,
    onRecipientsChange,
    onApprovalTypeChange,
  } = props;
  const [employees, setEmployees] = useState<ShortEmployee[]>([]);

  const getEmployees = (companyId: string) => {
    getCompanyEmployeesShort([companyId]).then((res) => {
      if (res && res.data) {
        setEmployees(res.data);
      }
    });
  };

  useEffect(() => {
    if (actData?.company) {
      getEmployees(actData.company.id);
    }
  }, [actData?.company]);

  const taskOptionList = useMemo(
    () =>
      Object.entries(UserTaskTypeName)
        .map(([key, value]) => ({
          label: value,
          value: key,
          item: null,
        }))
        .filter((item) => item.value === UserTaskType.AgreementAct.toString()),
    []
  );

  const employeeOptionList = useMemo(
    () =>
      employees
        .filter(
          (employee) =>
            !actSigners.find(
              (signer) => signer.user && signer.user.value === employee.user.id
            )
        )
        .map(({ user }) => ({
          label: getFullName(user),
          value: user.id,
          item: user,
        })),
    [actSigners, employees]
  );

  const onTaskSelect = useCallback(
    (index: number) => (value: SelectOption<any> | null, name: any) => {
      actSigners[index] = {
        ...actSigners[index],
        [name]: value,
      };
      onSignersChange([...actSigners]);
    },
    [actSigners, onSignersChange]
  );

  const onEmployeeSelect = useCallback(
    (index: number) => (value: SelectOption<any> | null, name: any) => {
      actSigners[index] = {
        ...actSigners[index],
        [name]: value,
      };
      onSignersChange([...actSigners]);
    },
    [actSigners, onSignersChange]
  );

  const onEmployeeRemove = useCallback(
    (index: number) => () => {
      actSigners.splice(index, 1);
      onSignersChange([...actSigners]);
    },
    [actSigners, onSignersChange]
  );

  const tableLabels = useMemo(
    () => [
      "Вид задачи",
      "ФИО ответственного",
      "Результат",
      "Срок",
      "Дата выполнения",
    ],
    []
  );

  const mapTableData = useCallback(
    (item: Signer, index: number) => {
      return [
        <Select<any>
          name="task"
          onChange={onTaskSelect(index)}
          value={item.task}
          options={taskOptionList}
        />,
        <Select<any>
          name="user"
          onChange={onEmployeeSelect(index)}
          value={item.user}
          options={employeeOptionList}
        />,
        item.status ? UserTaskStatusesName[item.status] : "-",
        item.createdDate ? dateFormat(item.createdDate, "dd.MM.yyyy") : "-",
        item.termDate && item.termDate !== "0001-01-01T00:00:00"
          ? dateFormat(item.termDate, "dd.MM.yyyy")
          : "-",
        <StyledButton onClick={onEmployeeRemove(index)}>
          <IconTrash24 />
        </StyledButton>,
      ];
    },
    [
      employeeOptionList,
      onEmployeeRemove,
      onEmployeeSelect,
      onTaskSelect,
      taskOptionList,
    ]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => actSigners.map(mapTableData),
    [actSigners, mapTableData]
  );

  const onSignerAdd = () => {
    if (actSigners.length >= employees.length) {
      return;
    }
    onSignersChange([
      ...actSigners,
      {
        task: null,
        user: null,
      },
    ]);
  };

  const onRecipientChange = useCallback(
    (userIds: string[]) => {
      onRecipientsChange(userIds);
    },
    [onRecipientsChange]
  );

  const onTypeChange = useCallback(
    (val: boolean) => {
      onApprovalTypeChange(val);
    },
    [onApprovalTypeChange]
  );

  return (
    <StyledContainer>
      {actData && (
        <CompanyNotificationRecipients
          companyId={actData.company.id}
          projectId={actData.project.id}
          typeId={CompanyProjectRecipientsType.AVRs}
          userIds={selectedRecipientIds}
          onChange={onRecipientChange}
        />
      )}
      <h6>Лица согласования</h6>
      <ToggleContainer>
        <Toggle
          negativeLabel="Последовательное"
          positiveLabel="Параллельное согласование"
          value={isParallel}
          onClick={onTypeChange}
        />
      </ToggleContainer>
      <Table
        labels={tableLabels}
        data={tableData}
        disableOverflow={true}
        onClick={() => {}}
      />
      <Button onClick={onSignerAdd} startIcon={IconPlus24} />

      {actData?.status === ActStatus.ActRevoked && (
        <HistoryList>
          <h6>Лист согласования</h6>
          <ActApprovalListView actId={actData?.id} />
        </HistoryList>
      )}
    </StyledContainer>
  );
};

export default memo(Step3);
