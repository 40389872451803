import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Field, { FieldProps } from "../Field";
import classNames from "classnames";
import { dateFormat, setMask } from "@app/helpers";
import Calendar, { ICalendarDay } from "../Calendar";
import "./styles.scss";
import { IconCalendar24, IconClose24 } from "@app/icons";
import TextField from "../TextField";
import Grid from "../Grid";
import Button from "../Button";
import { format, isValid, parseISO } from "date-fns";

interface Props extends FieldProps {
  value?: Date | null;
  onChange?: (value: Date | null, name: any) => void;
  format?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  hideTime?: boolean;
  editable?: boolean;
  paperTop?: boolean;
  paperRight?: boolean;
}

function DateTimePicker(props: Props) {
  const {
    className = "",
    placeholder: propsPlaceholder,
    format: propsFormat = "dd.MM.yyyy",
    value = null,
    minDate = null,
    maxDate = null,
    hideTime = false,
    onChange,
    paperTop = false,
    editable = false,
    paperRight = false,
    name,
    ...fieldProps
  } = props;
  const [date, setDate] = useState<Date>(value || new Date());
  const [paperVisible, setPaperVisible] = useState<boolean>(false);
  const [time, setTime] = useState<string>(
    !!value ? format(value, "HH:mm") : ""
  );
  const [timeError, setTimeError] = useState<boolean>(false);
  const [inputDate, setInputDate] = useState("");

  const fieldRef = useRef<HTMLDivElement>(null);

  const onFocus = useCallback(() => {
    setPaperVisible((prevState) => !prevState);
  }, []);

  const onClickDate = useCallback(
    (date: ICalendarDay) => {
      if (!onChange || date.disabled) {
        return;
      }

      setDate(date.day);
      setInputDate(dateFormat(date.day, propsFormat));

      if (!hideTime) {
        return;
      }

      onChange(date.day, name as string);
      setPaperVisible(false);
    },
    [hideTime, name, onChange, propsFormat]
  );

  const isDateCorrect = useMemo(
    () => /^\d{2}([./-])\d{2}\1\d{4}$/.test(inputDate),
    [inputDate]
  );

  const onDateValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | null) => {
      const text = e ? e.target.value : "";
      const maskedValue = setMask(text.replace(/\D/g, ""), "##.##.####");

      setInputDate(maskedValue);
      if (!onChange) {
        return;
      }

      const _isDateCorrect = /^\d{2}([./-])\d{2}\1\d{4}$/.test(maskedValue);

      if (_isDateCorrect) {
        const [dd, mm, yy] = maskedValue.split(".");
        const date = new Date(+yy, +mm - 1, +dd);

        setDate(date);

        if (!hideTime) {
          return;
        }

        onChange(date, name as string);
        setPaperVisible(false);
      }
    },
    [hideTime, name, onChange]
  );

  const onChangeTime = useCallback((value: string) => {
    const maskedValue = setMask(value.replace(/\D/g, ""), "##:##");
    const maskedValueParts = maskedValue.split(":");
    const hourParts = maskedValueParts[0].split("");
    const part2 = maskedValueParts[1];

    if (
      Number(hourParts[0]) > 2 ||
      (Number(hourParts[0]) === 2 && Number(hourParts[1]) > 3)
    ) {
      return;
    }

    if (part2) {
      const minParts = part2.split("");

      if (Number(minParts[0]) > 5) {
        return;
      }

      if (!!minParts[1] && Number(maskedValueParts[1]) > 59) {
        return;
      }
    }

    setTime(maskedValue);
    setTimeError(false);
  }, []);

  const onClickSubmit = useCallback(() => {
    if (!onChange) {
      return;
    }

    if (time.length < 5 || !date) {
      setTimeError(true);
      return;
    }

    const returnDate = new Date(date.getTime());
    const timeParts = time.split(":");
    returnDate.setHours(Number(timeParts[0]), Number(timeParts[1]), 0, 0);

    onChange(returnDate, name);
    setPaperVisible(false);
    setTimeError(false);
  }, [onChange, time, date, name]);

  const onClickCancel = useCallback(() => {
    if (!onChange) {
      return;
    }

    onChange(null, name as string);
    setPaperVisible(false);
    setTimeError(false);
  }, [onChange, name]);

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      fieldRef.current &&
      !fieldRef.current.contains(e.target as HTMLElement)
    ) {
      setPaperVisible(false);
    }
  }, []);

  const placeholder = useMemo<string>(() => {
    if (!!propsPlaceholder) {
      return propsPlaceholder;
    }

    if (hideTime) {
      return "Укажите дату";
    }

    return "Укажите дату и время";
  }, [hideTime, propsPlaceholder]);

  // const valueText = useMemo<string>(() => {
  //   if (!!value) {
  //     const returnText = dateFormat(value, "dd.MM.yyyy");
  //
  //     if (!!time && time.length > 4 && !hideTime) {
  //       return `${returnText} ${time}`;
  //     }
  //
  //     return returnText;
  //   }
  //
  //   return placeholder || "";
  // }, [value, placeholder, time, hideTime]);

  useEffect(() => {
    // Проверка, является ли props.value строкой, и преобразование ее в Date
    let newDate =
      typeof props.value === "string" ? parseISO(props.value) : props.value;

    if (newDate && isValid(newDate)) {
      setDate(newDate);
      setTime(format(newDate, "HH:mm"));

      setInputDate(
        dateFormat(newDate, hideTime ? propsFormat : "dd.MM.yyyy HH:mm")
      );
    } else {
      setDate(new Date()); // или установите в null, если дата не должна быть установлена по умолчанию
      setTime("");
      setInputDate("");
    }
  }, [hideTime, props.value, propsFormat]);

  useEffect(() => {
    // Добавить прослушивание событий на клик по DOM
    document.addEventListener("click", onClickOutside, true);

    return () => {
      // Удалить прослушивание событий на клик по DOM
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  const onAppendIconClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onClickCancel();
    },
    [onClickCancel]
  );

  return (
    <Field
      {...fieldProps}
      ref={fieldRef}
      helperText={
        fieldProps.helperText
          ? fieldProps.helperText
          : !isDateCorrect && inputDate && hideTime
          ? "Формат даты должен быть 'дд.мм.гггг'"
          : undefined
      }
      className={`b-dt-picker ${className}`.trim()}
    >
      <div
        className={classNames("b-dt-picker__trigger", {
          "b-dt-picker__trigger--placeholder": !value,
        })}
      >
        <input
          type="text"
          value={inputDate}
          onFocus={onFocus}
          onChange={onDateValueChange}
          placeholder={placeholder}
          disabled={fieldProps.disabled}
          readOnly={editable}
        />
        {!fieldProps.disabled && !!inputDate && (
          <a className="b-dt-picker__append-icon" onClick={onAppendIconClick}>
            <IconClose24 />
          </a>
        )}
        <a className="b-dt-picker__calendar-icon" onClick={onFocus}>
          <IconCalendar24 />
        </a>
      </div>
      <div
        className={classNames("b-dt-picker__paper", {
          "b-dt-picker__paper--visible": paperVisible,
          "b-dt-picker__paper--top": paperTop,
          "b-dt-picker__paper--right": paperRight,
        })}
      >
        <Calendar
          value={date}
          onClick={onClickDate}
          minDate={minDate}
          maxDate={maxDate}
        >
          <Grid>
            {!hideTime && (
              <TextField
                label="Время начала"
                placeholder="00:00"
                value={time}
                error={timeError}
                helperText={timeError ? "Неверный формат времени" : ""}
                timeFormat={true}
                onChange={onChangeTime}
              />
            )}
            <Grid columns={2}>
              <Button
                text="Отмена"
                variant="outlined"
                onClick={onClickCancel}
              />
              <Button text="Применить" onClick={onClickSubmit} />
            </Grid>
          </Grid>
        </Calendar>
      </div>
    </Field>
  );
}

export default memo(DateTimePicker);
