import React, { useCallback, useMemo } from "react";
import { LayoutDefault } from "@app/layouts";
import { TemplateSearch } from "@app/templates";
import { VehicleListItem } from "@app/models";
import { getVehicles } from "@app/api";
import { getDictionaryName, joinValues } from "@app/helpers";
import { ModalDictionaryVehicleDetail } from "@app/modals";
import { BaseButton } from "@app/components";
import { IconChevronLeft16 } from "@app/icons";
import { useNavigate } from "react-router-dom";

const PageDictionaryVehicles = () => {
  const navigate = useNavigate();
  const mapTableData = useCallback((item: VehicleListItem) => {
    const companies = item.companies.map((item) => ({
      ...item,
      name: item.name ?? "—",
    }));

    return [
      item.vehicleType,
      item.characteristics,
      joinValues(companies, ", "),
      item.govNumber,
      getDictionaryName(item.vehicleModel),
      item.gps,
    ];
  }, []);

  const tableLabels = useMemo(
    () => [
      "Вид техники",
      "Характеристика",
      "Организация",
      "Гос номер",
      "Модель",
      "Провайдер GPS",
    ],
    []
  );

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const backButton = useMemo(
    () => [
      <BaseButton onClick={onBackClick}>
        <IconChevronLeft16 />
        Назад
      </BaseButton>,
    ],
    [onBackClick]
  );

  return (
    <LayoutDefault title={"Справочники"}>
      <TemplateSearch<VehicleListItem>
        toolbarProps={{
          searchPlaceholder: "Поиск...",
          leftControls: backButton,
        }}
        getData={getVehicles}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        modalDetail={ModalDictionaryVehicleDetail}
        onClick={() => {}}
      />
    </LayoutDefault>
  );
};

export default PageDictionaryVehicles;
