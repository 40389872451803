import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IconMore24, SVGIconType } from "@app/icons";
import { Paper } from "@app/components";

export interface IMenuItem {
  label: string;
  color?: string;
  icon: SVGIconType;
  onClick: () => void;
}

interface Props {
  items: IMenuItem[];
}

const MoreMenuWrapper = styled.div`
  position: relative;
`;

const MoreButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 6px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #eee;
  }
`;

const Menu = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  z-index: 1;

  > div {
    padding: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
  }
`;

const MenuItemWrapper = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  outline: none;
  height: 48px;
  border: none;
  background-color: ${(props) => props.theme.color.white};
  padding: 0 16px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  svg {
    flex-grow: 0;
    flex-shrink: 0;
  }

  p {
    font-family: ${(props) => props.theme.fontFamily};
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 0 0 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.color.dark};
    text-align: left;
  }
`;

function MenuItem(
  props: {
    hideMenu: () => void;
  } & IMenuItem
) {
  const { icon: Icon, label, onClick, hideMenu } = props;

  const onClickButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClick();
      hideMenu();
    },
    [hideMenu, onClick]
  );

  return (
    <MenuItemWrapper onClick={onClickButton}>
      <Icon />
      <p>{label}</p>
    </MenuItemWrapper>
  );
}

function MoreMenu(props: Props) {
  const { items } = props;
  const [paperVisible, setPaperVisible] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onClickMore = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setPaperVisible((prevState) => !prevState);
    },
    []
  );

  const hideMoreMenu = useCallback(() => {
    setPaperVisible(false);
  }, []);

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target as HTMLElement)
    ) {
      setPaperVisible(false);
    }
  }, []);

  useEffect(() => {
    // Добавить прослушивание событий на клик по DOM
    document.addEventListener("click", onClickOutside, true);

    return () => {
      // Удалить прослушивание событий на клик по DOM
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <MoreMenuWrapper ref={wrapperRef}>
      <MoreButton onClick={onClickMore}>
        <IconMore24 />
      </MoreButton>
      {paperVisible && (
        <Menu>
          <Paper shadow={true}>
            {items.map((item, itemIndex) => (
              <MenuItem
                key={itemIndex.toString()}
                hideMenu={hideMoreMenu}
                {...item}
              />
            ))}
          </Paper>
        </Menu>
      )}
    </MoreMenuWrapper>
  );
}

export default memo(MoreMenu);
