import React, { memo, useCallback, useState } from "react";
import Field, { FieldProps } from "../Field";
import classNames from "classnames";
import { dateFormat, setMask } from "@app/helpers";
import "./styles.scss";
import TextField from "../TextField";
import { format } from "date-fns";

interface Props extends FieldProps {
  value?: string | null;
  onChange?: (value: string | null, name: any) => void;
}

function TimePicker(props: Props) {
  const {
    className = "",
    placeholder: propsPlaceholder,
    value = null,
    onChange,
    name,
    ...fieldProps
  } = props;
  // const [date, setDate] = useState<Date>(value || new Date());
  // const [time, setTime] = useState<string | null>(value);

  const onChangeTime = useCallback(
    (value: string) => {
      if (!onChange) {
        return;
      }
      const maskedValue = setMask(value.replace(/\D/g, ""), "##:##");
      const maskedValueParts = maskedValue.split(":");
      const hourParts = maskedValueParts[0].split("");
      const part2 = maskedValueParts[1];

      if (
        Number(hourParts[0]) > 2 ||
        (Number(hourParts[0]) === 2 && Number(hourParts[1]) > 3)
      ) {
        return;
      }

      if (part2) {
        const minParts = part2.split("");

        if (Number(minParts[0]) > 5) {
          return;
        }

        if (!!minParts[1] && Number(maskedValueParts[1]) > 59) {
          return;
        }
      }

      // setTime(maskedValue);

      onChange(maskedValue, name);
    },
    [name, onChange]
  );

  return (
    <TextField
      {...fieldProps}
      value={value || ""}
      placeholder="00:00"
      timeFormat={true}
      className={`b-dt-picker ${className}`.trim()}
      onChange={onChangeTime}
    />
  );
}

export default memo(TimePicker);
