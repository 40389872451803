import { SelectOption } from "@app/components";
import * as yup from "yup";

export const vehicleTypes: SelectOption<any>[] = [
  {
    label: "Колесная техника",
    value: "false",
    item: null,
  },
  {
    label: "ГПМ",
    value: "true",
    item: null,
  },
];

export const aosCreateSteps = [
  "Заполните реквизиты",
  "Проверьте данные работы техники",
  "Укажите согласующих лиц",
];

export const initFilterValues = {
  organization: null,
  project: null,
  partner: null,
  vehicleType: null,
  startDate: null,
  endDate: null,
  contract: null,
  nds: null,
  currency: null,
};

export const schema = yup.object().shape({
  organization: yup.object().nullable().required("required"),
  project: yup.object().nullable().required(),
  partner: yup.object().nullable().required(),
  contract: yup.object().nullable().required(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
});
