import { ActVehicleItemV2, VehicleJournalForActV2 } from "@app/models";

export function getActOfWorkNomenclatureName(
  item: VehicleJournalForActV2
): string {
  let name = `Услуги техники ${item.vehicleTypeName}`;

  const stringArr = [
    item.govNumber ? `гос.номер: ${item.govNumber}` : null,
    item.characteristic?.name,
    item.serviceType?.name,
  ].filter((item) => !!item && item.length > 3);

  // Если govNumber равен null, мы игнорируем другие атрибуты и возвращаем только vehicleTypeName
  if (item.govNumber === null) {
    return item.vehicleTypeName || "";
  }

  if (stringArr.length) {
    name += ` (${stringArr.join(" - ")})`;
  }

  return name.trim();
}

export function getActOfWorkNomenclatureQuantity(
  item: VehicleJournalForActV2
): number | null {
  if (item.unitDto.id === "26cd4f51-ca03-11e7-80c3-001dd8b71c7e") {
    return 1;
  }

  return item.totalToPay;
}

export function getActOfWorkNomenclaturePrice(
  item: VehicleJournalForActV2
): number | null {
  if (item.unitDto.id !== "26cd4f51-ca03-11e7-80c3-001dd8b71c7e") {
    return item.totalPrice;
  }

  return item.totalSumma;
}

export function getActOfWorkPayAmount(item: ActVehicleItemV2): number {
  if (item.unitDto.id === "26cd4f51-ca03-11e7-80c3-001dd8b71c7e") {
    return 1;
  }

  return item.toPay;
}

export function getActOfWorkPrice(item: ActVehicleItemV2): number {
  if (item.unitDto.id === "26cd4f51-ca03-11e7-80c3-001dd8b71c7e") {
    return item.summa;
  }

  return item.price;
}
