import React from "react";
import { dateFormat, wordDeclension } from "@app/helpers";
import styled from "styled-components";

type DataProgressBarProps = {
  today: Date;
  expirationDate: Date;
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-self: center;
`;

const StyledProgressBarText = styled.p`
  color: #636463;
  font-size: 14px;
  font-weight: 400;
`;

const StyledTodayText = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: #636463;
  font-size: 12px;
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 21px;
`;

const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: visible;
  height: 8px;
  margin-bottom: 12px;
`;

const DataProgressBar: React.FC<DataProgressBarProps> = ({
  today,
  expirationDate,
}) => {
  const totalDays = Math.ceil(
    (expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );

  const startDate = new Date(expirationDate.getTime());
  startDate.setDate(startDate.getDate() - 365);
  const elapsedDays = Math.ceil(
    (today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  const progressPercentage = (elapsedDays / 365) * 100;
  const thumbPosition = Math.min(progressPercentage, 100);

  const remainingDays = totalDays;
  const remainingMonths = Math.floor(remainingDays / 30);

  const daysOrMonthsLeft =
    remainingMonths > 0
      ? `${remainingMonths} ${wordDeclension(remainingMonths, [
          "месяц",
          "месяца",
          "месяцев",
        ])}`
      : `${remainingDays} ${wordDeclension(remainingDays, [
          "день",
          "дня",
          "дней",
        ])}`;

  const formattedExpirationDate = dateFormat(expirationDate, "dd.MM.yyyy");

  return (
    <div>
      <StyledContainer>
        <div></div>
        <StyledHeader>
          <StyledProgressBarText>
            {remainingDays > 0 ? `${daysOrMonthsLeft} до истечения ` : "Истек"}
          </StyledProgressBarText>
        </StyledHeader>
        <div>
          <StyledProgressBarText>
            {formattedExpirationDate === "Не верный формат даты"
              ? ""
              : formattedExpirationDate}
          </StyledProgressBarText>
        </div>
      </StyledContainer>
      <StyledProgressBar>
        <div
          style={{
            height: "8px",
            width: `${thumbPosition}%`,
            background:
              progressPercentage >= 100
                ? "#DA616C"
                : "linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%)",
            transition: "width 0.5s ease-in-out",
            borderRadius: "4px",
          }}
        />
        {progressPercentage < 100 && (
          <>
            <div
              style={{
                position: "absolute",
                top: "-1px",
                left: `${thumbPosition}%`,
                transform: "translateX(-50%)",
                height: "8px",
                width: "8px",
                background:
                  "linear-gradient(86.19deg, #ED4A3F 19.16%, #F99335 78.75%)",
                borderRadius: "50%",
                border: "2px solid #F99335",
                transition: "left 0.5s ease-in-out",
              }}
            />
            <StyledTodayText style={{ left: `calc(${thumbPosition}% - 1px)` }}>
              Сегодня
            </StyledTodayText>
          </>
        )}
      </StyledProgressBar>
    </div>
  );
};

export default DataProgressBar;
