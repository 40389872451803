import instance from "./instance";

export function getFileById(id: string, isAttachment = false): Promise<Blob> {
  const params = { isAttachment };
  return instance()
    .get(`/file/${id}`, {
      params,
      responseType: isAttachment ? "json" : "blob",
    })
    .then((response) => response.data);
}
