import React from "react";
import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
  label: string;
  value: string;
}

const StyledInfo = styled.div``;

const StyledLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.grayDark")};
  margin: 0 0 4px;
`;

const StyledValue = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
  margin: 0;
`;

export default function (props: Props) {
  const { label, value } = props;

  return (
    <StyledInfo>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue>{value}</StyledValue>
    </StyledInfo>
  );
}
