import React, {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import {
  Notification as NotificationComponent,
  NotificationProps,
} from "@app/components";
import { generateUniqueId } from "@app/helpers";
import "./styles.scss";

interface Props extends PropsWithChildren {}

interface NotificationPickedProps
  extends Pick<NotificationProps, "variant" | "message"> {}

export interface NotificationItem extends NotificationPickedProps {
  id: string;
}

interface NotificationContextValue {
  showNotification: (notification: NotificationPickedProps) => void;
}

const NotificationContext = createContext<NotificationContextValue>(
  {} as NotificationContextValue
);

const notificationsRoot = document.getElementById("notifications-root");

function Notification(
  props: NotificationItem & {
    hideNotification: (id: string) => void;
  }
) {
  const { id, variant, message, hideNotification } = props;
  const [focused, setFocused] = useState<boolean>(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const onMouseEnter = useCallback(() => {
    setFocused(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setFocused(false);
  }, []);

  useEffect(() => {
    if (focused) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    } else {
      timeout.current = setTimeout(() => {
        hideNotification(id);
      }, 3000);
    }
  }, [focused, hideNotification, id]);

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <NotificationComponent variant={variant} message={message} />
    </div>
  );
}

function NotificationProvider(props: Props) {
  const { children } = props;
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const el = useMemo<HTMLDivElement>(() => {
    const divElement = document.createElement("div");

    divElement.classList.add("b-notifications");

    return divElement;
  }, []);

  const showNotification = useCallback(
    (notification: NotificationPickedProps) => {
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        {
          ...notification,
          id: generateUniqueId(),
        },
      ]);
    },
    []
  );

  const hideNotification = useCallback((id: string) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((prevNotification) => prevNotification.id !== id)
    );
  }, []);

  const renderNotifications = useCallback(() => {
    return notifications.map((notification) => (
      <Notification
        key={notification.id}
        {...notification}
        hideNotification={hideNotification}
      />
    ));
  }, [hideNotification, notifications]);

  useEffect(() => {
    notificationsRoot?.appendChild(el);

    return () => {
      notificationsRoot?.removeChild(el);
    };
  }, [el]);

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
      }}
    >
      {children}
      {createPortal(renderNotifications(), el)}
    </NotificationContext.Provider>
  );
}

export function useNotification(): NotificationContextValue {
  return useContext(NotificationContext);
}

export default memo(NotificationProvider);
