import React, { memo, useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useNotification, useUser } from "@app/providers";
import { getContractReference } from "@app/api";

function PageContract() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticated } = useUser();
  const { showNotification } = useNotification();
  const [message, setMessage] = useState("Скачивание файла");

  const getReference = useCallback(
    (id: string) => {
      getContractReference(id)
        .then((res) => {
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = `Квитанция_о_документе`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          setMessage("Квитанция скачана. Проверьте папку Загрузки");
        })
        .catch(async (error) => {
          let errorString = error.response.data;
          if (
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") !== -1
          ) {
            errorString = JSON.parse(await error.response.data.text());
          }

          const errorText =
            "Ошибка! Попробуйте позднее или обратитесь к администратору";
          setMessage(errorString.Message || errorText);

          showNotification({
            message: errorString.Message || errorText,
            variant: "error",
          });
        });
    },
    [showNotification]
  );

  useEffect(() => {
    if (params.fileId) {
      if (authenticated) {
        getReference(params.fileId);
      } else {
        const uri = encodeURI(location.pathname);
        navigate(`/sign-in?returnUrl=${uri}`);
      }
    } else {
      navigate("/");
    }
  }, [authenticated, getReference, location, navigate, params]);

  return (
    <div>
      <p>{message}</p>
      <Link to="/vehicles">На главную</Link>
    </div>
  );
}

export default memo(PageContract);
