import { memo, PropsWithChildren, useState } from "react";
import "./styles.scss";
import classNames from "classnames";

interface Props extends PropsWithChildren {
  content: string;
  delay?: number;
  direction?: "top" | "right" | "left";
}

const Tooltip = ({ delay = 300, direction, content, children }: Props) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className={`tooltip-tip ${direction || "top"}`}>{content}</div>
      )}
    </div>
  );
};

export default memo(Tooltip);
