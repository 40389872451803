import React, {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useUser } from "../User";
import ringSrc from "./ring.mp3";
import { addDevice, deleteDevice } from "@app/api";
import { useEventEmitter } from "../EventEmitter";
import Cookies from "js-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyCOFH2GTwP8N8RxiKlsCH-3BEq3NMqw6P4",
  authDomain: "dispatchcds-859d7.firebaseapp.com",
  projectId: "dispatchcds-859d7",
  storageBucket: "dispatchcds-859d7.appspot.com",
  messagingSenderId: "789358008383",
  appId: "1:789358008383:web:d6d02902fedd5a9dbec08a",
  measurementId: "G-RVM5YPT2NK",
};

export enum NotificationTypes {
  /// Заявки от 1 до 10
  RequestCreated = 1,
  RequestUpdated = 2,
  RequestDeleted = 3,

  /// Предложения от 11 до 20
  OfferCreated = 11,
  OfferUpdated = 12,
  OfferDeleted = 13,

  /// Акты от 21 до 30
  ActCreated = 21,
  ActUpdated = 22,
  ActDeleted = 23,

  /// Задачи от 31 до 40
  TaskCreated = 31,
  TaskUpdated = 32,
  TaskDeleted = 33,

  /// Журналы от 41 до 50
  VehicleZhurnalCreated = 41,
  VehicleZhurnalUpdated = 42,
  VehicleZhurnalDeleted = 43,

  /// Договора от 51 до 60
  ContractCreated = 51,
  ContractUpdated = 52,
  ContractDeleted = 53,
}

export const NotificationTypeNames: Record<NotificationTypes, string> = {
  [NotificationTypes.RequestCreated]: "RequestCreated",
  [NotificationTypes.RequestUpdated]: "RequestUpdated",
  [NotificationTypes.RequestDeleted]: "RequestDeleted",
  [NotificationTypes.OfferCreated]: "OfferCreated",
  [NotificationTypes.OfferUpdated]: "OfferUpdated",
  [NotificationTypes.OfferDeleted]: "OfferDeleted",
  [NotificationTypes.ActCreated]: "ActCreated",
  [NotificationTypes.ActUpdated]: "ActUpdated",
  [NotificationTypes.ActDeleted]: "ActDeleted",
  [NotificationTypes.TaskCreated]: "TaskCreated",
  [NotificationTypes.TaskUpdated]: "TaskUpdated",
  [NotificationTypes.TaskDeleted]: "TaskDeleted",
  [NotificationTypes.VehicleZhurnalCreated]: "VehicleZhurnalCreated",
  [NotificationTypes.VehicleZhurnalUpdated]: "VehicleZhurnalUpdated",
  [NotificationTypes.VehicleZhurnalDeleted]: "VehicleZhurnalDeleted",
  [NotificationTypes.ContractCreated]: "ContractCreated",
  [NotificationTypes.ContractUpdated]: "ContractUpdated",
  [NotificationTypes.ContractDeleted]: "ContractDeleted",
};

interface FirebaseNotification {
  data: {
    id: string;
    type: NotificationTypes;
  };
  notification: {
    body: string;
    title: string;
  };
  from: string;
  messageId: string;
}

interface Props extends PropsWithChildren {}

interface FirebaseContextValue {}

const FirebaseContext = createContext<FirebaseContextValue>(
  {} as FirebaseContextValue
);

initializeApp(firebaseConfig);

const messaging = getMessaging();

onMessage(messaging, (payload: any) => {
  console.log(payload);
});

function FirebaseProvider(props: Props) {
  const { children } = props;
  const { emitEvent } = useEventEmitter();
  const { authenticated } = useUser();

  const checkToken = useCallback(async () => {
    try {
      const firebaseToken = await Cookies.get("firebaseToken");

      if (Notification.permission === "denied") {
        const permission = await Notification.requestPermission();

        if (permission !== "granted") {
          if (firebaseToken) {
            await Cookies.remove("firebaseToken");

            await deleteDevice(firebaseToken);
          }

          return;
        }
      }

      const token = await getToken(messaging, {
        vapidKey:
          "BLaXDpgiYSfYIHCYYX4d-TzQWB80DJsH5z0NCVDBciwWB8DuULwLjsJSIwx_B6Ez3mQ5FpXrhNRT7a5dgjUkn4A",
      });

      if (firebaseToken && firebaseToken !== token) {
        await deleteDevice(firebaseToken);
      }

      if (!firebaseToken) {
        await Cookies.set("firebaseToken", token);
      }

      await addDevice(token);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onMessageReceive = useCallback(
    (payload: any) => {
      const firebaseNotification = payload as FirebaseNotification;
      const {
        notification: { title, body },
        data,
      } = firebaseNotification;

      const notification = new Notification(title, {
        body: body,
        icon: "/path/to/icon.png",
        silent: false,
      });

      const audio = new Audio(ringSrc);
      audio.play();

      if (!data) {
        return;
      }

      if (!data.type) {
        return;
      }

      if (Number(data.type) === NotificationTypes.RequestCreated) {
        notification.onclick = () => {
          window.open(
            `${window.location.origin}/vehicles-in-project/requests`,
            "_blank"
          );
        };

        emitEvent(
          NotificationTypeNames[NotificationTypes.RequestCreated],
          null
        );
      }

      if (Number(data.type) === NotificationTypes.ContractCreated) {
        notification.onclick = () => {
          window.open(`${window.location.origin}/smart-contracts`, "_blank");
        };

        emitEvent(
          NotificationTypeNames[NotificationTypes.ContractCreated],
          null
        );
      }

      if (Number(data.type) === NotificationTypes.ActCreated) {
        notification.onclick = () => {
          window.open(
            `${window.location.origin}/act-of-provided-services`,
            "_blank"
          );
        };

        emitEvent(NotificationTypeNames[NotificationTypes.ActCreated], null);
      }

      if (Number(data.type) === NotificationTypes.VehicleZhurnalCreated) {
        notification.onclick = () => {
          window.open(
            `${window.location.origin}/vehicle-accounting/list/by-dates`,
            "_blank"
          );
        };

        emitEvent(
          NotificationTypeNames[NotificationTypes.VehicleZhurnalCreated],
          null
        );
      }
    },
    [emitEvent]
  );

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    checkToken();

    const onMessageListener = onMessage(messaging, onMessageReceive);

    return () => {
      onMessageListener();
    };
  }, [authenticated, checkToken, onMessageReceive]);

  return (
    <FirebaseContext.Provider value={{}}>{children}</FirebaseContext.Provider>
  );
}

export function useFirebase(): FirebaseContextValue {
  return useContext(FirebaseContext);
}

export default memo(FirebaseProvider);
