import React, { memo, ReactNode, useCallback } from "react";
import { ToolbarSearch } from "./components";
import { IconClose24, IconFilter24 } from "@app/icons";
import Button from "../Button";
import styled from "styled-components";
import { theme } from "styled-tools";
import "./styles.scss";
import classNames from "classnames";

export interface Props {
  initialSearchText?: string;
  searchPlaceholder?: string;
  onFilter?: () => void;
  onFilterClear?: () => void;
  onSearch?: (searchText: string) => void;
  rightControls?: ReactNode[];
  leftControls?: ReactNode[];
  bottomControls?: ReactNode[];
  title?: string;
  hasFilters?: boolean;
}

const StyledTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px 0 0;
  font-family: ${theme("fontFamily")};
  color: ${theme("color.black")};
`;

function Toolbar(props: Props) {
  const {
    title,
    onSearch,
    onFilter,
    onFilterClear,
    leftControls,
    bottomControls,
    rightControls,
    initialSearchText,
    searchPlaceholder,
    hasFilters,
  } = props;

  const onClickFilter = useCallback(() => {
    if (!!onFilter) {
      onFilter();
    }
  }, [onFilter]);

  const onClickFilterClear = useCallback(() => {
    if (!!onFilterClear) {
      onFilterClear();
    }
  }, [onFilterClear]);

  return (
    <div className="b-toolbar">
      {!!title && <StyledTitle>{title}</StyledTitle>}
      <div style={{ display: "flex", flexDirection: "row" }}>
        {!!leftControls && (
          <div className="b-toolbar__controls">
            {leftControls.map((control, controlIndex) => (
              <div className="b-toolbar__control" key={controlIndex.toString()}>
                {control}
              </div>
            ))}
          </div>
        )}
        {(!!rightControls || !!onSearch) && (
          <div className="b-toolbar__controls b-toolbar__controls--right">
            {!!onSearch && (
              <div className="b-toolbar__control">
                <ToolbarSearch
                  initialText={initialSearchText}
                  placeholder={searchPlaceholder}
                  onSearch={onSearch}
                />
              </div>
            )}
            {!!rightControls &&
              rightControls.map((control, controlIndex) => (
                <div
                  className="b-toolbar__control"
                  key={controlIndex.toString()}
                >
                  {control}
                </div>
              ))}
            {!!onFilter && (
              <div className="b-toolbar__control">
                <Button
                  variant="outlined"
                  className={classNames("b-toolbar__filter", {
                    "b-toolbar__filter--active": hasFilters,
                  })}
                  endIcon={IconFilter24}
                  onClick={onClickFilter}
                />
                {hasFilters && (
                  <Button
                    variant="outlined"
                    className={"b-toolbar__filter--clear"}
                    startIcon={IconClose24}
                    text={"Очистить фильтры"}
                    onClick={onClickFilterClear}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {!!bottomControls && (
        <div className="b-toolbar__controls b-toolbar__controls--bottom">
          {bottomControls.map((control, controlIndex) => (
            <div className="b-toolbar__control" key={controlIndex.toString()}>
              {control}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(Toolbar);
