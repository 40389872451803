import React, { PropsWithChildren } from "react";

import * as Styled from "./styled";
import { useElementOnScreen } from "@app/hooks";

interface Props extends PropsWithChildren {
  clickable: boolean;
  lazy?: boolean;
  onClick?: () => void;
}

export const TableRow = (props: Props) => {
  const { children, clickable, lazy, onClick } = props;

  const [containerRef, appeared] = useElementOnScreen<HTMLTableRowElement>({
    rootMargin: "0px",
    threshold: 0,
  });

  /* Пустая строка таблицы для вызова useElementOnScreen */
  if (lazy && !appeared) {
    return (
      <Styled.Row clickable={true} ref={containerRef}>
        <td height={60} />
      </Styled.Row>
    );
  }

  return (
    <Styled.Row clickable={clickable} onClick={onClick}>
      {children}
    </Styled.Row>
  );
};
