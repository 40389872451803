import * as yup from "yup";
import { WorkShiftTime } from "@app/models";
import {
  KM_UNIT_ID,
  M2_UNIT_ID,
  M3_UNIT_ID,
  M_UNIT_ID,
  MONTH_UNIT_ID,
  PIECE_UNIT_ID,
  POG_M_UNIT_ID,
  RACE_UNIT_ID,
  SHIFT_UNIT_ID,
  TN_KM_UNIT_ID,
  TN_UNIT_ID,
} from "@app/helpers";
import { addDays, format, isAfter, isBefore } from "date-fns";

export const getMinutes = (time: string) => {
  let total = 0;

  const parts = time.split(":");

  if (parts.length > 1) {
    total = +parts[0] * 60 + +parts[1];
  }

  return total;
};

const getDateOnly = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const journalSchema = yup.object().shape({
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  startTime: yup
    .string()
    .matches(
      /^(?:2[0-3]|[0-1]?[0-9])?:?[0-5]?[0-9]$/,
      "Укажите корректное время"
    )
    .when(["workShift"], (shift, schema) => {
      return schema.test(
        "startTime",
        "Укажите время начала соответствующее смене",
        (val: string) => {
          if (val) {
            const startTimeInMinutes = getMinutes(val);
            const shiftStartInMinutes = getMinutes(shift.startTime);
            const shiftEndInMinutes = getMinutes(shift.endTime);

            return (
              startTimeInMinutes >= shiftStartInMinutes &&
              startTimeInMinutes * -1 < shiftEndInMinutes
            );
          }
          return false;
        }
      );
    })
    .nullable()
    .required(),
  endTime: yup
    .string()
    .matches(
      /^(?:2[0-3]|[0-1]?[0-9])?:?[0-5]?[0-9]$/,
      "Укажите корректное время"
    )
    .when(["workShift"], (shift: WorkShiftTime, schema) => {
      return schema.test(
        "endTime",
        "Укажите время завершения соответствующее смене",
        (val: string, ctx: any) => {
          if (val) {
            // дата (без времени) указанная в поле дата
            const startDate = getDateOnly(ctx.parent.startDate);
            const endDate = getDateOnly(ctx.parent.endDate);
            // заполненные дата и время
            const start = new Date(`${startDate} ${ctx.parent.startTime}`);
            const end = new Date(`${endDate} ${val}`);
            // даты и время смены
            const shiftStart = new Date(`${startDate} ${shift.startTime}`);
            let shiftEnd = new Date(`${endDate} ${shift.endTime}`);
            // если конец смены раньше, то вероятно это ночная смена
            if (isBefore(shiftEnd, shiftStart)) {
              shiftEnd = addDays(shiftEnd, 1);
            }

            const isStartDateIncorrect =
              isAfter(start, shiftEnd) || isBefore(start, shiftStart);
            const isEndDateIncorrect =
              isAfter(end, shiftEnd) || isBefore(end, shiftStart);

            return !isStartDateIncorrect && !isEndDateIncorrect;
          }
          return false;
        }
      );
    })
    .when(["startTime"], (startTimeVal: string, schema) => {
      return schema.test(
        "endTime",
        "Дата завершения не может быть раньше начала",
        (val: string, context: any) => {
          if (val) {
            const startTimeInSec = getMinutes(startTimeVal) * 60;
            const endTimeInSec = getMinutes(val) * 60;
            if (context.parent.startDate) {
              const startDateSec = new Date(context.parent.startDate).getTime();
              const endDateSec = new Date(context.parent.endDate).getTime();

              return startTimeInSec + startDateSec < endTimeInSec + endDateSec;
            }
          }
          return true;
        }
      );
    })
    .nullable()
    .required(),
  cargoWeight: yup
    .string()
    .nullable()
    .when("unitShort.id", {
      is: TN_KM_UNIT_ID,
      then: yup.string().required("Укажите корректное количество"),
    }),
  distance: yup
    .string()
    .nullable()
    .when("unitShort.id", {
      is: TN_KM_UNIT_ID,
      then: yup.string().required("Укажите корректное количество"),
    }),
  workPerformed: yup
    .string()
    .nullable()
    .when(["unitShort"], (unitShort, schema) => {
      switch (unitShort.id) {
        case MONTH_UNIT_ID:
          return schema.test(
            "workPerformed",
            "Укажите корректное количество",
            () => true
          );
        case RACE_UNIT_ID:
          return schema.test(
            "workPerformed",
            "Укажите корректное количество",
            (val: string) => +val > 0
          );
        case SHIFT_UNIT_ID:
        case POG_M_UNIT_ID:
        case PIECE_UNIT_ID:
        case TN_UNIT_ID:
        case KM_UNIT_ID:
        case M3_UNIT_ID:
        case M2_UNIT_ID:
        case M_UNIT_ID:
          return schema.test(
            "workPerformed",
            "Укажите корректное количество",
            (val: string) =>
              +val > 0 && typeof +val === "number" && isFinite(+val)
          );
        default:
          return schema.required().min(5, "Укажите корректное время");
      }
    }),
});
