import React, { useCallback, useMemo, useState } from "react";
import { LayoutDefault } from "@app/layouts";
import { TemplateSearch, TemplateSearchModalDetail } from "@app/templates";
import { CompanyListItem, CompanyStatus, CompanyStatusName } from "@app/models";
import { getCompanyList } from "@app/api";
import { enumToArray, getDictionaryName, getFilterValues } from "@app/helpers";
import { CompanyStatusChip } from "@app/common";
import { BaseButton, Combobox, DefaultObject } from "@app/components";
import { IconChevronLeft16 } from "@app/icons";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "@app/hooks";
import { ModalOrganizationDetail } from "@app/modals";

const FILTER_STORAGE_KEY = "contractDocumentsFilters";

interface FilterParamsProps {
  statuses: DefaultObject<CompanyStatus>[] | null;
}

interface FilterValuesProps {
  Statuses: (string | number)[];
}

const initialFiltersParams = JSON.stringify({
  statuses: null,
});

function DetailModal(props: TemplateSearchModalDetail<CompanyListItem>) {
  return <ModalOrganizationDetail size={"medium"} {...props} />;
}

const PageDictionaryCompanies = () => {
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : JSON.parse(initialFiltersParams);
  });

  const mapTableData = useCallback((item: CompanyListItem) => {
    return [
      item.name,
      getDictionaryName(item.type),
      "—",
      item.bin,
      "—",
      <CompanyStatusChip status={item.status} comment={item.comment ?? ""} />,
    ];
  }, []);

  const tableLabels = useMemo(
    () => [
      "Организация",
      "Формма организации",
      "Лицо",
      "ИИН/БИН",
      "Адрес",
      "Статус",
    ],
    []
  );

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        Statuses: getFilterValues(filterParams.statuses),
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox<DefaultObject<CompanyStatus>>
        label="Статус"
        name="statuses"
        values={filterParams.statuses}
        options={enumToArray(CompanyStatusName)}
        onChange={onFilterChange}
      />,
    ],
    [filterParams.statuses, onFilterChange]
  );

  const onBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const backButton = useMemo(
    () => [
      <BaseButton onClick={onBackClick}>
        <IconChevronLeft16 />
        Назад
      </BaseButton>,
    ],
    [onBackClick]
  );

  return (
    <LayoutDefault title={"Справочники"}>
      <TemplateSearch<CompanyListItem>
        toolbarProps={{
          searchPlaceholder: "Поиск...",
          leftControls: backButton,
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        getData={getCompanyList}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        modalDetail={DetailModal}
        onClick={() => {}}
      />
    </LayoutDefault>
  );
};

export default PageDictionaryCompanies;
