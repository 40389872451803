import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { LayoutDefault } from "@app/layouts";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Button, Table } from "@app/components";
import {
  ActStatusName,
  Reports,
  ReportsGroup,
  ReportsTotal,
} from "@app/models";
import { currencyFormat } from "@app/helpers";
import { IconChevronDown24, IconChevronUp24 } from "@app/icons";
import { Filters, FilterSubmitData } from "./components";

const StyledContainer = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`;
const StyledContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 16px;
`;

const PlaceholderText = styled.p`
  color: ${theme("color.secondary")};
  text-align: center;
  padding: 32px;
`;

const StyledParentCell = styled.td`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 12px;
  width: 1%;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  border-top: 1px solid ${theme("color.grayLight")};
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  background-color: ${theme("color.blueLight")};
  position: sticky;
  top: 60px;
`;

type Props = {
  title: string;
  isGroup?: boolean;
};

const ParentCell = ({ text }: { text?: string | number }) => {
  return <StyledParentCell title={`${text || ""}`}>{text}</StyledParentCell>;
};

const PageReportDetail = (props: Props) => {
  const { title, isGroup = false } = props;
  const [reportData, setReportData] = useState<Reports[] | null>(null);
  const [reportDataGroup, setReportDataGroup] = useState<ReportsGroup[] | null>(
    null
  );
  const [totalRowValues, setTotalRowValue] = useState<ReportsTotal>();
  const [collapsedRows, setCollapsedRows] = useState<number[]>([0]);

  const mapTableData = useCallback((item: Reports, index: number) => {
    return [
      `${index + 1}`,
      item.actNumber,
      item.company,
      item.project,
      item.partner,
      item.vehicleType,
      item.characteristic,
      item.serviceType,
      item.govNumber,
      item.shiftType,
      item.workDate,
      item.workStartDateTimeToTime,
      item.workEndDateTimeToTime,
      item.unit,
      item.workPerformed,
      item.hoursGpsToTimeSpan,
      item.shiftDurationToTimeSpan,
      currencyFormat(item.toPay),
      currencyFormat(item.price),
      item.nds,
      currencyFormat(item.amountToPay),
      item.status ? ActStatusName[item.status] : "—",
      currencyFormat(item.sumFactWithOutGps),
      item.equipmentGps,
      item.comment,
    ];
  }, []);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => (reportData || []).map(mapTableData),
    [reportData, mapTableData]
  );

  const mapTableCollapsedData = useCallback(
    (item: ReportsGroup, index: number) => {
      if (!item) {
        return [];
      }

      return [
        <StyledParentCell>
          {collapsedRows.includes(index) ? (
            <IconChevronDown24 />
          ) : (
            <IconChevronUp24 />
          )}
        </StyledParentCell>,
        <ParentCell />,
        <ParentCell text={item.companyName} />,
        <ParentCell text={item.projectName} />,
        <ParentCell text={item.partnerName} />,
        <ParentCell text={item.vehicleTypeName} />,
        <ParentCell text={item.characteristicName} />,
        <ParentCell text={item.serviceName} />,
        <ParentCell text={item.govNumber} />,
        //
        <ParentCell text={item.shiftType} />,
        <ParentCell text={item.workDate} />,
        <ParentCell text={item.workStartDateTimeToTime} />,
        <ParentCell text={item.workEndDateTimeToTime} />,
        <ParentCell text={item.unit} />,
        <ParentCell text={item.workPerformed} />,
        <ParentCell text={item.totalHoursGps} />,
        <ParentCell text={item.totalShiftDuration} />,
        <ParentCell text={currencyFormat(item.totalToPay)} />,
        <ParentCell text={currencyFormat(item.totalPrice)} />,
        <ParentCell text={item.nds} />,
        <ParentCell text={currencyFormat(item.totalAmountToPay)} />,
        <ParentCell text={item.status ? ActStatusName[item.status] : ""} />,
        <ParentCell text={currencyFormat(item.totalSumFactWithOutGps)} />,
        <ParentCell text={item.totalEquipmentGps} />,
        <ParentCell />,
      ];
    },
    [collapsedRows]
  );

  const tableDataCollapsed = useMemo<(string | ReactNode)[][]>(
    () => (reportDataGroup || []).map(mapTableCollapsedData),
    [reportDataGroup, mapTableCollapsedData]
  );

  const reduceTableData = useCallback(
    (item: ReportsGroup) => {
      return item.reportActDtos.map(mapTableData);
    },
    [mapTableData]
  );

  const tableDataChildren = useMemo<(string | ReactNode)[][][]>(
    () => (reportDataGroup || []).map(reduceTableData),
    [reduceTableData, reportDataGroup]
  );

  const tableLabels = useMemo(
    () => [
      "№",
      "Номер акта",
      "Заказчик",
      "Проект",
      "Партнер",
      "Вид Техники",
      "Характеристика",
      "Вид услуги",
      "Гос номер",
      "Смена",
      "Дата работы",
      "Время начала\nработы",
      "Время окончания\nработы",
      "Ед изм",
      "Данные линии",
      "Моточасы GPS",
      "Длительность смены",
      "К оплате",
      "Цена",
      "Ставка НДС",
      "Сумма",
      "Статус акта",
      "Сумма факта без GPS",
      "Оснащение GPS,%",
      "Комментарий",
    ],
    []
  );

  const allRowsCollapsed = useMemo(
    () => tableDataCollapsed.length === collapsedRows.length,
    [collapsedRows.length, tableDataCollapsed.length]
  );

  const onRowCollapse = useCallback(() => {
    if (allRowsCollapsed) {
      setCollapsedRows([]);
    } else {
      setCollapsedRows(tableDataCollapsed.map((_, i) => i));
    }
  }, [allRowsCollapsed, tableDataCollapsed]);

  const onFilterSubmit = useCallback((data: FilterSubmitData) => {
    setCollapsedRows([]);
    setReportData(data.reportData);
    setReportDataGroup(data.reportDataGroup);
    setTotalRowValue(data.totalRows);
  }, []);

  const tableHeight = useMemo(() => {
    return window.innerHeight - 100 /*- (filterPanelOpen ? 190 : 0)*/;
  }, []);

  const tableExtraRow = useMemo<(string | ReactNode)[]>(
    () =>
      tableLabels.map((_, index) => {
        if (index === 1) {
          return "Итого";
        } else if (index === 15) {
          return totalRowValues?.totalHoursGps || "—";
        } else if (index === 16) {
          return totalRowValues?.totalShiftDuration || "—";
        } else if (index === 20) {
          return currencyFormat(totalRowValues?.totalAmountToPay);
        }
        return "";
      }),
    [
      tableLabels,
      totalRowValues?.totalAmountToPay,
      totalRowValues?.totalHoursGps,
      totalRowValues?.totalShiftDuration,
    ]
  );

  return (
    <LayoutDefault title={title}>
      <StyledContainer id="main-table">
        <Filters
          isGroup={isGroup}
          downloadDisabled={!reportData?.length && !reportDataGroup?.length}
          CollapseButton={
            isGroup && reportDataGroup?.length ? (
              <Button
                variant="text"
                text={`${
                  allRowsCollapsed ? "Развернуть" : "Свернуть"
                } все строки`}
                onClick={onRowCollapse}
              />
            ) : (
              <div />
            )
          }
          onSubmit={onFilterSubmit}
        />
        <StyledContent>
          {tableData.length || tableDataCollapsed.length ? (
            <Table
              labels={tableLabels}
              onClick={() => {}}
              data={tableData}
              totalRow={tableExtraRow}
              nodeData={tableDataChildren}
              collapsedRows={collapsedRows}
              collapsedData={tableDataCollapsed}
              stickyHeader={true}
              lazy={true}
              height={`${tableHeight}px`}
              cellMaxWidth={150}
              onRowCollapse={(val) => setCollapsedRows(val)}
            />
          ) : (
            <PlaceholderText>
              {reportData?.length === 0
                ? "Нет данных. Попробуйте изменить параметры фильтров"
                : "Заполните фильтры и нажмите «Применить»"}
            </PlaceholderText>
          )}
        </StyledContent>
      </StyledContainer>
    </LayoutDefault>
  );
};

export default memo(PageReportDetail);
