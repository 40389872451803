import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { DrawingManager, PolygonF } from "@react-google-maps/api";
import { Colors } from "../../../../ThemeProvider";
import { useGoogleMap, useMonitoring } from "@app/providers";

interface Props {}

/*const CustomPoint = styled.div`
  background-color: #5bcd71;
  width: 96px;
  padding: 4px;
`;*/
const DrawGEOFence = (_props: Props) => {
  const { map } = useGoogleMap();
  const {
    geoFenceDetail,
    setGEOFence,
    latLngList,
    setGEOFenceLatLngList,
    controlsHidden,
  } = useMonitoring();
  // const [paths, setPaths] = useState<LatLng[]>([]);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager>();

  const onClickGoogleMap = useCallback(
    (mapEvent: google.maps.MapMouseEvent, _args: any) => {
      // setGEOFenceLatLngList((prevState) => [...prevState, mapEvent.latLng!]);
      // const clickedLatLng: LatLng = {
      //   latitude: mapEvent.latLng!.lat(),
      //   longitude: mapEvent.latLng!.lng(),
      // };
      // setGEOFence(geoFenceDetail, [...paths, clickedLatLng]);
    },
    []
  );

  const onClickEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setGEOFence(null);
        setGEOFenceLatLngList([]);
      }
    },
    [setGEOFence, setGEOFenceLatLngList]
  );

  useEffect(() => {
    const clickListener = map.addListener("click", onClickGoogleMap);
    document.addEventListener("keydown", onClickEsc, true);

    return () => {
      clickListener.remove();
      document.removeEventListener("keydown", onClickEsc);
    };
  }, [map, onClickEsc, onClickGoogleMap]);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current.getPath().getArray();
      setGEOFenceLatLngList(nextPath);
    }
  }, [setGEOFenceLatLngList]);

  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const polygonOptions: google.maps.PolygonOptions = useMemo(
    () => ({
      strokeColor: geoFenceDetail?.colorCode || Colors.green,
      strokeOpacity: 1,
      strokeWeight: 5,
      fillColor: geoFenceDetail?.colorCode || Colors.green,
      fillOpacity: controlsHidden ? 0.33 : 0.5,
      // если скрыто меню Создание геозоны, значит открыто НЕ из раздела Мониторинг
      draggable: !controlsHidden,
      editable: !controlsHidden,
      clickable: !controlsHidden,
      zIndex: 0,
    }),
    [geoFenceDetail?.colorCode, controlsHidden]
  );

  const polygonType = useMemo(
    () => window.google.maps.drawing.OverlayType.POLYGON,
    []
  );

  const drawingManagerOptions = useMemo(
    () => ({
      polygonOptions,
      drawingControl: !geoFenceDetail?.id,
      drawingMode: !geoFenceDetail?.id ? polygonType : null,
      drawingControlOptions: {
        position: window.google?.maps?.ControlPosition?.TOP_CENTER,
        drawingModes: [polygonType],
      },
    }),
    [geoFenceDetail?.id, polygonOptions, polygonType]
  );
  const onLoadDrawingManager = (
    drawingManager: google.maps.drawing.DrawingManager
  ) => {
    drawingManagerRef.current = drawingManager;
  };

  const onOverlayComplete = (
    $overlayEvent: google.maps.drawing.OverlayCompleteEvent
  ) => {
    drawingManagerRef.current?.setOptions({
      drawingMode: null,
      drawingControl: null,
    });
    map.setOptions({ draggableCursor: "grab" });
    if ($overlayEvent.type === polygonType) {
      const newPolygonLatLng = ($overlayEvent.overlay as google.maps.Polygon)
        .getPath()
        .getArray();

      // start and end point should be same for valid geojson
      const startPoint = newPolygonLatLng[0];
      newPolygonLatLng.push(startPoint);
      $overlayEvent.overlay?.setMap(null);
      setGEOFenceLatLngList(newPolygonLatLng);
    }
  };

  return (
    <>
      <DrawingManager
        options={drawingManagerOptions}
        onLoad={onLoadDrawingManager}
        onOverlayComplete={onOverlayComplete}
      />
      {/*{latLngList.length && <Marker position={latLngList[0]} />}*/}
      {/* Можно использовать для редактирования точек */}
      {/*{latLngList.length && (*/}
      {/*  <OverlayView*/}
      {/*    position={latLngList[2]}*/}
      {/*    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}*/}
      {/*  >*/}
      {/*    <CustomPoint>*/}
      {/*      <h6>OverlayView</h6>*/}
      {/*      <button*/}
      {/*        onClick={() => {*/}
      {/*          latLngList.splice(2, 1);*/}
      {/*          setGEOFenceLatLngList([...latLngList]);*/}
      {/*        }}*/}
      {/*        type="button"*/}
      {/*      >*/}
      {/*        Delete point*/}
      {/*      </button>*/}
      {/*    </CustomPoint>*/}
      {/*  </OverlayView>*/}
      {/*)}*/}
      {latLngList.length > 0 && (
        <PolygonF
          path={latLngList}
          options={polygonOptions}
          onLoad={onLoad}
          onMouseUp={onEdit}
          onDragEnd={onEdit}
          onUnmount={onUnmount}
        />
      )}
    </>
  );
};

export default DrawGEOFence;
