import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Info, Modal, ModalProps, TextField } from "@app/components";
import { useNotification, useUser } from "@app/providers";
import { VehicleJournalCommentDto } from "@app/models";
import {
  dateFormat,
  getAxiosErrorMessage,
  getFullName,
  isPartner,
} from "@app/helpers";
import styled from "styled-components";
import {
  addCommentInJournal,
  APIResponse,
  deleteCommentInJournal,
  JournalCommentCreate,
  updateCommentInJournal,
} from "@app/api";
import { AxiosError } from "axios";
import {
  IconClose24,
  IconPencil24,
  IconSuccessCircle24,
  IconTrash24,
} from "@app/icons";
import { theme } from "styled-tools";

interface Props extends ModalProps {
  canComment: boolean;
  journalId: string;
  comment?: string;
  comments?: VehicleJournalCommentDto[];
  onChange: (value: string, name: any) => void;
  onClose: (val?: boolean) => void;
}

interface CommentRowProp {
  loading: boolean;
  comment: VehicleJournalCommentDto;
  onEdit: (commentId: VehicleJournalCommentDto["id"], value: string) => void;
  onDelete: (commentId: VehicleJournalCommentDto["id"]) => void;
}

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledListItemWrapper = styled.div`
  border-bottom: 1px #b2b2b2 solid;
`;

const StyledListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 16px 0;

  .delete-text {
    color: ${theme("color.red")};
  }
`;

const StyledAction = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

function CommentRow(props: CommentRowProp) {
  const { comment, loading, onEdit, onDelete } = props;
  const { user } = useUser();

  const [value, setValue] = useState(comment.comment);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const isSameUser = useCallback(
    (userId: string) => user?.id === userId,
    [user?.id]
  );

  const onChange = useCallback((val: string) => setValue(val), []);

  const startEditComment = useCallback(() => {
    setDeleting(false);
    setEditing(true);
  }, []);
  const stopEditComment = useCallback(() => setEditing(false), []);
  const confirmEditing = useCallback(
    () => onEdit(comment.id, value),
    [comment.id, onEdit, value]
  );

  const startDeleteComment = useCallback(() => {
    setEditing(false);
    setDeleting(true);
  }, []);
  const stopDeleteComment = useCallback(() => setDeleting(false), []);

  const confirmDelete = useCallback(
    () => onDelete(comment.id),
    [comment.id, onDelete]
  );

  return (
    <StyledListItemWrapper>
      <StyledListItem>
        <Info
          label={
            getFullName(comment.user) +
            " / " +
            dateFormat(comment.created, "dd.MM.yyyy HH:mm")
          }
          value={comment.comment}
          variant={"secondary"}
        />
        {isSameUser(comment.userId) && !comment.isDeleted && (
          <StyledAction>
            <Button
              startIcon={IconPencil24}
              variant={"outlined"}
              showLoader={loading}
              disabled={loading}
              onClick={startEditComment}
            />
            <Button
              startIcon={IconTrash24}
              variant={"text"}
              showLoader={loading}
              disabled={loading}
              onClick={startDeleteComment}
            />
          </StyledAction>
        )}
      </StyledListItem>
      {editing && (
        <StyledListItem>
          <TextField
            label="Редактирование"
            placeholder="Введите текст"
            name="comment"
            value={value}
            // disabled={!canCommentJournal}
            multiline={true}
            onChange={onChange}
          />
          <StyledAction>
            <Button
              startIcon={IconClose24}
              variant={"outlined"}
              showLoader={loading}
              disabled={loading}
              onClick={stopEditComment}
            />
            <Button
              startIcon={IconSuccessCircle24}
              variant={"text"}
              showLoader={loading}
              disabled={loading}
              onClick={confirmEditing}
            />
          </StyledAction>
        </StyledListItem>
      )}
      {deleting && (
        <StyledListItem>
          <p className="delete-text">
            Вы действительно хотите удалить данный комментарий?
          </p>
          <StyledAction>
            <Button
              startIcon={IconClose24}
              variant={"outlined"}
              showLoader={loading}
              disabled={loading}
              onClick={stopDeleteComment}
            />
            <Button
              startIcon={IconSuccessCircle24}
              variant={"text"}
              showLoader={loading}
              disabled={loading}
              onClick={confirmDelete}
            />
          </StyledAction>
        </StyledListItem>
      )}
    </StyledListItemWrapper>
  );
}

const ModalJournalComments = (props: Props) => {
  const {
    // comment,
    comments,
    canComment,
    journalId,
    onChange,
    onClose,
    ...restProps
  } = props;
  const { showNotification } = useNotification();
  const { user } = useUser();
  const userIsPartner = !!(user && isPartner(user.role));
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState("");

  const onCommentChange = useCallback((value: string) => setComment(value), []);

  const onModalClose = useCallback(
    (needUpdate?: boolean) => {
      setComment("");
      onClose(needUpdate);
    },
    [onClose]
  );

  const onCommentAdd = useCallback(
    async (commentId?: string, value?: string) => {
      try {
        setLoading(true);
        let response;

        if (commentId && value) {
          response = await updateCommentInJournal({
            id: commentId,
            comment: value,
          });
        } else {
          const body: JournalCommentCreate = {
            vehicleJournalId: journalId,
            comment: comment,
          };

          response = await addCommentInJournal(body);
        }

        setLoading(false);
        onModalClose(true);
        //
        if (response?.succeeded) {
          showNotification({
            message: "Комментарий сохранен",
            variant: "success",
          });
        }
      } catch (e) {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [comment, journalId, onModalClose, showNotification]
  );

  const onCommentDelete = useCallback(
    async (commentId: string) => {
      try {
        setLoading(true);

        const response = await deleteCommentInJournal(commentId);
        setLoading(false);
        onModalClose(true);

        if (response?.succeeded) {
          onChange("", "comment");
          showNotification({
            message: "Комментарий удален",
            variant: "success",
          });
        }
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [onChange, onModalClose, showNotification]
  );

  const renderComments = useCallback(
    (item: VehicleJournalCommentDto, index: number) => (
      <CommentRow
        key={index.toString()}
        comment={item}
        loading={loading}
        onEdit={onCommentAdd}
        onDelete={onCommentDelete}
      />
    ),
    [loading, onCommentAdd, onCommentDelete]
  );

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    if (canComment && userIsPartner) {
      returnActions.push(
        <Button
          text="Добавить комментарий"
          showLoader={loading}
          disabled={!comment}
          onClick={onCommentAdd}
        />
      );
    }
    return returnActions;
  }, [canComment, comment, loading, onCommentAdd, userIsPartner]);

  return (
    <Modal actions={actions} onClose={onModalClose} {...restProps}>
      <StyledContainer>
        <StyledList>{comments?.map(renderComments)}</StyledList>
        {userIsPartner && (
          <TextField
            label="Комментарий (вид работ и пр.заметки)"
            placeholder="Введите текст"
            name="comment"
            value={comment}
            // error={!!errors.comment}
            // helperText={errors.comment}
            disabled={loading}
            multiline={true}
            onChange={onCommentChange}
          />
        )}
      </StyledContainer>
    </Modal>
  );
};

export default memo(ModalJournalComments);
