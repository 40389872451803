import styled, { css } from "styled-components";
import { switchProp, theme } from "styled-tools";
import { FC, PropsWithChildren } from "react";

type PageCardProps = PropsWithChildren & {
  bg: string;
  variant?: "default" | "small";
  onClick: () => void;
};

const StyledListItem = styled.div<PageCardProps>`
  ${switchProp("variant", {
    default: css`
      height: 388px;
      min-height: 50vh;
    `,
    small: css`
      height: 114px;
      min-height: 17vh;
    `,
  })}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  gap: 16px;
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  border: 1px solid ${theme("color.grayLight")};
  cursor: pointer;

  p {
    color: ${theme("color.white")};
    font-size: 3vh;
    font-weight: 600;
    line-height: 3.5vh;
    text-align: center;
  }

  svg {
    display: inline-block;
    width: 12vh;
    height: 12vh;
  }

  &:hover {
    p {
      text-decoration: underline;
    }
  }
`;

const PageCard: FC<PageCardProps> = (props) => {
  return <StyledListItem {...props} />;
};

export default PageCard;
