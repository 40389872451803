import { Chip, ChipColor } from "@app/components";
import { AdhesionContractStatus, AdhesionStatusName } from "@app/models";
import React from "react";

interface Props {
  status: AdhesionContractStatus;
}

const AdhesionStatusChip = (props: Props) => {
  const { status } = props;

  const getStatusVariant = (status: AdhesionContractStatus): ChipColor => {
    switch (status) {
      case AdhesionContractStatus.Created:
        return "primary";
      case AdhesionContractStatus.Signed:
        return "success";
      default:
        return "primary";
    }
  };

  return (
    <Chip text={AdhesionStatusName[status]} color={getStatusVariant(status)} />
  );
};

export default AdhesionStatusChip;
