import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Checkbox } from "@app/components";
import { MonitoringStatusColors } from "../Vehicles/components/Header/Header";
import styled from "styled-components";
import { prop, theme } from "styled-tools";
import { VehicleMonitoring } from "@app/models";
import { APIResponse, getVehicleState, getVehicleTrack } from "@app/api";
import { useMonitoring, useNotification } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

interface Props {
  vehicle: VehicleMonitoring;
  setPending: Dispatch<SetStateAction<boolean>>;
}

const StyledVehicle = styled.div`
  width: 100%;
  background-color: ${theme("color.white")};
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  text-align: left;
  position: relative;

  &:not(:first-child) {
    border-top: 1px solid ${theme("color.gray")};
  }
`;

const StyledVehicleContent = styled.button`
  cursor: pointer;
  background-color: transparent;
  flex-grow: 1;
  flex-shrink: 1;
  border: none;
  padding: 0;
  text-align: left;

  &:hover {
    > p:first-child {
      text-decoration: underline;
    }
  }
`;

const StyledVehicleLabel = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 4px;
  color: ${theme("color.dark")};
`;

const StyledVehicleValue = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${theme("color.grayDark")};
`;

const StyledVehicleStatus = styled.div<{
  color: string;
}>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${prop("color")};
`;

const StyledCheckboxContainer = styled.div`
  margin-right: 8px;
`;

export default function (props: Props) {
  const { vehicle, setPending } = props;
  const { setVehicles, vehicles } = useMonitoring();
  const { showNotification } = useNotification();

  const getVehicleTrackData = useCallback(async () => {
    try {
      setPending(true);
      setVehicles([]);

      let { data: stateData } = await getVehicleState(vehicle.id);
      const { data, errors } = await getVehicleTrack(vehicle.id, {
        fromTime: Math.floor(vehicle.dateMin!.getTime() / 1000),
        toTime: Math.floor(vehicle.dateMax!.getTime() / 1000),
      });

      setPending(false);

      if (!!errors && errors.length > 0) {
        showNotification({
          variant: "error",
          message:
            typeof errors !== "string"
              ? errors[0]
              : `Ошибка при получении данных от провайдера`,
        });

        return;
      }

      const trackPointsLength = (data.points || []).length;

      if (trackPointsLength === 0) {
        showNotification({
          variant: "warning",
          message: `Трек отсутствует!\nНе удалось загрузить историю движения`,
        });

        return;
      }

      setVehicles([
        {
          ...(vehicle as VehicleMonitoring),
          points: data.points,
          ...(stateData || {
            address: "",
            dateTime: data.points[trackPointsLength - 1].dateTime,
            speed: data.points[trackPointsLength - 1].speed,
            latitude: data.points[trackPointsLength - 1].latitude,
            longitude: data.points[trackPointsLength - 1].longitude,
          }),
        },
      ]);
    } catch (e) {
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
      setPending(false);
    }
  }, [setPending, setVehicles, vehicle, showNotification]);

  const onClickVehicle = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (vehicles.findIndex((item) => item.id === vehicle.id) === -1) {
        await getVehicleTrackData();
      } else {
        setVehicles(vehicles.filter((item) => item.id !== vehicle.id));
      }
    },
    [getVehicleTrackData, setVehicles, vehicle, vehicles]
  );

  const onChangeVehicle = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const {
        target: { checked },
      } = e;

      try {
        if (checked && vehicles.length === 0) {
          await getVehicleTrackData();

          return;
        }

        if (!checked) {
          setVehicles(
            vehicles.filter((prevVehicle) => vehicle.id !== prevVehicle.id)
          );

          return;
        }

        setPending(true);

        const { data: vehicleStateResponse, errors: vehicleStateErrors } =
          await getVehicleState(vehicle.id);

        const { data } = await getVehicleTrack(vehicle.id, {
          fromTime: Math.floor(vehicle.dateMin!.getTime() / 1000),
          toTime: Math.floor(vehicle.dateMax!.getTime() / 1000),
        });

        setPending(false);

        if (!!vehicleStateErrors && vehicleStateErrors.length > 0) {
          showNotification({
            variant: "error",
            message:
              typeof vehicleStateErrors !== "string"
                ? vehicleStateErrors[0]
                : `Ошибка при получении данных от провайдера`,
          });

          return;
        }

        setVehicles([
          ...vehicles,
          {
            ...vehicle,
            ...vehicleStateResponse,
            points: data.points || [],
          },
        ]);
      } catch (e) {}
    },
    [
      getVehicleTrackData,
      setPending,
      setVehicles,
      showNotification,
      vehicle,
      vehicles,
    ]
  );

  const checked = useMemo<boolean>(() => {
    return (
      vehicles.map((vehicleItem) => vehicleItem.id).indexOf(vehicle.id) > -1
    );
  }, [vehicle.id, vehicles]);

  return (
    <StyledVehicle>
      <StyledCheckboxContainer>
        <Checkbox checked={checked} onChange={onChangeVehicle} />
      </StyledCheckboxContainer>
      <StyledVehicleContent onClick={onClickVehicle}>
        <StyledVehicleLabel>{vehicle.govNumber}</StyledVehicleLabel>
        <StyledVehicleValue>
          {vehicle.companies.reduce(
            (accumulator, currentValue, currentIndex) =>
              `${accumulator} ${currentIndex > 0 ? ", " : ""} ${
                currentValue.name
              }`.trim(),
            ""
          )}
        </StyledVehicleValue>
      </StyledVehicleContent>
      <StyledVehicleStatus
        color={MonitoringStatusColors[vehicle.gpsDataUpdatedStatusId]}
      />
    </StyledVehicle>
  );
}
