import React, { useCallback, useEffect, useRef, useState } from "react";
import Field, { FieldProps } from "../Field";
import { genericMemo } from "@app/helpers";
import { IconCheckCircle16, IconChevronDown24, IconClose24 } from "@app/icons";
import { Loader, Paper } from "../index";
import styled from "styled-components";
import { theme } from "styled-tools";
import { PageableParams, PageableResponse } from "@app/api";
import { useDebounce } from "@app/hooks";

export interface DefaultObject<T> {
  id: keyof T;
  name: string;
}

interface Props<T> extends FieldProps {
  options?: (T | string)[];
  valueKey?: keyof T;
  labelKey?: keyof T;
  labelKeys?: (keyof T)[];
  labelKeysSeparator?: string;
  values?: (T | string)[] | null;
  placeholder?: string;
  compliant?: boolean; // можно добавлять на enter
  loadData?: (params: PageableParams<T>) => Promise<PageableResponse<T>>;
  onChange: (value: (T | string)[], name: any) => void;
  onItemSelect?: (value: T | string | null, name: any) => void;
  onClear?: () => void;
}

const StyledWrapper = styled.div<{ addMargin?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.addMargin ? "24px" : 0)};
`;

const StyledInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
  padding: 0 36px 0 12px;

  input {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;

    ::placeholder {
      color: ${theme("color.gray")};
    }

    &:focus-visible {
      outline: none;
    }
  }

  #loader {
    margin-top: 8px;
  }

  button {
    width: 24px;
    height: 24px;
    visibility: hidden;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    opacity: 0.7;

    :hover {
      background: #f4f4f4;
      opacity: 1;
    }
  }

  :hover {
    button {
      visibility: visible;
    }
  }
`;

const StyledDropdown = styled.div`
  // width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  margin-top: 26px;
  z-index: 2;
  padding: 0;
`;

const StyledList = styled.div`
  padding: 0;
  margin: 0;
  max-height: 240px;
  overflow: auto;
`;

const StyledListItem = styled.button<{
  selected?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  padding: 12px 16px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => theme(props.disabled ? "color.grayDark" : "color.dark")};
  text-align: left;
  outline: none;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  background-color: transparent;
  border: none;

  #icon-check {
    height: 16px;
    width: 16px;
    min-width: 16px;
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "transparent" : "#F5F6F8"};
  }
`;

const StyledChipsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 54px;
  margin-top: 16px;
  padding-right: 8px;
  z-index: 0;
  display: flex;
  gap: 4px;
  background: white;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledChip = styled.div`
  position: relative;
  display: flex;
  height: 16px;
  //max-width: 72px;
  min-width: 64px;
  padding: 2px 14px 2px 4px;
  background: ${theme("color.white")};
  border-radius: 16px;
  border: 1px solid ${theme("color.gray")};

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    width: 12px;
    position: absolute;
    right: 4px;
    top: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    border: none;
    background: ${theme("color.white")};
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background: #f5f6f8;
    }

    &:after {
      content: "✕";
    }
  }
`;

const Combobox = <T extends { name: string; id: string }>(props: Props<T>) => {
  const {
    options: propsOptions,
    values = [],
    label,
    valueKey = "id",
    labelKey = "name",
    labelKeys,
    labelKeysSeparator = " ",
    placeholder = "Поиск...",
    compliant = false,
    loadData,
    onChange,
    onItemSelect,
    onClear,
    ...fieldProps
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<(T | string)[]>(propsOptions || []);
  const [paperVisible, setPaperVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const debouncedText = useDebounce(inputValue, 500);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const params: PageableParams<T> = {
        pageNumber: 1,
        pageSize: 20,
        searchText: debouncedText,
      };

      const response = await loadData!(params);

      setOptions(response.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setOptions([]);
    }
  }, [debouncedText, loadData]);

  const getValue = useCallback(
    (item: T | string, key?: keyof T) => {
      if (typeof item === "string") {
        return item;
      } else if (labelKeys) {
        return labelKeys
          .map((key) => item[key] as string)
          .join(labelKeysSeparator);
      }
      return item[key || labelKey] as string;
    },
    [labelKey, labelKeys, labelKeysSeparator]
  );

  useEffect(() => {
    if (!!loadData) {
      getData();
    } else if (propsOptions) {
      const arr = propsOptions.filter((item) =>
        new RegExp(debouncedText, "i").test(getValue(item))
      );
      setOptions([...arr]);
    }
  }, [debouncedText, getData, getValue, loadData, propsOptions]);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    []
  );

  const onInputClear = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setInputValue("");
    setPaperVisible(false);
  }, []);

  const onChangeHandler = useCallback(
    (item: T | string, index: number, isDelete = false) => {
      if (values) {
        if (index >= 0) {
          values.splice(index, 1);
        } else {
          values.push(item);
        }
        onChange([...values], props.name);
      } else if (values === null) {
        onChange([item], props.name);
      }
      if (!!onItemSelect) {
        onItemSelect(isDelete ? null : item, props.name);
      }
      setInputValue("");
      setPaperVisible(false);
    },
    [onChange, onItemSelect, props.name, values]
  );

  const onChipRemove = useCallback(
    (item: T | string, index: number) => () =>
      onChangeHandler(item, index, true),
    [onChangeHandler]
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (compliant && (e.code === "Enter" || e.code === "NumpadEnter")) {
        const idx = (values || []).findIndex(
          (v) => getValue(v, valueKey) === inputValue
        );
        onChangeHandler(inputValue, idx);
        setInputValue("");
      }
    },
    [compliant, getValue, inputValue, onChangeHandler, valueKey, values]
  );

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target as HTMLElement)
    ) {
      setPaperVisible(false);
    }
  }, []);

  const onInputClick = useCallback(() => {
    setPaperVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  const renderOptions = () => {
    if (options.length === 0 && !!inputValue) {
      return (
        <StyledListItem
          key={"no-items"}
          onClick={() => onChangeHandler(inputValue, -1)}
        >
          Добавить "{inputValue}"
        </StyledListItem>
      );
    }
    return options.map((item, index) => {
      const idx = (values || []).findIndex(
        (v) => getValue(v, valueKey) === getValue(item, valueKey)
      );
      return (
        <StyledListItem
          key={index.toString()}
          selected={idx > -1}
          onClick={() => onChangeHandler(item, idx)}
        >
          <div id="icon-check">{idx > -1 && <IconCheckCircle16 />}</div>
          {getValue(item)}
        </StyledListItem>
      );
    });
  };

  return (
    <StyledWrapper ref={wrapperRef} addMargin={!!values?.length}>
      <Field
        label={label}
        appendIcon={!compliant ? <IconChevronDown24 /> : undefined}
        onAppendClick={onInputClick}
        className={""}
        {...fieldProps}
      >
        <StyledInput>
          <input
            value={inputValue}
            disabled={fieldProps.disabled}
            placeholder={compliant ? "Нажм. Enter для добавления" : placeholder}
            onClick={onInputClick}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
          />
          {loading && (
            <div id="loader">
              <Loader size={"small"} />
            </div>
          )}
          {!loading && inputValue && (
            <button onClick={onInputClear}>
              <IconClose24 />
            </button>
          )}
        </StyledInput>
      </Field>
      {paperVisible && !compliant && (
        <StyledDropdown>
          <Paper shadow={true} dense={true}>
            <StyledList>{renderOptions()}</StyledList>
          </Paper>
        </StyledDropdown>
      )}
      <StyledChipsContainer>
        {(values || []).map((item, index) => (
          <StyledChip key={index.toString()}>
            <span title={getValue(item)}>{getValue(item)}</span>
            <button id="icon" onClick={onChipRemove(item, index)} />
          </StyledChip>
        ))}
      </StyledChipsContainer>
    </StyledWrapper>
  );
};

export default genericMemo(Combobox);
