import { DocumentFile } from "@app/components";
import React, { FC, memo } from "react";
import { CompanyInfo } from "@app/models";
import { StyledGrid } from "./styled";

type Props = {
  company: CompanyInfo | null;
};

const FilesView: FC<Props> = (props) => {
  const { company } = props;

  if (!company) {
    return null;
  }

  return (
    <StyledGrid>
      {company.files.map((item, index) => {
        const { file } = item;
        return (
          <DocumentFile
            key={index.toString()}
            id={file.id}
            name={file.name || `${item?.id || index.toString()}`}
            previewEnabled={true}
          />
        );
      })}
    </StyledGrid>
  );
};

export default memo(FilesView);
