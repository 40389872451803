export function getUrlParam(key: string): string | null {
  const urlSearchParams = new URLSearchParams(window.location.search);

  return urlSearchParams.get(key);
}

export function getUrlParams<T>(keys: string[]): T {
  const urlSearchParams = new URLSearchParams(window.location.search);

  return keys.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: urlSearchParams.get(currentValue),
    }),
    {}
  ) as T;
}

export function updateUrlParams(params: { [key: string]: any }) {
  const url = new URL(`${window.location.origin}${window.location.pathname}`);

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (value && Array.isArray(value)) {
      // set array params and skip empty
      for (let i = 0; i < value.length; i++) {
        url.searchParams.append(key, value[i]);
      }
    } else if (typeof value !== "undefined" && value !== null && value !== "") {
      url.searchParams.set(
        key,
        typeof value === "string" ? value : value.toString()
      );
    }
  });

  window.history.replaceState(null, "React App", url);
}

export function addUrlParam(param: string | null, key = "id") {
  const queryParams = new URLSearchParams(window.location.search);
  if (param) {
    queryParams.set(key, param);
  } else {
    queryParams.delete(key);
  }
  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState({ path: newUrl }, "", newUrl);
}
