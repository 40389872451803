import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, Loader, Pagination } from "@app/components";
import { theme } from "styled-tools";
import { VehicleHistory } from "@app/models";
import { getVehicleHistory } from "@app/api";
import { dateFormat, getDictionaryName, numberWithSpaces } from "@app/helpers";

interface Props {
  id: string;
  date: Date | null;
}

const StyledVehicleHistory = styled.div``;

const StyledLabel = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.grayDark")};
  margin: 0 0 4px;
`;

const StyledValue = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
  margin: 0;
`;

const StyledLoaderContainer = styled.div`
  text-align: center;
`;

const StyledPagination = styled.div`
  margin: 20px auto 0;
`;

export default function (props: Props) {
  const { id, date } = props;
  const [pending, setPending] = useState<boolean>(false);
  const [history, setHistory] = useState<VehicleHistory[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState(0);

  const getData = useCallback(async () => {
    try {
      setPending(true);
      setHistory([]);

      const { data, recordsFiltered } = await getVehicleHistory(id, {
        date: date!.toJSON(),
        pageSize: 6,
        pageNumber: page,
      });

      setTotalCount(recordsFiltered);
      setHistory(data || []);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [page, date, id]);

  const onChangePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <StyledVehicleHistory>
      {pending && (
        <StyledLoaderContainer>
          <Loader size="small" />
        </StyledLoaderContainer>
      )}
      <Grid gap={16}>
        {history.length === 0 && (
          <StyledLabel>На данный период отсутствует история найма</StyledLabel>
        )}
        {history.map((historyItem) => (
          <div key={historyItem.id}>
            <StyledLabel>
              С {dateFormat(historyItem.startDate, "dd.MM.yyyy HH:mm")} по{" "}
              {dateFormat(historyItem.endDate, "dd.MM.yyyy HH:mm")}
            </StyledLabel>
            <StyledValue>
              {`${getDictionaryName(
                historyItem.project,
                "-"
              )}, ${getDictionaryName(historyItem.unit, "-")}
              ${numberWithSpaces(historyItem.priceWithoutNds.toString())}`}
            </StyledValue>
          </div>
        ))}
        <StyledPagination>
          <Pagination
            onChange={onChangePage}
            pageSize={6}
            initialPage={page}
            totalCount={totalCount}
            siblingCount={4}
          />
        </StyledPagination>
      </Grid>
    </StyledVehicleHistory>
  );
}
