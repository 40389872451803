import React, { memo, useMemo } from "react";
import { GeoFenceForm } from "@app/models";
import { PolygonF } from "@react-google-maps/api";
import { Colors } from "../../../../ThemeProvider";

type Props = {
  geoFence: GeoFenceForm | null;
};

const PolyFence = (props: Props) => {
  const { geoFence } = props;

  const coords = geoFence?.coordinates.map(
    ([lat, lng]) => new google.maps.LatLng(lat, lng)
  );

  // const randomColor = () => `hsla(${Math.random() * 360}, 100%, 50%, 1)`;

  const polygonOptions: google.maps.PolygonOptions = useMemo(
    () => ({
      strokeColor: geoFence?.colorCode || Colors.green,
      strokeOpacity: 1,
      strokeWeight: 5,
      fillColor: geoFence?.colorCode || Colors.green,
      fillOpacity: 0.33,
      // если скрыто меню Создание геозоны, значит открыто НЕ из раздела Мониторинг
      draggable: false,
      editable: false,
      clickable: false,
      zIndex: 0,
    }),
    [geoFence?.colorCode]
  );

  if (!coords) {
    return null;
  }

  return <PolygonF path={coords} options={polygonOptions} onLoad={() => {}} />;
};

export default memo(PolyFence);
