import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Grid, Modal, ModalProps, TextField } from "@app/components";
import { useNotification } from "@app/providers";
import { approveTaskContract } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";

interface Props extends ModalProps {
  taskId?: string;
  onClose: (val?: boolean) => void;
}

const ModalContractTaskApprove = (props: Props) => {
  const { onClose, taskId, ...restProps } = props;
  const { showNotification } = useNotification();

  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const onApproveConfirm = useCallback(async () => {
    if (!taskId) {
      return;
    }
    setLoading(true);
    approveTaskContract(taskId, comment)
      .then(() => {
        showNotification({
          variant: "success",
          message: "Согласован",
        });
        onClose(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e),
          variant: "error",
        });
      });
  }, [comment, onClose, showNotification, taskId]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text="Подтвердить согласование"
        onClick={onApproveConfirm}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onApproveConfirm]);

  const onCommentChange = useCallback((value: string) => {
    setComment(value);
  }, []);

  return (
    <Modal actions={actions} onClose={onClose} {...restProps}>
      <Grid>
        <TextField
          label="Комментарий"
          placeholder="Добавьте комментарий (необязательно)"
          value={comment}
          name="comment"
          onChange={onCommentChange}
        />
      </Grid>
    </Modal>
  );
};

export default memo(ModalContractTaskApprove);
