import { PageableParams, PageableResponse } from "./interfaces";
import instance from "./instance";
import { Unit } from "@app/models";

export function getUnits(
  params: PageableParams<Unit> & {
    nomenclatureId?: string;
    serviceTypeId?: string;
  }
): Promise<PageableResponse<Unit>> {
  return instance()
    .get("/unit", {
      params,
    })
    .then((response) => response.data);
}
