import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { Chip } from "@app/components";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "styled-tools";

export type ChipRoute = {
  label: string;
  path: string;
};

interface Props extends PropsWithChildren {
  routes: ChipRoute[];
}

const ChipRouterWrapper = styled.div`
  height: 100%;
`;

const ChipRouterLabels = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: ${theme("color.white")};
  border-bottom: 1px solid ${theme("color.grayLight")};
`;

const ChipRouterLabel = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 16px;
  cursor: pointer;
`;

const ChipRouterContent = styled.div`
  height: calc(100% - 64px);
`;

function ChipRouter(props: Props) {
  const { routes, children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState<number>(
    routes.findIndex((route) => location.pathname.indexOf(route.path) > -1)
  );

  const chipLabels = useMemo(
    () => routes.map((route) => route.label),
    [routes]
  );

  const onClick = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setActiveIndex(index);
      navigate(routes[index].path);
    },
    [navigate, routes]
  );

  return (
    <ChipRouterWrapper>
      <ChipRouterLabels>
        {chipLabels.map((label, labelIndex) => (
          <ChipRouterLabel
            onClick={onClick(labelIndex)}
            key={labelIndex.toString()}
          >
            <Chip
              color={activeIndex === labelIndex ? "warning" : "default"}
              variant="secondary"
              text={label}
            />
          </ChipRouterLabel>
        ))}
      </ChipRouterLabels>
      <ChipRouterContent>{children}</ChipRouterContent>
    </ChipRouterWrapper>
  );
}

export default memo(ChipRouter);
