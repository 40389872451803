import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Button,
  Dialog,
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import { GEOFence, Project } from "@app/models";
import {
  addGPSGeofences,
  APIResponse,
  createGEOFence,
  deleteGEOFence,
  getMyProjects,
  getProjects,
  updateGEOFence,
} from "@app/api";
import { AxiosError } from "axios";
import { useMonitoring, useNotification, useUser } from "@app/providers";
import { getAxiosErrorMessage, isDispatcher } from "@app/helpers";
import styled from "styled-components";
import { IconChevronDown24, IconTrash24 } from "@app/icons";
import { theme } from "styled-tools";
import { Colors } from "../../ThemeProvider";

interface Props {
  onClickAddGps: () => void;
  onClose: (needUpdate?: boolean) => void;
}
interface Data {
  name: string;
  project: SelectOption<Project> | null;
  color: string;
}

const schema = yup.object().shape({
  name: yup.string().required(),
  project: yup.object().nullable().required(),
  color: yup.string().required(),
});

const ColorInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  .label {
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: ${theme("color.grayDark")};
  }
`;

const ColorInput = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  transition: border 0.1s ease-in-out;
  position: relative;
  margin-bottom: 16px;

  &:hover {
    border: 1px solid #1c2246;
  }
  input[type="color"] {
    -webkit-appearance: none;
    border: none;
    background: #fff;
    width: 100%;
    height: 40px;
    overflow: hidden;
    outline: none;
    cursor: inherit;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 6px;
  }
`;

export default function (props: Props) {
  const { onClickAddGps, onClose } = props;
  const { setGEOFence, geoFenceDetail, latLngList, gpsGEOFences } =
    useMonitoring();
  const { user } = useUser();
  const { showNotification } = useNotification();
  const inputColorRef = useRef<HTMLInputElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { pending, onChange, errors, values, validate, setPending } =
    useForm<Data>({
      values: {
        name: geoFenceDetail?.name || "",
        project: !!geoFenceDetail?.project
          ? {
              label: geoFenceDetail.project.name,
              value: geoFenceDetail.project.id,
            }
          : null,
        color: geoFenceDetail?.colorCode || Colors.green,
      },
      schema,
    });

  const onColorInputClick = useCallback(() => {
    inputColorRef.current!.click();
  }, []);

  const onColorChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      onChange(value, "color");
      if (geoFenceDetail) {
        setGEOFence({ ...geoFenceDetail, colorCode: value });
      }
    },
    [geoFenceDetail, onChange, setGEOFence]
  );

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      if (latLngList.length < 3) {
        showNotification({
          message: "Некорректная область геозоны",
          variant: "error",
        });
        return;
      }

      // start and end point should be same for valid geojson
      const firstPoint = latLngList.at(0);
      const lastPoint = latLngList.at(-1);
      if (firstPoint && lastPoint && !firstPoint.equals(lastPoint)) {
        latLngList.push(firstPoint);
      }
      const coordinates = latLngList.map((item) => [item.lat(), item.lng()]);
      // console.log({ coordinates });

      setPending(true);

      let response;

      const body: Partial<GEOFence> = {
        projectId: values.project!.value,
        colorCode: values.color,
        name: values.name,
        coordinates,
      };

      if (geoFenceDetail?.id) {
        body.id = geoFenceDetail!.id;
        response = await updateGEOFence(body);
      } else {
        response = await createGEOFence(body);
      }

      if (!response?.succeeded) {
        showNotification({
          message: response?.message || "Возникла ошибка",
          variant: "error",
        });

        setPending(false);
        return;
      }

      showNotification({
        message: "Свойства геозоны сохранены",
        variant: "success",
      });

      const addGpsGeofences = {
        geofenceId: geoFenceDetail?.id || response.data.id,
        gpsGeofences: gpsGEOFences,
      };
      addGPSGeofences(addGpsGeofences)
        .then(() => {
          showNotification({
            message: "Провайдеры GPS добавлены",
            variant: "success",
          });
          setPending(false);
        })
        .catch(() => {
          showNotification({
            message: "Возникла ошибка, провайдеры GPS не были добавлены",
            variant: "error",
          });
        });

      onClose(true);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    validate,
    latLngList,
    setPending,
    values,
    geoFenceDetail,
    showNotification,
    gpsGEOFences,
    onClose,
  ]);

  const showDeleteDialog = useCallback(
    (val: boolean) => () => {
      setDeleteDialogOpen(val);
    },
    []
  );

  const onDeleteClick = useCallback(async () => {
    try {
      const response = await deleteGEOFence(geoFenceDetail!.id!);

      if (!response?.succeeded) {
        showNotification({
          message: response?.message || "Возникла ошибка",
          variant: "error",
        });
        return;
      }

      showNotification({
        message: "Геозона успешно удалена",
        variant: "success",
      });
      showDeleteDialog(false)();
      onClose(true);
    } catch (e) {
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [geoFenceDetail, onClose, showDeleteDialog, showNotification]);

  const onCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const actions = useMemo(() => {
    return [
      geoFenceDetail?.id && (
        <Button
          text="Удалить"
          variant={"text"}
          disabled={!geoFenceDetail}
          onClick={showDeleteDialog(true)}
        />
      ),
      <Button variant={"outlined"} text="Отменить" onClick={onCancelClick} />,
    ];
  }, [geoFenceDetail, onCancelClick, onDeleteClick]);

  return (
    <>
      <Form
        onSubmit={onSubmitForm}
        pending={pending}
        submitLabel="Сохранить"
        actions={actions}
      >
        <Grid>
          <TextField
            label="Название"
            placeholder="Введите название"
            name="name"
            onChange={onChange}
            value={values.name}
            error={!!errors.name}
            helperText={errors.name}
          />
          <Select<Project>
            label="Проект"
            name="project"
            onChange={onChange}
            value={values.project}
            error={!!errors.project}
            helperText={errors.project}
            valueKey="id"
            labelKey="name"
            loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
          />
          <ColorInputWrapper>
            <p className="label">Цвет зоны</p>
            <ColorInput>
              <input
                ref={inputColorRef}
                type="color"
                name="head"
                value={values.color}
                onChange={onColorChange}
              />
              <div className="icon" onClick={onColorInputClick}>
                <IconChevronDown24 />
              </div>
            </ColorInput>
          </ColorInputWrapper>

          <Button
            text="Настроить информацию провайдеров"
            variant="outlined"
            onClick={onClickAddGps}
          />
        </Grid>
      </Form>
      <Dialog
        open={deleteDialogOpen}
        title={`Вы уверены, что хотите удалить данную геозону?`}
        icon={IconTrash24}
        onConfirm={onDeleteClick}
        onClose={showDeleteDialog(false)}
      />
    </>
  );
}
