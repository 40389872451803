import React, { memo, useMemo } from "react";
import { Button, Modal, ModalProps } from "@app/components";
import styled from "styled-components";

interface Props extends Pick<ModalProps, "open"> {
  onAccept: () => void;
  onDecline: () => void;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 16px;
`;

const StyledHeaderText = styled.p`
  margin: 0;
  font-size: 20px;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 14px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

function ModalCookiePolicy(props: Props) {
  const { open, onAccept, onDecline } = props;

  const modalTitle = useMemo(() => "", []);

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      open={open}
      hideHeader={true}
      position="center"
      size="xsmall"
      onClose={() => {}}
    >
      <StyledContent>
        <StyledHeaderText>На сайте используются файлы cookie</StyledHeaderText>
        <StyledText>
          Они используются для статистических целей. Нажимая ’Принять’, Вы
          принимаете политику использование файлов cookie.
        </StyledText>
      </StyledContent>
      <StyledFooter>
        <Button text="Отклонить" variant="outlined" onClick={onDecline} />
        <Button text="Принять" onClick={onAccept} />
      </StyledFooter>
    </Modal>
  );
}

export default memo(ModalCookiePolicy);
