import React, { ChangeEvent, useCallback } from "react";
import { GEOFence } from "@app/models";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Checkbox } from "@app/components";

interface Props {
  active: boolean;
  geoFence: GEOFence;
  onClick: (data: GEOFence) => void;
  isSelected?: boolean;
  onSelect?: (data: GEOFence) => void;
}

const StyledGEOFence = styled.div<{ active: boolean }>`
  width: 100%;
  background-color: ${(props) =>
    props.active ? theme("color.blueLight") : theme("color.white")};
  padding: 12px 16px;
  box-sizing: border-box;

  &:hover {
    background-color: ${theme("color.blueLight")};
  }

  &:not(:first-child) {
    border-top: 1px solid ${theme("color.gray")};
  }
`;

const StyledGEOFenceContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const StyledGEOFenceLabel = styled.button`
  border: none;
  background: none;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 4px;
  color: ${theme("color.dark")};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default function (props: Props) {
  const { geoFence, active, onClick, onSelect, isSelected } = props;

  const onClickGEOFence = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onClick(geoFence);
    },
    [geoFence, onClick]
  );

  const onSelectGEOFence = useCallback(
    (_e: ChangeEvent<HTMLInputElement>) => {
      if (onSelect) {
        onSelect(geoFence);
      }
    },
    [geoFence, onSelect]
  );

  return (
    <>
      <StyledGEOFence active={active}>
        <StyledGEOFenceContent>
          <Checkbox checked={isSelected} onChange={onSelectGEOFence} />
          <StyledGEOFenceLabel onClick={onClickGEOFence}>
            {geoFence.name}
          </StyledGEOFenceLabel>
        </StyledGEOFenceContent>
      </StyledGEOFence>
    </>
  );
}
