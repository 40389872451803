import { APIResponse } from "./interfaces";
import { ConstructiveShort } from "@app/models";
import instance from "./instance";
import { AxiosResponse } from "axios";

export function getConstructiveById(
  id: string
): Promise<APIResponse<ConstructiveShort>> {
  const params = { id };
  return instance()
    .get(`/constructive/by-id`, { params })
    .then((response) => response.data);
}

export function getConstructiveByCompany(
  companyId: string
): Promise<APIResponse<ConstructiveShort[]>> {
  const params = { companyId };
  return instance()
    .get(`/constructive/by-company`, { params })
    .then((response) => response.data);
}
