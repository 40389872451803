import React, { memo, PropsWithChildren } from "react";
import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;

  @media only screen and (max-width: 576px) {
    width: initial;
    max-width: inherit;
    margin: 0;
  }
`;

function Container(props: PropsWithChildren) {
  const { children } = props;

  return <StyledContainer>{children}</StyledContainer>;
}

export default memo(Container);
