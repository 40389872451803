import { Grid, ListItem, Modal, ModalProps, ModalTitle } from "@app/components";
import React, { ReactNode, useMemo } from "react";
import { Adhesion } from "@app/models";
import { AdhesionStatusChip, ContractTranscriptView } from "@app/common";
import { dateFormat, getFullName } from "@app/helpers";

interface Props extends Omit<ModalProps, "title"> {
  adhesionContract: Adhesion;
}

const ModalAdhesionDetail = (props: Props) => {
  const { adhesionContract, ...modalProps } = props;

  const modalTitle = useMemo<ReactNode>(() => {
    if (!adhesionContract) {
      return "Нет данных";
    }

    return (
      <ModalTitle text={"Договор присоединения"}>
        <AdhesionStatusChip status={adhesionContract.statusId} />
      </ModalTitle>
    );
  }, [adhesionContract]);

  const modalHeader = useMemo(
    () => (
      <Grid columns={4}>
        <ListItem
          title={getFullName(adhesionContract.initiatorUser)}
          subtitle="Инициатор"
          reverse
        />
        <ListItem
          title={dateFormat(adhesionContract.date, "dd.MM.yyyy")}
          subtitle="Дата отправки"
          reverse
        />
      </Grid>
    ),
    [adhesionContract]
  );

  return (
    <Modal
      {...modalProps}
      headerContent={modalHeader}
      title={modalTitle}
      size={"medium"}
    >
      <ContractTranscriptView
        files={[adhesionContract.file]}
        pdfFiles={[adhesionContract.pdfFile]}
        signedPdfFiles={[adhesionContract.signedFile]}
      />
    </Modal>
  );
};

export default ModalAdhesionDetail;
