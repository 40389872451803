import React, { memo, useCallback, useMemo, useState } from "react";
import { VehicleRequest } from "@app/models";
import { dateFormat } from "@app/helpers";
import { TemplateSearch, TemplateSearchModalDetail } from "@app/templates";
import { getVehiclesRequests } from "@app/api";
import { ModalVehicleRequestDetail } from "@app/modals";
import { VehicleRequestStatusChip } from "@app/common";
import { Checkbox } from "@app/components";
import styled from "styled-components";

const StyledCheckbox = styled.div`
  margin-top: -64px;
`;

function DetailModal(props: TemplateSearchModalDetail<VehicleRequest>) {
  const { item: vehicleRequest, open, onClose, updateItem } = props;

  if (!open || !vehicleRequest) {
    return null;
  }

  return (
    <ModalVehicleRequestDetail
      showOffers={false}
      data={vehicleRequest}
      open={!!vehicleRequest}
      onClose={onClose}
      updateData={updateItem}
    />
  );
}

function VehicleRequests() {
  const [modalCreateVisible, setModalCreateVisible] = useState<boolean>(false);
  const [vehicleRequest, setVehicleRequest] = useState<VehicleRequest | null>(
    null
  );
  const [onlyForMyRegions, setOnlyForMyRegions] = useState<boolean>(true);

  const mapTableData = useCallback((item: VehicleRequest) => {
    return [
      item.number,
      item.project.name,
      item.project.region?.name,
      item.vehicleType.name,
      dateFormat(item.startDate, "dd.MM.yyyy "),
      dateFormat(item.endDate, "dd.MM.yyyy"),
      <VehicleRequestStatusChip status={item.status} />,
    ];
  }, []);

  const onClickAdd = useCallback(() => {
    setModalCreateVisible(true);
  }, []);

  const onClickItem = useCallback((item: VehicleRequest) => {
    setVehicleRequest(item);
  }, []);

  const closeModal = useCallback(() => {
    setModalCreateVisible(false);
  }, []);

  const closeDetailModal = useCallback(() => {
    setVehicleRequest(null);
  }, []);

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOnlyForMyRegions(e.target.checked);
    },
    []
  );

  const tableLabels = useMemo(
    () => [
      "№",
      "Проект",
      "Регион",
      "Вид техники",
      "Дата начала",
      "Дата завершения",
      "Статус",
    ],
    []
  );

  const getDataWithParams = useCallback(
    (params: any) =>
      getVehiclesRequests({ ...params, OnlyForMyRegions: onlyForMyRegions }),
    [onlyForMyRegions]
  );

  return (
    <>
      <TemplateSearch<VehicleRequest>
        toolbarProps={{
          searchPlaceholder: "Задача, инициатор проект, контрагент",
          bottomControls: [
            <StyledCheckbox>
              <Checkbox
                label="Только мои регионы"
                checked={onlyForMyRegions}
                onChange={handleCheckboxChange}
              />
            </StyledCheckbox>,
          ],
        }}
        getData={getDataWithParams}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        onClick={onClickItem}
        modalDetail={DetailModal}
      />
    </>
  );
}

export default memo(VehicleRequests);
