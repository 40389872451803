export const content = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{margin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:8.0pt;
\tmargin-left:0in;
\tline-height:107%;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;}
.MsoChpDefault
\t{font-family:"Calibri",sans-serif;}
.MsoPapDefault
\t{margin-bottom:8.0pt;
\tline-height:107%;}
@page WordSection1
\t{size:595.3pt 841.9pt;
\tmargin:56.7pt 42.5pt 56.7pt 85.05pt;}
div.WordSection1
\t{page:WordSection1;}
-->
</style>

</head>

<body lang=EN-US style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>Коэффициент использования рабочего времени</span></b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>&nbsp;характеризует степенью использования транспортных
средств и механизмов за время смены.</span><span lang=RU style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529'><br>
<span style='background:white'>Во время на смене входит: время движения, время
на погрузку и разгрузку и время простоев.</span></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><i><span
lang=RU style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>Чем лучше организованы погрузочно-разгрузочные работы и
меньше непроизводительные простои, тем выше коэффициент использования рабочего
времени.</span></i></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=KZ
style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212529'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=KZ style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212529'>Формулы
расчета </span></b><b><span lang=KZ style='font-size:12.0pt;font-family:"Arial",sans-serif'>для
работ </span></b><b><span lang=RU style='font-size:12.0pt;font-family:"Arial",sans-serif'>выполненных
по ед. изм «час»:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529'><br>
<br>
<b><span style='background:white'>Коэффициент использования рабочего времени
(загруженность) вычисляют по формуле:</span></b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Загруженность, % =</span></i></p>
  </td>
  <td style='border:none;border-bottom:solid black 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=KZ style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Длительность смены по </span></i><i><span
  style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>GPS</span></i><i><span
  lang=RU style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>,ч</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 0in 0in 0in'></td>
 </tr>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Данные линии,ч</span></i></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>«</span></b><b><span lang=KZ style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529;background:white'>Загруженность</span></b><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>,%»</span></b><span lang=RU style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529;background:white'>&nbsp;—
показатель работы техники согласно моточасам GPS относительно установленных
норм загруженности;</span><span lang=RU style='font-size:10.5pt;font-family:
"Arial",sans-serif;color:#212529'><br>
<b><span style='background:white'>«Длительность смены по GPS,ч»</span></b><span
style='background:white'>&nbsp;— сумма часов данных GPS за смену от первого
включения (допускается периодическое отключение двигателя при отсутствии фронта
работы) до последнего отключения двигателя;</span><br>
<br>
<br>
<b><span style='background:white'>Формулы расчета Потерь:</span></b></span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Потери = (</span></i><i><span lang=KZ
  style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>Данные
  линии</span></i><i><span lang=RU style='font-size:11.5pt;font-family:"Arial",sans-serif;
  color:#212529'> -</span></i><i><span lang=RU style='font-size:11.5pt;
  font-family:"Arial",sans-serif;color:#212529'> </span></i><i><span lang=RU
  style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>Длительность
  смены по GPS)</span></i></p>
  </td>
  <td style='border:none;border-bottom:solid black 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>&nbsp;</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>* Цена аренды</span></i></p>
  </td>
 </tr>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>&nbsp;</span></i></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>Пример:</span></b><span lang=RU style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529'><br>
<span style='background:white'>Длительность смены экскаватора 6 ч 18 мин., ответственный
проекта указал количество выполненных работ 9 часов, цена аренды 6 000 тг за
час.</span><br>
<span style='background:white'>Тогда для расчета потерь переведем часы и минуты
длит.смены в число:</span></span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Часы +</span></i></p>
  </td>
  <td style='border:none;border-bottom:solid black 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>минуты</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>= 6 +</span></i></p>
  </td>
  <td style='border:none;border-bottom:solid black 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>18</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>= 6 + 0,3 = 6,3 ч</span></i></p>
  </td>
 </tr>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>60</span></i></p>
  </td>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>60</span></i></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;display:none'>&nbsp;</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Потери = (9 -</span></i></p>
  </td>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>6,3</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 7.5pt 0in 7.5pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>) * 6000 = 16200 тг</span></i></p>
  </td>
 </tr>
 <tr>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<p class=MsoNormal><b><span lang=RU style='font-size:10.5pt;line-height:107%;
font-family:"Arial",sans-serif;color:#212529;background:white'>«</span></b><b><span
lang=KZ style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'>Неосвоенный бюджет</span></b><b><span lang=RU
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'>,</span></b><b><span lang=RU style='font-size:
10.5pt;line-height:107%;font-family:"Arial",sans-serif;color:#212529;
background:white'> </span></b><b><span lang=RU style='font-size:10.5pt;
line-height:107%;font-family:"Arial",sans-serif;color:#212529;background:white'>тг»</span></b><span
lang=RU style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'>&nbsp;— неосвоенная сумма работы техники,
которая не была использована в рамках </span><span lang=KZ style='font-size:
10.5pt;line-height:107%;font-family:"Arial",sans-serif;color:#212529;
background:white'>часов выполненных работ</span><span lang=RU style='font-size:
10.5pt;line-height:107%;font-family:"Arial",sans-serif;color:#212529;
background:white'>;</span><span lang=RU style='font-size:10.5pt;line-height:
107%;font-family:"Arial",sans-serif;color:#212529'><br>
<b><span style='background:white'>«Данные линии»</span></b><span
style='background:white'>&nbsp;— количество часов выполненных работ, указанных
в </span></span><span style='font-size:10.5pt;line-height:107%;font-family:
"Arial",sans-serif;color:#212529;background:white'>Dispatch</span><span
lang=RU style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'> ответственным сотрудником проекта</span><span
lang=RU style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529'><br>
<b><span style='background:white'>«Длительность смены по GPS,ч»</span></b><span
style='background:white'>&nbsp;— сумма часов данных GPS за смену от первого
включения (допускается периодическое отключение двигателя при отсутствии фронта
работы) до последнего отключения двигателя.</span></span></p>

<p class=MsoNormal><span lang=RU>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=KZ style='font-size:12.0pt;font-family:"Arial",sans-serif;color:#212529'>Формулы
расчета </span></b><b><span lang=KZ style='font-size:12.0pt;font-family:"Arial",sans-serif'>для
работ </span></b><b><span lang=RU style='font-size:12.0pt;font-family:"Arial",sans-serif'>выполненных
по ед. изм «</span></b><b><span lang=KZ style='font-size:12.0pt;font-family:
"Arial",sans-serif'>смена</span></b><b><span lang=RU style='font-size:12.0pt;
font-family:"Arial",sans-serif'>»:</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529'><br>
<br>
<b><span style='background:white'>Коэффициент использования рабочего времени
(загруженность) вычисляют по формуле:</span></b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.0pt;font-family:"Arial",sans-serif'>&nbsp;</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Загруженность, % =</span></i></p>
  </td>
  <td style='border:none;border-bottom:solid black 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=KZ style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Длительность смены по </span></i><i><span
  style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>GPS</span></i><i><span
  lang=RU style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>,ч</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 0in 0in 0in'></td>
 </tr>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=KZ style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Коэффициент смены * Кол-во часов в смене на
  проекте</span></i></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>«</span></b><b><span lang=KZ style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529;background:white'>Загруженность</span></b><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>,%»</span></b><span lang=RU style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529;background:white'>&nbsp;—
показатель работы техники согласно длительности смен GPS относительно коэффициента
работы техники на смене;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>«</span></b><b><span lang=KZ style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529;background:white'>Коэффициент
смены</span></b><b><span lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;
color:#212529;background:white'>»</span></b><span lang=RU style='font-size:
10.5pt;font-family:"Arial",sans-serif;color:#212529;background:white'>&nbsp;— показатель
работы техники за смену</span><span lang=KZ style='font-size:10.5pt;font-family:
"Arial",sans-serif;color:#212529;background:white'> в диапазоне от 0,1 до 1</span><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>, указываемый в </span><span style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529;background:white'>Dispatch</span><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>«</span></b><b><span lang=KZ style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529;background:white'>Кол-во часов в
смене на проекте</span></b><b><span lang=RU style='font-size:10.5pt;font-family:
"Arial",sans-serif;color:#212529;background:white'>»</span></b><span lang=RU
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>&nbsp;— количество часов в смене за исключением обеденного
перерыва;</span><span lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;
color:#212529'><br>
<b><span style='background:white'>«Длительность смены по GPS,ч»</span></b><span
style='background:white'>&nbsp;— сумма часов данных GPS за смену от первого
включения (допускается периодическое отключение двигателя при отсутствии фронта
работы) до последнего отключения двигателя;</span><br>
<br>
<br>
<b><span style='background:white'>Формулы расчета Потерь:</span></b></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Потери = </span></i></p>
  </td>
  <td style='border:none;border-bottom:solid windowtext 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>100 – Загруженность</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 7.5pt 0in 7.5pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>* Коэф.смены</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 0in 0in 7.5pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><i><span
  lang=RU style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>*
  Цена аренды</span></i></p>
  </td>
 </tr>
 <tr>
  <td style='border:none;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>100</span></i></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>Пример:</span></b><span lang=RU style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529'><br>
<span style='background:white'>Длительность смены экскаватора составила 8 ч 24
мин. Коэф.смены указан 1, часы ремонта- 1 час, часы ремонта – 1 час,</span></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><b><span
lang=RU style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529;
background:white'>Пример:</span></b><span lang=RU style='font-size:10.5pt;
font-family:"Arial",sans-serif;color:#212529'><br>
<span style='background:white'>Длительность смены экскаватора 6 ч 18 мин., ответственный
проекта указал коэффициент смены 0,9, цена аренды 60 000 тг за смену,
загруженность составила 55,6%</span></span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:10.5pt;font-family:"Arial",sans-serif;color:#212529'><br>
<span style='background:white'>Тогда для расчета потерь переведем часы и минуты
длит.смены в число:</span></span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Часы +</span></i></p>
  </td>
  <td style='border:none;border-bottom:solid black 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>минуты</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>= 6 +</span></i></p>
  </td>
  <td style='border:none;border-bottom:solid black 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>18</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>= 6 + 0,3 = 6,3 ч</span></i></p>
  </td>
 </tr>
 <tr>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>60</span></i></p>
  </td>
  <td style='padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>60</span></i></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='background:white;border-collapse:collapse'>
 <tr>
  <td rowspan=2 style='padding:0in 7.5pt 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>Потери = </span></i></p>
  </td>
  <td style='border:none;border-bottom:solid windowtext 1.0pt;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>100 – 55,6</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 7.5pt 0in 7.5pt'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>* 0,9</span></i></p>
  </td>
  <td rowspan=2 style='padding:0in 0in 0in 7.5pt'>
  <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><i><span
  lang=RU style='font-size:11.5pt;font-family:"Arial",sans-serif;color:#212529'>*
  60000 =  23&nbsp;976 тг</span></i></p>
  </td>
 </tr>
 <tr>
  <td style='border:none;padding:0in 0in 0in 0in'>
  <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
  line-height:normal'><i><span lang=RU style='font-size:11.5pt;font-family:
  "Arial",sans-serif;color:#212529'>100</span></i></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span lang=RU
style='font-size:12.0pt;font-family:"Times New Roman",serif;display:none'>&nbsp;</span></p>

<p class=MsoNormal><b><span lang=RU style='font-size:10.5pt;line-height:107%;
font-family:"Arial",sans-serif;color:#212529;background:white'>«</span></b><b><span
lang=KZ style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'>Неосвоенный бюджет</span></b><b><span lang=RU
style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'>,</span></b><b><span lang=RU style='font-size:
10.5pt;line-height:107%;font-family:"Arial",sans-serif;color:#212529;
background:white'> </span></b><b><span lang=RU style='font-size:10.5pt;
line-height:107%;font-family:"Arial",sans-serif;color:#212529;background:white'>тг»</span></b><span
lang=RU style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'>&nbsp;— неосвоенная сумма работы техники,
которая не была использована в рамках </span><span lang=KZ style='font-size:
10.5pt;line-height:107%;font-family:"Arial",sans-serif;color:#212529;
background:white'>часов выполненных работ</span><span lang=RU style='font-size:
10.5pt;line-height:107%;font-family:"Arial",sans-serif;color:#212529;
background:white'>;</span><span lang=RU style='font-size:10.5pt;line-height:
107%;font-family:"Arial",sans-serif;color:#212529'><br>
<b><span style='background:white'>«Данные линии»</span></b><span
style='background:white'>&nbsp;— количество часов выполненных работ, указанных
в </span></span><span style='font-size:10.5pt;line-height:107%;font-family:
"Arial",sans-serif;color:#212529;background:white'>Dispatch</span><span
lang=RU style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529;background:white'> ответственным сотрудником проекта</span><span
lang=RU style='font-size:10.5pt;line-height:107%;font-family:"Arial",sans-serif;
color:#212529'><br>
<b><span style='background:white'>«Длительность смены по GPS,ч»</span></b><span
style='background:white'>&nbsp;— сумма часов данных GPS за смену от первого
включения (допускается периодическое отключение двигателя при отсутствии фронта
работы) до последнего отключения двигателя.</span></span></p>

<p class=MsoNormal><span lang=RU>&nbsp;</span></p>

</div>

</body>

</html>
`;
