import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CompanyProjectRecipientsParams, CompanyRecipient } from "@app/models";
import { getCompanyProjectRecipients } from "@app/api";
import { Checkbox, Table } from "@app/components";
import { getFullName } from "@app/helpers";
import styled from "styled-components";

type Props = CompanyProjectRecipientsParams & {
  userIds: string[];
  onChange: (ids: string[]) => void;
};

const StyledWrapper = styled.div`
  margin-bottom: 36px;
`;

const CompanyNotificationRecipients = (props: Props) => {
  const { companyId, projectId, typeId, userIds, onChange } = props;
  const [companyRecipientList, setCompanyRecipientList] = useState<
    CompanyRecipient[]
  >([]);

  const getRecipients = useCallback(() => {
    if (!companyId || !projectId || companyRecipientList.length > 0) {
      return;
    }

    const params: CompanyProjectRecipientsParams = {
      companyId,
      projectId,
      typeId,
    };

    getCompanyProjectRecipients(params).then((res) => {
      if (res?.data) {
        setCompanyRecipientList(res.data);
      }
    });
  }, [companyId, companyRecipientList.length, projectId, typeId]);

  useEffect(() => {
    getRecipients();
  }, [getRecipients]);

  const recipientTableLabels = useMemo(
    () => ["Уведомления включены", "ФИО ответственного", "ИИН", "Email"],
    []
  );

  const LocalCheckbox = useCallback(
    (item: CompanyRecipient) => {
      const isChecked = userIds.includes(item.id);

      const onClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        if (checked) {
          onChange([...userIds, item.id]);
        } else {
          onChange(userIds.filter((id) => id !== item.id));
        }
      };

      return <Checkbox checked={isChecked} onChange={onClick} />;
    },
    [onChange, userIds]
  );

  const mapTableData = useCallback(
    (item: CompanyRecipient, _index: number) => {
      return [LocalCheckbox(item), getFullName(item), item.iin, item.email];
    },
    [LocalCheckbox]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => companyRecipientList.map(mapTableData),
    [companyRecipientList, mapTableData]
  );

  if (!companyRecipientList.length) {
    return null;
  }

  return (
    <StyledWrapper>
      <h6>Лица для ознакомления</h6>
      <Table
        labels={recipientTableLabels}
        data={tableData}
        disableOverflow={true}
        emptyTitle={"Добавьте пользователей для получения уведомлений"}
      />
    </StyledWrapper>
  );
};

export default memo(CompanyNotificationRecipients);
