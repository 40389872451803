import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNotification, useUser } from "@app/providers";
import {
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import {
  Company,
  NdsShort,
  Vehicle,
  VehicleRequest,
  VehicleRequestOffer,
} from "@app/models";
import {
  APIResponse,
  getCompanies,
  getMyCompanies,
  getVehicles,
  updateVehicleRequestOffer,
} from "@app/api";
import { getAxiosErrorMessage, isDispatcher } from "@app/helpers";
import { AxiosError } from "axios";

interface Props {
  request: VehicleRequest;
  offer: VehicleRequestOffer;
  onSuccess: () => void;
}

interface Data {
  organization: SelectOption<Company> | null;
  vehicle: SelectOption<any> | null;
  price: string;
  phoneNumber: string;
  email: string;
  comment: string;
  files: File[];
}

const schema = yup.object().shape({
  organization: yup.object().nullable().required("required"),
  vehicle: yup.object().nullable().required("required"),
  price: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().required(),
  comment: yup.string().required(),
});

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

function VehicleRequestOfferEdit(props: Props) {
  const { request, onSuccess, offer } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [companies, setCompanies] = useState<SelectOption<any>[]>([]);
  const [vehicles, setVehicles] = useState<SelectOption<any>[]>([]);
  const [nds, setNds] = useState<NdsShort>();
  const { pending, onChange, values, errors, validate, setPending } =
    useForm<Data>({
      values: {
        organization: !!offer.company
          ? {
              label: offer.company.name,
              value: offer.company.id,
              item: offer.company,
            }
          : null,
        vehicle: !!offer.vehicle
          ? {
              label:
                offer.vehicle.name ||
                `${request.vehicleType.name} ${offer.vehicle.govNumber}`,
              value: offer.vehicle.id,
            }
          : null,
        price: offer.price?.toString() || "",
        phoneNumber: "",
        email: "",
        comment: request.comments || "",
        files: [],
      },
      schema,
    });

  const onCompanyChange = useCallback(
    (value: SelectOption<Company> | null, name: any) => {
      onChange(value, name);
      setNds(value?.item?.nds);
    },
    [onChange]
  );

  const updateNds = useCallback(
    (companies: Company[]) => {
      if (offer.company.id) {
        const findCompany = companies.find(
          (item) => item.id === offer.company.id
        );
        setNds(findCompany?.nds);
      }
    },
    [offer.company]
  );

  const loadCompanies = useCallback(
    async (params: any) => {
      try {
        let response;
        if (isDispatcher(user!.role)) {
          response = await getCompanies(params);
        } else {
          response = await getMyCompanies(params);
        }

        const formattedData = response.data.map((company: any) => {
          return {
            value: company.id,
            label:
              `${company.name} ${company.type?.name ?? ""}` +
              ` ${company.bin ? `(${company.bin})` : ""}`,
            item: company,
          };
        });

        updateNds(response.data);

        setCompanies(formattedData);
        setPending(false);
      } catch (error) {
        console.error("Ошибка при загрузке списка компаний: ", error);
        setPending(false);
      }
    },
    [setPending, updateNds, user]
  );

  const loadVehicles = useCallback(
    async (params: any) => {
      try {
        const response = await getVehicles({
          ...params,
          companyId: values.organization!.value,
        });

        const formattedData = response.data.map((vehicle: any) => {
          return {
            value: vehicle.id,
            label: `${vehicle.name}`,
          };
        });

        setVehicles(formattedData);
        setPending(false);
      } catch (error) {
        console.error("Ошибка при загрузке списка компаний: ", error);
        setPending(false);
      }
    },
    [setPending, values.organization]
  );

  useEffect(() => {
    (async () => {
      await loadCompanies(initialParams);
      // await loadMyCompanies(initialParams);
    })();
  }, [loadCompanies]);

  useEffect(() => {
    if (values.organization) {
      loadVehicles(initialParams);
    }
  }, [values.organization, loadVehicles]);

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      if (!nds) {
        showNotification({
          message: "Отсутствует НДС компании",
          variant: "error",
        });
        return;
      }

      const response = await updateVehicleRequestOffer({
        id: offer.id,
        requestId: request.id,
        companyId: values.organization!.value,
        vehicleId: values.vehicle!.value,
        price: Number(values.price),
        phone: values.phoneNumber,
        email: values.email,
        comments: values.comment,
        ndsId: nds.id,
        files: [],
      });

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Ценовое предложение на технику отредактировано",
        variant: "success",
      });

      onSuccess();

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [
    validate,
    setPending,
    nds,
    offer.id,
    request.id,
    values.organization,
    values.vehicle,
    values.price,
    values.phoneNumber,
    values.email,
    values.comment,
    showNotification,
    onSuccess,
  ]);

  const vehicleLabelKeys = useMemo<(keyof Vehicle)[]>(
    () => ["vehicleType", "govNumber"],
    []
  );

  return (
    <Form
      onSubmit={onSubmitForm}
      pending={pending}
      dense={false}
      submitLabel={"Редактировать"}
      disabled={!nds?.name}
    >
      <Grid>
        <Select<Company>
          label="Организация"
          name="organization"
          onChange={onCompanyChange}
          value={values.organization}
          error={!!errors.organization}
          helperText={errors.organization}
          valueKey="id"
          labelKey="name"
          options={companies}
        />
        <Select<Vehicle>
          label="Техника"
          name="vehicle"
          onChange={onChange}
          value={values.vehicle}
          error={!!errors.vehicle}
          helperText={errors.vehicle}
          valueKey="id"
          labelKeys={vehicleLabelKeys}
          options={vehicles}
          disabled={!values.organization}
        />
        <Grid columns={2}>
          <TextField
            label="Цена"
            placeholder="Введите цену"
            name="price"
            priceFormat={true}
            onChange={onChange}
            value={values.price}
            error={!!errors.price}
            helperText={errors.price}
          />
          <TextField
            label="Ставка НДС"
            value={nds?.name || "—"}
            disabled={true}
          />
        </Grid>
        <Grid columns={2}>
          <TextField
            label="Номер телефона"
            placeholder="Введите номер телефона"
            name="phoneNumber"
            onChange={onChange}
            value={values.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
          <TextField
            label="Email"
            placeholder="Введите Email"
            name="email"
            onChange={onChange}
            value={values.email}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <TextField
          label="Комментарий"
          placeholder="Введите текст"
          name="comment"
          onChange={onChange}
          value={values.comment}
          error={!!errors.comment}
          helperText={errors.comment}
          multiline={true}
        />
        {/*<FilesPicker*/}
        {/*  files={values.files}*/}
        {/*  onChange={onChangeFiles}*/}
        {/*  horizontal={false}*/}
        {/*/>*/}
      </Grid>
    </Form>
  );
}

export default VehicleRequestOfferEdit;
