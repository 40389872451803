import React, { CSSProperties, memo, PropsWithChildren } from "react";
import styled from "styled-components";

interface GridWrapperProps {
  gap?: number;
  columns?: number;
  auto?: boolean;
  style?: CSSProperties;
  center?: boolean;
}

interface Props extends PropsWithChildren, GridWrapperProps {}

export const GridStyled = styled.div<GridWrapperProps>`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: ${(props) => `${props.gap}px`};
  grid-template-columns: ${(props) =>
    `repeat(${props.columns}, ${props.auto ? "max-content" : "1fr"})`};
  justify-content: ${(props) => (props.center ? "center" : "initial")};
`;

function Grid(props: Props) {
  const {
    style = {},
    gap = 16,
    columns = 1,
    auto = false,
    center = false,
    children,
  } = props;

  return (
    <GridStyled
      style={style}
      gap={gap}
      columns={columns}
      auto={auto}
      center={center}
    >
      {children}
    </GridStyled>
  );
}

export default memo(Grid);
