import { Grid, Info } from "@app/components";
import React, { FC, memo } from "react";
import { CompanyInfo } from "@app/models";
import { StyledGrid } from "./styled";
import styled from "styled-components";
import { theme } from "styled-tools";

type Props = {
  company: CompanyInfo | null;
};

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${theme("color.grayLight")};
  margin-bottom: 16px;
`;

const BankAccountView: FC<Props> = (props) => {
  const { company } = props;

  if (!company) {
    return null;
  }

  return (
    <StyledGrid>
      {company.bankAccounts.map((item, index) => (
        <Grid key={index.toString()}>
          {item.isMain && <b>Основной</b>}
          <Info label="Наименование банка" value={item.bank.name} />
          <Info label="БИК" value={item.bank.bik} />
          <Info label="Номер счета" value={item.accountNumber} />
          <StyledDivider />
        </Grid>
      ))}
    </StyledGrid>
  );
};

export default memo(BankAccountView);
