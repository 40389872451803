import { Button, Checkbox, Chip, Modal, ModalProps } from "@app/components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Act, ActStatus, ActStatusName } from "@app/models";
import { getActById, getActs, PageableParams } from "@app/api";
import { TemplateSearch } from "@app/templates";
import { priceFormat } from "@app/helpers";
import { format } from "date-fns";
import styled from "styled-components";

interface Props extends Omit<ModalProps, "title"> {
  setAct: (act: Act) => void;
}

const StyledRoot = styled.div`
  display: grid;
  grid-template-rows: calc(100% - 64px) 64px;
  max-height: 100%;
  overflow: hidden;
`;

const StyledContent = styled.div`
  overflow-y: auto;
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  box-sizing: border-box;
  height: 48px;
`;

function ModalActChoose(props: Props) {
  const { open, onClose, setAct, ...modalProps } = props;
  const [pending, setPending] = useState<boolean>(false);
  const [selectedActId, setSelectedActId] = useState<string>("");

  const tableLabels = useMemo(
    () => [
      "",
      "№  акта",
      "Дата создания",
      "Организация",
      "Проект",
      "Контрагент",
      "Период",
      "Сумма",
      "Статус",
    ],
    []
  );

  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const onClickItem = useCallback((item: Act) => {
    setSelectedActId(item.id);
  }, []);

  const onClickChoose = useCallback(async () => {
    try {
      setPending(true);

      const response = await getActById(selectedActId);

      setAct(response.data);
      setPending(false);
      onClose();
    } catch (e) {
      setPending(false);
      onClose();
    }
  }, [setAct, selectedActId, onClose]);

  const mapDataTable = useCallback(
    (item: Act) => {
      return [
        <Checkbox type="radio" checked={item.id === selectedActId} />,
        item.actNumber,
        format(new Date(item.createdDate), "dd.MM.yyyy"),
        item.company.name,
        item.project.name,
        item.partner.name,
        `${format(new Date(item.startWorkDate), "dd.MM.yyyy")}-${format(
          new Date(item.endWorkDate),
          "dd.MM.yyyy"
        )}`,
        priceFormat(item.sum.toString()),
        <Chip text={ActStatusName[item.status]} />,
      ];
    },
    [selectedActId]
  );

  const getData = useCallback((params: PageableParams) => {
    return getActs({
      ...params,
      statuses: [
        ActStatus.Agreed,
        ActStatus.ActSigningRejected,
        ActStatus.ActSigningOverdue,
        ActStatus.AvrRevoked,
        ActStatus.AvrDeleted,
      ],
    });
  }, []);

  useEffect(() => {
    if (!open) {
      setSelectedActId("");
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      title="Выбор табеля оказанных услуг"
      open={open}
      onClose={onCloseModal}
      size="xlarge"
      dense={true}
    >
      <StyledRoot>
        <StyledContent>
          <TemplateSearch<Act>
            toolbarVisible={false}
            getData={getData}
            onClick={onClickItem}
            mapTableData={mapDataTable}
            tableLabels={tableLabels}
          />
        </StyledContent>
        <StyledFooter>
          <Button text="Выбрать" onClick={onClickChoose} />
        </StyledFooter>
      </StyledRoot>
    </Modal>
  );
}

export default ModalActChoose;
