import React, { useCallback, useMemo, useState } from "react";
import { Info, Modal } from "@app/components";
import { FormGEOFence, FormGpsGEOFences } from "@app/forms";
import styled from "styled-components";
import { theme } from "styled-tools";
import { useMonitoring } from "@app/providers";

interface Props {
  onClose: (needUpdate?: boolean) => void;
  title: string;
  open: boolean;
  // geoFence: GEOFence | null;
  // updateGEOFence: (data: GEOFence) => void;
}

const StyledModal = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 420px;
  height: 100%;
  background-color: ${theme("color.white")};
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${theme("color.grayLight")};
`;

const StyledHeader = styled.div`
  background-color: #f5f6f8;
  padding: 16px 24px;
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  flex-grow: 0;
  flex-shrink: 0;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    border-top: 1px solid ${theme("color.grayLight")};
    padding-top: 16px;
  }
`;

const StyledTitle = styled.p`
  font-size: 24px;
  color: ${theme("color.dark")};
  margin: 0;
  font-weight: 600;
`;

const StyledContent = styled.div`
  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  flex-shrink: 1;
`;

export default function (props: Props) {
  const { open, title, onClose } = props;
  const { geoFenceDetail, latLngList, setGpsGEOFences } = useMonitoring();
  const [gpsModalVisible, setGPSModalVisible] = useState<boolean>(false);

  const hideGpsModal = useCallback(
    (needReset = true) => {
      setGPSModalVisible(false);
      if (needReset) {
        setGpsGEOFences([]);
      }
    },
    [setGpsGEOFences]
  );

  const onSaveGpsGeofences = useCallback(() => {
    hideGpsModal(false);
  }, [hideGpsModal]);

  const onClickAddGps = useCallback(() => {
    setGPSModalVisible(true);
  }, []);

  const onModalClose = useCallback(
    (val?: boolean) => {
      onClose(val);
    },
    [onClose]
  );

  const area = useMemo(() => {
    if (latLngList.length >= 3) {
      const polygonArea =
        google.maps.geometry?.spherical.computeArea(latLngList);
      return (polygonArea / 10000).toFixed(2).toString();
    }

    return "—";
  }, [latLngList]);

  const perimeter = useMemo(() => {
    if (latLngList.length >= 3) {
      const polygonPerimeter =
        google.maps.geometry?.spherical.computeLength(latLngList);

      return (polygonPerimeter / 1000).toFixed(2).toString();
    }

    return "—";
  }, [latLngList]);

  if (!open) {
    return null;
  }

  return (
    <>
      <StyledModal>
        <StyledHeader>
          <StyledTitle>{title}</StyledTitle>
          <div>
            <Info label="Площадь, га" value={area} variant="secondary" />
            <Info label="Периметр, км" value={perimeter} variant="secondary" />
          </div>
        </StyledHeader>
        <StyledContent>
          <FormGEOFence onClickAddGps={onClickAddGps} onClose={onModalClose} />
        </StyledContent>
      </StyledModal>
      {gpsModalVisible && (
        <Modal
          position="center"
          title={geoFenceDetail?.project?.name || ""}
          open={gpsModalVisible}
          onClose={hideGpsModal}
        >
          <FormGpsGEOFences onSave={onSaveGpsGeofences} />
        </Modal>
      )}
    </>
  );
}
