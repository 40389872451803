import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import IconOutlookSrc from "./outlook.svg";
import IconSAPSrc from "./sap.svg";
import Icon1CSrc from "./1c.svg";
import IconWhatsAppSrc from "./whats-app.svg";
import { Container } from "@app/components";
import SectionTitle from "../SectionTitle";

const StyledSection = styled.section`
  background-color: ${theme("color.light")};
  padding: 40px 0;
  box-sizing: border-box;
  border-radius: 12px;
`;

const StyledTitle = styled(SectionTitle)`
  text-align: center;
  margin: 0 0 32px 0;
`;

const StyledText = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: ${theme("color.gray")};
  margin: 0 0 60px 0;
`;

const StyledGrid = styled.div`
  display: flex;
  gap: 32px;
  justify-content: center;

  @media only screen and (max-width: 576px) {
    width: 184px;
    flex-wrap: wrap;
    gap: 32px;
    margin: 0 auto;
  }
`;

const StyledBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  width: 76px;
  background-color: ${theme("color.white")};
  box-shadow: 2px 4px 12px rgba(73, 74, 71, 0.12);
  border-radius: 6px;
`;

function SectionIntegration() {
  return (
    <Container>
      <StyledSection id="section-integrations">
        <StyledTitle>
          интегрируйтесь и используйте <br />
          DISPATCH на максимум
        </StyledTitle>
        <StyledText>
          Мы разработали открытые API для застройщиков, что позволит подключить
          к Dispatch свои <br /> IT системы и получать данные
        </StyledText>
        <StyledGrid>
          <StyledBox>
            <img src={IconOutlookSrc} alt="" />
          </StyledBox>
          <StyledBox>
            <img src={IconSAPSrc} alt="" />
          </StyledBox>
          <StyledBox>
            <img src={Icon1CSrc} alt="" />
          </StyledBox>
          <StyledBox>
            <img src={IconWhatsAppSrc} alt="" />
          </StyledBox>
        </StyledGrid>
      </StyledSection>
    </Container>
  );
}

export default memo(SectionIntegration);
