import { useCallback, useMemo, useState } from "react";
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
  subYears,
} from "date-fns";

interface UseCalendarProps {
  date: Date | null;
}

export function useCalendar(props: UseCalendarProps) {
  const [currentMonth, setCurrentMonth] = useState<Date>(
    props.date || new Date()
  );

  const currentDate = useMemo<Date>(() => new Date(), []);

  const daysOfWeek = useMemo<string[]>(() => {
    const days = [];
    const startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(format(addDays(startDate, i), "eee"));
    }

    return days;
  }, [currentMonth]);

  const data = useMemo(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
    const dateFormat = "d";

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push({
          day: day,
          formattedDate: format(day, dateFormat),
          sameDay: isSameDay(day, currentDate),
          sameMonth: isSameMonth(day, monthStart),
        });
        day = addDays(day, 1);
      }

      rows.push(days);

      days = [];
    }
    return rows;
  }, [currentDate, currentMonth]);

  const nextMonth = useCallback(() => {
    setCurrentMonth((prevCurrentMonth) => addMonths(prevCurrentMonth, 1));
  }, []);

  const prevMonth = useCallback(() => {
    setCurrentMonth((prevCurrentMonth) => subMonths(prevCurrentMonth, 1));
  }, []);

  const nextYear = useCallback(() => {
    setCurrentMonth((prevCurrentMonth) => addYears(prevCurrentMonth, 1));
  }, []);

  const prevYear = useCallback(() => {
    setCurrentMonth((prevCurrentMonth) => subYears(prevCurrentMonth, 1));
  }, []);

  return {
    currentMonth,
    nextMonth,
    prevMonth,
    nextYear,
    prevYear,
    daysOfWeek,
    data,
    setCurrentMonth,
  };
}
