import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { PageableParams, PageableResponse } from "@app/api";
import styled from "styled-components";
import { IconClose24, IconSearch24 } from "@app/icons";
import { theme } from "styled-tools";
import { genericMemo } from "@app/helpers";
import { Loader } from "@app/components";

interface Props<T> {
  getData: (params: PageableParams) => Promise<PageableResponse<T>>;
  renderItem: (item: T) => ReactNode;
}

const StyledSearch = styled.div`
  height: 40px;
  background: ${theme("color.white")};
  border-radius: 4px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto 1fr auto;
  padding: 8px 12px;
  box-sizing: border-box;
  border: 1px solid ${theme("color.dark")};
`;

const StyledSearchInput = styled.input`
  height: 24px;
  background-color: transparent;
  outline: none;
  border: none;
  font-family: ${theme("fontFamily")};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const StyledSearchClearButton = styled.button`
  padding: 0;
  margin: 0;
  height: 24px;
  width: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
`;

const StyledCompanyList = styled.div`
  display: grid;
  grid-gap: 0;

  > * + * {
    border-top: 1px solid ${theme("color.grayLight")};
  }
`;

const StyledErrorText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #da616c;
  display: block;
  margin-top: 4px;
`;

function Search<T extends { id: string }>(props: Props<T>) {
  const { getData, renderItem } = props;
  const [pending, setPending] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [error, setError] = useState<string | null>(null);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
      if (e.target.value.length === 12) {
        setError(null);
      }
    },
    []
  );

  const onClickClear = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setSearchText("");
    setError(null);
    setSearchResults([]);
  }, []);

  const onKeyPress = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        if (searchText.length !== 12) {
          setError("Введите 12-значный БИН");
          return;
        }
        try {
          setPending(true);
          const response = await getData({
            pageNumber: 1,
            pageSize: 20,
            searchText,
          });

          if (response.succeeded) {
            setSearchResults(response.data);
          }

          setPending(false);
        } catch (e) {
          setError("Ошибка при получении данных");
          setPending(false);
        }
      }
    },
    [getData, searchText]
  );

  const hasSearchResult = useMemo(
    () => searchResults.length > 0,
    [searchResults]
  );

  return (
    <>
      <StyledSearch>
        <IconSearch24 />
        <StyledSearchInput
          value={searchText}
          onChange={onChangeInput}
          placeholder={"БИН организации"}
          disabled={pending}
          onKeyPress={onKeyPress}
          type="number"
        />
        {!!searchText && (
          <StyledSearchClearButton onClick={onClickClear}>
            <IconClose24 />
          </StyledSearchClearButton>
        )}
      </StyledSearch>
      {error && <StyledErrorText>{error}</StyledErrorText>}
      {pending && <Loader size="small" />}
      {!pending && hasSearchResult && (
        <StyledCompanyList>
          {searchResults.map((result) => (
            <Fragment key={result.id}>{renderItem(result)}</Fragment>
          ))}
        </StyledCompanyList>
      )}
    </>
  );
}

export default genericMemo(Search);
