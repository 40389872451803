import { useEffect, useState } from "react";

const useCountdown = (targetDate: string) => {
  const [countDownDate, setCountDownDate] = useState(
    new Date(+targetDate).getTime()
  );
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = countDownDate - new Date().getTime();
      setCountDown(diff);
      if (diff < 0) {
        clearInterval(interval);
      }
    }, 200);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return {
    timeStr: getReturnValues(countDown),
    isExpired: countDown < 0,
    setCountDownDate,
  };
};

const formatStr = (val: number) => {
  return val.toString().padStart(2, "0");
};

const getReturnValues = (countDown: number) => {
  if (countDown < 1) {
    return "00:00";
  }

  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return `${formatStr(minutes)}:${formatStr(seconds)}`;
};

export { useCountdown };
