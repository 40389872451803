import { memo, ReactNode } from "react";
import styled from "styled-components";

interface ContainerProps {
  reverse?: boolean;
}

interface ListItemProps extends ContainerProps {
  title: string | ReactNode | undefined | null;
  subtitle: string | undefined | null;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  justify-content: ${(props) => (props.reverse ? "flex-end" : "flex-start")};
`;

const PrimaryText = styled.p`
  color: #1c2246;
  font-weight: 500;
`;

const SecondaryText = styled.p`
  color: #acacac;
`;

const ListItem = (props: ListItemProps) => {
  const { reverse } = props;
  return (
    <Container reverse={reverse}>
      <PrimaryText>{props.title || "-"}</PrimaryText>
      <SecondaryText>{props.subtitle || "-"}</SecondaryText>
    </Container>
  );
};

export default memo(ListItem);
