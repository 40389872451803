import React, { useCallback, useState } from "react";
import { TextField, TextFieldProps } from "@app/components";
import { ModalActCompanyAddress } from "@app/modals";

interface Props extends TextFieldProps {
  companyId: string;
}

function ActCompanyAddress(props: Props) {
  const { companyId, onChange, ...textFieldProps } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const onClickShowModal = useCallback(() => {
    if (textFieldProps.disabled) {
      return;
    }

    setModalVisible(true);
  }, [textFieldProps.disabled]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const onSubmitAddress = useCallback(
    (address: string) => {
      if (onChange) {
        onChange(address, textFieldProps.name);
      }

      setModalVisible(false);
    },
    [onChange, textFieldProps.name]
  );

  return (
    <>
      <TextField
        {...textFieldProps}
        readOnly={true}
        onClick={onClickShowModal}
      />
      <ModalActCompanyAddress
        address={textFieldProps.value || ""}
        open={modalVisible}
        title={textFieldProps.label}
        onClose={hideModal}
        onSubmit={onSubmitAddress}
        companyId={companyId}
      />
    </>
  );
}

export default ActCompanyAddress;
