import React, { memo } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import styled, { css } from "styled-components";
import BGSrc from "./bg.png";
import ImageContract from "./smart-contract.png";
import content from "./content.json";
import { Grid } from "@app/components";
import { switchProp } from "styled-tools";

interface Props {
  variant?: "default" | "secondary";
}

const StyledPanelSmartContract = styled(StyledPanel)<Pick<Props, "variant">>`
  @media only screen and (max-width: 576px) {
    background: none;
  }
  background-image: url(${BGSrc});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 90%;

  ${switchProp("variant", {
    default: css`
      grid-column-start: 1;
      grid-column-end: 4;
      height: 320px;

      @media only screen and (max-width: 576px) {
        height: auto;
      }
    `,
    secondary: css`
      grid-column-start: 5;
      grid-column-end: 7;
      height: 272px;

      @media only screen and (max-width: 576px) {
        height: 180px;
      }
    `,
  })}
`;

function PanelSmartContract(props: Props) {
  const { variant = "default" } = props;

  return (
    <StyledPanelSmartContract variant={variant}>
      <Grid gap={12}>
        <StyledPanelTitle>{content.title}</StyledPanelTitle>
        <StyledPanelText>{content.text}</StyledPanelText>
        <StyledPanelImageMobile src={ImageContract} width={189} alt="" />
      </Grid>
    </StyledPanelSmartContract>
  );
}

export default memo(PanelSmartContract);
