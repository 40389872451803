import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import { Modal, ModalProps } from "@app/components";
import styled from "styled-components";
import FileComponent from "../../components/File/File";
import { VehicleJournalDetail } from "@app/models";

interface Props extends Omit<ModalProps, "title" | "onClose"> {
  data: VehicleJournalDetail;
  files: File[];
  onClose: (files: File[]) => void;
}

const StyledDropZone = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  background: #ffffff;
  border: 1px dashed #d8d8d8;
  border-radius: 4px;

  input {
    display: none;
  }
`;

const StyledDropZoneTitle = styled.div`
  color: #636463;

  .link {
    color: #4f8bff;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;
const StyledDropZoneCaption = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #acacac;
`;

function ModalVehicleJournalFiles(props: Props) {
  const { data, onClose, ...modalProps } = props;

  const [files, setFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const modalTitle = useMemo(() => "Документы", []);

  const actions = useMemo(() => [], []);

  const onClick = useCallback(() => {
    inputRef.current!.click();
  }, []);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) {
        return;
      }
      setFiles([...files, ...fileList]);

      e.target.value = "";
    },
    [files]
  );

  const onFileDelete = useCallback(
    (index: number) => {
      setFiles(files.filter((file, fileIndex) => fileIndex !== index));
    },
    [files]
  );

  if (!modalProps.open) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      title={modalTitle}
      actions={actions}
      size="small"
      onClose={() => onClose(files)}
    >
      <StyledDropZone>
        <StyledDropZoneTitle>
          <span className="link" onClick={onClick}>
            Загрузите
          </span>{" "}
          документ или перетащите его сюда
        </StyledDropZoneTitle>
        <StyledDropZoneCaption>
          Форматы: doc, docx, pdf, png, jpeg; Максимальный размер: 3 мб
        </StyledDropZoneCaption>
        <input
          type="file"
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
          ref={inputRef}
          onChange={onChangeInput}
          multiple={false}
          style={{}}
        />
        {files.map((file, fileIndex) => (
          <FileComponent
            key={fileIndex.toString()}
            file={file}
            fileIndex={fileIndex}
            onDelete={onFileDelete}
          />
        ))}
      </StyledDropZone>
    </Modal>
  );
}

export default memo(ModalVehicleJournalFiles);
