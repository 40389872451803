import instance from "./instance";
import { PageableParams, PageableResponse } from "./interfaces";
import { Adhesion } from "@app/models";

export function getAdhesionContracts(
  params: PageableParams & {
    CompanyId?: string;
    StatusIds?: number;
  }
): Promise<PageableResponse<Adhesion>> {
  return instance()
    .get("/adhesionContract/list", {
      params,
    })
    .then((response) => response.data);
}
