import { CompanyType } from "./Company";

export interface User {
  id: string;
  fullName: string;
  email: string;
  role: UserRoles;
}

export interface UserSimple {
  id: string;
  userName: string | null;
  email: string | null;
  phoneNumber: string | null;
  accessFailedCount: number;
  lastname: string | null;
  firstname: string | null;
  middlename: string | null;
  iin: string | null;
  isDelete: boolean;
}

export interface Confirmer {
  id: string;
  firstname: string;
  lastname: string;
  middlename: string | null;
  phone: string | null;
}

export interface Employee {
  isOwner: boolean;
  user: Exclude<Confirmer, "phone">;
}

export interface EmployeeComponentProps extends Employee {
  onDelete: (userId: string) => void;
}

export interface ShortEmployee {
  company: CompanyType;
  user: Confirmer;
}

export enum UserRoles {
  SUPER_ADMIN = "SUPERADMIN",
  ADMIN = "ADMIN",
  DISPATCHER = "DISPATCHER",
  PARTNER = "PARTNER",
  USER = "CUSTOMER",
}

export interface RegistrationCertificate {
  date: string;
  number: string;
  series: string;
  id: string;
  isDeleted: boolean;
}

export enum EmploymentTypeId {
  UNKNOWN1 = 1,
  UNKNOWN2 = 2,
  REGISTRATION_CERTIFICATE = 3,
}

export interface EmploymentType {
  id: EmploymentTypeId;
  name: string;
}

export interface UserCompany {
  user: UserSimple;
  employmentType: EmploymentType;
  powerOfAttorney: unknown | null;
  registrationCertificate: RegistrationCertificate;
  companyId: string;
  userId: string;
  employmentTypeId: number;
  accessLevel: number;
  isSigner: boolean;
  id: string;
  isDeleted: boolean;
}
