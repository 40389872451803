import instance from "./instance";
import {
  Act,
  ActCreateDTO,
  ActHistoryItemDTO,
  ActNotificationRecipientsDto,
  ActStatus,
  ActVehicles,
  AddConstructiveDTO,
  ConstructiveResponse,
  GetPayDataDTO,
  VehicleJournalForActV2,
} from "@app/models";
import { APIResponse, PageableParams, PageableResponse } from "./interfaces";

export function getActs(
  params: PageableParams & {
    statuses?: ActStatus[];
  }
): Promise<PageableResponse<Act>> {
  return instance()
    .get("/act", {
      params,
    })
    .then((response) => response.data);
}

export function getActById(id: string): Promise<APIResponse<Act>> {
  return instance()
    .get(`/act/${id}`)
    .then((response) => response.data);
}

export function createAct(body: ActCreateDTO): Promise<APIResponse<Act>> {
  return instance()
    .post("/act", body)
    .then((response) => response.data);
}

export function editAct(body: ActCreateDTO): Promise<APIResponse<Act>> {
  return instance()
    .put("/act", body)
    .then((response) => response.data);
}

export function deleteAct(id: string): Promise<APIResponse<boolean>> {
  return instance()
    .delete(`/act/${id}`)
    .then((response) => response.data);
}

export function sendActToApprove(
  body: ActCreateDTO
): Promise<APIResponse<boolean>> {
  return instance()
    .post("/act/send-approval", body)
    .then((response) => response.data);
}

export function createAOSPClosingRequest(body: unknown): Promise<APIResponse> {
  return Promise.resolve({
    data: null,
    errors: null,
    message: "Нет API для создания",
    succeeded: false,
  });
}

export function getPayData(body: GetPayDataDTO): Promise<APIResponse<boolean>> {
  return instance()
    .put("/act/to-pay", body)
    .then((response) => response.data);
}

export function getVehiclesByActId(
  actId: number | string
): Promise<APIResponse<ActVehicles[]>> {
  return instance()
    .get(`/act/${actId}/vehicles`)
    .then((response) => response.data);
}

export function getVehiclesByActIdV2(
  actId: number | string,
  forAvr = false
): Promise<APIResponse<VehicleJournalForActV2[]>> {
  return instance()
    .get(`/act/${actId}/v2-vehicles`, {
      params: {
        forAvr,
      },
    })
    .then((response) => response.data);
}

export function addConstructiveToAct(
  data: AddConstructiveDTO
): Promise<APIResponse<ConstructiveResponse[]>> {
  return instance()
    .post(`/act/add/constructives`, data)
    .then((response) => response.data);
}

export function getActConstructives(
  actId: number | string
): Promise<APIResponse<ActVehicles[]>> {
  return instance()
    .get(`/act/${actId}/constructives`)
    .then((response) => response.data);
}

export function revokeAct(
  id: Act["id"],
  comment: string
): Promise<APIResponse<Act>> {
  return instance()
    .put(`/act/revoke`, { id, comment })
    .then((response) => response.data);
}

export function getActHistory(
  actId: Act["id"]
): Promise<APIResponse<ActHistoryItemDTO[]>> {
  return instance()
    .get(`/act/${actId}/history`)
    .then((response) => response.data);
}

export function setActNotificationRecipients(
  data: ActNotificationRecipientsDto
): Promise<APIResponse<boolean>> {
  return instance()
    .post(`/act/notificationRecipients`, data)
    .then((response) => response.data);
}
