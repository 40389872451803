import React, { memo } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import styled from "styled-components";
import BGSrc from "./bg.png";
import content from "./content.json";
import { Grid } from "@app/components";

const StyledPanelVehiclePrice = styled(StyledPanel)`
  @media only screen and (max-width: 576px) {
    background: none;
  }
  grid-column-start: 3;
  grid-column-end: 7;
  background-image: url(${BGSrc});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
`;

function PanelVehiclePrice() {
  return (
    <StyledPanelVehiclePrice>
      <Grid gap={12}>
        <StyledPanelTitle>{content.title}</StyledPanelTitle>
        <StyledPanelText>{content.text}</StyledPanelText>
        <StyledPanelImageMobile src={BGSrc} width={"100%"} alt="" />
      </Grid>
    </StyledPanelVehiclePrice>
  );
}

export default memo(PanelVehiclePrice);
