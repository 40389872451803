import { PageableParams, PageableResponse } from "./interfaces";
import { Region } from "@app/models";
import instance from "./instance";

export function getRegions(
  params: PageableParams<Region>
): Promise<PageableResponse<Region>> {
  return instance()
    .get("/region", {
      params,
    })
    .then((response) => response.data);
}
