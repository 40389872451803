import React, {
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import { Tabs } from "@app/components";
import { useLocation, useNavigate } from "react-router-dom";

export type TabRoute = {
  label: string;
  path: string;
  badge?: string | number;
};

interface Props extends PropsWithChildren {
  routes: TabRoute[];
  controls?: ReactNode[];
}

const TabRouterWrapper = styled.div`
  height: 100%;
`;

const TabRouterContent = styled.div`
  height: calc(100% - 56px);
`;

function TabRouter(props: Props) {
  const { routes, controls, children } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeTab = useCallback(
    (index: number) => {
      navigate(routes[index].path);
    },
    [navigate, routes]
  );

  const tabLabels = useMemo(() => routes.map((route) => route.label), [routes]);
  const tabBadges = useMemo(() => routes.map((route) => route.badge), [routes]);

  const initialTabIndex = useMemo(
    () =>
      routes.findIndex(
        (route) => location.pathname.indexOf(route.path.replace("*", "")) > -1
      ),
    [routes, location]
  );

  return (
    <TabRouterWrapper>
      <Tabs
        initialTabIndex={initialTabIndex}
        labels={tabLabels}
        badges={tabBadges}
        onChange={onChangeTab}
        controls={controls}
      />
      <TabRouterContent>{children}</TabRouterContent>
    </TabRouterWrapper>
  );
}

export default memo(TabRouter);
