import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { IconLogo, IconPersonCircle32, IconPhone24 } from "@app/icons";
import { Link, useNavigate } from "react-router-dom";
import { StyledContainer } from "@app/components";

interface Props {}

const StyledHeader = styled.header`
  background-color: ${theme("color.white")};
  border-bottom: 1px solid ${theme("color.grayLight")};
  display: flex;
  align-items: center;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
`;

const StyledHeaderContainer = styled(StyledContainer)`
  display: flex;
  gap: 40px;
  align-items: center;
  width: 100%;
`;

const StyledLogoContainer = styled(Link)`
  width: 90px;
  color: inherit;

  > svg {
    width: 100%;
  }
`;

const StyledHeaderNav = styled.ul`
  display: flex;
  gap: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0;
  margin: 0;
  list-style: none;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const StyledHeaderNavItem = styled.li`
  a {
    font-size: 16px;
    line-height: 16px;
    color: ${theme("color.dark")};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledIconButton = styled.a`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  margin-left: auto;

  > svg {
    color: ${theme("color.orange")};
  }

  span {
    font-size: 16px;
    line-height: 16px;
    color: ${theme("color.dark")};
    white-space: nowrap;

    @media only screen and (max-width: 576px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media only screen and (max-width: 576px) {
    .btn-title {
      display: none;
    }
  }
`;

const StyledIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(
    86.19deg,
    ${theme("color.orange")} 19.16%,
    ${theme("color.orangeLight")} 78.75%
  );
  border-radius: 12px;

  svg {
    color: ${theme("color.white")};
  }

  @media only screen and (max-width: 576px) {
    width: 32px;
    height: 32px;
  }
`;

function Header(props: Props) {
  const navigate = useNavigate();

  const navItems = useMemo(
    () => [
      {
        label: "Возможности",
        slug: "section-possibilities",
      },
      {
        label: "GPS",
        slug: "section-gps",
      },
      {
        label: "Интеграции",
        slug: "section-integrations",
      },
    ],
    []
  );

  const onClickNavLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const anchorElement = e.target as HTMLElement;
      const scrollToElement = document.getElementById(
        anchorElement.getAttribute("href")!.replace("#", "")
      );

      if (scrollToElement) {
        window.scrollTo({
          top: scrollToElement.offsetTop - 92,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    []
  );

  const onClickSignIn = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();

      navigate("/sign-in");
    },
    [navigate]
  );

  return (
    <StyledHeader>
      <StyledHeaderContainer>
        <StyledLogoContainer to="/">
          <IconLogo />
        </StyledLogoContainer>
        <StyledHeaderNav>
          {navItems.map((navItem, navItemIndex) => (
            <StyledHeaderNavItem key={navItemIndex.toString()}>
              <a href={`#${navItem.slug}`} onClick={onClickNavLink}>
                {navItem.label}
              </a>
            </StyledHeaderNavItem>
          ))}
        </StyledHeaderNav>
        <StyledIconButton href="tel:+77710460533">
          <StyledIconWrapper>
            <IconPhone24 />
          </StyledIconWrapper>
          <span>+7 (771) 046 05 33</span>
        </StyledIconButton>
        <StyledIconButton href="#" onClick={onClickSignIn}>
          <IconPersonCircle32 />
          <span className="btn-title">Войти</span>
        </StyledIconButton>
      </StyledHeaderContainer>
    </StyledHeader>
  );
}

export default memo(Header);
