import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import {
  Button,
  Grid,
  ListItem,
  Modal,
  ModalProps,
  ModalTitle,
} from "@app/components";
import { Adhesion, Task } from "@app/models";
import { ContractTranscriptView, TaskStatusChip } from "@app/common";
import { dateFormat, getAxiosErrorMessage, getFullName } from "@app/helpers";
import styled from "styled-components";
import { theme } from "styled-tools";
import { APIResponse, getFileById, signAdhesionContract } from "@app/api";
import * as ncaLayer from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";

interface Props extends Omit<ModalProps, "title" | "onCLose"> {
  task: Task;
  contract: Adhesion | undefined;
  onClose: (val?: boolean) => void;
}

const StyledInfoBlock = styled.div`
  padding: 16px 24px;
  background-color: ${theme("color.background")};
`;

const StyledContent = styled.div`
  padding: 16px;
`;

const ModalAdhesionTaskDetail = (props: Props) => {
  const { open, task, contract, onClose } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { showNotification } = useNotification();

  const modalTitle = useMemo<ReactNode>(() => {
    return (
      <ModalTitle text={task.name || ""}>
        <TaskStatusChip status={task.status} />
      </ModalTitle>
    );
  }, [task.name, task.status]);

  const blobToFile = useCallback((blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise<string>((res) => {
      reader.onloadend = () => {
        res(reader.result as string);
      };
    });
  }, []);

  const signContract = useCallback(
    async (data: any) => {
      if (data && data.responseObject && task?.id) {
        try {
          setLoading(true);
          const body = {
            taskId: task?.id,
            signedFile: data.responseObject,
          };
          const res = await signAdhesionContract(body);

          setLoading(false);

          if (res && res.succeeded) {
            showNotification({
              message: "Договор подписан успешно",
              variant: "success",
            });
            onClose(true);
          }
        } catch (e) {
          setLoading(false);

          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });
        }
      }
    },
    [onClose, showNotification, task?.id]
  );

  const onSigningStart = useCallback(async () => {
    if (contract?.fileId) {
      const res = await getFileById(contract.fileId);

      if (!ncaLayer.checkWebsocket()) {
        ncaLayer.initializeLayer();
      }

      const base64 = await blobToFile(res);

      if (!base64) {
        console.error("No file");
        return;
      }

      const base64Url = base64.split(",").pop();

      const storageType = {
        type: "PKCS12",
        name: "Компьютер",
      };
      const signType = {
        type: String,
        default: "SIGNATURE",
      };

      const callback = async (data: any) => {
        await signContract(data);
      };

      ncaLayer.createCMSSignatureFromBase64(
        storageType.type,
        signType.default,
        base64Url,
        true,
        callback
      );
    }
  }, [blobToFile, contract?.fileId, signContract]);

  const modalActions = useMemo(
    () => [
      <Button
        text="Подписать"
        disabled={loading}
        showLoader={loading}
        onClick={onSigningStart}
      />,
    ],
    [loading, onSigningStart]
  );

  return (
    <Modal
      open={open}
      title={modalTitle}
      size={"medium"}
      actions={modalActions}
      dense
      onClose={onClose}
    >
      <StyledInfoBlock>
        <Grid columns={5}>
          <ListItem
            subtitle="Инициатор"
            title={getFullName(task.initiator)}
            reverse
          />
          <ListItem
            subtitle="Дата отправки"
            title={
              contract?.created
                ? dateFormat(contract?.created, "dd.MM.yyyy ")
                : "—"
            }
            reverse
          />
        </Grid>
      </StyledInfoBlock>
      <StyledContent>
        {contract && (
          <ContractTranscriptView
            files={[contract.file]}
            pdfFiles={[contract.pdfFile]}
            signedPdfFiles={[]}
          />
        )}
      </StyledContent>
    </Modal>
  );
};

export default memo(ModalAdhesionTaskDetail);
