import React, { memo, useMemo } from "react";
import { TabRoute, TabRouter } from "@app/common";
import { LayoutDefault } from "@app/layouts";
import { Route, Routes } from "react-router-dom";
import { DocumentType, DocumentTypeNames } from "@app/models";
import { ContractDocuments, ActDocuments, AdhesionDocuments } from "./views";

function PageDocuments() {
  const routes: TabRoute[] = useMemo(
    () => [
      {
        label: DocumentTypeNames[DocumentType.Contract],
        path: "/documents/contracts",
      },
      {
        label: DocumentTypeNames[DocumentType.Act],
        path: "/documents/acts",
      },
      {
        label: DocumentTypeNames[DocumentType.Note],
        path: "/documents/notes",
      },
      {
        label: DocumentTypeNames[DocumentType.Adhesion],
        path: "/documents/adhesion",
      },
    ],
    []
  );

  return (
    <LayoutDefault title="Мои документы">
      <TabRouter routes={routes}>
        <Routes>
          <Route path="/contracts" element={<ContractDocuments />} />
          <Route path="/acts" element={<ActDocuments />} />
          <Route path="/notes" element={null} />
          <Route path="/adhesion" element={<AdhesionDocuments />} />
        </Routes>
      </TabRouter>
    </LayoutDefault>
  );
}

export default memo(PageDocuments);
