import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Modal, ModalProps, TextField, useForm } from "@app/components";
import * as yup from "yup";
import "./styles.scss";
import { useNotification } from "@app/providers";
import Cookies from "js-cookie";
import { IconEye24, IconEyeClosed24 } from "@app/icons";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { APIResponse, changePassword, ChangePasswordData } from "@app/api";

interface Props extends Omit<ModalProps, "title"> {
  onClose: (val?: boolean) => void;
}

const ModalPasswordRecovery = (props: Props) => {
  const { onClose, open, ...restProps } = props;
  const { showNotification } = useNotification();
  const [phone, setPhone] = useState(Cookies.get("phone") || "");
  const [hidePassword, setHidePassword] = useState(true);

  const { values, errors, onChange, validate, setPending, pending } =
    useForm<ChangePasswordData>({
      values: {
        password: "",
        confirmPassword: "",
        phone,
      },
      schema: yup.object().shape({
        phone: yup.string().required(),
        password: yup.string().min(8).required(),
        confirmPassword: yup
          .string()
          .required()
          .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
      }),
    });

  useEffect(() => {
    onChange(Cookies.get("phone") || "", "phone");
  }, [onChange, open]);

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      await changePassword({
        password: values.password,
        confirmPassword: values.confirmPassword,
        phone: values.phone.substring(1),
      });

      setPending(false);
      onClose(true);

      showNotification({
        message: "Пароль обновлен",
        variant: "success",
      });
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [validate, setPending, values, onClose, showNotification]);

  const onAppendClick = useCallback(() => {
    setHidePassword((prevState) => !prevState);
  }, []);

  return (
    <Modal
      open={open}
      title={"title"}
      position={"center"}
      size={"xsmall"}
      hideHeader
      onClose={onClose}
      {...restProps}
    >
      <div className="recovery-pwd-modal">
        <p className="recovery-pwd-modal__title">Создайте новый пароль</p>
        <div className="recovery-pwd-modal__form">
          <TextField
            label="Пароль"
            placeholder="Придумайте пароль"
            value={values.password}
            name="password"
            onChange={onChange}
            secureTextEntry={hidePassword}
            error={!!errors.password}
            helperText={errors.password}
            appendIcon={hidePassword ? <IconEye24 /> : <IconEyeClosed24 />}
            onAppendClick={onAppendClick}
          />
          <TextField
            label="Подтвердить пароль"
            placeholder="Повторите пароль"
            value={values.confirmPassword}
            name="confirmPassword"
            onChange={onChange}
            secureTextEntry={hidePassword}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            appendIcon={hidePassword ? <IconEye24 /> : <IconEyeClosed24 />}
            onAppendClick={onAppendClick}
          />
          <Button
            text="Изменить пароль"
            disabled={pending}
            showLoader={pending}
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalPasswordRecovery);
