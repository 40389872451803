import React, { useCallback, useMemo, useState } from "react";
import { TabRoute, TabRouter } from "@app/common";
import { Route, Routes } from "react-router-dom";
import styled, { css } from "styled-components";
import { IconChevronDown24, IconChevronUp24 } from "@app/icons";
import { switchProp, theme } from "styled-tools";
import MonitoringGEOFences from "../GEOFences";
import MonitoringVehicles from "../Vehicles";

interface Props {}

const StyledSidebar = styled.div<{
  visible: boolean;
}>`
  position: absolute;
  left: 16px;
  top: 16px;
  width: 335px;
  background-color: #f4f4f4;
  z-index: 2;
  transition: height 0.1s ease-in;
  zoom: 80%;

  ${switchProp("visible", {
    true: css`
      height: calc(100% - 32px);
    `,
    false: css`
      height: 56px;
      overflow: hidden;
    `,
  })}
`;

const StyledControl = styled.button`
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${theme("color.light")};
  }
`;

export default function (props: Props) {
  const [expanded, setExpanded] = useState(true);

  const onClickHide = useCallback(() => {
    setExpanded(false);
  }, []);

  const onClickExpand = useCallback(() => {
    setExpanded(true);
  }, []);

  const controls = useMemo(() => {
    return [
      <StyledControl onClick={expanded ? onClickHide : onClickExpand}>
        {expanded ? <IconChevronUp24 /> : <IconChevronDown24 />}
      </StyledControl>,
    ];
  }, [expanded, onClickHide, onClickExpand]);

  const routes: TabRoute[] = useMemo(
    () => [
      {
        label: "Техника",
        path: "/monitoring/vehicles",
        element: null,
      },
      {
        label: "Геозоны",
        path: "/monitoring/geozones",
        element: null,
      },
    ],
    []
  );

  return (
    <StyledSidebar visible={expanded}>
      <TabRouter routes={routes} controls={controls}>
        <Routes>
          <Route path="/vehicles" element={<MonitoringVehicles />} />
          <Route path="/geozones" element={<MonitoringGEOFences />} />
        </Routes>
      </TabRouter>
    </StyledSidebar>
  );
}
