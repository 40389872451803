import React, { memo } from "react";
import { Modal, ModalProps } from "@app/components";
import { FormVAERequest } from "@app/forms";

interface Props extends ModalProps {}

function ModalVAERequestCreate(props: Props) {
  const { onClose, ...restProps } = props;

  return (
    <Modal onClose={onClose} {...restProps}>
      <FormVAERequest onSuccess={onClose} />
    </Modal>
  );
}

export default memo(ModalVAERequestCreate);
