import React, { useCallback, useEffect, useMemo, useState } from "react";
import VehicleTrackSrc from "../../../../providers/Monitoring/components/TrackPlayer/vehicle-track.svg";
import { Marker, MarkerProps, OverlayView } from "@react-google-maps/api";
import { VehicleTrack, VehicleTrackPoint } from "@app/models";
import styled from "styled-components";
import { prop, theme } from "styled-tools";
import Panel from "../../../../providers/Monitoring/components/Panel/Panel";
import { Grid, Loader } from "@app/components";
import Info from "../../../../providers/Monitoring/components/Info/Info";
import { toLatLng } from "@app/helpers";
import { MonitoringStatusColors } from "../Vehicles/components/Header/Header";
import { getVehicleTrack } from "@app/api";
import { useGoogleMap, useNotification } from "@app/providers";

interface Props extends Pick<MarkerProps, "clusterer" | "options"> {
  vehicle: VehicleTrack;
  currentDateTime: string;
  trackPoint: VehicleTrackPoint;
  skipLoadingData?: boolean;
  onClick?: (vehicle: VehicleTrack) => void;
}

const StyledMarkerInfo = styled.div`
  border-radius: 12px;
  background-color: ${theme("color.white")};
  padding: 4px 6px;
  box-sizing: border-box;
  width: max-content;
  position: absolute;
  transform: translate(-50%, -100%);
  top: -100%;
  left: 50%;
  margin-top: 30px;
  text-align: center;
`;

const StyledMarkerInfoText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  color: ${theme("color.gray")};
  display: flex;
  align-items: center;
  justify-content: center;

  + * {
    margin-top: 4px;
  }
`;

const StyledVehicleStatus = styled.div<{
  color: string;
}>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${prop("color")};
  margin-left: 4px;
`;

const StyledVehicleInfo = styled.div`
  z-index: 2;
  zoom: 80%;
`;

const StyledLoaderWrapper = styled.div`
  margin-top: -46px;
  margin-left: -24px;
`;

const StyledContent = styled.div`
  padding: 20px;
  box-sizing: border-box;
  background-color: ${theme("color.white")};
  margin-top: 20px;
`;

export default function (props: Props) {
  const {
    vehicle,
    trackPoint,
    onClick,
    currentDateTime,
    options,
    clusterer,
    skipLoadingData,
  } = props;
  const { showNotification } = useNotification();
  const { map } = useGoogleMap();
  const [isHovered, setIsHovered] = useState(false);
  const [pending, setPending] = useState<boolean>(false);

  const handleMarkerMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMarkerMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const onZoomChanged = useCallback(() => {
    if (isHovered) {
      setIsHovered(false);
    }
  }, [isHovered]);

  useEffect(() => {
    const zoomListener = map.addListener("zoom_changed", onZoomChanged);

    return () => {
      zoomListener.remove();
    };
  }, [map, onZoomChanged]);

  const onClickMarker = useCallback(async () => {
    try {
      if (!onClick) {
        return;
      }
      if (skipLoadingData) {
        onClick(vehicle);
        return;
      }

      setPending(true);
      // setVehicle(null);

      const { data, errors } = await getVehicleTrack(vehicle.id, {
        fromTime: Math.floor(vehicle.dateMin!.getTime() / 1000),
        toTime: Math.floor(vehicle.dateMax!.getTime() / 1000),
      });

      setPending(false);

      if (!!errors && errors.length > 0) {
        showNotification({
          variant: "error",
          message:
            typeof errors !== "string"
              ? errors[0]
              : `Ошибка при получении данных от провайдера`,
        });

        setPending(false);
        return;
      }

      const trackPointsLength = (data.points || []).length;

      if (trackPointsLength === 0) {
        showNotification({
          variant: "warning",
          message: `Трек отсутствует!\nНе удалось загрузить историю движения`,
        });

        return;
      }

      onClick({
        ...vehicle,
        points: data.points,
      });
    } catch (e) {
      setPending(false);
    }
  }, [onClick, showNotification, skipLoadingData, vehicle]);

  const title = useMemo(() => {
    if (!vehicle) {
      return "";
    }
    const companiesLength = vehicle.companies.length;

    if (vehicle.companies.length === 0) {
      return "";
    }

    if (companiesLength === 1) {
      return vehicle.companies[0].name;
    }

    return vehicle.companies.reduce(
      (accumulator, currentValue, currentIndex) =>
        `${accumulator} ${currentIndex > 0 ? ", " : ""} ${
          currentValue.name
        }`.trim(),
      ""
    );
  }, [vehicle]);

  if (!trackPoint) {
    return null;
  }

  return (
    <>
      <Marker
        position={toLatLng(trackPoint)}
        options={{ ...options, icon: VehicleTrackSrc }}
        clusterer={clusterer}
        onClick={onClickMarker}
        onMouseOver={handleMarkerMouseEnter}
        onMouseOut={handleMarkerMouseLeave}
      />
      {!clusterer && (
        <OverlayView
          position={toLatLng(trackPoint)}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <StyledMarkerInfo>
            <StyledMarkerInfoText>
              {vehicle.govNumber}
              <StyledVehicleStatus
                color={MonitoringStatusColors[vehicle.gpsDataUpdatedStatusId]}
              />
            </StyledMarkerInfoText>
            <StyledMarkerInfoText>{currentDateTime}</StyledMarkerInfoText>
          </StyledMarkerInfo>
        </OverlayView>
      )}
      {pending && (
        <OverlayView
          position={toLatLng(trackPoint)}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <StyledLoaderWrapper>
            <Loader />
          </StyledLoaderWrapper>
        </OverlayView>
      )}
      {isHovered && (
        <OverlayView
          position={toLatLng(trackPoint)}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <StyledVehicleInfo>
            <Panel
              title={title}
              excerpt={vehicle.govNumber}
              small={true}
              text={`${vehicle.vehicleType} - ${vehicle.characteristics}`}
            >
              <StyledContent>
                <Grid gap={8}>
                  <Info label="Дата:" value={currentDateTime} />
                  <Info label="Провайдер:" value={vehicle.gps || "-"} />
                  <Info label="GPS ID:" value={vehicle.imeigps || "-"} />
                  <Info
                    label="Скорость:"
                    value={`${trackPoint.speed} км/час`}
                  />
                  <Info label="Местоположение:" value="—" />
                </Grid>
              </StyledContent>
            </Panel>
          </StyledVehicleInfo>
        </OverlayView>
      )}
    </>
  );
}
