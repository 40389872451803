import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GPS, GPSGeofence } from "@app/models";
import { getGps, getGpsGeoFences, GPSGeofencesListRequest } from "@app/api";
import { Button, Loader, Select, SelectOption } from "@app/components";
import { IconPlus24 } from "@app/icons";
import { theme } from "styled-tools";
import { useMonitoring } from "@app/providers";

interface Props {
  onSave: () => void;
}

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 400px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const StyledFooter = styled.div`
  margin-top: auto;
  border-top: 1px solid ${theme("color.grayLight")};
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: ${theme("color.grayDark")};
  }
`;

const GpsGEOFence = (props: {
  item: GPSGeofencesListRequest;
  gpsList: GPS[];
  onChangeGps: (data: Partial<GPSGeofencesListRequest>) => void;
}) => {
  const { item, gpsList, onChangeGps: propsOnChangeGps } = props;
  const [pending, setPending] = useState<boolean>(false);
  const [gpsGEOFences, setGpsGEOFences] = useState<GPSGeofence[]>([]);

  const onChangeGps = useCallback(
    (data: SelectOption<GPS> | null) => {
      propsOnChangeGps({
        ...item,
        gpsId: data?.value || "",
      });
    },
    [item, propsOnChangeGps]
  );

  const onChangeGeofence = useCallback(
    (data: SelectOption<GPSGeofence> | null) => {
      propsOnChangeGps({
        ...item,
        gpsGeofenceId: data?.value || "",
        gpsGeofenceName: data?.label || "",
      });
    },
    [item, propsOnChangeGps]
  );

  const loadGpsGEOFences = useCallback(async () => {
    try {
      setPending(true);

      const { data } = await getGpsGeoFences({
        pageSize: 10000,
        pageNumber: 1,
        gpsId: item.gpsId,
      });

      const arr = (data || []).reduce(
        (acc, cur) => [...acc, ...(cur.geofences || [])],
        [] as GPSGeofence[]
      );
      setGpsGEOFences(arr || []);

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [item]);

  const options = useMemo<SelectOption<GPS>[]>(
    () =>
      gpsList.map((gpsProvider) => ({
        label: gpsProvider.name,
        value: gpsProvider.id,
      })),
    [gpsList]
  );

  const optionsGeofences = useMemo<SelectOption<GPSGeofence>[]>(
    () =>
      gpsGEOFences.map((gf) => ({
        label: gf.name || "",
        value: gf.id,
      })),
    [gpsGEOFences]
  );

  const value = useMemo<SelectOption<GPS> | null>(() => {
    const findGPS = gpsList.find((_gps) => _gps.id === item.gpsId);

    if (!findGPS) {
      return null;
    }

    return {
      label: findGPS.name,
      value: findGPS.id,
    };
  }, [gpsList, item.gpsId]);

  const valueGeofence = useMemo<SelectOption<GPSGeofence> | null>(() => {
    if (!item.gpsGeofenceId) {
      return null;
    }

    return {
      label: item.gpsGeofenceName,
      value: item.gpsGeofenceId,
    };
  }, [item]);

  useEffect(() => {
    if (!!item.gpsId) {
      loadGpsGEOFences();
    }
  }, [loadGpsGEOFences, item.gpsId]);

  return (
    <>
      <Select
        label="Провайдер"
        options={options}
        disabled={pending}
        value={value}
        onChange={onChangeGps}
      />
      <Select
        label="Геозона"
        disabled={!item.gpsId || pending}
        options={optionsGeofences}
        value={valueGeofence}
        onChange={onChangeGeofence}
      />
    </>
  );
};

export default function (props: Props) {
  const { geoFenceDetail, gpsGEOFences, setGpsGEOFences } = useMonitoring();
  const [pending, setPending] = useState<boolean>(true);
  const [gpsList, setGpsList] = useState<GPS[]>([]);

  const onClickAdd = useCallback(() => {
    if (!geoFenceDetail) {
      return;
    }
    setGpsGEOFences((prevItems) => [
      ...prevItems,
      {
        // geofenceId: geoFenceDetail.id || "",
        gpsId: "",
        gpsGeofenceId: "",
        gpsGeofenceName: "",
      },
    ]);
  }, [geoFenceDetail, setGpsGEOFences]);

  const onChangeGps = useCallback(
    (index: number) => (value: Partial<GPSGeofencesListRequest> | null) => {
      setGpsGEOFences((_prevGpsGEOFences) =>
        gpsGEOFences.map((_item, itemIndex) => {
          if (index !== itemIndex) {
            return { ..._item };
          }
          return {
            gpsId: value?.gpsId || "",
            gpsGeofenceId: value?.gpsGeofenceId || "",
            gpsGeofenceName: value?.gpsGeofenceName || "",
          };
        })
      );
    },
    [gpsGEOFences, setGpsGEOFences]
  );

  const onClickSave = useCallback(async () => {
    props.onSave();
  }, [props]);

  const getData = useCallback(async () => {
    try {
      setPending(true);

      const { data } = await getGps({
        pageSize: 10000,
        pageNumber: 1,
      });

      setGpsList(data);
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, []);

  const isFormFilled = useMemo(() => {
    if (gpsGEOFences.length === 0) {
      return true;
    }
    return gpsGEOFences.every(
      (_item) => !!_item.gpsId && !!_item.gpsGeofenceId
    );
  }, [gpsGEOFences]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!!geoFenceDetail) {
      setGpsGEOFences(
        geoFenceDetail.gpsGeofences.map((item) => ({
          gpsId: item.gpsId,
          gpsGeofenceId: item.gpsGeofenceId || "",
          gpsGeofenceName: item.gpsGeofenceName || "",
        }))
      );
    }
  }, [geoFenceDetail, setGpsGEOFences]);

  if (pending) {
    return <Loader />;
  }

  return (
    <StyledContent>
      <StyledGrid>
        {gpsGEOFences.map((item, itemIndex) => (
          <GpsGEOFence
            key={itemIndex.toString()}
            item={item}
            gpsList={gpsList}
            onChangeGps={onChangeGps(itemIndex)}
          />
        ))}
        <div>
          <Button startIcon={IconPlus24} onClick={onClickAdd} />
        </div>
      </StyledGrid>
      <StyledFooter>
        <p>
          {gpsGEOFences.length
            ? "* Все поля обязательны"
            : "Нажмите + чтобы добавить"}
        </p>
        <Button
          text="Сохранить"
          disabled={!isFormFilled}
          onClick={onClickSave}
        />
      </StyledFooter>
    </StyledContent>
  );
}
