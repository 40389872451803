import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Loader, Table } from "@app/components";
import styled from "styled-components";
import {
  SmartContract,
  SmartContractVehicle,
  SmartContractVehicleType,
} from "@app/models";
import {
  dateFormat,
  getAxiosErrorMessage,
  getDictionaryId,
  getDictionaryName,
  priceFormat,
} from "@app/helpers";
import { ModalSmartContractVehicle } from "@app/modals";
import { IconDocument24, IconPlus24, IconTrash24 } from "@app/icons";
import { theme } from "styled-tools";
import {
  addSmartContractVehicles,
  APIResponse,
  getSmartContractVehicles,
  GetSmartContractVehiclesDTO,
} from "@app/api";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";

interface Props {
  contract: SmartContract;
  vehicles: SmartContractVehicle[];
  onChange: (step: number) => void;
  setModalActions: Dispatch<SetStateAction<ReactNode[]>>;
  updateContract: (partialContract: Partial<SmartContract>) => void;
}

const StyledStep3 = styled.div`
  display: grid;
  grid-gap: 16px;
  height: 100%;
  grid-template-rows: auto 1fr auto auto;
`;

const StyledEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color ${theme("color.grayDark")};
  gap: 10px;
  position: relative;
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  text-align: center;
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${theme("color.orange")};
  opacity: 0.8;
  transition: 0.1s ease-in-out;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
  &:disabled {
    opacity: 1 !important;
    transform: scale(1);
    cursor: default;
    color: ${theme("color.gray")};
    box-shadow: none;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

function Step3(props: Props) {
  const {
    vehicles: propsVehicles,
    onChange: onChangeStep,
    contract,
    updateContract,
    setModalActions,
  } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<SmartContractVehicle | null>(null);
  const [vehicles, setVehicles] = useState<SmartContractVehicle[]>([]);
  const [confirmed, setConfirmed] = useState(false);

  const onSubmitForm = useCallback(async () => {
    try {
      setPending(true);

      // Удаляем временные requestId в добавленных техниках
      const newVehicles = vehicles.map((item) => ({
        ...item,
        requestId: null,
      }));

      const { message, succeeded, data } = await addSmartContractVehicles({
        draftId: contract.id,
        vehicleItems: [...propsVehicles, ...newVehicles].map((item) => ({
          requestId: item.requestId || null,
          vehicleId: item.vehicleId || null,
          vehicleTypeId: getDictionaryId(item.vehicleType),
          serviceTypeId: getDictionaryId(item.serviceType),
          characteristicsId: getDictionaryId(item.characteristics),
          workShiftId: getDictionaryId(item.workShift),
          unitId: getDictionaryId(item.unit),
          nomenclatureId: getDictionaryId(item.nomenclature),
          startDate: item.startDate!,
          endDate: item.endDate!,
          height: item.height || null,
          craneBoomReach: item.craneBoomReach || null,
          volume: item.volume,
          loadCapacity: item.loadCapacity || null,
          price: item.price,
          priceWithoutNds: item.priceWithoutNds,
          totalPrice: item.totalPrice || 0,
          totalPriceWithoutNds: item.totalPriceWithoutNds || 0,
          requestType: item.requestType,
        })),
      });

      if (!succeeded) {
        showNotification({
          message: message,
          variant: "error",
        });

        return;
      }

      setVehicles([]);

      showNotification({
        message: "Данные успешно сохранены",
        variant: "success",
      });

      updateContract(data);

      setPending(false);
    } catch (e) {
      setPending(false);

      console.log(e);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [contract, propsVehicles, showNotification, updateContract, vehicles]);

  const onClickGetVehicles = useCallback(async () => {
    try {
      setPending(true);

      const isWheeled = [
        SmartContractVehicleType.WheeledVehicle,
        SmartContractVehicleType.WheeledExtra,
        SmartContractVehicleType.PriceChangeWheeledExtra,
      ].includes(contract.contractType);

      const params: GetSmartContractVehiclesDTO = {
        customerId: contract.customer.id,
        projectId: contract.project.id,
        partnerId: contract.partner.id,
        vehicleType: isWheeled ? 1 : 2,
      };

      const { succeeded, message, data } = await getSmartContractVehicles(
        params
      );

      if (!succeeded) {
        showNotification({
          message: message,
          variant: "error",
        });

        return;
      }

      updateContract({
        vehicles: data,
      });

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [contract, showNotification, updateContract]);

  const onSaveVehicle = useCallback(
    (_vehicle: SmartContractVehicle) => {
      const findIndex = propsVehicles.findIndex(
        (item) => item.requestId === _vehicle.requestId
      );
      console.log(_vehicle);
      if (findIndex >= 0) {
        propsVehicles[findIndex] = _vehicle;
        updateContract({
          vehicles: [...propsVehicles],
        });
      } else {
        const _index = vehicles.findIndex(
          (item) => item.requestId === _vehicle.requestId
        );
        if (_index >= 0) {
          vehicles[_index] = _vehicle;
          setVehicles([...vehicles]);
        } else {
          setVehicles((prevVehicles) => [...prevVehicles, _vehicle]);
        }
      }
      setVehicle(null);
      setModalVisible(false);
    },
    [propsVehicles, updateContract, vehicles]
  );

  const onClickItem = useCallback(
    (index: number) => {
      setVehicle([...propsVehicles, ...vehicles][index]);
      setModalVisible(true);
    },
    [propsVehicles, vehicles]
  );

  const onVehicleRemove = useCallback(
    (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (index + 1 > propsVehicles.length) {
        vehicles.splice(index - propsVehicles.length, 1);
        setVehicles([...vehicles]);
      } else {
        propsVehicles.splice(index, 1);
        updateContract({ vehicles: [...propsVehicles] });
      }
    },
    [propsVehicles, updateContract, vehicles]
  );

  const onClickCreate = useCallback(() => {
    setVehicle(null);
    setModalVisible(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setVehicle(null);
    setModalVisible(false);
  }, []);

  const onClickPrev = useCallback(() => {
    onChangeStep(2);
  }, [onChangeStep]);

  const onClickNext = useCallback(() => {
    // Check new vehicles if it saved
    if (vehicles.length && !confirmed) {
      showNotification({
        message: "Имеется несохраненная техника",
        variant: "warning",
      });
      setConfirmed(true);
      return;
    }
    onChangeStep(4);
  }, [confirmed, onChangeStep, showNotification, vehicles.length]);

  useEffect(() => {
    setModalActions([
      <Button
        text="Сохранить"
        variant="text"
        onClick={onSubmitForm}
        disabled={pending}
      />,
    ]);
  }, [onSubmitForm, pending, setModalActions]);

  const tableLabels = useMemo(
    () => [
      "Вид ТС",
      "Вид услуги",
      "Смена",
      "Номенклатура",
      "Время начало работ",
      "Цена c НДС",
      "Доп затраты",
      "",
    ],
    []
  );

  const tableData = useMemo(() => {
    return [
      ...[...propsVehicles, ...vehicles].map((vehicle, index) => [
        getDictionaryName(vehicle.vehicleType, "-"),
        getDictionaryName(vehicle.serviceType, "-"),
        getDictionaryName(vehicle.workShift, "-"),
        getDictionaryName(vehicle.nomenclature, "-"),
        !!vehicle.startDate
          ? dateFormat(new Date(vehicle.startDate), "dd.MM.yyyy")
          : "-",
        priceFormat(vehicle.price.toString()),
        "-",
        <StyledButton onClick={(ev) => onVehicleRemove(index, ev)}>
          <IconTrash24 />
        </StyledButton>,
      ]),
    ];
  }, [onVehicleRemove, propsVehicles, vehicles]);

  return (
    <StyledStep3>
      <div>
        <Button
          text="Заполнить таблицу"
          size="small"
          onClick={onClickGetVehicles}
          disabled={pending}
        />
      </div>
      {propsVehicles.length > 0 || vehicles.length > 0 ? (
        <Table labels={tableLabels} data={tableData} onClick={onClickItem} />
      ) : (
        <StyledEmpty>
          {pending ? (
            <StyledLoader>
              <Loader />
            </StyledLoader>
          ) : (
            <>
              <IconDocument24 />
              <StyledText>Для отображения данных заполните таблицу</StyledText>
            </>
          )}
        </StyledEmpty>
      )}
      <div>
        <Button
          startIcon={IconPlus24}
          onClick={onClickCreate}
          disabled={pending}
        />
      </div>
      <StyledFooter>
        <Button
          onClick={onClickPrev}
          text="Назад"
          variant="outlined"
          disabled={pending}
        />
        <Button onClick={onClickNext} text="Далее" disabled={pending} />
      </StyledFooter>
      <ModalSmartContractVehicle
        open={modalVisible}
        vehicle={vehicle}
        onClose={onCloseModal}
        onSave={onSaveVehicle}
        organization={contract.partner}
        nds={contract.nds}
      />
    </StyledStep3>
  );
}

export default memo(Step3);
