import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { IconPause24Yellow, IconPlay24Green, IconStop24Red } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import TrackTimeline from "./TrackTimeline";

interface Props {
  min?: number;
  max: number;
  trackIndex: number;
  onChange: Dispatch<SetStateAction<number>>;
  currentDateTime: string;
  endDateTime: string;
}

const StyledTrackPlayer = styled.div`
  padding: 8px 12px;
  width: 480px;
  height: 40px;
  border-radius: 12px;
  background-color: ${theme("color.white")};
  display: flex;
  align-items: center;
`;

const StyledPlayButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
  padding: 0;
  background-color: transparent;
  margin-right: 16px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const StyledTrackPlayerTime = styled.p`
  font-size: 10px;
  line-height: 12px;
  color: #707781;
  margin: 0 20px 0 8px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const StyledPlaybackSpeedButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0 6px 0 0;
  background-color: transparent;
`;

const StyledTrackTimeline = styled.input`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
`;

export default function (props: Props) {
  const {
    trackIndex,
    min = 0,
    max = 0,
    onChange,
    currentDateTime,
    endDateTime,
  } = props;
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const timerId = useRef<NodeJS.Timeout | null>(null);

  const pause = useCallback(() => {
    if (timerId.current) {
      clearInterval(timerId.current);
      setIsPlaying(false);
      timerId.current = null;
    }
  }, []);

  const play = useCallback(() => {
    const interval = 1000 / playbackSpeed; // Calculate the interval based on the playback speed

    if (trackIndex < max) {
      setIsPlaying(true);

      timerId.current = setInterval(() => {
        if (trackIndex === max - 1) {
          if (timerId.current) {
            clearInterval(timerId.current);
          }

          onChange((prevIndex) => prevIndex);

          pause();

          return;
        }

        onChange((prevIndex) => prevIndex + 1);
      }, interval);
    } else {
      pause();
    }
  }, [playbackSpeed, trackIndex, max, onChange, pause]);

  const handlePlayPauseClick = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, pause, play]);

  const handleStop = useCallback(() => {
    pause();
    onChange(0);
  }, [onChange, pause]);

  const handleSpeedChange = useCallback(() => {
    const speeds = [1, 2, 5, 10, 25];
    const currentSpeedIndex = speeds.indexOf(playbackSpeed);
    const nextSpeedIndex = (currentSpeedIndex + 1) % speeds.length;
    const speed = speeds[nextSpeedIndex];

    pause();
    setPlaybackSpeed(speed);
    play();
  }, [pause, playbackSpeed, play]);

  const handleTimelineChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value, 10);

      pause();
      onChange(value);
    },
    [pause, onChange]
  );

  useEffect(() => {
    if (trackIndex === max) {
      pause();
    }
  }, [max, trackIndex, pause]);

  useEffect(() => {
    return () => {
      pause();
    };
  }, [pause]);

  return (
    <StyledTrackPlayer>
      <StyledPlayButton onClick={handlePlayPauseClick}>
        {isPlaying ? <IconPause24Yellow /> : <IconPlay24Green />}
      </StyledPlayButton>
      <StyledPlayButton onClick={handleStop}>
        <IconStop24Red />
      </StyledPlayButton>
      <TrackTimeline
        type="range"
        min={min}
        max={max - 1}
        value={trackIndex}
        onChange={handleTimelineChange}
      />
      {/*<TrackTimeline />*/}
      <StyledTrackPlayerTime>
        {currentDateTime} <span>-</span> {endDateTime}
      </StyledTrackPlayerTime>
      <StyledPlaybackSpeedButton onClick={handleSpeedChange}>
        {playbackSpeed}x
      </StyledPlaybackSpeedButton>
    </StyledTrackPlayer>
  );
}
