import React, { memo } from "react";
import styled from "styled-components";
import { Container } from "@app/components";
import { theme } from "styled-tools";
import BIGroupSrc from "./bi-group.svg";
import ModexSrc from "./modex.svg";
import NRGSrc from "./ngr.svg";
import AZKSrc from "./azk.jpg";
import PGSSrc from "./pgs-astana.png";
import ESSrc from "./es.jpeg";
import GlavStroySrc from "./glav-stroy.png";
import InvestSrc from "./inves-city.jpg";
import Alkon from "./alkon.png";
import Batsu from "./431.png";
import BazisA from "./bazis-A.svg";
import BazisT from "./bazis-t-logo.svg";
import Mirva from "./mirva.png";
import Saba from "./saba.jpeg";
import SDN from "./SDN.png";
import PartnersMobile from "./partners-mobile.png";
import SectionTitle from "../SectionTitle";
import { useWindowWide } from "@app/hooks";

const StyledSection = styled.section`
  padding: 100px 0;
  box-sizing: border-box;

  @media only screen and (max-width: 576px) {
    padding: 32px 0;
  }
`;

const StyledTitle = styled(SectionTitle)`
  text-align: center;
  margin: 0 0 32px 0;

  @media only screen and (max-width: 576px) {
    margin: 0 0 16px 0;
  }
`;

const StyledText = styled(SectionTitle)`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 60px 0;
  color: ${theme("color.grayDark")};
`;

const StyledGrid = styled.div`
  display: flex;
  gap: 48px;
  justify-content: center;
`;

const StyledPartner = styled.a`
  margin-bottom: -48px;

  img {
    height: 50px;
  }
`;

const StyledPartner2 = styled.a`
  margin-top: 18px;

  img {
    height: 60px;
  }
`;

const StyledPartner3 = styled.a`
  margin-top: 18px;

  img {
    height: 60px;
  }
`;

function SectionPartners() {
  const isMobile = useWindowWide(576);

  if (isMobile) {
    return (
      <StyledSection>
        <Container>
          <StyledTitle>Компании, которые уже используют Dispatch</StyledTitle>
          <StyledText>Уже более 25+ компаний испытали эффективность</StyledText>
          <img src={PartnersMobile} width="100%" alt="" />
        </Container>
      </StyledSection>
    );
  }

  return (
    <StyledSection>
      <Container>
        <StyledTitle>Компании, которые уже используют Dispatch</StyledTitle>
        <StyledText>Уже более 25+ компаний испытали эффективность</StyledText>
        <StyledGrid>
          <StyledPartner href="#">
            <img src={BIGroupSrc} alt="" />
          </StyledPartner>
          <StyledPartner href="#">
            <img src={ModexSrc} alt="" />
          </StyledPartner>
          <StyledPartner href="#">
            <img src={NRGSrc} alt="" />
          </StyledPartner>
        </StyledGrid>
        <br />
        <br />
        <br />
        <StyledGrid>
          <StyledPartner2 href="#">
            <img src={GlavStroySrc} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={BazisA} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={BazisT} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={Alkon} alt="" />
          </StyledPartner2>
        </StyledGrid>

        <StyledGrid>
          <StyledPartner2 href="#">
            <img src={Mirva} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={PGSSrc} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={Batsu} alt="" />
          </StyledPartner2>
          <StyledPartner3 href="#">
            <img src={SDN} alt="" />
          </StyledPartner3>
          {/*<StyledPartner2 href="#">*/}
          {/*  <img src={HayaBuild} alt="" />*/}
          {/*</StyledPartner2>*/}
        </StyledGrid>
        <StyledGrid>
          <StyledPartner2 href="#">
            <img src={AZKSrc} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={ESSrc} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={Saba} alt="" />
          </StyledPartner2>
          <StyledPartner2 href="#">
            <img src={InvestSrc} alt="" />
          </StyledPartner2>
        </StyledGrid>
      </Container>
    </StyledSection>
  );
}

export default memo(SectionPartners);
