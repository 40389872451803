import { PageableParams, PageableResponse } from "./interfaces";
import { Document, DocumentType } from "@app/models";
import instance from "./instance";
import axios from "axios";

export async function getContractDocuments(
  params: PageableParams & {
    type?: DocumentType;
  }
): Promise<PageableResponse<Document>> {
  const { type, ...restParams } = params;

  const response = await instance().get("/document/contract", {
    params: {
      ...restParams,
      forModuleId: type,
    },
  });

  return await response.data;
}

export async function getActDocuments(
  params: PageableParams & {
    type?: DocumentType;
  }
): Promise<PageableResponse<Document>> {
  const { type, ...restParams } = params;

  const response = await instance().get("/document/act", {
    params: {
      ...restParams,
      forModuleId: type,
    },
  });

  return await response.data;
}

export async function getSingingDocumentBase64(url: string): Promise<string> {
  const blobFile = await axios
    .get(url, {
      responseType: "blob",
    })
    .then((response) => response.data);

  const reader = new FileReader();

  reader.readAsDataURL(blobFile);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve((reader.result as string).split(",")[1]);
    };
  });
}
