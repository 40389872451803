import React, { memo, useCallback } from "react";
import { FilesPickerDropzone } from "./components";
import FileComponent from "../File";
import styled from "styled-components";

interface Props {
  files: File[];
  horizontal?: boolean;
  multiple?: boolean;
  onChange?: (files: File[]) => void;
}

const FilesPickerStyled = styled.div<Pick<Props, "horizontal">>`
  display: grid;
  grid-template-columns: ${(props) => (props.horizontal ? "1fr 1fr" : "1fr")};
  grid-gap: 16px;
`;

function FilesPicker(props: Props) {
  const { files, onChange, horizontal = false, multiple = true } = props;

  const onDelete = useCallback(
    (index: number) => {
      if (!onChange) {
        return;
      }

      onChange(files.filter((file, fileIndex) => fileIndex !== index));
    },
    [files, onChange]
  );

  return (
    <FilesPickerStyled horizontal={horizontal}>
      <FilesPickerDropzone
        onChange={onChange}
        files={files}
        multiple={multiple}
      />
      {files.map((file, fileIndex) => (
        <FileComponent
          key={fileIndex.toString()}
          file={file}
          fileIndex={fileIndex}
          onDelete={onDelete}
        />
      ))}
    </FilesPickerStyled>
  );
}

export default memo(FilesPicker);
