import React, { memo, useMemo, useState } from "react";
import { Modal, ModalProps, Tabs } from "@app/components";
import styled from "styled-components";
import posterImage from "../../assets/icons/poster.svg";
import { useUser } from "@app/providers";
import { useNavigate } from "react-router-dom";

interface Props extends Omit<ModalProps, "title"> {}

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 872px;
  overflow-y: auto;
`;

const StyledVideoContent = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
`;

const VideoThumbnailContainer = styled.div`
  width: 40%;
  position: relative;
  cursor: pointer;
`;

const VideoThumbnail = styled.img`
  width: 100%;
`;

const VideoTitle = styled.div`
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 96%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: center;
  padding: 5px;
`;

function ModalAddOrganizationInstruction(props: Props) {
  const { ...modalProps } = props;
  const navigate = useNavigate();
  const { authenticated, user, logout } = useUser();
  const [activeTab, setActiveTab] = useState<string>("Для ТОО");

  const tabLabels = useMemo<string[]>(() => ["Для ТОО", "Для ИП"], []);

  const tabMap = useMemo<Record<string, { src: string; title: string }[]>>(
    () => ({
      "Для ТОО": [
        { src: "/too2.mp4", title: "Добавление ТОО" },
        { src: "/too1.mp4", title: "Присоединение к ТОО" },
      ],
      "Для ИП": [
        { src: "/IP2.mp4", title: "Добавление ИП" },
        { src: "/IP1.mp4", title: "Присоединение к ИП" },
      ],
    }),
    []
  );

  const onChangeTab = (index: number) => {
    setActiveTab(tabLabels[index]);
  };

  const onClickVideo = (videoSrc: string) => {
    const videoPath = `/video/${encodeURIComponent(videoSrc)}`;
    const newWindow = window.open(videoPath, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Modal title="Инструкции по добавлению организации" {...modalProps}>
      <StyledModalContent>
        <Tabs labels={tabLabels} onChange={onChangeTab}>
          {Object.values(tabMap).map((videos, index) => (
            <StyledVideoContent key={index}>
              {videos.map((video, videoIndex) => (
                <React.Fragment key={videoIndex}>
                  <VideoThumbnailContainer
                    key={videoIndex}
                    onClick={() => onClickVideo(video.src)}
                  >
                    <VideoTitle>{video.title}</VideoTitle>
                    <VideoThumbnail
                      src={posterImage}
                      alt="Предпросмотр видео"
                    />
                  </VideoThumbnailContainer>
                </React.Fragment>
              ))}
            </StyledVideoContent>
          ))}
        </Tabs>
      </StyledModalContent>
    </Modal>
  );
}

export default memo(ModalAddOrganizationInstruction);
