import React, { memo, useCallback, useRef } from "react";
import styled from "styled-components";
import { IconShare40 } from "@app/icons";
import Button from "../../../Button/Button";

interface Props {
  files: File[];
  multiple?: boolean;
  comments?: string[];
  accept?: string;
  onChange?: (files: File[]) => void;
}

const FilesPickerDropzoneStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px dashed #ed4a3f;

  > p {
    color: ${(props) => props.theme.color.grayDark};
    margin: 8px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  input {
    display: none;
  }

  button {
    margin-top: 16px;
  }

  ul {
    font-size: 12px;
    line-height: 16px;
    color: ${(props) => props.theme.color.grayDark};
    margin: 16px 0;
  }
`;

function FilesPickerDropzone(props: Props) {
  const {
    onChange,
    files,
    multiple = true,
    accept = "/*",
    comments = [],
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = useCallback(() => {
    inputRef.current!.click();
  }, []);

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;
      const filesToReturn = [];

      if (!fileList || !onChange) {
        return;
      }

      for (let i = 0; i < fileList.length; i++) {
        filesToReturn.push(fileList[i]);
      }

      onChange([...files, ...filesToReturn]);

      e.target.value = "";
    },
    [onChange, files]
  );

  return (
    <FilesPickerDropzoneStyle>
      <IconShare40 />
      <p>Перетащите файлы сюда</p>
      <p>или</p>
      <input
        type="file"
        accept={accept}
        ref={inputRef}
        onChange={onChangeInput}
        multiple={multiple}
      />
      <Button
        text="Загрузите файлы"
        disabled={!multiple && files.length > 0}
        onClick={onClick}
      />
      <ul>
        {comments.map((comment, index) => (
          <li key={index.toString()}>{comment}</li>
        ))}
      </ul>
    </FilesPickerDropzoneStyle>
  );
}

export default memo(FilesPickerDropzone);
