import React, { memo, ReactNode, useCallback, useMemo } from "react";
import {
  VAERequest,
  VAERequestOffer,
  VAERequestOfferStatus,
  VAERequestStatus,
} from "@app/models";
import { getVAERequestOffers, PageableParams } from "@app/api";
import { TemplateSearch } from "@app/templates";
import styled from "styled-components";
import { Button, Checkbox } from "@app/components";
import { getDictionaryName, isDispatcher, priceFormat } from "@app/helpers";
import { useUser } from "@app/providers";
import { VAERequestDetail } from "@app/common";

interface Props {
  request: VAERequest;
  selectedOffer: VAERequestOffer | null;
  onSelectOffer: (offer: VAERequestOffer) => void;
  onClickAddOffer: () => void;
}

const StyledSearchTemplateWrapper = styled.div`
  height: 100%;
  padding: 0 8px 16px 8px;
`;

function VAERequestOffers(props: Props) {
  const { user } = useUser();
  const { request, selectedOffer, onSelectOffer, onClickAddOffer } = props;

  const onClickOffer = useCallback(
    (offer: VAERequestOffer) => {
      onSelectOffer(offer);
    },
    [onSelectOffer]
  );

  const mapOffersTableData = useCallback(
    (item: VAERequestOffer) => {
      return [
        <Checkbox
          editable={false}
          type="radio"
          checked={
            (!!selectedOffer && item.id === selectedOffer.id) ||
            item.status === VAERequestOfferStatus.Selected
          }
        />,
        getDictionaryName(item.company, "-"),
        "-",
        item.vehicle.govNumber || "-",
        !!item.priceWithoutNds
          ? priceFormat(item.priceWithoutNds.toString())
          : "-",
        getDictionaryName(item.vehicle.gps, "-"),
        priceFormat(item.price.toString()),
        getDictionaryName(item.nds, "-"),
        item.comments || "-",
      ];
    },
    [selectedOffer]
  );

  const getData = useCallback(
    async (params: PageableParams) => {
      return getVAERequestOffers({
        ...params,
        requestId: request.id,
      });
    },
    [request]
  );

  const leftControls = useMemo<ReactNode[]>(() => {
    if (
      [
        VAERequestStatus.AwaitingConfirmation,
        VAERequestStatus.AwaitingRequest,
      ].indexOf(request.status) > -1 &&
      isDispatcher(user!.role)
    ) {
      return [
        <Button
          text="Добавить предложение"
          onClick={onClickAddOffer}
          size="small"
        />,
      ];
    }

    return [];
  }, [request, onClickAddOffer, user]);

  const tableLabels = useMemo<string[]>(
    () => [
      "",
      "Абонент",
      "Рейтинг",
      "Гос номер",
      "Цена без НДС",
      "Провайдер GPS",
      "Цена с НДС",
      "Ставка НДС ",
      "Примечание",
    ],
    []
  );

  return (
    <>
      <VAERequestDetail columns={5} request={request} variant="secondary" />
      <StyledSearchTemplateWrapper>
        <TemplateSearch<VAERequestOffer>
          shadow={false}
          tableLabels={tableLabels}
          getData={getData}
          mapTableData={mapOffersTableData}
          searchBar={false}
          onClick={onClickOffer}
          toolbarProps={{
            title: "Ценовые предложения",
            leftControls,
          }}
        />
      </StyledSearchTemplateWrapper>
    </>
  );
}

export default memo(VAERequestOffers);
