import { Company } from "./Company";
import { Project } from "./Project";
import { SmartContractStatus } from "./SmartContract";

export enum DocumentType {
  Contract = 1,
  Act = 2,
  Note = 3,
  Adhesion = 4,
}

export const DocumentTypeNames: Record<DocumentType, string> = {
  [DocumentType.Contract]: "Договоры",
  [DocumentType.Act]: "Акты",
  [DocumentType.Note]: "Служебные записки",
  [DocumentType.Adhesion]: "Договор присоединения",
};

export interface Document {
  id: string;
  name: string | null;
  company: Company;
  project: Project;
  partner: Company;
  // companyShortDto: Company;
  // projectShortDto: Project;
  startDate: string | null;
  endDate: string | null;
  status: SmartContractStatus; // ???
  sourceId?: string;
}
