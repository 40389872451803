import { NDSCompany, NdsShort } from "./NDS";
import { BankAccount } from "./Bank";
import { Address } from "./Address";
import { FileObject, FileSimple } from "./Default";
import { CountryShort } from "./Country";
import { VehicleInfo } from "./Vehicle";
import { UserCompany } from "./User";
import { Project } from "./Project";

export enum CompanyStatus {
  Created = 0,
  AwaitingConfirmation = 1,
  Confirmed = 2,
  Rejected = 3,
}

export enum CompanyProjectRecipientsType {
  Contracts = 10,
  AVRs = 20,
}

export const CompanyStatusName: Record<CompanyStatus, string> = {
  [CompanyStatus.AwaitingConfirmation]: "На рассмотрении",
  [CompanyStatus.Confirmed]: "Подтверждено",
  [CompanyStatus.Rejected]: "Отклонен",
  [CompanyStatus.Created]: "Создан",
};

export interface CompanyType {
  id: string;
  name: string;
}

export interface Company {
  id: string;
  companyStatus: CompanyStatus;
  companyType: CompanyType;
  name: string;
  employeesCount: number;
  bin: string;
  nds: NdsShort;
  comment?: string;
}

export interface CompanyListItem {
  id: string;
  name: string;
  bin: string;
  kbe: string;
  nds: NdsShort;
  type: CompanyType;
  status: CompanyStatus;
  created: string;
  addressList: unknown[];
  bankList: unknown[] | null;
  contactList: unknown[] | null;
  phone: string | null;
  comment: string | null;
}

export interface CompanyVehicle {
  company: CompanyFull;
  companyId: string;
  vehicleId: string;
  statusId: number;
  comment: null;
  id: string;
  isDeleted: boolean;
}

export interface CompanyFull {
  name: string;
  bin: string;
  kbe: string;
  ndsId: string;
  typeId: number;
  divisionId: unknown | null;
  companyGroupId: unknown | null;
  phone: string | null;
  email: string | null;
  countryId: unknown | null;
  headName: string | null;
  headIdn: unknown | null;
  isResident: boolean | null;
  registrationNumber: unknown | null;
  status: number;
  canCommentJournal: boolean;
  id: string;
  createdBy: unknown | null;
  created: string;
  lastModifiedBy: string;
  lastModified: string;
  deleted: unknown | null;
  isDeleted: boolean;
}

export enum CompanyFormTypeId {
  IP = 1,
  TOO = 2,
}

export interface CompanyFormType {
  id: CompanyFormTypeId;
  name: string;
}

export interface CompanyFileType {
  name: string;
  id: string;
  isDeleted: boolean;
}

export interface CompanyInfoFile {
  file: FileSimple;
  type: CompanyFileType | null;
  companyId: string;
  fileId: string;
  typeId: null | string;
  id: string;
  isDeleted: boolean;
}

export interface CompanyInfo {
  addresses: Address[];
  avrEndDay: unknown | null;
  avrStartDay: unknown | null;
  bankAccounts: BankAccount[];
  bin: string;
  canCommentJournal: boolean;
  companyGroupId: unknown | null;
  contacts: unknown[];
  country: CountryShort;
  countryId: string | null;
  created: string;
  createdBy: string;
  deleted: string | null;
  division: unknown | null;
  divisionId: string | null;
  email: null;
  files: CompanyInfoFile[];
  group: unknown | null;
  headIdn: string | null;
  headName: string | null;
  id: string;
  isBranch: boolean;
  isDeleted: boolean;
  isResident: boolean | null;
  kbe: string;
  lastModified: string | null;
  lastModifiedBy: string | null;
  name: string;
  nds: NDSCompany;
  ndsId: string;
  phone: string | null;
  projects: Project[];
  registrationNumber: string | null;
  status: number;
  type: CompanyFormType;
  typeId: CompanyFormTypeId;
  users: UserCompany[];
  vehicles: VehicleInfo[];
}

export interface CompanyShort {
  id: string;
  name: string | null;
  bin: string | null;
  canCommentJournal: boolean;
}

export interface CompanyJournal {
  id: string;
  name: string;
  canCommentJournal: boolean;
}

export interface CompanyCreateAddress {
  companyId: string;
  regionId: string;
  street: string;
  block: string;
  apartment: string;
  isLegalAddress: boolean;
}

export interface CompanyCreateBankAccount {
  companyId: string;
  bankId: string;
  accountNumber: string;
  isMain: boolean;
}

export interface CompanyConfirmStatus {
  companyId: string;
  statusId: number | null;
}

export interface CompanyBankAccount {
  companyId: string;
  id: string;
  bankAccount: BankAccount;
}

export interface CompanyAddress {
  id: string;
  company: CompanyType;
  address: Address | null;
}

export interface CompanyFile {
  company: CompanyType;
  file: FileObject;
  type: null;
}

export interface LegalRegion {
  name: string;
  ndsId: string;
  currencyId: string;
  countryId: string;
  id: string;
  isDeleted: boolean;
}

export interface LegalAddress {
  region: LegalRegion;
  id: string;
  street: string;
  block: string;
  apartment: string;
  isLegalAddress: boolean;
  regionId: string;
}

export interface CompanyProjectRecipientsParams {
  companyId: string;
  projectId: string;
  typeId: CompanyProjectRecipientsType;
}

// TODO удалить, дублирует UserSimple
export interface CompanyRecipient {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  accessFailedCount: number;
  lastname: string;
  firstname: string;
  middlename: string;
  iin: string;
  isDelete: boolean;
}
