import React, { memo, ReactNode, useCallback } from "react";
import "./styles.scss";
import { useUser } from "@app/providers";
import { IconExit24, IconPersonCircle32 } from "@app/icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { deleteDevice } from "@app/api";
// import styled from "styled-components";
// import XmasTreeRight from "../../../../assets/images/ny/ny-decor-right.png";
// import XmasTreeLeft from "../../../../assets/images/ny/ny-decor-left.png";

interface Props {
  title?: string;
  rightControls?: ReactNode;
}

/*const DecorRight = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 9999;
  animation: slideIn 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  @keyframes slideIn {
    from {
      right: -16px;
    }
    to {
      right: 0;
    }
  }
  &:hover {
    transform: translate(25px, 0);
  }
`;

const DecorLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  animation: fadeIn 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  @keyframes fadeIn {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  &:hover {
    transform: translate(-15px, -15px);
  }
`;*/

function Header(props: Props) {
  const { title, rightControls } = props;
  const navigate = useNavigate();
  const { authenticated, user, logout } = useUser();

  const onClickLogout = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const firebaseToken = await Cookies.get("firebaseToken");

      if (firebaseToken) {
        await Cookies.remove("firebaseToken");
        await deleteDevice(firebaseToken);
      }

      logout();

      navigate("/sign-in");
    },
    [navigate, logout]
  );

  return (
    <header className="b-header">
      {/* Xmas Decoration */}
      {/*<DecorRight>
        <img src={XmasTreeRight} alt="Xmas Tree" width={64} />
      </DecorRight>
      <DecorLeft>
        <img src={XmasTreeLeft} alt="Xmas Tree" width={107} />
      </DecorLeft>*/}
      {!!title && <p className="b-header__title">{title}</p>}
      {!!rightControls && <p className="b-header__title">{rightControls}</p>}
      {authenticated && !!user && (
        <div className="b-header__right">
          <div className="b-header__profile">
            <IconPersonCircle32 />
            <p>{user.fullName}</p>
          </div>
          <button onClick={onClickLogout} className="b-header__button">
            <div>
              <IconExit24 />
            </div>
          </button>
        </div>
      )}
    </header>
  );
}

export default memo(Header);
