import { APIResponse, PageableParams, PageableResponse } from "./interfaces";
import { ActOfWork, ActOfWorkStatus, Dictionary } from "@app/models";
import instance from "./instance";
import { AxiosResponse } from "axios";

export interface ActOfProvidedWorkDTO {
  senderCompanyId: string;
  recipientCompanyId: string;
  projectId: string;
  actId: string;
  registrationNumber: string;
  date: string;
  startDate: string;
  endDate: string;
  contractId: string;
  senderCompanyAddress: string;
  recipientCompanyAddress: string;
  ndsRate: string;
  basisId: string;
  nomenclatures: {
    name: string;
    executionDate: string;
    unitId: string;
    quantity: number;
    price: number;
    sum: number;
    sumNds: number;
  }[];
}

export interface ActOfProvidedWorkStatusDTO {
  id: string;
  statusId: ActOfWorkStatus;
  comment?: string;
}

export interface ActOfProvidedWorkSignDTO {
  avrId: string;
  signedFile: string;
  isRecipient: boolean;
}

export interface ActOfWorkRedirectRequest {
  avrId: string;
  userId: string;
  oldUserId: string;
}

export interface ActOfWorkApproveRequest {
  id: string;
  isApproved: boolean;
  comment: string;
}

export function getActOfProvidedWorks(
  params: PageableParams & {
    number?: string;
    statusIds?: string;
  }
): Promise<PageableResponse<ActOfWork>> {
  return instance()
    .get("/avr/list", {
      params,
    })
    .then((response) => response.data);
}
export function getOnlySignedActOfWorks(
  params: PageableParams & {
    number?: string;
  }
): Promise<PageableResponse<ActOfWork>> {
  return getActOfProvidedWorks({ ...params, statusIds: "50" });
}
export function getActOfWorksById(
  id: ActOfWork["id"]
): Promise<APIResponse<ActOfWork>> {
  return instance()
    .get(`/avr/${id}`, {})
    .then((response) => response.data);
}

export function getActOfProvidedWorkBasisList(): Promise<
  APIResponse<(Dictionary & { isActBasis: boolean })[]>
> {
  return instance()
    .get("/avrBasis/list")
    .then((response) => response.data);
}

export function createActOfProvidedWork(
  data: ActOfProvidedWorkDTO
): Promise<APIResponse<ActOfWork>> {
  return instance()
    .post("/avr", data)
    .then((response) => response.data);
}

export function updateActOfProvidedWork(
  data: ActOfProvidedWorkDTO & {
    id: string;
  }
): Promise<APIResponse<ActOfWork>> {
  return instance()
    .put("/avr", data)
    .then((response) => response.data);
}

export function changeActOfProvidedWorkStatus(
  data: ActOfProvidedWorkStatusDTO
) {
  return instance()
    .put("/avr/status", data)
    .then((response) => response.data);
}

export function signActOfProvidedWork(
  data: ActOfProvidedWorkSignDTO
): Promise<APIResponse<ActOfWork>> {
  return instance()
    .post("/avr/sign", data)
    .then((response) => response.data);
}

export function redirectActOfProvidedWork(
  data: ActOfWorkRedirectRequest
): Promise<APIResponse<boolean>> {
  return instance()
    .put("/avr/approver", data)
    .then((response) => response.data);
}

export function approveActOfProvidedWork(
  data: ActOfWorkApproveRequest
): Promise<APIResponse<boolean>> {
  return instance()
    .put("/avr/approve", data)
    .then((response) => response.data);
}

export function getActOfProvidedWorkReference(
  id: string
): Promise<AxiosResponse<Blob>> {
  return instance().get(`/avr/reference/${id}`, {
    responseType: "blob",
  });
}
