import React, { memo } from "react";
import { MenuItem, MenuItemComponent } from "./components";
import classNames from "classnames";
import "./styles.scss";
import { IconSettings24, IconSettingsFilled24 } from "@app/icons";

interface Props {
  items: MenuItem[];
  variant?: "light" | "dark";
}

function Menu(props: Props) {
  const { items, variant = "light" } = props;

  return (
    <nav
      className={classNames("b-menu", {
        "b-menu--dark": variant === "dark",
      })}
    >
      <ul className="b-menu__list">
        {items.map((item, itemIndex) => (
          <MenuItemComponent key={itemIndex.toString()} {...item} />
        ))}
      </ul>
      <ul className="b-menu__footer">
        <MenuItemComponent
          title="Настройки"
          path="/settings"
          icon={IconSettings24}
          activeIcon={IconSettingsFilled24}
        />
      </ul>
    </nav>
  );
}

export default memo(Menu);
