import {
  Button,
  Loader,
  Modal,
  ModalProps,
  ModalTitle,
  Stepper,
} from "@app/components";
import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { SmartContract } from "@app/models";
import { APIResponse, getSmartContract } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";
import { Filter, Step1, Step2, Step3, Step4, Step5 } from "./components";
import { SmartContractStatusChip } from "@app/common";
import { ModalContractorFiles } from "../index";

interface Props extends Omit<ModalProps, "title" | "onClose"> {
  data: Pick<SmartContract, "id" | "number" | "status"> | null;
  onClose: (refresh?: boolean) => void;
}

const StyledContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
`;

const StyledStepper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: 8px solid #f5f6f8;
  margin: 0 -24px 16px;
  padding: 16px 24px;
  box-sizing: border-box;
`;

const StyledDetail = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  padding: 0 24px;
  margin: 0 -24px;
  box-sizing: border-box;
`;

function ModalSmartContractDetail(props: Props) {
  const { onClose, data, ...restProps } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [contract, setContract] = useState<SmartContract | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [modalActions, setModalActions] = useState<ReactNode[]>([]);

  const onCreateContract = useCallback((createdContract: SmartContract) => {
    setContract(createdContract);
    setContract((prevContract) => ({
      ...(!!prevContract ? prevContract : ({} as SmartContract)),
      ...createdContract,
    }));
  }, []);

  const onCloseModal = useCallback(() => {
    setContract(null);
    setPending(false);
    setError(false);
    setCurrentStep(1);
    setModalActions([]);

    // при создании договора в contract есть данные, а пропс data - пустой
    // в этом случае отправится true и будет обновление списка
    onClose(!data && !!contract);
  }, [contract, data, onClose]);

  const onModalFilesOpen = useCallback(() => {
    setShowFiles(true);
  }, []);

  const onModalFilesClose = useCallback(() => {
    setShowFiles(false);
  }, []);

  const getData = useCallback(async () => {
    try {
      const response = await getSmartContract(data!.id);

      if (!response.succeeded) {
        showNotification({
          variant: "error",
          message: response.message,
        });

        setError(true);
        setPending(false);

        return;
      }

      setContract(response.data);

      if (response.data && !!response.data.file) {
        setCurrentStep(4);
      }
      setError(false);
      setPending(false);
    } catch (e) {
      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });

      setError(true);
      setPending(false);
    }
  }, [data, showNotification]);

  const updateContract = useCallback(
    (partialContractData: Partial<SmartContract>, refresh = false) => {
      setContract((prevContract) => ({
        ...(!!prevContract ? prevContract : ({} as SmartContract)),
        ...partialContractData,
      }));
      if (refresh) {
        getData().then(() => {});
      }
    },
    [getData]
  );

  const modalTitle = useMemo<ReactNode>(() => {
    const contractData = data || contract;
    if (!contractData) {
      return "Создание smart-договора";
    }

    return (
      <ModalTitle
        text={`Smart-договор ${
          contractData.number ? `№${contractData.number}` : ""
        }`}
      >
        {!!contract?.status && (
          <SmartContractStatusChip status={contractData.status} />
        )}
        {contract && (
          <Button
            variant={"contained"}
            text={"Документы"}
            size={"small"}
            onClick={onModalFilesOpen}
          />
        )}
      </ModalTitle>
    );
  }, [contract, data, onModalFilesOpen]);

  const isStepAvailable = useCallback(
    (stepIndex: number): boolean => {
      const { additionalContractReasons } = contract || {};
      if (additionalContractReasons?.length) {
        if (stepIndex === 2) {
          return additionalContractReasons.some(
            (reason) => reason.stepIndex === 2
          );
        } else if (stepIndex === 3) {
          return additionalContractReasons.some(
            (reason) => reason.stepIndex === 3
          );
        }
      }
      return true;
    },
    [contract]
  );

  const onChangeStep = useCallback(
    (step: number) => {
      if (isStepAvailable(step)) {
        setCurrentStep(step);
      } else {
        onChangeStep(step > currentStep ? step + 1 : step - 1);
      }
    },
    [currentStep, isStepAvailable]
  );

  const steps = useMemo(
    () => [
      "Заполните реквизиты",
      "Порядок расчетов",
      "Техника",
      "Файл договора",
      "Лица согласования",
    ],
    []
  );

  useEffect(() => {
    if (!!data) {
      setPending(true);
      setContract(null);

      getData().then(() => {});
    } else {
      setContract(null);
    }
  }, [data, getData]);

  if (!restProps.open) {
    return null;
  }

  return (
    <Modal
      zIndex={998}
      title={modalTitle}
      onClose={onCloseModal}
      {...restProps}
      size="large"
      actions={modalActions}
    >
      <Filter contract={contract} onCreate={onCreateContract} />
      <StyledContent>
        <StyledStepper>
          <Stepper
            steps={steps}
            disabled={false}
            currentStep={currentStep}
            filled={false}
            disabledSteps={steps.map((_, stepIndex) => {
              return isStepAvailable(stepIndex + 1) ? -1 : stepIndex + 1;
            })}
          />
        </StyledStepper>
        <StyledDetail>
          {!pending && !error && (
            <>
              {currentStep === 1 && (
                <Step1
                  contract={contract}
                  onChange={onChangeStep}
                  setModalActions={setModalActions}
                  updateContract={updateContract}
                />
              )}
              {!!contract && (
                <>
                  {currentStep === 2 && isStepAvailable(2) && (
                    <Step2
                      contract={contract}
                      onChange={onChangeStep}
                      setModalActions={setModalActions}
                      updateContract={updateContract}
                    />
                  )}
                  {currentStep === 3 && isStepAvailable(3) && (
                    <Step3
                      contract={contract!}
                      vehicles={contract!.vehicles}
                      onChange={onChangeStep}
                      updateContract={updateContract}
                      setModalActions={setModalActions}
                    />
                  )}
                  {currentStep === 4 && (
                    <Step4
                      onChange={onChangeStep}
                      contract={contract!}
                      updateContract={updateContract}
                      setModalActions={setModalActions}
                    />
                  )}
                  {currentStep === 5 && (
                    <Step5
                      onChange={onChangeStep}
                      contract={contract!}
                      updateContract={updateContract}
                      setModalActions={setModalActions}
                    />
                  )}
                </>
              )}
            </>
          )}
          {pending && <Loader />}
        </StyledDetail>
      </StyledContent>
      <ModalContractorFiles
        zIndex={999}
        open={showFiles}
        title={"Документы"}
        dense={true}
        size={"medium"}
        contractData={contract}
        onClose={onModalFilesClose}
      />
    </Modal>
  );
}

export default memo(ModalSmartContractDetail);
