import React, { memo, PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
  text: string;
}

const StyledModalTitle = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 16px;
  }
`;

const StyledText = styled.span`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: ${(props) => props.theme.color.dark};
  max-width: 680px;
`;

function ModalTitle(props: Props & PropsWithChildren) {
  const { text, children } = props;

  return (
    <StyledModalTitle>
      <StyledText>{text}</StyledText>
      {children || null}
    </StyledModalTitle>
  );
}

export default memo(ModalTitle);
