import React, { memo, useMemo } from "react";
import { Modal, ModalProps } from "@app/components";
import { VehicleRequest } from "@app/models";
import { VehicleRequestDetail } from "@app/common";
import { FormVehicleRequestOffer } from "@app/forms";

interface Props extends Omit<ModalProps, "title"> {
  request: VehicleRequest;
  hideInfo?: boolean;
  onSuccess: () => void;
}

function ModalVehicleRequestOffer(props: Props) {
  const { request, hideInfo = false, onSuccess, ...modalProps } = props;

  const modalTitle = useMemo(() => "Подача предложения", []);

  const actions = useMemo(() => [], []);

  if (!modalProps.open) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      title={modalTitle}
      actions={actions}
      dense={true}
      size="medium"
    >
      {!hideInfo && (
        <VehicleRequestDetail
          request={request}
          variant="secondary"
          columns={3}
        />
      )}
      <FormVehicleRequestOffer request={request} onSuccess={onSuccess} />
    </Modal>
  );
}

export default memo(ModalVehicleRequestOffer);
