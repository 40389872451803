import React, { ReactElement, useCallback, useMemo, useState } from "react";
import {
  Button,
  Combobox,
  DateTimePicker,
  DefaultObject,
  Grid,
  TextField,
} from "@app/components";
import {
  ActReportFilterValue,
  ActReportGroupParams,
  ActReportParams,
  ActStatus,
  ActStatusForFilters,
  ActStatusForFiltersName,
  Characteristic,
  Company,
  Project,
  ServiceType,
  VehicleType,
} from "@app/models";
import {
  APIResponse,
  getActReport,
  getActReportGroup,
  getActReportJson,
  getActReportJsonGroup,
  getCharacteristics,
  getCompaniesFiltered,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getServiceTypes,
  getVehicleTypes,
  PageableParams,
} from "@app/api";
import { ValidationError } from "yup";
import { useDebounce } from "@app/hooks";
import {
  companyLabelKeys,
  enumToArray,
  getAxiosErrorMessage,
  getDateFilterValue,
  getFilterValues,
  isDispatcher,
} from "@app/helpers";
import { IconClose24, IconDocument24, IconFilter24 } from "@app/icons";
import * as Styled from "./styled";
import { AxiosError } from "axios";
import { downloadFile } from "../../../helpers/downloadFile";
import { useNotification, useUser } from "@app/providers";
import { actStatusInitial, initialFiltersParams, schema } from "./constants";
import { FilterErrors, FilterSubmitData } from "./types";

type Props = {
  isGroup: boolean;
  downloadDisabled: boolean;
  CollapseButton: ReactElement;
  onSubmit: (data: FilterSubmitData) => void;
};

export const Filters = (props: Props) => {
  const { isGroup, downloadDisabled, onSubmit, CollapseButton } = props;

  const { user } = useUser();
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);

  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  const [filterParams, setFilterParams] = useState<
    Partial<ActReportFilterValue>
  >(JSON.parse(initialFiltersParams));

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      setFilterParams({ ...filterParams, [key]: value });
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    setFilterParams(JSON.parse(initialFiltersParams));
  }, []);

  const onClickFilter = useCallback(() => {
    setFilterPanelOpen((prev) => !prev);
  }, []);

  const hasFilters = useMemo(
    () => Object.entries(filterParams).length,
    [filterParams]
  );

  const loadCharacteristics = useCallback(
    (params: PageableParams<Characteristic>) => {
      return getCharacteristics({
        ...params,
        vehicleTypeId: "", // filterParams.vehicleTypeIds,
      });
    },
    []
  );

  const loadServiceTypes = useCallback(
    (params: PageableParams<ServiceType>) =>
      getServiceTypes({ ...params, characteristicId: "" }),
    []
  );
  const [filterErrors, setFilterErrors] = useState<FilterErrors>({});

  const loadData = useMemo(() => {
    return isDispatcher(user!.role) ? getCompaniesFiltered : getMyCompanies;
  }, [user]);

  const filtersValue = useDebounce(
    useMemo(
      (): Partial<ActReportGroupParams> => ({
        companyIds: getFilterValues(filterParams.companyIds),
        partnerIds: getFilterValues(filterParams.partnerIds),
        projectIds: getFilterValues(filterParams.projectIds),
        // "Хотелка" была такая: сделать кастомные 2 статуса (Согласованные и Несогл)
        status: getFilterValues(filterParams.status).reduce((acc, cur) => {
          if (+cur === ActStatusForFilters.Created) {
            acc.push(actStatusInitial);
          }
          if (+cur === ActStatusForFilters.Agreed) {
            acc.push(
              Object.values(ActStatus).filter(
                (s) => Number.isInteger(+s) && !actStatusInitial.includes(+s)
              )
            );
          }
          return acc;
        }, []),
        fromDate: getDateFilterValue(filterParams.fromDate),
        toDate: getDateFilterValue(filterParams.toDate),
        fromPrice: filterParams.fromPrice || undefined,
        toPrice: filterParams.toPrice || undefined,
        fromAmount: filterParams.fromAmount || undefined,
        toAmount: filterParams.toAmount || undefined,
        actNumber: filterParams.actNumber || undefined,
        vehicleIds: getFilterValues(filterParams.vehicleIds),
        vehicleTypeIds: getFilterValues(filterParams.vehicleTypeIds),
        serviceTypeIds: getFilterValues(filterParams.serviceTypeIds),
        characteristicIds: getFilterValues(filterParams.characteristicIds),
      }),
      [
        filterParams.companyIds,
        filterParams.partnerIds,
        filterParams.projectIds,
        filterParams.status,
        filterParams.fromDate,
        filterParams.toDate,
        filterParams.fromPrice,
        filterParams.toPrice,
        filterParams.fromAmount,
        filterParams.toAmount,
        filterParams.actNumber,
        filterParams.vehicleIds,
        filterParams.vehicleTypeIds,
        filterParams.serviceTypeIds,
        filterParams.characteristicIds,
      ]
    ),
    600
  );

  const onFilterApply = useCallback(async () => {
    try {
      await schema.validate(filtersValue, {
        abortEarly: false,
      });

      setLoading(true);
      onSubmit({
        reportData: null,
        reportDataGroup: null,
      });

      if (isGroup) {
        const response = await getActReportJsonGroup(filtersValue);

        onSubmit({
          reportData: [],
          reportDataGroup: response.data.reportGroupActByGovNumberDto,
          totalRows: response.data.totalDto,
        });
      } else {
        const response = await getActReportJson(filtersValue);

        onSubmit({
          reportData: response.data.reportActDto,
          reportDataGroup: [],
          totalRows: response.data.totalDto,
        });
      }
      setLoading(false);
    } catch (err) {
      if ((err as ValidationError).inner) {
        const tempErrors: FilterErrors = {};

        (err as ValidationError).inner?.forEach((error: ValidationError) => {
          tempErrors[error.path as keyof ActReportParams] = error.errors[0];
        });
        setFilterErrors(tempErrors);
        setTimeout(() => setFilterErrors({}), 3000);
      } else {
        showNotification({
          message: getAxiosErrorMessage(err as AxiosError<APIResponse>),
          variant: "error",
        });
        setLoading(false);
      }
    }
  }, [filtersValue, isGroup, onSubmit, showNotification]);

  const onReportDownload = useCallback(async () => {
    try {
      setLoading(true);

      let response;
      if (isGroup) {
        response = await getActReportGroup(filtersValue);
      } else {
        response = await getActReport(filtersValue);
      }

      const url = window.URL.createObjectURL(response);
      const filename = `Отчет по актам ${
        isGroup ? "(группированный)" : "(без группировки)"
      } ${filtersValue.fromDate} - ${filtersValue.toDate}`;
      downloadFile(url, filename);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [filtersValue, isGroup, showNotification]);

  const Collapsed = React.cloneElement(CollapseButton, {
    disabled: !hasFilters || loading,
  });

  const filters = useMemo(
    () => [
      <TextField
        label="Номер акта"
        name="actNumber"
        placeholder="Укажите номер"
        onChange={onFilterChange}
        value={filterParams.actNumber}
      />,
      <Combobox<ServiceType>
        label="Вид услуги"
        name="serviceTypeIds"
        onChange={onFilterChange}
        values={filterParams.serviceTypeIds}
        loadData={loadServiceTypes}
      />,
      <Combobox<VehicleType>
        label="Вид техники"
        name="vehicleTypeIds"
        onChange={onFilterChange}
        values={filterParams.vehicleTypeIds}
        loadData={getVehicleTypes}
      />,
      <Combobox<Characteristic>
        label="Характеристика техники"
        name="characteristicIds"
        onChange={onFilterChange}
        values={filterParams.characteristicIds}
        loadData={loadCharacteristics}
      />,
      <TextField
        label="Госномер"
        name="govNumber"
        placeholder="Укажите госномер"
        onChange={onFilterChange}
        value={filterParams.govNumber}
      />,
      <TextField
        label="Цена, от"
        name="fromPrice"
        type="number"
        placeholder="Укажите цену"
        onChange={onFilterChange}
        value={filterParams.fromPrice}
      />,
      <TextField
        label="Цена, до"
        name="toPrice"
        type="number"
        placeholder="Укажите цену"
        onChange={onFilterChange}
        value={filterParams.toPrice}
      />,
    ],
    [
      filterParams.actNumber,
      filterParams.characteristicIds,
      filterParams.fromPrice,
      filterParams.govNumber,
      filterParams.serviceTypeIds,
      filterParams.toPrice,
      filterParams.vehicleTypeIds,
      loadCharacteristics,
      loadServiceTypes,
      onFilterChange,
    ]
  );

  return (
    <>
      <Styled.Filter>
        <Styled.Grid>
          <DateTimePicker
            label="Дата, от"
            name="fromDate"
            onChange={onFilterChange}
            value={filterParams.fromDate}
            error={!!filterErrors.fromDate}
            helperText={filterErrors.fromDate}
            hideTime
          />
          <DateTimePicker
            label="Дата, до"
            name="toDate"
            onChange={onFilterChange}
            value={filterParams.toDate}
            error={!!filterErrors.toDate}
            helperText={filterErrors.toDate}
            hideTime
          />
          <Combobox<Company>
            label="Организация"
            name="companyIds"
            onChange={onFilterChange}
            values={filterParams.companyIds}
            labelKeys={companyLabelKeys}
            labelKeysSeparator={" / "}
            loadData={loadData}
          />
          <Combobox<Company>
            label="Контрагент"
            name="partnerIds"
            onChange={onFilterChange}
            values={filterParams.partnerIds}
            labelKeys={companyLabelKeys}
            labelKeysSeparator={" / "}
            loadData={getCompaniesFiltered}
          />
          <Combobox<Project>
            label="Проект"
            name="projectIds"
            onChange={onFilterChange}
            values={filterParams.projectIds}
            loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
          />
          <Combobox<DefaultObject<ActStatusForFilters>>
            label="Статус"
            name="status"
            onChange={onFilterChange}
            values={filterParams.status}
            options={enumToArray(ActStatusForFiltersName)}
          />
        </Styled.Grid>
        <Grid columns={5}>
          <Button
            variant="outlined"
            text="Расширенный фильтр"
            startIcon={IconFilter24}
            onClick={onClickFilter}
          />
          <Button
            variant="text"
            text="Сбросить фильтры"
            disabled={!hasFilters}
            startIcon={IconClose24}
            onClick={onFilterClear}
          />
          {Collapsed}
          <Button
            variant="outlined"
            text="Скачать отчет"
            startIcon={IconDocument24}
            showLoader={loading}
            disabled={downloadDisabled || loading}
            onClick={onReportDownload}
          />
          <Button
            text="Применить"
            showLoader={loading}
            disabled={loading}
            onClick={onFilterApply}
          />
        </Grid>
      </Styled.Filter>
      <Styled.FilterPanel open={filterPanelOpen}>
        <Grid columns={5}>
          {filters.map((filter, fIndex) => (
            <React.Fragment key={fIndex.toString()}>{filter}</React.Fragment>
          ))}
        </Grid>
      </Styled.FilterPanel>
    </>
  );
};
