import { memo } from "react";
import { IconEllipse, IconEllipseActive, IconPersonCircle32 } from "@app/icons";
import classNames from "classnames";
import "./styles.scss";
import styled from "styled-components";
import { Confirmer } from "@app/models";
import Tooltip from "../Tooltip";
import { getFullName } from "@app/helpers";

interface Props {
  title: string;
  user?: Confirmer;
  date?: Date;
  isActive?: boolean;
}
const IconUser = styled.div`
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;
function StatusStep(props: Props) {
  const { title, date, isActive, user } = props;

  return (
    <div className="status-step">
      {isActive ? <IconEllipseActive /> : <IconEllipse />}
      <div
        className={classNames("status-step__info", {
          "status-step__info--active": isActive,
        })}
      >
        <div>
          <p>{date?.toLocaleDateString()}</p>
          <p className="status-step__time">{date?.toLocaleTimeString()}</p>
        </div>
        <div className="status-step__title">
          <p>{title}</p>
        </div>
        <IconUser>
          <Tooltip content={getFullName(user)}>
            <IconPersonCircle32 color={"#1C2246"} />
          </Tooltip>
        </IconUser>
      </div>
    </div>
  );
}

export default memo(StatusStep);
