import React, { memo } from "react";
import styled from "styled-components";
import { Container, Grid } from "@app/components";
import content from "./content.json";
import { theme } from "styled-tools";
import { IconAppStore, IconGooglePlay } from "@app/icons";
import FlagSrc from "./flag.png";
import MembersSrc from "./members.png";
import BgSrc from "./bg.png";
import SectionTitle from "../SectionTitle";
import { useWindowWide } from "@app/hooks";

const StyledSection = styled.section`
  padding: 40px 0;
  box-sizing: border-box;

  @media only screen and (min-width: 576px) {
    background-image: url(${BgSrc});
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right;
  }
`;

const StyledTitle = styled(SectionTitle)`
  margin: 0 0 16px;
`;

const StyledText = styled(SectionTitle)`
  font-size: 18px;
  line-height: 24px;
  color: #69748c;
  margin: 0 0 60px;
  text-transform: none;

  @media only screen and (max-width: 576px) {
    margin: 0;
  }
`;

const StyledButton = styled.div`
  gap: 8px;
  padding: 8px 16px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${theme("color.grayLight")};
  display: inline-flex;

  img {
    width: 28px;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: ${theme("color.dark")};
  }
`;

const StyledMembers = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 16px;

  @media only screen and (max-width: 576px) {
    margin: 0 auto;
    text-align: center;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: ${theme("color.dark")};
  }

  img {
    width: 238px;
  }
`;

const StyledMadeIn = styled.div`
  @media only screen and (max-width: 576px) {
    margin: 0 auto;
  }
`;

function SectionInfo() {
  const isMobile = useWindowWide(576);

  const onAppStoreClick = () => {
    window.open(
      "https://apps.apple.com/kz/app/dispatch/id6449212365",
      "_blank"
    );
  };

  const onGooglePlayClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.dispatchcds&pli=1",
      "_blank"
    );
  };
  return (
    <StyledSection>
      <Container>
        <Grid columns={isMobile ? 1 : 2}>
          <div>
            <StyledTitle>{content.title}</StyledTitle>
            <StyledText>{content.text}</StyledText>
            {isMobile && <img src={BgSrc} alt="" width={"100%"} />}
            <Grid gap={50} center={isMobile}>
              <Grid gap={34}>
                <Grid columns={2} auto={true} gap={8} center={isMobile}>
                  <IconAppStore onClick={onAppStoreClick} />
                  <IconGooglePlay onClick={onGooglePlayClick} />
                </Grid>
                <StyledMadeIn>
                  <StyledButton>
                    <img src={FlagSrc} alt="" />
                    <span>Сделано в Казахстане</span>
                  </StyledButton>
                </StyledMadeIn>
              </Grid>
              <StyledMembers>
                <span>Являемся участниками</span>
                <img src={MembersSrc} alt="" />
              </StyledMembers>
            </Grid>
          </div>
        </Grid>
      </Container>
    </StyledSection>
  );
}

export default memo(SectionInfo);
