import { LayoutDefault } from "@app/layouts";
import React, { memo } from "react";
import styled from "styled-components";
import MonitoringSidebar from "./components/Sidebar";
import { GoogleMapProvider, MonitoringProvider } from "@app/providers";

const StyledContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

function PageMonitoring() {
  return (
    <LayoutDefault title="Мониторинг">
      <StyledContent>
        <GoogleMapProvider>
          <MonitoringProvider>
            <MonitoringSidebar />
          </MonitoringProvider>
        </GoogleMapProvider>
      </StyledContent>
    </LayoutDefault>
  );
}

export default memo(PageMonitoring);
