import React, { memo } from "react";
import { Modal, ModalProps } from "@app/components";
import styled from "styled-components";
import posterImage from "../../assets/icons/poster.svg";

interface Props extends Omit<ModalProps, "title"> {}

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 872px;
  overflow-y: auto;
`;

const VideoThumbnailContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
`;

const VideoThumbnail = styled.img`
  width: 100%;
`;

const VideoTitle = styled.div`
  position: absolute;
  bottom: 6px;
  left: 0;
  width: 97.5%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: center;
  padding: 5px;
`;

function ModalAddOrganizationInstruction(props: Props) {
  const { ...modalProps } = props;

  const onClickVideo = (videoSrc: string) => {
    const videoPath = `/video/${encodeURIComponent(videoSrc)}`;
    const newWindow = window.open(videoPath, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Modal title="Инструкция по добавлению спецтехники" {...modalProps}>
      <StyledModalContent>
        <VideoThumbnailContainer
          onClick={() => onClickVideo("/addVehicle.mp4")}
        >
          <VideoTitle>Добавление техники</VideoTitle>
          <VideoThumbnail src={posterImage} alt="Предпросмотр видео" />
        </VideoThumbnailContainer>
      </StyledModalContent>
    </Modal>
  );
}

export default memo(ModalAddOrganizationInstruction);
