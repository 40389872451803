import { PageableParams, PageableResponse } from "./interfaces";
import { ServiceType } from "@app/models";
import instance from "./instance";

export function getServiceTypes(
  params: PageableParams<ServiceType> & {
    characteristicId: string;
  }
): Promise<PageableResponse<ServiceType>> {
  return instance()
    .get("/service-type", {
      params,
    })
    .then((response) => response.data);
}
