import React, { memo, useCallback, useState } from "react";
import { Button, Modal, ModalProps, TextField } from "@app/components";

interface Props extends Pick<ModalProps, "open" | "onClose" | "title"> {
  buttonText: string;
  onSubmit: (comment: string) => Promise<void>;
}

const ModalRevokeOrReject = (props: Props) => {
  const { buttonText, onClose, onSubmit, ...restProps } = props;
  const [pending, setPending] = useState<boolean>(false);
  const [comment, setComment] = useState("");

  const onChangeComment = useCallback((value: string) => {
    setComment(value);
  }, []);

  const onConfirm = useCallback(async () => {
    try {
      setPending(true);

      await onSubmit(comment);

      setPending(true);

      onClose();
    } catch (e) {
      setPending(false);
    }
  }, [comment, onClose, onSubmit]);

  return (
    <Modal
      {...restProps}
      actions={[
        <Button
          text={buttonText}
          onClick={onConfirm}
          showLoader={pending}
          disabled={pending}
        />,
      ]}
      onClose={onClose}
    >
      <TextField
        label="Комментарий"
        placeholder="Укажите причину"
        value={comment}
        name="comment"
        onChange={onChangeComment}
      />
    </Modal>
  );
};

export default memo(ModalRevokeOrReject);
