import { object, string } from "yup";
import { ActStatus } from "@app/models";

export const schema = object({
  fromDate: string().required("Укажите дату"),
  toDate: string().required("Укажите дату"),
});

export const initialFiltersParams = JSON.stringify({});

export const actStatusInitial = [
  ActStatus.Created,
  ActStatus.ToBeAgreed,
  ActStatus.ActRevoked,
];
