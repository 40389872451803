import { APIResponse, PageableResponse } from "./interfaces";
import { Contract, ContractActParams, SmartContract } from "@app/models";
import instance from "./instance";
import { AxiosResponse } from "axios";

export function getContracts(): Promise<PageableResponse<Contract>> {
  return instance()
    .get("/contract")
    .then((response) => response.data);
}

export function getContractDetail(id: string): Promise<APIResponse<Contract>> {
  return instance()
    .get(`/contractDraft/${id}`)
    .then((response) => response.data);
}

export function getContractReference(id: string): Promise<AxiosResponse<Blob>> {
  return instance().get(`/contract/reference/${id}`, {
    responseType: "blob",
  });
}

export function getContractsForAct(
  params: ContractActParams
): Promise<PageableResponse<SmartContract>> {
  const { customerId, projectId, partnerId } = params;
  return instance()
    .get(`/contract/${customerId}/${projectId}/${partnerId}`)
    .then((response) => response.data);
}
