import React, { memo, useCallback, useMemo, useState } from "react";
import { Button, Modal, ModalProps } from "@app/components";
import styled from "styled-components";
import { Task } from "@app/models";
import { useNotification } from "@app/providers";
import * as ncaLayer from "@app/helpers";
import { APIResponse, signSmartContract } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

interface Props extends Omit<ModalProps, "title"> {
  task: Task | null;
  fileUrl: string | null | undefined;
  file?: Blob;
  onSignSuccess: () => void;
}

const StyledContent = styled.div`
  height: 100%;
  display: block;
`;

function ModalSigning(props: Props) {
  const {
    onClose,
    onSignSuccess,
    fileUrl,
    file: fileBlob,
    task,
    ...restProps
  } = props;
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const blobToFile = useCallback((blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise<string>((res) => {
      reader.onloadend = () => {
        res(reader.result as string);
      };
    });
  }, []);

  const convertURLToBase64 = useCallback(
    async (url: string) => {
      return await fetch(url)
        .then((response) => response.blob())
        .then(blobToFile);
    },
    [blobToFile]
  );

  const signContract = useCallback(
    async (data: any) => {
      if (data && data.responseObject && task?.id) {
        try {
          setLoading(true);
          const body = {
            id: task?.id,
            signedFile: data.responseObject,
          };
          const res = await signSmartContract(body);

          setLoading(false);

          if (res && res.succeeded) {
            showNotification({
              message: "Договор подписан успешно",
              variant: "success",
            });
            onSignSuccess();
            onClose();
          }
        } catch (e) {
          setLoading(false);

          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });
        }
      }
    },
    [onClose, onSignSuccess, showNotification, task?.id]
  );

  const onClickSave = useCallback(async () => {
    if (!ncaLayer.checkWebsocket()) {
      ncaLayer.initializeLayer();
    }

    let base64;
    if (fileUrl) {
      base64 = await convertURLToBase64(fileUrl);
    } else if (fileBlob) {
      base64 = await blobToFile(fileBlob);
    }

    if (!base64) {
      console.error("No file");
      return;
    }

    const base64Url = base64.split(",").pop();
    console.log({ base64Url });

    const storageType = {
      type: "PKCS12",
      name: "Компьютер",
    };
    const signType = {
      type: String,
      default: "SIGNATURE",
    };

    // const tsCallback = async (data: any) => {
    //   await signContract(data);
    // };

    const callback = async (data: any) => {
      console.log("callback data?", data);

      await signContract(data);
      // if (data && data.responseObject && task?.id) {
      //   ncaLayer.applyCAdEST(
      //     storageType.type,
      //     signType.default,
      //     data.responseObject,
      //     tsCallback
      //   );
      // }
    };

    ncaLayer.createCMSSignatureFromBase64(
      storageType.type,
      signType.default,
      base64Url,
      true,
      callback
    );
    // onClose();
    // createCAdESFromBase64 true status 200
  }, [blobToFile, convertURLToBase64, fileBlob, fileUrl, signContract]);

  const modalTitle = useMemo(() => "Подписать документ", []);

  const modalActions = useMemo(
    () => [<Button text="Подписать" onClick={onClickSave} />],
    [onClickSave]
  );

  if (!restProps.open) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      onClose={onCloseModal}
      {...restProps}
      position="center"
      actions={modalActions}
    >
      <StyledContent>
        Нажмите Подписать и выберите ЭЦП для подписи. Необходимо проделать
        процедуру дважды.
      </StyledContent>
    </Modal>
  );
}

export default memo(ModalSigning);
