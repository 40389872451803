import React, { memo } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import styled from "styled-components";
import BGSrc from "./bg.png";
import content from "./content.json";
import { Grid } from "@app/components";

const StyledPanelWorkSearch = styled(StyledPanel)`
  @media only screen and (max-width: 576px) {
    background: none;
    height: 280px;
  }
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 4;
  height: 480px;
  background-image: url(${BGSrc});
  background-size: 262px;
  background-repeat: no-repeat;
  background-position: bottom right;
`;

function PanelWorkSearch() {
  return (
    <StyledPanelWorkSearch>
      <Grid gap={12}>
        <StyledPanelTitle>{content.title}</StyledPanelTitle>
        <StyledPanelText>{content.text}</StyledPanelText>
        <StyledPanelImageMobile src={BGSrc} width={"100%"} alt="" />
      </Grid>
    </StyledPanelWorkSearch>
  );
}

export default memo(PanelWorkSearch);
