import React, {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { ModalCookiePolicy } from "@app/modals";
import Cookies from "js-cookie";

interface Props extends PropsWithChildren {}

interface CookiePolicyContextValue {
  canUseCookie: boolean;
}

const CookiePolicyContext = createContext<CookiePolicyContextValue>(
  {} as CookiePolicyContextValue
);

function CookiePolicyProvider(props: Props) {
  const { children } = props;
  const [canUse, setCanUse] = useState(!!Cookies.get("canUsePrivacyCookie"));
  const [modalVisible, setModalVisible] = useState(!canUse);

  const onAccept = useCallback(() => {
    Cookies.set("canUsePrivacyCookie", "true");

    setCanUse(true);
    setModalVisible(false);
  }, []);

  const onDecline = useCallback(() => {
    Cookies.remove("canUsePrivacyCookie");

    setCanUse(false);
    setModalVisible(false);
  }, []);

  return (
    <CookiePolicyContext.Provider
      value={{
        canUseCookie: canUse,
      }}
    >
      {children}
      <ModalCookiePolicy
        open={modalVisible}
        onAccept={onAccept}
        onDecline={onDecline}
      />
    </CookiePolicyContext.Provider>
  );
}

export function useCookiePolicy(): CookiePolicyContextValue {
  return useContext(CookiePolicyContext);
}

export default memo(CookiePolicyProvider);
