import React, { useCallback, useMemo } from "react";
import {
  DateTimePicker,
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import {
  Company,
  Nomenclature,
  Project,
  ServiceType,
  Unit,
  VehicleListItem,
} from "@app/models";
import {
  APIResponse,
  createVAERequest,
  getCompaniesFiltered,
  getMyCompanies,
  getMyProjects,
  getNomenclatures,
  getProjects,
  getUnits,
  getVehicles,
  PageableParams,
} from "@app/api";
import { AxiosError } from "axios";
import { useNotification, useUser } from "@app/providers";
import {
  companyLabelKeys,
  getAxiosErrorMessage,
  isDispatcher,
} from "@app/helpers";

interface Props {
  onSuccess: () => void;
}

interface Data {
  organization: SelectOption<Company> | null;
  project: SelectOption<Project> | null;
  partner: SelectOption<Company> | null;
  vehicle: SelectOption<VehicleListItem> | null;
  serviceType: SelectOption<ServiceType> | null;
  nomenclature: SelectOption<Nomenclature> | null;
  unit: SelectOption<Unit> | null;
  startDate: Date | null;
  endDate: Date | null;
  comment: string;
  volume: string;
}

const schema = yup.object().shape({
  organization: yup.object().nullable().required("required"),
  project: yup.object().nullable().required(),
  partner: yup.object().nullable().required(),
  nomenclature: yup.object().nullable().required(),
  vehicle: yup.object().nullable().required(),
  unit: yup.object().nullable().required(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  volume: yup.string().required(),
});

function FormVAERequest(props: Props) {
  const { onSuccess } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const { pending, onChange, errors, values, validate, setPending } =
    useForm<Data>({
      values: {
        organization: null,
        project: null,
        partner: null,
        vehicle: null,
        serviceType: null,
        nomenclature: null,
        unit: null,
        startDate: null,
        endDate: null,
        comment: "",
        volume: "",
      },
      schema,
    });

  const loadUnits = useCallback(
    (params: PageableParams<Unit>) => {
      return getUnits({
        ...params,
        nomenclatureId: values.nomenclature!.value,
      });
    },
    [values.nomenclature]
  );

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const response = await createVAERequest({
        customerId: values.organization!.value,
        projectId: values.project!.value,
        partnerId: values.partner!.value,
        vehicleId: values.vehicle!.value,
        nomenclatureId: values.nomenclature!.value,
        unitId: values.unit!.value,
        startDate: values.startDate!.toJSON(),
        endDate: values.endDate!.toJSON(),
        comments: values.comment,
        volume: values.volume,
      });

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заказ на дополнительные затраты добавлен",
        variant: "success",
      });

      onSuccess();
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [values, onSuccess, showNotification, setPending, validate]);

  const loadVehicles = useCallback(
    (params: PageableParams<VehicleListItem>) => {
      return getVehicles({
        ...params,
        companyId: values.partner!.value,
      });
    },
    [values.partner]
  );

  const vehicleLabelKeys = useMemo<(keyof VehicleListItem)[]>(
    () => ["vehicleType", "govNumber"],
    []
  );

  return (
    <Form onSubmit={onSubmitForm} pending={pending} submitLabel={"Создать"}>
      <Grid>
        <Select<Company>
          label="Организация"
          name="organization"
          onChange={onChange}
          value={values.organization}
          error={!!errors.organization}
          helperText={errors.organization}
          valueKey="id"
          labelKeys={companyLabelKeys}
          labelKeysSeparator={" / "}
          loadData={
            isDispatcher(user!.role) ? getCompaniesFiltered : getMyCompanies
          }
        />
        <Select<Project>
          label="Проект"
          name="project"
          onChange={onChange}
          value={values.project}
          error={!!errors.project}
          helperText={errors.project}
          valueKey="id"
          labelKey="name"
          loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
        />
        <Select<Company>
          label="Контрагент"
          name="partner"
          onChange={onChange}
          value={values.partner}
          error={!!errors.partner}
          helperText={errors.partner}
          valueKey="id"
          labelKeys={companyLabelKeys}
          labelKeysSeparator={" / "}
          loadData={getCompaniesFiltered}
        />
        <Select<Nomenclature>
          label="Номенклатура"
          name="nomenclature"
          onChange={onChange}
          value={values.nomenclature}
          error={!!errors.nomenclature}
          helperText={errors.nomenclature}
          valueKey="id"
          labelKey="name"
          loadData={getNomenclatures}
        />
        <Select<VehicleListItem>
          label="Техника"
          name="vehicle"
          onChange={onChange}
          value={values.vehicle}
          error={!!errors.vehicle}
          helperText={errors.vehicle}
          valueKey="id"
          labelKeys={vehicleLabelKeys}
          loadData={loadVehicles}
          disabled={!values.partner}
        />
        <Select<Unit>
          label="Единица измерения работ"
          name="unit"
          onChange={onChange}
          value={values.unit}
          error={!!errors.unit}
          helperText={errors.unit}
          valueKey="id"
          labelKey="name"
          loadData={loadUnits}
          disabled={!values.nomenclature}
        />
        <TextField
          label="Количество"
          placeholder="Введите количество"
          name="volume"
          type="number"
          onChange={onChange}
          onBlur={() => {
            onChange(Math.ceil(Math.abs(+values.volume)).toString(), "volume");
          }}
          value={values.volume}
          error={!!errors.volume}
          helperText={errors.volume}
          inputProps={{
            min: 1,
          }}
        />
        <Grid columns={2}>
          <DateTimePicker
            label="Дата начала"
            name="startDate"
            onChange={onChange}
            value={values.startDate}
            error={!!errors.startDate}
            helperText={errors.startDate}
            paperTop={true}
          />
          <DateTimePicker
            label="Дата завершения"
            name="endDate"
            onChange={onChange}
            value={values.endDate}
            error={!!errors.endDate}
            helperText={errors.endDate}
            paperTop={true}
          />
        </Grid>
        <TextField
          label="Комментарий"
          placeholder="Введите текст"
          name="comment"
          maxLength={250}
          onChange={onChange}
          value={values.comment}
          error={!!errors.comment}
          helperText={errors.comment}
          multiline={true}
        />
      </Grid>
    </Form>
  );
}

export default FormVAERequest;
