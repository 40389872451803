import React, { useCallback, useEffect, useState } from "react";
import { useNotification, useUser } from "@app/providers";
import {
  FilesPicker,
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import {
  Company,
  CompanyListItem,
  VehicleListItem,
  VehicleRequest,
} from "@app/models";
import {
  APIResponse,
  createVehicleRequestOffer,
  getCompanyList,
  getMyCompanies,
  getVehicles,
  PageableParams,
} from "@app/api";
import {
  emptyPageableResponse,
  getAxiosErrorMessage,
  isDispatcher,
  joinValues,
} from "@app/helpers";
import { AxiosError } from "axios";

interface Props {
  request: VehicleRequest;
  onSuccess: () => void;
}

interface Data {
  organization: SelectOption<CompanyListItem> | null;
  vehicle: SelectOption<VehicleListItem> | null;
  price: string;
  phoneNumber: string;
  email: string;
  comment: string;
  files: File[];
}

const schema = yup.object().shape({
  organization: yup.object().nullable().required("required"),
  vehicle: yup.object().nullable().required("required"),
  price: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().required(),
  comment: yup.string().required(),
});

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

function VehicleRequestOffer(props: Props) {
  const { request, onSuccess } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [companies, setCompanies] = useState<SelectOption<any>[]>([]);
  // companyChanged нужен для перерендера селекта и вызова списка в нем
  const [companyChanged, setCompanyChanged] = useState(true);

  const { pending, onChange, values, errors, validate, setPending } =
    useForm<Data>({
      values: {
        organization: null,
        vehicle: null,
        price: "",
        phoneNumber: "",
        email: "",
        comment: "",
        files: [],
      },
      schema,
    });

  const onChangeFiles = useCallback(
    (files: File[]) => {
      onChange(files, "files");
    },
    [onChange]
  );

  const loadVehicles = useCallback(
    async (params: PageableParams<VehicleListItem>) => {
      const companyId = values.organization?.value;
      if (companyId) {
        return await getVehicles({
          ...params,
          companyId,
        });
      } else {
        return emptyPageableResponse;
      }
    },
    [values.organization?.value]
  );

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const response = await createVehicleRequestOffer({
        requestId: request.id,
        companyId: values.organization!.value,
        vehicleId: values.vehicle!.value,
        price: Number(values.price),
        phone: values.phoneNumber,
        email: values.email,
        comments: values.comment,
        ndsId: values.organization!.item!.nds.id,
        files: values.files,
      });

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заявка на добавление техники отправлена на модерацию",
        variant: "success",
      });

      onSuccess();

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [request, onSuccess, setPending, showNotification, validate, values]);

  const loadCompanies = useCallback(
    async (params: any) => {
      try {
        let response;
        if (isDispatcher(user!.role)) {
          response = await getCompanyList(params);
        } else {
          response = await getMyCompanies(params);
        }

        const formattedData = response.data.map(
          (company: CompanyListItem | Company) => {
            const type = "type" in company ? company.type : company.companyType;
            const bin = company.bin ? `(${company.bin})` : "";
            return {
              value: company.id,
              label: joinValues([company.name, type.name, bin], " "),
              item: company,
            };
          }
        );

        setCompanies(formattedData);
      } catch (error) {
        console.error("Ошибка при загрузке списка компаний: ", error);
      }
    },
    [user]
  );

  useEffect(() => {
    (async () => {
      await loadCompanies(initialParams);
    })();
  }, [loadCompanies]);

  const onCompanyChange = useCallback(
    (value: SelectOption<CompanyListItem> | null, name: any) => {
      setCompanyChanged(false);
      onChange(value, name);
      onChange(null, "vehicle");
      setTimeout(() => {
        setCompanyChanged(true);
      }, 200);
    },
    [onChange]
  );

  return (
    <Form onSubmit={onSubmitForm} pending={pending} dense={false}>
      <Grid>
        <Select<CompanyListItem>
          label="Организация"
          name="organization"
          onChange={onCompanyChange}
          value={values.organization}
          error={!!errors.organization}
          helperText={errors.organization}
          valueKey="id"
          labelKey="name"
          options={companies}
        />
        <Grid columns={2}>
          <TextField
            label="Рейтинг компании"
            placeholder="Введите рейтинг компании"
            name="price"
            onChange={onChange}
            value=""
            disabled={true}
          />
          <Select<VehicleListItem>
            label="Техника"
            name="vehicle"
            value={values.vehicle}
            error={!!errors.vehicle}
            helperText={errors.vehicle}
            valueKey="id"
            labelKey="name"
            disabled={!values.organization || !companyChanged}
            loadData={loadVehicles}
            onChange={onChange}
          />
          <TextField
            label="Цена"
            placeholder="Введите цену"
            name="price"
            priceFormat={true}
            onChange={onChange}
            value={values.price}
            error={!!errors.price}
            helperText={errors.price}
          />
          <TextField
            label="Ставка НДС"
            value={
              values.organization ? values.organization.item!.nds.name : ""
            }
            disabled={true}
          />
          <TextField
            label="Номер телефона"
            placeholder="Введите номер телефона"
            name="phoneNumber"
            onChange={onChange}
            value={values.phoneNumber}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
          <TextField
            label="Email"
            placeholder="Введите Email"
            name="email"
            onChange={onChange}
            value={values.email}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <TextField
          label="Комментарий"
          placeholder="Введите текст"
          name="comment"
          onChange={onChange}
          value={values.comment}
          error={!!errors.comment}
          helperText={errors.comment}
          multiline={true}
        />
        <FilesPicker
          files={values.files}
          onChange={onChangeFiles}
          horizontal={true}
        />
      </Grid>
    </Form>
  );
}

export default VehicleRequestOffer;
