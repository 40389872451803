import { APIResponse } from "./interfaces";
import {
  ActReportGroupedResponse,
  ActReportGroupParams,
  ActReportParams,
  ActReportResponse,
} from "@app/models";
import instance from "./instance";

export function getActReportJson(
  params: Partial<ActReportParams>
): Promise<APIResponse<ActReportResponse>> {
  return instance()
    .get("/report/by-act/to-json", {
      params,
    })
    .then((response) => response.data);
}

export function getActReportJsonGroup(
  params: Partial<ActReportGroupParams>
): Promise<APIResponse<ActReportGroupedResponse>> {
  return instance()
    .get("/report/group/by-act/to-json", {
      params,
    })
    .then((response) => response.data);
}

export function getActReport(params: Partial<ActReportParams>): Promise<Blob> {
  return instance()
    .get("/report/by-act", {
      params,
      responseType: "blob",
    })
    .then((response) => response.data);
}

export function getActReportGroup(
  params: Partial<ActReportParams>
): Promise<Blob> {
  return instance()
    .get("/report/group/by-act", {
      params,
      responseType: "blob",
    })
    .then((response) => response.data);
}
