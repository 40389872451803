import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  DateTimePicker,
  Select,
  SelectOption,
  Toggle,
  useForm,
} from "@app/components";
import {
  Characteristic,
  Company,
  Holding,
  Project,
  Region,
  ServiceType,
  VehicleType,
} from "@app/models";
import * as yup from "yup";
import { theme } from "styled-tools";
import { IconChevronDown24, IconChevronUp24, IconFilter24 } from "@app/icons";
import { companyLabelKeys, dateFormat, isDispatcher } from "@app/helpers";
import {
  getCharacteristics,
  getCompanies,
  getHoldings,
  GetKIPDTO,
  getMyCompanies,
  getMyHoldings,
  getMyProjects,
  getProjects,
  getRegions,
  getServiceTypes,
  getVehicleTypes,
  PageableParams,
} from "@app/api";
import { useKIP, useUser } from "@app/providers";
import { subDays } from "date-fns";
import Alert from "../Alert";
import { ModalKIPInformation } from "@app/modals";

interface Props {
  onDateSelect: (date: string) => void;
}

interface FormData {
  startDate: Date | null;
  endDate: Date | null;
  holding: SelectOption<Holding> | null;
  organization: SelectOption<Company> | null;
  region: SelectOption<Region> | null;
  vehicleType: SelectOption<VehicleType> | null;
  characteristic: SelectOption<Characteristic> | null;
  serviceType: SelectOption<ServiceType> | null;
  project: SelectOption<Project> | null;
}

const schema = yup.object().shape({
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  holding: yup.object().nullable().required(),
  organization: yup.object().nullable().required(),
  region: yup.object().nullable().required(),
  vehicleType: yup.object().nullable().required(),
  characteristic: yup.object().nullable().required(),
  serviceType: yup.object().nullable().required(),
  project: yup.object().nullable().required(),
});

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${theme("color.white")};
  padding: 24px;
  box-sizing: border-box;
`;

const StyledGrid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(5, 1fr);
  align-items: end;
`;

const StyledShowMore = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  gap: 8px;
`;

const StyledShowMoreText = styled.div`
  margin: 0;
  color: ${theme("color.dark")};
  font-size: 16px;
  line-height: 24px;
`;

const ToggleContainer = styled.div`
  min-width: 248px;
`;

const ApplyRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export default function (props: Props) {
  const { user } = useUser();
  const { onDateSelect } = props;
  const { getData } = useKIP();
  const [groupedByDefault, setGroupedByDefault] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { values, onChange, pending, setPending, errors } = useForm<FormData>({
    values: {
      startDate: subDays(new Date(), 8),
      endDate: subDays(new Date(), 1),
      holding: null,
      organization: null,
      region: null,
      vehicleType: null,
      characteristic: null,
      serviceType: null,
      project: null,
    },
    schema,
  });
  const [moreVisible, setMoreVisible] = useState<boolean>(false);

  const onClickShowMore = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setMoreVisible((prevMoreVisible) => !prevMoreVisible);
    },
    []
  );

  const onSubmit = useCallback(async () => {
    try {
      setPending(true);

      const data: GetKIPDTO = {
        startDate: dateFormat(values.startDate!, "yyyy-MM-dd"),
        endDate: dateFormat(values.endDate!, "yyyy-MM-dd"),
        fromActs: groupedByDefault,
      };
      // для отображения даты в модалке
      const date1 = dateFormat(values.startDate!, "dd.MM.yyyy");
      const date2 = dateFormat(values.endDate!, "dd.MM.yyyy");
      onDateSelect(`${date1} - ${date2}`);

      if (!!values.holding) {
        data.holdingId = values.holding.value;
      }

      if (!!values.organization) {
        data.companyId = values.organization.value;
      }

      if (!!values.region) {
        data.regionId = values.region.value;
      }

      if (!!values.vehicleType) {
        data.vehicleTypeId = values.vehicleType.value;
      }

      if (!!values.characteristic) {
        data.characteristicsId = values.characteristic.value;
      }

      if (!!values.serviceType) {
        data.serviceTypeId = values.serviceType.value;
      }

      if (!!values.project) {
        data.projectId = values.project.value;
      }

      await getData(data);

      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [setPending, values, groupedByDefault, getData, onDateSelect]);

  const onSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      onSubmit();
    },
    [onSubmit]
  );

  const loadCharacteristics = useCallback(
    (params: PageableParams<Characteristic>) => {
      return getCharacteristics({
        ...params,
        vehicleTypeId: values.vehicleType!.value,
      });
    },
    [values.vehicleType]
  );

  const loadServiceTypes = useCallback(
    (params: PageableParams<ServiceType>) => {
      return getServiceTypes({
        ...params,
        characteristicId: values.characteristic!.value,
      });
    },
    [values.characteristic]
  );

  const onChangeGroup = useCallback((value: boolean) => {
    setGroupedByDefault(value);
  }, []);

  const onAlertClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    // Отключен запрос данных при изменении фильтров
    // onSubmit();
  }, [onSubmit]);

  return (
    <>
      <StyledForm onSubmit={onSubmitForm}>
        <StyledGrid>
          <DateTimePicker
            label="Дата от"
            name="startDate"
            hideTime={true}
            onChange={onChange}
            value={values.startDate}
          />
          <DateTimePicker
            label="Дата до"
            name="endDate"
            hideTime={true}
            minDate={values.startDate}
            onChange={onChange}
            value={values.endDate}
          />
          <Select<Holding>
            label="Холдинг"
            name="holding"
            onChange={onChange}
            value={values.holding}
            error={!!errors.holding}
            helperText={errors.holding}
            valueKey="id"
            labelKey="name"
            loadData={isDispatcher(user!.role) ? getHoldings : getMyHoldings}
          />
          <Select<Company>
            label="Организация"
            name="organization"
            onChange={onChange}
            value={values.organization}
            error={!!errors.organization}
            helperText={errors.organization}
            valueKey="id"
            labelKeys={companyLabelKeys}
            labelKeysSeparator={" / "}
            loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
          />
          <ToggleContainer>
            <Toggle
              negativeLabel="По журналам"
              positiveLabel="По табелям"
              value={groupedByDefault}
              dark
              onClick={onChangeGroup}
            />
          </ToggleContainer>
        </StyledGrid>
        <StyledShowMore onClick={onClickShowMore}>
          <IconFilter24 />
          <StyledShowMoreText>Расширенный фильтр</StyledShowMoreText>
          {moreVisible ? <IconChevronUp24 /> : <IconChevronDown24 />}
        </StyledShowMore>
        {moreVisible && (
          <StyledGrid>
            <Select<Region>
              label="Регион"
              name="region"
              onChange={onChange}
              value={values.region}
              valueKey="id"
              labelKey="name"
              loadData={getRegions}
            />
            <Select<VehicleType>
              label="Тип ТС"
              name="vehicleType"
              onChange={onChange}
              value={values.vehicleType}
              valueKey="id"
              labelKey="name"
              loadData={getVehicleTypes}
            />
            <Select<Characteristic>
              label="Характеристика техники"
              name="characteristic"
              onChange={onChange}
              value={values.characteristic}
              error={!!errors.characteristic}
              helperText={errors.characteristic}
              valueKey="id"
              labelKey="name"
              disabled={!values.vehicleType}
              loadData={loadCharacteristics}
            />
            <Select<ServiceType>
              label="Вид услуги"
              name="serviceType"
              onChange={onChange}
              value={values.serviceType}
              error={!!errors.serviceType}
              helperText={errors.serviceType}
              valueKey="id"
              labelKey="name"
              disabled={!values.characteristic}
              loadData={loadServiceTypes}
            />
            <Select<Project>
              label="Проект"
              name="project"
              onChange={onChange}
              value={values.project}
              valueKey="id"
              labelKey="name"
              loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
            />
          </StyledGrid>
        )}
        <ApplyRow>
          <Button
            text="Применить"
            type="submit"
            disabled={pending || !values.startDate || !values.endDate}
            showLoader={pending}
          />
          <Alert
            text={
              "Чем лучше организованы погрузочно-разгрузочные работы и меньше непроизводительные простои, тем выше коэффициент использования рабочего времени"
            }
            onClick={onAlertClick}
          />
        </ApplyRow>
      </StyledForm>

      <ModalKIPInformation open={showModal} onClose={onModalClose} />
    </>
  );
}
