import React, { memo, useCallback } from "react";
import {
  FilesPicker,
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import { useNotification } from "@app/providers";
import {
  Characteristic,
  Company,
  GPS,
  VehicleModel,
  VehicleType,
} from "@app/models";
import {
  APIResponse,
  createVehicle,
  getCharacteristics,
  getGps,
  getMyCompanies,
  getVehicleModels,
  getVehicleTypes,
  PageableParams,
} from "@app/api";
import * as yup from "yup";
import { AxiosError } from "axios";
import { companyLabelKeys, getAxiosErrorMessage } from "@app/helpers";

interface Props {
  onSuccess: () => void;
}

interface Data {
  organization: SelectOption<Company> | null;
  vehicleType: SelectOption<VehicleType> | null;
  vehicleModel: SelectOption<VehicleModel> | null;
  characteristic: SelectOption<Characteristic> | null;
  govNumber: string;
  gps: SelectOption<GPS> | null;
  files: File[];
}

const schema = yup.object().shape({
  organization: yup.object().nullable().required("required"),
  vehicleType: yup.object().nullable().required(),
  vehicleModel: yup.object().nullable().required(),
  characteristic: yup.object().nullable().required(),
  govNumber: yup.string().required(),
  gps: yup.object().nullable().required(),
});

function FormVehicle(props: Props) {
  const { onSuccess } = props;
  const { showNotification } = useNotification();
  const { values, errors, pending, validate, setPending, onChange } =
    useForm<Data>({
      values: {
        organization: null,
        vehicleType: null,
        vehicleModel: null,
        characteristic: null,
        gps: null,
        govNumber: "",
        files: [],
      },
      schema,
    });

  const onChangeFiles = useCallback(
    (files: File[]) => {
      onChange(files, "files");
    },
    [onChange]
  );

  const loadCharacteristics = useCallback(
    (params: PageableParams<Characteristic>) => {
      return getCharacteristics({
        ...params,
        vehicleTypeId: values.vehicleType!.value,
      });
    },
    [values.vehicleType]
  );

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const response = await createVehicle({
        companyIds: [values.organization!.value],
        vehicleTypeId: values.vehicleType!.value,
        vehicleModelId: values.vehicleModel!.value,
        characteristicsId: values.characteristic!.value,
        govNumber: values.govNumber,
        gpsId: values.gps!.value,
        files: values.files,
      });

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заявка на добавление техники отправлена на модерацию",
        variant: "success",
      });

      onSuccess();
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [onSuccess, showNotification, values, setPending, validate]);

  return (
    <Form onSubmit={onSubmitForm} pending={pending}>
      <Grid>
        <Select<Company>
          label="Организация"
          name="organization"
          onChange={onChange}
          value={values.organization}
          error={!!errors.organization}
          helperText={errors.organization}
          valueKey="id"
          labelKeys={companyLabelKeys}
          labelKeysSeparator={" / "}
          loadData={getMyCompanies}
        />
        <Select<VehicleType>
          label="Вид техники"
          name="vehicleType"
          onChange={onChange}
          value={values.vehicleType}
          error={!!errors.vehicleType}
          helperText={errors.vehicleType}
          valueKey="id"
          labelKey="name"
          loadData={getVehicleTypes}
        />
        <Select<VehicleModel>
          label="Модель"
          name="vehicleModel"
          onChange={onChange}
          value={values.vehicleModel}
          error={!!errors.vehicleModel}
          helperText={errors.vehicleModel}
          valueKey="id"
          labelKey="name"
          loadData={getVehicleModels}
        />
        <Select<Characteristic>
          label="Характеристика техники"
          name="characteristic"
          onChange={onChange}
          value={values.characteristic}
          error={!!errors.characteristic}
          helperText={errors.characteristic}
          valueKey="id"
          labelKey="name"
          disabled={!values.vehicleType}
          loadData={loadCharacteristics}
        />
        <TextField
          label="Гос номер"
          placeholder="Введите гос номер"
          name="govNumber"
          onChange={onChange}
          value={values.govNumber}
          error={!!errors.govNumber}
          helperText={errors.govNumber}
        />
        <Select<GPS>
          label="Провайдер GPS"
          name="gps"
          onChange={onChange}
          value={values.gps}
          error={!!errors.gps}
          helperText={errors.gps}
          valueKey="id"
          labelKey="name"
          loadData={getGps}
        />
        <FilesPicker files={values.files} onChange={onChangeFiles} />
      </Grid>
    </Form>
  );
}

export default memo(FormVehicle);
