import { LayoutDefault } from "@app/layouts";
import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { useNotification, useUser } from "@app/providers";
import { Button, useForm } from "@app/components";
import {
  APIResponse,
  getAccountRegion,
  getRegions,
  postAccountRegion,
} from "@app/api";
import RegionMultiSelect from "./components/RegionMultiSelect";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

const StyledContainer = styled.div`
  display: flex;
  height: calc(100% - 32px);
`;

const StyledGrid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 16px;
  background: ${theme("color.white")};
  flex: 1;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

const StyledField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledIcon = styled.div`
  margin-left: 8px;
  margin-top: 8px;
  cursor: pointer;
`;

const StyledHeader = styled.span`
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
`;

const StyledText = styled.span`
  color: #636463;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
`;

const StyledButton = styled.div`
  width: 300px;
`;

const StyledAcceptButton = styled.div`
  width: 200px;
  margin-left: 8px;
`;

interface Data {
  region: { name: string; id: string }[];
}

function PageSettings() {
  const { user } = useUser();
  const { showNotification } = useNotification();
  const { pending, onChange, values, errors, validate, setPending, setValues } =
    useForm<Data>({
      values: {
        region: [],
      },
    });

  const [regions, setRegions] = useState<{ name: string; id: string }[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const accountRegionsResponse = await getAccountRegion();
        const selectedRegions = accountRegionsResponse.data.map(
          (region: any) => ({
            name: region.name,
            id: region.id,
          })
        );
        setValues({ region: selectedRegions });

        const regionsResponse = await getRegions({
          pageNumber: 1,
          pageSize: 100,
        });
        const regionOptions = regionsResponse.data
          .map((region: any) => ({
            name: region.name,
            id: region.id,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setRegions(regionOptions);
      } catch (error) {
        showNotification({
          message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    }

    fetchData();
  }, []);

  const onRegionChange = (selectedRegions: { name: string; id: string }[]) => {
    setValues({ region: selectedRegions });
  };

  const onClearRegions = () => {
    setValues({ region: [] });
  };

  const onSaveRegions = async () => {
    const regionIds = values.region.map((region) => region.id);
    try {
      await postAccountRegion(regionIds);
      showNotification({
        message: "Регион успешно сохранен",
        variant: "success",
      });
    } catch (error) {
      showNotification({
        message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  };

  return (
    <LayoutDefault title="Настройки">
      <StyledContainer>
        <StyledGrid>
          <StyledItem>
            <StyledHeader>Регион</StyledHeader>
            <StyledField>
              <StyledButton>
                <RegionMultiSelect<any>
                  onChange={onRegionChange}
                  options={regions}
                  values={values.region}
                  onClear={onClearRegions}
                />
              </StyledButton>
              <StyledAcceptButton>
                <Button
                  text={"Применить"}
                  variant="contained"
                  onClick={onSaveRegions}
                />
              </StyledAcceptButton>
            </StyledField>
          </StyledItem>
          <StyledItem>
            <StyledHeader>Имя и Фамилия</StyledHeader>
            <StyledText>{user?.fullName}</StyledText>
            <StyledButton>
              <Button
                text={"Редактировать Имя и Фамилия"}
                variant="outlined"
                onClick={() => {}}
              />
            </StyledButton>
          </StyledItem>
          <StyledItem>
            <StyledHeader>Email</StyledHeader>
            <StyledText>{user?.email}</StyledText>
            <StyledButton>
              <Button
                text={"Редактировать Email"}
                variant="outlined"
                onClick={() => {}}
              />
            </StyledButton>
          </StyledItem>
          <StyledItem>
            <StyledHeader>Контакты</StyledHeader>
            <StyledText>{user?.role}</StyledText>
            <StyledButton>
              <Button
                text={"Редактировать контакты"}
                variant="outlined"
                onClick={() => {}}
              />
            </StyledButton>
          </StyledItem>
        </StyledGrid>
      </StyledContainer>
    </LayoutDefault>
  );
}

export default memo(PageSettings);
