import React, { memo } from "react";
import { Button, Modal } from "@app/components";
import styled from "styled-components";

type Props = {
  open: boolean;
  title: string;
  subtitle?: string;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const StyledTitle = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledSubtitle = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 20px;
`;

const StyledActions = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const UpdateDialog = (props: Props) => {
  const { open, title, subtitle, loading, onClose, onConfirm } = props;

  return (
    <Modal
      open={open}
      title={title}
      position={"center"}
      customSize="372px"
      hideHeader
      onClose={onClose}
    >
      <StyledContainer>
        <StyledTitle>
          <h3>{title}</h3>
        </StyledTitle>
        {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      </StyledContainer>
      <hr />
      <StyledActions>
        <Button text="Нет" variant="outlined" onClick={onClose} />
        <Button text="Да" showLoader={loading} onClick={onConfirm} />
      </StyledActions>
    </Modal>
  );
};

export default memo(UpdateDialog);
