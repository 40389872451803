import React, {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  Grid,
  Loader,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import {
  AdvancedPaymentType,
  AdvancedPaymentTypeNames,
  CollateralType,
  SmartContract,
} from "@app/models";
import { object, string } from "yup";
import {
  addSmartContractPaymentCalculations,
  AddSmartContractPaymentCalculationsDTO,
  APIResponse,
  getCollateralTypes,
} from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";

interface Props {
  contract: SmartContract;
  onChange: (step: number) => void;
  setModalActions: Dispatch<SetStateAction<ReactNode[]>>;
  updateContract: (partialContract: Partial<SmartContract>) => void;
}

const StyledStep2 = styled.div`
  display: grid;
  grid-gap: 16px;
  height: 100%;
  grid-template-rows: 1fr auto;
  position: relative;
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContent = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: max-content;
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface Step2FormData {
  advancedPaymentType: SelectOption<AdvancedPaymentType> | null;
  prepaymentPrice: string;
  contractPrice: string;
  collateralType: SelectOption<CollateralType> | null;
}

function Step2(props: Props) {
  const {
    contract,
    onChange: onChangeStep,
    setModalActions,
    updateContract,
  } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);

  const { values, onChange, errors, setValues, validate } =
    useForm<Step2FormData>({
      values: {
        advancedPaymentType: null,
        collateralType: null,
        prepaymentPrice: "",
        contractPrice: "",
      },
    });

  const onClickSave = useCallback(async () => {
    try {
      const isValid = await validate(
        object().shape({
          advancedPaymentType: object().nullable().required(),
          collateralType: object().nullable().required(),
          contractPrice: string().required(),
        })
      );

      if (!isValid) {
        return;
      }

      if (
        values.advancedPaymentType!.value !== AdvancedPaymentType.None &&
        !(await validate(
          object().shape({
            prepaymentPrice: string().required(),
          })
        ))
      ) {
        return;
      }
      const data: AddSmartContractPaymentCalculationsDTO = {
        draftId: contract!.id,
        amount: Number(values.contractPrice),
        advancePaymentType: values.advancedPaymentType!.value,
        collateralTypeId: values.collateralType!.value,
      };

      if (values.advancedPaymentType!.value !== AdvancedPaymentType.None) {
        data.advancePaymentAmount = Number(values.prepaymentPrice);
      }

      const { succeeded, message } = await addSmartContractPaymentCalculations(
        data
      );

      if (!succeeded) {
        return showNotification({
          message,
          variant: "error",
        });
      }

      setPending(false);

      updateContract({
        advancePaymentType: values.advancedPaymentType!.value,
        collateralType: values.collateralType!.item,
        amount: Number(values.contractPrice),
        advancePaymentAmount:
          values.advancedPaymentType!.value !== AdvancedPaymentType.None
            ? Number(values.prepaymentPrice)
            : contract!.advancePaymentAmount,
      });

      showNotification({
        message: "Порядок расчетов успешно сохранен",
        variant: "success",
      });
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    contract,
    showNotification,
    updateContract,
    validate,
    values.advancedPaymentType,
    values.collateralType,
    values.contractPrice,
    values.prepaymentPrice,
  ]);

  const onChangeAdvancedType = useCallback(
    (type: AdvancedPaymentType) => () => {
      onChange(
        {
          label: AdvancedPaymentTypeNames[type],
          value: type,
        },
        "advancedPaymentType"
      );

      if (type === AdvancedPaymentType.None) {
        onChange("", "prepaymentPrice");
      }
    },
    [onChange]
  );

  const onClickPrev = useCallback(() => {
    onChangeStep(1);
  }, [onChangeStep]);

  const onClickNext = useCallback(() => {
    onChangeStep(3);
  }, [onChangeStep]);

  useEffect(() => {
    setModalActions([
      <Button text="Сохранить" variant="text" onClick={onClickSave} />,
    ]);
  }, [onClickSave, setModalActions, values]);

  useEffect(() => {
    setValues({
      advancedPaymentType: !!contract.advancePaymentType
        ? {
            label: AdvancedPaymentTypeNames[contract.advancePaymentType],
            value: contract.advancePaymentType,
          }
        : null,
      prepaymentPrice: !!contract.advancePaymentAmount
        ? contract.advancePaymentAmount.toString()
        : "",
      contractPrice: !!contract.amount ? contract.amount.toString() : "",
      collateralType: !!contract.collateralType
        ? {
            label: contract.collateralType.name,
            value: contract.collateralType.id,
          }
        : null,
    });
  }, [contract, setValues]);

  return (
    <StyledStep2>
      {pending && (
        <StyledLoader>
          <Loader />
        </StyledLoader>
      )}
      <StyledContent>
        <Grid gap={32}>
          <Grid columns={3} auto={true}>
            {[
              AdvancedPaymentType.None,
              AdvancedPaymentType.OneTime,
              AdvancedPaymentType.Monthly,
            ].map((type, typeIndex) => (
              <Checkbox
                type="radio"
                key={typeIndex.toString()}
                checked={
                  !!values.advancedPaymentType &&
                  values.advancedPaymentType.value === type
                }
                label={AdvancedPaymentTypeNames[type]}
                onChange={onChangeAdvancedType(type)}
              />
            ))}
          </Grid>
          <Grid columns={4}>
            <TextField
              label="Сумма аванса"
              placeholder="Введите сумму"
              name="prepaymentPrice"
              onChange={onChange}
              value={values.prepaymentPrice}
              helperText={errors.prepaymentPrice}
              error={!!errors.prepaymentPrice}
              priceFormat={true}
              disabled={
                !!values.advancedPaymentType &&
                values.advancedPaymentType.value === AdvancedPaymentType.None
              }
            />
            <TextField
              label="Сумма договора"
              placeholder="Введите сумму"
              name="contractPrice"
              value={values.contractPrice}
              onChange={onChange}
              priceFormat={true}
              helperText={errors.contractPrice}
              error={!!errors.contractPrice}
            />
            <Select<CollateralType>
              label="Вид обеспечения"
              value={values.collateralType}
              onChange={onChange}
              name="collateralType"
              loadData={getCollateralTypes}
              helperText={errors.collateralType}
              error={!!errors.collateralType}
              labelKey="name"
              valueKey="id"
            />
          </Grid>
        </Grid>
      </StyledContent>
      <StyledFooter>
        <Button onClick={onClickPrev} text="Назад" variant="outlined" />
        <Button
          onClick={onClickNext}
          text="Далее"
          disabled={
            !contract.advancePaymentType ||
            !contract.collateralType ||
            !contract.amount ||
            (contract.advancePaymentType !== AdvancedPaymentType.None &&
              !contract.advancePaymentAmount)
          }
        />
      </StyledFooter>
    </StyledStep2>
  );
}

export default memo(Step2);
