import { memo } from "react";
import styled from "styled-components";

interface Props {
  value?: number;
}

const Wrapper = styled.div`
  background-color: transparent;
  height: 2px;

  position: relative;
  overflow: hidden;
`;
const Bar = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;

  /* Move the bar infinitely */
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;

  @keyframes indeterminate-progress-bar {
    0% {
      left: -40%;
      background: #f99335;
    }
    50% {
      left: 90%;
      background: #ed4a3f;
    }
    100% {
      left: -40%;
      background: #f99335;
    }
  }
`;

const ProgressBar = (props: Props) => {
  const { value } = props;

  const containerStyles = {
    height: 12,
    width: "100%",
    backgroundColor: "#d8d8d8",
    borderRadius: 12,
    margin: "6px 0",
  };

  const fillerStyles = {
    height: "100%",
    width: `${value || 0}%`,
    background: "linear-gradient(90deg, #ED4A3F 20%, #F99335 80%)",
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
  };

  if (!value) {
    return (
      <Wrapper>
        <Bar />
      </Wrapper>
    );
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} />
    </div>
  );
};

export default memo(ProgressBar);
