import React, { memo } from "react";
import "./styles.scss";
import styled from "styled-components";
import { switchProp, theme } from "styled-tools";

type LoaderSizes = "xsmall" | "small" | "default";

interface Props {
  size?: LoaderSizes;
}

const StyledLoader = styled.div<Pick<Props, "size">>`
  width: ${switchProp("size", {
    xsmall: "14px",
    small: "24px",
    default: "48px",
  })};
  height: ${switchProp("size", {
    xsmall: "14px",
    small: "24px",
    default: "48px",
  })};
  border-width: ${switchProp("size", {
    xsmall: "1px",
    small: "2px",
    default: "5px",
  })};
  border-color: ${theme("color.orangeLight")};
  border-style: solid;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto;
`;

function Loader(props: Props) {
  const { size = "default" } = props;

  return <StyledLoader size={size} />;
}

export default memo(Loader);
