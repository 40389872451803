import React, { memo, useCallback, useEffect, useState } from "react";
import { Grid, Select, SelectOption, TextField } from "@app/components";
import styled from "styled-components";
import { getCompanyTypes, getNds, getRegions } from "@app/api";
import { getCountries } from "../../../api/country";
import { formatPhoneNumber } from "@app/helpers";
import { NDS, NdsShort } from "@app/models";

type DataErrors = {
  [key in keyof any]?: string;
};

type Country = {
  id: string;
  name: string;
};

type Region = {
  id: string;
  name: string;
};

interface Props {
  dataOfOrganization: any;
  dataParams?: any;
  dataErrors: DataErrors;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
  readonly: boolean;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`;

const StyledAddressContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

function StepView1(props: Props) {
  const { dataParams, dataErrors, readonly = false, onDataChange } = props;
  const [errors, setErrors] = useState<DataErrors>({});
  const [companyTypes, setCompanyTypes] = useState<SelectOption<any>[]>([]);
  const [countries, setCountries] = useState<SelectOption<any>[]>([]);
  const [ndsList, setNdsList] = useState<SelectOption<any>[]>([]);
  const [regions, setRegions] = useState<SelectOption<any>[]>([]);

  useEffect(() => {
    setErrors(dataErrors);
  }, [dataErrors]);

  const handleChange = (value: any, name: any) => {
    if (name === "bin" || name === "headIdn") {
      value = value.replace(/\D/g, "");

      if (value.length > 12) {
        value = value.slice(0, 12);
      }
    }

    if (name === "headName") {
      value = value.replace(/[^a-zA-Zа-яА-Я\s]/g, "");
    }

    onDataChange(value, name);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/\D/g, "");
    const formattedValue = formatPhoneNumber(
      rawValue,
      dataParams.country?.label
    );
    onDataChange(formattedValue, "phone");
  };

  const handleTextFieldChange = (value: string, name: any) => {
    const event = { target: { value } } as React.ChangeEvent<HTMLInputElement>;
    handlePhoneChange(event);
  };

  const onChangeCompanyType = useCallback(
    (value: any, name: any) => {
      onDataChange(value, name);
      if (value.value !== dataParams.companyType) {
        onDataChange(null, "type");
      }
    },
    [onDataChange, dataParams]
  );

  const loadCompanyTypes = useCallback(async () => {
    try {
      const response = await getCompanyTypes();
      const formattedData = response.data.map((companyTypes: any) => {
        return {
          value: companyTypes.id,
          label: companyTypes.name,
          item: companyTypes,
        };
      });

      setCompanyTypes(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка: ", error);
    }
  }, []);

  const loadCountries = useCallback(async (params: any) => {
    try {
      const countries = await getCountries(params);

      const formattedCountryData: SelectOption<Country>[] = countries.data.map(
        (country: Country) => {
          return {
            value: country.id,
            label: country.name,
            item: country,
          };
        }
      );

      const sortedCountryData = formattedCountryData.sort((a, b) => {
        if (a.label === "Казахстан") return -1;
        if (b.label === "Казахстан") return 1;
        return 0;
      });

      setCountries(sortedCountryData);
    } catch (error) {
      console.error("Ошибка при загрузке списка:  ", error);
    }
  }, []);

  const loadNds = useCallback(
    async (params: any) => {
      try {
        if (!dataParams.region || !dataParams.region.value) {
          setNdsList([]);
          return;
        }

        const response = await getNds({
          ...params,
          regionId: dataParams.region.value,
        });
        const formattedData = response.data.map((nds: NDS) => ({
          value: nds.id,
          label: nds.name,
          item: nds,
        }));

        setNdsList(formattedData);
      } catch (error) {
        console.error("Ошибка при загрузке списка:  ", error);
      }
    },
    [dataParams.region]
  );

  const loadRegions = useCallback(async (params: any) => {
    try {
      const response = await getRegions(params);

      const formattedData: SelectOption<any>[] = response.data.map(
        (region: any) => ({
          value: region.id,
          label: region.name.trim(),
          item: region,
        })
      );

      const priorityRegions = ["Астана", "Алматы", "Шымкент", "Ташкент"];
      const priorityData: SelectOption<Region>[] = [];
      const otherData: SelectOption<Region>[] = [];

      formattedData.forEach((region: SelectOption<Region>) => {
        if (priorityRegions.includes(region.label)) {
          priorityData.push(region);
        } else {
          otherData.push(region);
        }
      });

      otherData.sort((a: SelectOption<Region>, b: SelectOption<Region>) =>
        a.label.localeCompare(b.label)
      );
      priorityData.sort(
        (a: SelectOption<Region>, b: SelectOption<Region>) =>
          priorityRegions.indexOf(a.label) - priorityRegions.indexOf(b.label)
      );

      const sortedData: SelectOption<Region>[] = [
        ...priorityData,
        ...otherData,
      ];

      setRegions(sortedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка:  ", error);
    }
  }, []);

  const onBinChange = (value: any, name: any) => {
    if (name === "bin" || name === "headIdn") {
      value = value.replace(/\D/g, "");
    }
    onDataChange(value, name);
  };

  useEffect(() => {
    (async () => {
      await loadCompanyTypes();
      await loadCountries(initialParams);
      await loadRegions(initialParams);
    })();
  }, [loadCompanyTypes, loadCountries, loadRegions]);

  useEffect(() => {
    if (dataParams.region && dataParams.region.value) {
      loadNds(initialParams);
    }
  }, [dataParams.region, loadNds]);

  return (
    <Header>
      <StyledHeader>Общая информация</StyledHeader>
      <Grid columns={1}>
        <Select<any>
          label="Страна"
          name="country"
          onChange={handleChange}
          value={dataParams.country}
          error={!!errors.country}
          helperText={errors.country}
          placeholder={"Выберите из списка"}
          valueKey="id"
          labelKey="country"
          options={countries}
          readonly={readonly}
          onClear={() => handleChange(null, "country")}
        />
      </Grid>
      {dataParams.country && dataParams.country?.label !== "Казахстан" && (
        <Grid columns={1}>
          <TextField
            label="Регистрационный номер"
            placeholder="Введите регистрационный номер"
            name="registrationNumber"
            onChange={handleChange}
            value={dataParams.registrationNumber}
            error={!!errors.registrationNumber}
            helperText={errors.registrationNumber}
            readOnly={readonly}
          />
        </Grid>
      )}
      <Grid columns={1}>
        <Select<any>
          label="Регион"
          name="region"
          onChange={handleChange}
          value={dataParams.region}
          error={!!errors.region}
          helperText={errors.region}
          placeholder={"Выберите из списка"}
          valueKey="id"
          labelKey="name"
          options={regions}
          readonly={readonly}
          onClear={() => handleChange(null, "region")}
        />
      </Grid>
      <Grid columns={2}>
        <Select<any>
          label="Форма организации"
          name="companyType"
          onChange={onChangeCompanyType}
          value={dataParams.companyType}
          error={!!errors.companyType}
          helperText={errors.companyType}
          valueKey="id"
          labelKey="name"
          options={companyTypes}
          readonly={readonly}
        />
        <TextField
          label="Наименование организации"
          placeholder={"Введите наименование"}
          name="organization"
          onChange={handleChange}
          value={dataParams.organization}
          error={!!errors.organization}
          helperText={errors.organization}
          readOnly={readonly}
        />
      </Grid>
      <Grid columns={2}>
        <TextField
          label="ИИН/БИН"
          placeholder="Введите ИИН/БИН"
          name="bin"
          onChange={onBinChange}
          value={dataParams.bin}
          error={!!errors.bin}
          helperText={errors.bin}
          readOnly={readonly}
        />
        <TextField
          label="Лицо"
          placeholder="Введите лицо"
          name="name"
          onChange={handleChange}
          value={dataParams.name.fullName}
          disabled={true}
          readOnly={readonly}
        />
      </Grid>

      <Grid columns={2}>
        <TextField
          label="ФИО Директора"
          placeholder={"Введите ФИО директора"}
          name="headName"
          onChange={handleChange}
          value={dataParams.headName}
          error={!!errors.headName}
          helperText={errors.headName}
          readOnly={readonly}
        />
        <TextField
          label="ИИН/БИН директора"
          placeholder="Введите ИИН/БИН директора"
          name="headIdn"
          onChange={onBinChange}
          value={dataParams.headIdn}
          error={!!errors.headIdn}
          helperText={errors.headIdn}
          readOnly={readonly}
        />
      </Grid>
      <Grid columns={2}>
        <TextField
          label="КБЕ"
          placeholder="Введите КБЕ"
          name="kbe"
          onChange={handleChange}
          value={dataParams.kbe}
          error={!!errors.kbe}
          helperText={errors.kbe}
          readOnly={readonly}
        />
        <Select<NdsShort>
          label="Ставка НДС"
          name="nds"
          onChange={handleChange}
          value={dataParams.nds}
          error={!!errors.nds}
          helperText={errors.nds}
          valueKey="id"
          labelKey="name"
          options={ndsList}
          readonly={readonly}
        />
      </Grid>
      <StyledAddressContent>
        <StyledHeader>Контакты организации</StyledHeader>
        <Grid columns={2}>
          <TextField
            label="Номер телефона"
            placeholder="Введите номер телефона"
            name="phone"
            onChange={handleTextFieldChange}
            value={dataParams.phone}
            error={!!errors.phone}
            helperText={errors.phone}
            readOnly={readonly}
          />
          <TextField
            label="E-mail"
            placeholder="Введите e-mail"
            name="email"
            onChange={handleChange}
            value={dataParams.email}
            error={!!errors.email}
            helperText={errors.email}
            readOnly={readonly}
          />
        </Grid>
      </StyledAddressContent>
    </Header>
  );
}

export default memo(StepView1);
