import { LayoutDefault } from "@app/layouts";
import React, { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styled-tools";
import { ReportActGroup60, ReportActUngroup60, ReportKip60 } from "@app/icons";
import { ImgActGroup, ImgActUngroup, ImgKip } from "assets/images/main";
import PageCard from "../../common/PageCard";

const StyledContainer = styled.div`
  display: flex;
  height: calc(100% - 32px);
`;

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  align-content: center;
  padding: 16px;
  background: ${theme("color.white")};
  flex: 1;
`;

function PageReports() {
  const navigate = useNavigate();
  const data = useMemo(
    () => [
      {
        path: "/dashboard/kip",
        bg: ImgKip,
        icon: () => <ReportKip60 />,
        title: "Отчет о выполненной работе и загруженности техники (КИП)",
      },
      {
        path: "detail",
        bg: ImgActUngroup,
        icon: () => <ReportActUngroup60 />,
        title: "Отчет по затратам на транспорт (без группировок)",
      },
      {
        path: "detail/group",
        bg: ImgActGroup,
        icon: () => <ReportActGroup60 />,
        title: "Отчет по затратам на транспорт (группированный)",
      },
    ],
    []
  );

  const onReportClick = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <LayoutDefault title="Отчеты">
      <StyledContainer>
        <StyledGrid>
          {data.map((item, index) => (
            <PageCard
              bg={item.bg}
              key={index.toString()}
              onClick={onReportClick(item.path)}
            >
              {item.icon()}
              <p>{item.title}</p>
            </PageCard>
          ))}
        </StyledGrid>
      </StyledContainer>
    </LayoutDefault>
  );
}

export default memo(PageReports);
