import instance from "./instance";
import { PageableParams, PageableResponse } from "./interfaces";
import { Currency } from "@app/models";

export function getCurrencies(
  params: PageableParams<Currency>
): Promise<PageableResponse<Currency>> {
  return instance()
    .get("/currency/short-info", {
      params,
    })
    .then((response) => response.data);
}
