import React, {
  CSSProperties,
  Fragment,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalPaper,
  PaperSizes,
} from "./components";

export interface Props extends PropsWithChildren {
  open: boolean;
  title: string | ReactNode;
  onClose: () => void;
  size?: PaperSizes;
  position?: "center" | "left" | "right";
  actions?: ReactNode[];
  hideHeader?: boolean;
  dense?: boolean;
  // not dismissed when clicking outside or Esc key
  persistent?: boolean;
  showBackDrop?: boolean;
  customSize?: string;
  headerContent?: ReactNode;
  contentStyle?: CSSProperties;
  zIndex?: number;
}

const StyledModal = styled.div<Pick<Props, "position" | "open" | "zIndex">>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.zIndex};
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  align-items: ${(props) =>
    props.position === "center"
      ? "center"
      : props.position === "right"
      ? "flex-end"
      : "flex-start"};
  justify-content: center;
`;

const StyledModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c2246;
  opacity: 0.5;
`;

const modalRoot = document.getElementById("modals-root");

function Modal(props: Props) {
  const {
    title,
    open,
    size,
    onClose,
    position = "right",
    actions = [],
    children,
    hideHeader,
    dense,
    persistent,
    showBackDrop = true,
    customSize,
    headerContent,
    contentStyle,
    zIndex = 9999,
  } = props;
  const el = useMemo(() => document.createElement("div"), []);
  const hasActions = useMemo(() => actions.length > 0, [actions]);

  const onClickEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();

        if (persistent) {
          return;
        }

        onClose();
      }
    },
    [onClose, persistent]
  );

  const onOutsideClick = useCallback(() => {
    if (persistent) {
      return;
    }

    onClose();
  }, [onClose, persistent]);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "initial";
  }, [open]);

  useEffect(() => {
    modalRoot?.appendChild(el);
    document.addEventListener("keydown", onClickEsc, true);

    return function () {
      try {
        modalRoot?.removeChild(el);

        document.body.style.overflow = "initial";

        document.removeEventListener("keydown", onClickEsc);
      } catch (e) {}
    };
  }, [el, onClickEsc]);

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <StyledModal position={position} open={open} zIndex={zIndex}>
      {showBackDrop && <StyledModalBackground onClick={onOutsideClick} />}
      <ModalPaper
        fullHeight={position !== "center"}
        size={size}
        customSize={customSize}
      >
        {!hideHeader ? (
          <ModalHeader title={title} closeModal={onClose}>
            {headerContent || null}
          </ModalHeader>
        ) : (
          <div />
        )}
        <ModalContent style={contentStyle} dense={dense}>
          {children}
        </ModalContent>
        {hasActions && (
          <ModalFooter>
            {actions.map((action, actionIndex) => (
              <Fragment key={actionIndex.toString()}>{action}</Fragment>
            ))}
          </ModalFooter>
        )}
      </ModalPaper>
    </StyledModal>,
    el
  );
}

export default memo(Modal);
