import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
  background: #f5f6f8;
  border-bottom: 1px solid #d8d8d8;
`;

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 24px 16px;
  padding: 16px 0;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
`;

export const StyledStepper = styled.div`
  flex-grow: 1;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
