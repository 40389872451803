import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Loader, Modal, ModalProps, ModalTitle, Tabs } from "@app/components";
import { CompanyFormType, CompanyInfo, CompanyListItem } from "@app/models";
import {
  AddressListView,
  BankAccountView,
  CompanyBasisView,
  FilesView,
  GeneralInformationView,
} from "./views";
import { APIResponse, getCompanyById, getCompanyTypes } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";
import { CompanyStatusChip } from "@app/common";

interface Props extends Omit<ModalProps, "title"> {
  item: CompanyListItem | null;
}

const tabLabels = [
  "Общая информация",
  "Адрес организации",
  "Основание деятельности",
  "Документы",
  "Банковские реквизиты",
];

const ModalOrganizationDetail: FC<Props> = (props) => {
  const { item: companyProps, ...modalProps } = props;
  const { showNotification } = useNotification();

  const [pending, setPending] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null);
  const [companyTypes, setCompanyTypes] = useState<CompanyFormType[]>([]);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!companyProps) {
      return "";
    }

    return (
      <ModalTitle text={companyProps.name}>
        {!!companyProps.status && (
          <CompanyStatusChip status={companyProps.status} />
        )}
      </ModalTitle>
    );
  }, [companyProps]);

  const getCompanyInfo = useCallback(async () => {
    if (companyProps) {
      try {
        setCompanyInfo(null);
        setPending(true);
        const res = await getCompanyById(companyProps.id);
        setCompanyInfo(res.data);
        console.log(res.data);
        setPending(false);
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
        setPending(false);
      }
    }
  }, [companyProps, showNotification]);

  const getTypes = useCallback(async () => {
    try {
      const response = await getCompanyTypes();
      // @ts-ignore не совпадают типы id
      setCompanyTypes(response.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getCompanyInfo();
    getTypes();
  }, [getCompanyInfo, getTypes]);

  return (
    <Modal title={modalTitle} dense {...modalProps}>
      {pending && <Loader />}
      <Tabs labels={tabLabels}>
        <GeneralInformationView
          company={companyInfo}
          companyTypes={companyTypes}
        />
        <AddressListView company={companyInfo} />
        <CompanyBasisView company={companyInfo} />
        <FilesView company={companyInfo} />
        <BankAccountView company={companyInfo} />
      </Tabs>
    </Modal>
  );
};

export default ModalOrganizationDetail;
