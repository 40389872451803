import React, { memo, useCallback, useState } from "react";
import { TextField } from "@app/components";
import styled from "styled-components";

interface Props {
  initialText?: string;
  placeholder?: string;
  onSearch: (searchText: string) => void;
}

const StyledToolbarSearch = styled.div`
  width: 480px;
`;

function ToolbarSearch(props: Props) {
  const { initialText = "", placeholder = "", onSearch } = props;
  const [searchText, setSearchText] = useState(initialText);

  const onChangeSearchText = useCallback(
    (text: string) => {
      setSearchText(text);

      if (!!onSearch && !text) {
        onSearch("");
      }
    },
    [onSearch]
  );

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e;

      if (!!onSearch && key === "Enter") {
        onSearch(searchText);
      }
    },
    [onSearch, searchText]
  );

  return (
    <StyledToolbarSearch>
      <TextField
        value={searchText}
        placeholder={placeholder}
        onChange={onChangeSearchText}
        onKeyPress={onKeyPress}
      />
    </StyledToolbarSearch>
  );
}

export default memo(ToolbarSearch);
