import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { useGoogleMap, useMonitoring } from "@app/providers";
import DrawGEOFence from "../DrawGEOFence";
import { Colors } from "../../../../ThemeProvider";

// interface Props {}

const StyledMenu = styled.div`
  width: 212px;
  background-color: ${theme("color.white")};
  zoom: 80%;
`;

const StyledMenuItem = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme("color.dark")};
  display: block;
  box-sizing: border-box;
  padding: 12px 16px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: ${theme("color.light")};
  }
`;

const MonitoringContextMenu = () => {
  const { map } = useGoogleMap();
  const {
    setModalVisible,
    setGEOFence,
    setGEOFenceLatLngList,
    geoFenceDetail,
  } = useMonitoring();
  // const [drawingGEOFence, setDrawingGEOFence] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const onClickCreateGEOzone = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      map.setOptions({ draggableCursor: "crosshair" });

      setModalVisible(true);
      setMenuPosition(null);
      setGEOFence({
        colorCode: Colors.green,
        coordinates: [],
        gpsGeofences: [],
        name: "",
        project: null,
        projectId: "",
      });
      setGEOFenceLatLngList([]);
    },
    [map, setGEOFence, setGEOFenceLatLngList, setModalVisible]
  );

  const onRightClickGoogleMap = useCallback((e: any) => {
    // 125% - коэффициент обратного зумирования
    setMenuPosition({
      x: e.domEvent.layerX * 1.25,
      y: e.domEvent.layerY * 1.25,
    });
  }, []);

  const onDragStartGoogleMap = useCallback(() => {
    setMenuPosition(null);
  }, []);

  const onClickEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setMenuPosition(null);
        setModalVisible(false);
        map.setOptions({ draggableCursor: "grab" });
      }
    },
    [map, setModalVisible]
  );

  useEffect(() => {
    const rightClickListener = map.addListener(
      "contextmenu",
      onRightClickGoogleMap
    );

    const dragListener = map.addListener("dragstart", onDragStartGoogleMap);

    document.addEventListener("keydown", onClickEsc, true);

    return () => {
      rightClickListener.remove();
      dragListener.remove();
      document.removeEventListener("keydown", onClickEsc);
    };
  }, [map, onClickEsc, onDragStartGoogleMap, onRightClickGoogleMap]);

  return (
    <>
      {!!menuPosition && (
        <StyledMenu
          style={{
            position: "absolute",
            top: menuPosition.y,
            left: menuPosition.x,
          }}
        >
          <StyledMenuItem onClick={onClickCreateGEOzone}>
            Создать геозону
          </StyledMenuItem>
          <StyledMenuItem>Измерить расстояние</StyledMenuItem>
          <StyledMenuItem>Измерить площадь</StyledMenuItem>
        </StyledMenu>
      )}
      {!!geoFenceDetail && <DrawGEOFence />}
    </>
  );
};

export default MonitoringContextMenu;
