import React, { memo, PropsWithChildren } from "react";
import "./styles.scss";
import { Logo } from "@app/common";
import styled from "styled-components";
import { theme } from "styled-tools";

interface Props {
  variant?: "light" | "dark";
}

const StyledSidebar = styled.aside<Pick<Props, "variant">>`
  background-color: ${(props) =>
    props.variant === "light" ? theme("color.white") : theme("color.dark")};
`;

const StyledSidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`;

function Sidebar(props: PropsWithChildren<Props>) {
  const { variant = "light", children } = props;

  return (
    <StyledSidebar variant={variant}>
      <StyledSidebarHeader className="b-sidebar__header">
        <Logo variant={variant === "dark" ? "light" : "dark"} />
      </StyledSidebarHeader>
      {children}
    </StyledSidebar>
  );
}

export default memo(Sidebar);
