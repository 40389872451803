import React, { useCallback } from "react";
import styled from "styled-components";
import { ifProp, prop, switchProp, theme } from "styled-tools";

interface Props {
  label?: string;
  reverse?: boolean;
  isOn: boolean;
  size?: "default" | "large";
  onChange?: (isOn: boolean) => void;
}

const StyledContainer = styled.div<Pick<Props, "reverse">>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledLabel = styled.p<
  {
    order: number;
  } & Pick<Props, "size">
>`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${switchProp("size", {
    default: 400,
    large: 700,
  })};
  color: ${theme("color.dark")};
  order: ${prop("order")};
`;

const SwitcherContainer = styled.div<
  {
    order: number;
  } & Pick<Props, "isOn">
>`
  width: 40px;
  height: 20px;
  background-color: ${ifProp(
    "isOn",
    theme("color.green"),
    theme("color.gray")
  )};
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  order: ${prop("order")};
`;

const SwitcherPoint = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${theme("color.white")};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.1s ease-in;
`;

export default function (props: Props) {
  const { isOn, label, reverse = false, size = "default", onChange } = props;

  const handleClick = useCallback(() => {
    if (!!onChange) {
      onChange(!isOn);
    }
  }, [isOn, onChange]);

  return (
    <StyledContainer reverse={reverse}>
      <SwitcherContainer
        isOn={isOn}
        onClick={handleClick}
        order={reverse ? 1 : 0}
      >
        <SwitcherPoint style={{ left: isOn ? "calc(100% - 16px)" : "4px" }} />
      </SwitcherContainer>
      {!!label && (
        <StyledLabel order={reverse ? 0 : 1} size={size}>
          {label}
        </StyledLabel>
      )}
    </StyledContainer>
  );
}
