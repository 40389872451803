import React from "react";
import { LayoutLanding } from "@app/layouts";
import { Container } from "@app/components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "styled-tools";

const StyledPage = styled.div`
  padding: 40px 0;
  box-sizing: border-box;

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  h2 {
    margin: 40px 0 20px 0;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: ${theme("color.dark")};

    + p {
      margin-top: 8px;
    }

    + ul {
      margin: 8px 0 0 0;

      + p {
        margin-top: 8px;
      }
    }
  }

  a {
    display: inline-block;
    margin-left: 4px;
    color: ${theme("color.blue")};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function PagePublicOfferAgreement() {
  return (
    <LayoutLanding>
      <StyledPage>
        <Container>
          <h1>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ</h1>
          <p>
            Частная компания «CDS Development Ltd.» (<b>далее - Компания</b>), в
            лице Директора Насимулдинова Нұрхата Еркежанұлы, действующего на
            основании Устава, публикует настоящий договор публичной оферты по
            адресу <Link to="/">https://www.dispatchcds.com</Link>.,
            представляющую собой публичную оферту (предложение) заключить
            гражданско-правовой договор на нижеуказанных условиях с любым лицом,
            который примет его условия путем совершения указанных в настоящем
            Договоре действий.
          </p>
          <h2>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
          <p>
            <b>Публичная оферта</b> – это содержащее все существенные условия
            договора предложение, из которого усматривается воля лица, делающего
            предложение заключить договор на указанных в публичной оферте
            условиях с любым лицом, которое отзовется на это предложение (п. 5
            ст. 395 Гражданского кодекса Республики Казахстан).
          </p>
          <p>
            <b>Приложение</b> - информационная система, размещенная в сети
            Интернет по адресу: <Link to="/">https://www.dispatchcds.com</Link>{" "}
            и в мобильном приложении DISPATCH которая, помимо прочего, содержит
            информацию о перечне и характеристиках Веб-служб.
          </p>
          <p>
            <b>Пользователь</b> – любое физическое или юридическое лицо, которое
            совершило акцепт настоящей публичной оферты.
          </p>
          <p>
            <b>Договор</b> – настоящий договор публичной оферты, заключенный
            между Компанией и Пользователем, вместе с договорной документацией,
            на которые имеется ссылка и которые должны входить в состав
            Договора.
          </p>
          <p>
            <b>Акцепт</b> – полное и безоговорочное принятие Пользователем
            публичной оферты – Договора в полном объеме, что равносильно
            заключению настоящего Договора на установленных в нем условиях.
          </p>
          <p>
            <b>Статус Пользователя</b> – статус «Исполнитель» или «Заказчик»,
            определенный Пользователем при регистрации в Приложении.
          </p>
          <p>
            <b>Исполнитель</b> – Пользователь, зарегистрированный в Приложении в
            статусе «Партнер», являющийся владельцем техники и предоставляющий
            услуги техники на условиях настоящего Договора.
          </p>
          <p>
            <b>Заказчик</b> – Пользователь, зарегистрированный в Приложении в
            статусе «Заказчик», приобретающий услуги техники, мониторинга работы
            техники на условиях настоящего Договора.
          </p>
          <p>
            <b>Услуга Компании</b> – платные услуги, оказываемые Компанией
            Пользователям, условия предоставления которых определяются в
            установленном порядке в зависимости от Статуса Пользователя.
          </p>
          <p>
            <b>Услуга техники</b>– услуги, оказываемые Исполнителем на
            собственной или арендованной спецтехнике по Заказам/Откликам от
            Заказчика.
          </p>
          <p>
            <b>Договор Услуг Компании</b> – гражданско-правовой договор,
            заключенный между Компанией и Заказчиком, которым установлены
            существенные условия оказания платных Услуг Компании в отношении
            Заказчика (наименование, стоимость, сроки и др.).
          </p>
          <p>
            <b>Договор Услуг техники</b>– гражданско-правовой договор,
            заключенный между Заказчиком и Исполнителем, которым установлены
            существенные условия оказания Услуг Исполнителя в отношении
            Заказчика (наименование, стоимость, сроки и др.).
          </p>
          <p>
            <b>Электронные документы</b> - документ, в котором информация
            представлена в электронно-цифровой форме и удостоверена посредством
            электронной-цифровой подписи.
          </p>
          <p>
            <b>Личный кабинет</b> - персональный раздел Пользователя в
            Приложении, к которому Пользователь получает доступ после
            регистрации в Приложении, содержащий совокупность данных о
            Пользователе и набор функций для зарегистрированных Пользователей
            Приложении.
          </p>
          <p>
            <b>Заказ</b> – электронный запрос Заказчика в адрес Компании на
            поиск и подбор Услуг спецтехники, опубликованный в Приложении в
            установленном порядке и форме.
          </p>
          <p>
            <b>Цена Заказа</b> – указанная в Заказе стоимость, по которой
            Заказчик предлагает Компании осуществить поиск и подбор Услуг
            спецтехники.
          </p>
          <p>
            <b>Отклик</b> – предложение Исполнителя на выполнение Заказа и
            заключение Договора Услуг спецтехники с Заказчиком, отправленный в
            Приложении в установленном порядке и форме.
          </p>
          <p>
            <b>Акцепт Отклика</b> –безоговорочное принятие и подтверждение
            Отклика Заказчиком или Компанией по поручению Заказчика.
          </p>
          <p>
            <b>Коммерческая тайна</b> – информация, не являющаяся общедоступной,
            разглашение которой может привести к убыткам и/или отрицательно
            повлиять на деловую репутацию любой из сторон Договора, включая
            информацию о коммерческих условиях настоящего Договора, личные и
            рабочие данные каждой из сторон.
          </p>
          <p>
            <b>Спецтехника</b> – механизмы, автотранспорт и/или специальная
            техника с экипажем, которые используются для решения специфических
            задач.
          </p>
          <p>
            <b>Аккредитованный GPS провайдер</b> – юридическое лицо или группа
            юридических лиц, привлекаемый Компанией для оказания услуг систем
            онлайн мониторинга GPS.
          </p>
          <p>
            <b>Оборудование GPS (терминал, трекер, датчик, дут)</b> -
            спутниковая система навигации, для учета и отражение данных в
            мониторинговой системе контроля времени и расхода топлива.
          </p>
          <br />
          <br />
          <p>
            <b>1. ПРЕДМЕТ ДОГОВОРА</b>
          </p>
          <p>
            Компания, обладая необходимыми средствами для организации процесса
            поиска потенциальных поставщиков/заказчиков услуг спецтехники и
            быстрой ценовой ориентацией для проведения маркетинга рынка,
            совершает публичное предложение неограниченному кругу лиц заключить
            настоящий Договор на приобретение услуг, представленных через
            Приложение Компании.
          </p>
          <p>
            После получения Акцепта настоящего Договора от любого Пользователя,
            согласившегося с его условиями, Договор не требует двустороннего
            подписания и действителен в электронном виде.
          </p>
          <p>
            Акцепт считается совершенным после прохождения регистрации
            Пользователя в Приложении.
          </p>
          <p>
            Условия оказания платных Услуг Компании в отношении Исполнителя
            регулируются настоящим Договором, с чем соглашается Исполнитель.
          </p>
          <p>
            Условия оказания платных Услуг Компании в отношении Заказчика
            устанавливаются путем заключения между Компанией и Заказчиком
            соответствующего Договора Услуг Компании, с чем соглашается
            Заказчик. Условия оказания Услуг Исполнителя в отношении Заказчика
            регулируются Договором Услуг Исполнителя, с чем согласны Исполнитель
            и Заказчик.
          </p>
          <p>
            Компания не отвечает прямо или косвенно за исполнение обязательств
            по Договору Услуг Исполнителя. Условия настоящего Договора
            устанавливаются Компанией самостоятельно в соответствии с
            действующим законодательством Республики Казахстан.
          </p>
          <br />
          <br />
          <p>
            <b>2. РЕГИСТРАЦИЯ ПОЛЬЗОВАТЕЛЯ</b>
          </p>
          <p>
            Лицо, желающее стать Пользователем, обязано пройти процедуру
            регистрации в Приложении.
          </p>
          <p>
            Использование Приложение и Услуги Компании возможно только после
            надлежащей регистрации Пользователя и Акцепта оферты.
          </p>
          <p>
            При регистрации Пользователь самостоятельно определяет собственный
            статус «Пользователь» либо «Заказчик», в зависимости от целей
            регистрации, заполняет соответствующую регистрационную форму и
            удовлетворяет дополнительные запросы Компании.
          </p>
          <p>
            Пользователь может пройти процедуру регистрации только один раз.
          </p>
          <p>
            Пользователь несет полную ответственность за правильность сведений,
            предоставленных при регистрации в Приложении, а также за
            несвоевременное представление изменений в регистрационные данные.
          </p>
          <p>
            Пользователь несет ответственность за все действия и последствия
            использования Личного кабинета, за передачу доступа к своему Личному
            кабинету третьим лицам, а также за несанкционированный доступ. Все
            перечисленные действия будут считаться произведенными
            непосредственно самим Пользователем.
          </p>
          <p>
            Статус может быть снят с Пользователя, и регистрация аннулирована, в
            любой момент по усмотрению Компании без объяснения причин, что
            повлечет полное отключение доступа Пользователя к Приложению и
            Услугам Компании.
          </p>
          <br />
          <br />
          <p>
            <b>3. ПОРЯДОК СОГЛАСОВАНИЯ ЗАКАЗА (ОТКЛИК, ПОДТВЕРЖДЕНИЕ)</b>
          </p>
          <p>
            <b>3.1. РАЗМЕЩЕНИЕ ЗАКАЗА</b>
          </p>
          <p>
            Заказ направляется Заказчиком через Личный кабинет в Приложении.
          </p>
          <p>
            Заказ должен заполняться Заказчиком необходимыми данными в
            соответствии с формой Заказа в Личном кабинете.
          </p>
          <p>
            Сформированный Заказ подлежит отправке на модерацию для проверки на
            корректность и полноту заполнения.,
          </p>
          <p>
            После проверки Заказ публикуется в Приложении. После публикации
            Заказ становиться доступным и видным всем Пользователям.
          </p>
          <p>
            <b>3.2. ПОДАЧА ОТКЛИКА</b>
          </p>
          <p>
            Исполнитель осуществляет Отклик на опубликованные Заказы путем
            отправления предложения в соответствии с формой Отклика в Личном
            кабинете.
          </p>
          <p>
            <b>3.3. АКЦЕПТ ОТКЛИКА</b>
          </p>
          <p>
            Направленный Исполнителем Отклик рассматривается Заказчиком в Личном
            кабинете.
          </p>
          <p>
            По результатам рассмотрения Заказчик осуществляет Акцепт Отклика
            либо Отклонение Отклика.
          </p>
          <p>
            <b>
              3.4. ПОДПИСАНИЕ ДОГОВОРА УСЛУГ ТЕХНИКИ И ВЕДЕНИЕ УЧЕТА РАБОТЫ
              ТЕХНИКИ
            </b>
          </p>
          <p>
            После Акцепта Отклика Модератор отправляет в Личный кабинет
            Заказчика и Исполнителя проект Договора услуг техники.
          </p>
          <p>
            Заказчик и Исполнитель подписывают Договор услуг техники в
            соответствующем разделе для подписания договоров в Личном кабинете.
          </p>
          <p>
            Договор Услуг техники считается заключенным с момента подписания
            уполномоченными представителями Заказчика и Исполнителя
            собственноручно либо посредством электронных цифровых подписей (ЭЦП)
            и обмена электронными документами в Приложении.
          </p>
          <p>
            <b>3.5. ВЕДЕНИЕ УЧЕТА РАБОТЫ ТЕХНИКИ</b>
          </p>
          <p>
            После заключения Договора Услуг техники Заказчик и Исполнитель
            приступают к исполнению договорных обязательств.
          </p>
          <p>
            После заключения Договора Услуг техники определяет уполномоченного
            представителя, ответственного за ведение журнала учета работы
            техники на ежедневной основе в Личном кабинете Заказчика.
          </p>
          <p>
            По итогам оказания Услуг техники за отчетный месяц либо после
            завершения оказания Услуг техники, уполномоченный представитель
            Заказчика, ответственный за ведение журнала учета работы техники,
            формирует и отправляет на согласование руководства и/или иным
            причастным сотрудникам Заказчика Акт выполненных работ (оказанных
            услуг) за отчетный период либо Итоговый акт выполненных работ
            (оказанных услуг).
          </p>
          <p>
            Согласованный Заказчиком акт выполненных работ (оказанных услуг)
            размещается в Личном кабинете Исполнителя для подписания
            уполномоченным представителем (руководителем) и отражения в
            бухгалтерском учете Исполнителя.
          </p>
          <br />
          <br />
          <p>
            <b>4. УСЛУГИ КОМПАНИИ</b>
          </p>
          <p>
            <b>4.1. ПОРЯДОК РАСЧЕТОВ ЗА УСЛУГИ КОМПАНИИ С ИСПОЛНИТЕЛЕМ</b>
          </p>
          <p>
            Стоимость Услуг Компании по подбору Заказа и предоставления услуг в
            отношении Исполнителя составляет 0,3% от стоимости Услуг
            Исполнителя, оказанных Заказчику по соответствующему Договору на
            оказание Услуг Исполнителя;
          </p>
          <p>
            Стоимость Услуг Компании за отчетный период оплачивается
            Исполнителем на основании Актов оказанных услуг, подписанных между
            Компанией и Исполнителем, в течение 5 (пяти) банковских дней с даты
            подписания Актов оказанных услуг за отчетный период между Заказчиком
            и Исполнителем по Договору оказания Услуг Исполнителя;
          </p>
          <p>
            Исполнитель осуществляет оплату Услуг Компании в течение 5 (пяти)
            банковских дней с даты подписания Актов оказанных услуг по Договору,
            в безналичной форме по указанным в настоящем Договоре реквизитам;
          </p>
          <p>
            При наличии разногласий при подписании Акта оказанных услуг по
            Договору, Исполнитель направляет в адрес Компании свои
            мотивированные возражения в письменной форме не позднее чем через 3
            (три) календарных дня после получения Акта оказанных услуг от
            Компании. В этом случае Компания и Исполнитель проведут сверку
            расчетов по результатам которой подписывают Акт сверки
            взаиморасчетов; Факт непредоставления в указанный срок Исполнителем
            письменных замечаний Компании, или невозвращение Акта считается
            принятием Услуг Компании и с указанной даты Акт считается
            подписанным Исполнителем;
          </p>
          <p>
            Услуга Компании считается надлежаще оказанной Исполнителю с даты
            Акцепта Отклика.
          </p>
          <p>
            <b>4.2. ПРАВА И ОБЯЗАННОСТИ КОМПАНИИ.</b>
          </p>
          <p>
            <b>ОБЯЗАННОСТИ КОМПАНИИ:</b>
          </p>
          <p>
            Оказать Услуги качественно и в сроки, предусмотренные
            законодательством Республики Казахстан и настоящим Договором;
          </p>
          <p>
            Организовывать процесс поиска и найма техники в соответствии с
            Заказами;
          </p>
          <p>
            По факту оказания услуг, предоставлять Акт оказанных услуг за
            отчетный период;
          </p>
          <p>
            Предоставить доступ Исполнителю к мониторингу работы техники на
            объекте(-ах) Заказчика через системы Компании;
          </p>
          <p>
            Предоставить доступ Исполнителю вести учет работы техники в
            электронном документе «Журнал учета техники» в системе Компании;
          </p>
          <p>
            Осуществлять информирование Исполнителя об условиях оказания услуг
            мониторинга техники и о всех ее изменениях в Системе;
          </p>
          <p>
            <b>ПРАВА КОМПАНИИ:</b>
          </p>
          <p>
            Требовать от Исполнителя своевременной оплаты за Услуги Компании по
            настоящему Договору; По своему усмотрению изменять, улучшать,
            отзывать или приостанавливать оказание Услуг на Веб-службах как
            целиком, так и в части;
          </p>
          <p>
            Проводить профилактические работы на сервере системы, для чего
            временно приостанавливать доступ к системе, в том числе по
            техническим, технологическим или иным причинам, препятствующим
            оказанию Услуг, на время устранения таких причин, предупредив
            Пользователя о приостановке доступа к системе или оказанию Услуг за
            сутки до начала проведения работ, путем направления электронного
            письма либо в Приложении;
          </p>
          <p>
            Приостанавливать работу отдельных функциональных возможностей
            системы для проведения необходимых плановых профилактических и
            ремонтных работ на технических ресурсах Компании, а также
            внеплановых работ в аварийных ситуациях;
          </p>
          <p>
            В целях реализации Договора Компания получает право на
            использование, хранение, обработку и распространение учетных данных
            Пользователя тем способом и в той мере, в которых это необходимо для
            исполнения условий Договора;
          </p>
          <p>
            Модифицировать или выпускать обновления системы, добавлять новые
            свойства или функциональные возможности системы, повышающие его
            работоспособность или иным способом улучшающие его характеристики, в
            том числе в целях удовлетворения требований законодательства РК и
            международных договоров, а также расширять перечень предоставляемых
            Услуг.
          </p>
          <p>
            <b>4.3. ДЕАКТИВАЦИЯ ЛИЧНОГО КАБИНЕТА ИСПОЛНИТЕЛЯ</b>
          </p>
          <p>Компания вправе потребовать от Исполнителя:</p>
          <p>
            1) удалить или заменить представителя Исполнителя (работника,
            машиниста техники), который:
          </p>
          <ul>
            <li>систематически нарушает дисциплину;</li>
            <li>
              проявляет некомпетентность, небрежность при выполнении своих
              обязанностей;
            </li>
            <li>
              не соблюдает условия Договора, либо постоянно осуществляет
              действия, угрожающие безопасности, здоровью или окружающей среде.
            </li>
          </ul>
          <p>
            2) соблюдать требования по установке аккредитованного Компанией
            оборудования GPS;
          </p>
          <p>
            3) заменить технику в случае систематической поломки (2 и более раз)
            на аналогичный вид техники в соответствии с Заказом.
          </p>
          <p>
            Деактивировать личный кабинет Исполнителя в случае неисполнения
            требований Компании, установленных настоящим пунктом.
          </p>
          <br />
          <br />
          <p>
            <b>5. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</b>
          </p>
          <p>
            <b>ПРАВА ПОЛЬЗОВАТЕЛЯ:</b>
          </p>
          <p>
            Получать доступ к Приложению, предоставленным для Пользователя
            Компанией;
          </p>
          <p>Корректировать данные в Личном кабинете;</p>
          <p>
            Направлять жалобы в Компанию по фактам допущенных нарушений иными
            Пользователями.
          </p>
          <p>
            <b>ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ:</b>
          </p>
          <p>
            При регистрации вносить достоверные и актуальные данные о себе в
            соответствии с регистрационной формой Приложении;
          </p>
          <p>
            Предпринимать меры по обеспечению сохранности персональных данных
            Личного кабинета.
          </p>
          <br />
          <br />
          <p>
            <b>6. ПРАВА И ОБЯЗАННОСТИ ЗАКАЗЧИКА.</b>
          </p>
          <p>
            <b>ПРАВА ЗАКАЗЧИКА:</b>
          </p>
          <p>Создавать Заказы и выбирать Исполнителя;</p>
          <p>
            Пользоваться Услугами Компании в соответствии с выбранным видом
            услуг, предложенных в Приложении.
          </p>
          <p>
            <b>ОБЯЗАННОСТИ ЗАКАЗЧИКА:</b>
          </p>
          <p>
            Предоставлять Компании дополнительные сведения, необходимые для
            подтверждения Статуса «Заказчик»; Обеспечивать защиту
            конфиденциальной информации и персональных данных, полученных от
            Компании или иных Пользователей;
          </p>
          <p>
            Своевременно оплачивать Услуги Компании в соответствии с условиями
            Договора Услуг Компании.
          </p>
          <br />
          <br />
          <p>
            <b>7. ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ.</b>
          </p>
          <p>
            <b>ПРАВА ИСПОЛНИТЕЛЯ:</b>
          </p>
          <p>Оставлять Отклики на Заказы в Приложении;</p>
          <p>
            Пользоваться Услугами Компании в соответствии с выбранным Пакетом
            Услуг.
          </p>
          <p>
            <b>ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ:</b>
          </p>
          <p>
            Исполнять Заказы надлежащим образом в соответствии с условиями
            принятых обязательств;
          </p>
          <p>
            Исполнять Заказы лично, не передавая исполнение Заказа
            третьим-лицам, не прошедшим регистрацию и не согласованных в
            Заказчиком;
          </p>
          <p>
            С момента Акцепта, самостоятельно и за свой счет приобретать и
            устанавливать Оборудование GPS на свою Спецтехнику согласно одного
            из Аккредитованных провайдеров Компании. При этом, Исполнитель
            обязан беспрепятственно предоставить доступ представителям Компании
            и/или Заказчику к показаниям приборов Оборудования, а также передать
            Заказчику код доступа к отчетам системы контроля GPS;
          </p>
          <p>
            Своевременно информировать Заказчика об отказе выполнять Заказ путем
            удаления Отклика либо об изменениях исполнения принятых обязанностей
            любым доступным способом связи;
          </p>
          <p>
            Предоставлять Компании дополнительные сведения, необходимые для
            подтверждения Статуса «Исполнитель»;
          </p>
          <p>
            Обеспечивать защиту конфиденциальной информации и персональных
            данных, полученных от Компании или иных Пользователей;
          </p>
          <p>Своевременно оплачивать Услуги Компании;</p>
          <p>По требованию Компании производить взаимную сверку расчетов;</p>
          <p>
            Обеспечить прохождение обучения своих работников работе в системе
            Компании и назначить список ответственных лиц за работу в Системе;
          </p>
          <p>
            Подписать Акт выполненных работ/акт оказанных услуг в течение 3
            (трех) календарных дней с момента их получения от Компании.
          </p>
          <br />
          <br />
          <p>
            <b>8. ОТВЕТСТВЕННОСТЬ</b>
          </p>
          <p>
            Сторона, не исполнившая или ненадлежащим образом исполнившая
            обязательства, предусмотренные настоящим Договором, несет
            ответственность в соответствии с действующим законодательством
            Республики Казахстан и настоящим Договором.
          </p>
          <p>
            За просрочку оплаты Исполнитель уплачивает неустойку Компании в
            размере 0,1% от суммы задолженности в течение 3 (трех) рабочих дней
            с момента требования Компании. При этом в случае просрочки оплаты, в
            системе блокируется личный кабинет Исполнителя (в том числе, но не
            ограничиваясь: подачи заявки, закрытие журнала связанный с
            транспортом и т.д.) до момента погашения задолженности;
          </p>
          <p>
            За предоставление некорректных данных для расчёта оказанных услуг
            Исполнитель обязуется выплатить компании штраф в размере 10% от
            суммы оказанных услуг Исполнителем Заказчику в течение 3 (трех)
            рабочих дней с момента требования Компании.
          </p>
          <p>
            Оплата неустойки, пени, штрафа не освобождает виновную Сторону от
            исполнения обязательств, предусмотренных настоящим Договором.
          </p>
          <br />
          <br />
          <p>
            <b>9. ВНЕСЕНИЕ ИЗМЕНЕНИЙ В ДОГОВОР</b>
          </p>
          <p>
            Компания оставляет за собой право внести изменения в условия
            настоящего Договора и/или отозвать Договор в любой момент по своему
            усмотрению. В случае внесения Компанией изменений в Договор, такие
            изменения вступают в силу с момента размещения измененного текста
            Договора в Приложении по адресу: https:// dispatchcds.com. Просмотр
            страницы в сети интернет для ознакомления с действующей редакцией
            Договора является личной ответственностью каждого Пользователя.
          </p>
          <br />
          <br />
          <p>
            <b>10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</b>
          </p>
          <p>
            Все приложения к настоящему Договору являются его неотъемлемыми
            частями.
          </p>
          <p>
            При возникновении обстоятельств, не предусмотренных настоящим
            Договором, Стороны руководствуются действующим законодательством
            Республики Казахстан.
          </p>
          <p>
            Содержание текста настоящего Договора полностью соответствует
            действительному волеизъявлению Сторон.
          </p>
          <p>Настоящий Договор составлен на русском языке.</p>
          <p>
            Исключительные права на использование Приложении принадлежат
            Компании, зарегистрированной на территории Республики Казахстан.
          </p>
          <p>
            Компания не предоставляет Исполнителю никаких прав на использование
            знаков для услуг, которые могут содержаться в Услугах Приложение.
          </p>
          <br />
          <br />
          <p>
            <b>11. РЕКВИЗИТЫ КОМПАНИИ</b>
          </p>
          <p>
            <b>Частная компания CDS Development Ltd.</b>
          </p>
          <p>БИН 221040900222</p>
          <p>Казахстан, город Астана, район Есиль, улица</p>
          <p>Туркестан, дом 8/2, ВП 3</p>
          <p>ИИК: KZ188562203126148539</p>
          <p>Банк: BCC БАНК ЦЕНТРКРЕДИТ</p>
          <p>БИК: KCJBKZKX</p>
        </Container>
      </StyledPage>
    </LayoutLanding>
  );
}

export default PagePublicOfferAgreement;
