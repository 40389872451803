import React, { memo, useCallback } from "react";
import { FileWrapper } from "./components";
import { IconDocumentFile40, IconTrash24 } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import { formatFileSize } from "@app/helpers";
import { Button } from "../index";

interface Props {
  file: File;
  fileIndex?: number;
  onDelete?: (fileIndex: number) => void;
  onClick?: (fileIndex: number) => void;
  loading?: boolean;
  actionTitle?: string;
  onActionClick?: () => void;
}

const StyledFileIcon = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  color: ${theme("color.blue")};
`;

const StyledFileInfo = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const StyledFileName = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  word-break: break-word;
`;

const StyledFileSize = styled.p`
  font-weight: 400;
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-family: ${theme("fontFamily")};
  color: ${theme("color.gray")};
`;

const StyledFileControl = styled.button`
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  border-radius: 4px;
  color: ${theme("color.gray")};

  &:hover {
    background: ${theme("color.grayLight")};
    color: ${theme("color.dark")};
  }
`;

function FileComponent(props: Props) {
  const {
    file,
    onDelete,
    fileIndex,
    onClick,
    onActionClick,
    actionTitle = "",
    loading,
  } = props;

  const onClickDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!onDelete) {
        return;
      }

      onDelete(fileIndex!);
    },
    [fileIndex, onDelete]
  );

  const onClickFile = useCallback(() => {
    if (!onClick) {
      return;
    }

    onClick(fileIndex!);
  }, [onClick, fileIndex]);

  return (
    <FileWrapper onClick={onClickFile}>
      <StyledFileIcon>
        <IconDocumentFile40 />
      </StyledFileIcon>
      <StyledFileInfo>
        <StyledFileName>{file.name}</StyledFileName>
        {!!file.size && (
          <StyledFileSize>{formatFileSize(file.size)}</StyledFileSize>
        )}
      </StyledFileInfo>
      {!!onActionClick && (
        <Button
          text={actionTitle}
          size="small"
          showLoader={loading}
          onClick={onActionClick}
        />
      )}
      {!!onDelete && (
        <StyledFileControl onClick={onClickDelete}>
          <IconTrash24 />
        </StyledFileControl>
      )}
    </FileWrapper>
  );
}

export default memo(FileComponent);
