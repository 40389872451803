import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Modal, ModalProps, TextField } from "@app/components";
import { useNotification } from "@app/providers";
import { revokeAct } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { Act } from "@app/models";

interface Props extends ModalProps {
  actId?: Act["id"];
  onClose: (val?: boolean) => void;
}

const ModalActOfServiceRevoke = (props: Props) => {
  const { onClose, actId, ...restProps } = props;
  const { showNotification } = useNotification();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const onRejectConfirm = useCallback(async () => {
    if (!actId) {
      return;
    }
    setLoading(true);
    revokeAct(actId, comment)
      .then(() => {
        showNotification({
          variant: "success",
          message: "Табель отозван",
        });
        onClose(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e),
          variant: "error",
        });
      });
  }, [actId, comment, showNotification, onClose]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text="Подтвердить отзыв"
        onClick={onRejectConfirm}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onRejectConfirm]);

  return (
    <Modal actions={actions} onClose={onClose} {...restProps}>
      <div className="">
        <TextField
          label="Комментарий"
          placeholder="Укажите причину отзыва"
          value={comment}
          name="comment"
          onChange={(value, name) => {
            setComment(value);
          }}
        />
      </div>
    </Modal>
  );
};

export default memo(ModalActOfServiceRevoke);
