import { VAERequestStatus, VehicleRequestStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: VAERequestStatus;
}

const getStatusVariant = (status: VAERequestStatus): ChipColor => {
  switch (status) {
    case VAERequestStatus.AwaitingConfirmation:
      return "primary";
    case VAERequestStatus.AwaitingRequest:
      return "warning";
    case VAERequestStatus.TermAgreement:
      return "default";
    case VAERequestStatus.ContractSign:
      return "default";
    case VAERequestStatus.OrderCompleted:
      return "success";
    case VAERequestStatus.Rejected:
      return "danger";
    default:
      return "default";
  }
};

function VAERequestStatusChip(props: Props) {
  const { status } = props;

  return (
    <Chip
      text={VehicleRequestStatusName[status]}
      color={getStatusVariant(status)}
    />
  );
}

export default memo(VAERequestStatusChip);
