import React, { useCallback, useEffect, useState } from "react";
import { useNotification } from "@app/providers";
import {
  FilesPicker,
  Form,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import { Company, Currency, VAERequest } from "@app/models";
import {
  APIResponse,
  createVAERequestOffer,
  getCompanyById,
  getCurrencies,
} from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

interface Props {
  request: VAERequest;
  onSuccess: () => void;
}

interface Data {
  currency: SelectOption<Currency> | null;
  price: string;
  comment: string;
  files: File[];
}

const schema = yup.object().shape({
  currency: yup.object().nullable().required("required"),
  price: yup.string().required(),
  comment: yup.string().required(),
});

function VAERequestOffer(props: Props) {
  const { request, onSuccess } = props;
  const { showNotification } = useNotification();
  const { pending, onChange, values, errors, validate, setPending } =
    useForm<Data>({
      values: {
        currency: null,
        price: "",
        comment: "",
        files: [],
      },
      schema,
    });
  const [partnerNds, setPartnerNds] = useState<Company["nds"]>();

  useEffect(() => {
    (async () => {
      try {
        const res = await getCompanyById(request.partner.id);
        if (res.data?.nds) {
          // @ts-ignore
          setPartnerNds(res.data.nds);
        }
      } catch (e) {
        showNotification({
          variant: "error",
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        });
      }
    })();
  }, [request.partner.id, showNotification]);

  const onChangeFiles = useCallback(
    (files: File[]) => {
      onChange(files, "files");
    },
    [onChange]
  );

  const onSubmitForm = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const response = await createVAERequestOffer({
        requestId: request.id,
        companyId: request.partner.id,
        vehicleId: request.vehicle.id,
        nomenclatureId: request.nomenclature.id,
        unitId: request.unit!.id,
        currencyId: values.currency!.value,
        price: Number(values.price),
        comments: values.comment,
        files: values.files,
        ndsId: request.nds.id,
      });

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message:
          "Заказ на добавление дополнительных затрат отправлен на модерацию",
        variant: "success",
      });

      onSuccess();

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });
    }
  }, [request, onSuccess, setPending, showNotification, validate, values]);

  return (
    <Form onSubmit={onSubmitForm} pending={pending} dense={false}>
      <Grid>
        <TextField
          label="Организация"
          value={request.partner.name}
          disabled={true}
        />
        <TextField
          label="Техника"
          value={`${request.vehicle.vehicleModel.name} ${request.vehicle.govNumber}`}
          disabled={true}
        />
        <TextField
          label="Номенклатура"
          value={request.nomenclature.name}
          disabled={true}
        />
        <Grid columns={2}>
          <Select<Currency>
            label="Валюта"
            name="currency"
            onChange={onChange}
            value={values.currency}
            error={!!errors.currency}
            helperText={errors.currency}
            valueKey="id"
            labelKey="name"
            loadData={getCurrencies}
          />
          <TextField
            label="Единица измерения работ"
            value={request.unit!.name}
            disabled={true}
          />
          <TextField
            label="Цена"
            placeholder="Введите цену"
            name="price"
            priceFormat={true}
            onChange={onChange}
            value={values.price}
            error={!!errors.price}
            helperText={errors.price}
          />
          <TextField
            label="Ставка НДС"
            value={partnerNds?.name}
            disabled={true}
          />
        </Grid>
        <TextField
          label="Комментарий"
          placeholder="Введите текст"
          name="comment"
          onChange={onChange}
          value={values.comment}
          error={!!errors.comment}
          helperText={errors.comment}
          multiline={true}
        />
        <FilesPicker
          files={values.files}
          onChange={onChangeFiles}
          horizontal={true}
        />
      </Grid>
    </Form>
  );
}

export default VAERequestOffer;
