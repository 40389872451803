import React, {
  forwardRef,
  HTMLAttributes,
  memo,
  useCallback,
  useMemo,
} from "react";
import classNames from "classnames";
import { SVGIconType } from "@app/icons";
import Loader from "../Loader";
import "./styles.scss";

interface Props {
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit";
  className?: string;
  startIcon?: SVGIconType;
  endIcon?: SVGIconType;
  showLoader?: boolean;
  size?: "default" | "small";
  variant?: "contained" | "outlined" | "text" | "newDesign";
}

export type Ref = HTMLButtonElement;

const Button = forwardRef<Ref, Props>((props, ref) => {
  const {
    text,
    onClick,
    disabled = false,
    variant = "contained",
    type = "button",
    size = "default",
    className = "",
    startIcon: StartIcon,
    endIcon: EndIcon,
    showLoader = false,
  } = props;

  const onClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (type === "button") {
        e.preventDefault();
        e.stopPropagation();

        if (!!onClick) {
          onClick();
        }
      }
    },
    [onClick, type]
  );

  const buttonAttributes = useMemo<HTMLAttributes<HTMLButtonElement>>(() => {
    const attributes: HTMLAttributes<HTMLButtonElement> = {};

    if (!!onClickButton) {
      attributes.onClick = onClickButton;
    }

    return attributes;
  }, [onClickButton]);

  return (
    <button
      ref={ref}
      {...buttonAttributes}
      className={classNames(`b-button ${className}`.trim(), {
        "b-button--outlined": variant === "outlined",
        "b-button--new-design": variant === "newDesign",
        "b-button--text": variant === "text",
        "b-button--small": size === "small",
        "b-button--disabled": disabled,
        "b-button--start-icon": !!text && !!StartIcon,
        "b-button--end-icon": !!text && !!EndIcon,
        "b-button--icon": !text && (!!EndIcon || !!StartIcon),
      })}
      disabled={disabled}
      type={type}
    >
      {!!StartIcon && <StartIcon />}
      {!!text && (
        <span
          className={classNames("b-button__text", {
            "b-button__text--hidden": showLoader,
          })}
        >
          {text}
        </span>
      )}
      {!!EndIcon && <EndIcon />}
      {showLoader && (
        <div className="b-button__loader">
          <Loader size={size === "small" ? "xsmall" : "small"} />
        </div>
      )}
    </button>
  );
});

export default memo(Button);
