import { dateFormat } from "./dateFormat";

export function getFilterValues(array?: any[] | null) {
  return array?.map((item) => item.id) || [];
}

export function getDateFilterValue(date?: Date | null) {
  if (!date) {
    return;
  }
  return dateFormat(date, "yyyy-MM-dd");
}
