import React, { memo, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Checkbox,
  DateTimePicker,
  Modal,
  ModalProps,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import {
  Characteristic,
  Company,
  NdsShort,
  Nomenclature,
  ServiceType,
  SmartContractVehicle,
  Unit,
  VehicleListItem,
  VehicleType,
  WorkShift,
} from "@app/models";
import styled from "styled-components";
import {
  getCharacteristics,
  getNomenclatures,
  getServiceTypes,
  getUnits,
  getVehicleDetail,
  getVehicles,
  getVehicleTypes,
  getWorkShifts,
  PageableParams,
} from "@app/api";
import * as yup from "yup";
import { object } from "yup";
import { addVAT, roundToTwo, setSelectOption, subtractVAT } from "@app/helpers";

interface Props extends Omit<ModalProps, "title"> {
  vehicle: SmartContractVehicle | null;
  onSave: (vehicle: SmartContractVehicle) => void;
  organization: Company;
  nds: NdsShort | null;
}

interface FormData {
  vehicleType: SelectOption<VehicleType> | null;
  characteristics: SelectOption<Characteristic> | null;
  serviceType: SelectOption<ServiceType> | null;
  govNumber: string;
  workShift: SelectOption<WorkShift> | null;
  nomenclature: SelectOption<Nomenclature> | null;
  unit: SelectOption<Unit> | null;
  volume: string;
  loadCapacity: string;
  craneBoomReach: string;
  height: string;
  startDate: Date | null;
  endDate: Date | null;
  price: string;
  priceWithoutNds: string;
  vehicle: SelectOption<VehicleListItem> | null;
  isContract: boolean;
  requestId: string;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

const schema = object().shape({
  vehicleType: yup.object().nullable().required(),
  characteristics: yup.object().nullable().required(),
  serviceType: yup.object().nullable().required(),
  govNumber: yup.string().required(),
  workShift: yup.object().nullable().required(),
  unit: yup.object().nullable().required(),
  volume: yup.string().required(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
  price: yup.string().required(),
  priceWithoutNds: yup.string().required(),
  vehicle: yup.object().nullable().required(),
});

const initValues = JSON.stringify({
  vehicleType: null,
  characteristics: null,
  serviceType: null,
  govNumber: "",
  workShift: null,
  nomenclature: null,
  unit: null,
  volume: "",
  loadCapacity: "",
  craneBoomReach: "",
  height: "",
  startDate: null,
  endDate: null,
  price: "",
  priceWithoutNds: "",
  vehicle: null,
  isContract: true,
  requestId: "",
});

function ModalSmartContractVehicle(props: Props) {
  const { vehicle, onSave, organization, nds, onClose, ...restProps } = props;
  const { values, errors, onChange, setValues, validate, resetForm } =
    useForm<FormData>({
      values: {
        ...JSON.parse(initValues),
        requestId: new Date().getTime().toString(),
      },
      schema,
    });

  const onSubmitForm = useCallback(async () => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    onSave({
      vehicleId: values.vehicle!.value,
      govNumber: values.govNumber,
      vehicleType: values.vehicleType!.item!,
      serviceType: values.serviceType!.item!,
      workShift: values.workShift!.item!,
      unit: values.unit!.item!,
      characteristics: values.characteristics!.item!,
      nomenclature: values.nomenclature?.item || null,
      volume: Number(values.volume),
      height: Number(values.height),
      craneBoomReach: Number(values.craneBoomReach),
      startDate: values.startDate!.toJSON(),
      endDate: values.endDate!.toJSON(),
      price: Number(values.price),
      priceWithoutNds: Number(values.priceWithoutNds),
      loadCapacity: Number(values.loadCapacity),
      requestType: values.isContract ? 1 : 2,
      requestId: values.requestId,
    });
    resetForm();
  }, [validate, onSave, values, resetForm]);

  const onChangeIsContract = useCallback(() => {
    setValues({
      isContract: !values.isContract,
    });
  }, [setValues, values.isContract]);

  const loadCharacteristics = useCallback(
    (params: PageableParams<Characteristic>) => {
      return getCharacteristics({
        ...params,
        vehicleTypeId: values.vehicleType!.value,
      });
    },
    [values.vehicleType]
  );

  const loadServiceTypes = useCallback(
    (params: PageableParams<ServiceType>) => {
      return getServiceTypes({
        ...params,
        characteristicId: values.characteristics!.value,
      });
    },
    [values.characteristics]
  );

  const loadUnits = useCallback((params: PageableParams<Unit>) => {
    return getUnits({
      ...params,
      // nomenclatureId: values.nomenclature!.value,
    });
  }, []);

  const loadVehicles = useCallback(
    (params: PageableParams<VehicleListItem>) => {
      return getVehicles({
        ...params,
        companyId: organization.id,
      });
    },
    [organization]
  );

  useEffect(() => {
    if (!!vehicle) {
      // FIXME: перепроверить тип vehicle
      const vehicleTypeObject = {
        ...vehicle.vehicle,
        id: vehicle.vehicle?.id || "",
        name: [vehicle.vehicle?.vehicleType?.name, vehicle.vehicle?.govNumber]
          .filter(Boolean)
          .join(" "),
      };
      setValues({
        vehicleType: setSelectOption(vehicle.vehicleType),
        characteristics: setSelectOption(vehicle.characteristics),
        serviceType: setSelectOption(vehicle.serviceType),
        govNumber: vehicle.govNumber || "",
        workShift: setSelectOption(vehicle.workShift),
        nomenclature: setSelectOption(vehicle.nomenclature),
        unit: setSelectOption(vehicle.unit),
        volume: !!vehicle.volume ? vehicle.volume.toString() : "",
        loadCapacity: !!vehicle.loadCapacity
          ? vehicle.loadCapacity.toString()
          : "",
        craneBoomReach: !!vehicle.craneBoomReach
          ? vehicle.craneBoomReach.toString()
          : "",
        height: !!vehicle.height ? vehicle.height.toString() : "",
        startDate: !!vehicle.startDate ? new Date(vehicle.startDate) : null,
        endDate: !!vehicle.endDate ? new Date(vehicle.endDate) : null,
        price: !!vehicle.price ? vehicle.price.toString() : "",
        priceWithoutNds: !!vehicle.priceWithoutNds
          ? vehicle.priceWithoutNds.toString()
          : "",
        isContract: vehicle.requestType === 1,
        requestId: vehicle.requestId,
        // @ts-ignore
        vehicle: setSelectOption(vehicleTypeObject),
      });
    }
  }, [setValues, vehicle]);

  const modalTitle = useMemo(() => "Техника", []);

  const modalActions = useMemo(
    () => [<Button text="Сохранить" onClick={onSubmitForm} />],
    [onSubmitForm]
  );

  const vehicleLabelKeys = useMemo<(keyof VehicleListItem)[]>(
    () => ["vehicleType", "govNumber"],
    []
  );

  const onVehicleSelect = useCallback(
    (value: SelectOption<VehicleListItem> | null, name: any) => {
      onChange(value, name);
      if (value?.value) {
        getVehicleDetail(value.value).then((res) => {
          const {
            govNumber,
            type,
            vehicleTypeId,
            characteristicsId,
            characteristic,
          } = res.data;
          const _ch: SelectOption<Characteristic> = {
            label: characteristic.name,
            value: characteristicsId,
            // @ts-ignore
            item: {
              id: characteristic.id,
              name: characteristic.name,
            },
          };
          const _v: SelectOption<VehicleType> = {
            label: type.name,
            value: vehicleTypeId,
            item: type,
          };
          setValues({ govNumber, characteristics: _ch, vehicleType: _v });
        });
      }
    },
    [onChange, setValues]
  );

  const onPriceChange = useCallback(
    (value: string) => {
      let priceWithoutNds;
      let price;
      if (nds?.isNds) {
        price = value;
        priceWithoutNds = roundToTwo(subtractVAT(+value, nds?.rate)).toString();
      } else {
        priceWithoutNds = value;
        price = roundToTwo(addVAT(+value, 1 + (nds?.rate || 0))).toString();
      }
      setValues({ price, priceWithoutNds });
    },
    [nds, setValues]
  );

  const onModalClose = useCallback(() => {
    setValues(JSON.parse(initValues));
    onClose();
  }, [onClose, setValues]);

  if (!restProps.open) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      actions={modalActions}
      onClose={onModalClose}
      {...restProps}
    >
      <StyledGrid>
        <Select<VehicleListItem>
          label="Техника"
          name="vehicle"
          onChange={onVehicleSelect}
          value={values.vehicle}
          error={!!errors.vehicle}
          helperText={errors.vehicle}
          valueKey="id"
          labelKeys={vehicleLabelKeys}
          loadData={loadVehicles}
        />
        <Select<VehicleType>
          label="Вид техники"
          name="vehicleType"
          onChange={onChange}
          value={values.vehicleType}
          error={!!errors.vehicleType}
          helperText={errors.vehicleType}
          valueKey="id"
          labelKey="name"
          loadData={getVehicleTypes}
        />
        <Select<Characteristic>
          label="Характеристика техники"
          name="characteristics"
          onChange={onChange}
          value={values.characteristics}
          error={!!errors.characteristics}
          helperText={errors.characteristics}
          valueKey="id"
          labelKey="name"
          loadData={loadCharacteristics}
          disabled={!values.vehicleType}
        />
        <Select<ServiceType>
          label="Вид услуги"
          name="serviceType"
          onChange={onChange}
          value={values.serviceType}
          error={!!errors.serviceType}
          helperText={errors.serviceType}
          valueKey="id"
          labelKey="name"
          disabled={!values.characteristics}
          loadData={loadServiceTypes}
        />
        <TextField
          label="Гос номер"
          placeholder="Введите гос номер"
          name="govNumber"
          onChange={onChange}
          value={values.govNumber}
          error={!!errors.govNumber}
          helperText={errors.govNumber}
        />
        <Select<WorkShift>
          label="Смена"
          name="workShift"
          onChange={onChange}
          value={values.workShift}
          error={!!errors.workShift}
          helperText={errors.workShift}
          valueKey="id"
          labelKey="name"
          loadData={getWorkShifts}
        />
        <Select<Nomenclature>
          label="Номенклатура"
          name="nomenclature"
          onChange={onChange}
          value={values.nomenclature}
          valueKey="id"
          labelKey="name"
          loadData={getNomenclatures}
        />
        <Select<Unit>
          label="Единица измерения работ"
          name="unit"
          onChange={onChange}
          value={values.unit}
          error={!!errors.unit}
          helperText={errors.unit}
          valueKey="id"
          labelKey="name"
          loadData={loadUnits}
        />
        <TextField
          label="Объём работ"
          placeholder="Введите объём"
          name="volume"
          onChange={onChange}
          value={values.volume}
          error={!!errors.volume}
          helperText={errors.volume}
          onBlur={() => {
            onChange(Math.ceil(Math.abs(+values.volume)).toString(), "volume");
          }}
          inputProps={{
            min: 1,
          }}
        />
        <TextField
          label="Грузоподъемность"
          placeholder="Введите грузоподъемность"
          name="loadCapacity"
          onChange={onChange}
          value={values.loadCapacity}
        />
        <TextField
          label="Вылет (М)"
          placeholder="Введите вылет (М)"
          name="craneBoomReach"
          onChange={onChange}
          value={values.craneBoomReach}
        />
        <TextField
          label="Высота (М)"
          placeholder="Введите высота (М)"
          name="height"
          onChange={onChange}
          value={values.height}
        />
        <DateTimePicker
          label="Время начало работ"
          name="startDate"
          hideTime={true}
          onChange={onChange}
          value={values.startDate}
          error={!!errors.startDate}
          helperText={errors.startDate}
        />
        <DateTimePicker
          label="Время окончания работ"
          name="endDate"
          hideTime={true}
          onChange={onChange}
          value={values.endDate}
          error={!!errors.endDate}
          helperText={errors.endDate}
        />
        <TextField
          label="Цена без НДС"
          placeholder="Введите цену без НДС"
          name="priceWithoutNds"
          priceFormat={true}
          disabled={nds?.isNds}
          value={values.priceWithoutNds}
          error={!!errors.priceWithoutNds}
          helperText={errors.priceWithoutNds}
          onChange={onPriceChange}
        />
        <TextField
          label="Цена с НДС"
          placeholder="Введите с НДС"
          name="price"
          priceFormat={true}
          disabled={!nds?.isNds}
          value={values.price}
          error={!!errors.price}
          helperText={errors.price}
          onChange={onPriceChange}
        />
        <div>
          <Checkbox
            onChange={onChangeIsContract}
            checked={!values.isContract}
            label="Доп затраты"
          />
        </div>
      </StyledGrid>
    </Modal>
  );
}

export default memo(ModalSmartContractVehicle);
