import React from "react";
import { Grid, ListItem } from "@app/components";
import { format } from "date-fns";
import { vehicleTypes } from "../constants";
import { Styled } from "../components";
import { FilterParams } from "../types";

type Props = {
  filterParams: FilterParams;
};

const HeaderInfo: React.FC<Props> = ({ filterParams }) => {
  return (
    <Styled.Header>
      <Grid columns={5}>
        <ListItem
          title={filterParams?.organization?.label}
          subtitle={"Организация"}
        />
        <ListItem title={filterParams?.project?.label} subtitle={"Проект"} />
        <ListItem
          title={
            filterParams?.startDate
              ? format(new Date(filterParams.startDate), "dd.MM.yyyy")
              : ""
          }
          subtitle={"Дата начала"}
        />
        <ListItem title={filterParams?.contract?.label} subtitle={"Договор"} />
        <ListItem title={filterParams?.currency?.label} subtitle={"Валюта"} />
        <ListItem
          title={filterParams?.partner?.label}
          subtitle={"Контрагент"}
        />
        <ListItem
          title={
            vehicleTypes[filterParams?.vehicleType?.value === "true" ? 1 : 0]
              .label
          }
          subtitle={"Тип техники"}
        />
        <ListItem
          title={
            filterParams?.endDate
              ? format(new Date(filterParams.endDate), "dd.MM.yyyy")
              : ""
          }
          subtitle={"Дата завершения"}
        />
        <ListItem
          title={filterParams?.nds?.label || "-"}
          subtitle={"Ставка НДС"}
        />
      </Grid>
    </Styled.Header>
  );
};

export default HeaderInfo;
