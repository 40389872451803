import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../styles.scss";
import { GEOFence } from "@app/models";
import { getGEOFence, getGEOFences } from "@app/api";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Pagination, ProgressBar } from "@app/components";
import { getUrlParams, isDispatcher, updateUrlParams } from "@app/helpers";
import { useMonitoring } from "@app/providers";
import MonitoringGEOFence from "../GEOFence";
import { ModalGeofence } from "@app/modals";
import { useUser } from "@app/providers";

const StyledGEOFenceResults = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledGEOFences = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
`;

const StyledPagination = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 48px;
  border-top: 1px solid ${theme("color.grayLight")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme("color.white")};
`;

const StyledResetFilter = styled.button`
  padding: 16px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme("color.dark")};
  margin: 0;
  text-align: start;

  &:hover {
    text-decoration: underline;
  }
`;

const initialSearchParams = getUrlParams<{
  page: number;
  perPage: number;
}>(["page", "perPage"]);

interface Props {}

export default function (_props: Props) {
  const { user } = useUser();
  const {
    geoFenceDetail,
    setVehicles,
    setGEOFence,
    modalVisible,
    setModalVisible,
    setGEOFenceLatLngList,
    selectedGeoFences,
    setSelectedGeofence,
  } = useMonitoring();
  const [pending, setPending] = useState<boolean>(true);

  const [pendingGEOFence, setPendingGEOFence] = useState<boolean>(false);
  // const [modalVisible, setModalVisible] = useState(false);
  const [geoFences, setGeofences] = useState<GEOFence[]>([]);
  const [page, setPage] = useState<number>(
    Number(initialSearchParams.page) || 1
  );
  const [totalCount, setTotalCount] = useState(0);

  const perPage = useMemo<number>(() => initialSearchParams.perPage || 20, []);

  const isGeoFenceSelect = useCallback(
    (id: GEOFence["id"]) => {
      return selectedGeoFences.some((geofence) => geofence.id === id);
    },
    [selectedGeoFences]
  );

  const getData = useCallback(async () => {
    try {
      setPending(true);

      updateUrlParams({
        page,
        perPage,
      });

      const response = await getGEOFences({
        pageSize: perPage,
        pageNumber: page,
      });

      if (response) {
        setTotalCount(response.recordsFiltered);
        setGeofences(response.data);
      }
      setPending(false);
    } catch (e) {
      setPending(false);
    }
  }, [page, perPage]);

  const onChangePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onClickGEOFence = useCallback(
    async (geoFence: GEOFence) => {
      try {
        setPendingGEOFence(true);
        setModalVisible(false);
        setGEOFence(null);

        const { data } = await getGEOFence(geoFence.id);

        setGEOFence(data);
        const latLngList = data.coordinates.map(
          ([lat, lng]) => new google.maps.LatLng(lat, lng)
        );
        setGEOFenceLatLngList(latLngList);

        // if (latLngList.length > 0) {
        //   const bounds = new window.google.maps.LatLngBounds();
        //   latLngList.forEach((point) => bounds.extend(point));
        //   map.fitBounds(bounds);
        //   setZoom(map.getZoom()! - 1);
        // }

        setPendingGEOFence(false);
        setModalVisible(true);
      } catch (e) {
        setPendingGEOFence(false);
      }
    },
    [setGEOFence, setGEOFenceLatLngList, setModalVisible]
  );

  const onSelectGEOFence = useCallback(
    (geoFence: GEOFence) => {
      const findIndex = selectedGeoFences.findIndex(
        (item) => item.id === geoFence.id
      );
      if (findIndex > -1) {
        selectedGeoFences.splice(findIndex, 1);
        setSelectedGeofence([...selectedGeoFences]);
        setGEOFence(null);
        setGEOFenceLatLngList([]);
        if (selectedGeoFences.length === 0) {
          setModalVisible(false);
        }
      } else {
        setSelectedGeofence([...selectedGeoFences, geoFence]);
        setGEOFence(geoFence);
        const latLngList = geoFence.coordinates.map(
          ([lat, lng]) => new google.maps.LatLng(lat, lng)
        );
        setGEOFenceLatLngList(latLngList);
      }
    },
    [selectedGeoFences, setGEOFence, setGEOFenceLatLngList, setSelectedGeofence]
  );

  const onClickClear = useCallback(() => {
    setSelectedGeofence([]);
  }, [setSelectedGeofence]);

  const closeModal = useCallback(
    (needUpdate?: boolean) => {
      setModalVisible(false);
      setGEOFence(null);
      setGEOFenceLatLngList([]);
      if (needUpdate) {
        getData();
      }
    },
    [getData, setGEOFence, setGEOFenceLatLngList, setModalVisible]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setVehicles([]);
    setGEOFence(null);
    // drawGEOFence(null);
  }, [setGEOFence, setVehicles]);

  return (
    <>
      <StyledGEOFenceResults>
        {(pending || pendingGEOFence) && <ProgressBar />}
        <StyledResetFilter onClick={onClickClear}>
          Отменить выбор
        </StyledResetFilter>
        <StyledGEOFences>
          {geoFences.map((item) => (
            <MonitoringGEOFence
              key={item.id}
              active={item.id === geoFenceDetail?.id}
              isSelected={isGeoFenceSelect(item.id)}
              geoFence={item}
              onClick={onClickGEOFence}
              onSelect={onSelectGEOFence}
            />
          ))}
        </StyledGEOFences>
        <StyledPagination>
          <Pagination
            onChange={onChangePage}
            pageSize={20}
            initialPage={page}
            totalCount={totalCount}
            siblingCount={4}
          />
        </StyledPagination>
      </StyledGEOFenceResults>
      {!!user && isDispatcher(user.role) && (
        <ModalGeofence
          open={modalVisible}
          title="Свойства геозоны"
          onClose={closeModal}
        />
      )}
    </>
  );
}
