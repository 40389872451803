import React, { memo, useCallback, useEffect } from "react";
import { Button, Modal, ModalProps, TextField, useForm } from "@app/components";
import * as yup from "yup";
import { getAxiosErrorMessage, setMask } from "@app/helpers";
import { APIResponse, verifyCode } from "@app/api";
import "./styles.scss";
import Cookies from "js-cookie";
import { useCountdown } from "../../hooks/useCountdown";
import { useNotification, useUser } from "@app/providers";
import { AxiosError } from "axios";

interface Props extends Omit<ModalProps, "title"> {
  onClose: (val?: boolean) => void;
}

const ModalSMSVerification = (props: Props) => {
  const { onClose, open, ...restProps } = props;
  const { sendCode } = useUser();
  const { showNotification } = useNotification();
  const { timeStr, isExpired, setCountDownDate } = useCountdown(
    Cookies.get("send_time") || ""
  );

  const { values, errors, onChange, validate, setPending, pending } = useForm<{
    phone: string;
    code: string;
  }>({
    values: {
      phone: "",
      code: "",
    },
    schema: yup.object().shape({
      code: yup.string().length(4).required(),
      phone: yup.string().required(),
    }),
  });

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const res = await verifyCode(values.phone.substring(1), values.code);

      if (res) {
        Cookies.set("accessToken", res.message);
        onClose(true);
      }

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [validate, setPending, values, onClose, showNotification]);

  useEffect(() => {
    setCountDownDate(new Date(+(Cookies.get("send_time") || "")).getTime());
    onChange(Cookies.get("phone") || "", "phone");
  }, [onChange, open, setCountDownDate]);

  const resendCode = async () => {
    setCountDownDate(new Date().getTime() + 1000 * 61);
    await sendCode(values.phone);
  };

  const changePhoneNumber = useCallback(() => {
    Cookies.remove("phone");
    Cookies.remove("send_time");
    onChange("", "phone");
    onClose();
  }, [onChange, onClose]);

  return (
    <Modal
      open={open}
      title={"title"}
      position={"center"}
      size={"xsmall"}
      hideHeader
      onClose={onClose}
      {...restProps}
    >
      <div className="otp-modal">
        <p className="otp-modal__title">Введите код из SMS</p>
        <p className="otp-modal__subtitle">
          На ваш номер {values.phone} отправлено сообщение с кодом
        </p>
        <div className="otp-modal__form">
          <TextField
            placeholder="Код подтверждения"
            value={values.code}
            name="code"
            onChange={(value, name) => {
              const formatted = setMask(value.replace(/\D/g, ""), "####");
              onChange(formatted, name);
            }}
            error={!!errors.code}
            helperText={errors.code}
          />
          {isExpired ? (
            <a className="otp-modal__red-link" onClick={resendCode}>
              Получить новый код
            </a>
          ) : (
            <p className="otp-modal__caption">
              Получить новый код можно через&nbsp;
              <span className="otp-modal__caption--time">{timeStr}</span>
            </p>
          )}

          <Button
            text="Подтвердить"
            disabled={pending || values.code.length < 4}
            showLoader={pending}
            onClick={onClickSubmit}
            className="otp-modal__btn"
          />
        </div>
        <a onClick={changePhoneNumber} className="otp-modal__link">
          Изменить номер
        </a>
      </div>
    </Modal>
  );
};

export default memo(ModalSMSVerification);
