import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { switchProp, theme } from "styled-tools";

interface Props {
  title: string;
  text: string;
  excerpt?: string;
  small?: boolean;
}

const StyledPanel = styled.div`
  background: #f5f6f8;
  width: 400px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
`;

const StyledTitle = styled.p<{
  small: boolean;
}>`
  font-weight: 600;
  color: ${theme("color.dark")};
  white-space: pre-wrap;
  margin: 20px 20px 0;
  ${switchProp("small", {
    true: css`
      font-size: 16px;
      line-height: 24px;
    `,
    false: css`
      font-size: 24px;
      line-height: 32px;
    `,
  })}

  > span {
    font-weight: 400;
  }
`;

const StyledText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 20px;
  color: ${theme("color.grayDark")};
`;

export default function (props: Props & PropsWithChildren) {
  const { title, text, excerpt, small = false, children } = props;

  return (
    <StyledPanel>
      <StyledTitle small={small}>
        {title}
        {!!excerpt && <span> - {excerpt}</span>}
      </StyledTitle>
      <StyledText>{text}</StyledText>
      <div>{children}</div>
    </StyledPanel>
  );
}
