import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  DateTimePicker,
  Grid,
  Select,
  SelectOption,
  TextField,
} from "@app/components";
import { NDS, NdsShort } from "@app/models";
import styled from "styled-components";
import { getCompanyTypes, getNds, getRegions } from "@app/api";
import { theme } from "styled-tools";
import { getCountries } from "../../../api/country";
import { formatPhoneNumber } from "@app/helpers";

type DataErrors = {
  [key in keyof any]?: string;
};

type Region = {
  id: string;
  name: string;
};

type Country = {
  id: string;
  name: string;
};

interface Props {
  dataOfOrganization: any;
  dataParams?: any;
  dataErrors: DataErrors;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledHeader = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 8px;
`;

const StyledAddressContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${theme("color.grayLight")};
`;

const initialParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

function StepView1(props: Props) {
  const { dataParams, dataErrors, onDataChange } = props;
  const [errors, setErrors] = useState<DataErrors>({});
  const [companyTypes, setCompanyTypes] = useState<SelectOption<any>[]>([]);
  const [ndsList, setNdsList] = useState<SelectOption<any>[]>([]);
  const [regions, setRegions] = useState<SelectOption<any>[]>([]);
  const [countries, setCountries] = useState<SelectOption<any>[]>([]);
  const [showSeriesField, setShowSeriesField] = useState(true);

  useEffect(() => {
    setErrors(dataErrors);
  }, [dataErrors]);

  const handleChange = (value: any, name: any) => {
    if (name === "bin" || name === "headIdn") {
      value = value.replace(/\D/g, "");

      if (value.length > 12) {
        value = value.slice(0, 12);
      }
    }

    if (name === "headName") {
      value = value.replace(/[^a-zA-Zа-яА-Я\s]/g, "");
    }

    onDataChange(value, name);

    if (name === "registrationCertificateDate") {
      checkDate(value);
    }
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/\D/g, "");
    const formattedValue = formatPhoneNumber(
      rawValue,
      dataParams.country.label
    );
    onDataChange(formattedValue, "phone");
  };

  const handleTextFieldChange = (value: string, name: any) => {
    const event = { target: { value } } as React.ChangeEvent<HTMLInputElement>;
    handlePhoneChange(event);
  };

  const onChangeCompanyType = useCallback(
    (value: any, name: any) => {
      onDataChange(value, name);
      if (value.value !== dataParams.companyType) {
        onDataChange(null, "type");
      }
    },
    [onDataChange, dataParams]
  );

  const loadCompanyTypes = useCallback(async () => {
    try {
      const response = await getCompanyTypes();
      const formattedData = response.data.map((companyTypes: any) => {
        return {
          value: companyTypes.id,
          label: companyTypes.name,
          item: companyTypes,
        };
      });

      setCompanyTypes(formattedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка: ", error);
    }
  }, []);

  const loadNds = useCallback(
    async (params: any) => {
      try {
        if (!dataParams.region) {
          setNdsList([]);
          return;
        }

        const response = await getNds({
          ...params,
          regionId: dataParams.region.value,
        });
        const formattedData = response.data.map((nds: NDS) => {
          return {
            value: nds.id,
            label: nds.name,
            item: nds,
          };
        });

        setNdsList(formattedData);
      } catch (error) {
        console.error("Ошибка при загрузке списка:  ", error);
      }
    },
    [dataParams.region]
  );

  const loadRegions = useCallback(async (params: any) => {
    try {
      const response = await getRegions(params);
      const countries = await getCountries(params);

      const formattedData: SelectOption<any>[] = response.data.map(
        (region: any) => {
          return {
            value: region.id,
            label: region.name.trim(),
            item: region,
          };
        }
      );

      const formattedCountryData: SelectOption<Country>[] = countries.data.map(
        (country: Country) => {
          return {
            value: country.id,
            label: country.name,
            item: country,
          };
        }
      );

      const priorityRegions = ["Астана", "Алматы", "Шымкент", "Ташкент"];
      const priorityData: SelectOption<Region>[] = [];
      const otherData: SelectOption<Region>[] = [];

      formattedData.forEach((region: SelectOption<Region>) => {
        if (priorityRegions.includes(region.label)) {
          priorityData.push(region);
        } else {
          otherData.push(region);
        }
      });

      otherData.sort((a: SelectOption<Region>, b: SelectOption<Region>) =>
        a.label.localeCompare(b.label)
      );
      priorityData.sort(
        (a: SelectOption<Region>, b: SelectOption<Region>) =>
          priorityRegions.indexOf(a.label) - priorityRegions.indexOf(b.label)
      );

      const sortedData: SelectOption<Region>[] = [
        ...priorityData,
        ...otherData,
      ];

      const sortedCountryData = formattedCountryData.sort((a, b) => {
        if (a.label === "Казахстан") return -1;
        if (b.label === "Казахстан") return 1;
        return 0;
      });

      setCountries(sortedCountryData);
      setRegions(sortedData);
    } catch (error) {
      console.error("Ошибка при загрузке списка:  ", error);
    }
  }, []);

  const contractBasisType: SelectOption<any>[] = useMemo(() => {
    if (dataParams.companyType && dataParams.companyType.value === 1) {
      return [
        {
          label:
            "Свидетельство (Талон) о регистрации индивидуального предпринимателя",
          value: 3,
          item: null,
        },
      ];
    }

    if (dataParams.companyType && dataParams.companyType.value === 2) {
      return [
        {
          label: "Устав",
          value: 1,
          item: null,
        },
        {
          label: "Доверенности",
          value: 2,
          item: null,
        },
      ];
    }
    return [];
  }, [dataParams.companyType]);

  const onBinChange = (value: any, name: any) => {
    if (name === "bin" || name === "headIdn") {
      value = value.replace(/\D/g, "");

      if (value.length > 12) {
        value = value.slice(0, 12);
      }
    }
    onDataChange(value, name);
  };

  useEffect(() => {
    (async () => {
      await loadCompanyTypes();
      await loadNds(initialParams);
      await loadRegions(initialParams);
    })();
  }, [loadCompanyTypes, loadNds, loadRegions]);

  useEffect(() => {
    if (dataParams.registrationCertificateDate) {
      checkDate(dataParams.registrationCertificateDate);
    }
  }, [dataParams.registrationCertificateDate]);

  const checkDate = (date: string) => {
    const selectedDate = new Date(date);
    const cutOffDate = new Date("2014-12-31");
    const show = selectedDate < cutOffDate;
    setShowSeriesField(show);
  };

  return (
    <div>
      <Header>
        <StyledHeader>Общая информация</StyledHeader>
        <Grid columns={1}>
          <Select<any>
            label="Страна"
            name="country"
            onChange={handleChange}
            value={dataParams.country}
            error={!!errors.country}
            helperText={errors.country}
            placeholder={"Выберите из списка"}
            valueKey="id"
            labelKey="country"
            options={countries}
            onClear={() => handleChange(null, "country")}
          />
        </Grid>
        {dataParams.country && dataParams.country?.label !== "Казахстан" && (
          <Grid columns={1}>
            <TextField
              label="Регистрационный номер"
              placeholder="Введите регистрационный номер"
              name="registrationNumber"
              onChange={handleChange}
              value={dataParams.registrationNumber}
              error={!!errors.registrationNumber}
              helperText={errors.registrationNumber}
            />
          </Grid>
        )}
        <Grid columns={2}>
          <Select<any>
            label="Форма организации"
            name="companyType"
            onChange={onChangeCompanyType}
            value={dataParams.companyType}
            error={!!errors.companyType}
            helperText={errors.companyType}
            valueKey="id"
            labelKey="name"
            options={companyTypes}
          />
          <TextField
            label="Наименование организации"
            placeholder={"Введите наименование"}
            name="organization"
            onChange={handleChange}
            value={dataParams.organization}
            error={!!errors.organization}
            helperText={errors.organization}
          />
        </Grid>
        <Grid columns={2}>
          <TextField
            label="ИИН/БИН"
            placeholder="Введите ИИН/БИН"
            name="bin"
            maxLength={12}
            onChange={onBinChange}
            value={dataParams.bin}
            error={!!errors.bin}
            helperText={errors.bin}
          />
          <TextField
            label="Лицо"
            placeholder="Введите лицо"
            name="name"
            onChange={handleChange}
            value={dataParams.name.fullName}
            disabled={true}
          />
        </Grid>

        <Grid columns={2}>
          <TextField
            label="ФИО Директора"
            placeholder={"Введите ФИО директора"}
            name="headName"
            type={"text"}
            onChange={handleChange}
            value={dataParams.headName}
            error={!!errors.headName}
            helperText={errors.headName}
          />
          <TextField
            label="ИИН/БИН директора"
            placeholder="Введите ИИН/БИН директора"
            name="headIdn"
            maxLength={12}
            onChange={onBinChange}
            value={dataParams.headIdn}
            error={!!errors.headIdn}
            helperText={errors.headIdn}
          />
        </Grid>
        <StyledDivider />
        <StyledAddressContent>
          <StyledHeader>Адрес организации</StyledHeader>
          <Grid columns={2}>
            <Select<any>
              label="Регион"
              name="region"
              onChange={handleChange}
              value={dataParams.region}
              error={!!errors.region}
              helperText={errors.region}
              placeholder={"Выберите из списка"}
              valueKey="id"
              labelKey="name"
              options={regions}
              onClear={() => handleChange(null, "region")}
            />
            <TextField
              label="Улица"
              placeholder="Введите улицу"
              name="street"
              onChange={handleChange}
              value={dataParams.street}
              error={!!errors.street}
              helperText={errors.street}
            />
          </Grid>
          <Grid columns={2} style={{ marginTop: 16 }}>
            <TextField
              label="№ дома"
              placeholder="№ дома"
              name="block"
              onChange={handleChange}
              value={dataParams.block}
              error={!!errors.block}
              helperText={errors.block}
            />
            <TextField
              label="№ офиса/квартиры"
              placeholder="№ офиса/квартиры"
              name="apartment"
              onChange={handleChange}
              value={dataParams.apartment}
              error={!!errors.apartment}
              helperText={errors.apartment}
            />
          </Grid>
          <Grid columns={2} style={{ marginTop: 16 }}>
            <Select<NdsShort>
              label="Ставка НДС"
              name="nds"
              onChange={handleChange}
              value={dataParams.nds}
              error={!!errors.nds}
              helperText={errors.nds}
              valueKey="id"
              labelKey="name"
              options={ndsList}
            />
            <TextField
              label="КБЕ"
              placeholder="Введите КБЕ"
              name="kbe"
              onChange={handleChange}
              value={dataParams.kbe}
              error={!!errors.kbe}
              helperText={errors.kbe}
            />
          </Grid>
        </StyledAddressContent>
        <StyledDivider />
        <StyledAddressContent>
          <StyledHeader>Прикрепление пользователя</StyledHeader>
          <Grid columns={1}>
            <Select<any>
              label="Вид основания"
              name="type"
              onChange={handleChange}
              value={dataParams.type}
              error={!!errors.type}
              helperText={errors.type}
              valueKey="id"
              labelKey="name"
              options={contractBasisType}
            />
          </Grid>
          {dataParams.type && dataParams.type.value === 2 && (
            <Grid columns={2} style={{ marginTop: 16 }}>
              <TextField
                label="Номер доверенности"
                placeholder="Введите номер доверенности"
                name="powerOfAttorniesNumber"
                onChange={handleChange}
                value={dataParams.powerOfAttorniesNumber}
                error={!!errors.powerOfAttorniesNumber}
                helperText={errors.powerOfAttorniesNumber}
              />
              <DateTimePicker
                label="Дата доверенности"
                name="powerOfAttorniesDate"
                onChange={handleChange}
                value={dataParams.powerOfAttorniesDate}
                error={!!errors.powerOfAttorniesDate}
                helperText={errors.powerOfAttorniesDate}
                hideTime={true}
              />
            </Grid>
          )}

          {dataParams.type && dataParams.type.value === 3 && (
            <Grid columns={2} style={{ marginTop: 16 }}>
              <Grid columns={1}>
                <DateTimePicker
                  label="Дата"
                  name="registrationCertificateDate"
                  onChange={handleChange}
                  value={dataParams.registrationCertificateDate}
                  error={!!errors.registrationCertificateDate}
                  helperText={errors.registrationCertificateDate}
                  hideTime={true}
                />
              </Grid>
              <Grid columns={2}>
                {showSeriesField && (
                  <TextField
                    label="Серия"
                    placeholder="Введите серию"
                    name="registrationCertificateSeries"
                    onChange={handleChange}
                    value={dataParams.registrationCertificateSeries}
                    error={!!errors.registrationCertificateSeries}
                    helperText={errors.registrationCertificateSeries}
                  />
                )}
                <TextField
                  label="Номер"
                  placeholder="Введите номер"
                  name="registrationCertificateNumber"
                  onChange={handleChange}
                  value={dataParams.registrationCertificateNumber}
                  error={!!errors.registrationCertificateNumber}
                  helperText={errors.registrationCertificateNumber}
                />
              </Grid>
            </Grid>
          )}
        </StyledAddressContent>
        <StyledDivider />
        <StyledAddressContent>
          <StyledHeader>Контакты организации</StyledHeader>
          <Grid columns={2}>
            <TextField
              label="Номер телефона"
              placeholder="Введите номер телефона"
              name="phone"
              onChange={handleTextFieldChange}
              value={dataParams.phone}
              error={!!errors.phone}
              helperText={errors.phone}
            />
            <TextField
              label="E-mail"
              placeholder="Введите e-mail"
              name="email"
              onChange={handleChange}
              value={dataParams.email}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
        </StyledAddressContent>
      </Header>
    </div>
  );
}

export default memo(StepView1);
