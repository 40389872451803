import axios, { AxiosHeaders } from "axios";
import Cookies from "js-cookie";
import qs from "qs";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1`,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  },
});

function instance(authorization = true, url?: string) {
  if (authorization) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        if (config.headers) {
          (config.headers as AxiosHeaders).set(
            "Authorization",
            `Bearer ${Cookies.get("accessToken") || ""}`
          );
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        if (error.response.status === 401) {
          // TODO update token
        }

        return Promise.reject(error);
      }
    );

    // axiosInstance.interceptors.request.
  }

  return axiosInstance;
}

export default instance;
