import React, {
  Component,
  createContext,
  memo,
  PropsWithChildren,
  useContext,
} from "react";
import {
  KIPCharacteristics,
  KIPCustomer,
  KIPDivision,
  KIPPartners,
  KIPProject,
  KIPRegion,
  KIPVehicle,
  KIPVehicleType,
} from "@app/models";
import { getKip, GetKIPDTO } from "@app/api";

interface Props extends PropsWithChildren {}

interface State {
  pending: boolean;
  loaded: boolean;
}

interface KIPContextValue {
  pending: boolean;
  loaded: boolean;
  getData: (params: GetKIPDTO) => Promise<unknown>;
  getDivisions: () => KIPDivision[];
  getProjects: () => KIPProject[];
  getVehiclesQuantity: () => number;
  getWorkLoad: () => number;
  getCostAmount: () => number;
  getUnusedAmount: () => number;
  getProjectTableData: () => Partial<KIPProject>[];
  getDivisionTableData: () => Partial<KIPDivision>[];
  getRegionTableData: () => Partial<KIPRegion>[];
  getCustomersTableData: () => Partial<KIPCustomer>[];
  getVehicles: (projectId: string) => Partial<KIPVehicle>[];
  getVehicleTypes: (projectId: string) => Partial<KIPVehicleType>[];
  getPartners: (projectId: string) => Partial<KIPPartners>[];
  getCharacteristics: (projectId: string) => Partial<KIPCharacteristics>[];
}

const KIPContext = createContext<KIPContextValue>({} as KIPContextValue);

class KIPProvider extends Component<Props, State> {
  divisions: KIPDivision[] = [];
  projects: KIPProject[] = [];
  regions: KIPRegion[] = [];
  customers: KIPCustomer[] = [];

  state: State = {
    pending: false,
    loaded: false,
  };

  getData = (params: GetKIPDTO) => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          pending: true,
        },
        async () => {
          try {
            const { data } = await getKip(params);
            this.divisions = data.divisions;
            this.projects = data.projects;
            this.regions = data.regions;
            this.customers = data.customers;

            this.setState(
              {
                pending: false,
                loaded: true,
              },
              () => {
                resolve(true);
              }
            );
          } catch (e) {
            this.setState(
              {
                pending: false,
              },
              () => {
                reject(e);
              }
            );
            // showNotification({
            //   message: "Возникла ошибка",
            //   variant: "error",
            // });
          }
        }
      );
    });
  };

  getDivisions = (): KIPDivision[] => {
    return this.divisions;
  };

  getProjects = (): KIPProject[] => {
    return this.projects;
  };

  getVehiclesQuantity = (): number => {
    return this.projects.reduce((acc, item) => acc + item.vehiclesQty, 0);
  };

  getWorkLoad = (): number => {
    return (
      this.projects.reduce((acc, item) => acc + item.workLoad, 0) /
      this.projects.length
    );
  };

  getCostAmount = (): number => {
    return this.projects.reduce((acc, item) => acc + item.costAmount, 0);
  };

  getUnusedAmount = (): number => {
    return this.projects.reduce((acc, item) => acc + item.unusedAmount, 0);
  };

  getProjectTableData = (): Partial<KIPProject>[] => {
    return this.projects.map(({ vehicles, vehicleTypes, ...item }) => ({
      ...item,
    }));
  };

  getDivisionTableData = (): Partial<KIPDivision>[] => {
    return this.divisions.map(({ ...item }) => ({
      ...item,
      projects: item.projects.map(
        ({ vehicles, vehicleTypes, ...project }) =>
          ({
            ...project,
          } as KIPProject)
      ),
    }));
  };

  getRegionTableData = (): Partial<KIPDivision>[] => {
    return this.regions.map(({ ...item }) => ({
      ...item,
      projects: item.projects.map(
        ({ vehicles, vehicleTypes, ...project }) =>
          ({
            ...project,
          } as KIPProject)
      ),
    }));
  };

  getCustomerTableData = (): Partial<KIPDivision>[] => {
    return this.customers.map(({ ...item }) => ({
      ...item,
      projects: item.projects.map(
        ({ vehicles, vehicleTypes, ...project }) =>
          ({
            ...project,
          } as KIPProject)
      ),
    }));
  };

  getVehicles = (projectId: string): Partial<KIPVehicle>[] => {
    const project = this.projects.find((item) => item.id === projectId);

    if (!project) {
      return [];
    }

    return project.vehicles;
  };

  getVehicleTypes = (projectId: string): Partial<KIPVehicleType>[] => {
    const project = this.projects.find((item) => item.id === projectId);

    if (!project) {
      return [];
    }

    return project.vehicleTypes;
  };

  getPartners = (projectId: string): Partial<KIPVehicleType>[] => {
    const project = this.projects.find((item) => item.id === projectId);

    if (!project) {
      return [];
    }

    return project.partners;
  };

  getCharacteristics = (projectId: string): Partial<KIPCharacteristics>[] => {
    const project = this.projects.find((item) => item.id === projectId);

    if (!project) {
      return [];
    }

    return project.characteristics;
  };

  render() {
    const { children } = this.props;
    const { loaded, pending } = this.state;

    return (
      <KIPContext.Provider
        value={{
          loaded,
          pending,
          getData: this.getData,
          getDivisions: this.getDivisions,
          getProjects: this.getProjects,
          getVehiclesQuantity: this.getVehiclesQuantity,
          getWorkLoad: this.getWorkLoad,
          getCostAmount: this.getCostAmount,
          getUnusedAmount: this.getUnusedAmount,
          getProjectTableData: this.getProjectTableData,
          getDivisionTableData: this.getDivisionTableData,
          getRegionTableData: this.getRegionTableData,
          getCustomersTableData: this.getCustomerTableData,
          getVehicles: this.getVehicles,
          getVehicleTypes: this.getVehicleTypes,
          getPartners: this.getPartners,
          getCharacteristics: this.getCharacteristics,
        }}
      >
        {children}
      </KIPContext.Provider>
    );
  }
}

export function useKIP(): KIPContextValue {
  return useContext(KIPContext);
}

export default memo(KIPProvider);
