import React, { memo } from "react";
import { Modal, ModalProps } from "@app/components";
import { FormVehicleRequest } from "@app/forms";

interface Props extends ModalProps {}

function ModalVehicleRequestCreate(props: Props) {
  const { onClose, ...restProps } = props;

  return (
    <Modal onClose={onClose} {...restProps}>
      <FormVehicleRequest onSuccess={onClose} />
    </Modal>
  );
}

export default memo(ModalVehicleRequestCreate);
