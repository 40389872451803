import React, { ReactNode, useCallback, useMemo } from "react";
import { Chip, Table } from "@app/components";
import { priceFormat } from "@app/helpers";
import { KIPProject } from "@app/models";
import styled from "styled-components";
import { theme } from "styled-tools";
import { TooltipCell } from "@app/common";

interface Props {
  title?: string;
  projects: Partial<KIPProject>[];
  isGroupedByDivision?: boolean;
  onClick: (item: KIPProject) => void;
}

const StyledBlock = styled.div<{ isSkipped: boolean }>`
  display: flex;
  flex: 1;
  max-width: 800px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: ${(props) =>
    props.isSkipped ? theme("color.background") : theme("color.white")};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 -2px 8px 0 rgba(0, 0, 0, 0.08);
`;

const CellTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${theme("color.dark")};
`;

const TitleCell = styled.div`
  max-width: 240px;
  width: 240px;
  padding: 0 16px;
`;

const CellRedText = styled.div`
  color: ${theme("color.red")};
`;

const StyledTableLabel = styled.div`
  width: 64px;
  > div {
    margin-bottom: 6px;
  }
`;

export default function (props: Props) {
  const { title, projects, isGroupedByDivision, onClick } = props;

  const enableSkip = useMemo(
    () => projects.length > 4 && isGroupedByDivision,
    [isGroupedByDivision, projects.length]
  );

  const tableData = useMemo(() => {
    return projects.map((item, itemIndex) => {
      return [
        <TitleCell>
          <TooltipCell
            text={`${(itemIndex + 1).toString()}. ${item.name || ""}`}
            maxWidth={270}
          />
        </TitleCell>,
        item.vehiclesQty ? `${item.vehiclesQty} ТС` : "-",
        typeof item.workLoad === "number" ? `${item.workLoad.toFixed()}%` : "-",
        item.costAmount ? priceFormat(item.costAmount.toFixed()) : "-",
        <CellRedText>
          {item.unusedAmount ? priceFormat(item.unusedAmount.toFixed()) : "-"}
        </CellRedText>,
      ];
    });
  }, [projects]);

  const topTableData = useMemo(() => {
    if (enableSkip) {
      return tableData.slice(0, 2);
    }

    return tableData;
  }, [enableSkip, tableData]);

  const tableLabels = useMemo<(string | ReactNode)[]>(() => {
    const vehicleCount = projects.reduce(
      (acc, item) => acc + item.vehiclesQty!,
      0
    );

    const workLoad =
      projects.reduce(
        (acc, item) =>
          acc + (typeof item.workLoad === "number" ? item.workLoad : 0),
        0
      ) / projects.length;

    const costAmount = projects.reduce(
      (acc, item) => acc + item.costAmount!,
      0
    );

    const unusedAmount = projects.reduce(
      (acc, item) => acc + item.unusedAmount!,
      0
    );

    return [
      <TitleCell>
        {title ? <CellTitle>{title}</CellTitle> : "Наименование"}
      </TitleCell>,
      <StyledTableLabel>
        <Chip bold={true} color="primaryDark" text={`${vehicleCount} TC`} />
      </StyledTableLabel>,
      <StyledTableLabel>
        <Chip
          bold={true}
          color="warning"
          text={`${!!workLoad ? workLoad.toFixed() : 0}%`}
        />
      </StyledTableLabel>,
      <StyledTableLabel>
        <Chip color="info" text={priceFormat(costAmount.toFixed())} />
      </StyledTableLabel>,
      <StyledTableLabel>
        <Chip
          bold={true}
          color="danger"
          text={priceFormat(unusedAmount.toFixed())}
        />
      </StyledTableLabel>,
    ];
  }, [projects, title]);

  const onClickTableRow = useCallback(
    (index: number) => {
      onClick(projects[index] as KIPProject);
    },
    [onClick, projects]
  );

  return (
    <StyledBlock isSkipped={!!enableSkip}>
      <Table
        align="top"
        labels={tableLabels}
        data={topTableData}
        onClick={onClickTableRow}
        disableOverflow
        dense
      />
      {enableSkip && (
        <Table
          align="top"
          labels={tableLabels}
          data={tableData.slice(-2)}
          onClick={onClickTableRow}
          disableOverflow
          hideHeader
          dense
        />
      )}
    </StyledBlock>
  );
}
