export function formatPhoneNumber(
  value: string | null | undefined,
  country: string
) {
  if (!value) return "";

  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (!phoneNumberLength) return "";

  if (country === "Казахстан") {
    if (phoneNumberLength === 0) return "";
    if (phoneNumberLength < 2) return `+7`;
    if (phoneNumberLength < 5) {
      return `+7 (${phoneNumber.slice(1, 4)}`;
    }
    if (phoneNumberLength < 8) {
      return `+7 (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}`;
    }
    if (phoneNumberLength < 10) {
      return `+7 (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(
        4,
        7
      )}-${phoneNumber.slice(7, 9)}`;
    }
    return `+7 (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(
      4,
      7
    )}-${phoneNumber.slice(7, 9)}-${phoneNumber.slice(9, 11)}`;
  } else if (country === "Узбекистан") {
    if (phoneNumberLength === 0) return "";
    if (phoneNumberLength < 4) return `+998`;
    if (phoneNumberLength < 6) {
      return `+998 ${phoneNumber.slice(3, 5)}`;
    }
    if (phoneNumberLength < 9) {
      return `+998 ${phoneNumber.slice(3, 5)}-${phoneNumber.slice(5, 8)}`;
    }
    if (phoneNumberLength < 11) {
      return `+998 ${phoneNumber.slice(3, 5)}-${phoneNumber.slice(
        5,
        8
      )}-${phoneNumber.slice(8, 10)}`;
    }
    return `+998 ${phoneNumber.slice(3, 5)}-${phoneNumber.slice(
      5,
      8
    )}-${phoneNumber.slice(8, 10)}-${phoneNumber.slice(10, 12)}`;
  } else {
    return phoneNumber;
  }
}

export function stripPhoneNumberFormatting(phone: string) {
  return phone.replace(/\D/g, "");
}
