import React, { memo, useCallback, useMemo, useState } from "react";
import {
  Company,
  Project,
  ServiceType,
  VehicleRequest,
  VehicleRequestStatus,
  VehicleRequestStatusName,
  VehicleType,
} from "@app/models";
import {
  companyLabelKeys,
  dateFormat,
  enumToArray,
  getDateFilterValue,
  getFilterValues,
  getUrlParams,
  isDispatcher,
} from "@app/helpers";
import {
  Button,
  Combobox,
  DateTimePicker,
  DefaultObject,
} from "@app/components";
import { IconPlus20 } from "@app/icons";
import { TemplateSearch, TemplateSearchModalDetail } from "@app/templates";
import {
  getCompanies,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getServiceTypes,
  getVehiclesRequests,
  getVehicleTypes,
} from "@app/api";
import {
  ModalVehicleRequestCreate,
  ModalVehicleRequestDetail,
} from "@app/modals";
import { TooltipCell, VehicleRequestStatusChip } from "@app/common";
import {
  NotificationTypeNames,
  NotificationTypes,
} from "../../../providers/Firebase/Firebase";
import { useDebounce } from "@app/hooks";
import { useUser } from "@app/providers";

const FILTER_STORAGE_KEY = "vehicleRequestFilters";

interface FilterParamsProps {
  numbers: string[];
  organization: Array<Company> | null;
  project: Array<Project> | null;
  vehicleType: Array<VehicleType> | null;
  serviceType: Array<ServiceType> | null;
  status: DefaultObject<VehicleRequestStatus>[] | null;
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValuesProps {
  Numbers?: string[];
  OrganizationIds: string[];
  ProjectIds: string[];
  VehicleTypeIds: string[];
  ServiceTypeIds: string[];
  StatusIds: (string | number)[];
  FromDate?: string;
  ToDate?: string;
}

const initialFiltersParams = JSON.stringify({
  numbers: [],
  organization: null,
  project: null,
  vehicleType: null,
  serviceType: null,
  status: null,
  fromDate: null,
  toDate: null,
});

function DetailModal(props: TemplateSearchModalDetail<VehicleRequest>) {
  const { item: request, open, onClose, updateItem } = props;

  if (!open || !request) {
    return null;
  }

  return (
    <ModalVehicleRequestDetail
      showOffers={
        !!request &&
        [
          VehicleRequestStatus.AwaitingConfirmation,
          VehicleRequestStatus.Rejected,
        ].indexOf(request.status) === -1
      }
      data={request}
      open={open}
      onClose={onClose}
      updateData={updateItem}
    />
  );
}

function VehicleRequests() {
  const { user } = useUser();
  const childRef = React.useRef<{
    showAlert(): void;
  }>(null);
  const [modalCreateVisible, setModalCreateVisible] = useState<boolean>(false);

  const initialSearchParams = getUrlParams<FilterValuesProps>([
    "Numbers",
    "FromDate",
    "ToDate",
  ]);

  const [filterParams, setFilterParams] = useState<FilterParamsProps>(() => {
    const savedFilters = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
          ...JSON.parse(initialFiltersParams),
          numbers: initialSearchParams.Numbers
            ? [initialSearchParams.Numbers]
            : [],
          fromDate: initialSearchParams.FromDate
            ? new Date(initialSearchParams.FromDate)
            : null,
          toDate: initialSearchParams.ToDate
            ? new Date(initialSearchParams.ToDate)
            : null,
        };
  });

  const mapTableData = useCallback((item: VehicleRequest) => {
    return [
      item.number,
      <TooltipCell text={item.organization.name} />,
      dateFormat(item.startDate, "dd.MM.yyyy "),
      <TooltipCell text={item.project.name} />,
      item.vehicleType.name,
      item.serviceType.name,
      <VehicleRequestStatusChip status={item.status} />,
      dateFormat(item.endDate, "dd.MM.yyyy"),
    ];
  }, []);

  const onClickAdd = useCallback(() => {
    setModalCreateVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalCreateVisible(false);
  }, []);

  const tableLabels = useMemo(
    () => [
      "№",
      "Организация",
      "Дата начала",
      "Проект",
      "Вид техники",
      "Вид заказа",
      "Статус",
      "Дата завершения",
    ],
    []
  );

  const leftControls = useMemo(
    () => [
      <Button
        text="Создать заказ"
        startIcon={IconPlus20}
        onClick={onClickAdd}
      />,
    ],
    [onClickAdd]
  );

  const onFilterChange = useCallback(
    (value: any, key: string) => {
      const updatedFilters = { ...filterParams, [key]: value };
      setFilterParams(updatedFilters);
      localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(updatedFilters));
    },
    [filterParams]
  );

  const onFilterClear = useCallback(() => {
    const clearedFilters = JSON.parse(initialFiltersParams);
    setFilterParams(clearedFilters);
    localStorage.removeItem(FILTER_STORAGE_KEY);
  }, []);

  const filtersValue = useDebounce(
    useMemo(
      (): FilterValuesProps => ({
        Numbers: filterParams.numbers,
        OrganizationIds: getFilterValues(filterParams.organization),
        ProjectIds: getFilterValues(filterParams.project),
        VehicleTypeIds: getFilterValues(filterParams.vehicleType),
        ServiceTypeIds: getFilterValues(filterParams.serviceType),
        StatusIds: getFilterValues(filterParams.status),
        FromDate: getDateFilterValue(filterParams.fromDate),
        ToDate: getDateFilterValue(filterParams.toDate),
      }),
      [filterParams]
    ),
    600
  );

  const filters = useMemo(
    () => [
      <Combobox
        label="Номер"
        name="numbers"
        compliant={true}
        values={filterParams.numbers}
        onChange={onFilterChange}
      />,
      <Combobox<Company>
        label="Организация"
        name="organization"
        onChange={onFilterChange}
        values={filterParams.organization}
        labelKeys={companyLabelKeys}
        labelKeysSeparator={" / "}
        loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
      />,
      <Combobox<Project>
        label="Проект"
        name="project"
        onChange={onFilterChange}
        values={filterParams.project}
        loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
      />,
      <Combobox<VehicleType>
        label="Вид техники"
        name="vehicleType"
        onChange={onFilterChange}
        values={filterParams.vehicleType}
        loadData={getVehicleTypes}
      />,
      <Combobox<ServiceType>
        label="Вид услуги"
        name="serviceType"
        onChange={onFilterChange}
        values={filterParams.serviceType}
        loadData={(params) =>
          getServiceTypes({ ...params, characteristicId: "" })
        }
      />,
      <Combobox<DefaultObject<VehicleRequestStatus>>
        label="Статус"
        name="status"
        onChange={onFilterChange}
        values={filterParams.status}
        options={enumToArray(VehicleRequestStatusName)}
      />,
      <DateTimePicker
        label="Дата начала"
        name="fromDate"
        onChange={onFilterChange}
        value={filterParams.fromDate}
        hideTime
      />,
      <DateTimePicker
        label="Дата завершения"
        name="toDate"
        onChange={onFilterChange}
        value={filterParams.toDate}
        hideTime
      />,
    ],
    [filterParams, onFilterChange, user]
  );

  return (
    <>
      <TemplateSearch<VehicleRequest>
        toolbarProps={{
          onFilter: () => {},
          leftControls: leftControls,
          searchPlaceholder: "Задача, инициатор проект, контрагент",
        }}
        filterProps={{
          filters,
          filterParams: filtersValue,
          onClear: onFilterClear,
        }}
        getData={getVehiclesRequests}
        tableLabels={tableLabels}
        mapTableData={mapTableData}
        modalDetail={DetailModal}
        refreshEventName={
          NotificationTypeNames[NotificationTypes.RequestCreated]
        }
      />
      <ModalVehicleRequestCreate
        title="Создание заказа"
        open={modalCreateVisible}
        onClose={closeModal}
      />
    </>
  );
}

export default memo(VehicleRequests);
