import React, { memo } from "react";
import { Modal, ModalProps } from "@app/components";
import { FormCompany } from "@app/forms";
import { Company } from "@app/models";

interface Props extends ModalProps {
  company: Company | null;
}

function ModalCompanyCreate(props: Props) {
  const { onClose, company, ...restProps } = props;

  return (
    <Modal onClose={onClose} {...restProps}>
      {restProps.open && <FormCompany onSuccess={onClose} company={company} />}
    </Modal>
  );
}

export default memo(ModalCompanyCreate);
