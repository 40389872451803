import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNotification } from "@app/providers";
import {
  Checkbox,
  DateTimePicker,
  FilesPicker,
  Grid,
  Select,
  SelectOption,
  TextField,
} from "@app/components";

type DataErrors = {
  [key in keyof any]?: string;
};

interface Props {
  organization: any;
  onBack: () => void;
  dataParams?: any;
  dataErrors: DataErrors;
  onDataChange: (value: any[keyof any], key: keyof any) => void;
  resetForm: () => void;
  onCheckboxChange: (isChecked: boolean) => void;
}

const StyledOrganization = styled.div`
  box-sizing: border-box;
`;

const StyledContainer = styled.div`
  margin-top: 12px;
`;

function OrganizationDetails(props: Props) {
  const {
    organization,
    onBack,
    dataParams,
    dataErrors,
    onDataChange,
    resetForm,
    onCheckboxChange,
  } = props;
  const { showNotification } = useNotification();
  const [errors, setErrors] = useState<DataErrors>({});
  const [isSignatory, setIsSignatory] = useState(false);

  const contractBasisType: SelectOption<any>[] = useMemo(() => {
    if (organization.type.name === "ТОО") {
      return [
        {
          label: "Устав",
          value: 1,
          item: null,
        },
        {
          label: "Доверенность",
          value: 2,
          item: null,
        },
      ];
    } else if (organization.type.name === "ИП") {
      return [
        {
          label: "Свидетельство (Талон) о гос. регистрации",
          value: 3,
          item: null,
        },
      ];
    } else {
      return [];
    }
  }, [organization.type.name]);

  const handleChange = (value: any, name: any) => {
    onDataChange(value, name);
  };

  useEffect(() => {
    setErrors(dataErrors);
  }, [dataErrors]);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setIsSignatory(checked);
      onCheckboxChange(checked);
      onDataChange(checked, "isSigner");
      if (!checked) {
        resetForm();
      }
    },
    [resetForm, onCheckboxChange, onDataChange]
  );

  const onChangeFiles = useCallback(
    (files: File[]) => {
      onDataChange(files, "files");
    },
    [onDataChange]
  );

  const isDate2015OrLater = (date: any) => {
    return new Date(date).getFullYear() >= 2015;
  };

  return (
    <StyledOrganization>
      <Checkbox
        type="checkbox"
        label="Являетесь вы подписантом?"
        checked={isSignatory}
        onChange={handleCheckboxChange}
      />
      {isSignatory && (
        <Grid columns={1}>
          <StyledContainer>
            <Select<any>
              label="Основание подписания"
              name="type"
              onChange={handleChange}
              value={dataParams.type}
              error={!!errors.type}
              helperText={errors.type}
              valueKey="value"
              labelKey="label"
              options={contractBasisType}
            />
          </StyledContainer>
        </Grid>
      )}

      {isSignatory && dataParams.type && (
        <>
          {organization.type.name === "ТОО" && dataParams.type.value === 1 && (
            <Grid columns={1}>
              <StyledContainer>
                <FilesPicker
                  files={dataParams.files}
                  onChange={onChangeFiles}
                  horizontal={false}
                />
              </StyledContainer>
            </Grid>
          )}
          {dataParams.type.value === 2 && (
            <Grid columns={1}>
              <StyledContainer>
                <TextField
                  label="Номер доверенности"
                  placeholder="Введите номер доверенности"
                  name="powerOfAttorniesNumber"
                  onChange={handleChange}
                  value={dataParams.powerOfAttorniesNumber}
                  error={!!errors.powerOfAttorniesNumber}
                  helperText={errors.powerOfAttorniesNumber}
                />
              </StyledContainer>
              <StyledContainer>
                <DateTimePicker
                  label="Дата доверенности"
                  name="powerOfAttorniesDate"
                  onChange={handleChange}
                  value={dataParams.powerOfAttorniesDate}
                  error={!!errors.powerOfAttorniesDate}
                  helperText={errors.powerOfAttorniesDate}
                  hideTime={true}
                />
              </StyledContainer>
            </Grid>
          )}
          {organization.type.name === "ИП" && dataParams.type.value === 3 && (
            <Grid columns={1}>
              <StyledContainer>
                <DateTimePicker
                  label="Дата"
                  name="registrationCertificateDate"
                  onChange={handleChange}
                  value={dataParams.registrationCertificateDate}
                  error={!!errors.registrationCertificateDate}
                  helperText={errors.registrationCertificateDate}
                  hideTime={true}
                />
              </StyledContainer>
              {!isDate2015OrLater(dataParams.registrationCertificateDate) && (
                <StyledContainer>
                  <TextField
                    label="Серия"
                    placeholder="Введите серию"
                    name="registrationCertificateSeries"
                    onChange={handleChange}
                    value={dataParams.registrationCertificateSeries}
                    error={!!errors.registrationCertificateSeries}
                    helperText={errors.registrationCertificateSeries}
                  />
                </StyledContainer>
              )}
              <StyledContainer>
                <TextField
                  label="Номер"
                  placeholder="Введите номер"
                  name="registrationCertificateNumber"
                  onChange={handleChange}
                  value={dataParams.registrationCertificateNumber}
                  error={!!errors.registrationCertificateNumber}
                  helperText={errors.registrationCertificateNumber}
                />
              </StyledContainer>

              <StyledContainer>
                <FilesPicker
                  files={dataParams.files}
                  onChange={onChangeFiles}
                  horizontal={false}
                />
              </StyledContainer>
            </Grid>
          )}
        </>
      )}
    </StyledOrganization>
  );
}

export default memo(OrganizationDetails);
