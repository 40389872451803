import React, {
  Fragment,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
} from "react";
import Button from "../Button";
import "./styles.scss";
import classNames from "classnames";

interface Props extends PropsWithChildren {
  onSubmit: () => void;
  pending: boolean;
  submitLabel?: string;
  secondaryLabel?: string;
  className?: string;
  dense?: boolean;
  actions?: ReactNode[];
  readOnly?: boolean;
  disabled?: boolean;
}

function Form(props: Props) {
  const {
    onSubmit,
    className = "",
    pending,
    submitLabel = "Сохранить",
    dense = true,
    children,
    actions,
    readOnly = false,
    disabled = false,
  } = props;

  const onClickSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <div
      className={`${classNames("b-form", {
        "b-form--dense": dense,
      })} ${className}`.trim()}
    >
      <div className="b-form__content">{children}</div>
      <div className="b-form__footer">
        {!!actions &&
          actions.map((action, actionIndex) => (
            <Fragment key={actionIndex.toString()}>{action}</Fragment>
          ))}
        {!readOnly && (
          <Button
            text={submitLabel ? submitLabel : "Сохранить"}
            disabled={pending || disabled}
            showLoader={pending}
            onClick={onClickSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default memo(Form);
