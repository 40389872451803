export function excludeVAT(val: number, rate = 0) {
  return (val / (1 + rate)) * rate;
}

export function addVAT(val: number, rate = 0) {
  return val * rate;
}

export function subtractVAT(val: number, rate = 0) {
  return val - (val / (1 + rate)) * rate;
}
