import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CalendarDay, ICalendarDay } from "./components";
import "./styles.scss";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { useCalendar } from "./hooks";
import Select, { SelectOption } from "../Select/Select";
import { IconChevronLeft24, IconChevronRight24 } from "@app/icons";

export type ICalendarWeek = ICalendarDay[];

interface Props extends PropsWithChildren {
  value: Date | null;
  onClick: (day: ICalendarDay) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
}

function Calendar(props: Props) {
  const { minDate, maxDate, onClick, value, children, startDate, endDate } =
    props;
  const {
    data,
    // daysOfWeek,
    nextMonth,
    prevMonth,
    nextYear,
    prevYear,
    // currentMonth,
    setCurrentMonth,
  } = useCalendar({
    date: value,
  });

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedYear, setSelectedYear] = useState<number>(
    selectedDate.getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    selectedDate.getMonth()
  );

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
      setSelectedYear(value.getFullYear());
      setSelectedMonth(value.getMonth());
    }
  }, [value]);

  const onClickDay = useCallback(
    (date: ICalendarDay) => {
      setCurrentMonth(date.day);
      setSelectedDate(date.day);

      onClick(date);
    },
    [onClick, setCurrentMonth]
  );

  const years = Array.from({ length: 50 }, (_, i) => {
    const year = new Date().getFullYear() - 25 + i;
    return { label: year.toString(), value: year };
  });

  const months = [
    { label: "Январь", value: 0 },
    { label: "Февраль", value: 1 },
    { label: "Март", value: 2 },
    { label: "Апрель", value: 3 },
    { label: "Май", value: 4 },
    { label: "Июнь", value: 5 },
    { label: "Июль", value: 6 },
    { label: "Август", value: 7 },
    { label: "Сентябрь", value: 8 },
    { label: "Октябрь", value: 9 },
    { label: "Ноябрь", value: 10 },
    { label: "Декабрь", value: 11 },
  ];

  const onYearChange = (
    selectedOption: SelectOption<number> | null,
    _name: string
  ) => {
    if (selectedOption) {
      setSelectedYear(selectedOption.value);
      setCurrentMonth(new Date(selectedOption.value, selectedMonth, 1));
    }
  };

  const onMonthChange = (
    selectedOption: SelectOption<number> | null,
    _name: string
  ) => {
    if (selectedOption) {
      setSelectedMonth(selectedOption.value);
      setCurrentMonth(new Date(selectedYear, selectedOption.value, 1));
    }
  };

  const onCurrentDateClick = () => {
    const today = new Date();
    setSelectedDate(today);
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth());
    setCurrentMonth(today);
  };

  const weekNames = useMemo<string[]>(() => {
    return ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
  }, []);

  const calendarData = useMemo<ICalendarWeek[]>(() => {
    return data.map((week) =>
      week.map((day) => ({
        day: day.day,
        selected: !value ? false : isSameDay(day.day, value),
        disabled:
          (!!minDate && isBefore(day.day, minDate)) ||
          (!!maxDate && isAfter(day.day, maxDate)),
        outOfMonth: !day.sameMonth,
      }))
    );
  }, [data, maxDate, minDate, value]);

  return (
    <div className="b-calendar">
      <div className="b-calendar__header">
        <div className="b-calendar__controls">
          <button
            onClick={() => {
              prevMonth();
              setSelectedMonth((prev) => (prev === 0 ? 11 : prev - 1));
              if (selectedMonth === 0) {
                setSelectedYear((prev) => prev - 1);
              }
            }}
            className="b-calendar__control"
          >
            <IconChevronLeft24 />
          </button>
          <Select
            options={months}
            value={months.find((m) => m.value === selectedMonth) || null}
            onChange={onMonthChange}
            placeholder="Месяц"
            name="month"
          />
          <button
            onClick={() => {
              nextMonth();
              setSelectedMonth((prev) => (prev === 11 ? 0 : prev + 1));
              if (selectedMonth === 11) {
                setSelectedYear((prev) => prev + 1);
              }
            }}
            className="b-calendar__control"
          >
            <IconChevronRight24 />
          </button>
          <button
            onClick={() => {
              prevYear();
              setSelectedYear((prev) => prev - 1);
            }}
            className="b-calendar__control"
          >
            <IconChevronLeft24 />
          </button>
          <Select
            options={years}
            value={years.find((y) => y.value === selectedYear) || null}
            onChange={onYearChange}
            placeholder="Год"
            name="year"
          />
          <button
            onClick={() => {
              nextYear();
              setSelectedYear((prev) => prev + 1);
            }}
            className="b-calendar__control"
          >
            <IconChevronRight24 />
          </button>
        </div>

        <span className="b-calendar__header-text" onClick={onCurrentDateClick}>
          К текущей дате
        </span>
      </div>
      <div className="b-calendar__content">
        <div className="b-calendar__week">
          {weekNames.map((day, dayIndex) => (
            <CalendarDay day={day} key={dayIndex.toString()} />
          ))}
        </div>
        {calendarData.map((week, weekIndex) => (
          <div className="b-calendar__week" key={weekIndex.toString()}>
            {week.map((day, dayIndex) => (
              <CalendarDay
                {...day}
                onClick={onClickDay}
                key={dayIndex.toString()}
                startDate={startDate || undefined}
                endDate={endDate || undefined}
              />
            ))}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
}

export default memo(Calendar);
