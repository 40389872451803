import React, { Fragment, memo, useMemo } from "react";
import { Grid, Modal, ModalProps } from "@app/components";
import styled from "styled-components";
import { theme } from "styled-tools";
import { setMask } from "@app/helpers";
import { IconMail24, IconPhone24 } from "@app/icons";

interface Props extends Pick<ModalProps, "open" | "onClose"> {}

const StyledHeaderText = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${theme("color.dark")};
  margin: 0 0 32px;
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${theme("color.dark")};
  margin: 0;
  display: inline-flex;
  gap: 4px;
  align-items: center;

  > a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function ModalContacts(props: Props) {
  const { open, onClose } = props;

  const gpsProviders = useMemo(
    () => [
      {
        name: "TOO “StS Global” ",
        email: "support@sts-gps.com  ",
        phone: "7058722500",
      },
      {
        name: "TOO “Omnicomm Alliance”",
        email: "info@omnicomm-alliance.kz",
        phone: "8000802442",
      },
      {
        name: "TOO “Спектрум”        ",
        email: "astana_gps@spectrum.kz alliance.kz",
        phone: "7273440880",
      },
      {
        name: "TOO “Nomad GPS” ",
        email: "support@nomad-gps.com",
        phone: "7172730222",
      },
    ],
    []
  );

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      position="center"
      title=""
      size="medium"
      hideHeader={true}
    >
      <StyledHeaderText>Аккредитованные GPS компании:</StyledHeaderText>
      <Grid columns={3} gap={24}>
        {gpsProviders.map((gpsProvider, gpsProviderIndex) => (
          <Fragment key={gpsProviderIndex.toString()}>
            <StyledText>{gpsProvider.name}</StyledText>
            <StyledText>
              <IconMail24 />
              <a href={`mailto:${gpsProvider.email}`}>{gpsProvider.email}</a>
            </StyledText>
            <StyledText>
              <IconPhone24 />
              <a href={`tel:+7${gpsProvider.phone}`}>
                {setMask(gpsProvider.phone, "+7 (###) ### ## ##")}
              </a>
            </StyledText>
          </Fragment>
        ))}
      </Grid>
    </Modal>
  );
}

export default memo(ModalContacts);
