import { memo } from "react";
import styled from "styled-components";

type Props = {
  text: string;
  maxWidth?: number;
};

const StyledCell = styled.div<Pick<Props, "maxWidth">>`
  max-width: ${(props) => `${props.maxWidth || 345}px`};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TooltipCell = (props: Props) => {
  const { text, maxWidth } = props;
  return (
    <StyledCell maxWidth={maxWidth} title={text}>
      {text}
    </StyledCell>
  );
};

export default memo(TooltipCell);
