export function wordDeclension(number: number, words_arr: string[]): string {
  number = Math.abs(number);

  if (Number.isInteger(number)) {
    let options = [2, 0, 1, 1, 1, 2];

    return words_arr[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : options[number % 10 < 5 ? number % 10 : 5]
    ];
  }

  return words_arr[1];
}

// Пример
// wordDeclension(цифра, [слонение в ед. числе, слонение в 2-ом числе, слонение несколько])
// wordDeclension(1, ['месяц', 'масяца', 'месяцев']);
// слонение в ед. числе - один месяц
// слонение в 2-ом числе - два месяца
// слонение несколько - несколько месяцев
