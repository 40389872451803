import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Company,
  ContractDraftShort,
  NdsShort,
  Project,
  SmartContract,
  SmartContractAdditionalReason,
  SmartContractVehicleType,
  SmartContractVehicleTypeName,
} from "@app/models";
import styled from "styled-components";
import { theme } from "styled-tools";
import {
  Button,
  Checkbox,
  MultiSelect,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import * as yup from "yup";
import { useNotification } from "@app/providers";
import {
  APIResponse,
  createSmartContract,
  getAdditionalContractReason,
  getCompaniesFiltered,
  getSmartContracts,
  getProjects,
  PageableParams,
} from "@app/api";
import {
  companyLabelKeys,
  getAxiosErrorMessage,
  getDictionaryName,
  setSelectOption,
} from "@app/helpers";
import { AxiosError } from "axios";

interface FilterProps {
  contract: SmartContract | null;
  onCreate: (contract: SmartContract) => void;
}

const StyledFilter = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  background: #f5f6f8;
  border-bottom: 1px solid ${theme("color.grayLight")};
  margin: -16px -24px 0;
  padding: 16px 24px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
`;

const StyledFilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 256px);
  grid-gap: 16px;
`;

const StyledCheckbox = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  box-sizing: border-box;
  margin: 20px 0 0;
`;

interface FilterData {
  customer: SelectOption<Company> | null;
  project: SelectOption<Project> | null;
  partner: SelectOption<Company> | null;
  contractVehicleType: SelectOption<SmartContractVehicleType> | null;
  isAdditional: boolean;
  additionalContractReasons: SmartContractAdditionalReason[];
  parentContract: SelectOption<ContractDraftShort> | null;
}

const schema = yup.object().shape({
  customer: yup.object().nullable().required(),
  project: yup.object().nullable().required(),
  contractVehicleType: yup.object().nullable().required(),
  isAdditional: yup.boolean(),
  additionalContractReasons: yup.array().when("isAdditional", {
    is: true,
    then: yup.array().min(1, "Укажите причины"),
  }),
  parentContract: yup
    .object()
    .nullable()
    .when("isAdditional", {
      is: true,
      then: yup.object().nullable().required("Укажите основной договор"),
    }),
});

function Filter(props: FilterProps) {
  const { contract, onCreate } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);
  const [reasons, setReasons] = useState<SmartContractAdditionalReason[]>([]);
  // const [selectedReasons, setSelectedReasons] = useState<
  //   SmartContractAdditionalReason[]
  // >([]);
  const { onChange, values, errors, validate, setValues } = useForm<FilterData>(
    {
      values: {
        customer: null,
        project: null,
        partner: null,
        contractVehicleType: null,
        isAdditional: false,
        parentContract: null,
        additionalContractReasons: [],
      },
      schema,
    }
  );

  const isContractListEnabled = useMemo(() => {
    const { customer, partner, project, isAdditional } = values;
    return !!customer && !!partner && !!project && isAdditional;
  }, [values]);

  const getContracts = useCallback(
    (params: PageableParams<SmartContract>) => {
      const { customer, partner, project } = values;
      if (!isContractListEnabled) {
        return Promise.resolve({
          errors: null,
          message: null,
          pageNumber: 1,
          pageSize: 10,
          recordsFiltered: 0,
          recordsTotal: 0,
          succeeded: true,
          data: [],
        });
      }
      return getSmartContracts({
        ...params,
        partnerIds: partner?.value ? [partner?.value] : undefined,
        projectIds: project?.value ? [project?.value] : undefined,
        customerIds: customer?.value ? [customer?.value] : undefined,
      });
    },
    [values, isContractListEnabled]
  );

  const onClickSubmit = useCallback(async () => {
    try {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      setPending(true);

      const additionalContractReasonIds = values.additionalContractReasons.map(
        (item) => item.id
      );

      const response = await createSmartContract({
        customerId: values.customer!.value,
        projectId: values.project!.value,
        partnerId: values.partner!.value,
        contractVehicleTypeId: values.contractVehicleType!.value,
        ndsId: values.partner!.item!.nds.id,
        parentId: values.parentContract ? values.parentContract.value : null,
        additionalContractReasonIds,
      });

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      if (!!onCreate) {
        onCreate(response.data);
      }

      showNotification({
        message: "Шаблон smart-договора был успешно создан!",
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [onCreate, showNotification, validate, values]);

  const buttonDisabled = useMemo<boolean>(
    () =>
      !values.customer ||
      !values.project ||
      !values.partner ||
      !values.contractVehicleType ||
      pending,
    [values, pending]
  );

  const contractVehicleTypes = useMemo<
    SelectOption<SmartContractVehicleType>[]
  >(
    () =>
      Object.entries(SmartContractVehicleTypeName)
        .map(([key, value]) => ({
          label: value,
          value: key,
        }))
        .slice(0, 2),
    []
  );

  const nds = useMemo<NdsShort | null>(() => {
    if (
      !!values.partner &&
      !!values.partner.item &&
      !!values.partner.item.nds
    ) {
      return values.partner.item.nds;
    }

    if (!!contract && !!contract.nds) {
      return contract.nds;
    }

    return null;
  }, [contract, values.partner]);

  useEffect(() => {
    getAdditionalContractReason().then((res) => {
      setReasons(res.data);
    });

    if (!!contract) {
      setValues({
        customer: setSelectOption(contract.customer),
        project: setSelectOption(contract.project),
        partner: setSelectOption(contract.partner),
        isAdditional: !!contract.parent,
        contractVehicleType: {
          label: SmartContractVehicleTypeName[contract.contractType],
          value: contract.contractType,
        },
        parentContract: contract.parent?.name
          ? setSelectOption({
              ...contract.parent,
              name: contract.parent?.name || "",
            })
          : null,
        additionalContractReasons: contract.additionalContractReasons,
      });
    }
  }, [contract, setValues]);

  return (
    <StyledFilter>
      <StyledFilterGrid>
        <Select<Company>
          label="Заказчик"
          name="customer"
          onChange={onChange}
          value={values.customer}
          error={!!errors.customer}
          helperText={errors.customer}
          valueKey="id"
          labelKeys={companyLabelKeys}
          labelKeysSeparator={" / "}
          loadData={getCompaniesFiltered}
        />
        <Select<Project>
          label="Проект"
          name="project"
          onChange={onChange}
          value={values.project}
          error={!!errors.project}
          helperText={errors.project}
          valueKey="id"
          labelKey="name"
          loadData={getProjects}
        />
        <Select<Company>
          label="Исполнитель"
          name="partner"
          onChange={onChange}
          value={values.partner}
          error={!!errors.partner}
          helperText={errors.partner}
          valueKey="id"
          labelKeys={companyLabelKeys}
          labelKeysSeparator={" / "}
          loadData={getCompaniesFiltered}
        />
        <Select
          label="Тип техники"
          name="contractVehicleType"
          onChange={onChange}
          options={contractVehicleTypes}
          value={values.contractVehicleType}
          error={!!errors.contractVehicleType}
          helperText={errors.contractVehicleType}
        />
        <TextField
          label="Ставка НДС"
          placeholder={getDictionaryName(nds, "")}
          disabled={true}
        />
        <StyledCheckbox>
          <Checkbox
            label="Дополнительное соглашение"
            checked={values.isAdditional}
            editable={!contract}
            onChange={(e) => {
              onChange(e.currentTarget.checked, "isAdditional");
            }}
          />
        </StyledCheckbox>

        <Select<ContractDraftShort>
          label="Договор"
          name="parentContract"
          onChange={onChange}
          value={values.parentContract}
          error={!!errors.parentContract}
          helperText={errors.parentContract}
          disabled={!isContractListEnabled}
          loadData={getContracts}
          valueKey="id"
          labelKey="name"
        />
        <MultiSelect<SmartContractAdditionalReason>
          label="Причины доп.соглашения"
          onChange={(value) => {
            // setSelectedReasons(value);
            onChange(value, "additionalContractReasons");
          }}
          options={reasons}
          values={values.additionalContractReasons}
          error={!!errors.additionalContractReasons}
          helperText={errors.additionalContractReasons}
          disabled={!values.isAdditional}
          onClear={() => onChange([], "additionalContractReasons")}
        />
      </StyledFilterGrid>
      {!contract && (
        <div>
          <Button
            text="Применить"
            disabled={buttonDisabled}
            onClick={onClickSubmit}
          />
        </div>
      )}
    </StyledFilter>
  );
}

export default memo(Filter);
