import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { prop, theme } from "styled-tools";
import { IconCheckCircle32 } from "@app/icons";

interface Props {
  steps: string[];
  currentStep?: number;
  disabled?: boolean;
  disabledSteps?: number[];
  filled?: boolean;
}

const StyledStepper = styled.div<{
  columns: number;
}>`
  display: grid;
  grid-template-columns: repeat(${prop("columns")}, 1fr);
`;

const StyledStep = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 12px;

  opacity: ${(props) => (props.disabled ? 0.33 : 1)};
`;

const StyledStepNumber = styled.div<{
  active: boolean;
  filled: boolean;
}>`
  width: 40px;
  height: 40px;
  min-width: 40px;
  flex-grow: 0;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme("color.white")};
  color: ${theme("color.gray")};

  ${(props) =>
    props.filled
      ? css`
          background: linear-gradient(86.19deg, #ed4a3f 19.16%, #f99335 78.75%);
          color: ${theme("color.white")};
        `
      : props.active
      ? css`
          background: linear-gradient(86.19deg, #ed4a3f 19.16%, #f99335 78.75%);
          border: 1px solid #ed4a3f;
          position: relative;
          color: #ffffff;
        `
      : css`
          border: 1px solid ${theme("color.gray")};
        `}
`;

const StyledStepContent = styled.div`
  flex-grow: 1;
`;

const StyledStepText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  position: relative;
  color: inherit;
`;

function Stepper(props: Props) {
  const {
    steps,
    currentStep = 1,
    disabled = false,
    filled = false,
    disabledSteps = [],
  } = props;

  const stepsLength = useMemo(() => steps.length, [steps]);

  return (
    <StyledStepper columns={stepsLength}>
      {steps.map((step, stepIndex) => (
        <StyledStep
          key={stepIndex.toString()}
          disabled={disabledSteps.includes(stepIndex + 1)}
        >
          <StyledStepNumber
            filled={(filled && !disabled) || currentStep > stepIndex + 1}
            active={!disabled && stepIndex + 1 === currentStep}
          >
            {(filled && !disabled) || currentStep > stepIndex + 1 ? (
              <IconCheckCircle32 />
            ) : (
              <StyledStepText>{stepIndex + 1}</StyledStepText>
            )}
          </StyledStepNumber>
          <StyledStepContent>
            <StyledStepText>Шаг {stepIndex + 1}</StyledStepText>
            <StyledStepText>{step}</StyledStepText>
          </StyledStepContent>
        </StyledStep>
      ))}
    </StyledStepper>
  );
}

export default Stepper;
