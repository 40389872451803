import { PageableParams, PageableResponse } from "./interfaces";
import instance from "./instance";
import { Nomenclature } from "@app/models";

export function getNomenclatures(
  params: PageableParams<Nomenclature>
): Promise<PageableResponse<Nomenclature>> {
  return instance()
    .get("/nomenclature", {
      params,
    })
    .then((response) => response.data);
}
