import React, { memo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  PageActOfProvidedServices,
  PageActOfProvidedWorkReference,
  PageActOfProvidedWorks,
  PageActPrinting,
  PageApprovalHistoryPrinting,
  PageContract,
  PageDashboard,
  PageDictionaries,
  PageDictionaryCompanies,
  PageDictionaryVehicles,
  PageDocuments,
  PageLanding,
  PageMain,
  PageMonitoring,
  PageOrganizations,
  PagePrivacyPolicy,
  PagePublicOfferAgreement,
  PageRegistrationVideoPlayer,
  PageReportDetail,
  PageReports,
  PageSettings,
  PageSignIn,
  PageSignUp,
  PageSmartContracts,
  PageTasks,
  PageVehicleAccounting,
  PageVehicleAccountingDetailPrinting,
  PageVehicleAccountingPrinting,
  PageVehicleRequestPrinting,
  PageVehicleRequests,
  PageVehicles,
  PageVehiclesInProject,
  PageVideoPlayer,
} from "@app/pages";
import { AuthorizedOutlet } from "./outlets";

interface Props {}

function Router(_props: Props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLanding />} />
        <Route path="/video/:videoSrc" element={<PageVideoPlayer />} />
        <Route
          path="/video/sign-up/:videoSrc"
          element={<PageRegistrationVideoPlayer />}
        />
        <Route path="/privacy-policy" element={<PagePrivacyPolicy />} />
        <Route
          path="/public-offer-agreement"
          element={<PagePublicOfferAgreement />}
        />
        <Route path="/sign-in" element={<PageSignIn />} />
        <Route path="/sign-up" element={<PageSignUp />} />
        <Route element={<AuthorizedOutlet />}>
          <Route path="/main" element={<PageMain />} />
          <Route path="/dashboard/*" element={<PageDashboard />} />
          <Route path="/organizations" element={<PageOrganizations />} />
          <Route path="/tasks/*" element={<PageTasks />} />
          <Route
            path="/tasks/contracts/printing/:contractId"
            element={<PageApprovalHistoryPrinting />}
          />
          <Route
            path="/act-of-provided-services"
            element={<PageActOfProvidedServices />}
          />
          <Route
            path="/act-of-provided-works"
            element={<PageActOfProvidedWorks />}
          />
          <Route
            path="/act-of-provided-services/printing/:actId"
            element={<PageActPrinting />}
          />
          <Route path="/monitoring/*" element={<PageMonitoring />} />
          <Route path="/smart-contracts" element={<PageSmartContracts />} />
          <Route path="/documents/*" element={<PageDocuments />} />
          <Route path="/dictionaries" element={<PageDictionaries />} />
          <Route
            path="/dictionaries/companies"
            element={<PageDictionaryCompanies />}
          />
          <Route
            path="/dictionaries/vehicles"
            element={<PageDictionaryVehicles />}
          />
          <Route path="/reports" element={<PageReports />} />
          <Route
            path="/reports/detail"
            element={
              <PageReportDetail
                title={"Отчет по затратам на транспорт (без группировок)"}
              />
            }
          />
          <Route
            path="/reports/detail/group"
            element={
              <PageReportDetail
                title={"Отчет по затратам на транспорт (группированный)"}
                isGroup={true}
              />
            }
          />
          <Route path="/vehicles" element={<PageVehicles />} />
          <Route path="/settings" element={<PageSettings />} />
          <Route
            path="/vehicle-accounting/*"
            element={<PageVehicleAccounting />}
          />
          <Route
            path="/app/vehicle-accounting/printing"
            element={<PageVehicleAccountingPrinting />}
          />
          <Route
            path="/app/vehicle-accounting/detail/printing"
            element={<PageVehicleAccountingDetailPrinting />}
          />

          <Route
            path="/vehicles-in-project/printing/:requestId"
            element={<PageVehicleRequestPrinting />}
          />
          <Route path="/vehicle-requests/*" element={<PageVehicleRequests />} />
          <Route
            path="/vehicles-in-project/*"
            element={<PageVehiclesInProject />}
          />
        </Route>
        {/* Получение квитанции, в том числе для неавторизованных */}
        <Route path="/contract/reference/:fileId" element={<PageContract />} />
        <Route
          path="/avr/reference/:fileId"
          element={<PageActOfProvidedWorkReference />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default memo(Router);
