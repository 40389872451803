import React, { memo } from "react";
import styled from "styled-components";
import { IconDocumentBlue40, IconEye24 } from "@app/icons";
import { FileObject } from "@app/models";
import { theme } from "styled-tools";
import { Loader } from "@app/components";

type Props = {
  file?: FileObject | null;
  loading?: boolean;
  onPreview?: (file: FileObject) => void;
};

const StyledDocFile = styled.div<{ hoverable: boolean }>`
  //width: 303px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 4px 8px;
  cursor: ${(props) => (props.hoverable ? "pointer" : "default")};

  &:hover {
    box-shadow: ${(props) =>
      props.hoverable ? "2px 0 4px rgba(0, 0, 0, 0.16)" : "none"};
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: #1b1b1b;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  transition: all 120ms ease-in-out;
`;

const PreviewButton = styled.button`
  height: 40px;
  width: 40px;
  padding: 8px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${theme("color.grayLight")};
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.08);
  }
`;

const StyledTitle = styled.p`
  color: ${theme("color.dark")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const DocFile = (props: Props) => {
  const { file, loading, onPreview } = props;

  const onPreviewClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onPreview && file) {
      onPreview(file);
    }
  };

  if (!file) return <></>;

  return (
    <StyledDocFile hoverable={!!file.url}>
      <StyledAnchor href={file.url || undefined} download title="Cкачать">
        <IconDocumentBlue40 />
        <StyledTitle>{file.name}</StyledTitle>
      </StyledAnchor>
      {!!onPreview && file.url && (
        <PreviewButton onClick={onPreviewClick}>
          {loading ? <Loader size={"small"} /> : <IconEye24 />}
        </PreviewButton>
      )}
    </StyledDocFile>
  );
};

export default memo(DocFile);
