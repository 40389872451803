import React, { memo } from "react";
import { Modal, ModalProps } from "@app/components";
import { ContractorDocuments } from "@app/common";
import { SmartContract } from "@app/models";

interface Props extends ModalProps {
  contractData: SmartContract | null;
}

const ModalContractorFiles = (props: Props) => {
  const { contractData, ...restProps } = props;

  return (
    <Modal {...restProps}>
      <ContractorDocuments contractData={contractData} />
    </Modal>
  );
};

export default memo(ModalContractorFiles);
