import { APIResponse, PageableParams, PageableResponse } from "./interfaces";
import instance from "./instance";
import { NDS } from "@app/models";

export function getNds(
  params: PageableParams<NDS>
): Promise<PageableResponse<NDS>> {
  return instance()
    .get("/nds", {
      params,
    })
    .then((response) => response.data);
}

export function getNdsByCompany(companyId: string): Promise<APIResponse<NDS>> {
  return instance()
    .get(`/nds/getByCompanyId/${companyId}`)
    .then((response) => response.data);
}
