export function getDictionaryName<
  T extends {
    name: string;
  }
>(item: T | null, fallbackText = "", keys?: (keyof T)[]): string {
  return !!item
    ? !!keys
      ? keys.reduce(
          (acc, currentValue) => `${acc} ${item[currentValue]}`.trim(),
          ""
        )
      : item.name
    : fallbackText;
}

export function getDictionaryId<
  T extends {
    id: string;
  }
>(item: T | null): string | null {
  return !!item ? item.id : null;
}

export const joinValues = <T extends { name: string }>(
  values: (T | string | null)[],
  delimiter = " - "
) => {
  return values
    .filter(Boolean)
    .map((item) => (typeof item === "string" ? item : getDictionaryName(item)))
    .join(delimiter);
};
