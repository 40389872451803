import React, { memo, useMemo } from "react";
import { Modal, ModalProps } from "@app/components";
import { VehicleRequest, VehicleRequestOffer } from "@app/models";
import { VehicleRequestDetail } from "@app/common";
import { FormVehicleRequestOfferEdit } from "@app/forms";

interface Props extends Omit<ModalProps, "title"> {
  request: VehicleRequest;
  offer: VehicleRequestOffer;
  hideInfo?: boolean;
  onSuccess: () => void;
}

function ModalVehicleRequestOfferEdit(props: Props) {
  const { request, offer, hideInfo = false, onSuccess, ...modalProps } = props;

  const modalTitle = useMemo(() => `Редактирование предложения на технику`, []);

  const actions = useMemo(() => [], []);

  if (!modalProps.open) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      title={modalTitle}
      actions={actions}
      dense={true}
      size="medium"
    >
      {!hideInfo && (
        <VehicleRequestDetail
          request={request}
          variant="secondary"
          columns={3}
        />
      )}
      <FormVehicleRequestOfferEdit
        offer={offer}
        request={request}
        onSuccess={onSuccess}
      />
    </Modal>
  );
}

export default memo(ModalVehicleRequestOfferEdit);
