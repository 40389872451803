import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { IconLogo } from "@app/icons";
import { theme } from "styled-tools";
import { isDispatcher, isPartner } from "@app/helpers";
import { useUser } from "@app/providers";
import { useNavigate } from "react-router-dom";

interface Props {
  variant?: "light" | "dark";
}

const StyledLogo = styled.div<Pick<Props, "variant">>`
  padding: 8px;
  box-sizing: border-box;
  text-align: center;
  color: ${(props) =>
    props.variant === "dark" ? theme("color.dark") : theme("color.white")};
  cursor: pointer;
`;

const StyledText = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin: 0;
  width: 100%;
  text-align: right;
  color: ${theme("color.gray")};
`;

function Logo(props: Props) {
  const { variant = "dark" } = props;
  const { user, asPartner } = useUser();
  const navigate = useNavigate();

  const text = useMemo(() => {
    if (isDispatcher(user!.role)) {
      return "Диспетчер";
    }

    if (isPartner(user!.role) && asPartner) {
      return "Партнер";
    }

    return "Девелопер";
  }, [asPartner, user]);

  const onLogoClick = useCallback(() => {
    navigate("/main");
  }, [navigate]);

  return (
    <StyledLogo variant={variant} onClick={onLogoClick}>
      <IconLogo />
      <StyledText>{text}</StyledText>
    </StyledLogo>
  );
}

export default Logo;
