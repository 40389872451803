import React, { memo, useCallback, useMemo } from "react";
import {
  AdvancedPaymentTypeNames,
  Approver,
  SmartContract,
  UserTaskStatusesName,
  UserTaskTypeName,
} from "@app/models";
import { Grid, Loader, Table } from "@app/components";
import { dateFormat, getFullName } from "@app/helpers";
import "../styles.scss";
import styled from "styled-components";
import { ApproveListView } from "../../ContractDocDetail/components";
import { ContractTranscriptView } from "@app/common";

interface Props {
  contractData: SmartContract | null;
  loading?: boolean;
}

interface BoxListItemProps {
  label: string;
  text: string | number;
}

const BoxListItem = (props: BoxListItemProps) => {
  return (
    <div>
      <p className="m-document-detail__labels">{props.label}:</p>
      <p className="m-document-detail__text">{props.text}</p>
    </div>
  );
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 32px;
`;

const ContractDetail = (props: Props) => {
  const { contractData, loading } = props;

  const tableLabels = useMemo(
    () => [
      "Вид задачи",
      "ФИО ответственного",
      "Результат",
      "Срок",
      "Комментарий",
    ],
    []
  );

  const mapTableData = useCallback((item: Approver) => {
    return [
      item.taskTypeId ? UserTaskTypeName[item.taskTypeId] : "-",
      getFullName(item.user),
      item.status ? UserTaskStatusesName[item.status] : "-",
      item.date ? dateFormat(item.date, "dd.MM.yyyy") : "-",
      item.comment,
    ];
  }, []);

  const tableData = useMemo(() => {
    const sortedApprovers = (contractData?.approvers || []).sort(
      (a, b) => a.ord - b.ord
    );
    return sortedApprovers.map(mapTableData);
  }, [contractData?.approvers, mapTableData]);

  if (loading) {
    return (
      <StyledContainer>
        <Loader />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <StyledGrid>
        {contractData && (
          <Grid>
            <BoxListItem
              label={"Дата начала"}
              text={dateFormat(contractData.startDate, "dd.MM.yyyy HH:mm")}
            />
            <BoxListItem
              label={"Дата окончания"}
              text={dateFormat(contractData.endDate, "dd.MM.yyyy HH:mm")}
            />
            {/* Попросили убрать 13/02/24 */}
            {/*<BoxListItem
              label={"Сумма договора"}
              text={currencyFormat(contractData.amount || "")}
            />*/}
            <BoxListItem
              label={"Рег. номер"}
              text={contractData.number || " - "}
            />
            <BoxListItem
              label={"Дата договора"}
              text={dateFormat(contractData.contractDate, "dd.MM.yyyy HH:mm")}
            />
            <BoxListItem
              label={"Условия оплаты"}
              text={
                contractData.advancePaymentType
                  ? AdvancedPaymentTypeNames[contractData.advancePaymentType]
                  : " - "
              }
            />
          </Grid>
        )}
        {contractData && (
          <ContractTranscriptView
            files={
              contractData.files?.length
                ? contractData.files
                : [contractData.file]
            }
            pdfFiles={
              contractData.pdfFiles?.length
                ? contractData.pdfFiles
                : [contractData.pdf]
            }
            signedPdfFiles={
              contractData.signedPdfFiles?.length
                ? contractData.signedPdfFiles
                : [contractData.signedPdf]
            }
          />
        )}
      </StyledGrid>
      {/*<StyledTitle>*/}
      {/*</StyledTitle>*/}

      <Grid>
        <h6>Лист согласования</h6>
        <Table
          labels={tableLabels}
          data={tableData}
          disableOverflow={true}
          emptyTitle={"Добавьте подписантов"}
        />
      </Grid>
      <ApproveListView histories={contractData?.histories} />
    </StyledContainer>
  );
};

export default memo(ContractDetail);
