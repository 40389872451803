import { CompanyStatus, CompanyStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { IconInfo16 } from "@app/icons";

interface Props {
  status: CompanyStatus;
  comment?: string;
}

const TooltipContainer = styled.div`
  display: none;
  position: absolute;
  width: 150px;
  font-size: 14px;
  min-height: 20px;
  background-color: ${theme("color.dark")};
  color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 999;
  box-sizing: border-box;
  margin-top: 2px;

  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  word-wrap: break-word;
  word-break: break-word;
  white-space: initial;

  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

const InfoIcon = styled.div`
  display: flex;
  height: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${theme("color.dark")};
  cursor: pointer;
  position: relative;
  margin-left: 12px;

  &:hover ${TooltipContainer} {
    display: flex;
  }
`;

const getStatusVariant = (status: CompanyStatus): ChipColor => {
  switch (status) {
    case CompanyStatus.Created:
      return "default";
    case CompanyStatus.AwaitingConfirmation:
      return "warning";
    case CompanyStatus.Confirmed:
      return "success";
    case CompanyStatus.Rejected:
      return "danger";
    default:
      return "default";
  }
};

function CompanyStatusChip(props: Props) {
  const { status, comment } = props;

  return (
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      <Chip text={CompanyStatusName[status]} color={getStatusVariant(status)} />
      {status === CompanyStatus.Rejected && comment && (
        <InfoIcon>
          <IconInfo16 />
          <TooltipContainer>{comment}</TooltipContainer>
        </InfoIcon>
      )}
    </div>
  );
}

export default memo(CompanyStatusChip);
