import styled from "styled-components";
import { memo, PropsWithChildren } from "react";
import { theme } from "styled-tools";

interface Props extends PropsWithChildren {
  shadow?: boolean;
  bordered?: boolean;
  dense?: boolean;
}

const Paper = styled.div<Props>`
  padding: ${(props) => (!!props.dense ? "0" : "16px")};
  overflow: hidden;
  box-sizing: border-box;
  background: ${theme("color.white")};
  border-radius: 4px;
  ${(props) => !!props.shadow && `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);`}
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.bordered ? theme("color.grayLight") : theme("color.white")};
`;

export default memo(Paper);
