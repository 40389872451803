import { PageableParams, PageableResponse } from "./interfaces";
import { Project } from "@app/models";
import instance from "./instance";

export function getProjects(
  params: PageableParams<Project>
): Promise<PageableResponse<Project>> {
  return instance()
    .get("/project", {
      params,
    })
    .then((response) => response.data);
}

export function getMyProjects(
  params: PageableParams<Project>
): Promise<PageableResponse<Project>> {
  return instance()
    .get("/project/by-user", {
      params,
    })
    .then((response) => response.data);
}
