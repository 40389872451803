import React, { memo } from "react";
import styled, { css } from "styled-components";
import { ifProp, switchProp, theme } from "styled-tools";

type InfoSize = "default" | "small" | "xsmall";

interface Props {
  label: string;
  value: string;
  variant?: "default" | "secondary";
  reversed?: boolean;
  centered?: boolean;
  size?: InfoSize;
  ellipsis?: boolean;
  color?: "dark" | "light";
}

const StyledInfo = styled.div<Pick<Props, "variant" | "centered">>`
  position: relative;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: ${(props) => (props.variant === "default" ? "16px" : "0")};
  grid-template-columns: ${switchProp("variant", {
    default: "1fr 1fr",
    secondary: "1fr",
  })};
  text-align: ${(props) => (props.centered ? "center" : "left")};
`;

const LabelStyled = styled.p<Pick<Props, "size" | "color">>`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 400;
  margin: 0;
  color: ${ifProp({ color: "dark" }, "#ACACAC", "#D8D8D8")};

  ${switchProp("size", {
    xsmall: css`
      line-height: 12px;
      font-size: 10px;
    `,
    small: css`
      line-height: 20px;
      font-size: 14px;
    `,
    default: css`
      line-height: 24px;
      font-size: 16px;
    `,
  })}
`;

const ValueStyled = styled.p<
  Pick<Props, "ellipsis" | "color" | "reversed" | "size">
>`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  position: relative;
  color: ${ifProp({ color: "dark" }, "#1C2246", "#fff")};

  order: ${(props) => (props.reversed ? -1 : 1)};

  ${switchProp("size", {
    xsmall: css`
      font-size: 12px;
      line-height: 16px;
    `,
    small: css`
      font-size: 14px;
      line-height: 20px;
    `,
    default: css`
      font-size: 16px;
      line-height: 24px;
    `,
  })}

  ${switchProp("ellipsis", {
    true: css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `,
    false: css``,
  })}

  &:hover +  div {
    display: block;
  }
`;

const ValueInfo = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: ${theme("color.white")};
  line-height: 18px;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  display: none;
  padding: 8px;
  z-index: 1;
`;

function Info(props: Props) {
  const {
    label,
    value,
    variant = "default",
    size = "default",
    ellipsis = false,
    reversed = false,
    centered = false,
    color = "dark",
  } = props;

  return (
    <StyledInfo variant={variant} centered={centered}>
      <LabelStyled size={size} color={color}>
        {label}
      </LabelStyled>
      <ValueStyled
        size={size}
        ellipsis={ellipsis}
        color={color}
        reversed={reversed}
      >
        {value}
      </ValueStyled>
      {ellipsis && <ValueInfo>{value}</ValueInfo>}
    </StyledInfo>
  );
}

export default memo(Info);
