import React, { memo } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import content from "./content.json";
import styled, { css } from "styled-components";
import { Grid } from "@app/components";
import { switchProp, theme } from "styled-tools";
import BGSrc from "./bg.png";
import BGSrc2 from "./bg2.png";
import ImageChart from "./graphic-chart.png";

interface Props {
  variant?: "default" | "secondary";
}

const StyledPanelEconomy = styled(StyledPanel)<Pick<Props, "variant">>`
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 182px;

  @media only screen and (max-width: 576px) {
    background: none;
  }

  ${switchProp("variant", {
    default: css`
      grid-column-start: 1;
      grid-column-end: 5;
      background-image: url(${BGSrc});
    `,
    secondary: css`
      grid-column-start: 4;
      grid-column-end: 7;
      background-image: url(${BGSrc2});
    `,
  })}
`;

const StyledPanelGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
`;

const StyledChart = styled.div`
  border-width: 4px;
  border-radius: 50%;
  width: 68px;
  height: 68px;
  position: relative;
  background: linear-gradient(
    86.19deg,
    ${theme("color.orange")} 19.16%,
    ${theme("color.orangeLight")} 78.75%
  );

  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: transparent;
    border-left-color: ${theme("color.grayLight")};
    content: "";
    box-sizing: border-box;
    rotate: 150deg;
  }

  > span {
    position: relative;
    z-index: 1;
    background: ${theme("color.white")};
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${theme("color.dark")};
    text-align: center;
    margin: 6px;
  }
`;

const StyledText = styled(StyledPanelText)`
  span {
    color: ${theme("color.orange")};
    margin-left: 5px;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    display: flex;

    &::before {
      background-color: #69748c;
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin-top: 10px;
      margin-right: 8px;
    }
  }
`;

function PanelEconomy(props: Props) {
  const { variant = "default" } = props;

  return (
    <StyledPanelEconomy variant={variant}>
      <Grid gap={18}>
        <StyledPanelTitle>{content.title}</StyledPanelTitle>
        {variant === "default" && (
          <StyledPanelGrid>
            <StyledChart>
              <span>30%</span>
            </StyledChart>
            <StyledText>
              {content.text}
              <span>30%</span>
            </StyledText>
          </StyledPanelGrid>
        )}
        <StyledList>
          {(variant === "default" ? content.list : content.list2).map(
            (item, itemIndex) => (
              <li key={itemIndex.toString()}>
                <StyledPanelText>{item}</StyledPanelText>
              </li>
            )
          )}
        </StyledList>
        <StyledPanelImageMobile src={ImageChart} width={183} alt="" />
      </Grid>
    </StyledPanelEconomy>
  );
}

export default memo(PanelEconomy);
