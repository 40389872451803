import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CompanyFile,
  FileObject,
  FileSimple,
  SmartContract,
  SmartContractVehicle,
  VehicleFile,
} from "@app/models";
import { ChipRounded, Grid, Loader, Table } from "@app/components";
import styled from "styled-components";
import {
  IconDocumentBlue24,
  IconDocumentBlue40,
  IconEye24,
  IconPaperClip24,
} from "@app/icons";
import {
  APIResponse,
  getCompanyDocuments,
  getFileById,
  getVehicleDetail,
} from "@app/api";
import { ifProp, theme } from "styled-tools";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";
import { PopupMenu, PreviewModal } from "@app/common";
import { downloadFile } from "../../helpers/downloadFile";

interface Props {
  contractData: SmartContract | null;
  loading?: boolean;
}

interface VehiclePopupProps {
  files: VehicleFile[];
  fileLoading: boolean;
  onItemClick: () => void;
  onPreviewClick: (
    fileId: string,
    fileName?: string,
    download?: boolean
  ) => void;
  onDownloadAll: () => void;
}

const StyledContainer = styled.div`
  padding: 16px 32px;
`;

const ChipRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
`;

const FileRow = styled.div<{ maxWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;

  max-width: ${ifProp("maxWidth", "750px", "initial")};

  p {
    flex: 1;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background: ${theme("color.blueLight")};
  }
`;

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  text-decoration: none;
  border-radius: 100px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  background: linear-gradient(69deg, #ed4a3f 0%, #f99335 100%);
  &:disabled {
    background: ${theme("color.grayLight")};
  }
`;

const PreviewButton = styled.button`
  height: 40px;
  width: 40px;
  padding: 8px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${theme("color.grayLight")};
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.08);
  }
`;

const StyledDownloadAll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  //margin-bottom: 16px;
  padding: 16px;
`;

const tabs = ["Список техники", "Документы организации"];

function FilesPopup(props: VehiclePopupProps) {
  const { files, fileLoading, onItemClick, onPreviewClick, onDownloadAll } =
    props;

  const download = useCallback(
    (file: FileSimple) => () => onPreviewClick(file.id, file.name || "", true),
    [onPreviewClick]
  );

  return (
    <PopupMenu icon={IconPaperClip24} onClick={onItemClick}>
      {files.length === 0 && (
        <FileRow>{fileLoading ? "Загружается..." : "Нет файлов"}</FileRow>
      )}
      <StyledDownloadAll>
        {!!files?.length && (
          <DownloadButton onClick={onDownloadAll}>Скачать все</DownloadButton>
        )}
      </StyledDownloadAll>
      {files.map((item, index) => (
        <FileRow
          key={index.toString()}
          title={item.file.name || ""}
          maxWidth={true}
        >
          <IconDocumentBlue24 />
          <p>{item.file.name}</p>
          <PreviewButton onClick={() => onPreviewClick(item.file.id)}>
            {fileLoading ? <Loader size={"small"} /> : <IconEye24 />}
          </PreviewButton>
          <DownloadButton disabled={fileLoading} onClick={download(item.file)}>
            Скачать
          </DownloadButton>
        </FileRow>
      ))}
    </PopupMenu>
  );
}

const ContractorDocuments = (props: Props) => {
  const { contractData, loading } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [vehicleId, setVehicleId] = useState<string>();
  const [vehicleFiles, setVehicleFiles] = useState<VehicleFile[]>([]);
  const [fileLoading, setFileLoading] = useState(false);
  // документы организации
  const [orgFiles, setOrgFiles] = useState<CompanyFile[]>();
  const [currentFile, setCurrentFile] = useState<Blob | null>(null);

  const { showNotification } = useNotification();

  const showDocument = useCallback(
    async (fileId: string, fileName?: string, download = false) => {
      try {
        setFileLoading(true);
        const res = await getFileById(fileId);
        if (download) {
          const url = window.URL.createObjectURL(res);
          downloadFile(url, fileName || `Документ ${contractData?.name}`);
        } else {
          setCurrentFile(res);
        }
        setFileLoading(false);
      } catch (e) {
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
        setFileLoading(false);
      }
    },
    [contractData?.name, showNotification]
  );

  const onChipClick = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  useEffect(() => {
    setVehicleFiles([]);
    if (vehicleId) {
      setFileLoading(true);
      getVehicleDetail(vehicleId)
        .then(({ data }) => {
          if (data.files) {
            setVehicleFiles(data.files);
            setFileLoading(false);
          }
        })
        .catch((e) => {
          setFileLoading(false);
          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });
        });
    }
  }, [showNotification, vehicleId]);

  useEffect(() => {
    if (activeIndex === 1 && !orgFiles && contractData?.partner.id) {
      getCompanyDocuments(contractData?.partner.id).then(({ data }) => {
        setOrgFiles(data);
      });
    }
  }, [activeIndex, contractData?.partner.id, orgFiles]);

  const onFileDownload = useCallback(
    (file: FileObject) => () => showDocument(file.id, file?.name || "", true),
    [showDocument]
  );

  const onFileShow = useCallback(
    (file: FileObject) => () => showDocument(file.id),
    [showDocument]
  );

  const downloadFiles = useCallback(
    async (files: FileObject[] | undefined) => {
      if (files?.length) {
        const downloads = files.map((item) => {
          return showDocument(item.id, item?.name || "", true);
        });
        try {
          setFileLoading(true);
          await Promise.all(downloads);
          showNotification({
            message: "Все файлы скачаны",
            variant: "success",
          });
          setFileLoading(false);
        } catch (e) {
          setFileLoading(false);
          showNotification({
            message: "Не удалось скачать все файлы",
            variant: "error",
          });
        }
      }
    },
    [showDocument, showNotification]
  );

  const downloadOrgFiles = useCallback(async () => {
    await downloadFiles(orgFiles?.map(({ file }) => file));
  }, [downloadFiles, orgFiles]);

  const downloadVehicleFiles = useCallback(async () => {
    await downloadFiles(
      vehicleFiles?.map(({ file }) => ({
        id: file.id,
        name: file.name,
        url: file.path,
      }))
    );
  }, [downloadFiles, vehicleFiles]);

  const tableLabels = useMemo(() => ["Техника", "Гос номер", ""], []);
  const mapTableData = useCallback(
    (item: SmartContractVehicle) => {
      return [
        <p>{item.vehicleType?.name}</p>,
        item.govNumber,
        <div style={{ textAlign: "right", position: "relative" }}>
          <FilesPopup
            files={vehicleFiles}
            fileLoading={fileLoading}
            onItemClick={() => setVehicleId(item.vehicleId)}
            onPreviewClick={showDocument}
            onDownloadAll={downloadVehicleFiles}
          />
        </div>,
      ];
    },
    [downloadVehicleFiles, fileLoading, showDocument, vehicleFiles]
  );

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => (contractData?.vehicles || []).map(mapTableData),
    [contractData?.vehicles, mapTableData]
  );

  if (loading) {
    return (
      <StyledContainer>
        <Loader />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <ChipRow>
        {tabs.map((tab, index) => (
          <ChipRounded
            key={index.toString()}
            text={tab}
            active={activeIndex === index}
            onClick={() => onChipClick(index)}
          />
        ))}
      </ChipRow>
      {contractData && (
        <Grid>
          {activeIndex === 0 && (
            <Table
              labels={tableLabels}
              onClick={() => {}}
              data={tableData}
              disableOverflow={true}
              emptyTitle={"Список пуст"}
            />
          )}
          {activeIndex === 1 && (
            <div>
              {orgFiles?.length === 0 && (
                <p>{fileLoading ? "Загружается..." : "Нет файлов"}</p>
              )}
              <StyledDownloadAll>
                {!!orgFiles?.length && (
                  <DownloadButton onClick={downloadOrgFiles}>
                    Скачать все
                  </DownloadButton>
                )}
              </StyledDownloadAll>
              {(orgFiles || []).map((item: any, index) => (
                <FileRow key={index.toString()}>
                  <IconDocumentBlue40 />
                  <p>{item.file.name}</p>
                  {/pdf|png|jpeg|jpg/gi.test(item.file.url || "") && (
                    <PreviewButton onClick={onFileShow(item.file)}>
                      {fileLoading ? <Loader size={"small"} /> : <IconEye24 />}
                    </PreviewButton>
                  )}
                  <DownloadButton onClick={onFileDownload(item.file)}>
                    Скачать
                  </DownloadButton>
                </FileRow>
              ))}
            </div>
          )}
        </Grid>
      )}
      <PreviewModal
        open={!!currentFile}
        file={currentFile}
        onClose={() => setCurrentFile(null)}
      />
    </StyledContainer>
  );
};

export default memo(ContractorDocuments);
