import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { SVGIconType } from "@app/icons";
import { Paper } from "@app/components";

interface Props extends PropsWithChildren {
  icon: SVGIconType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const PopupMenuWrapper = styled.div`
  position: relative;
`;

const PopupButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #eee;
    border: 1px solid #d8d8d8;
  }
`;

const Menu = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  z-index: 1;

  > div {
    padding: 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
  }
`;

const MenuTransition = styled.div<{ isHidden: boolean }>`
  visibility: ${(props) => (props.isHidden ? "hidden" : "visible")};
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  transition: all 0.2s linear;
`;

function PopupMenu(props: Props) {
  const { icon: Icon, children } = props;
  const [paperVisible, setPaperVisible] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setPaperVisible((prev) => !prev);
      if (!!props.onClick) {
        props.onClick(event);
      }
    },
    [props]
  );

  const hidePopupMenu = useCallback(() => {
    setPaperVisible(false);
  }, []);

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target as HTMLElement)
    ) {
      setPaperVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <PopupMenuWrapper ref={wrapperRef}>
      <PopupButton onClick={onClick}>
        <Icon />
      </PopupButton>
      <MenuTransition isHidden={!paperVisible}>
        {paperVisible && (
          <Menu>
            <Paper shadow={true}>{children}</Paper>
          </Menu>
        )}
      </MenuTransition>
    </PopupMenuWrapper>
  );
}

export default memo(PopupMenu);
