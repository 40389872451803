import {
  Button,
  Grid,
  Loader,
  Modal,
  ModalProps,
  Select,
  SelectOption,
  Switcher,
  TextField,
  useForm,
} from "@app/components";
import React, { useCallback, useEffect, useState } from "react";
import { getBanks } from "@app/api";
import { useNotification } from "@app/providers";
import * as yup from "yup";

interface Props extends Pick<ModalProps, "open" | "onClose" | "title"> {
  onSubmit: (bankData: any) => void;
}

const defaultParams = {
  pageNumber: 1,
  pageSize: 10000,
  searchText: "",
};

const schema = yup.object().shape({
  bank: yup.object().nullable().required("Поле обязательно к заполнению"),
  accountNumber: yup
    .string()
    .nullable()
    .required("Поле обязательно к заполнению"),
});

function ModalOrganizationBankAccount(props: Props) {
  const { open, onClose, onSubmit, ...modalProps } = props;
  const { showNotification } = useNotification();
  const [banks, setBanks] = useState<SelectOption<any>[]>([]);
  const [isMain, setIsMain] = useState<boolean>(false);
  const { pending, onChange, values, errors, validate, setPending, resetForm } =
    useForm<any>({
      values: {
        bank: null,
        accountNumber: "",
        isMain: false,
      },
      schema,
    });

  const onChangeSwitch = useCallback(() => {
    setIsMain((prevState) => !prevState);
  }, []);

  const onClickSubmit = useCallback(async () => {
    const isValid = await validate();
    if (!isValid) {
      showNotification({
        message: "Пожалуйста, заполните все обязательные поля.",
        variant: "error",
      });
      return;
    }

    const bankData = {
      bank: values.bank,
      accountNumber: values.accountNumber,
      isMain: isMain,
    };
    onSubmit(bankData);
    onClose();
  }, [validate, values, isMain, onSubmit, onClose, showNotification]);

  const getActions = useCallback(
    () => [
      <Button
        text="Добавить"
        onClick={onClickSubmit}
        disabled={pending}
        showLoader={pending}
      />,
    ],
    [onClickSubmit, pending]
  );

  const loadBanks = useCallback(
    async (params: any) => {
      try {
        const response = await getBanks(params);
        const formattedData = response.data.map((region: any) => {
          return {
            value: region.id,
            label: region.name,
            item: region,
          };
        });

        setBanks(formattedData);
        setPending(false);
      } catch (error) {
        console.error("Ошибка при загрузке списка:  ", error);
        setPending(false);
      }
    },
    [setPending]
  );

  useEffect(() => {
    (async () => {
      await loadBanks(defaultParams);
    })();
  }, [loadBanks]);

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Modal {...modalProps} open={open} onClose={onClose} actions={getActions()}>
      {pending && <Loader />}
      {!pending && (
        <Grid gap={16}>
          <Select<any>
            label="Банк"
            name="bank"
            onChange={onChange}
            value={values.bank}
            error={!!errors.bank}
            helperText={errors.bank}
            placeholder={"Выберите из списка"}
            valueKey="id"
            labelKey="name"
            options={banks}
            onClear={() => onChange(null, "bank")}
          />
          <TextField
            label="ИИК"
            placeholder="Введите ИИК"
            name="accountNumber"
            onChange={onChange}
            value={values.accountNumber}
            error={!!errors.accountNumber}
            helperText={errors.accountNumber}
          />
          <Switcher
            label="Основной счет"
            isOn={isMain}
            reverse={true}
            onChange={onChangeSwitch}
          />
        </Grid>
      )}
    </Modal>
  );
}

export default ModalOrganizationBankAccount;
