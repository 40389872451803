import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Info, TextField, Tooltip } from "@app/components";
import "./styles.scss";
import {
  currencyFormat,
  dateFormat,
  HOURS_UNIT_ID,
  KM_UNIT_ID,
  M2_UNIT_ID,
  M3_UNIT_ID,
  M_UNIT_ID,
  MONTH_UNIT_ID,
  NIGHT_SHIFT_ID,
  PIECE_UNIT_ID,
  POG_M_UNIT_ID,
  RACE_UNIT_ID,
  SHIFT_UNIT_ID,
  TN_KM_UNIT_ID,
  TN_UNIT_ID,
} from "@app/helpers";
import { IconDay24, IconNight24 } from "@app/icons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  @page {
    size: A4 landscape;
  }

  @media print {
    padding: 0;
    button {
      display: none;
    }
  }
`;

const PrintButton = styled.button`
  border: none;
  background: lightgray;
  outline: none;
  cursor: pointer;
  width: 930px;
  align-self: center;
`;

function PageVehicleAccountingPrinting() {
  const [vehicleData, setVehicleData] = useState<any>(null);
  const [headerInfo, setHeaderInfo] = useState<any>(null);

  useEffect(() => {
    const data = localStorage.getItem("vehicleData");
    const header = localStorage.getItem("headerInfo");

    if (data && header) {
      setVehicleData(JSON.parse(data));
      setHeaderInfo(JSON.parse(header));
    } else {
      console.error("Missing data in localStorage");
    }
  }, []);

  if (!vehicleData || !headerInfo) {
    return <div>Loading...</div>;
  }

  const isNightShift = vehicleData.shiftType?.id === NIGHT_SHIFT_ID;
  const getPriceWithNDS = (price: number | string | null) => {
    if (!price) {
      return "";
    }
    let _price = currencyFormat(price);
    if (vehicleData.ndsShortDto?.isNds) {
      _price += " С НДС ";
    }

    _price += ` ${vehicleData.ndsShortDto?.name}`;

    return _price;
  };

  const getTotal = () => {
    if (!vehicleData) {
      return 0;
    }
    const { ndsShortDto, priceWithNds, priceWithOutNds, unitShort } =
      vehicleData;
    const price = (ndsShortDto?.isNds ? priceWithNds : priceWithOutNds) || 0;
    let amount = +(vehicleData.workPerformed || 0);
    if (unitShort.id === MONTH_UNIT_ID) {
      amount = 1;
    } else if (unitShort.id === HOURS_UNIT_ID) {
      const timeParts = vehicleData.workPerformed?.split(":");
      if (timeParts && timeParts.length === 2) {
        const timeAmount = +timeParts[0] + +timeParts[1] / 60;
        amount = Math.round((timeAmount + Number.EPSILON) * 100) / 100;
      }
    }
    return getPriceWithNDS(price * amount);
  };

  const getShiftIcon = () => {
    let tooltipText = isNightShift ? "Ночная смена" : "Дневная смена";

    if (vehicleData.workShift) {
      const { name } = vehicleData.workShift;
      tooltipText = tooltipText.concat(" (", name, ")");
    }

    return (
      <Tooltip content={tooltipText}>
        {isNightShift ? <IconNight24 /> : <IconDay24 />}
      </Tooltip>
    );
  };

  const renderWorkPerformedInput = () => {
    const attrs = {
      label: "Фактическое время работы",
      placeholder: "Укажите количество часов",
      name: "workPerformed",
      value: vehicleData.workPerformed,
      disabled: true,
    };

    switch (vehicleData.unitShort?.id) {
      case MONTH_UNIT_ID:
        return null;
      case RACE_UNIT_ID:
        return (
          <TextField
            {...attrs}
            label="Количество рейсов"
            placeholder="Укажите количество рейсов"
            type={"number"}
          />
        );
      case TN_KM_UNIT_ID:
        return (
          <div className={"flex-row"}>
            <TextField
              {...attrs}
              name="cargoWeight"
              label="Вес груза, т"
              placeholder="Укажите значение"
              type={"number"}
              value={vehicleData.cargoWeight}
            />
            <TextField
              {...attrs}
              name="distance"
              label="Расстояние, км"
              placeholder="Укажите значение"
              type={"number"}
              value={vehicleData.distance}
            />
          </div>
        );
      case SHIFT_UNIT_ID:
        return (
          <TextField
            {...attrs}
            label="Коэффициент смены"
            placeholder="Укажите от 0.01 до 1"
            value={vehicleData.workPerformed}
          />
        );
      case HOURS_UNIT_ID:
        return (
          <TextField
            {...attrs}
            placeholder="00:00"
            value={vehicleData.workPerformed}
          />
        );
      case POG_M_UNIT_ID:
      case PIECE_UNIT_ID:
      case TN_UNIT_ID:
      case KM_UNIT_ID:
      case M3_UNIT_ID:
      case M2_UNIT_ID:
      case M_UNIT_ID:
        return (
          <TextField
            {...attrs}
            label="Укажите количество"
            placeholder="0.00"
            type={"number"}
          />
        );
      default:
        return <TextField {...attrs} />;
    }
  };

  return (
    <Container>
      <h2 className="working-card__title">Журнал учета техники</h2>
      <div className="working-card">
        <div className="working-card__header-printing">
          <span className="working-card__gov-num">
            {vehicleData.vehicle.govNumber}
          </span>
          <div className="working-card__shift">{getShiftIcon()}</div>
          <span>
            {[
              dateFormat(vehicleData.workDate, "dd.MM.yyyy"),
              vehicleData.vehicleType?.name,
              vehicleData.vehicleCharacteristic?.name,
              vehicleData.serviceType?.name,
              vehicleData.unitShort?.name,
              vehicleData.workPerformed,
            ]
              .filter(Boolean)
              .join(" · ")}
          </span>
        </div>
        <div className="working-card__cols">
          <div className="working-card__col">
            <p className="working-card__info">
              <span className="working-card__info--label">Контрагент</span>
              <span className="working-card__info--title">
                {vehicleData.partner.name}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Цена заказчика</span>
              <span className="working-card__info--title">
                {getPriceWithNDS(
                  vehicleData.ndsShortDto?.isNds
                    ? vehicleData.priceWithNds
                    : vehicleData.priceWithOutNds
                )}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Цена партнера</span>
              <span className="working-card__info--title">
                {getPriceWithNDS(
                  vehicleData.ndsShortDto?.isNds
                    ? vehicleData.priceWithNds
                    : vehicleData.priceWithOutNds
                )}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Моточасы GPS</span>
              <span className="working-card__info--title">
                {vehicleData.hoursGps}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">
                Длительность смены
              </span>
              <span className="working-card__info--title">
                {vehicleData.shiftDuration}
              </span>
            </p>
            <p className="working-card__info">
              <span className="working-card__info--label">Сумма</span>
              <span className="working-card__info--title">{getTotal()}</span>
            </p>
          </div>
          <div className="working-card__col">
            <div className="working-card__info">
              <span className="working-card__info--label">Дата начала</span>
              <span className="working-card__info--title">
                {dateFormat(vehicleData.workStartDateTime, "dd.MM.yyyy")}
              </span>
            </div>
            <div className="working-card__info">
              <span className="working-card__info--label">Время начала</span>
              <span className="working-card__info--title">
                {dateFormat(vehicleData.workStartDateTime, "HH:mm")}
              </span>
            </div>
            <div className="working-card__info">
              <span className="working-card__info--label">Дата завершения</span>
              <span className="working-card__info--title">
                {dateFormat(vehicleData.workEndDateTime, "dd.MM.yyyy")}
              </span>
            </div>
            <div className="working-card__info">
              <span className="working-card__info--label">
                Время завершения
              </span>
              <span className="working-card__info--title">
                {dateFormat(vehicleData.workEndDateTime, "HH:mm")}
              </span>
            </div>
            {renderWorkPerformedInput()}
          </div>
        </div>
        <div className="working-card__bottom">
          <div id="inner-info">
            <Info
              label={"Комментарий из журнала"}
              variant={"secondary"}
              value={vehicleData.comment}
              ellipsis={false}
            />
          </div>
        </div>
      </div>
      <PrintButton onClick={() => window.print()}>Печать</PrintButton>
    </Container>
  );
}

export default PageVehicleAccountingPrinting;
