import React, { memo } from "react";
import {
  StyledPanel,
  StyledPanelImageMobile,
  StyledPanelText,
  StyledPanelTitle,
} from "../StyledPanel";
import styled, { css } from "styled-components";
import BG2Src from "./bg2.png";
import BGSrc from "./bg.png";
import content from "./content.json";
import { Grid } from "@app/components";
import { switchProp } from "styled-tools";

interface Props {
  variant?: "default" | "secondary";
}

const StyledPanelControl = styled(StyledPanel)<Pick<Props, "variant">>`
  @media only screen and (max-width: 576px) {
    background: none;
  }
  background-repeat: no-repeat;
  background-position: bottom right;

  ${switchProp("variant", {
    default: css`
      grid-column-start: 4;
      grid-column-end: 7;
      background-image: url(${BGSrc});
      background-size: 90%;
      @media only screen and (max-width: 576px) {
        height: 270px;
      }
    `,
    secondary: css`
      grid-column-start: 1;
      grid-column-end: 4;
      height: 256px;
      background-image: url(${BG2Src});
      background-size: 80%;
    `,
  })}
`;

function PanelControl(props: Props) {
  const { variant = "default" } = props;

  return (
    <StyledPanelControl variant={variant}>
      <Grid gap={12}>
        <StyledPanelTitle>{content.title}</StyledPanelTitle>
        <StyledPanelText>
          {variant === "default" ? content.text : content.text2}
        </StyledPanelText>
        <StyledPanelImageMobile src={BGSrc} width={"100%"} alt="" />
      </Grid>
    </StyledPanelControl>
  );
}

export default memo(PanelControl);
