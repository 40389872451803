import React, {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  accountRegistration,
  getCode,
  getCountByUser,
  signIn as signInUser,
  SignInData,
  SignUpData,
  UserCounts,
} from "@app/api";
import Cookies from "js-cookie";
import { isPartner, parseJWT } from "@app/helpers";
import { User, UserRoles } from "@app/models";
import { AxiosResponse } from "axios";

interface Props extends PropsWithChildren {}

interface UserContextValue {
  authenticated: boolean;
  user: User | null;
  signIn: (data: SignInData) => Promise<boolean>;
  signUp: (data: SignUpData) => Promise<boolean>;
  sendCode: (phone: string) => Promise<boolean>;
  userCounts?: UserCounts;
  asPartner: boolean;
  logout: () => void;
  getUserCounts: () => void;
}

interface JWTContent {
  fullName: string;
  userId: string;
  aud: string;
  email: string;
  exp: number;
  iat: number;
  iss: string;
  nameid: string;
  nbf: number;
  unique_name: string;
  role: UserRoles;
}

const UserContext = createContext<UserContextValue>({} as UserContextValue);

const accessToken = Cookies.get("accessToken");
const asPartnerCookie = Cookies.get("asPartner");

function UserProvider(props: Props) {
  const { children } = props;
  const [authenticated, setAuthenticated] = useState<boolean>(!!accessToken);
  const [asPartner, setAsPartner] = useState<boolean>(!!asPartnerCookie);

  const [user, setUser] = useState<User | null>(() => {
    if (!accessToken) {
      return null;
    }

    const jwtContent = parseJWT<JWTContent>(accessToken);

    return {
      id: jwtContent.userId,
      fullName: jwtContent.fullName,
      email: jwtContent.email,
      role: jwtContent.role,
    };
  });

  const [userCounts, setUserCounts] = useState<UserCounts>();

  const signIn = useCallback((data: SignInData): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      signInUser({
        username: data.username,
        password: data.password,
      })
        .then((signInResponse) => {
          const jwtContent = parseJWT<JWTContent>(signInResponse.token);

          if (data.asPartner && !isPartner(jwtContent.role)) {
            reject({
              response: {
                data: {
                  message: "Вы не являетесь партнером",
                  succeeded: false,
                },
              },
            } as Partial<AxiosResponse<Response>>);

            return;
          }

          setUser({
            id: jwtContent.userId,
            fullName: jwtContent.fullName,
            email: jwtContent.email,
            role: jwtContent.role,
          });

          setAuthenticated(true);

          Cookies.set("accessToken", signInResponse.token);

          if (data.asPartner) {
            Cookies.set("asPartner", "true");
          }

          setAsPartner(data.asPartner);

          resolve(true);
        })
        .catch(reject);
    });
  }, []);

  const signUp = useCallback((data: SignUpData): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      accountRegistration(data)
        .then((success) => {
          resolve(success);
        })
        .catch(reject);
    });
  }, []);

  const sendCode = useCallback((phone: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      getCode(phone.substring(1))
        .then((res) => {
          Cookies.set("phone", phone);
          Cookies.set("send_time", String(new Date().getTime() + 1000 * 61));
          if (!res.succeeded) {
            reject({
              response: {
                data: {
                  message: "Ошибка",
                  succeeded: false,
                },
              },
            } as Partial<AxiosResponse<Response>>);
            return;
          }

          resolve(true);
        })
        .catch(reject);
    });
  }, []);

  const logout = useCallback(() => {
    Cookies.remove("accessToken");
    localStorage.clear();
    if (asPartner) {
      Cookies.remove("asPartner");
      localStorage.clear();
    }

    setAuthenticated(false);
  }, [asPartner]);

  // Получение кол-ва незавершенных задач юзера
  const getUserCounts = useCallback(() => {
    getCountByUser()
      .then((res) => {
        setUserCounts(res.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <UserContext.Provider
      value={{
        authenticated,
        user,
        userCounts,
        signIn,
        signUp,
        sendCode,
        logout,
        asPartner,
        getUserCounts,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser(): UserContextValue {
  return useContext(UserContext);
}

export default memo(UserProvider);
