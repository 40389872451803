import { VehicleStatus, VehicleStatusName } from "@app/models";
import { Chip, ChipColor } from "@app/components";
import React, { memo } from "react";

interface Props {
  status: VehicleStatus;
}

function getStatusVariant(status: VehicleStatus): ChipColor {
  switch (status) {
    case VehicleStatus.Created:
      return "default";
    case VehicleStatus.AwaitingConfirmation:
      return "warning";
    case VehicleStatus.Confirmed:
      return "success";
    case VehicleStatus.Rejected:
      return "danger";
    default:
      return "default";
  }
}

function VehicleStatusChip(props: Props) {
  const { status } = props;

  return (
    <Chip text={VehicleStatusName[status]} color={getStatusVariant(status)} />
  );
}

export default memo(VehicleStatusChip);
