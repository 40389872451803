import React, { memo, ReactNode, useCallback, useEffect } from "react";
import { TooltipCell } from "@app/common";
import * as Styled from "./components";
import { TableRow } from "./components";

interface Props {
  labels: (string | ReactNode)[];
  data?: (string | ReactNode)[][];
  collapsedData?: (string | ReactNode)[][];
  collapsedRows?: number[];
  nodeData?: (string | ReactNode)[][][];
  onClick?: (index: number) => void;
  onRowCollapse?: (indexes: number[]) => void;
  extraRow?: (string | ReactNode)[];
  totalRow?: (string | ReactNode)[];
  disableOverflow?: boolean;
  dense?: boolean;
  emptyTitle?: string;
  hideHeader?: boolean;
  stickyHeader?: boolean;
  height?: string;
  cellMaxWidth?: number;
  align?: "top" | "middle" | "bottom";
  lazy?: boolean;
}

function Table(props: Props) {
  const {
    labels,
    data = [],
    collapsedData = [],
    collapsedRows = [],
    nodeData = [],
    onClick,
    onRowCollapse,
    extraRow,
    totalRow,
    disableOverflow = false,
    dense = false,
    hideHeader = false,
    stickyHeader = false,
    lazy = false,
    height,
    cellMaxWidth = 345,
    emptyTitle,
    align = "middle",
  } = props;

  useEffect(() => {}, []);

  const onClickRow = useCallback(
    (index: number) => () => {
      if (!onClick) {
        return;
      }

      onClick(index);
    },
    [onClick]
  );

  const onParentRowClick = useCallback(
    (rowIndex: number) => () => {
      const findIndex = collapsedRows.findIndex((val) => val === rowIndex);
      if (findIndex >= 0) {
        collapsedRows.splice(findIndex, 1);
        if (onRowCollapse) {
          onRowCollapse([...collapsedRows]);
        }
      } else {
        if (onRowCollapse) {
          onRowCollapse([...collapsedRows, rowIndex]);
        }
      }
    },
    [collapsedRows, onRowCollapse]
  );

  return (
    <Styled.Wrapper disableOverflow={disableOverflow} fixedHeight={height}>
      <Styled.TableRoot className="b-table">
        {!hideHeader && (
          <thead>
            <tr
              style={{
                verticalAlign: align,
              }}
            >
              {labels.map((label, labelIndex) => (
                <Styled.TableHead
                  key={labelIndex.toString()}
                  dense={dense}
                  sticky={stickyHeader}
                  fitContent={typeof label === "string" && label.length === 0}
                >
                  {label}
                </Styled.TableHead>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {!!extraRow && (
            <Styled.TableExtraRow>
              {extraRow.map((val, index) => (
                <Styled.TableExtraCell colSpan={1} key={index.toString()}>
                  {val}
                </Styled.TableExtraCell>
              ))}
            </Styled.TableExtraRow>
          )}
          {data.map((values, valuesIndex) => {
            return (
              <TableRow
                key={valuesIndex.toString()}
                clickable={!!onClick}
                lazy={lazy}
                onClick={onClickRow(valuesIndex)}
              >
                {values.map((value, valueIndex) => (
                  <Styled.TableCell
                    key={valueIndex.toString()}
                    dense={dense}
                    noHeader={valuesIndex === 0 && hideHeader}
                    maxWidth={cellMaxWidth}
                  >
                    {typeof value === "string" ? (
                      <TooltipCell text={value} />
                    ) : (
                      value
                    )}
                  </Styled.TableCell>
                ))}
              </TableRow>
            );
          })}
          {collapsedData.map((values, valuesIndex) => (
            <React.Fragment key={valuesIndex.toString()}>
              <TableRow
                clickable={true}
                lazy={lazy}
                onClick={onParentRowClick(valuesIndex)}
              >
                {values.map((value, valueIndex) => (
                  <React.Fragment key={valueIndex.toString()}>
                    {value}
                  </React.Fragment>
                ))}
              </TableRow>
              {nodeData[valuesIndex].map((node, nodeIndex) => (
                <TableRow
                  key={`${valuesIndex * 10 + nodeIndex}`}
                  clickable={!!onClick}
                  lazy={lazy}
                  onClick={onClickRow(nodeIndex)}
                >
                  {!collapsedRows.includes(valuesIndex) &&
                    node.map((nodeValue, nodeValueIndex) => (
                      <Styled.TableCell
                        key={`${nodeIndex}${nodeValueIndex}`}
                        maxWidth={cellMaxWidth}
                      >
                        {typeof nodeValue === "string" ? (
                          <TooltipCell text={nodeValue} />
                        ) : (
                          nodeValue
                        )}
                      </Styled.TableCell>
                    ))}
                </TableRow>
              ))}
            </React.Fragment>
          ))}
          {!!totalRow && (
            <Styled.TableExtraRow>
              {totalRow.map((val, index) => (
                <Styled.TableExtraCell colSpan={1} key={index.toString()}>
                  {val}
                </Styled.TableExtraCell>
              ))}
            </Styled.TableExtraRow>
          )}
          {data.length === 0 && emptyTitle && (
            <tr>
              <Styled.EmptyCell colSpan={labels.length}>
                {emptyTitle}
              </Styled.EmptyCell>
            </tr>
          )}
        </tbody>
      </Styled.TableRoot>
    </Styled.Wrapper>
  );
}

export default memo(Table);
