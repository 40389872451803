import React, { useCallback, useState } from "react";
import { LayoutDefault } from "@app/layouts";
import { KIPFilter, KIPStatistics } from "./components";
import styled from "styled-components";
import { Grid, Loader, Select, SelectOption } from "@app/components";
import { useKIP } from "@app/providers";
import { ProjectsNew } from "./components/Projects";
import { KIPCustomer, KIPDivision, KIPProject, KIPRegion } from "@app/models";
import { ModalKIPDivisionDetail, ModalKIPProject } from "@app/modals";
import { theme } from "styled-tools";

const StyledContent = styled.div`
  padding: 16px;
  gap: 16px;
  display: grid;
`;

const StyledRow = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-radius: 4px;
  background: ${theme("color.white")};

  /* Table shadow */
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08), 0 -2px 8px 0 rgba(0, 0, 0, 0.08);

  #select {
    width: 240px;
  }
`;

const StyledBlock = styled.div`
  display: flex;
  justify-content: center;
`;

const vehicleTypes: SelectOption<null>[] = [
  {
    label: "Без группировки",
    value: 0,
    item: null,
  },
  {
    label: "По дивизионам",
    value: 1,
    item: null,
  },
  {
    label: "По регионам",
    value: 2,
    item: null,
  },
  {
    label: "По организациям",
    value: 3,
    item: null,
  },
];

export default function () {
  const {
    pending,
    loaded,
    getProjectTableData,
    getDivisionTableData,
    getRegionTableData,
    getCustomersTableData,
  } = useKIP();
  const [grouped, setGrouped] = useState<SelectOption<null> | null>(
    vehicleTypes[1]
  );
  const [project, setProject] = useState<KIPProject | null>(null);
  const [division, setDivision] = useState<Partial<KIPDivision> | null>(null);
  const [region, setRegion] = useState<Partial<KIPRegion> | null>(null);
  const [customer, setCustomer] = useState<Partial<KIPCustomer> | null>(null);
  const [filterDate, setFilterDate] = useState<string>("");

  const onCloseModal = useCallback(() => {
    setProject(null);
    setDivision(null);
    setRegion(null);
    setCustomer(null);
  }, []);

  const onClickProject = useCallback((item: KIPProject) => {
    setProject(item);
  }, []);

  const onClickDivision = useCallback((item: Partial<KIPDivision>) => {
    setDivision(item);
  }, []);

  const onClickRegion = useCallback((item: Partial<KIPRegion>) => {
    setRegion(item);
  }, []);

  const onClickCustomer = useCallback((item: Partial<KIPCustomer>) => {
    setCustomer(item);
  }, []);

  const onDateSelect = useCallback((date: string) => {
    setFilterDate(date);
  }, []);

  const onChangeGroup = useCallback(
    (value: SelectOption<null> | null, _: any) => {
      setGrouped(value);
    },
    []
  );

  return (
    <LayoutDefault
      title={"Отчет о выполненной работе и загруженности техники (КИП)"}
    >
      <KIPFilter onDateSelect={onDateSelect} />
      {loaded ? (
        <StyledContent>
          {pending ? (
            <Loader />
          ) : (
            <>
              <StyledRow>
                <KIPStatistics />
                <div id="select">
                  <Select<any>
                    label="Групировать по"
                    name="group"
                    onChange={onChangeGroup}
                    value={grouped}
                    valueKey="id"
                    labelKey="name"
                    options={vehicleTypes}
                  />
                </div>
              </StyledRow>
              <Grid
                columns={
                  grouped?.value === 0 || getDivisionTableData().length === 1
                    ? 1
                    : 2
                }
              >
                {getProjectTableData().length > 0 ? (
                  <>
                    {getProjectTableData().length && grouped?.value === 0 && (
                      <StyledBlock>
                        <ProjectsNew
                          projects={getProjectTableData()}
                          onClick={onClickProject}
                        />
                      </StyledBlock>
                    )}
                    {grouped?.value === 1 &&
                      getDivisionTableData().map((division) => (
                        <StyledBlock>
                          <ProjectsNew
                            key={division.id}
                            title={division.name!}
                            projects={
                              division.projects as Partial<KIPProject>[]
                            }
                            isGroupedByDivision={true}
                            onClick={() => onClickDivision(division)}
                          />
                        </StyledBlock>
                      ))}
                    {grouped?.value === 2 &&
                      getRegionTableData().map((region) => (
                        <StyledBlock>
                          <ProjectsNew
                            key={region.id}
                            title={region.name!}
                            projects={region.projects as Partial<KIPProject>[]}
                            isGroupedByDivision={true}
                            onClick={() => onClickRegion(region)}
                          />
                        </StyledBlock>
                      ))}
                    {grouped?.value === 3 &&
                      getCustomersTableData().map((customer) => (
                        <StyledBlock>
                          <ProjectsNew
                            key={customer.id}
                            title={customer.name!}
                            projects={
                              customer.projects as Partial<KIPProject>[]
                            }
                            isGroupedByDivision={true}
                            onClick={() => onClickCustomer(customer)}
                          />
                        </StyledBlock>
                      ))}
                  </>
                ) : null}
              </Grid>
            </>
          )}
        </StyledContent>
      ) : (
        <StyledContent>
          <br />
          <br />
          <StyledBlock>Заполните фильтры и нажмите «Применить»</StyledBlock>
        </StyledContent>
      )}
      <ModalKIPProject
        open={!!project}
        project={project}
        date={filterDate}
        onClose={onCloseModal}
      />
      <ModalKIPDivisionDetail
        open={!!division}
        title={division?.name}
        date={filterDate}
        projects={division?.projects ? division.projects : []}
        onClose={onCloseModal}
      />
      <ModalKIPDivisionDetail
        open={!!region}
        title={region?.name}
        date={filterDate}
        projects={region?.projects || []}
        onClose={onCloseModal}
      />
      <ModalKIPDivisionDetail
        open={!!customer}
        title={customer?.name}
        date={filterDate}
        projects={customer?.projects || []}
        onClose={onCloseModal}
      />
    </LayoutDefault>
  );
}
