import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Button, Grid, Select, SelectOption, useForm } from "@app/components";
import {
  Characteristic,
  Company,
  GPS,
  Project,
  VehicleType,
} from "@app/models";
import {
  getCharacteristics,
  getCompanies,
  getGps,
  getMyCompanies,
  getMyProjects,
  getProjects,
  getVehicleTypes,
  PageableParams,
} from "@app/api";
import * as yup from "yup";
import { useUser } from "@app/providers";
import { companyLabelKeys, isDispatcher } from "@app/helpers";

interface Props {
  onSubmit: (values: VehiclesFilterParams) => void;
  defaultFilter: VehiclesFilterParams;
}

export interface VehiclesFilterParams {
  gps: SelectOption<GPS> | null;
  organization: SelectOption<Company> | null;
  project: SelectOption<Project> | null;
  partner: SelectOption<Company> | null;
  vehicleType: SelectOption<VehicleType> | null;
  characteristic: SelectOption<Characteristic> | null;
  isAvailable: SelectOption<boolean> | null;
}

const StyledFilter = styled.div<{ offset: number; maxHeight: number }>`
  position: absolute;
  box-sizing: border-box;
  padding: 20px;
  width: 360px;
  background-color: ${theme("color.white")};
  border-radius: 4px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
  overflow: auto;
  z-index: 1;
  margin-top: ${(props) => `${props.offset}px`};
  max-height: ${(props) => `${props.maxHeight}px`};
`;

const StyledTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
`;

const schema = yup.object().shape({});

export default function (props: Props) {
  const { onSubmit, defaultFilter } = props;
  const { user } = useUser();
  const { values, setValues, onChange } = useForm<VehiclesFilterParams>({
    values: defaultFilter,
    schema,
  });

  const ref = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);
  const [maxHeight, setMaxHeight] = useState(685);

  const onClickReset = useCallback(() => {
    setValues(defaultFilter);
  }, [setValues, defaultFilter]);

  const onClickSubmit = useCallback(() => {
    onSubmit(values);
  }, [values, onSubmit]);

  const loadCharacteristics = useCallback(
    (params: PageableParams<Characteristic>) => {
      return getCharacteristics({
        ...params,
        vehicleTypeId: values.vehicleType!.value,
      });
    },
    [values.vehicleType]
  );

  useEffect(() => {
    // В случаях, когда блок выше самого окна, изменять его размер и положение
    const { height = 0, top = 0 } = ref.current?.getBoundingClientRect() || {};

    if (height > window.innerHeight) {
      setMaxHeight(window.innerHeight);
    }
    if (height + top > window.innerHeight) {
      const HEADER_HEIGHT = 64;
      setOffset(window.innerHeight - top - height + HEADER_HEIGHT);
    }
  }, []);

  return (
    <StyledFilter ref={ref} offset={offset} maxHeight={maxHeight}>
      <Grid gap={24}>
        <StyledTitle>Фильтр</StyledTitle>
        <Grid gap={16}>
          <Select<GPS>
            label="Провайдер GPS"
            name="gps"
            onChange={onChange}
            value={values.gps}
            valueKey="id"
            labelKey="name"
            loadData={getGps}
          />
          <Select<Company>
            label="Организация"
            name="organization"
            onChange={onChange}
            value={values.organization}
            valueKey="id"
            labelKeys={companyLabelKeys}
            labelKeysSeparator={" / "}
            loadData={isDispatcher(user!.role) ? getCompanies : getMyCompanies}
          />
          <Select<Project>
            label="Проект"
            name="project"
            onChange={onChange}
            value={values.project}
            valueKey="id"
            labelKey="name"
            loadData={isDispatcher(user!.role) ? getProjects : getMyProjects}
          />
          <Select<Company>
            label="Партнер"
            name="partner"
            onChange={onChange}
            value={values.partner}
            valueKey="id"
            labelKey="name"
            loadData={getCompanies}
          />
          <Select<VehicleType>
            label="Тип ТС"
            name="vehicleType"
            onChange={onChange}
            value={values.vehicleType}
            valueKey="id"
            labelKey="name"
            loadData={getVehicleTypes}
          />
          <Select<Characteristic>
            label="Характеристика ТС"
            name="characteristic"
            onChange={onChange}
            value={values.characteristic}
            valueKey="id"
            labelKey="name"
            disabled={!values.vehicleType}
            loadData={loadCharacteristics}
          />
          <Select<boolean>
            label="Статус"
            name="isAvailable"
            onChange={onChange}
            options={[
              { label: "Свободен", value: true },
              { label: "Занят", value: false },
            ]}
            value={values.isAvailable}
          />
        </Grid>
        <Grid gap={16} columns={2}>
          <Button text="Сбросить" variant="outlined" onClick={onClickReset} />
          <Button text="Применить" onClick={onClickSubmit} />
        </Grid>
      </Grid>
    </StyledFilter>
  );
}
