import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, Modal, ModalProps, useForm } from "@app/components";
import { Company } from "@app/models";
import { Organization, OrganizationDetails } from "./components";
import { Search } from "@app/common";
import { APIResponse, getCompanies, joinAnCompany, uploadFile } from "@app/api";
import styled from "styled-components";
import { theme } from "styled-tools";
import * as yup from "yup";
import { format } from "date-fns";
import { useNotification } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

interface Props extends Omit<ModalProps, "title"> {}

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 872px;
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInfoContainerTitle = styled.p`
  font-family: ${theme("fontFamily")};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.gray")};
`;

const StyledOrganizationName = styled.p`
  margin: 0 0 4px;
  font-family: ${theme("fontFamily")};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
`;

const StyledOrganizationBin = styled.p`
  margin: 0 0 12px;
  font-family: ${theme("fontFamily")};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.grayDark")};
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  justify-content: flex-end;
  padding: 16px;
  box-sizing: border-box;
  margin-top: auto;
`;

function ModalJoinAnOrganization(props: Props) {
  const { ...modalProps } = props;
  const [currentView, setCurrentView] = useState<"search" | "list" | "details">(
    "search"
  );
  const [selectedOrganization, setSelectedOrganization] =
    useState<Company | null>(null);
  const { showNotification } = useNotification();
  const [isSignatoryChecked, setIsSignatoryChecked] = useState(false);
  const [validationSchema, setValidationSchema] = useState<any>();
  const {
    pending,
    onChange,
    values: formData,
    errors,
    validate,
    setPending,
    resetForm,
    setValues,
  } = useForm<any>({
    values: {
      type: null,
      powerOfAttorniesNumber: "",
      powerOfAttorniesDate: null,
      registrationCertificateNumber: "",
      registrationCertificateSeries: "",
      registrationCertificateDate: null,
      files: [],
    },
    schema: validationSchema,
  });

  useEffect(() => {
    const typeValue = formData.type?.value;
    setValidationSchema(
      yup.object().shape({
        type: isSignatoryChecked
          ? yup.object().nullable().required("Поле обязательно к заполнению")
          : yup.string().nullable().notRequired(),
        registrationCertificateNumber:
          typeValue === 3
            ? yup.string().nullable().required("Поле обязательно к заполнению")
            : yup.string().nullable().notRequired(),
        registrationCertificateSeries:
          typeValue === 3
            ? yup.string().nullable().notRequired()
            : yup.string().nullable().notRequired(),
        registrationCertificateDate:
          typeValue === 3
            ? yup.date().required("Поле обязательно к заполнению")
            : yup.date().nullable().notRequired(),
        powerOfAttorniesNumber:
          typeValue === 2
            ? yup.string().nullable().notRequired()
            : yup.string().nullable().notRequired(),
        powerOfAttorniesDate:
          typeValue === 2
            ? yup.date().required("Поле обязательно к заполнению")
            : yup.date().nullable().notRequired(),
      })
    );
  }, [formData.type]);

  const onSelectOrganization = useCallback((organization: Company) => {
    setSelectedOrganization(organization);
    setCurrentView("details");
  }, []);

  const onCheckboxChange = useCallback((isChecked: boolean) => {
    setIsSignatoryChecked(isChecked);
  }, []);

  const handleSubmit = async () => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }
    setPending(true);

    const uploadedFileIds: string[] = [];
    if (formData.files && formData.files.length > 0) {
      for (const file of formData.files) {
        const formData = new FormData();
        formData.append("files", file);

        try {
          const response = await uploadFile(formData);
          uploadedFileIds.push(response.data[0].id);
        } catch (error) {
          showNotification({
            message: `Ошибка загрузки файла: ${getAxiosErrorMessage(
              error as AxiosError<APIResponse>
            )}`,
            variant: "error",
          });
          setPending(false);
          return;
        }
      }
    }

    const payload = {
      companyId: selectedOrganization?.id,
      isSigner: isSignatoryChecked,
      employmentTypeId: formData.type?.value,
      registrationCertificateNumber: formData.registrationCertificateNumber,
      registrationCertificateSeries:
        formData.registrationCertificateSeries || "",
      registrationCertificateDate: formData.registrationCertificateDate
        ? format(formData.registrationCertificateDate, "yyyy-MM-dd'T'HH:mm:ss")
        : "",
      powerOfAttorneyNumber: formData.powerOfAttorniesNumber || "",
      powerOfAttorneyDate: formData.powerOfAttorniesDate
        ? format(formData.powerOfAttorniesDate, "yyyy-MM-dd'T'HH:mm:ss")
        : "",
      fileId: uploadedFileIds[0],
    };

    try {
      const response = await joinAnCompany(payload);
      console.log("RESPONSE: ", response);
      showNotification({
        message: "Данные успешно отправлены",
        variant: "success",
      });
      setPending(false);
      modalProps.onClose();
    } catch (error) {
      console.error(error);
      showNotification({
        message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
        variant: "error",
      });
      setPending(false);
    }
  };

  useEffect(() => {
    if (formData.type) {
      setValues({
        files: [],
      });
    }
  }, [formData.type]);

  useEffect(() => {
    if (modalProps.open) {
      setValues({
        type: null,
        powerOfAttorniesNumber: "",
        powerOfAttorniesDate: null,
        registrationCertificateNumber: "",
        registrationCertificateSeries: "",
        registrationCertificateDate: null,
        files: [],
      });
      setCurrentView("search");
      setSelectedOrganization(null);
      setIsSignatoryChecked(false);
      resetForm();
    }
  }, [modalProps.open]);

  const shouldShowFooter = ["details"].includes(currentView);
  let headerContent = null;
  if (currentView === "details" && selectedOrganization) {
    headerContent = (
      <>
        <StyledInfo>
          <StyledInfoContainer>
            <StyledInfoContainerTitle>Организация</StyledInfoContainerTitle>
            <StyledOrganizationName>
              {selectedOrganization.name}
            </StyledOrganizationName>
          </StyledInfoContainer>
          <StyledInfoContainer>
            <StyledInfoContainerTitle>БИН</StyledInfoContainerTitle>
            <StyledOrganizationBin>
              {selectedOrganization.bin}
            </StyledOrganizationBin>
          </StyledInfoContainer>
        </StyledInfo>
      </>
    );
  }

  return (
    <Modal
      title="Присоединиться к организации"
      {...modalProps}
      headerContent={headerContent}
    >
      <StyledModalContent>
        {currentView === "search" && (
          <Search<Company>
            renderItem={(organization) => (
              <Organization
                organization={organization}
                onClick={() => onSelectOrganization(organization)}
                onBack={() => setCurrentView("search")}
              />
            )}
            getData={getCompanies}
          />
        )}
        {currentView === "details" && selectedOrganization && (
          <OrganizationDetails
            dataParams={formData}
            dataErrors={errors}
            onDataChange={onChange}
            organization={selectedOrganization}
            resetForm={resetForm}
            onBack={() => setCurrentView("search")}
            onCheckboxChange={onCheckboxChange}
          />
        )}
      </StyledModalContent>
      {shouldShowFooter && (
        <StyledFooter>
          <Button
            text="Назад"
            variant="text"
            showLoader={false}
            onClick={() => setCurrentView("search")}
          />
          {currentView === "details" && (
            <Button
              text="Присоединиться"
              onClick={handleSubmit}
              showLoader={pending}
            />
          )}
        </StyledFooter>
      )}
    </Modal>
  );
}

export default memo(ModalJoinAnOrganization);
