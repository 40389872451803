import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TextField } from "../index";
import Calendar, { ICalendarDay } from "../Calendar";
import Grid from "../Grid/Grid";
import Button from "../Button/Button";
import { dateFormat } from "@app/helpers";
import styled from "styled-components";
import { format } from "date-fns";
import Field, { FieldProps } from "../Field";
import { theme } from "styled-tools";

interface Props extends FieldProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange?: (value: [Date, Date] | null, name: any) => void;
  format?: string;
  placeholder?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
}

const StyledInput = styled.input`
  height: 100%;
  border: 0;
  padding: 0 4px;
  box-sizing: border-box;
  outline: none;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;

  &::placeholder {
    color: #acacac;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #acacac;
  }

  &::-ms-input-placeholder {
    color: #acacac;
  }
`;

const StyledTrigger = styled.div`
  width: 100%;
  height: 40px;
  text-align: left;
  border: none;
  outline: none;
  background-color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const StyledPaper = styled.div<{ visible: boolean }>`
  position: absolute;
  left: 0;
  top: 64px;
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: column;
  gap: 24px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  overflow-y: auto;
  z-index: 2;
  width: 776px;
  min-height: 352px;
  box-sizing: border-box;
  padding: 16px;
`;

const StyledColumns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 24px;
`;

const StyledDivider = styled.div`
  width: 1px;
  background-color: ${theme("color.grayLight")};
  margin-top: 24px;
`;

function DatePeriodPicker(props: Props) {
  const {
    startDate: startDateProps,
    endDate: endDateProps,
    onChange,
    format: propsFormat = "dd.MM.yy",
    placeholder = "Укажите период",
    minDate,
    maxDate,
    name,
    ...fieldProps
  } = props;

  const [startDate, setStartDate] = useState<Date>(
    startDateProps || new Date()
  );
  const [startTime, setStartTime] = useState<string>(
    !!startDateProps ? format(startDateProps, "HH:mm") : ""
  );
  const [inputStartDate, setInputStartDate] = useState(
    !!startDateProps ? format(startDateProps, propsFormat) : ""
  );

  const [endDate, setEndDate] = useState<Date>(endDateProps || new Date());
  const [endTime, setEndTime] = useState<string>(
    !!endDateProps ? format(endDateProps, "HH:mm") : ""
  );
  const [inputEndDate, setInputEndDate] = useState(
    !!endDateProps ? format(endDateProps, propsFormat) : ""
  );

  const [startTimeError, setStartTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);

  const [paperVisible, setPaperVisible] = useState<boolean>(false);

  const fieldRef = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      fieldRef.current &&
      !fieldRef.current.contains(e.target as HTMLElement)
    ) {
      setPaperVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  const onClickStartDate = useCallback(
    (date: ICalendarDay) => {
      setStartDate(date.day);
      setInputStartDate(dateFormat(date.day, propsFormat));
    },
    [propsFormat]
  );

  const onClickEndDate = useCallback(
    (date: ICalendarDay) => {
      setEndDate(date.day);
      setInputEndDate(dateFormat(date.day, propsFormat));
    },
    [propsFormat]
  );

  const onStartTimeChange = useCallback((value: string) => {
    // onTimeChange(value, true);
    setStartTime(value);
    setStartTimeError(false);
  }, []);

  const onEndTimeChange = useCallback((value: string) => {
    setEndTime(value);
    setEndTimeError(false);
  }, []);

  const onFocus = useCallback(() => {
    setPaperVisible((prevState) => !prevState);
  }, []);

  const onClickCancel = useCallback(() => {
    setPaperVisible(false);
  }, []);

  const onClickSubmit = useCallback(() => {
    if (!onChange) {
      return;
    }

    if (startTime.length < 5 || !startDate) {
      setStartTimeError(true);
      return;
    }
    if (endTime.length < 5 || !endDate) {
      setEndTimeError(true);
      return;
    }

    const returnStartDate = new Date(startDate.getTime());
    const startTimeParts = startTime.split(":");
    returnStartDate.setHours(
      Number(startTimeParts[0]),
      Number(startTimeParts[1]),
      0,
      0
    );

    const returnEndDate = new Date(endDate.getTime());
    const endTimeParts = endTime.split(":");
    returnEndDate.setHours(
      Number(endTimeParts[0]),
      Number(endTimeParts[1]),
      0,
      0
    );

    onChange([returnStartDate, returnEndDate], name);
    setPaperVisible(false);
    setStartTimeError(false);
    setEndTimeError(false);
  }, [endDate, endTime, name, onChange, startDate, startTime]);

  const inputValue = useMemo(() => {
    return `${inputStartDate} ${startTime} - ${inputEndDate} ${endTime}`;
  }, [endTime, inputEndDate, inputStartDate, startTime]);

  return (
    <Field
      {...fieldProps}
      ref={fieldRef}
      helperText={fieldProps.helperText || undefined}
    >
      <StyledTrigger>
        <StyledInput
          type="text"
          value={inputValue}
          onFocus={onFocus}
          placeholder={placeholder}
          disabled={fieldProps.disabled}
          readOnly={true}
        />
      </StyledTrigger>
      <StyledPaper visible={paperVisible}>
        <StyledColumns>
          <Calendar
            value={startDate}
            onClick={onClickStartDate}
            minDate={minDate}
            maxDate={endDate}
            startDate={startDate}
            endDate={endDate}
          >
            <Grid>
              <TextField
                label="Время начала"
                placeholder="00:00"
                value={startTime}
                timeFormat={true}
                error={startTimeError}
                helperText={startTimeError ? "Неверный формат времени" : ""}
                onChange={onStartTimeChange}
              />
            </Grid>
          </Calendar>
          <StyledDivider />
          <Calendar
            value={endDate}
            onClick={onClickEndDate}
            minDate={startDate}
            maxDate={maxDate}
            startDate={startDate}
            endDate={endDate}
          >
            <Grid>
              <TextField
                label="Время окончания"
                placeholder="00:00"
                value={endTime}
                timeFormat={true}
                error={endTimeError}
                helperText={endTimeError ? "Неверный формат времени" : ""}
                onChange={onEndTimeChange}
              />
            </Grid>
          </Calendar>
        </StyledColumns>

        <Grid columns={2}>
          <Button text="Отмена" variant="outlined" onClick={onClickCancel} />
          <Button text="Применить" onClick={onClickSubmit} />
        </Grid>
      </StyledPaper>
    </Field>
  );
}

export default memo(DatePeriodPicker);
