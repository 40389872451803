import { CompanyFull } from "./Company";
import { FileSimple } from "./Default";
import { UserSimple } from "./User";

export enum AdhesionContractStatus {
  Created = 10,
  Signed = 20,
}

export const AdhesionStatusName: Record<AdhesionContractStatus, string> = {
  [AdhesionContractStatus.Created]: "Создан",
  [AdhesionContractStatus.Signed]: "Подписан",
};

export interface Adhesion {
  id: string;
  createdBy: string | null;
  created: string;
  lastModifiedBy: string | null;
  lastModified: string | null;
  deleted: string | null;
  isDeleted: boolean;
  number: number;
  statusId: AdhesionContractStatus;
  date: string;
  companyId: string;
  fileId: string | null;
  pdfFileId: string | null;
  signedFileId: string | null;
  signerUserId: string | null;
  initiatorUserId: string;
  signed: string | null;
  isSigned: boolean;
  company: CompanyFull;
  file: FileSimple;
  pdfFile: FileSimple;
  signedFile: FileSimple;
  signerUser: UserSimple;
  initiatorUser: UserSimple;
}
