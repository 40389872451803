import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { switchProp, theme } from "styled-tools";
import {
  Button,
  DateTimePicker,
  Grid,
  Select,
  SelectOption,
  TextField,
  useForm,
} from "@app/components";
import {
  ActOfWork,
  Company,
  Dictionary,
  NDS,
  Project,
  SmartContract,
} from "@app/models";
import {
  ActOfProvidedWorkDTO,
  APIResponse,
  createActOfProvidedWork,
  getCompaniesFiltered,
  getContractsForAct,
  getMyCompanies,
  getMyProjects,
  getNdsByCompany,
  getProjects,
  getUnits,
  updateActOfProvidedWork,
} from "@app/api";
import * as yup from "yup";
import {
  companyLabelKeys,
  getAxiosErrorMessage,
  isDispatcher,
  isPartner,
  isUser,
} from "@app/helpers";
import { useNotification, useUser } from "@app/providers";
import { IconPlus20, IconTrash24 } from "@app/icons";
import { AxiosError } from "axios";
import ActCompanyAddress from "../../../../common/ActCompanyAddress";
import { format } from "date-fns";

interface Props {
  setActOfWork: Dispatch<SetStateAction<ActOfWork | null>>;
  onChange: Dispatch<SetStateAction<number>>;
  basis: Dictionary | null;
  actOfWork: ActOfWork | null;
  disabled: boolean;
  isActOfService: boolean;
}

interface Data {
  senderCompany: SelectOption<Company> | null;
  recipientCompany: SelectOption<Company> | null;
  startDate: Date | null;
  endDate: Date | null;
  date: Date | null;
  project: SelectOption<Project> | null;
  contract: SelectOption<SmartContract> | null;
  nds: SelectOption<NDS> | null;
  registrationNumber: string;
  recipientCompanyAddress: string;
  senderCompanyAddress: string;
}

interface NomenclatureData {
  name: string;
  executionDate: Date | null;
  unit: SelectOption<Dictionary> | null;
  quantity: string;
  price: string;
  sum: string;
  sumNds: string;
}

const schema = yup.object().shape({
  senderCompany: yup.object().nullable().required("Выберите заказчика"),
  recipientCompany: yup.object().nullable().required("Выберите исполнителя"),
  startDate: yup.date().nullable().required("Укажите дату начала"),
  endDate: yup.date().nullable().required("Укажите дату окончания"),
  date: yup.date().nullable().required("Укажите дату выставления АВР"),
  project: yup.object().nullable().required("Выберите проект"),
  contract: yup.object().nullable().required("Выберите договор"),
  nds: yup.object().nullable().required("Выберите НДС"),
  registrationNumber: yup.string().required("Заполните номер АВР"),
  recipientCompanyAddress: yup.string().required("Укажите адрес"),
  senderCompanyAddress: yup.string().required("Укажите адрес"),
});

const StyledStep = styled.div`
  display: grid;
  grid-gap: 0;
  height: 100%;
  grid-template-rows: auto 1fr auto auto;
  position: relative;
  padding: 16px 0;
  box-sizing: border-box;
  overflow: hidden;
`;

const StyledHeader = styled.div`
  padding: 0 24px;
  margin-bottom: 16px;
  box-sizing: border-box;
`;

const StyledContent = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-rows: max-content;
  padding: 0 24px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const StyledNomenclature = styled.div<{
  withRemoveButton: boolean;
}>`
  display: grid;
  gap: 16px;
  align-items: flex-end;
  ${switchProp("withRemoveButton", {
    true: css`
      grid-template-columns: 46px auto 220px 150px 96px 116px 116px 116px max-content;
    `,
    false: css`
      grid-template-columns: 46px auto 220px 150px 96px 116px 116px 116px;
    `,
  })}
`;

const StyledRemoveButton = styled.div`
  .b-button {
    color: ${theme("color.red")} !important;
    border: none;
  }
`;

const StyledTotal = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 96px 116px 116px 116px;
  gap: 16px;
  align-items: center;
`;

const StyledTotalText = styled.p`
  font-size: 20px;
  color: ${theme("color.dark")};
  font-weight: 600;
  text-align: right;
`;

const StyledFooter = styled.div`
  padding: 16px 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  border-top: 1px solid ${theme("color.grayLight")};
  justify-content: flex-end;
`;

export default function (props: Props) {
  const {
    onChange: propsOnChange,
    actOfWork,
    basis,
    disabled,
    setActOfWork,
    isActOfService,
  } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [nomenclatures, setNomenclatures] = useState<NomenclatureData[]>(
    !!actOfWork
      ? actOfWork.nomenclatures.map((item) => ({
          name: item.name,
          executionDate: new Date(item.executionDate),
          unit: {
            label: item.unit.name,
            value: item.unit.id,
          },
          quantity: item.quantity ? item.quantity.toString() : "",
          price: item.price ? item.price.toString() : "",
          sum: item.sum ? item.sum.toString() : "",
          sumNds:
            item.sumNds !== null
              ? item.sumNds === 0
                ? "0"
                : item.sumNds.toString()
              : "",
        }))
      : []
  );
  const [nomenclaturesError, setNomenclaturesError] = useState<{
    [key: string]: string;
  }>({});
  const { values, onChange, errors, pending, setPending, validate } =
    useForm<Data>({
      values: {
        senderCompany: !!actOfWork
          ? {
              label: actOfWork.senderCompany.name,
              value: actOfWork.senderCompany.id,
            }
          : null,
        recipientCompany: !!actOfWork
          ? {
              label: actOfWork.recipientCompany.name,
              value: actOfWork.recipientCompany.id,
            }
          : null,
        startDate: !!actOfWork ? new Date(actOfWork.startDate) : null,
        endDate: !!actOfWork ? new Date(actOfWork.endDate) : null,
        date: !!actOfWork && !!actOfWork.date ? new Date(actOfWork.date) : null,
        project: !!actOfWork
          ? {
              label: actOfWork.project.name,
              value: actOfWork.project.id,
            }
          : null,
        contract: !!actOfWork
          ? {
              label: actOfWork.contract.name,
              value: actOfWork.contract.id,
            }
          : null,
        nds:
          !!actOfWork && !!actOfWork.ndsRate
            ? {
                label: actOfWork.ndsRate,
                value: actOfWork.ndsRate,
              }
            : null,
        registrationNumber: !!actOfWork ? actOfWork.registrationNumber : "",
        recipientCompanyAddress: !!actOfWork
          ? actOfWork.recipientCompanyAddress
          : "",
        senderCompanyAddress: !!actOfWork ? actOfWork.senderCompanyAddress : "",
      },
      schema,
    });

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    const checkFormValidity = async () => {
      const isValid = await validate();
      setIsNextDisabled(!isValid);
    };

    checkFormValidity();
  }, [values, validate]);

  const onClickBack = useCallback(() => {
    propsOnChange(1);
  }, [propsOnChange]);

  const onChangeSenderCompany = useCallback(
    async (value: SelectOption<Company> | null) => {
      try {
        onChange(value, "senderCompany");

        if (!value) {
          onChange(null, "nds");

          return;
        }

        const { succeeded, data: ndsData } = await getNdsByCompany(value.value);

        if (!succeeded) {
          onChange(null, "senderCompany");
          onChange(null, "nds");

          return;
        }

        onChange(
          {
            label: ndsData.name,
            value: ndsData.id,
          },
          "nds"
        );
      } catch (e) {
        onChange(null, "senderCompany");
        onChange(null, "nds");
      }
    },
    [onChange]
  );

  const onChangeNomenclature = useCallback(
    (index: number) => (value: any, name: string) => {
      setNomenclatures((prevNomenclatures) =>
        prevNomenclatures.map((item, itemIndex) => ({
          ...item,
          [name]:
            itemIndex === index ? value : item[name as keyof NomenclatureData],
        }))
      );
    },
    []
  );

  const getNomenclatureSum = useCallback((item: NomenclatureData) => {
    try {
      const price = parseFloat(item.price.replace(",", "."));
      const quantity = parseFloat(item.quantity.replace(",", "."));

      const sum = price * quantity;

      if (isNaN(sum)) {
        return "0";
      }

      return sum.toFixed(2).replace(".", ",");
    } catch (e) {
      return "0";
    }
  }, []);

  const getNomenclatureSumNds = useCallback(
    (item: NomenclatureData) => {
      try {
        if (!values.nds) {
          return "0";
        }

        const ndsRate = parseFloat(values.nds.label.replace("%", "")) / 100;

        const price = parseFloat(item.price.replace(",", "."));
        const quantity = parseFloat(item.quantity.replace(",", "."));

        const sum = price * quantity;

        if (isNaN(sum)) {
          return "0";
        }

        const sumWithNds = (sum / (1 + ndsRate)) * ndsRate;

        if (isNaN(sumWithNds)) {
          return "0";
        }

        return sumWithNds.toFixed(2).replace(".", ",");
      } catch (e) {
        return "0";
      }
    },
    [values.nds]
  );

  const onClickSave = useCallback(async () => {
    try {
      const isValid = await validate();

      const tempNomenclaturesError: { [key: string]: string } = {};

      if (nomenclatures.length === 0) {
        showNotification({
          variant: "error",
          message: "Добавьте номенклатуры",
        });

        return;
      }

      nomenclatures.forEach((item, itemIndex) => {
        Object.keys(item).forEach((fieldName) => {
          if (
            ["executionDate", "sum", "sumNds"].indexOf(
              fieldName as keyof NomenclatureData
            ) === -1 &&
            !item[fieldName as keyof NomenclatureData]
          ) {
            tempNomenclaturesError[`${itemIndex}.${fieldName}`] =
              "Обязательное поле";

            return;
          }
        });
      });

      setNomenclaturesError(tempNomenclaturesError);

      if (!isValid || Object.keys(tempNomenclaturesError).length > 0) {
        return;
      }

      const data: ActOfProvidedWorkDTO = {
        senderCompanyId: values.senderCompany?.value,
        recipientCompanyId: values.recipientCompany?.value,
        projectId: values.project?.value,
        actId: actOfWork?.actId || "",
        registrationNumber: values.registrationNumber,
        date: values.date ? format(values.date, "yyyy-MM-dd'T'HH:mm:ss") : "",
        startDate: values.startDate
          ? format(values.startDate, "yyyy-MM-dd'T'HH:mm:ss")
          : "",
        endDate: values.endDate
          ? format(values.endDate, "yyyy-MM-dd'T'HH:mm:ss")
          : "",
        contractId: values.contract?.value,
        senderCompanyAddress: values.senderCompanyAddress,
        recipientCompanyAddress: values.recipientCompanyAddress,
        ndsRate: values.nds!.label,
        basisId: basis!.id,
        nomenclatures: nomenclatures.map((item) => ({
          name: item.name,
          executionDate: item.executionDate!.toJSON(),
          unitId: item.unit?.value,
          quantity:
            !!item.unit &&
            item.unit.value === "26cd4f51-ca03-11e7-80c3-001dd8b71c7e"
              ? 1
              : Number(item.quantity.replace(",", ".")),
          price: Number(item.price.replace(",", ".")),
          sum: Number(getNomenclatureSum(item).replace(",", ".")),
          sumNds: Number(getNomenclatureSumNds(item).replace(",", ".")),
        })),
      };

      setPending(true);

      let response;

      if (!!actOfWork && actOfWork.id) {
        response = await updateActOfProvidedWork({
          ...data,
          id: actOfWork.id,
        });

        showNotification({
          message: "АВР успешно сохранен!",
          variant: "success",
        });
      } else {
        response = await createActOfProvidedWork(data);

        showNotification({
          message: "АВР успешно обновлен!",
          variant: "success",
        });
      }

      setActOfWork(response.data);

      propsOnChange(3);
      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [
    validate,
    nomenclatures,
    values.senderCompany?.value,
    values.recipientCompany?.value,
    values.project?.value,
    values.registrationNumber,
    values.date,
    values.startDate,
    values.endDate,
    values.contract?.value,
    values.senderCompanyAddress,
    values.recipientCompanyAddress,
    values.nds,
    actOfWork,
    basis,
    setPending,
    setActOfWork,
    propsOnChange,
    showNotification,
    getNomenclatureSum,
    getNomenclatureSumNds,
  ]);

  const onClickAddNomenclature = useCallback(() => {
    setNomenclatures((prevNomenclatures) => [
      ...prevNomenclatures,
      {
        name: "",
        executionDate: null,
        unit: null,
        quantity: "",
        price: "",
        sum: "",
        sumNds: "",
      },
    ]);
  }, []);

  const onClickRemoveNomenclature = useCallback(
    (index: number) => () => {
      setNomenclatures((prevNomenclatures) =>
        prevNomenclatures.filter((item, itemIndex) => itemIndex !== index)
      );
    },
    []
  );

  const onClickNext = useCallback(async () => {
    if (!disabled) {
      await onClickSave();
    }
  }, [disabled, onClickSave]);

  const getContracts = useCallback(() => {
    return getContractsForAct({
      partnerId: values?.senderCompany?.value,
      projectId: values?.project?.value,
      customerId: values?.recipientCompany?.value,
    });
  }, [values]);

  const renderSaveButton = useCallback(() => {
    if (disabled) {
      return null;
    }

    return (
      <Button
        text="Сохранить"
        onClick={onClickSave}
        disabled={pending}
        showLoader={pending}
      />
    );
  }, [disabled, onClickSave, pending]);

  const renderAddNomenclatureButton = useCallback(() => {
    if (!!actOfWork?.actId) {
      return null;
    }

    if (disabled) {
      return null;
    }

    return (
      <div>
        <Button
          startIcon={IconPlus20}
          onClick={onClickAddNomenclature}
          disabled={pending}
        />
      </div>
    );
  }, [actOfWork?.actId, disabled, onClickAddNomenclature, pending]);

  const totalCount = useMemo(() => {
    try {
      const unit = nomenclatures[0].unit?.value || "";

      if (!unit || !nomenclatures.every((item) => item.unit?.value === unit)) {
        return "-";
      }

      return nomenclatures
        .reduce((acc, item) => acc + Number(item.quantity), 0)
        .toFixed();
    } catch (e) {
      return "-";
    }
  }, [nomenclatures]);

  const totalPrice = useMemo(() => {
    try {
      const price = nomenclatures[0].price || "0";

      if (!price || !nomenclatures.every((item) => item.price === price)) {
        return "-";
      }

      return price;
    } catch (e) {
      return "-";
    }
  }, [nomenclatures]);

  const totalSum = useMemo(() => {
    try {
      return nomenclatures
        .reduce(
          (acc, item) =>
            acc + Number(getNomenclatureSum(item).replace(",", ".")),
          0
        )
        .toFixed(2)
        .replace(".", ",");
    } catch (e) {
      return "-";
    }
  }, [getNomenclatureSum, nomenclatures]);

  const totalSumNds = useMemo(() => {
    try {
      return nomenclatures
        .reduce(
          (acc, item) =>
            acc + Number(getNomenclatureSumNds(item).replace(",", ".")),
          0
        )
        .toFixed(2)
        .replace(".", ",");
    } catch (e) {
      return "-";
    }
  }, [getNomenclatureSumNds, nomenclatures]);

  return (
    <StyledStep>
      <StyledHeader>
        <Grid>
          <Grid columns={5}>
            <Select<Company>
              label="Заказчик"
              name="recipientCompany"
              onChange={onChange}
              value={values.recipientCompany}
              error={!!errors.recipientCompany}
              helperText={errors.recipientCompany}
              valueKey="id"
              labelKeys={companyLabelKeys}
              labelKeysSeparator={" / "}
              loadData={
                !isDispatcher(user!.role) && isUser(user!.role)
                  ? getMyCompanies
                  : getCompaniesFiltered
              }
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <Select<Company>
              label="Исполнитель"
              name="senderCompany"
              onChange={onChangeSenderCompany}
              value={values.senderCompany}
              error={!!errors.senderCompany}
              helperText={errors.senderCompany}
              valueKey="id"
              labelKeys={companyLabelKeys}
              labelKeysSeparator={" / "}
              loadData={
                !isDispatcher(user!.role) && isPartner(user!.role)
                  ? getMyCompanies
                  : getCompaniesFiltered
              }
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <DateTimePicker
              label="Дата начала"
              name="startDate"
              onChange={onChange}
              value={values.startDate}
              error={!!errors.startDate}
              helperText={errors.startDate}
              hideTime={true}
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <DateTimePicker
              label="Дата завершения"
              name="endDate"
              minDate={values.startDate}
              onChange={onChange}
              value={values.endDate}
              error={!!errors.endDate}
              helperText={errors.endDate}
              hideTime={true}
              disabled={pending || !!actOfWork?.actId || disabled}
            />
            <DateTimePicker
              label="Дата выставления АВР"
              name="date"
              onChange={onChange}
              value={values.date}
              error={!!errors.date}
              helperText={errors.date}
              hideTime={true}
              disabled={pending || disabled}
              paperRight={true}
            />
          </Grid>
          <Grid columns={5}>
            <Select<Project>
              label="Проект"
              name="project"
              onChange={onChange}
              value={values.project}
              error={!!errors.project}
              helperText={errors.project}
              valueKey="id"
              labelKey="name"
              loadData={
                !isDispatcher(user!.role) && isUser(user!.role)
                  ? getMyProjects
                  : getProjects
              }
              disabled={
                pending ||
                !!actOfWork?.actId ||
                disabled ||
                !values.recipientCompany
              }
            />
            <Select<SmartContract>
              label="Договор"
              name="contract"
              onChange={onChange}
              value={values.contract}
              error={!!errors.contract}
              helperText={errors.contract}
              loadData={getContracts}
              valueKey="id"
              labelKey="name"
              disabled={
                pending ||
                !!actOfWork?.actId ||
                disabled ||
                !values.senderCompany ||
                !values.recipientCompany ||
                !values.project
              }
            />
            <Select<NDS>
              label="Ставка НДС"
              name="nds"
              onChange={onChange}
              value={values.nds}
              valueKey="name"
              labelKey="name"
              disabled={true}
            />
            <TextField
              label="Номер АВР"
              name="registrationNumber"
              onChange={onChange}
              value={values.registrationNumber}
              error={!!errors.registrationNumber}
              helperText={errors.registrationNumber}
              disabled={pending || disabled}
            />
          </Grid>
          <Grid columns={2}>
            <ActCompanyAddress
              label="Юридический адрес заказчика"
              name="recipientCompanyAddress"
              placeholder="Выберите адрес заказчика"
              onChange={onChange}
              value={values.recipientCompanyAddress}
              error={!!errors.recipientCompanyAddress}
              helperText={errors.recipientCompanyAddress}
              disabled={pending || disabled || !values.recipientCompany}
              companyId={values.recipientCompany?.value || ""}
            />
            <ActCompanyAddress
              label="Юридический адрес исполнителя"
              name="senderCompanyAddress"
              onChange={onChange}
              value={values.senderCompanyAddress}
              error={!!errors.senderCompanyAddress}
              helperText={errors.senderCompanyAddress}
              disabled={pending || disabled || !values.senderCompany}
              companyId={values.senderCompany?.value || ""}
            />
          </Grid>
        </Grid>
      </StyledHeader>
      <StyledContent>
        <Grid>
          {nomenclatures.map((item, itemIndex) => (
            <StyledNomenclature
              withRemoveButton={!disabled && !isActOfService}
              key={itemIndex.toString()}
            >
              <TextField
                label="№"
                value={(itemIndex + 1).toString()}
                disabled={true}
              />
              <span title={item.name}>
                <TextField
                  label="Услуга"
                  name="name"
                  value={item.name}
                  disabled={pending || !!actOfWork?.actId || disabled}
                  onChange={onChangeNomenclature(itemIndex)}
                  helperText={nomenclaturesError[`${itemIndex}.name`]}
                  error={!!nomenclaturesError[`${itemIndex}.name`]}
                />
              </span>
              <DateTimePicker
                label="Дата выполнения"
                hideTime={true}
                name="executionDate"
                value={item.executionDate}
                disabled={pending || !!actOfWork?.actId || disabled}
                onChange={onChangeNomenclature(itemIndex)}
              />
              <Select
                label="Ед. изм"
                value={item.unit}
                name="unit"
                loadData={getUnits}
                labelKey="name"
                valueKey="id"
                disabled={pending || !!actOfWork?.actId || disabled}
                onChange={onChangeNomenclature(itemIndex)}
                helperText={nomenclaturesError[`${itemIndex}.unit`]}
                error={!!nomenclaturesError[`${itemIndex}.unit`]}
              />
              <TextField
                label="Кол-во"
                value={
                  !!item.unit &&
                  item.unit.value === "26cd4f51-ca03-11e7-80c3-001dd8b71c7e"
                    ? "1"
                    : item.quantity
                }
                priceFormat={true}
                name="quantity"
                disabled={
                  pending ||
                  !!actOfWork?.actId ||
                  disabled ||
                  (!!item.unit &&
                    item.unit.value === "26cd4f51-ca03-11e7-80c3-001dd8b71c7e")
                }
                onChange={onChangeNomenclature(itemIndex)}
                helperText={nomenclaturesError[`${itemIndex}.quantity`]}
                error={!!nomenclaturesError[`${itemIndex}.quantity`]}
              />
              <TextField
                label="Цена"
                value={item.price}
                priceFormat={true}
                name="price"
                disabled={pending || !!actOfWork?.actId || disabled}
                onChange={onChangeNomenclature(itemIndex)}
                helperText={nomenclaturesError[`${itemIndex}.price`]}
                error={!!nomenclaturesError[`${itemIndex}.price`]}
              />
              <TextField
                label="Сумма"
                value={getNomenclatureSum(item)}
                priceFormat={true}
                disabled={true}
                onChange={onChangeNomenclature(itemIndex)}
              />
              <TextField
                label="в т.ч. НДС"
                value={getNomenclatureSumNds(item)}
                priceFormat={true}
                disabled={true}
                onChange={onChangeNomenclature(itemIndex)}
              />
              {!disabled && !isActOfService && (
                <StyledRemoveButton>
                  <Button
                    startIcon={IconTrash24}
                    onClick={onClickRemoveNomenclature(itemIndex)}
                    variant="text"
                  />
                </StyledRemoveButton>
              )}
            </StyledNomenclature>
          ))}
          {renderAddNomenclatureButton()}
        </Grid>
      </StyledContent>
      {nomenclatures.length > 0 && (
        <StyledTotal>
          <StyledTotalText>Итого</StyledTotalText>
          <TextField disabled={true} value={totalCount} priceFormat={true} />
          <TextField disabled={true} value={totalPrice} priceFormat={true} />
          <TextField disabled={true} value={totalSum} priceFormat={true} />
          <TextField disabled={true} value={totalSumNds} priceFormat={true} />
        </StyledTotal>
      )}
      <StyledFooter>
        <Button text="Назад" onClick={onClickBack} variant="text" />
        {renderSaveButton()}
        <Button
          text="Далее"
          onClick={onClickNext}
          disabled={pending || isNextDisabled}
        />
      </StyledFooter>
    </StyledStep>
  );
}
