import React, { useCallback, useEffect, useMemo, useState } from "react";
import { VehicleTrack } from "@app/models";
import { Polyline } from "@react-google-maps/api";
import VehicleTrackSrc from "./vehicle-track.svg";
import { Colors } from "../../../../ThemeProvider";
import { dateFormat, toLatLng } from "@app/helpers";
import TrackPlayer from "../../../../pages/Monitoring/components/TrackPlayer";
import VehicleMarker from "../../../../pages/Monitoring/components/VehicleMarker";
import { useMonitoring } from "@app/providers";

interface Props {
  online: boolean;
  track: VehicleTrack;
}

export default function (props: Props) {
  const { track, online } = props;
  const { setVehicle } = useMonitoring();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const currentLocation = useMemo(
    () => track.points[currentIndex] || track.points[track.points.length - 1],
    [currentIndex, track]
  );

  const greenPaths = useMemo(
    () => track.points.filter((trackPath) => trackPath?.speed <= 69),
    [track]
  );

  const yellowPaths = useMemo(
    () =>
      track.points.filter(
        (trackPath) => trackPath?.speed >= 70 && trackPath?.speed <= 89
      ),
    [track]
  );

  const redPaths = useMemo(
    () => track.points.filter((trackPath) => trackPath?.speed >= 90),
    [track]
  );

  const endDateTime = useMemo(() => {
    if (track.points.length === 0) {
      return "";
    }

    return dateFormat(
      new Date(track.points[track.points.length - 1]?.dateTime * 1000),
      "dd.MM.yyyy HH:mm:ss"
    );
  }, [track]);

  const currentDateTime = useMemo(() => {
    if (!currentLocation) {
      return "";
    }

    return dateFormat(
      new Date(currentLocation.dateTime * 1000),
      "dd.MM.yyyy HH:mm:ss"
    );
  }, [currentLocation]);

  const onClickMarker = useCallback(() => {
    // при отображении трэка отображается данный маркер
    // повторное нажатие на него сбрасывает данные
    setVehicle(null);
  }, [setVehicle]);

  useEffect(() => {
    if (online) {
      setCurrentIndex(track.points?.length - 1);
    }
  }, [online, track]);

  return (
    <>
      {/* This is the polyline for shadow */}
      <Polyline
        path={track.points.map((redPath) => toLatLng(redPath))}
        options={{
          strokeOpacity: 0.25,
          strokeWeight: 7,
          strokeColor: Colors.black,
          zIndex: 10,
        }}
      />
      <Polyline
        path={greenPaths.map((greenPath) => toLatLng(greenPath))}
        options={{
          strokeOpacity: 0.9,
          strokeWeight: 4,
          strokeColor: Colors.green,
          zIndex: 10,
        }}
      />
      <Polyline
        path={yellowPaths.map((yellowPath) => toLatLng(yellowPath))}
        options={{
          strokeOpacity: 0.9,
          strokeWeight: 4,
          strokeColor: Colors.yellow,
          zIndex: 10,
        }}
      />
      <Polyline
        path={redPaths.map((redPath) => toLatLng(redPath))}
        options={{
          strokeOpacity: 0.9,
          strokeWeight: 4,
          strokeColor: Colors.red,
          zIndex: 10,
        }}
      />
      <VehicleMarker
        trackPoint={track.points[currentIndex]}
        vehicle={track}
        options={{ icon: VehicleTrackSrc }}
        currentDateTime={currentDateTime}
        skipLoadingData={true}
        onClick={onClickMarker}
      />
      {!online && (
        <TrackPlayer
          trackIndex={currentIndex}
          onChange={setCurrentIndex}
          max={track.points?.length}
          currentDateTime={currentDateTime}
          endDateTime={endDateTime}
        />
      )}
    </>
  );
}
