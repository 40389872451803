import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Modal,
  ModalProps,
  ModalTitle,
  useForm,
} from "@app/components";
import {
  Act,
  ActCreateDTO,
  NdsShort,
  VehicleJournalForActV2,
} from "@app/models";
import { format } from "date-fns";
import {
  HeaderInfo,
  StepperRow,
  StepView1,
  StepView2,
  StepView3,
} from "./components";
import {
  APIResponse,
  createAct,
  editAct,
  getActById,
  getVehicleJournalForActV2,
  sendActToApprove,
  setActNotificationRecipients,
} from "@app/api";
import { dateFormat, getAxiosErrorMessage } from "@app/helpers";
import { useNotification } from "@app/providers";
import { IconPrint16 } from "@app/icons";
import { AxiosError } from "axios";
import { FilterParams, Signer } from "./types";
import { initFilterValues, schema } from "./constants";
import { getVehicleItemsTotal } from "../../helpers/act-of-service";
import {
  convertActSignersForSelect,
  getAosCreateFilterParams,
  getAosCreateRequest,
  prepareVehicles,
} from "./helpers";

interface Props extends Omit<ModalProps, "title"> {
  data: Act | null;
  onClose: () => void;
  updateData?: (act: Act | null) => void;
}

function ModalActOfServicesCreate(props: Props) {
  const { onClose, open, data, updateData, ...restProps } = props;

  const { showNotification } = useNotification();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [actVehicles, setActVehicles] = useState<VehicleJournalForActV2[]>([]);
  const [actSigners, setActSigners] = useState<Signer[]>([]);
  const [selectedRecipientIds, setSelectedRecipientIds] = useState<string[]>(
    data?.actNotificationRecipientsDto?.map((user) => user.id) || []
  );
  const [isParallel, setParallel] = useState<boolean>(
    data?.actSignersDto?.every((signer) => signer.queue === 1) ?? false
  );

  const {
    pending,
    onChange,
    values: filterParams,
    errors,
    validate,
    setPending,
    setValues,
    resetForm,
  } = useForm<FilterParams>({
    values: initFilterValues,
    schema,
  });

  const onPrintButtonClick = useCallback(() => {
    if (!data) {
      return;
    }
    window.open(
      `./act-of-provided-services/printing/${data.id}`,
      "_blank",
      "noopener,noreferrer"
    );
  }, [data]);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!data) {
      return "Создание табеля оказанных услуг";
    }

    if (pending) {
      return "Загружается...";
    }

    const createdDate = format(new Date(data.createdDate), "dd.MM.yyyy");

    return (
      <ModalTitle
        text={`Табель оказанных услуг №${data.actNumber} от ${createdDate}`}
      >
        {!!data.status && (
          <Button
            text={"Печатная форма"}
            size={"small"}
            variant={"outlined"}
            startIcon={IconPrint16}
            onClick={onPrintButtonClick}
          />
        )}
      </ModalTitle>
    );
  }, [data, onPrintButtonClick, pending]);

  const getActVehicles = useCallback(async () => {
    if (!filterParams) {
      // show error or validate
      return;
    }

    const requestParams = getAosCreateRequest(filterParams);
    const response = await getVehicleJournalForActV2(requestParams);

    setActVehicles(response.data);
  }, [filterParams]);

  const getCalculateTotal = useCallback(
    (actVehicle: VehicleJournalForActV2[], ndsRate: NdsShort["rate"]) =>
      actVehicle.map((parent) => ({
        ...parent,
        items: getVehicleItemsTotal(parent.items, ndsRate),
      })),
    []
  );

  const getActDetails = useCallback(async () => {
    try {
      if (!data?.id || !data?.status) {
        return;
      }
      setPending(true);
      const response = await getActById(data.id);
      const details = response.data;

      setPending(false);

      // ЗАПОЛНИТЬ ПАРАМЕТРЫ ФИЛЬТРА
      const params = getAosCreateFilterParams(details);
      setValues(params);

      // СПИСОК ПОДПИСАНТОВ
      if (details.actSignersDto?.length) {
        const _actSigners = convertActSignersForSelect(details.actSignersDto);
        const sorted = _actSigners.sort((a, b) => a.queue - b.queue);
        setActSigners(sorted);
      }

      // СПИСОК ТЕХНИКИ
      if (details.actVehicleGroupDto?.length) {
        const calculateTotal = getCalculateTotal(
          details.actVehicleGroupDto,
          details.nds.rate
        );
        setActVehicles(calculateTotal);
      }
    } catch (e) {
      setPending(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [data, getCalculateTotal, setPending, setValues, showNotification]);

  useEffect(() => {
    if (open) {
      getActDetails(); // Загрузить детали акта, если данные существуют
    }
  }, [getActDetails, open]);

  const onModalClose = useCallback(() => {
    resetForm();
    onClose();
    setCurrentStep(1);

    resetForm(); // Сбросить форму при открытии модального окна
    setActVehicles([]); // Сбросить список техники
    setActSigners([]); // Сбросить список подписантов
    setSelectedRecipientIds([]); // Сбросить список выбранных лиц для уведомлений
  }, [onClose, resetForm]);

  const onSaveClick = useCallback(
    async (isSaving = true) => {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
      setPending(true);

      const body: ActCreateDTO = {
        companyId: filterParams.organization!.value,
        projectId: filterParams.project!.value,
        partnerId: filterParams.partner!.value,
        contractId: filterParams.contract!.value,
        ndsId: filterParams.nds!.value,
        vehicles: prepareVehicles(actVehicles),
        isLifting: filterParams.vehicleType?.value === "true",
        startWorkDate: dateFormat(filterParams.startDate, "yyyy-MM-dd"),
        endWorkDate: dateFormat(filterParams.endDate, "yyyy-MM-dd"),
        actSigners: actSigners.map((item, actSignerIndex) => ({
          taskType: +item.task!.value,
          userId: item.user!.value,
          queue: isParallel ? 1 : actSignerIndex + 1,
        })),
      };

      let response;
      let message;
      let actId = data?.id;

      try {
        if (isSaving) {
          if (data && data.id) {
            body.id = data.id;
            response = await editAct(body);
            message = "Заявка на технику сохранена";
          } else {
            response = await createAct(body);
            actId = response.data.id;
            message = "Заявка на технику добавлена";
            setValues(initFilterValues);
            if (currentStep === 3) {
              onModalClose();
            }
          }

          if (updateData) {
            updateData(response.data);
          }
        } else {
          body.id = data?.id;
          response = await sendActToApprove(body);
          message = "Заявка на технику отправлена на согласование";
        }

        setPending(false);

        if (!response.succeeded) {
          showNotification({
            message: response.message,
            variant: "error",
          });

          return;
        }

        showNotification({
          message,
          variant: "success",
        });
      } catch (e) {
        setPending(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      }

      const isSameIds =
        selectedRecipientIds.length ===
          data?.actNotificationRecipientsDto?.length &&
        data?.actNotificationRecipientsDto?.every((user) =>
          selectedRecipientIds.includes(user.id)
        );

      if (actId && selectedRecipientIds.length && !isSameIds) {
        try {
          const res = await setActNotificationRecipients({
            id: actId,
            userIds: selectedRecipientIds,
          });
          if (res.succeeded) {
            showNotification({
              message: "Лица для ознакомления сохранены",
              variant: "success",
            });
          }
        } catch (e) {
          showNotification({
            message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
            variant: "error",
          });
        }
      }

      if (!isSaving) {
        onClose();
      }
    },
    [
      actSigners,
      actVehicles,
      currentStep,
      data,
      filterParams.contract,
      filterParams.endDate,
      filterParams.nds,
      filterParams.organization,
      filterParams.partner,
      filterParams.project,
      filterParams.startDate,
      filterParams.vehicleType?.value,
      isParallel,
      onClose,
      onModalClose,
      selectedRecipientIds,
      setPending,
      setValues,
      showNotification,
      updateData,
      validate,
    ]
  );

  const onPrevClick = useCallback(() => {
    if (currentStep <= 1) {
      return;
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const onNextClick = useCallback(async () => {
    if (currentStep === 1) {
      const isValid = await validate();

      if (!isValid) {
        return;
      }

      await getActVehicles();
      setCurrentStep(2);
    } else if (currentStep === 2) {
      if (actVehicles.length === 0) {
        showNotification({
          message: "Техника отсутствует",
          variant: "error",
        });
        return;
      }
      setCurrentStep(3);
    } else if (currentStep === 3) {
      if (actSigners.some((signer) => !signer.user || !signer.task)) {
        showNotification({
          message: "Заполните данные лиц согласования",
          variant: "error",
        });
        return;
      }
      await onSaveClick(false);
    }
  }, [
    actSigners,
    actVehicles.length,
    currentStep,
    getActVehicles,
    onSaveClick,
    showNotification,
    validate,
  ]);

  const onSignersChange = useCallback((data: Signer[]) => {
    setActSigners(data);
  }, []);

  const onRecipientsChange = useCallback((userIds: string[]) => {
    setSelectedRecipientIds(userIds);
  }, []);

  const onApprovalTypeChange = useCallback((isParallel: boolean) => {
    setParallel(isParallel);
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Modal
      title={modalTitle}
      onClose={onModalClose}
      open={open}
      dense
      {...restProps}
    >
      {currentStep > 1 && <HeaderInfo filterParams={filterParams} />}
      <StepperRow
        currentStep={currentStep}
        loading={pending}
        approveDisabled={currentStep === 3 && actSigners.length === 0}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        onSaveClick={onSaveClick}
      />
      {currentStep === 1 && (
        <StepView1
          actData={data}
          filterParams={filterParams}
          filterErrors={errors}
          onFilterChange={onChange}
        />
      )}
      {currentStep === 2 && (
        <StepView2
          actData={data}
          actVehicles={actVehicles}
          filterParams={filterParams}
          onListChange={(data) => {
            setActVehicles(data);
          }}
        />
      )}
      {currentStep === 3 && (
        <StepView3
          actData={data}
          actSigners={actSigners}
          selectedRecipientIds={selectedRecipientIds}
          isParallel={isParallel}
          onSignersChange={onSignersChange}
          onRecipientsChange={onRecipientsChange}
          onApprovalTypeChange={onApprovalTypeChange}
        />
      )}
      {/*{open && <FormActOfServices actData={data} onSuccess={onSuccess} />}*/}
    </Modal>
  );
}

export default memo(ModalActOfServicesCreate);
