import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  APIResponse,
  deleteCompanyEmployee,
  getCompanyEmployees,
} from "@app/api";
import { useNotification, useUser } from "@app/providers";
import { Grid, Loader } from "@app/components";
import { Employee, EmployeeComponentProps } from "@app/models";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

interface Props {
  companyId: string | null;
  onEmployeeCountChange: (count: number) => void;
}

type DeleteEmployeeHandler = (userId: string) => Promise<void>;

const StyledEmployees = styled.div`
  padding: 16px;
  box-sizing: border-box;
`;

const EmployeeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const EmployeeName = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px 0 0;
  color: ${(props) => props.theme.color.dark};

  > span {
    color: ${(props) => props.theme.color.grayDark};
    margin-left: 4px;
  }
`;

const OwnerText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${(props) => props.theme.color.grayDark};
`;

const DeleteButton = styled.button`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${(props) => props.theme.color.grayDark};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

function EmployeeComponent(props: EmployeeComponentProps) {
  const { user, isOwner, onDelete } = props;
  const { user: currentUser } = useUser();
  const isYou = useMemo(
    () => currentUser!.id === user.id,
    [currentUser, user.id]
  );
  const fullName = useMemo(
    () =>
      `${user.lastname || ""} ${user.firstname || ""} ${
        user.middlename || ""
      }`.trim(),
    [user]
  );

  const onClickDelete = useCallback(() => {
    onDelete(user.id);
  }, [user.id, onDelete]);

  return (
    <EmployeeWrapper>
      <EmployeeName>
        {fullName}
        {isYou ? <span>(Вы)</span> : null}
      </EmployeeName>
      {isOwner && <OwnerText>Владелец</OwnerText>}
      {!isOwner && <DeleteButton onClick={onClickDelete}>Удалить</DeleteButton>}
    </EmployeeWrapper>
  );
}

function CompanyEmployees(props: Props) {
  const { companyId, onEmployeeCountChange } = props;
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const getData = useCallback(async () => {
    try {
      setPending(true);

      const response = await getCompanyEmployees(companyId!);

      if (response.succeeded) {
        setEmployees(response.data);
        onEmployeeCountChange(response.data.length);
      } else {
        showNotification({
          variant: "error",
          message: "Ошибка! Попробуйте позднее или обратитесь к администратору",
        });
      }

      setPending(false);
    } catch (e) {
      setPending(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [showNotification, companyId, onEmployeeCountChange]);

  const handleDeleteEmployee: DeleteEmployeeHandler = useCallback(
    async (userId) => {
      try {
        const response = await deleteCompanyEmployee(companyId!, userId);
        if (response.succeeded) {
          setEmployees((currentEmployees) =>
            currentEmployees.filter((emp) => emp.user.id !== userId)
          );
          onEmployeeCountChange(employees.length - 1);
          showNotification({
            message: "Employee successfully deleted",
            variant: "success",
          });
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        showNotification({
          message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
          variant: "error",
        });
      }
    },
    [companyId, employees.length, onEmployeeCountChange, showNotification]
  );

  useEffect(() => {
    if (!!companyId) {
      getData();
    }
  }, [getData, companyId]);

  if (pending) {
    return <Loader />;
  }

  return (
    <StyledEmployees>
      <Grid>
        {employees.map((employee) => (
          <EmployeeComponent
            key={employee.user.id}
            {...employee}
            onDelete={handleDeleteEmployee}
          />
        ))}
      </Grid>
    </StyledEmployees>
  );
}

export default memo(CompanyEmployees);
