import React, { memo, ReactNode } from "react";
import { Button, Modal } from "@app/components";
import { SVGIconType } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";

type Props = {
  open: boolean;
  title: string;
  subtitle?: string;
  loading?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  icon?: SVGIconType;
  onClose: () => void;
  onConfirm: () => void;
  children?: ReactNode;
  large?: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px;
`;

const StyledTitle = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledSubtitle = styled.div`
  display: flex;
  padding-left: 32px;
  font-size: 14px;
  line-height: 20px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
`;

const StyledActions = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-top: 1px solid ${theme("color.grayLight")};
`;

const Dialog = (props: Props) => {
  const {
    open,
    title,
    subtitle,
    children,
    loading,
    large,
    icon: Icon,
    onClose,
    onConfirm,
    confirmLabel = "Удалить",
    cancelLabel = "Отмена",
  } = props;

  return (
    <Modal
      open={open}
      title={title}
      position="center"
      customSize={large ? "672px" : "372px"}
      hideHeader
      size={large ? "large" : "medium"}
      dense={true}
      zIndex={10000}
      onClose={onClose}
    >
      <StyledContainer>
        <StyledTitle>
          {Icon && <Icon />}
          <h3>{title}</h3>
        </StyledTitle>
        {!!subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        {!!children && <StyledContent>{children}</StyledContent>}
      </StyledContainer>

      <StyledActions>
        <Button text={cancelLabel} variant="outlined" onClick={onClose} />
        <Button text={confirmLabel} showLoader={loading} onClick={onConfirm} />
      </StyledActions>
    </Modal>
  );
};

export default memo(Dialog);
