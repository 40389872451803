import React, { memo, PropsWithChildren } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";

interface Props extends PropsWithChildren {
  disabled?: boolean;
  title?: string;
  color?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledButton = styled.button<{ disabled: boolean; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  color: ${(props) => (props.disabled ? "black" : "initial")};
  margin: 0;
  padding: 0;
  opacity: 0.7;
  color: ${(props) => theme(props.color)};

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
    color: ${theme("color.gray")};
    box-shadow: none;
  }
`;

const BaseButton = (props: Props) => {
  const {
    children,
    disabled = false,
    color = "color.dark",
    title,
    onClick,
  } = props;

  return (
    <StyledButton
      title={title}
      disabled={disabled}
      color={color}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default memo(BaseButton);
