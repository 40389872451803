import { PageableParams, PageableResponse } from "./interfaces";
import { Holding } from "@app/models";
import instance from "./instance";

export function getHoldings(
  params: PageableParams<Holding>
): Promise<PageableResponse<Holding>> {
  return instance()
    .get("/holding", {
      params,
    })
    .then((response) => response.data);
}

export function getMyHoldings(): Promise<PageableResponse<Holding>> {
  return instance()
    .get("/holding/by-user")
    .then((response) => response.data);
}
