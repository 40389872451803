export enum AdvancedPaymentType {
  None = 1,
  OneTime = 2,
  Monthly = 3,
}

export const AdvancedPaymentTypeNames: Record<AdvancedPaymentType, string> = {
  [AdvancedPaymentType.None]: "Без аванса",
  [AdvancedPaymentType.OneTime]: "Разовый аванс",
  [AdvancedPaymentType.Monthly]: "Ежемесячная предоплата",
};
