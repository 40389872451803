import { AxiosError } from "axios";
import { APIResponse } from "@app/api";

export function getAxiosErrorMessage(error: AxiosError<APIResponse>): string {
  let message = "Ошибка! Попробуйте позднее или обратитесь к администратору";

  if (!!error.response && !!error.response.data) {
    if (!!error.response.data.message) message = error.response.data.message;
    if (!!error.response.data.Message) message = error.response.data.Message;
  }

  return message;
}
