import React, { memo, useMemo } from "react";
import { ActOfWork, FileObject, FileSimple, SmartContract } from "@app/models";
import { DocumentFile, Grid, Info } from "@app/components";
import styled from "styled-components";
import { theme } from "styled-tools";
import { getFullName } from "@app/helpers";

type Props = {
  contract?: SmartContract | null;
  act?: ActOfWork | null;
  signedPdf?: FileObject | null;
  signedPdfFiles?: (FileObject | FileSimple)[] | null;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
`;

const BorderBlock = styled.div`
  border: 1px solid ${theme("color.grayLight")};
  border-radius: 4px;
  padding: 12px;
`;

const SignerListView = (props: Props) => {
  const { contract, act, signedPdf, signedPdfFiles } = props;

  const files = useMemo(() => {
    let arr: (FileObject | FileSimple)[] = [];
    if (signedPdfFiles?.length) {
      arr = signedPdfFiles;
    } else if (signedPdf) {
      arr = [signedPdf];
    }
    return arr.filter(Boolean);
  }, [signedPdf, signedPdfFiles]);

  return (
    <StyledContainer>
      {contract && (
        <Grid>
          <BorderBlock>
            <Info
              label={"Поставщик"}
              value={getFullName(contract.partnerSigner)}
            />
            <Info label={"Получатель"} value={contract.partner.name} />
          </BorderBlock>
          <BorderBlock>
            <Info
              label={"Заказчик"}
              value={getFullName(contract.customerSigner)}
            />
            <Info label={"Отправитель"} value={contract.customer.name} />
          </BorderBlock>
        </Grid>
      )}
      {act && (
        <Grid>
          <BorderBlock>
            <Info label={"Заказчик:"} value={act.recipientCompany.name} />
            <Info
              label={"Подписант:"}
              value={getFullName(act.signers.find((s) => s.isRecipient)?.user)}
            />
          </BorderBlock>
          <BorderBlock>
            <Info label={"Поставщик:"} value={act.senderCompany.name} />
            <Info
              label={"Подписант:"}
              value={getFullName(act.signers.find((s) => !s.isRecipient)?.user)}
            />
          </BorderBlock>
        </Grid>
      )}
      {files.length > 0 && (
        <Grid>
          <h6>Документ</h6>
          {files.map((item) => (
            <DocumentFile id={item.id} name={item.name || ""} previewEnabled />
          ))}
        </Grid>
      )}
    </StyledContainer>
  );
};

export default memo(SignerListView);
