import React, { memo, PropsWithChildren } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Button } from "@app/components";

interface Props {
  title: string;
  text: string;
  imageSrc: string;
  onClick?: () => void;
}

const StyledPanel = styled.div`
  padding: 60px;
  box-sizing: border-box;
  background-color: ${theme("color.light")};
  border-radius: 12px;

  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
`;

const StyledPanelGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 60px;

  @media only screen and (max-width: 576px) {
    display: block;
  }
`;

const StyledPanelTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${theme("color.dark")};
  margin: 0 0 12px 0;
`;

const StyledPanelText = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #69748c;
  white-space: pre-wrap;
`;

const StyledPanelImage = styled.img`
  width: 90%;
  @media only screen and (max-width: 576px) {
    margin: 0 auto;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > button {
    margin-left: -60px;
    @media only screen and (max-width: 576px) {
      margin: 0;
    }
  }
`;

function Panel(props: Props & PropsWithChildren) {
  const { title, text, imageSrc, onClick, children } = props;

  return (
    <StyledPanel>
      <StyledPanelGrid>
        <div>
          <StyledPanelTitle>{title}</StyledPanelTitle>
          <StyledPanelText>{text}</StyledPanelText>
          {children}
        </div>
        <div>
          <StyledPanelImage src={imageSrc} alt="" />
          {!!onClick && (
            <StyledButtonWrapper>
              <Button onClick={onClick} text="Установить" />
            </StyledButtonWrapper>
          )}
        </div>
      </StyledPanelGrid>
    </StyledPanel>
  );
}

export default memo(Panel);
