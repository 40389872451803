import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Company } from "@app/models";
import { useNotification } from "@app/providers";

interface Props {
  organization: Company;
  onClick?: () => void;
  onBack: () => void;
}

const StyledOrganization = styled.div`
  box-sizing: border-box;
  padding: 16px 0;
`;

const StyledOrganizationName = styled.p`
  margin: 0 0 4px;
  font-family: ${theme("fontFamily")};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.dark")};
`;

const StyledOrganizationBin = styled.p`
  margin: 0 0 12px;
  font-family: ${theme("fontFamily")};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme("color.grayDark")};
`;

function Organization(props: Props) {
  const { organization, onClick, onBack } = props;
  const { showNotification } = useNotification();

  return (
    <StyledOrganization onClick={onClick}>
      <StyledOrganizationName>{organization.name}</StyledOrganizationName>
      <StyledOrganizationBin>{organization.bin}</StyledOrganizationBin>
    </StyledOrganization>
  );
}

export default memo(Organization);
