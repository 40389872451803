import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { IconDocumentBlue40, IconEye24 } from "@app/icons";
import { theme } from "styled-tools";
import { Loader } from "@app/components";
import { APIResponse, getFileById } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import { useNotification } from "@app/providers";
import { downloadFile } from "../../helpers/downloadFile";
import { PreviewModal } from "@app/common";

type Props = {
  id: string | undefined;
  name: string;
  previewEnabled?: boolean;
};

const StyledDocFile = styled.div`
  //width: 303px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.12);
  }
  &:active {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }
`;

const StyledButton = styled.button`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #1b1b1b;
  text-decoration: none;
  transition: all 120ms ease-in-out;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const PreviewButton = styled.button`
  height: 40px;
  width: 40px;
  padding: 8px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${theme("color.grayLight")};
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.08);
  }
`;

const StyledTitle = styled.p`
  color: ${theme("color.dark")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const DocumentFile = (props: Props) => {
  const { name: fileName, id: fileId, previewEnabled } = props;
  const { showNotification } = useNotification();
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const getBlob = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getFileById(fileId!);
      setLoading(false);
      return res;
    } catch (e) {
      setLoading(false);
      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
      return null;
    }
  }, [fileId, showNotification]);

  const onPreviewClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!fileBlob) {
        const blob = await getBlob();
        setFileBlob(blob);
      }
      setShowModal(true);
    },
    [fileBlob, getBlob]
  );

  const onPreviewClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const onDownloadClick = useCallback(async () => {
    let blob = fileBlob;
    if (!blob) {
      blob = await getBlob();
      setFileBlob(blob);
    }
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      downloadFile(url, fileName || `(Dispatch) Документ без названия`);
    }
  }, [fileBlob, fileName, getBlob]);

  if (!fileId) return <></>;

  return (
    <>
      <StyledDocFile>
        <StyledButton title="Cкачать" onClick={onDownloadClick}>
          <IconDocumentBlue40 />
          <StyledTitle>{fileName}</StyledTitle>
        </StyledButton>
        {(previewEnabled || loading) && (
          <PreviewButton disabled={loading} onClick={onPreviewClick}>
            {loading ? <Loader size={"small"} /> : <IconEye24 />}
          </PreviewButton>
        )}
      </StyledDocFile>

      <PreviewModal open={showModal} file={fileBlob} onClose={onPreviewClose} />
    </>
  );
};

export default memo(DocumentFile);
