import React, { memo } from "react";
import styled from "styled-components";
import "./styles.scss";

interface Props {
  text: string;
  active?: boolean;
  className?: string;
  onClick?: () => void;
}

const StyledChip = styled.div<Pick<Props, "active">>`
  //background: transparent;
  //color: #636463;
  padding: 6px 8px;
  gap: 4px;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.color.orange : props.theme.color.gray};
  border-radius: 56px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  background-color: ${(props) =>
    props.active ? props.theme.color.yellowLight : "transparent"};

  color: ${(props) =>
    props.active ? props.theme.color.dark : props.theme.color.grayDark};
`;

function ChipRounded(props: Props) {
  const { text, active, className = "", onClick } = props;

  return (
    <StyledChip active={active} className={className.trim()} onClick={onClick}>
      {text}
    </StyledChip>
  );
}

export default memo(ChipRounded);
