import React, { useCallback, useEffect, useRef, useState } from "react";
import { genericMemo } from "@app/helpers";
import "./styles.scss";
import { IconChevronDown24, IconClose24 } from "@app/icons";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Checkbox } from "@app/components";
import Field, { FieldProps } from "components/Field";

interface Props<T extends { name: string; id: string }> extends FieldProps {
  options?: T[];
  valueKey?: keyof T;
  labelKey?: keyof T;
  values?: T[];
  placeholder?: string;
  onChange: (value: T[]) => void;
  onClear?: () => void;
}

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.div<{ disabled?: boolean }>`
  height: 40px;
  display: flex;
  padding: 0 36px 0 12px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  background: ${(props) =>
    theme(props.disabled ? "color.disabled" : "color.white")};
  cursor: pointer;

  :hover {
    button {
      visibility: visible;
    }
  }

  .placeholder {
    color: ${theme("color.gray")};
  }

  button {
    visibility: hidden;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 16px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;
    opacity: 0.3;

    &:hover {
      opacity: 1;
      background-color: #eee;
    }

    &:active {
      transform: scale(0.8);
    }
  }
`;

const StyledDropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 4px;
  z-index: 1;

  > div {
    padding: 0;
  }
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 12px 16px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;

    &:hover {
      background: ${theme("color.grayLight")};
    }
  }
`;

const StyledChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const StyledPaper = styled.div`
  overflow: auto;
  height: 400px;
  box-sizing: border-box;
  background: ${theme("color.white")};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  padding: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme("color.white")};
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme("color.white")};
  border: 1px solid ${theme("color.gray")};
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;

  button {
    margin-left: 8px;
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease-in-out;

    &:hover {
      opacity: 1;
      background-color: #eee;
    }

    &:active {
      transform: scale(0.8);
    }
  }
`;

const RegionMultiSelect = <T extends { name: string; id: string }>({
  options = [],
  values = [],
  label,
  valueKey = "id",
  labelKey = "name",
  placeholder = "Выберите из списка",
  onChange,
  onClear,
  ...fieldProps
}: Props<T>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [paperVisible, setPaperVisible] = useState<boolean>(false);

  const onChangeHandler = (item: T, index: number) => {
    if (index >= 0) {
      values.splice(index, 1);
    } else {
      values?.push(item);
    }
    onChange([...values]);
  };

  const onClickRemoveChip = useCallback(
    (item: T) => {
      const newValues = values.filter((v) => v[valueKey] !== item[valueKey]);
      onChange(newValues);
    },
    [onChange, valueKey, values]
  );

  const onClickOutside = useCallback((e: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target as HTMLElement)
    ) {
      setPaperVisible(false);
    }
  }, []);

  const onInputClick = useCallback(() => {
    setPaperVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);

    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, [onClickOutside]);

  const renderOptions = () => {
    return options.map((item, index) => {
      const idx = values?.findIndex((v) => v[valueKey] === item[valueKey]);
      return (
        <li key={index.toString()} onClick={() => onChangeHandler(item, idx)}>
          <Checkbox onChange={() => {}} checked={idx >= 0} />
          {item[labelKey] as string}
        </li>
      );
    });
  };

  return (
    <StyledWrapper ref={wrapperRef}>
      <Field
        label={label}
        appendIcon={<IconChevronDown24 />}
        onAppendClick={onInputClick}
        {...fieldProps}
      >
        <StyledInput disabled={fieldProps.disabled} onClick={onInputClick}>
          {values?.length ? (
            <>
              <p>Выбрано ({values.length})</p>
              {!!onClear && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                >
                  <IconClose24 />
                </button>
              )}
            </>
          ) : (
            <p className="placeholder">{placeholder}</p>
          )}
        </StyledInput>
      </Field>
      {paperVisible && (
        <StyledDropdown>
          <StyledPaper>
            <StyledList>{renderOptions()}</StyledList>
          </StyledPaper>
        </StyledDropdown>
      )}
      {values.length > 0 && (
        <StyledChips>
          {values.map((value) => (
            <Chip key={value[valueKey] as string}>
              {value[labelKey] as string}
              <button onClick={() => onClickRemoveChip(value)}>
                <IconClose24 />
              </button>
            </Chip>
          ))}
        </StyledChips>
      )}
    </StyledWrapper>
  );
};

export default genericMemo(RegionMultiSelect);
