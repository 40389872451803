import React, { memo } from "react";
import { Info } from "@app/components";
import {
  dateFormat,
  getDictionaryName,
  getFullName,
  NO_API_DATA,
} from "@app/helpers";
import { VehicleRequest } from "@app/models";
import styled from "styled-components";
import { prop, switchProp, theme } from "styled-tools";

interface Props {
  request: VehicleRequest;
  variant?: "default" | "secondary";
  columns?: number;
}

const StyledVehicleRequestDetail = styled.div<
  Pick<Props, "variant" | "columns">
>`
  padding: 16px 24px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(${prop("columns")}, 1fr);
  background-color: ${switchProp("variant", {
    default: theme("color.white"),
    secondary: "#F5F6F8",
  })};
`;

function VehicleRequestDetail(props: Props) {
  const { request, variant = "default", columns = 1 } = props;

  return (
    <StyledVehicleRequestDetail variant={variant} columns={columns}>
      <Info
        variant={variant}
        label="Организация"
        value={getDictionaryName(request.organization, "-").replace(
          "Товарищество с ограниченной ответственностью",
          ""
        )}
      />
      <Info
        variant={variant}
        label="Проект"
        value={getDictionaryName(request.project, "-")}
      />
      <Info
        variant={variant}
        label="Вид техники"
        value={getDictionaryName(request.vehicleType, "-")}
      />
      <Info
        variant={variant}
        label="Характеристика"
        value={getDictionaryName(request.characteristics, "-")}
      />
      <Info
        variant={variant}
        label="Вид услуги"
        value={getDictionaryName(request.serviceType, "-")}
      />
      <Info
        variant={variant}
        label="Объём работ"
        value={request.volume.toString()}
      />
      <Info
        variant={variant}
        label="Дата начало"
        value={dateFormat(request.startDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={variant}
        label="Дата завершения"
        value={dateFormat(request.endDate, "dd.MM.yyyy HH:mm")}
      />
      <Info
        variant={variant}
        label="Единица измерения работ"
        value={getDictionaryName(request.unit, "-")}
      />
      <Info
        variant={variant}
        label="Ориентировачная цена без НДС"
        value={NO_API_DATA}
      />
      <Info
        variant={variant}
        label="Ориентировачная цена с НДС"
        value={NO_API_DATA}
      />
      <Info
        variant={variant}
        label="Комментарий"
        value={!!request.comments ? request.comments : "-"}
      />
      {!!request.confirmer && (
        <>
          <Info
            variant={variant}
            label="ФИО диспетчера"
            value={getFullName(request.confirmer)}
          />
          <Info
            variant={variant}
            label="Контакты диспетчера"
            value={request.confirmer.phone || "-"}
          />
        </>
      )}
    </StyledVehicleRequestDetail>
  );
}

export default memo(VehicleRequestDetail);
