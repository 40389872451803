import React, { memo, useCallback, useMemo } from "react";
import { DocumentFile, Grid } from "@app/components";
import { FileObject, FileSimple } from "@app/models";
import styled from "styled-components";
import { IconFile56 } from "@app/icons";
import { theme } from "styled-tools";

type FileCommon = FileObject | FileSimple | null;

interface Props {
  files: FileCommon[];
  pdfFiles: FileCommon[];
  signedPdfFiles: FileCommon[];
  filesTitle?: string;
  pdfFilesTitle?: string;
  signedPdfFilesTitle?: string;
}

const StyledContainer = styled.div`
  padding: 0;
`;

const StyledTitle = styled.h6`
  margin: 0 8px;
`;

const StyledCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 16px;
  margin: 16px 0;
  font-size: 14px;
  line-height: 20px;
  color ${theme("color.grayDark")};
`;

const ContractTranscriptView = (props: Props) => {
  const {
    files,
    pdfFiles,
    signedPdfFiles,
    filesTitle = "Договор",
    pdfFilesTitle = "Документ",
    signedPdfFilesTitle = "Документ подписанный",
  } = props;

  const isNoFiles = useMemo(
    () =>
      [...files, ...pdfFiles, ...signedPdfFiles].filter(Boolean).length === 0,
    [files, pdfFiles, signedPdfFiles]
  );

  const getFileList = useCallback(
    (value: FileCommon[], title: string, previewEnabled = true) => {
      const filtered = value.filter(Boolean);

      if (filtered.length === 0) {
        return null;
      }

      return (
        <React.Fragment>
          <StyledTitle>{title}</StyledTitle>
          {filtered.map((item, index) => {
            return (
              <DocumentFile
                key={index.toString()}
                id={item?.id}
                name={item?.name || `${title} ${item?.id || index.toString()}`}
                previewEnabled={previewEnabled}
              />
            );
          })}
        </React.Fragment>
      );
    },
    []
  );

  if (isNoFiles) {
    return (
      <React.Fragment>
        <StyledTitle>Файл договора</StyledTitle>
        <StyledCentered>
          <IconFile56 />
          Файл договора еще не добавлен
        </StyledCentered>
      </React.Fragment>
    );
  }

  return (
    <StyledContainer>
      <Grid>
        {getFileList(files, filesTitle, false)}
        {getFileList(pdfFiles, pdfFilesTitle)}
        {getFileList(signedPdfFiles, signedPdfFilesTitle)}
      </Grid>
    </StyledContainer>
  );
};

export default memo(ContractTranscriptView);
