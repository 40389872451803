import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { LatLng } from "@app/models";
import { GoogleMap, type Libraries, LoadScript } from "@react-google-maps/api";
import { toLatLng } from "@app/helpers";

interface Props extends PropsWithChildren {
  options?: google.maps.MapOptions;
}

interface GoogleMapContextValue {
  map: google.maps.Map;
  setOptions: Dispatch<SetStateAction<google.maps.MapOptions>>;
  setZoom: Dispatch<SetStateAction<number>>;
  setCenter: Dispatch<SetStateAction<LatLng>>;
}

const GoogleMapContext = createContext<GoogleMapContextValue>(
  {} as GoogleMapContextValue
);

const mapLibraries: Libraries = ["geometry", "drawing"];
export default function (props: Props) {
  const { children } = props;
  const [options, setOptions] = useState<google.maps.MapOptions>(
    props.options || {
      disableDefaultUI: true,
      mapTypeId: "hybrid",
      mapTypeControl: false,
      fullscreenControl: false,
      gestureHandling: "greedy",
      draggableCursor: "default",
    }
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState<number>(12);
  const [center, setCenter] = useState<LatLng>({
    latitude: 51.169,
    longitude: 71.449,
  });

  const onLoadMap = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBe_7MUVT03OzJDMLs1mlOeo5b9tPRjdHU"
      libraries={mapLibraries}
    >
      <GoogleMap
        tilt={0}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "absolute",
          zoom: "125%",
        }}
        center={toLatLng(center)}
        onLoad={onLoadMap}
        zoom={zoom}
        options={options}
      >
        {!!map && (
          <GoogleMapContext.Provider
            value={{
              map,
              setOptions,
              setZoom,
              setCenter,
            }}
          >
            {children}
          </GoogleMapContext.Provider>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export const useGoogleMap = (): GoogleMapContextValue => {
  return useContext(GoogleMapContext);
};
