import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Modal, ModalProps, ModalTitle } from "@app/components";
import { VAERequest, VAERequestOffer, VAERequestStatus } from "@app/models";
import {
  getAxiosErrorMessage,
  isDispatcher,
  isPartner,
  isUser,
} from "@app/helpers";
import {
  APIResponse,
  confirmVAERequest,
  confirmVAERequestOffer,
  getVAERequest,
  rejectVAERequest,
} from "@app/api";
import { useNotification, useUser } from "@app/providers";
import { AxiosError } from "axios";
import {
  VAERequestDetail,
  VAERequestOffers,
  VAERequestStatusChip,
} from "@app/common";
import { ModalVAERequestOffer } from "../index";

interface Props extends Omit<ModalProps, "title"> {
  data: Pick<VAERequest, "id" | "number" | "status"> | null;
  showOffers: boolean;
  updateData?: (item: VAERequest) => void;
}

function ModalVAERequestDetail(props: Props) {
  const { data, showOffers, updateData, ...modalProps } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [request, setRequest] = useState<VAERequest | null>(null);
  const [offer, setOffer] = useState<VAERequestOffer | null>(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);
  const [rejecting, setRejecting] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [requestOfferModalVisible, setRequestOfferModalVisible] =
    useState(false);

  const onSelectOffer = useCallback((selectedOffer: VAERequestOffer) => {
    setOffer(selectedOffer);
  }, []);

  const onClickReject = useCallback(async () => {
    try {
      setRejecting(true);

      const response = await rejectVAERequest({
        id: data!.id,
      });

      setRejecting(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заявка на дополнительные затраты отклонена",
        variant: "success",
      });

      if (!!updateData) {
        updateData({
          ...request!,
          status: VAERequestStatus.Rejected,
        });
      }

      modalProps.onClose();
    } catch (e) {
      setRejecting(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [data, showNotification, updateData, modalProps, request]);

  const onClickConfirm = useCallback(async () => {
    try {
      setConfirming(true);

      const response = await confirmVAERequest({
        id: data!.id,
      });

      setConfirming(false);

      if (!response.succeeded) {
        showNotification({
          message: response.message,
          variant: "error",
        });

        return;
      }

      showNotification({
        message: "Заявка на дополнительные затраты подтверждена",
        variant: "success",
      });

      if (!!updateData) {
        updateData({
          ...request!,
          status: VAERequestStatus.AwaitingRequest,
        });
      }

      modalProps.onClose();
    } catch (e) {
      setConfirming(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [data, showNotification, updateData, modalProps, request]);

  const onClickConfirmRequestOffer = useCallback(async () => {
    try {
      setConfirming(true);

      const response = await confirmVAERequestOffer(offer!.id);

      setConfirming(true);

      if (!response.succeeded) {
        showNotification({
          variant: "error",
          message: response.message,
        });

        return;
      }

      showNotification({
        message: "Предложение на дополнительные затраты выбрано",
        variant: "success",
      });

      if (!!updateData) {
        updateData({
          ...request!,
          status: VAERequestStatus.TermAgreement,
        });
      }

      modalProps.onClose();
    } catch (e) {
      setConfirming(false);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [modalProps, offer, request, showNotification, updateData]);

  const onClickAddOffer = useCallback(() => {
    setRequestOfferModalVisible(true);
  }, []);

  const onSuccessCreateVehicleRequestOffer = useCallback(() => {
    setRequestOfferModalVisible(false);

    modalProps.onClose();
  }, [modalProps]);

  const closeRequestOfferModal = useCallback(() => {
    setRequestOfferModalVisible(false);
  }, []);

  const getData = useCallback(async () => {
    try {
      const response = await getVAERequest(data!.id);

      if (!response.succeeded) {
        showNotification({
          variant: "error",
          message: response.message,
        });

        setError(true);
        setPending(false);

        return;
      }

      setRequest(response.data);
      setError(false);
      setPending(false);
    } catch (e) {
      showNotification({
        variant: "error",
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
      });

      setError(true);
      setPending(false);
    }
  }, [showNotification, data]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];

    if (!data) {
      return returnActions;
    }

    if (
      [
        VAERequestStatus.AwaitingConfirmation,
        VAERequestStatus.AwaitingRequest,
      ].indexOf(data.status) > -1
    ) {
      if (isUser(user!.role) || isDispatcher(user!.role)) {
        returnActions.push(
          <Button
            text="Отклонить"
            variant="outlined"
            onClick={onClickReject}
            disabled={rejecting || confirming}
            showLoader={rejecting}
          />
        );
      }
    }

    if (data.status === VAERequestStatus.AwaitingRequest) {
      if (isUser(user!.role) || isDispatcher(user!.role)) {
        returnActions.push(
          <Button
            text="Выбрать"
            onClick={onClickConfirmRequestOffer}
            disabled={rejecting || confirming || !offer}
            showLoader={confirming}
          />
        );
      }

      if (isPartner(user!.role)) {
        returnActions.push(
          <Button text="Подать предложение" onClick={onClickAddOffer} />
        );
      }
    }

    if (data.status === VAERequestStatus.AwaitingConfirmation) {
      if (isDispatcher(user!.role)) {
        returnActions.push(
          <Button
            text="Подтвердить"
            onClick={onClickConfirm}
            disabled={rejecting || confirming}
            showLoader={confirming}
          />
        );
      }
    }

    return returnActions;
  }, [
    offer,
    data,
    user,
    onClickReject,
    rejecting,
    confirming,
    onClickConfirmRequestOffer,
    onClickConfirm,
    onClickAddOffer,
  ]);

  const modalTitle = useMemo<ReactNode>(() => {
    if (!data) {
      return null;
    }

    return (
      <ModalTitle text={`Заказ на доп.затраты № ${data.number}`}>
        {!!data.status && <VAERequestStatusChip status={data.status} />}
      </ModalTitle>
    );
  }, [data]);

  useEffect(() => {
    if (!!data) {
      setPending(true);
      setRequest(null);

      getData();
    }
  }, [data, getData]);

  if (!data) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      title={modalTitle}
      actions={actions}
      size={showOffers ? "xlarge" : "small"}
      dense={true}
    >
      {!!request && (
        <>
          {!showOffers ? (
            <VAERequestDetail request={request} />
          ) : (
            <>
              <VAERequestOffers
                request={request}
                selectedOffer={offer}
                onSelectOffer={onSelectOffer}
                onClickAddOffer={onClickAddOffer}
              />
              <ModalVAERequestOffer
                hideInfo={true}
                onSuccess={onSuccessCreateVehicleRequestOffer}
                request={request}
                open={requestOfferModalVisible}
                onClose={closeRequestOfferModal}
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
}

export default memo(ModalVAERequestDetail);
