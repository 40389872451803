import React, { useCallback, useMemo, useState } from "react";
import { Modal, ModalProps, Tabs } from "@app/components";
import { Company } from "@app/models";
import { CompanyEmployees, CompanyJoinRequests } from "./components";

interface Props extends ModalProps {
  company: Company | null;
}

function ModalCompanyDetail(props: Props) {
  const { onClose, company, ...modalProps } = props;
  const [employeeCount, setEmployeeCount] = useState(0);
  const [joinRequestsCount, setJoinRequestsCount] = useState(0);

  const tabLabels = useMemo<string[]>(
    () => [
      `Сотрудники ${employeeCount ? `(${employeeCount})` : ""}`.trim(),
      `Запросы на добавление ${
        joinRequestsCount ? `(${joinRequestsCount})` : ""
      }`.trim(),
    ],
    [employeeCount, joinRequestsCount]
  );

  const onEmployeeCountChange = useCallback((count: number) => {
    setEmployeeCount(count);
  }, []);

  const onJoinRequestsCount = useCallback((count: number) => {
    setJoinRequestsCount(count);
  }, []);

  return (
    <Modal onClose={onClose} {...modalProps} dense>
      <Tabs labels={tabLabels}>
        <CompanyEmployees
          companyId={company ? company.id : ""}
          onEmployeeCountChange={onEmployeeCountChange}
        />
        <CompanyJoinRequests
          companyId={company ? company.id : ""}
          onConfirm={onClose}
          onJoinRequestsCount={onJoinRequestsCount}
        />
      </Tabs>
    </Modal>
  );
}

export default ModalCompanyDetail;
